<template>
    <v-col
        :cols="windowWidth >= 600 ? '1' : '10'"
        xl="1"
        lg="2"
        md="3"
        sm="4"
        class="scrollContainer"
        :style="windowWidth >= 600 ? '' : 'min-width: 95vw'"
        style="min-width: 295px; padding-right: 0"
    >
        <!-- <v-row
            no-gutters
        > -->
 
        <div class="d-flex">
            <div
                v-if="card.position_x === 0"
                :class="dragColumnMode ? 'columnDropZone':''"
                @dragenter="onDragColumnEnter"
                @dragover="onDragColumnOver"
                @dragleave="onDragColumnLeave"
                @drop="dropColumn($event, -1)"
                @dragover.prevent
                @drop.prevent
            />
            <v-card
                v-click-outside="clickOutsideTitleCard"
                class="pinboardCardTitle mt-4 d-flex justify-space-between align-center"
                :class="membershipRole === 'visitor' ? 'defaultCursor' : ''"
                :style="{'background-color': columnColor}"
                style="min-height: 71px; cursor: pointer"
                elevation="0"
                :draggable="dragColumnMode"
                :ripple="membershipRole !== 'visitor'"
                @dragstart="dragElementAction"
                v-on="enableClick ? { click: editCardTitle } : {}"
            >
                <v-icon
                    v-if="dragColumnMode && (selectedTitleCard && selectedTitleCard._id === card._id)"
                    color="white"
                    style="position:absolute; right: 0.25rem; top:0.25rem;"
                    large
                >
                    mdi-cursor-move
                </v-icon>
                <div
                    class="titleCardDiv"
                >
                    <p
                        v-if="!editTitle"
                        class="ml-2 ma-1"
                        style="font-weight: bold; overflow: auto; width: 175px; color: black; font-size: 23px"
                    >
                        {{ card.content }}
                    </p>
                    <v-text-field
                        v-else
                        id="cardTitleTextField"
                        v-model="card.content"
                        class="pinboardTitleCardInput"
                        dense
                        rounded
                        filled
                        background-color="rgba(255,255,255,0)"
                        color="black"
                        placeholder="Titel eingeben"
                        style="font-color: black !important;"
                        @keyup.enter="clickOutsideTitleCard"
                    />
                </div>
            </v-card>
            <v-card
                v-show="editTitle"
                class="pinboardCardButtonBody mt-4 buttonWindow"
                :style="accountRole === 'pupil' || accountRole === 'parent' ? 'height: 112px' : ''"
                elevation="0"
            >
                <v-tooltip
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteWholeCard()"
                        >
                            <img
                                :src="papierkorbIcon"
                                class="iconToRed pa-0 ml-1"
                                style="max-width: 23px; margin-top:-4px"
                                alt="Spalte löschen"
                            >
                        </v-btn>
                    </template>
                    <span>Spalte löschen</span>
                </v-tooltip>
                <div
                    :id="`colorPicker${position_x}`"
                    style="display: inline"
                >
                    <PinboardColorPicker
                        :attach="`colorPicker${position_x}`"
                        :change-card-color="changeColumnColor"
                        :card-color="columnColor"
                    />
                </div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            class="buttonWindow"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDragColumn()"
                        >
                            <v-icon color="grey">
                                mdi-cursor-move
                            </v-icon>
                        </v-btn>
                    </template>
                    <span> verschieben</span>
                </v-tooltip>
                <v-tooltip
                    :disabled="!showTooltips"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="accountRole === 'pupil' || accountRole === 'parent'"
                            icon
                            style="margin-left: 2px"
                            v-on="on"
                            @click="readText(sanitizeHtml(urlify(card.content)))"
                        >
                            <img
                                :src="usedSpeakerIcon"
                                class="icon20"
                                alt="Vorlesen"
                            >
                        </v-btn>
                    </template>
                    <span>Vorlesen</span>
                </v-tooltip>
            </v-card>
            <v-card
                v-if="!editTitle && membershipRole !== 'visitor'"
                class="pinboardCardButtonBodyNoEdit mt-4 buttonWindow"
                elevation="0"
            >
                <v-tooltip
                    class="buttonWindow"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            class="buttonWindow"
                            v-on="on"
                            @click="editCardTitle()"
                        >
                            <img
                                class="iconToGrey buttonWindow"
                                style="max-width: 20px"
                                alt="Titel bearbeiten"
                                :src="stiftIconDark"
                            >
                        </v-btn>
                    </template>
                    <span
                        class="buttonWindow"
                    >Titel bearbeiten</span>
                </v-tooltip>
            </v-card>
            <div
                :class="dragColumnMode ? 'columnDropZone':''"
                @dragenter="onDragColumnEnter"
                @dragover="onDragColumnOver"
                @dragleave="onDragColumnLeave"
                @drop="dropColumn($event, position_x)"
                @dragover.prevent
                @drop.prevent
            />
        </div>
        <div
            v-if="showLastEdited && card.lastEdited"
            class="d-flex flex-row justify-space-between"
            style="width: 75%;"
        >
            <v-spacer />
            <PinboardLastEdited
                :card="card"
                class="pinboardLastEditedWrapper"
            />
        </div>
        <!-- </v-row> -->
        <div
            :key="reloadKey"
            class="scrollContainer"
            style="overflow-y:scroll;"
        >
            <div
                v-if="filterContentCards.length===0"
                :class=" dragMode ? 'dropZone' : 'cardMargin'"
                @dragenter="onDragEnter"
                @dragover="onDragOver"
                @dragleave="onDragLeave"
                @drop="dropCard($event,position_x, 1)"
                @dragover.prevent
                @drop.prevent
            />
            <div
                v-for="(contentCard, index) in filterContentCards"
                :key="index"
            >
                <div
                    v-if="index===0"
                    :class=" dragMode ? 'dropZone' : 'cardMargin'"
                    @dragenter="onDragEnter"
                    @dragover="onDragOver"
                    @dragleave="onDragLeave"
                    @drop="dropCard($event, position_x, 1)"
                    @dragover.prevent
                    @drop.prevent
                />

                <PinboardCardElement
                    :ref="contentCard._id"
                    :content-card="contentCard"
                    :content-card-color="contentCard.color"
                    :files="contentCard.files"
                    :position_y="index + 1"
                    :position_x="position_x"
                    :pinboard="pinboard"
                    :update-card="updateCard"
                    :are-you-sure-card="areYouSureCard"
                    :membership-role="membershipRole"
                    @selectedCard="cardSelected"
                />
                <div
                    :class=" dragMode ? 'dropZone' : 'cardMargin'"
                    @dragenter="onDragEnter"
                    @dragover="onDragOver"
                    @dragleave="onDragLeave"
                    @drop="dropCard($event,position_x, index+2)"
                    @dragover.prevent
                    @drop.prevent
                />
            </div>
            <PinboardCardElementPlus
                v-if="membershipRole !== 'visitor'"
                :position_y="filterContentCards.length + 1"
                :position_x="position_x"
                :card-color="columnColor"
                :create-content-card="createContentCard"
            />
        </div>
    </v-col>
</template>

<script>
import {mapState, mapActions,mapMutations, mapGetters} from "vuex";
import stiftIconDark from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import papierkorbIcon from "../../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";

import PinboardCardElement from "./PinboardCardElement.vue";
import PinboardCardElementPlus from "./PinboardCardElementPlus.vue";
import PinboardColorPicker from "@/components/Pinboard/PinboardCardElements/PinboardColorPicker"

import {textToSpeech} from "@/util/textToSpeech";
import sanitizeHtml from '../../../util/sanitizeHtml';
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import PinboardLastEdited from './PinboardLastEdited.vue';
import urlify from '../../../util/urlify';
export default {
    name: 'PinboardCardsColumn',
    components: {
        PinboardCardElement,
        PinboardCardElementPlus,
        PinboardColorPicker,
        PinboardLastEdited
    },
    props: {
        card: { required: true, type: Object },
        allCards: { required: true, type: Array },
        wholePinboard:{ required: true, type: Array},
        // eslint-disable-next-line
        position_x: { required: true, type: Number },
        updateCard: { required: true, type: Function },
        createContentCard: { required: true, type: Function },
        areYouSureCard: { required: true, type: Function },
        columnColor: { required: false, type: String, default: '#3cb371' },
        pinboard: { required: true, type: Object },
        membershipRole: { required: true, type: String }
    },
    data: () => ({
        stiftIconDark,
        papierkorbIcon,
        currentCardContent: '',
        editTitle: false,
        titlePosition_y: 0,
        enableClick: true,
        showLastEdited: false,

        lautsprecherIcon,
        lautsprecherAusIcon,
        usedSpeakerIcon: lautsprecherIcon,
        sanitizeHtml,
        urlify,
        droppedElement: null,
        selectedElement: null,
        reloadKey: 0,
        targeClassElement: null,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('pinboard',['dragMode','selected','dragColumnMode','selectedTitleCard']),
        filterContentCards() {
            return this.allCards.filter(card => !card.isTitleCard && card.position_x === this.card.position_x).sort((a, b) => a.position_y - b.position_y);
        },
    },
    watch: {
        editTitle(newVal){
             if(newVal && newVal === true){
                this.showLastEdited = true;
            }
            if(newVal === false){
                this.showLastEdited = false;
            }
        }
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions("pinboard", ['toggleDragColumnMode','toggleDragMode','getPinboardCardUpload', 'deletePinboardCardUpload', 'getPinboardUploadThumbnail', 'toggleOpenPinboard','selectCard','removeSelectedCard','patchCard','selectTitleCard']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async editCardTitle() {
            if (this.membershipRole !== 'visitor' && !this.dragMode) {
                this.currentCardContent = this.card.content;
                this.enableClick = !this.enableClick;
                this.editTitle = !this.editTitle;
                await this.$nextTick();
                document.getElementById('cardTitleTextField').focus();
                document.getElementById('cardTitleTextField').select();
            }
        },
        deleteWholeCard() {
            var cardsToDelete = [];

            cardsToDelete = this.allCards.filter(card => card.position_x === this.card.position_x);
            cardsToDelete.push(this.card);

            this.enableClick = !this.enableClick;
            this.editTitle = !this.editTitle;

            this.$emit("areYouSureColumn", cardsToDelete);
            this.removeSelectedCard();
        },

        clickOutsideTitleCard(event) {
            if (this.enableClick === false && !event.target.closest('.pinboardLastEditedWrapper')
            && !event.target.classList.contains("buttonWindow") && !event.target.classList.contains("v-btn__content") && !event.target.classList.contains("mdi-palette")) {
                this.enableClick = !this.enableClick;
                this.editTitle = !this.editTitle;
                if (this.currentCardContent !== this.card.content) {
                    this.$emit("editCardColumnTitle", this.card.content, this.position_x, this.titlePosition_y, this.card._id, this.columnColor);
                }
            }
        },

        changeColumnColor(color) {
            var cardsToChangeColor = [];
            cardsToChangeColor = this.allCards.filter(card => card.position_x === this.card.position_x);
            cardsToChangeColor.push(this.card);

            this.$emit("changeColumnColor", cardsToChangeColor, color);
        },


        async readText(text) {  
            let txt;

            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Diese Notiz hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                this.usedSpeakerIcon = this.lautsprecherAusIcon;
            }, () => {
                this.usedSpeakerIcon = this.lautsprecherIcon;
            });
        },
        cardSelected(selected){
            this.selectedElement = selected;
        },
        getAllCardsFromTitleCard(xPos) {
            return this.wholePinboard.filter(card => card.position_x === xPos && !card.isTitleCard).sort((a, b) => a.position_y - b.position_y);
        },
        async moveCardsInColumn(column, xIndex, yIndex){
            const slicedColumn = column.slice(yIndex-1);
           const newItems = await Promise.all(slicedColumn.map(async (card)=>{
                await this.patchCard({cardId: card._id, body: {
                    position_x: xIndex,
                    position_y: card.position_y + 1,
                } })
                }));
        },
        async moveCardsInPreviousColumn(column, xIndex, yIndex){
            const slicedColumn = column.slice(yIndex-1);
            const newItems = await Promise.all(slicedColumn.map(async (card)=>{
                await this.patchCard({cardId: card._id, body: {
                    position_x: xIndex,
                    position_y: card.position_y - 1,
                } })
                }));
        },
         async moveCardsInSameColumn(column, xIndex, newPositionY, oldPositionY){
            if(newPositionY > oldPositionY){
               const itemsToBeChanged = column.filter(card => card.position_y > oldPositionY);
                const newItems = await Promise.all(itemsToBeChanged.map(async (card)=>{
                    await this.patchCard({cardId: card._id, body: {
                        position_x: xIndex,
                        position_y: card.position_y -1,
                    } })
                }));
            }else if(newPositionY < oldPositionY){
                const itemsToBeChanged = column.filter(card => (card.position_y < oldPositionY) && (card.position_y >= newPositionY));
                 const newItems = await Promise.all(itemsToBeChanged.map(async (card)=>{
                    await this.patchCard({cardId: card._id, body: {
                        position_x: xIndex,
                        position_y: card.position_y + 1,
                    } })
                }));
            }else if (newPositionY === oldPositionY){
                console.log('nothing changed');
                return 
            }
        },
        async dropCard(e, positionX, positionY){
            this.limitEvent(e);

            if(this.dragMode){
                const transferredData = e.dataTransfer.getData("text/plain");
                const droppedCard = this.wholePinboard.find(card => card._id === transferredData);
            
                //if no y-coordinate just add to beginning
                let yPosition = positionY || 1;
                
                
                const previousXPosition = droppedCard.position_x;
                const previousYPosition = droppedCard.position_y;
                const previousColumnCards = this.getAllCardsFromTitleCard(previousXPosition).filter(card=> card._id !== droppedCard._id)

                const thisColumnCards = this.getAllCardsFromTitleCard(positionX);
                if(positionX === droppedCard.position_x){
                    await this.moveCardsInSameColumn(thisColumnCards,positionX,yPosition,droppedCard.position_y);

                }else{
                //if current column has cards we may need to change them
                if(thisColumnCards.length > 0){
                    await this.moveCardsInColumn(thisColumnCards,positionX,positionY);
                }


            //if old column has still cards, we may need to change their x-coordinate.
            if( previousColumnCards.length >0){
                await this.moveCardsInPreviousColumn(previousColumnCards,previousXPosition,previousYPosition);
                }
                }
                //updateCard
                if(!((positionX === droppedCard.position_x) && (((yPosition -1)  === droppedCard.position_y) || (yPosition   === droppedCard.position_y)))){
                    await this.patchCard({cardId: droppedCard._id, body: {
                                        position_x: positionX,
                                        position_y: yPosition,
                                    } })
                this.$emit('pinboardChange');

                }else{
                    console.log('Nothing changed');
                }
            
                this.selectedElement = null;
                this.removeSelectedCard();

                if(e.currentTarget){
                e.currentTarget.classList.remove('getBigger');
                }else{
                    this.targeClassElement.classList.remove('getBigger');
                }
                this.toggleDragMode()
                this.reloadKey +=1;
            }
        },
        limitEvent(event) {
            event.stopPropagation();
            event.preventDefault();
        },
         onDragEnter(e) {
            this.limitEvent(e);
            if(this.dragMode){
                this.targeClassElement = e.currentTarget;
                e.currentTarget.classList.add('getBigger');
            }
        },
        onDragLeave(e) {
            this.limitEvent(e);

            if(this.dragMode){
                e.currentTarget.classList.remove('getBigger');
            }
        },
        onDragOver(e) {
                this.limitEvent(e);
        },
        onDragColumnEnter(e) {
            this.limitEvent(e);
            if(this.dragColumnMode){
                this.targeClassElement = e.currentTarget;
                e.currentTarget.classList.add('getWider');
            }
        },
        onDragColumnLeave(e) {
            this.limitEvent(e);

            if(this.dragColumnMode){
                e.currentTarget.classList.remove('getWider');
            }
        },
        onDragColumnOver(e) {
                this.limitEvent(e);
        },
        async dropColumn(e, newPositionX){
            if(this.dragColumnMode){
                 const transferredData = e.dataTransfer.getData("text/plain");
                const droppedCard = this.wholePinboard.find(card => card._id === transferredData);
                const oldPositionX = droppedCard.position_x;
                const elementsOldPositionX = this.wholePinboard.filter(card => card.position_x === oldPositionX);
                //depending on previous x-position and new position we need to perform different actions
                if(newPositionX === -1 && oldPositionX === 0){
                    console.log('Nothing changes');
                }
                else if(newPositionX > oldPositionX){
                    const elementsMinusOneX = this.wholePinboard.filter(card => (card.position_x > oldPositionX) && (card.position_x <= newPositionX))
                    if(elementsOldPositionX.length){
                        const newItems = await Promise.all(elementsOldPositionX.map(async (card)=>{
                        await this.patchCard({cardId: card._id, body: {
                            position_x: newPositionX,
                        } })
                        }));
                    }
                    if(elementsMinusOneX.length){
                        const newItems = await Promise.all(elementsMinusOneX.map(async (card)=>{
                        await this.patchCard({cardId: card._id, body: {
                            position_x: card.position_x -1,
                        } })
                        }));
                    }
                }else if(oldPositionX > newPositionX){
                    const elementsPlusOneX = this.wholePinboard.filter(card => (card.position_x < oldPositionX) && (card.position_x > newPositionX) )

                    if(elementsOldPositionX.length){
                        const newItems = await Promise.all(elementsOldPositionX.map(async (card)=>{
                        await this.patchCard({cardId: card._id, body: {
                            position_x: newPositionX +1,
                        } })
                        }));
                    }
                    if(elementsPlusOneX.length){
                        const newItems = await Promise.all(elementsPlusOneX.map(async (card)=>{
                        await this.patchCard({cardId: card._id, body: {
                            position_x: card.position_x +1,
                        } })
                        }));
                    }
                }
                else{
                    console.log('nothing changes');
                }

                this.targeClassElement.classList.remove('getWider');
                this.targeClassElement = null;
                this.selectTitleCard({});
                this.toggleDragColumnMode();
            }
        },
        dragElementAction(e){
            if(this.selectedTitleCard._id){
                e.dataTransfer.setData("text/plain", this.card._id);
                e.dataTransfer.dropEffect = "move";
            }
        },
        openDragColumn(){
            this.toggleDragColumnMode();
            this.selectTitleCard(this.card);
        }
    }
}
</script>

<style scoped lang="scss">
//transparent scrollbar track
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.pinboardCardTitle {
    background-color: mediumseagreen;
    color: whitesmoke;
    font-size: 30px;
    width: 220.5px;
}

.scrollContainer {
    //necessary to set a maximum height to enable scrolling (can also just be height)
    max-height: calc(100vh - 200px);
}

.titleCardDiv {
    width: 100%;
}

.pinboardTitleCardInput {
    font-weight: bold;
    width: 165px;
    font-size: 27px;
    margin-left: -16px;
    margin-top: 13px;
    margin-bottom: -10px !important;
}

.pinboardCardButtonBody {
    background-color: white;
    height: 108px;
    margin-left: 5px;
    width: 37px;
    box-shadow: 0 0 13px gray !important;
    border-radius: 8px;
}

.pinboardCardButtonBodyNoEdit {
    background-color: transparent;
    height: 75px;
    margin-left: 5px;
    width: 37px;
}

.defaultCursor {
    cursor: default !important;
}

@media only screen and (min-width: 725px) and (max-width: 1610px) {
    .titleCardDiv {
        width: 79%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 724px) {
    .titleCardDiv {
        width: 73%;
    }
}

@media only screen and (max-width: 599px) {
    .pinboardCardTitle {
        width: 72vw;
    }
}
.cardMargin{
    height: 1rem
}
.dropZone{
    height: 1.3rem
}
.getBigger {
    height: 2.5rem;
}
.getWider{
    width: 2.5rem;
}
.columnDropZone{
    width:1.3rem;
}
</style>
