import { render, staticRenderFns } from "./ChatRoomCreatePrivateChatDialog.vue?vue&type=template&id=7b967326&scoped=true&"
import script from "./ChatRoomCreatePrivateChatDialog.vue?vue&type=script&lang=js&"
export * from "./ChatRoomCreatePrivateChatDialog.vue?vue&type=script&lang=js&"
import style0 from "./ChatRoomCreatePrivateChatDialog.vue?vue&type=style&index=0&id=7b967326&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b967326",
  null
  
)

export default component.exports