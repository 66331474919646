import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";

const state = {
    privacyPolicy: false,
    forcePrivacyPolicy: false,
    privacyPolicyTutorialInfo: null,
};

const mutations = {
    SET_PRIVACY_POLICY: (state, value) => {
        state.privacyPolicy = value;
    },
    SET_FORCE_PRIVACY_POLICY: (state, value) => {
        state.forcePrivacyPolicy = value;
    },
    SET_PRIVACY_POLICY_TUTORIAL_INFO: (state, value) => {
        state.privacyPolicyTutorialInfo = value;
    },
};

const getters = {
    getPrivacyPolicy: state => state.privacyPolicy,
    getForcePrivacyPolicy: state => state.forcePrivacyPolicy,
    getPrivacyPolicyTutorialInfo: state => state.privacyPolicyTutorialInfo,
    videoTutorialsByFilename( state, getters ) {
        return getters.videoTutorials.reduce((acc, tutorial) => {
            acc[tutorial.filename] = tutorial;
            return acc;
        }, {})
    },
    videoTutorials() {
        return [{
            _id: 'maintainer_tutorial_1',
            filename: 'Tutorial_Verwaltung.mp4',
            title: 'Kurzvorstellung Seite 1',
            location: 'verwaltung.plan',
            role: 'maintainer',
        },
        {
            _id: 'maintainer_tutorial_2',
            filename: 'Tutorial_Verwaltung2.mp4',
            title: 'Kurzvorstellung Seite 2',
            location: 'verwaltung.plan',
            role: 'maintainer',
        },
        {
            _id: 'Tutorial_bbb_Lehrer_Verwaltung.mp4',
            filename: 'Tutorial_bbb_Lehrer_Verwaltung.mp4',
            title: 'Kurzvorstellung BBB',
            location: 'verwaltung.bbb',
            role: 'maintainer',
        },
        {
            _id: 'teacher_tutorial_1',
            filename: 'Tutorial_Lehrer.mp4',
            title: 'Kurzvorstellung Seite 1',
            location: 'lehrer.plan',
            role: 'teacher',
        },
        {
            _id: 'teacher_tutorial_2',
            filename: 'Tutorial_Lehrer2.mp4',
            title: 'Kurzvorstellung Seite 2',
            location: 'lehrer.plan',
            role: 'teacher',
        },
        {
            _id: 'pupil_tutorial_normal_1',
            filename: 'Tutorial_Schueler.mp4',
            title: 'Kurzvorstellung Seite 1',
            location: 'schueler.plan',
            role: 'pupil',
        },
        {
            _id: 'pupil_tutorial_normal_2',
            filename: 'Tutorial_Schueler2.mp4',
            title: 'Kurzvorstellung Seite 2',
            location: 'schueler.plan',
            role: 'pupil',
        },
        {
            _id: 'pupil_tutorial_baby_1',
            filename: 'Tutorial_Schueler_einfach.mp4',
            title: 'Kurzvorstellung',
            location: 'baby.plan',
            role: 'baby',
        },
        {
            _id: 'parent_tutorial_1.mp4',
            filename: 'Tutorial_Eltern.mp4',
            title: 'Kurzvorstellung Seite 1',
            location: 'eltern.plan',
            role: 'parent',
        },
        {
            _id: 'parent_tutorial_2.mp4',
            filename: 'Tutorial_Eltern2.mp4',
            title: 'Kurzvorstellung Seite 2',
            location: 'eltern.plan',
            role: 'parent',
        },
        // Not used currently
        // {
        //     _id: 'HA_erstellt_Autokorrektur.mp4',
        //     filename: 'HA_erstellt_Autokorrektur.mp4',
        //     title: 'Der eKlara Editor erklärt',
        //     location: 'lehrer.create',
        //     role: 'teacher',
        // },
        // {
        //     _id: 'Lehrer_bbb_erklaert.mp4',
        //     filename: 'Lehrer_bbb_erklaert.mp4',
        //     title: 'Kurzvorstellung BBB',
        //     location: 'lehrer.bbb',
        //     role: 'teacher',
        // },
        {
            _id: 'Tutorial_bbb_Lehrer_Verwaltung.mp4',
            filename: 'Tutorial_bbb_Lehrer_Verwaltung.mp4',
            title: 'Kurzvorstellung BBB',
            location: 'lehrer.bbb',
            role: 'teacher',
        },
        {
            _id: 'Tutorial_bbb_Eltern_Schueler.mp4',
            filename: 'Tutorial_bbb_Eltern_Schueler.mp4',
            title: 'Kurzvorstellung BBB',
            location: 'schueler.bbb',
            role: 'pupil',
        },
        {
            _id: 'Tutorial_bbb_Eltern_Schueler.mp4',
            filename: 'Tutorial_bbb_Eltern_Schueler.mp4',
            title: 'Kurzvorstellung BBB',
            location: 'baby.bbb',
            role: 'baby',
        }];
    }
};

const actions = {
    async getTutorialProperties({ commit, state }, params) {
        try {
            const videoTutorialFilename = params.videoTutorialFilename;
            delete params.videoTutorialFilename;
            const res = await backend.getVideoTutorialUserProperties(videoTutorialFilename, params);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async updateTutorialProperties({ commit, state }, params) {
        try {
            const videoTutorialFilename = params.videoTutorialFilename;
            delete params.videoTutorialFilename;
            await backend.patchVideoTutorialUserProperties(videoTutorialFilename, params);
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    setPrivacyPolicyState({commit}, state) {
        commit('SET_PRIVACY_POLICY', state);
    },

    setForcePrivacyPolicyState({commit}, state) {
        commit('SET_FORCE_PRIVACY_POLICY', state);
    },

    setPrivacyPolicyTutorialInfo({commit}, state) {
        commit('SET_PRIVACY_POLICY_TUTORIAL_INFO', state);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
