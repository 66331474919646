<template>
    <div
        class="d-flex"
        style="width: 100vw"
    >
        <MessagesWidget
            class="mt-3"
            style="width: calc((100vw - 112px)/3)"
        />

        <brett-widget
            class="mt-3 ml-4"
            style="width: calc((100vw - 112px)/3)"
        />

        <chat-verwaltung
            style="width: calc((100vw - 112px)/3)"
        />
    </div>
</template>

<script>
import ChatVerwaltung from "@/components/Verwaltung/ChatVerwaltung";
import BrettWidget from "@/components/BrettWidget";
import MessagesWidget from "@/components/MessagesWidget";

export default {
    name: "MessagesMaintainer",
    components: {BrettWidget, ChatVerwaltung, MessagesWidget}
}
</script>

<style scoped>

</style>
