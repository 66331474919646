<template>
    <div
        style="background-color: white"
    >
        <v-row
            no-gutters
            :style="{ backgroundColor: 'var(--v-headerblue-base)', height: '65px' }"
            class="mb-1 pa-1"
            align="center"
        >
            <v-col
                :cols="11"
                style="display: flex; justify-content: flex-start; align-items: center"
            >
                <icon-picker
                    v-if="membershipRole === 'moderator'"
                    class="ml-2 mb-4"
                    elevation="0"
                    :current-color="color"
                    :callback="(result) => { color = result; }"
                    :current-icon="icon"
                    :callback-icon="(result) => { icon = result;}"
                    :group-icons="icons"
                    :filter-icon-color-to-grey="true"
                    :color-picker-usage="'pinboardBase'"
                    :background-color="'#343e55'"
                    @savePinboardChanges="savePinboardChanges"
                />
                <img
                    v-else
                    :src="uploadedIcon ? customIcon : chooseIcon(icon)"
                    class="ml-2 mb-4 boardIcon"
                    :class="uploadedIcon ? 'pa-0' : ''"
                    :style="{'background-color': color}"
                    aspect-ratio="1.5"
                    :alt="title"
                >
                <v-text-field
                    v-if="windowWidth > 329 && !invalidInvitation"
                    id="pinboardTitleTextField"
                    v-model="title"
                    :disabled="membershipRole !== 'moderator'"
                    dark
                    height="46"
                    hide-details
                    placeholder="Name der Pinnwand"
                    class="ml-3 mb-5 pinboardTitleTextFieldWidth"
                    style="margin-top: -10px; font-size: 24px; font-weight: bold"
                    @click="selectPinboardTitle"
                    @change="savePinboardChanges"
                >
                    <template
                        v-if="membershipRole === 'moderator'"
                        v-slot:append
                    >
                        <img
                            :src="stiftIconDark"
                            class="icon mr-3 mt-1 iconToWhite"
                            style="cursor: pointer"
                            @click="savePinboardChanges"
                        >
                    </template>
                </v-text-field>
            </v-col>
            <v-col
                :cols="1"
                style="display: flex; justify-content: flex-end; align-items: center"
            >
                <div style="display: flex; flex-wrap: nowrap; align-items: center">
                    <div
                        v-if="membershipRole === 'visitor'"
                        class="d-flex"
                        style="flex-direction: row; margin-bottom: 13px"
                    >
                        <v-tooltip
                            bottom
                            max-width="400px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="windowWidth > 550 ? infoIcon : eyeIcon"
                                    class="iconToWhite ml-1"
                                    style="height: 30px"
                                    :style="windowWidth > 550 ? '' : 'margin-right: 22px'"
                                    alt="Sehen-Modus"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <span>{{ (accountRole === 'pupil' ? 'Du befindest dich' : 'Sie befinden sich') + ' im Sehen-Modus. '
                                + (accountRole === 'pupil' ? 'Kontaktiere' : 'Kontaktieren Sie') + ' einen Moderator, um Rechte zum Bearbeiten von Notizen zu erhalten.' }}</span>
                        </v-tooltip>
                        <p
                            v-if="windowWidth > 550"
                            style="color: white; font-size: large; margin-top: 3px; min-width: 138px"
                        >
                            Sehen-Modus
                        </p>
                    </div>
                    <!-- <v-btn
                        class="mr-4 mb-4 toolSelector"
                        :style="windowWidth < 600 ? '' : 'min-width: 110px'"
                        min-width="35"
                        elevation="0"
                        style="background-color: transparent; border-radius: 8px;"
                        @click="openEditMode"
                    >
                       
                        <v-icon color="white">
                            mdi-cursor-move
                        </v-icon>
                        <p
                            v-if="windowWidth >= 600"
                            class="text-capitalize ml-1"
                            style="color: white; font-size: large;"
                        >
                            Bewegen
                        </p>
                    </v-btn> -->
                    <v-btn
                        v-if="membershipRole === 'moderator'"
                        class="mr-4 mb-4 toolSelector"
                        :style="windowWidth < 600 ? '' : 'min-width: 110px'"
                        min-width="35"
                        elevation="0"
                        style="background-color: transparent; border-radius: 8px;"
                        @click="openParticipantsDialog"
                    >
                        <img
                            style="min-width: 34px"
                            alt="Nutzerverwaltung"
                            :src="groupIcon"
                        >
                        <p
                            v-if="windowWidth >= 600"
                            class="text-capitalize ml-1"
                            style="color: white; font-size: large;"
                        >
                            Teilen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="!invalidInvitation"
                        class="mr-2 mb-4 toolSelector"
                        color="gruen"
                        min-width="90px"
                        elevation="0"
                        style="border-radius: 8px;"
                        @click="close"
                    >
                        <p
                            class="text-capitalize"
                            style="font-size: large; color: white; margin-left: 4px"
                        >
                            Fertig
                        </p>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <div
            class="scrollContainer mt-3"
        >
            <div
                style="display: flex; flex-direction: row"
                :style="windowWidth >= 600 ? 'margin-left: 6px' : 'margin-left: 4%'"
            >
                <PinboardCardsColumn
                    v-for="(card, index) in sortedTitleCards"
                    :key="index"
                    :ref="card._id"
                    :all-cards="getAllCardsFromTitleCard(card.position_x)"
                    :whole-pinboard="allCards"
                    :card="card"
                    :column-color="card.color"
                    :position_x="index"
                    :pinboard="pinboard"
                    :membership-role="membershipRole"
                    :update-card="updateCard"
                    :create-content-card="createContentCard"
                    :are-you-sure-card="areYouSureCard"
                    @areYouSureColumn="areYouSureColumn"
                    @editCardColumnTitle="editCardColumnTitle"
                    @changeColumnColor="changeColumnColor"
                    @pinboardChange="updatePinboardHasChanged"
                />
                <PinboardCardTitleElementPlus
                    v-if="membershipRole !== 'visitor' && !invalidInvitation"
                    ref="titleElementPlus"
                    @addNewCard="addNewCard"
                />
            </div>
        </div>
        <PinboardParticipantsDialog
            v-if="showParticipantsDialog"
            :edit-id="id"
            :edit-mode="true"
            :item="pinboard"
            :memberships="pinboard.memberships"
            :load-pinboard="loadPinboard"
            :pinboard-base="true"
            @close="closeParticipantsDialog"
        />
        <AreYouSurePopup
            v-if="showAreYouSure"
            :show-dialog="showAreYouSure"
            ok-btn-color="frot"
            :header-color="'var(--v-headerblue-base)'"
            :content-text-prop="areYouSureMsg"
            @close="showAreYouSure = false"
            @ok="deleteItem"
        />
        <v-overlay
            :value="invalidInvitation"
        >
            <div
                class="d-flex flex-column"
                style="justify-items: center; align-items: center"
            >
                <p
                    style="font-size: x-large"
                >
                    Der Einladungslink ist ungütltig oder abgelaufen!
                </p>
                <v-btn
                    class="mr-2 mb-4 toolSelector"
                    color="gruen"
                    min-width="90px"
                    elevation="0"
                    style="border-radius: 8px;"
                    @click="close"
                >
                    <p
                        class="text-capitalize"
                        style="font-size: large; color: white; margin-left: 4px"
                    >
                        Zurück
                    </p>
                </v-btn>
            </div>
        </v-overlay>
        <v-card
            v-if="dragMode || dragColumnMode"
            style="background-color: #53a653; max-width: 500px; position: absolute;left: 0px;right: 0px;bottom: 8%;margin-inline: auto;"
        >
            <p
                style="color: white; padding: 0.75rem;"
            >
                {{ snackbarText }}
            </p>
        </v-card>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from "../../api/backend";

import stiftIconDark from "../../assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import PinboardCardTitleElementPlus from "./PinboardCardElements/PinboardCardTitleElementPlus.vue";
import PinboardCardsColumn from "./PinboardCardElements/PinboardCardsColumn.vue";
import PinboardParticipantsDialog from "./PinboardParticipantsDialog.vue";
import IconPicker from "../Utils/IconPicker.vue";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

import groupIcon from '@/assets/Icons/group.svg';
import erdkundeIconWeiss from '../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIconWeiss from '../../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIconWeiss from '../../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIconWeiss from '../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIconWeiss from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import pinIconWeiss from "../../assets/Icons/FaceliftIcons/pin_alt_weiss.svg";
import chemieIconWeiss from '../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg';
import deutschIconWeiss from '../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg';
import geschichteIconWeiss from '../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg';
import matheIconWeiss from '../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg';
import musikIconWeiss from '../../assets/Icons/FaceliftIcons/f-musik-weiss.svg';
import physikIconWeiss from '../../assets/Icons/FaceliftIcons/f-physik-weiss.svg';
import sportIconWeiss from '../../assets/Icons/FaceliftIcons/f-sport-weiss.svg';
import wirtschaftIconWeiss from '../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg';
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import eyeIcon from '@/assets/Icons/eye.svg';
import EventBus from "../../util/eventBus";


export default {
    name: 'PinboardBase',
    components: {
        PinboardCardTitleElementPlus,
        PinboardCardsColumn,
        IconPicker,
        PinboardParticipantsDialog,
        AreYouSurePopup
    },
    data: () => ({
        // Icons
        stiftIconDark,
        groupIcon,
        infoIcon,
        eyeIcon,
        icons:[
            {icon: pinIconWeiss, text: "pinIconWeiss"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIconWeiss, text: "politikIcon"},
            {icon: erdkundeIconWeiss, text: "erdkundeIcon"},
            {icon: religionIconWeiss, text: "religionIcon"},
            {icon: bioIconWeiss, text: "bioIcon"},
            {icon: chemieIconWeiss, text: "chemieIcon"},
            {icon: deutschIconWeiss, text: "deutschIcon"},
            {icon: geschichteIconWeiss, text: "geschichteIcon"},
            {icon: matheIconWeiss, text: "matheIcon"},
            {icon: musikIconWeiss, text: "musikIcon"},
            {icon: kunstIconWeiss, text: "kunstIcon"},
            {icon: physikIconWeiss, text: "physikIcon"},
            {icon: sportIconWeiss, text: "sportIcon"},
            {icon: wirtschaftIconWeiss, text: "wirtschaftIcon"},
        ],

        // Pinboard card data
        snackbarVisible: false,
        membershipRole: '',
        allCards: [],
        titleCards: [],
        pinboard: '',
        account: {},
        areYouSureMsg: '',
        itemsToDelete: null,
        deleteColumn: false,
        invalidInvitation: false,

        // Query parameters
        title: '',
        id: '',
        color: '',
        icon: '',
        uploadedIcon: '',
        customIcon: '',
        invitationCode: '',

        // Dialog booleans
        showParticipantsDialog: false,
        showAreYouSure: false,
        pinboardHasChanged: false,
        pinboardNotificationSent: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight', 'accountId']),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('pinboard',['dragMode','dragColumnMode']),

        sortedTitleCards(){
            return this.titleCards.slice().sort((a, b) => a.position_x - b.position_x);
        },
        snackbarText(){
            return this.dragMode ? 'Verschieben aktiviert! Klicken sie auf eine Karte um sie auszuwählen und innerhalb der Spalten zu verschieben.' 
            : this.dragColumnMode ? 'Verschieben aktiviert! Klicken sie auf eine Titel-Karte um sie auszuwählen und die gesamte Spalte zu verschieben.' 
            : ''
        }
    },
   
    async mounted() {
        this.account = await this.getCurrentAccount();

        if (this.$route.query.title) {
            this.title = this.$route.query.title;
        }
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
        }
        if (this.$route.query.color) {
            this.color = this.$route.query.color;
        }
        if (this.$route.query.icon) {
            this.icon = this.$route.query.icon;
        }
        if (this.$route.query.uploadedIcon) {
            this.uploadedIcon = this.$route.query.uploadedIcon;
        }
        if (this.$route.params.invitationCode) {
            this.invitationCode = this.$route.params.invitationCode;
        }
        if (this.uploadedIcon) {
            this.customIcon = await this.getUploadedIcon(this.icon);
        }
        await this.loadPinboard();
        this.showMobileSnackbar();
        EventBus.$off('new-thumbnail-pinboard');
        EventBus.$on('new-thumbnail-pinboard', async(fileId) => {
            let foundCard, foundFile;
            this.allCards.find((card) => {
               if (!card.files || !card.files.length) {
                   return false;
               }
               card.files.map((currentFile) => {
                  if (currentFile._id === fileId) {
                      foundCard = card;
                      foundFile = currentFile;
                      return true;
                  }
                  return false;
               });
            });
            if (!foundCard || !foundFile) {
                return;
            }
            await this.loadFilePreview(foundFile, foundCard._id);
            const titleCard = this.sortedTitleCards.find((titleCard) => titleCard.position_x === foundCard.position_x);
            const pinboardCardCollumn = this.$refs[titleCard._id][0]
            const card = pinboardCardCollumn.$refs[foundCard._id][0];
            card.forceRerender++;
        });
        EventBus.$off('pinboard-card-new');
        EventBus.$on('pinboard-card-new', (body) => {
            const { createdPinboardCard, senderId } = body;
            if (createdPinboardCard.pinboard !== this.id) {
                return;
            }
            if (!this.allCards) {
                return;
            }
            this.allCards.push(createdPinboardCard);
            if (createdPinboardCard.isTitleCard) {
                this.titleCards.push(createdPinboardCard);
            }
        });
        EventBus.$off('pinboard-card-delete')
        EventBus.$on('pinboard-card-delete', (body) => {
            const { cardId, isTitleCard, pinboard } = body;
            if (pinboard !== this.id) {
                return;
            }
            this.allCards = this.allCards.filter((card) => card._id !== cardId);
            if (isTitleCard) {
                this.titleCards = this.sortedTitleCards.filter((card) => card._id !== cardId);
            }
        });
        EventBus.$off('pinboard-card-patch');
        EventBus.$on('pinboard-card-patch',async (body) => {
            const { pinboard, patchedPinboardCard } = body;
            if (pinboard !== this.id) {
                return;
            }
            this.allCards = this.allCards.map((card) => card._id === patchedPinboardCard._id ? patchedPinboardCard : card);
            if (patchedPinboardCard.isTitleCard) {
                this.titleCards = this.sortedTitleCards.map((card) => card._id === patchedPinboardCard._id ? patchedPinboardCard : card);
            } else {
                this.allCards = this.allCards.map((card) => {
                    if (card._id === patchedPinboardCard._id) {
                        card = patchedPinboardCard;
                    }
                    return card;
                });
            }
        });
        EventBus.$on('pinboard-card-upload',async (body) => {
            const { pinboard, cardId } = body;
            let { createdFiles } = body;
            if (pinboard !== this.id) {
                return;
            }
            this.allCards = this.allCards.map((card) => {
                if (card._id === cardId) {
                    createdFiles.forEach((file) => card.files.push(file));
                }
                return card;
            });
        });
    },
    methods: {
        ...mapActions('pinboard', ['getPinboard', 'getInvitationPinboard', 'postCard', 'patchPinboardInfo', 'patchCard',
            'deleteCard', 'postPinboardCardUpload', 'getPinboardCardUpload', 'deletePinboardCardUpload',
            'getPinboardUploadThumbnail', 'clearThumbnails', 'sendPinboardNotification','toggleDragMode']),
        ...mapActions('auth', ['getCurrentAccount', 'logoutUser', 'getSecurityQuestions']),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations('pinboard', ['removeConverterThumbnail', 'pushConverterThumbnail',]),

        async loadFilePreview(file, cardId) {
            if (file.hasThumbnail) {
                return;
            }
            if (file.mimetype.includes('video') || file.mimetype.includes('gif')) {
                const res = await this.getPinboardCardUpload({
                    cardId,
                    fileId: file._id
                });
                file.hasThumbnail = true;
                file.thumbnail = res;
                return file;
            }
            const res = await this.getPinboardUploadThumbnail({
                fileId: file._id,
                pinboardCardId: cardId,
                pinboardId: this.pinboard._id,
            });
            file.hasThumbnail = res.hasThumbnail;
            file.thumbnail = res.url;
            return file;
        },
        async loadFilePreviews(cardId, files) {
            return await Promise.all(files.map(async (file) => {
                if (file.mimetype.split('/')[0] === 'video') {
                    file.thumbnail = await this.getPinboardCardUpload({
                        cardId,
                        fileId: file._id
                    });
                } else {
                    const res = await this.getPinboardUploadThumbnail({
                        fileId: file._id,
                        pinboardCardId: cardId,
                        pinboardId: this.pinboard._id
                    });
                    file.hasThumbnail = res.hasThumbnail;
                    file.thumbnail = res.url;
                }
                return file;
            }));
        },

        getAllCardsFromTitleCard(xPos) {
            return this.allCards.filter(card => card.position_x === xPos && !card.isTitleCard);
        },
        //Loads current Pinboard
        async loadPinboard() {
            if (this.id) {
                this.pinboard = await this.getPinboard(this.id);
            } else if (this.invitationCode) {
                const response = await this.getInvitationPinboard(this.invitationCode);
                if (response === 401 || response === 404) {
                    this.invalidInvitation = true;
                }
                this.pinboard = response;
                this.title = this.pinboard.title;
                this.id = this.pinboard._id;
                this.color = this.pinboard.color;
                this.icon = this.pinboard.icon;
                this.uploadedIcon = this.pinboard.uploadedIcon;
                if (this.uploadedIcon) {
                    this.customIcon = await this.getUploadedIcon(this.icon);
                }
            }
            this.allCards = this.pinboard.pinboardCards;
            this.filterCards();
            this.pinboardMembershipRole();
        },

        filterCards() {
            var columnNumber = [];
            this.titleCards = [];
            for (let i = 0; i < this.allCards.length; i++) {
                columnNumber.push(this.allCards[i].position_x);
            }
            const highestColumn = Math.max(...columnNumber);
            // Filters out all the titleCards
            for (let i = 0; i <= highestColumn; i++) {
                this.titleCards = this.allCards.filter(card => card.isTitleCard);
            }
        },

        async editCardColumnTitle(cardTitle, titlePosition_x, index, cardId, color) {
            const newCard = await this.patchCard({ cardId, body: {
                    content: cardTitle,
                    position_x: titlePosition_x,
                    position_y: index,
                    pinboard: this.id,
                    isTitleCard: true,
                    color: color,
                } });
            this.pinboardHasChanged = true;
            for (let i = 0; i < this.allCards.length; i++) {
                if (this.allCards[i]._id == cardId) {
                    this.allCards[i] = newCard;
                }
            }
        },

        async savePinboardChanges() {
            await this.patchPinboardInfo({ pinboardId: this.id, body: {
                    title: this.title,
                    color: this.color,
                    icon: this.icon.toString(),
                    uploadedIcon: !this.icon.includes('Icon'),
                } });
                this.pinboardHasChanged = true;
        },

        async selectPinboardTitle() {
            await this.$nextTick();
            document.getElementById('pinboardTitleTextField').select();
        },

        getReturnPage() {
            switch (this.account.role) {
                case 'teacher':
                    return 'lehrer.plan';
                case 'pupil':
                    return 'schueler.plan';
                case 'maintainer':
                    return 'verwaltung.plan';
                case 'parent':
                    return 'eltern.plan';
                default:
                    console.warn('No account role found!');
                    return 'login';
            }
        },

        async close() {
            let page = 1;
            if (this.windowWidth <= 900) {
                if (this.account.role === 'teacher' || this.account.role === 'maintainer') {
                    page = 5;
                } else {
                    page = 4;
                }
            }
            this.$router.push({
                name: this.getReturnPage(),
                query: {page}
            })
            this.clearThumbnails();
            if(this.pinboardHasChanged){
                const pinboard = await backend.postPinboardLastEdited(this.id);
            }
            if(this.pinboardHasChanged && !this.pinboardNotificationSent){
                await this.sendPinboardNotification(this.id);
                 this.pinboardNotificationSent = true;
            }
        },

        async createDefaultCard() {
            let data = {
                content: 'Titel',
                position_x: this.titleCards.length,
                position_y: 0,
                pinboard: this.id,
                isTitleCard: true,
                color: '#3cb371',
            }
            await this.postCard(data);
            let contentData = {
                content: '',
                position_x: this.titleCards.length -1,
                position_y: 1,
                pinboard: this.id,
                isTitleCard: false,
                color: '#3cb371',
            }
            await this.postCard(contentData);
            this.pinboardHasChanged = true;
            this.$refs.titleElementPlus.$el.scrollIntoView();
        },

        async createContentCard(content, position_x, position_y, color) {
            let data = {
                content: content,
                position_x: position_x,
                position_y: position_y,
                color: color,
                pinboard: this.id,
                isTitleCard: false,
            }
            await this.postCard(data);
            this.pinboardHasChanged = true;
        },

        async addNewCard() {
            await this.createDefaultCard();
        },

        openParticipantsDialog() {
            this.showParticipantsDialog = !this.showParticipantsDialog;
        },

        async updateCard(content, position_x, position_y, cardId, filesToUpload, color) {
            this.pinboardHasChanged = true;
            if(color) {
                var existingColor = color;
            }
            await this.patchCard({ cardId, body: {
                    content: content,
                    position_x: position_x,
                    position_y: position_y,
                    pinboard: this.id,
                    isTitleCard: false,
                    color: existingColor,
                } });
            if(!this.pinboardNotificationSent && this.pinboardHasChanged){
                this.pinboardNotificationSent = true;
                await this.sendPinboardNotification(this.id);
            }
            if(filesToUpload !== 'no files') {
                const files = filesToUpload;

                await Promise.all(files.map(async (file) => {
                    await this.postPinboardCardUpload( { cardId, file } );
                }))
            }
        },


        async deleteSingleCard() {
            for (let i = 0; i < this.itemsToDelete.files.length; i++) {
                await this.deletePinboardCardUpload( { cardId: this.itemsToDelete._id, fileId: this.itemsToDelete.files[i]._id } );
            }
            await this.deleteCard(this.itemsToDelete._id);
            this.allCards = this.allCards.filter((card) => {
                return card._id !== this.itemsToDelete._id;
            })
            this.pinboardHasChanged = true;
        },

        // TODO: Find better way to delete many cards
        async deleteWholeCard() {
            for (let i = 0; i < this.itemsToDelete.length; i++) {
                for (let j = 0; j < this.itemsToDelete[i].files.length; j++) {
                    await this.deletePinboardCardUpload( { cardId: this.itemsToDelete[i]._id, fileId: this.itemsToDelete[i].files[j]._id } );
                }
                await this.deleteCard(this.itemsToDelete[i]._id);
                this.allCards = this.allCards.filter((card) => {
                    return card._id !== this.itemsToDelete[i]._id;
                })
                this.titleCards = this.sortedTitleCards.filter((card) => {
                    return card._id !== this.itemsToDelete[i]._id;
                })
            }
            this.pinboardHasChanged = true;
            //Now move all the x-Positions after the deleted one -1
            try {
                const titleCards = this.sortedTitleCards;

                for (var i = 0; i < titleCards.length; i++) {
                    const crtTitleCard = titleCards[i];

                    //Is the current collumns xPosition before or behind the deleted one?
                    if (crtTitleCard.position_x <= this.itemsToDelete[0].position_x) {

                        //Yes => skip this iteration
                        continue;
                    }
                    //No => keep executing

                    const cards = this.getAllCardsFromTitleCard(crtTitleCard.position_x);

                    //Change the x-Position to current x-Position -1
                    for (var j = 0; j < cards.length; j++) {
                        const crtCard = cards[j];
                        if (!crtCard) {
                            continue;
                        }
                        const newCard = await this.patchCard({ cardId: crtCard._id, body: {
                                content: crtCard.content,
                                position_x: crtCard.position_x - 1,
                                position_y: crtCard.position_y,
                                pinboard: this.id,
                                isTitleCard: false,
                                color: crtCard.color,
                            } });
                            this.pinboardHasChanged = true;
                        for (let i = 0; i < this.allCards.length; i++) {
                            if (this.allCards[i]._id === crtCard._id) {
                                this.allCards[i] = newCard;
                            }
                        }
                    }
                    const newCard = await this.patchCard({ cardId: crtTitleCard._id, body: {
                            content: crtTitleCard.content,
                            position_x: crtTitleCard.position_x - 1,
                            position_y: crtTitleCard.position_y,
                            pinboard: this.id,
                            isTitleCard: true,
                            color: crtTitleCard.color,
                        } })
                        this.pinboardHasChanged = true;
                    for (let i = 0; i < this.allCards.length; i++) {
                        if (this.allCards[i]._id === crtTitleCard._id) {
                            this.allCards[i] = newCard;
                        }
                    }
                }
                // TODO: Get rid of this loadPinboard
                await this.loadPinboard();
                this.showSnackbar({
                    message: 'Spalte erfolgreich gelöscht!',
                    color: 'success',
                })
            } catch (err) {
                this.showSnackbar({
                    message: 'Unerwarteter Fehler beim Löschen der Reihe!',
                    color: 'error',
                })
            }
        },
        updatePinboardHasChanged(){
            this.pinboardHasChanged = true;
        },
        async changeColumnColor(cardsToChangeColor, color) {
            for (let i = 0; i < cardsToChangeColor.length; i++) {
                await this.patchCard({ cardId: cardsToChangeColor[i]._id, body: {
                        content: cardsToChangeColor[i].content,
                        position_x: cardsToChangeColor[i].position_x,
                        position_y: cardsToChangeColor[i].position_y,
                        pinboard: this.id,
                        isTitleCard: cardsToChangeColor[i].isTitleCard,
                        color: color,
                    }
                });
                this.pinboardHasChanged = true;
                const cardToUpdate = this.allCards.find((card) => card._id === cardsToChangeColor[i]._id);
                if (cardToUpdate) {
                    cardToUpdate.color = color;
                }
            }
            this.filterCards();
        },

        pinboardMembershipRole() {
            const memberships = this.pinboard.memberships.filter((membership) =>
                membership.accountSet.addressedAccounts.find(
                    (addressedAccount) => addressedAccount === this.account._id
                )
            );
            this.membershipRole = memberships[0].role;
        },

        chooseIcon(iconName) {
            for (let i of this.icons) {
                if (i.text === iconName) {
                    return i.icon;
                }
            }
        },

        areYouSureCard(deletedCard) {
            this.itemsToDelete = deletedCard;
            this.areYouSureMsg = 'Diese Notiz und alle darin enthaltenen Texte und Medien werden gelöscht!'
            this.deleteColumn = false;
            this.showAreYouSure = true;
        },

        areYouSureColumn(cardsToDelete) {
            this.itemsToDelete = cardsToDelete;
            this.areYouSureMsg = 'Die ganze Spalte mit all seinen Notizen und den darin enthaltenen Texten und Medien werden gelöscht!'
            this.deleteColumn = true;
            this.showAreYouSure = true;
        },

        deleteItem() {
            this.showAreYouSure = false;
            this.deleteColumn ? this.deleteWholeCard() : this.deleteSingleCard();
        },

        showMobileSnackbar() {
            if (this.windowWidth < 600) {
                this.showSnackbar({
                    message: (this.accountRole === 'pupil' ? 'Wische' : 'Wischen Sie') + ' nach rechts oder links,' +
                        ' um weitere Notizen einzusehen!',
                    color: 'info',
                    timeout: 3000
                });
            }
        },

        async closeParticipantsDialog(loadPinboard) {
            this.showParticipantsDialog = false;
            if (loadPinboard) {
                await this.loadPinboard();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }

        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before, .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}

.boardIcon {
    height: 50px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}

.toolSelector {
    background-color: #f8f8f880;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;

        img {
            width: 30px !important;
            height: 30px !important;
        }
    }
}

.pinboardCardTitle {
    background-color: seagreen;
    color: whitesmoke;
    font-size: 30px;
    width: 100%;
}

.pinboardCardBody {
    background-color: mediumseagreen;
    color: whitesmoke;
    font-size: 30px;
    width: 100%;
}

.scroll-area {
    flex: 1;
}

.icon {
    height: 27px;
}

.menuItemContainer {
    height: 45px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    background-color: var(--v-hellgrau-base);
    border-radius: 0.5em;
}

//transparent scrollbar track
::-webkit-scrollbar-track {
    background: transparent;
}

.scrollContainer {
    //necessary to set a maximum height to enable scrolling (can also just be height)
    // height: auto;
    max-height: calc(100vh - 75px);
    overflow-y: hidden;
}

.pinboardTitleTextFieldWidth {
    max-width: 400px;
}

@media only screen and (max-width: 745px) {
    .pinboardTitleTextFieldWidth {
        max-width: 300px;
    }
}

@media only screen and (max-width: 640px) {
    .pinboardTitleTextFieldWidth {
        max-width: 200px;
    }
}

@media only screen and (max-width: 475px) {
    .pinboardTitleTextFieldWidth {
        max-width: 100px;
    }
}

</style>
