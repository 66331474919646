const defaultState = {
    babyFilesToDoBadge: '',
    babyFilesCorrectedBadge: '',
    sickNoteBadge: '',
    parentalLetterBadge: '',
    blackboardBadge: '',
    timetableBadge: '',
    chatBadge: '',
    showCorrectionBadge: true,
    fileWidgetBadge: '',
    appBadge: '',
    maintainerParentalLetterBadge: '',
};

const mutations = {
    setBabyFilesToDoBadge: (state, badge) => {
        state.babyFilesToDoBadge = badge;
    },
    setBabyFilesCorrectedBadge: (state, badge) => {
        state.babyFilesCorrectedBadge = badge;
    },
    setSickNoteBadge: (state, badge) => {
        state.sickNoteBadge = badge;
    },
    setParentalLetterBadge: (state, badge) => {
        state.parentalLetterBadge = badge;
    },
    setBlackboardBadge: (state, badge) => {
        state.blackboardBadge = badge;
    },
    setTimetableBadge: (state, badge) => {
      state.timetableBadge = badge;
    },
    setChatBadge: (state, badge) => {
        state.chatBadge = badge;
    },
    setShowCorrectionBadge: (state, bool) => {
        state.showCorrectionBadge = bool;
    },
    setFileWidgetBadge: (state, badge) => {
        state.fileWidgetBadge = badge;
    },
    setAppBadge: (state, badge) => {
        state.appBadge = badge;
    },
    setMaintainerParentalLetterBadge: (state, badge) => {
        state.maintainerParentalLetterBadge = badge;
    }
};

const getters = {
    babyFilesToDoBadge: state => state.babyFilesToDoBadge,
    babyFilesCorrectedBadge: state => state.babyFilesCorrectedBadge,
    sickNoteBadge: state => state.sickNoteBadge,
    parentalLetterBadge: state => state.parentalLetterBadge,
    blackboardBadge: state => state.blackboardBadge,
    showCorrectionBadge: state => state.showCorrectionBadge,
    chatBadge: state => state.chatBadge,
    fileWidgetBadge: state => state.fileWidgetBadge,
    appBadge: state => state.appBadge,
    maintainerParentalLetterBadge: state => state.maintainerParentalLetterBadge,
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters
};
