import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import Vue from "vue";

const defaultState = {
    maintainers: [],
    profilePictures: [],
    meMaintainer: null,
};

const mutations = {
    setMaintainers: (state, list) => {
        state.maintainers = list;
    },
    updateMaintainer: (state, maintainer) => {
        const index = state.maintainers.findIndex(el => {
            return  el._id === maintainer._id;
        })

        if (index >= 0){
            Vue.set(state.maintainers, index, maintainer);

        }else{
            state.maintainers.push(maintainer);
        }

    },
    pushProfilePicture: (state, { maintainerId, url } ) => {
        state.profilePictures.push({ _id: maintainerId, url });
    },
    removeProfilePicture: (state, maintainerId) => {
        state.profilePictures.forEach((picture) => {
            if (maintainerId === picture._id) {
                window.URL.revokeObjectURL(picture.url);
            }
        })
        state.profilePictures = state.profilePictures.filter((picture) => {
            return picture._id !== maintainerId;
        })
    },
    setMeMaintainer: (state, meMaintainer) => {
        state.meMaintainer = meMaintainer;
    },
};

const getters = {
    maintainers: state => state.maintainers,
    profilePictures: state => state.profilePictures,
    profilePicturesById: state => state.profilePictures.reduce((acc, picture) => {
        acc[picture._id] = picture.url;
        return acc;
    }, {}),
    hideBadges: state => state.meMaintainer ? state.meMaintainer.hideBadges : false,
}

const actions = {
    async getMaintainers ({ commit, dispatch, getters }, update = false) {
        try {
            if (getters.maintainers && getters.maintainers.length > 0 && !update) {
                return getters.maintainers;
            }

            const res = await backend.getMaintainers();
            await checkResponseStatus(200, res);
            const maintainers = await res.json();
            commit('setMaintainers', maintainers);
            return maintainers;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMeMaintainer ({ commit, dispatch, getters }, update = false) {
        try {
            if (getters.meMaintainer && !update) {
                return getters.meMaintainer;
            }
            const res = await backend.getMeMaintainer();
            await checkResponseStatus(200, res);
            const meMaintainer = await res.json();
            commit('setMeMaintainer', meMaintainer);
            return meMaintainer;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async confirmMaintainerPassword({ commit, dispatch }, params) {
        try {
            delete params.maintainerId;
            const res = await backend.confirmMaintainerPassword(params);
            await checkResponseStatus(200, res);
            return res;
        } catch(err) {
            console.warn(err);
            return err.response;
        }
    },

    async changeSchoolYear({ commit, dispatch }, params) {
        try {
            const res = await backend.createNewSchoolYear(params);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.warn(err);
            return err.response;
        }
    },

    async getMaintainerProfilePicture ({ commit, dispatch, getters }, maintainerId) {
        try {
            if (getters.profilePicturesById[maintainerId]) {
                return getters.profilePicturesById[maintainerId];
            }
            const res = await backend.getMaintainerProfilePicture(maintainerId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                commit('pushProfilePicture', {maintainerId, url});
                return url;
            }
            return null;
        }
        catch (err) {
            console.error(err);
            return null;
            // return err?.response?.status;
        }
    },

    async deleteMaintainerProfilePicture({ commit, dispatch}, maintainerId) {
        try {
            const res = await backend.deleteMaintainerProfilePicture(maintainerId);
            await checkResponseStatus(204, res);
            return res.status;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async confirmMaintainerInitialization({commit, dispatch}, maintainerId){
        try {
            const res = await backend.maintainerFirstLogin(maintainerId);
            await checkResponseStatus(201, res);
            const maintainer = await res.json();
            commit('updateMaintainer',maintainer);
            return res.status;
        }
        catch (err){
            console.error(err);
                return err.response?.status;
            }
    },
    async editMaintainer({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchMaintainer(id, params);
            if (res.status === 409) {
                return 409;
            }
            dispatch('getMeMaintainer',true);

            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
