<template>
    <v-card style="border-radius: 16px">
        <v-card-title
            style="background-color: #467abb"
            class="pa-2 px-2 pl-4"
        >
            <p style="color: white">
                Neues Fach anlegen
            </p>

            <v-spacer />

            <v-btn
                icon
                @click="()=> { $emit('stopEvent'); resetData(); }"
            >
                <img
                    :src="cancelIcon"
                    class="icon20 iconToWhite"
                    alt="schließen"
                >
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div class="d-flex justify-center align-center">
                <icon-picker
                    :current-color="color"
                    :callback="(result) => { color = result; validateInput(); }"
                    :current-icon="icon"
                    :callback-icon="(result) => { icon = result;}"
                    :group-icons="groupIcons"
                    :color-picker-usage="'fachErstellen'"
                />

                <v-text-field
                    v-model="fachTitel"
                    class="ml-2"
                    label="Fach benennen"
                    filled
                    dense
                    outlined
                    hide-details
                    style="border-radius: 10px"
                    type="text"
                    @change="validateInput"
                />

                <div class="d-flex align-center justify-center ml-3">
                    <v-checkbox
                        v-model="isSport"
                        rounded
                        small
                        light
                        class="mt-4"
                    />
                    <p style="font-size: 16px;">
                        Schulsport
                    </p>
                </div>
            </div>
            <div v-if="!teacherCreation">
                <v-text-field
                    v-model="search"
                    single-line
                    hide-details
                    type="text"
                    label="Lehrer zuordnen"
                >
                    <template v-slot:prepend-inner>
                        <img
                            :src="magnifierIcon"
                            class="mt-1 icon20"
                            alt="Lehrer"
                        >
                    </template>
                </v-text-field>

                <v-data-table
                    v-model="selected"
                    class="scrollable"
                    :headers="headers"
                    :items="members"
                    :search="search"
                    item-key="_id"
                    disable-pagination
                    hide-default-footer
                    hide-default-header
                    show-select
                    locale="de-DE"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                    no-data-text="Keine Daten verfügbar"
                    sort-by="name"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.account ? item.name + ' ' + item.lastName : item.name }}
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                class="saveButton text-none"
                color="success"
                elevation="0"
                :loading="loadingSave"
                @click="saveSubject"
            >
                Speichern
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import magnifierIcon from '@/assets/Icons/redesignV3/magnifier.svg';

import bioIcon from '../../assets/Icons/FaceliftIcons/f-biologie.svg'
import chemieIcon from '../../assets/Icons/FaceliftIcons/f-chemie.svg'
import deutschIcon from '../../assets/Icons/FaceliftIcons/f-deutsch.svg'
import englischIcon from '../../assets/Icons/FaceliftIcons/f-englisch.svg'
import erdkundeIcon from '../../assets/Icons/FaceliftIcons/f-erdkunde.svg'
import geschichteIcon from '../../assets/Icons/FaceliftIcons/f-geschichte.svg'
import matheIcon from '../../assets/Icons/FaceliftIcons/f-mathe.svg'
import musikIcon from '../../assets/Icons/FaceliftIcons/f-musik.svg'
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst.svg';
import physikIcon from '../../assets/Icons/FaceliftIcons/f-physik.svg'
import politikIcon from '../../assets/Icons/FaceliftIcons/f-politik.svg'
import religionIcon from '../../assets/Icons/FaceliftIcons/f-religion.svg'
import sportIcon from '../../assets/Icons/FaceliftIcons/f-sport.svg'
import wirtschaftIcon from '../../assets/Icons/FaceliftIcons/f-wirtschaft.svg'

// Import the styles too, globally
import IconPicker from "@/components/Utils/IconPicker";

export default {
    name: "FachErstellen",
    components: {
        IconPicker,
    },
    props: {
        addNewSubject: {type: Function},
        teacherCreation: {type: Boolean, default: false}
    },
    data: () => ({
        cancelIcon,
        magnifierIcon,

        showLoadingOverlay: false,
        loadingSave: false,
        selected: [],
        search:"",

        icon: 'deutschIcon',
        fachTitel: '',
        color: '#fcd20a',

        groupIcons: [
            { icon: bioIcon, text: "biologieIcon" },
            { icon: chemieIcon, text: "chemieIcon" },
            { icon: deutschIcon, text: "deutschIcon" },
            { icon: englischIcon, text: "englischIcon" },
            { icon: erdkundeIcon, text: "erdkundeIcon" },
            { icon: geschichteIcon, text: "geschichteIcon" },
            { icon: matheIcon, text: "matheIcon" },
            { icon: musikIcon, text: "musikIcon" },
            { icon: kunstIcon, text: "kunstIcon"},
            { icon: physikIcon, text: "physikIcon" },
            { icon: politikIcon, text: "politikIcon" },
            { icon: religionIcon, text: "religionIcon" },
            { icon: sportIcon, text: "sportIcon" },
            { icon: wirtschaftIcon, text: "wirtschaftIcon" },
        ],

        fields: {
            name: false,
            color: false,
            teacher: false,
        },

        height: 0,

        members: [],
        subjects: [],

        headers: [
            { text: 'Lehrer', value: 'name' },
        ],

        isSport: false,
    }),
    computed: {
        ...mapGetters('teachers', [ 'getTeacherStatus' ]),
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
    },
    watch: {
        getTeacherStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestTeachers();
            }
        },
        getSubjectInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSubjects();
            }
        },
    },
    async created() {
        this.height = window.innerHeight * 0.4
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.requestSubjects();
        this.requestTeachers();
    },
    methods: {
        ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
        ...mapActions("subjects", ["getSubjects","createSubject", "editSubject", "deleteSubject", 'setSubjectListStatus']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async requestTeachers() {
            this.showLoadingOverlay = true;
            this.members = await this.getTeachers();
            this.showLoadingOverlay = false;
            this.setTeachersInvalidStatus(false);
        },
        async requestSubjects() {
            this.subjects = await this.getSubjects();
            this.setSubjectListStatus(false);
        },

        validateInput() {
            let result = true;

            this.fields.name = false;
            this.fields.color = false;

            if (this.fachTitel.replace(/\s/g, '') === '') {
                this.fields.name = true;
                result = false;
            }

            if (this.color.replace(/\s/g, '') === '') {
                this.fields.color = true;
                result = false;
            }

            return result;
        },
        resetData() {
            this.fachTitel = "";
            this.selected = [];
        },

        async saveSubject() {
            try {
                this.loadingSave = true;
                this.showLoadingOverlay = true;

                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }

                let sendTeachers = [];
                this.selected.forEach((el) => {
                    sendTeachers.push(el._id)
                });

                let data = {
                    name: this.fachTitel,
                    color: this.color,
                    icon: this.icon.toString(),
                    teachers: sendTeachers,
                    uploadedIcon: this.icon.includes('Icon') ?  false : true,
                    isSport: this.isSport,
                };
                let response = await this.createSubject(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    // this.addNewSubject(response);
                    this.$emit('addSubject', response);
                    // this.setSubjectListStatus(true);
                    this.showSnackbar({ message: 'Gespeichert!'});
                    this.$emit('stopEvent');
                }
                this.loadingSave = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table, tr, td {
    border: none;
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.input{
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width:0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance:none
}

.input[disabled]{
    color: grey;
    background-color: #CACACA;
}

.errorInput {
    border: 2px solid red !important;
}
.editContainer {
    width: 25%;
    border-radius: 16px;
    margin: auto;
    background-color:#dfdfdf
}
.innerContainer {
    background-color: #ffffff;
    border-radius: 15px;
    margin: 1.5%;
}
.scrollable{
    max-height: 40vh;
    overflow-y: auto;
}

.iconSelect {
    width: 42px;
    padding: 2px;
    border: solid 2px #EAEAEA;
    border-radius: 10px;
    margin-right: 2px;
}

.iconSelect[selected] {
    width: 42px;
    padding: 2px;
    border: solid 2px #737373;
    border-radius: 10px;
}

.mainControlButtons {
    font-weight: 300;
    font-size: 10px;
    border-radius: 8px;

    & span {
        line-height: 0 !important;
    }
}

.mainFunctionButtons {
    width: 38px !important;
    height: 38px !important;
    border-radius: 8px;

    img {
        width: 27px !important;
        height: 27px !important;
        filter: brightness(1000%);
    }
}

.infoTextToolTip {
    max-width: 250px;
    background-color: var(--v-blau-base);
}
</style>

