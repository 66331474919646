const urlify = (text = "",alternate) => {
    // Define regex to match email addresses
    var emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/gi;

    // Replace email addresses with a placeholder to prevent them from being processed as URLs
    var emails = [];
    text = text.replace(emailRegex, function (email) {
        emails.push(email);
        return `{{${emails.length - 1}}}`; // Placeholder
    });

    // Define regex to match URLs
    // eslint-disable-next-line no-useless-escape
    var urlRegex =/((https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;
    // Replace URLs in the text with HTML anchor elements
    text = text.replace(urlRegex, function (url) {
        url = url.includes('://') ? url : 'http://' + url;
        if (alternate) {
            return '<a style="color: white" href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
        }
        return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });

    // Replace placeholders with original email addresses
    text = text.replace(/{{(\d+)}}/g, function (match, index) {
        return emails[index];
    });

    return text;
}

export default urlify;