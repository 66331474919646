<template>
    <v-card style="border-radius: 16px">
        <v-card-title
            style="background-color: var(--v-rot-base)"
            class="pa-2 px-2 pl-4"
        >
            <p style="color: white">
                Neue Gruppe anlegen
            </p>

            <v-spacer />

            <v-btn
                icon
                @click="()=> { $emit('stopEvent'); stop(); }"
            >
                <img
                    :src="cancelIcon"
                    class="icon20 iconToWhite"
                    alt="schließen"
                >
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-4">
            <div class="mb-4">
                <v-text-field
                    v-model="groupName"
                    outlined
                    dense
                    hide-details
                    label="Gruppen-, Klassenname"
                    placeholder="Gruppenname eingeben"
                    class="inputField mb-1"
                    :class="{ errorInput: fields.groupName || fields.groupNameDuplicate }"
                    @change="validateInput()"
                />
                <span
                    v-if="fields.groupName"
                    style="color: var(--v-frot-base)"
                >
                    Geben Sie einen Gruppenname an
                </span>
                <span
                    v-else-if="fields.groupNameDuplicate"
                    style="color: var(--v-frot-base)"
                >
                    Dieser Gruppenname wird bereits verwendet
                </span>
            </div>

            <div
                
                class="mb-4"
            >
                <v-autocomplete
                    v-model="groupLeaders"
                    :items="createTeacherFirst ? displayedTeachers : teachers"
                    :item-text="item => item.name + ' ' + item.lastName"
                    return-object
                    outlined
                    dense
                    hide-details
                    multiple
                    label="Gruppen-,Klassenleiter"
                    placeholder="Leiter auswählen"
                    no-data-text="Keine Lehrer vorhanden"
                    class="inputField mb-1"
                    :class="{ errorInput: fields.groupLeaders }"
                    @change="validateInput()"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle(true)"
                        >
                            <v-list-item-action>
                                <v-icon>{{ iconLeader }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ selectedAllLeaders ? 'Alle abwählen' : 'Alle auswählen' }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2" />
                    </template>
                </v-autocomplete>
                <span
                    v-if="fields.groupLeaders"
                    style="color: var(--v-frot-base)"
                >
                    Wählen Sie mindestens einen Leiter aus
                </span>
            </div>

            <v-autocomplete
                v-model="groupMembers"
                :items="pupils"
                :item-text="item => item.name + ' ' + item.lastName"
                return-object
                outlined
                dense
                hide-details
                multiple
                label="Schüler"
                placeholder="Schüler auswählen/suchen"
                no-data-text="Keine Schüler vorhanden"
                class="inputField"
                @change="validateInput()"
            >
                <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggle()"
                    >
                        <v-list-item-action>
                            <v-icon>{{ iconMember }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ selectedAllMembers ? 'Alle abwählen' : 'Alle auswählen' }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2" />
                </template>
            </v-autocomplete>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                class="text-none mt-2"
                color="success"
                elevation="0"
                @click="save"
            >
                Speichern
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: "CreateGroup",
    props:{
        createTeacherFirst: { required: false, type: Boolean, default: false},
        teacherName: { required: false, type: String, default: 'Neu angelegter Lehrer'}
    },
    data: () => ({
        cancelIcon,

        groupName: '',
        groups: [],
        teachers: [],
        displayedTeachers: [],
        pupils: [],
        groupLeaders: [],
        groupMembers: [],

        fields: {
            groupName: false,
            groupNameDuplicate: false,
            groupLeaders: false,
        },
    }),
    computed: {
        selectedAllLeaders() {
            return this.groupLeaders.length === this.teachers.length;
        },
        selectedSomeLeaders() {
            return this.groupLeaders.length > 0 && !this.selectedAllLeaders;
        },
        selectedAllMembers() {
            return this.groupMembers.length === this.pupils.length;
        },
        selectedSomeMembers() {
            return this.groupMembers.length > 0 && !this.selectedAllMembers;
        },
        iconLeader() {
            if (this.selectedAllLeaders) return 'mdi-close-box';
            if (this.selectedSomeLeaders) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        iconMember() {
            if (this.selectedAllMembers) return 'mdi-close-box';
            if (this.selectedSomeMembers) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
    },
    async mounted() {
        await this.requestTeachers();
        await this.requestGroups();
        await this.requestPupils();
    },
    methods: {
        ...mapActions('groups', ['getGroups', 'createGroup', 'setGroupInvalidStatus']),
        ...mapActions('pupils', ['getPupils']),
        ...mapActions('teachers', ['getTeachers']),
        ...mapMutations('snackbar', ['showSnackbar']),

        // true = leader, false = members
        toggle(leader) {
            this.$nextTick(() => {
                if (leader) {
                    if (this.selectedAllLeaders) {
                        this.groupLeaders = [];
                    } else {
                        this.groupLeaders = this.displayedTeachers.slice();
                    }
                } else {
                    if (this.selectedAllMembers) {
                        this.groupMembers = [];
                    } else {
                        this.groupMembers = this.pupils.slice();
                    }
                }
            })
        },

        alphabetically(a, b) {
            let x = a.toLowerCase();
            let y = b.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        stop() {
            this.groupName = '';
            this.groupLeaders = [];
            this.groupMembers = [];
        },

        // result = false indicates a problem, if a field is true it indicates a problem with this specific field
        validateInput() {
            let result = true;

            this.fields.groupName = false;
            this.fields.groupNameDuplicate = false;
            this.fields.groupLeaders = false;

            if (this.groupName.replace(/\s/g, '') === '' || this.groupName.trim() === '') {
                this.fields.groupName = true;
                result = false;
            }
            if (this.groups.filter(el => el.name === this.groupName.trim()).length > 0) {
                this.fields.groupNameDuplicate = true;
                result = false;
            }

            if (this.groupLeaders.length < 1) {
                this.fields.groupLeaders = true;
                result = false;
            }

            return result;
        },

        async requestTeachers() {
            this.teachers = await this.getTeachers();
            this.teachers.sort((a, b) => this.alphabetically(a.name, b.name));
            if(this.createTeacherFirst){
                const placeholderTeacher = {
                                            name: this.teacherName !== '' ? this.teacherName:
                                                                    'Neu angelegter Lehrer', 
                                            lastName: '', 
                                            _id: 'placeholder'
                                            };
                //clone teachers to not mess with teachers in store '
                this.displayedTeachers = this.teachers.map((x) => x);
                this.displayedTeachers.unshift(placeholderTeacher);
                this.groupLeaders.push(placeholderTeacher);
            }

        },

        async requestGroups() {
            this.groups = await this.getGroups();
            this.groups.sort((a, b) => this.alphabetically(a.name, b.name));
        },

        async requestPupils() {
            this.pupils = await this.getPupils();
            this.pupils.sort((a, b) => this.alphabetically(a.name, b.name));
        },

        async save() {
            if (!this.validateInput()) {
                this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error' });
                return;
            }

            let data = {
                name: this.groupName.trim(),
                leaders: this.groupLeaders.map(el => el._id),
                participants: this.groupMembers.map(el => el._id),
            };
            if(this.createTeacherFirst){
                this.$emit('newGroup',data);
                this.stop();
            } else {
                let response = await this.createGroup(data);

                if (Number.isInteger(response)) {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                    this.stop();
                } else {
                    this.showSnackbar({ message: 'Gespeichert!'});
                    await this.setGroupInvalidStatus(true);
                    this.stop();
                    this.$emit('stopEvent');
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table, tr, td {
    border: none;
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.input {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width: 0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance:none
}

.inputField {
  border-radius: 8px;
}

.errorInput {
    border: 2px solid red !important;
}

.scrollable {
    max-height: 40vh;
    overflow-y: auto;
}
</style>
