import { render, staticRenderFns } from "./EditorDocumentRegistry.vue?vue&type=template&id=03f37e25&scoped=true&"
import script from "./EditorDocumentRegistry.vue?vue&type=script&lang=js&"
export * from "./EditorDocumentRegistry.vue?vue&type=script&lang=js&"
import style0 from "./EditorDocumentRegistry.vue?vue&type=style&index=0&id=03f37e25&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f37e25",
  null
  
)

export default component.exports