import { render, staticRenderFns } from "./PinboardExternalDialog.vue?vue&type=template&id=c2d4a378&scoped=true&"
import script from "./PinboardExternalDialog.vue?vue&type=script&lang=js&"
export * from "./PinboardExternalDialog.vue?vue&type=script&lang=js&"
import style0 from "./PinboardExternalDialog.vue?vue&type=style&index=0&id=c2d4a378&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2d4a378",
  null
  
)

export default component.exports