import * as backend from '../../api/backend';
import {checkResponseStatus} from "@/util/check";

const actions = {
    async createPoll({ commit, dispatch }, params) {
        try {
            const res = await backend.createPoll(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getPoll({ commit, dispatch }, pollId) {
        try {
            const res = await backend.getPoll(pollId);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async voteOption({ commit, dispatch }, params) {
        try {
            let pollId = params.pollId;
            delete params.pollId;
            const res = await backend.vote(pollId, params);
            const status = await checkResponseStatus(200, res);
            let response = await res.json();
            if (status) {
                response.status = '200';
            }
            return response;
        }
        catch (err) {
            await err.response.status;
        }
    },

    async createOption({ commit, dispatch }, params) {
        try {
            let pollId = params.pollId;
            delete params.pollId;
            const res = await backend.createOption(pollId, params);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            await err.response.status;
        }
    }
}

export default {
    namespaced: true,
    actions
}
