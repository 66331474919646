import * as backend from '../../api/backend';
import { checkResponseStatus } from '@/util/check';
import backendWebSocket from '../../api/backendWebSocket';
import Vue from "vue";

const defaultState = {
    // Is false when backend is down or e.g. no dns entry is found
    chatRooms: [],
    chatRoomEvents: [],
    lastFetchTime: new Date(), // When was chat last fetched
    chatBadgeEventList: [],
};
const getNewMessagesCount = (chatRoom, accountId, getters) => {
    const accountTimestampObject = chatRoom.lastSeenTimestamps.find(item => item.account === accountId);

    // Todo might have fixed unwated badges bug, unable to reproduce
    // from statistical code analysis: this is the only spot where unknown badges might come from in frontend,
    // need to look at backend as well (migration script)
    if ((!accountTimestampObject) && getters.chatRoomEventsByChatRoom[chatRoom._id] && getters.chatRoomEventsByChatRoom[chatRoom._id].length > 0 ) {
        return  getters.chatRoomEventsByChatRoom[chatRoom._id].length
    }else if((!accountTimestampObject) && (!getters.chatRoomEventsByChatRoom[chatRoom._id])){
        return 0
    }
    const lastSeenTimestamp = new Date(accountTimestampObject.timestamp);

    const newChatRoomEvents = (getters.chatRoomEventsByChatRoom[chatRoom._id] || [])
        .filter(chatRoomEvent => ((chatRoomEvent.author.toString() !== accountId.toString()) && new Date(chatRoomEvent.createdAt) >= lastSeenTimestamp));


    return newChatRoomEvents.length;
};
const getLastEventTime = (chatRoom, getters) => {
    const chatRoomEvents =
        getters.chatRoomEventsByChatRoom[chatRoom._id] || [];
    const lastChatRoomEvent = chatRoomEvents[chatRoomEvents.length - 1];
    return lastChatRoomEvent
        ? new Date(lastChatRoomEvent.createdAt).getTime()
        : new Date(chatRoom.createdAt).getTime();
};
const getLastChatRoomEvent = (chatRoom, getters) => {
    const chatRoomEvents =
        getters.chatRoomEventsByChatRoom[chatRoom._id] || [];
    const lastChatRoomEvent = chatRoomEvents[chatRoomEvents.length - 1];
    return lastChatRoomEvent
        ? {time: lastChatRoomEvent.createdAt, message: lastChatRoomEvent.text}
        : {time: chatRoom.createdAt, message: '' };
};
const enhanceChats = (chatRooms, accountId, getters) => {
    const enhanced = chatRooms
    .map((room) => ({
        ...room,
        newMessagesCount: getNewMessagesCount(room, accountId, getters),
        lastChatMessage: getLastChatRoomEvent(room, getters),
    }))
    .sort(
        (room1, room2) => getLastEventTime(room2, getters) - getLastEventTime(room1, getters)
    );
   return enhanced;
};

const mutations = {
    setChatRooms(state, chatRooms) {

        // TODO Add unread Messages
        // TODO Add can be deleted By Me
        // TODO Add Title
        state.chatRooms = chatRooms;
    },
    setLastFetchTime(state, lastFetchTime) {
        state.lastFetchTime = lastFetchTime
    },
    addOrUpdateChatEvents(state,chatRoomEvents){
        chatRoomEvents.map(async (chatRoomEvent) =>{
            const index = state.chatBadgeEventList.findIndex(el => el._id.toString() === chatRoomEvent._id.toString());

            if (index >= 0){
                Vue.set(state.chatBadgeEventList, index, chatRoomEvent);
            }else{
                state.chatBadgeEventList.push(chatRoomEvent);
            }
        })
        
    },
    setChatRoomEvents(state, chatRoomEvents) {
        state.chatRoomEvents = chatRoomEvents;
    },
    updateChatRoomEvent(state, chatRoomEvent){
        const index = state.chatRoomEvents.findIndex(el => {
            return  el._id === chatRoomEvent._id;
        });
        if (index >= 0){
            Vue.set(state.chatRoomEvents, index, chatRoomEvent);
        }
    },
    setChatRoomEventThumbnail(state,  parameters) {
        const { chatRoomEventId, url, loadedFileType } = parameters;
        const tempTest = state.chatRoomEvents.map((event) => event);
        state.chatRoomEvents = tempTest.map((chatRoomEvent) => {
            if (chatRoomEvent._id === chatRoomEventId) {
                chatRoomEvent.thumbnail = url;
                chatRoomEvent.hasThumbnail = loadedFileType !== 'text/plain; charset=utf-8';
                chatRoomEvent.loadedFileType = loadedFileType;
            }
            return chatRoomEvent;
        });
    },
    clearBadgesFromChat(state, chatRoomId){
        state.chatBadgeEventList = state.chatBadgeEventList.filter(badgeEvent=> badgeEvent.chatRoom !== chatRoomId)
    },
    clearAllChatBadges(state ){
        state.chatBadgeEventList = [];
    }
};

const actions = {
    /**
     * @param {Object} vuexParams - Vuex environment parameters
     * @param {Object} callerParams - Parameters given from calling line
     * @param {Date} callerParams.sinceUpdatedAt - Optional: Only retrieve updated and created chats since the given date.
     * If unset then retrieve all chats. This is also useful in order to know which chats have been deleted (since they are not there anymore)
     */
    async fetchChats({ commit, dispatch, state, getters, rootGetters }, { sinceUpdatedAt } = {}) {
        const chatRoomsResponse = await backend.getChatRooms(sinceUpdatedAt);
        const retrievedChatRooms = await chatRoomsResponse.json();
        let accountId = rootGetters['auth/accountId'];
        if(accountId === undefined){
            const res = await backend.getCurrentAccount();
            const acc = await res.json();
            accountId = acc._id;
        }
        
        // method to update any array
        const updateArray = (newArray, oldArray) => {
            return newArray.reduce((acc, item) => {
                const index = acc.findIndex(localItem => localItem._id === item._id);

                // remove old item if it exists
                if (index >= 0) {
                    acc.splice(index, 1);
                }
                acc.push(item);
                return acc;
            }, [...oldArray]);
        }

        const chatRoomEventsResponse = await backend.getChatRoomEvents(sinceUpdatedAt);
        const retrievedChatRoomEvents = await chatRoomEventsResponse.json();

        // Merge or replace data depending on if new data only contains changes or everything
        if(sinceUpdatedAt) {
            // Merge old events with new events
            if(retrievedChatRoomEvents.length){
                const chatRoomEvents = updateArray(retrievedChatRoomEvents, state.chatRoomEvents);
                commit('setChatRoomEvents', chatRoomEvents);
            }
        } else {
            // Replace old events with new Events
            commit('setChatRoomEvents', retrievedChatRoomEvents);
        }
        // Merge or replace data depending on if new data only contains changes or everything
        if(sinceUpdatedAt) {
            // Merge old chatRooms with chatRooms
            if(retrievedChatRooms.length >0){
                const chatRooms = updateArray(retrievedChatRooms, state.chatRooms);
                const enhanced = enhanceChats(chatRooms, accountId, getters)
                commit('setChatRooms', enhanced);
                dispatch('getChatBadgeEventList', enhanced);
            }
        } else {
            const enhanced = enhanceChats(retrievedChatRooms, accountId, getters)

            commit('setChatRooms', enhanced);
            dispatch('getChatBadgeEventList', enhanced);
        }
        const currDate = new Date();
        commit('setLastFetchTime', currDate.toISOString());
    },
    async clearChatStore({ commit, dispatch, state, getters, rootGetters }) {
        commit('setChatRooms',[])
        commit('setLastFetchTime', new Date())
        commit('setChatRoomEvents', [])
        commit('clearAllChatBadges');
    },
    async redactChatRoomEvent({commit, dispatch}, chatRoomEventId) {
        try {
            const res = await backend.redactChatRoomEvent(chatRoomEventId);
            await checkResponseStatus(204, res);
            dispatch("fetchChats");
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postChatRoom ({ commit, dispatch }, body) {
        try {
            const res = await backend.postChatRoom(body);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async patchChatRoom ({ commit, dispatch }, { chatRoomId, body }) {
        try {
            const res = await backend.patchChatRoom(chatRoomId, body);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteChatRoom ({ commit, dispatch }, chatRoomId) {
        try {
            const res = await backend.deleteChatRoom(chatRoomId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async leaveChatRoom ({ commit, dispatch }, chatRoomId) {
        try {
            const res = await backend.leaveChatRoom(chatRoomId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async updateChatRoomLastSeenTimestamp ({ commit, dispatch }, chatRoomId) {
        try {
            const res = await backend.updateChatRoomLastSeenTimestamp(chatRoomId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postChatRoomEvent ({ commit, dispatch }, body) {
        try {
            const res = await backend.postChatRoomEvent(body);
            await checkResponseStatus(201, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getChatRoomEventFile ({ commit, dispatch }, chatRoomEventId) {
        try {
            const res = await backend.getChatRoomEventFile(chatRoomEventId);
            await checkResponseStatus(200, res);
            return await res.blob();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postChatRoomEventFile ({ commit, dispatch }, { chatRoomId, files }) {
        try {
            let res = null;
            if (files) {
                res = await backend.postChatRoomEventFile(chatRoomId, files);
            } else {
                res = await backend.postChatRoomEventFile(chatRoomId);
            }
            await checkResponseStatus(0, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getChatFileThumbnail ({ commit, dispatch }, chatRoomInformation) {
        const { chatRoomId, chatRoomEventId } = chatRoomInformation;
        try {
            const res = await backend.getChatFileThumbnail(chatRoomId, chatRoomEventId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            return { blob, hasThumbnail: blob.type !== 'text/plain; charset=utf-8', };
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async updateChatRoomEvent({ commit, dispatch },chatRoomEventId) {
        try {
            const res = await backend.getChatRoomEvent(chatRoomEventId);
            const newEvent = await res.json();
            commit('updateChatRoomEvent', newEvent);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getChatBadgeEventList({commit, getters, rootGetters,}, chatRooms){
        const accountId = rootGetters['auth/accountId'];
        chatRooms.forEach(chatRoom=>{
            const accountTimestampObject = chatRoom.lastSeenTimestamps.find(item => item.account === accountId);
            if(accountTimestampObject){
                const lastSeenTimestamp = new Date(accountTimestampObject.timestamp);
                const newChatRoomEvents = (getters.chatRoomEventsByChatRoom[chatRoom._id] || [])
                .filter(chatRoomEvent => ((chatRoomEvent.author.toString() !== accountId.toString()) && new Date(chatRoomEvent.createdAt) >= lastSeenTimestamp));
                commit('addOrUpdateChatEvents',newChatRoomEvents);
            }else if(!accountTimestampObject && chatRoom.newMessagesCount > 0){
                const newEvents = (getters.chatRoomEventsByChatRoom[chatRoom._id] || [])
                .filter(chatRoomEvent => ((chatRoomEvent.author.toString() !== accountId.toString())));
                commit('addOrUpdateChatEvents',newEvents);
            }
        })
    },
    async clearBadgesFromChat({commit, getters, rootGetters,}, chatRoomId){
        commit('clearBadgesFromChat',chatRoomId);
    }
};

const getChatRoomDisplayName = (chatRoom, rootGetters) => {
    if (chatRoom.type === 'privateChat') {
        const loggedInAccountId =  rootGetters['auth/accountId'];
        const accountId0 = getAddressedAccountsOfMembership(chatRoom.memberships[0], rootGetters)[0];
        const accountId1 = getAddressedAccountsOfMembership(chatRoom.memberships[1], rootGetters)[0];
        
        // Get account of the partner I am chatting with
        const chatPartnerAccountId = loggedInAccountId === accountId1 ? accountId0 : accountId1;
        const chatPartnerAccount = rootGetters['accounts/accountsById'][chatPartnerAccountId];

        if(chatPartnerAccount) {
            return `${chatPartnerAccount.displayName}`
        } else {
            // Legends tell that this case might have been true.
            return "Privater Chat";
        }
    }
    return chatRoom.name;
}

// A membership can be a memberType of 'accounts', 'allTeachers', 'groupLeaders', ....
// depending on this different accounts are affected/addressed/part of that membership
const getAddressedAccountsOfMembership = (membership, rootGetters) => {
    const accountSet = rootGetters['accountSets/accountSetsById'][membership.accountSet];
    if(accountSet) {
        return accountSet.addressedAccounts;
    } else {
        return [];
    }
}

const getters = {
    chatRooms(state, getters, rootState, rootGetters) {
        const unfiltered = state.chatRooms.map(chatRoom => ({
            ...chatRoom,
            displayName: getChatRoomDisplayName(chatRoom, rootGetters),
            memberships: chatRoom.memberships.map((membership) => ({
                ...membership,
                addressedAccounts: getAddressedAccountsOfMembership(membership, rootGetters),
            })),
        }));
        return unfiltered.filter(room => !(room.type === 'privateChat' && room.displayName === 'Privater Chat'));
    },
    chatRoomEvents(state) { return state.chatRoomEvents },
    chatRoomEventsByChatRoom(state) {
        return state.chatRoomEvents.reduce((acc, chatRoomEvent) => {
            if (acc[chatRoomEvent.chatRoom]) {
                acc[chatRoomEvent.chatRoom].push(chatRoomEvent);
            } else {
                acc[chatRoomEvent.chatRoom] = [chatRoomEvent];
            }
            return acc;
        }, {});
    },
    chatRoomEventByFile: (state) => (fileId) => {
        return state.chatRoomEvents.find((event) => event.type === 'fileMessage' && event.file === fileId);
    },
    chatBadgeEventList(state) { return state.chatBadgeEventList},
};


export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};



// isPrivateChat
// joinEvent
// leaveEvent

// Parent => Teacher
// Pupil => Teacher
// Maintainer => Group<Teacher>
// Teacher => Group<Pupil>
// Pupil => Pupil (Not directly possible)

// Types
// Audio
// Video
// Poll
// File
// Text

// GroupChat vs Private Chat

// Group Chat has leaders
// - Members cannot leave the chat
// - Moderators can del events, leave the Chat (only when there is another Mod), del the Chat
// - Moderator can lock or unlock Chat for Members

// Private Chat => Everyone can delete his own event... and leave the Chat
// - Everyone can leave the chat
// - Everyone can only delete his own event

// Maintainer can write
// Teacher
// Parent
// Pupil

// Teacher can write
// Children of his Groups
// Parents of his Children
// Maintainer
// Teachers

// Parent can write
// His own children
// Teachers of the Children

// Child can write
// its parents
// its teachers (Group Leads)

// Appointment Leads (Lehrer, der in einer Klasse unterrichtet) can

// Group Leads  (Klassenlehrer) can
// - The Same as an Appointment Lead
// - Add/Remove Appointments and Set the Appointment Lead

// Appointment Leads can
// Appointment Leads in a Group
// Cached in a Group as Appointment Lead Array
// - Write Children
// - And such
//
