const state = {
    imageCaptureFile: null,
};

const mutations = {
    setFile: (state, file) => {
        state.imageCaptureFile = file;
    },
};

export default {
  namespaced: true,
  state,
  mutations,
};
