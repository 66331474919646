<template>
    <v-dialog
        v-model="showDialog"
        width="400"
    >
        <v-card style="border-radius: 16px">
            <v-card-title class="d-flex align-center justify-space-between" style="background-color: var(--v-chatColor-base); color: white;">
                {{ groupName }}
                <v-btn
                    x-small
                    text
                    elevation="0"
                    class="pa-0"
                    @click="showDialog = false"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text>
                <h1 class="formHeader mt-3">
                    {{ groupParticipants.length }} Teilnehmer:
                </h1>

                <li
                    v-for="participant in groupParticipants"
                    :key="participant"
                    class="mt-2 ml-4"
                    style="font-size: 16px"
                >
                    {{ participant }}
                </li>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: "ChatRoomInfoDialog",
    props: {
        chatRoom: { required: true, type: Object },
    },
    data: () => ({
        groupParticipants: [],
        participants: [],
        groupName: "",
        showDialog: true,
        cancelIcon,
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
    },
    watch: {
        showDialog(newState, oldState) {
            if (newState === false) {
                this.$emit("closeDialog");
            }
        },
    },
    async mounted() {
        if(this.matrixUsers){
            this.groupName = this.checkRoomName(this.chatRoom.name);
            this.groupParticipants = await this.getMatrixUsersFullNameByRoomId(
                this.chatRoom._id
            );

        } else {
            this.groupName = this.chatRoom.name;
            this.participants = this.chatRoom.memberships.map(
                (membership) => membership.accountSet
            );
            for (let i = 0; i < this.participants.length; i++ ) {
                this.groupParticipants.push(this.getAccountSetDisplayName(this.participants[i]));
            }
        }
        this.groupParticipants.sort();
    },
    methods: {
        ...mapActions("matrix", ["getMatrixUsersFullNameByRoomId"]),
        checkRoomName(name) {
            // group room names look like: group Room <id, groupName>, so return substring between , and >
            if (name.includes("group Room")) {
                const start = name.indexOf(",");
                const end = name.indexOf(">");
                return name.slice(start + 1, end).trim();
            }
            const id = name
                .replace(this.accountId, "")
                .replace("private Room", "")
                .replace("<", "")
                .replace(">", "")
                .replace(",", "")
                .replace(" ", "");
            let secondUser = this.matrixUsers.find((el) => {
                return el.account === id;
            });
            if (secondUser) {
                return secondUser.lastName + " " + secondUser.name;
            } else {
                if (this.accountRole === "teacher") {
                    secondUser = this.maintainers.find((el) => {
                        return el.account === id;
                    });
                    if (secondUser) {
                        return "Verwaltung";
                    } else {
                        return "Unbekannter Nutzer";
                    }
                }
                return "Unbekannter Nutzer";
            }
        },
        getAccountSetDisplayName(participantSet) {
            let set = this.accountSets
                .filter((accountSet) => accountSet._id == participantSet);
            if (set[0].type === "account") {
                const account = this.accountsById[set[0].account];
                if(account){
                    return account ? account.displayName : account._id;
                }else {
                    return 'Unbekannter Account';
                }
            } else if (set[0].type === "groupLeaders") {
                const group = this.groupsById[set[0].group];
                return `Gruppe "${group?.name}" Leiter`;
            } else if (set[0].type === "groupAppointmentLeaders") {
                const group = this.groupsById[set[0].group];
                return `Gruppe "${group?.name}" Fachlehrer`;
            } else if (set[0].type === "groupParticipants") {
                const group = this.groupsById[set[0].group];
                return `Gruppe "${group?.name}" Mitglieder`;
            } else if (set[0].type === "groupParticipantsParents") {
                const group = this.groupsById[set[0].group];
                return `Gruppe "${group?.name}" Eltern`;
            } else if (set[0].type === "allTeachers") {
                return "Alle Lehrer";
            } else if (set[0].type === "allMaintainers") {
                return "Verwaltung";
            } else if (set[0].type === "allPupils") {
                return "Alle Schüler";
            } else if (set[0].type === "allParents") {
                return "Alle Eltern";
            } else {
                // For debugging if there is a new type
                console.log('Problematic new type:', set[0].type);
                return 'Unbekannt'
            }
        },
    },
};
</script>

<style>
</style>
