<template>
    <div
        style="width: 100%; height: 100%"
        :class="currentFontSize"
    >
        <!-- Teacher View -->
        <div v-if="showTeacherUI">
            <div class="greyDivBorderColor">
                <div
                    id="textContainer"
                    style="padding: 24px; margin: 8px;"
                    class="greyDiv"
                >
                    <div v-if="hideMode">
                        <span
                            v-for="(chip, index) in splittedText"
                            :id="'clozeWord' + index"
                            :key="index"
                            style="display: inline-flex; margin-bottom: 2px; cursor: pointer; border-radius: 6px"
                            :class="chip.word === '\n' ? 'visually-hidden' : ''"
                            :hidden="chip.word === '\n' || chip.word === ''"
                        >
                            <span v-if="chip && !chip.hidden && chip.word !== '\n'">
                                <span
                                    style="display: inline-block; border-radius: 6px"
                                    class="editorColorPrimaryBackgroundOnHover"
                                    @click="hideWord(index)"
                                >
                                    {{ chip.word }}
                                </span>
                                <span
                                    style="cursor: text; display: inline"
                                    @click="editText"
                                >{{ isNextWordPunctation(index) ? '' : '&nbsp;' }}</span>
                            </span>
                            <span v-else-if="chip && chip.word !== '\n'">
                                <span
                                    style="border-style: solid; border-width: 1px; color: #00000060"
                                    @click="showWord(index)"
                                >{{ chip.word }}</span>
                                <span
                                    style="cursor: text; display: inline; white-space: pre"
                                    @click="editText"
                                >{{ isNextWordPunctation(index) ? '' : '&nbsp;' }}</span>
                            </span>
                        </span>
                    </div>
                    <div
                        v-else
                        style="display: flex; flex-direction: column; width: 100%; height: 100%"
                    >
                        <textarea
                            ref="textAreaCloze"
                            v-model="textArea"
                            rows="1"
                            style="height:15em; resize: none; overflow: hidden"
                            placeholder="Geben Sie hier Ihren Text ein. Bestätigen Sie anschließend die Eingabe mit Strg + Enter (oder klicken Sie aus dem Eingabefeld) und tippen Sie auf die Worte, um sie in Lücken zu verwandeln."
                            @cut="delayedResize"
                            @paste="delayedResize"
                            @drop="delayedResize"
                            @blur="saveState"
                            @keydown="delayedResize"
                            @keypress="textInsertedAcceptWithCombo($event)"
                        />
                        <!-- Placeholder Text wenn Bibliothek implementiert wurden: Geben sie hier Ihren Text ein oder suchen Sie sich einen Text aus der Bibliothek ein heraus. Bestätigen sie anschließend die Eingabe und tippen sie auf die Worte um sie in Lücken zu verwandeln. -->
                        <!--                        <v-btn @click="textInsertedAccept" style="align-self: flex-end; min-width: 36px; width: 36px; height: 36px; bottom: -8px" class="editorColorPrimaryBackgroundOnHover ">-->
                        <!--                            <img :src="fertigIcon" style="height: 24px" class="iconToWhiteHover">-->
                        <!--                        </v-btn>-->
                    </div>
                </div>
            </div>

            <!-- Controls rechts rausgeschoben -->
            <div class="editorOptionsFade">
                <v-tooltip
                    right
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-2 editorColorPrimaryBackgroundOnHover"
                            style="position: absolute; right: -56px; top: 62px; width: 48px; height: 48px; min-width: 48px"
                            v-bind="attrs"
                            v-on="on"
                            @click="switchTextMode()"
                        >
                            <!--                        <v-icon class="iconToWhiteHover" style="opacity: 0.75" large>mdi-format-size</v-icon>-->
                            <img
                                :src="currentModeIcon"
                                class="iconToWhiteHover"
                                style="height: 25px"
                                alt="Lücken oder Textmodus ändern"
                            >
                        </v-btn>
                    </template>
                    <span>Modus ändern</span>
                </v-tooltip>
                <v-tooltip
                    right
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-2 editorColorPrimaryBackgroundOnHover"
                            style="position: absolute; right: -56px; top: 116px; width: 48px; height: 48px; min-width: 48px"
                            v-bind="attrs"
                            v-on="on"
                            @click="changeSize()"
                        >
                            <!--                        <v-icon class="iconToWhiteHover" style="opacity: 0.75" large>mdi-format-size</v-icon>-->
                            <img
                                :src="currentSizeIcon"
                                class="iconToWhiteHover"
                                style="height: 32px"
                                alt="Größe des Elements verändern"
                            >
                        </v-btn>
                    </template>
                    <span>Größe ändern</span>
                </v-tooltip>
                <v-tooltip
                    right
                    :disabled="!elementOnCurrentPage"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="editorColorPrimaryBackgroundOnHover"
                            style="position: absolute; right: -56px; top: 170px; width: 48px; height: 48px; min-width: 48px; font-size: xx-large"
                            v-on="on"
                            @click="switchMode"
                            @mouseenter="animateTooltipText"
                            @mouseleave="tooltipText = ''"
                        >
                            <img
                                class="iconToWhiteHover"
                                style="height: 17px"
                                :src="mode === 'freeEdit' ? bearbeitenIcon : dropDownIcon"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column">
                        <span style="width: 200px">{{ mode === 'freeEdit' ? 'SuS geben bei jeder Lücke einen Freitext ein' : wrongWords.length > 0 ? 'SuS haben bei jeder Lücke die Auswahl aus richtigen und falschen Wörtern' : 'SuS haben bei jeder Lücke die Auswahl aus richtigen Wörtern' }}</span>
                        <span style="margin-right: 4px">Vorschau:</span>
                        <div v-if="mode === 'dropdown'">
                            <select
                                name="answers"
                                style="width: fit-content; border: solid 1px; border-radius: 6px; margin-right: 7.2px"
                            >
                                <option
                                    v-for="(word, index) in tooltipSelector"
                                    :key="index"
                                >
                                    {{ word }}
                                </option>
                            </select>
                            <div
                                style="display: flex; flex-direction: column; width: fit-content; border: solid 1px black; background-color: white"
                            >
                                <span
                                    v-for="(word, index) in tooltipSelector"
                                    :key="index"
                                    style="color: black"
                                    class="px-1"
                                >{{ word }}
                                </span>
                            </div>
                        </div>
                        <div v-else-if="mode === 'freeEdit'">
                            <input
                                v-model="tooltipText"
                                style="border: solid 1px; border-radius: 6px"
                            >
                        </div>
                    </div>
                </v-tooltip>
            </div>

            <!-- Unterer Config Bereich -->
            <div
                style="display: flex; flex-direction: row; margin-top: 8px"
                class="greyDiv editorOptionsFade"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <img
                            :src="showAvailableWords ? eyeIconOpen : eyeIconClosed"
                            style="height: 20px; margin-top: 10px; margin-left: 10px; margin-right: 10px; cursor: pointer"
                            v-on="on"
                            @click="showAvailableWords = !showAvailableWords"
                        >
                    </template>
                    <span>
                        {{ showAvailableWords ?
                            'Die Schüler bekommen alle ausgeblendeten Wörter unter der Aufgabe aufgelistet' :
                            'Die Schüler bekommen die ausgeblendeten Wörter nicht angezeigt'
                        }}
                    </span>
                </v-tooltip>
                <v-chip-group :style="'max-width: '+smallUI ? '400px' : '800px'">
                    <v-chip
                        v-for="(word, index) in hiddenAndWrongWords"
                        :key="index"
                        dense
                        style="margin: 2px; border-radius: 12px"
                        class="editorColorPrimaryBackground"
                    >
                        {{ word }}
                    </v-chip>
                    <input
                        v-if="addNewWrongWord"
                        v-model="wrongWord"
                        style="background-color: white !important; margin: 2px; border: solid 2px; border-radius: 12px;"
                        class="editorColorPrimaryBorderColor"
                        @change="addWrongWord"
                        @keypress.enter="addWrongWord"
                    >
                    <v-chip
                        style="margin: 2px; border: solid 2px; border-radius: 12px; background-color: white !important;"
                        class="editorColorPrimaryBorderColor"
                        dense
                        @click="addNewWrongWord = true"
                    >
                        + Falsche Lösung
                    </v-chip>
                </v-chip-group>
            </div>
        </div>

        <!-- Teacher Correction View -->
        <div v-else-if="state === 'teacher' || state === 'viewer'">
            <div
                v-for="(chip, index) in splittedText"
                :key="index"
                style="display: inline-flex; margin-bottom: 2px"
            >
                <div v-if="!chip.hidden">
                    <span style="display: inline; white-space: pre">{{ chip.word }}</span>
                    <span
                        v-if="!isNextWordPunctation(index)"
                        style="display: inline; white-space: pre"
                    >{{ '&nbsp;' }}</span>
                </div>
                <div v-else>
                    <div v-if="answerCorrect(index)">
                        <span
                            style="border-style: solid; border-width: 1px; border-radius: 6px; border-color: black"
                            class="editorColorPrimary"
                        >{{ chip.word }}</span>
                        <span
                            v-if="!isNextWordPunctation(index)"
                            style="display: inline; white-space: pre; font-size: 16px"
                        >{{ '&nbsp;' }}</span>
                    </div>
                    <div v-else>
                        <v-tooltip
                            top
                            :disabled="!elementOnCurrentPage"
                        >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <div v-if="!showSolutions[index]">
                                        <div v-if="solutions[index] !== undefined && solutions[index] !== null">
                                            <span
                                                style="border-style: solid; border-width: 1px; border-radius: 6px; cursor: pointer; color: white"
                                                class="editorColorWrongBackground"
                                                @click="toggleShowSolution(index)"
                                            >{{ solutions[index] }}</span>
                                            <span style="display: inline; white-space: pre">{{ '&nbsp;' }}</span>
                                        </div>
                                        <div v-else>
                                            <span
                                                style="border-style: solid; border-width: 1px; border-radius: 6px; cursor: pointer; color: white"
                                                class="editorColorWrongBackground"
                                                @click="toggleShowSolution(index)"
                                            >keine Lösung</span>
                                            <span style="display: inline; white-space: pre">{{ '&nbsp;' }}</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span
                                            style="border-style: solid; border-width: 1px; border-radius: 6px; cursor: pointer; color: white"
                                            class="editorColorPrimaryBackground"
                                            @click="toggleShowSolution(index)"
                                        >{{ chip.word }}</span>
                                        <span style="display: inline; white-space: pre">{{ '&nbsp;' }}</span>
                                    </div>
                                </div>
                            </template>
                            <span>{{ showSolutions[index] ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pupil View -->
        <div v-else-if="showPupilUI">
            <span class="visually-hidden">Lückentext</span>
            <!-- Accessibility -->
            <div
                v-if="showAvailableWords"
                style="max-width: 1000px;"
                class="visually-hidden"
            >
                <div style="display: flex; align-items: center">
                    <span
                        class="mr-2"
                        style="padding: 12px 0px"
                    >Die verfügbare Lösungswörter:</span>
                    <div v-if="mode === 'dropdown'">
                        <p
                            v-for="(word, index) in hiddenWords"
                            :key="index"
                        >
                            {{ word.word }}
                        </p>
                    </div>
                    <div v-else>
                        <p
                            v-for="(word, index) in hiddenAndWrongWords"
                            :key="index"
                        >
                            {{ word }}
                        </p>
                    </div>
                    <span>sind in den folgenden Lückentext einzutragen</span>
                </div>
            </div>
            <div
                v-for="(chip, index) in splittedText"
                :key="index"
                style="display: inline-flex; margin-bottom: 2px"
            >
                <div v-if="!chip.hidden">
                    <span style="display: inline; white-space: pre">{{ chip.word }}</span>
                    <span
                        v-if="!isNextWordPunctation(index)"
                        style="display: inline; white-space: pre"
                    >{{ '&nbsp;' }}</span>
                </div>
                <!--                <v-select v-model="solutions[index]" v-else-if="mode === 'dropdown'" style="max-height: 24px; padding-top: 0px; margin-top: 0px; min-width: min-content" :items="hiddenWordsText"></v-select>-->
                <select
                    v-else-if="mode === 'dropdown'"
                    v-model="solutions[index]"
                    name="answers"
                    style="width: fit-content; border: solid 1px; border-radius: 6px; margin-right: 7.2px"
                >
                    <option
                        v-for="(answer, wordIndex) in hiddenWordsText"
                        :key="wordIndex"
                    >
                        {{ answer }}
                    </option>
                </select>
                <select
                    v-else-if="mode === 'dropdownWrong'"
                    v-model="solutions[index]"
                    name="answers"
                    style="width: fit-content; border: solid 1px; border-radius: 6px; margin-right: 7.2px"
                >
                    <option
                        v-for="(answer, wordIndex) in hiddenAndWrongWords"
                        :key="wordIndex"
                    >
                        {{ answer }}
                    </option>
                </select>

                <!--                <v-select v-model="solutions[index]" v-else-if="mode === 'dropdownWrong'" hide-details style="max-height: 24px; width: 75px; padding-top: 0px; margin-top: 0px" :items="hiddenAndWrongWords"></v-select>-->
                <div v-else-if="mode === 'freeEdit'">
                    <!--                    <v-text-field v-model="solutions[index]" dense hide-details></v-text-field>-->
                    <input
                        v-model="solutions[index]"
                        style="border: solid 1px; border-radius: 6px"
                    >
                </div>
                <div v-else-if="mode === 'random'">
                    <div style="display: grid; grid-template-columns: 1fr;">
                        <v-chip
                            v-if="chip.hint"
                            pill
                            small
                            dense
                            style="top: -20px; width: fit-content; margin: auto; grid-row-start: 1; grid-column-start: 1"
                        >
                            {{ chip.shuffledWord }}
                        </v-chip>
                        <v-text-field
                            v-model="solutions[index]"
                            style="grid-row-start: 1; grid-column-start: 1"
                            dense
                            hide-details
                            @focusout="focusOut(index)"
                            @focusin="focusIn(index)"
                        />
                    </div>
                </div>
            </div>
            <v-divider
                v-if="showAvailableWords"
                class="mt-3 mx-4"
                aria-hidden="true"
            />
            <v-chip-group
                v-if="showAvailableWords"
                column
                style="max-width: 1000px;"
                aria-hidden="true"
            >
                <div style="display: flex; align-items: center">
                    <span
                        class="mr-2"
                        style="padding: 12px 0px"
                    >Lösungswörter:</span>
                    <div v-if="mode === 'dropdown'">
                        <v-chip
                            v-for="(word, index) in hiddenWords"
                            :key="index"
                            dense
                            style="border-radius: 6px; color: white"
                            class="editorColorNeutralBackground"
                        >
                            {{ word.word }}
                        </v-chip>
                    </div>
                    <div v-else>
                        <v-chip
                            v-for="(word, index) in hiddenAndWrongWords"
                            :key="index"
                            dense
                            style="border-radius: 6px; color: white"
                            class="editorColorNeutralBackground"
                        >
                            {{ word }}
                        </v-chip>
                    </div>
                </div>
            </v-chip-group>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState, mapGetters} from "vuex";
import eyeIconClosed from "../../assets/Icons/eye-off.svg";
import eyeIconOpen from "../../assets/Icons/eye.svg";
import dropDownIcon from "../../assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-komplimentär-green-88.svg";
import clickIcon from "../../assets/Icons/bearbeiten-komplimentär-mdi-grau-88.svg";
import writeIcon from "../../assets/Icons/Textfunktion-67.svg";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import {v4 as uuidv4} from "uuid";

export default {
    name: 'EditorCloze',
    props: {
        config: { type: Object, required: true},
        state: { type: String, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        exportMode: { type: Boolean, required: false, default: false },
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        lockedTeacherComponent: false,
        splittedText: [],
        mode: 'dropdown',
        wrongWords: [],
        solutions: [],
        showSolutions: [],
        showAvailableWords: false,
        addNewWrongWord: false,
        sizeState: 0,
        hideMode: false,
        textArea: '',

        tooltipText: '',

        // UI
        eyeIconClosed,
        eyeIconOpen,
        dropDownIcon,
        bearbeitenIcon,
        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,
        clickIcon,
        writeIcon,

        wrongWord: '',

        // screen size
        screenX: null,
        screenY: null,
        smallUI: false,

        person: {
            accessibility: {
                screenreader: false,
            }
        }
    }),
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },
        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        currentModeIcon() {
            return this.hideMode ? this.clickIcon : this.writeIcon;
        },

        hiddenWords() {
            return this.shuffleArray(this.splittedText.filter(el => el.hidden));
        },

        hiddenWordsText() {
            let hiddenWordsText = [];
            for(let i=0; i < this.hiddenWords.length; i++) {
                if(this.hiddenWords[i]) {
                    hiddenWordsText.push(this.hiddenWords[i].word)
                }
            }
            return hiddenWordsText;
        },

        hiddenAndWrongWords() {
            let hiddenAndWrongWordsDisplay = [];
            for(let i=0; i < this.hiddenWords.length; i++) {
                if(this.hiddenWords[i]) {
                    hiddenAndWrongWordsDisplay.push(this.hiddenWords[i].word)
                }
            }
            for(let i=0; i < this.wrongWords.length; i++) {
                if(this.wrongWords[i]) {
                    hiddenAndWrongWordsDisplay.push(this.wrongWords[i])
                }
            }
            hiddenAndWrongWordsDisplay = this.shuffleArray(hiddenAndWrongWordsDisplay);
            return hiddenAndWrongWordsDisplay;
        },

        tooltipSelector() {
            if(this.hiddenAndWrongWords.length > 0) {
                return this.hiddenAndWrongWords;
            } else {
                return ['Antwort 1', 'Antwort 2', 'Antwort 3'];
            }
        },

        showPupilUI() {
            return this.exportMode || (this.accountRole === 'pupil' && this.state === 'pupil') || this.previewPupilUI;
        },

        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.accountRole === 'teacher' && this.state === 'creator';
        },
    },
    watch: {
        answers: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        hideAnswers: {
            handler(val, oldVal) {
                this.updateHideStatus();
                this.saveState();
            }
        },
        solutions: {
            handler(val, oldVal) {
                this.config.clozeConfig.solutions = this.solutions;
            }, deep: true
        },
        mode: {
            handler(val, oldval) {
                this.saveState();
            }
        },
        showAvailableWords: {
            handler(val, oldval) {
                this.saveState();
            }
        },
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                this.solutions = [];
            }
        }
    },
    mounted() {
        this.evalScreenSize();
        this.requestPerson();


        if (!this.config.clozeConfig) {
            this.config.clozeConfig = {};
            this.config.identifier = uuidv4();
            this.config.clozeConfig.splittedText = this.splittedText;
            this.config.clozeConfig.textArea = this.textArea;
            this.config.clozeConfig.mode = this.mode;
            this.config.clozeConfig.wrongWords = this.wrongWords;
            this.config.clozeConfig.solutions = this.solutions;
            this.config.clozeConfig.showAvailableWords = this.showAvailableWords;
            this.config.clozeConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.splittedText = this.config.clozeConfig.splittedText;
            if(this.splittedText.length > 0) {
                this.hideMode = true;
            }
            this.textArea = this.config.clozeConfig.textArea;
            this.mode = this.config.clozeConfig.mode;
            this.wrongWords = this.config.clozeConfig.wrongWords;
            this.solutions = this.config.clozeConfig.solutions;
            this.showAvailableWords = this.config.clozeConfig.showAvailableWords;
            this.sizeState = this.config.clozeConfig.sizeState;
        }
    },
    methods: {
        ...mapActions('pupils', ['getMePupil']),

        async animateTooltipText() {
            let answer = ['A', 'n', 't', 'w', 'o', 'r', 't'];
            let timeout = 100;
            for(let i=0; i<answer.length;i++) {
                setTimeout(() => {
                    this.tooltipText = this.tooltipText + answer[i];
                }, timeout);
                timeout += 200;
            }
        },

        editText() {
            this.hideMode = false;
            this.delayedResize();
        },

        textInsertedAccept() {
            this.hideMode = true;
            this.splitText(this.textArea);
            this.resize();
        },

        resize() {
            const textArea =  this.$refs.textAreaCloze
            if(textArea) {
                textArea.style.height = 'auto';
                textArea.style.height = textArea.scrollHeight + 'px';
            }
        },

        delayedResize() {
            window.setTimeout(this.resize, 0);
        },

        textInsertedAcceptWithCombo(event) {
            if(event.key === "\n" && event.ctrlKey) {
                this.textInsertedAccept();
            }
        },

        toggleShowSolution(index) {
            if(this.showSolutions[index] === undefined) {
                this.$set(this.showSolutions, index, true)
            } else {
                this.$set(this.showSolutions, index, !this.showSolutions[index])
            }
        },

        switchTextMode() {
            if (!this.hideMode) {
                this.textInsertedAccept();
            } else {
                this.hideMode = !this.hideMode;
            }
        },

        switchMode() {
            if(this.mode === 'dropdown' || this.mode === 'dropdownWrong' ) {
                this.mode = 'freeEdit';
            } else if(this.mode === 'freeEdit') {
                if(this.wrongWords.length > 0) {
                    this.mode = 'dropdownWrong';
                }
                else {
                    this.mode = 'dropdown';
                }
            }
        },

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        async requestPerson() {
            if(this.accountRole === 'pupil') {
                this.person = await this.getMePupil();
                return this.person;
            }
        },

        isNextWordPunctation(index) {
            if(index + 1 < this.splittedText.length && this.splittedText[index + 1]) {
                return /([.,!?%:;])/.test(this.splittedText[index + 1].word)
            } else {
                return false;
            }
        },

        answerCorrect(index) {
            return this.solutions[index] === this.splittedText[index].word;
        },

        focusIn(index) {
            let object = {
                word: this.splittedText[index].word, hidden: this.splittedText[index].hidden, shuffledWord: this.splittedText[index].shuffledWord, hint: true
            }
            // Vue is not reactive on Arrays ._.
            this.$set(this.splittedText, index, object);
        },
        focusOut(index) {
            let object = {
                word: this.splittedText[index].word, hidden: this.splittedText[index].hidden, shuffledWord: this.splittedText[index].shuffledWord, hint: false
            }
            // Vue is not reactive on Arrays ._.
            this.$set(this.splittedText, index, object);
        },
        shuffleArray(array) {
            let counter = array.length;
            // While there are elements in the array
            while (counter > 0) {
                // Pick a random index
                let index = Math.floor(Math.random() * counter);
                // Decrease counter by 1
                counter--;
                // And swap the last element with it
                let temp = array[counter];
                array[counter] = array[index];
                array[index] = temp;
            }
            return array;
        },

        execClozeConfirm() {
            // ensure intial history
            if(!this.config.clozeConfig) {
                this.config.clozeConfig = {};
            }
            // deepCopy the intial State which gets uploaded as history
            const clozeCopy = {...this.config.clozeConfig};
            // then save new inputs under calcConfig
            this.saveState();
            this.$emit('history', { clozeConfig: clozeCopy });
        },

        saveState() {
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.clozeConfig.splittedText = this.splittedText;
                this.config.clozeConfig.textArea = this.textArea;
                this.config.clozeConfig.hiddenWords = this.hiddenWords;
                this.config.clozeConfig.mode = this.mode;
                this.config.clozeConfig.showAvailableWords = this.showAvailableWords;
                if (this.mode === 'dropdownWrong') {
                    this.config.clozeConfig.wrongWords = this.wrongWords;
                }
                this.config.clozeConfig.solutions = this.solutions;
                this.config.clozeConfig.sizeState = this.sizeState;
            }
        },

        hideWord(index) {
            if(!this.lockedTeacherComponent) {
                let shuffledString = this.shuffleArray(this.splittedText[index].word.split("")).join("");
                let object = {
                    word: this.splittedText[index].word, hidden: true, shuffledWord: shuffledString,
                }
                // Vue is not reactive on Arrays ._.
                this.$set(this.splittedText, index, object);
            }
            this.execClozeConfirm();
        },

        showWord(index) {
            if(!this.lockedTeacherComponent) {
                let object = {
                    word: this.splittedText[index].word, hidden: false,
                }
                // Vue is not reactive on Arrays ._.
                this.$set(this.splittedText, index, object);
                this.execClozeConfirm();
            }
        },

        // Split the input text by whitespaces into an array respecting linebreaks and punctuation
        async splitText(insertedText) {
            if(insertedText && insertedText !== '') {
                const text = insertedText.split(' ');
                let targetindex = 0;
                for(let i = 0; i < text.length; i++) {
                    if(text[i].match("([.,!?%:;])")) {
                        if(text[i].match("([.,!?%:;][\\S])")) {
                            let index = text[i].search("([.,!?%:;])");
                            let word1 = {
                                word: text[i].slice(0, index),
                            }
                            let puncation = {
                                word: text[i].charAt(index),
                            }
                            let word2 = {
                                word: text[i].slice(index + 1, text[i].length),
                            }
                            this.$set(this.splittedText, targetindex, word1);
                            targetindex++;
                            this.$set(this.splittedText, targetindex, puncation);
                            targetindex++;
                            this.$set(this.splittedText, targetindex, word2);

                        } else {
                            let wordWithoutPunctation = text[i].replace(/[.,/#!?$%^&*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ");
                            let word1 = {
                                word: wordWithoutPunctation,
                            }
                            let index = text[i].search("([.,!?%:;])");
                            let punctation = text[i].charAt(index);
                            let word2 = {
                                word: punctation,
                            }
                            // Vue is not reactive on Arrays ._.
                            this.$set(this.splittedText, targetindex, word1);
                            targetindex++;
                            this.$set(this.splittedText, targetindex, word2);
                        }
                    } else if(text[i].includes('\n')) {
                        // Get the index of all \n characters
                        const indexOfLineBreaks = [...text[i].matchAll(new RegExp('(\\n)', 'gi'))].map(a => a.index);
                        // Loop through all indices and push them in splittedText as separate words
                        for(let k = 0; k < indexOfLineBreaks.length + 1; k++) {
                            if(k === 0) {
                                // Push the first word without the \n
                                let extractedWord = text[i].slice(0, indexOfLineBreaks[k]);
                                this.$set(this.splittedText, targetindex, { word: extractedWord });
                                targetindex++;
                                // and push the \n
                                this.$set(this.splittedText, targetindex, { word: text[i].slice(indexOfLineBreaks[k], indexOfLineBreaks[k] + 1) });
                                targetindex++;
                            } else {
                                if (k === indexOfLineBreaks.length) {
                                    let extractedWord = text[i].slice(indexOfLineBreaks[k - 1] + 1, text[i].length);
                                    this.$set(this.splittedText, targetindex, {word: extractedWord});
                                    targetindex++;
                                } else {
                                    let extractedWord = text[i].slice(indexOfLineBreaks[k - 1] + 1, indexOfLineBreaks[k])
                                    if(extractedWord !== '') {
                                        this.$set(this.splittedText, targetindex, {word: extractedWord});
                                        targetindex++;
                                    }
                                    this.$set(this.splittedText, targetindex, { word: text[i].slice(indexOfLineBreaks[k], indexOfLineBreaks[k] + 1) });
                                    targetindex++;
                                }
                            }
                        }
                    } else {
                        let word = {
                            word: text[i],
                        }
                        this.$set(this.splittedText, targetindex, word);
                        targetindex++;
                    }
                }
            }
            await this.$nextTick();
            for(let i=0; i < this.splittedText.length; i++) {
                if(this.splittedText[i].word === '\n') {
                    const parentSpan = document.getElementById('clozeWord' + i);
                    if(parentSpan) {
                        parentSpan.after(document.createElement("br"));
                    }
                }
            }
        },
        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
            if(this.screenX < 800 || this.screenY < 800) {
                this.smallUI = true;
            }
            // alert(this.screenX + ' × ' + this.screenY);
        },
        addWrongWord() {
            if(this.mode !== 'dropdown') {
                this.mode = 'dropdownWrong';
            }
            this.wrongWords.push(this.wrongWord);
            this.wrongWord = '';
            this.addNewWrongWord = false;
        },
        updateHideStatus() {
            let i;
            for(i=0; i<this.answers.length; i++) {
                this.answers[i].hidden = this.hideAnswers;
            }
        },
    },
};
</script>

<style scoped lang="scss">
input:focus {
    outline: none;
}
textarea:focus {
    outline: none;
}

.hiddenForPupil {
  border-style: dotted;
  border-width: 5px;
  border-color: rgba(107, 105, 105, 0.15);
}

.userExerciseAnswerButton {
    float: right;
}

.icon {
    width: 25px;
    height: 25px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

#answerFieldOuter {
    -webkit-transform-origin: top left !important; // vllt hilft das ja sogar, sonst hab ich das mal für morgen reingeschrieben :D thx. bb.
    background-color: #00000000;
    display: block;
    overflow: hidden !important;
    resize: both !important;

    min-width: 200px;
    min-height: 50px;
}

.answerField {
    font-size: large;
    display: inline-flex;
    border-radius: 8px;

    p {
        max-width: 400px;
        overflow-wrap: break-word;
    }
}

#pupilAnswerTextArea {
    width: 100% !important;
    height: 100% !important;
    resize: none;
    outline: none;

}

.answerCorrect {
    border-top-style: inset;
    border-width: 5px;
    border-color: rgba(0, 224, 101, 0.733);
}

.answerWrong {
    border-top-style: inset;
    border-width: 5px;
    border-color: rgba(255, 0, 0, 0.555);
}

.acceptEntryButton {
    width: 36px !important;
    height: 36px !important;

    img {
        width: 20px !important;
        height: 20px !important;
    }
}

textarea.v-input {
    min-height: 10px !important;
}

input:focus {outline:none!important;}

</style>

<style lang="scss">

</style>
