<template>
    <v-menu
        top
        :nudge-top="usage==='list'?'140': usage==='BabyList'?'110':'90'"
        offset-y
        z-index="50"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="usage === 'list' || usage === 'BabyList'"
                icon
                v-on="on"
            >
                <v-icon>
                    mdi-emoticon-happy-outline 
                </v-icon>
                <p style="color:black; text-transform:none!important;">
                    Emoji
                </p>
            </v-btn>
            <v-icon
                v-else
                class="ml-1"
                style="opacity: 0.5"
                v-on="on"
            >
                mdi-emoticon-happy-outline 
            </v-icon>
        </template>
        <v-card
            class="pa-0 ma-0"
        >
            <div
                data-simplebar
                class="scrollContainer px-4 py-2"
            >
                <v-icon
                    v-for="(emoji, index) in emojis"
                    :key="index"
                    class="pa-1"
                    @click="selectEmoji(emoji)"
                >
                    {{ emoji.character }}
                </v-icon>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
export default {
  name: 'EmojiPicker',
  props: {
    usage: {required: false, type: String, default: ''}
  },
  data() {
    return {
      emojis: [
      ],
      showList: false,
    };
  },
  watch: {
  },
    created() {
    // Generate array of emoji objects
    const emojiRange = [0x1F600,0x1F64F];
    for (let i = emojiRange[0]; i <= emojiRange[1]; i++) {
      this.emojis.push({
        codePoint: i,
        character: String.fromCodePoint(i)
      });
    }
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("selectedEmoji", emoji.character);
      this.showList = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.emoji-selector {
  display: flex;
  align-items: center;
}

.emoji-selector v-icon {
  font-size: 18px;
  margin-left: 5px;
}

.scrollContainer {
	height: auto;
	max-height: 15vh; 
  width: auto;
  min-width: 28vw;
  max-width: 28vw;
  background-color: white;
}
@media only screen and (max-width: 900px) {
    .scrollContainer {
        width: auto;
        min-width: 300px;
    }
}



</style>
