<template>
    <v-dialog
        v-model="showItemDialog"
        transition="dialog-bottom-transition"
        persistent
    >
        <v-card
            class="dialogItem"
            :flat="scanning"
            @keydown.esc="closeDialog"
        >
            <v-card-title
                v-if="!scanning"
                style="display: flex; justify-content: space-between; align-items: center;  background-color: var(--v-headerblue-base); color: white"
            >
                <img
                    :src="bearbeitenIcon"
                    style="width: 20px; height: 20px; margin-right: 5px; margin-bottom: 3px"
                >
                <span :style="windowWidth < 500 ? 'font-size: smaller; margin-left: 5px;':''">{{ item ? 'Gegenstand bearbeiten' : 'Gegenstand anlegen' }} </span>
                <v-spacer v-if="windowWidth > 550" />
                <div v-if="windowWidth > 550">
                    <v-tooltip
                        v-if="item"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                elevation="3"
                                style="background-color: #e6221e;min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; margin-right: 16px"
                                v-on="on"
                                @click="showAreYouSureDelete = true"
                            >
                                <img
                                    :src="deleteIcon"
                                    class="icon20 iconToWhite"
                                    alt="Gegenstand löschen"
                                >
                            </v-btn>
                        </template>
                        <span> Gegenstand löschen </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                elevation="3"
                                style="min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; margin-right: 16px"
                                v-on="on"
                                @click="() => { showRecords = true; }"
                            >
                                <v-icon> mdi-script-text </v-icon>
                            </v-btn>
                        </template>
                        <span> Protokoll einsehen </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                elevation="3"
                                dark
                                style="text-transform: inherit; min-width: 40px; min-height: 40px; max-height: 40px; margin-right: 16px; background-color: var(--v-gruen-base)"
                                v-bind="attrs"
                                v-on="on"
                                @click="saveItem"
                            >
                                <img
                                    :src="speichernIcon"
                                    style="width: 25px; height: 25px; filter: brightness(1000%)"
                                >
                                <p
                                    v-if="!smartphoneUi"
                                    class="ml-2"
                                >
                                    Speichern
                                </p>
                            </v-btn>
                        </template>
                        <span>Gegenstand speichern</span>
                    </v-tooltip>
                     
                    <v-btn
                        elevation="0"
                        style="min-width: 40px; max-width: 40px; min-height: 40px; max-height: 40px; background-color: transparent"
                        @click="closeDialog"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="Fenster schließen"
                            style="height: 40px; filter: brightness(1000%)"
                        >
                    </v-btn>
                </div>
                <div
                    v-else
                    class="d-flex flex-row justify-space-around align-center"
                >
                    <v-menu
                        v-if="windowWidth < 550"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                icon
                                v-on="on"
                            >
                                <img
                                    :src="kebabMenuIcon"
                                    alt="Optionen"
                                    class="icon30 iconToWhite"
                                >
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="item"
                                @click="showAreYouSureDelete = true"
                            >
                                <v-list-item-icon>
                                    <img
                                        :src="deleteIcon"
                                        class="icon20"
                                        alt="Gegenstand löschen"
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Gegenstand löschen
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                @click="() => { showRecords = true; }"
                            >
                                <v-list-item-icon>
                                    <v-icon> mdi-script-text </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Protokoll einsehen
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                @click="saveItem"
                            >
                                <v-list-item-icon>
                                    <img
                                        :src="speichernIcon"
                                        style="width: 25px; height: 25px;"
                                    >
                                </v-list-item-icon>
                                <v-list-item-title>
                                    Speichern
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        elevation="0"
                        style="min-width: 25px; max-width: 25px; min-height: 25px; max-height: 25px; background-color: transparent"
                        @click="closeDialog"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="Fenster schließen"
                            style="height: 25px; filter: brightness(1000%)"
                        >
                    </v-btn>
                </div>
            </v-card-title>
            <div
                data-simplebar
                class="scroll-area"
                :settings="settings"
            >
                <div
                    class="dialogContainer"
                >
                    <div class="subcontainer">
                        <div style="display: flex; flex-direction: row; align-items: center">
                            <ColoredIconPicker
                                :selected-icon="itemIcon"
                                :selected-color="itemColor"
                                :icons="itemIcons"
                                :colors="itemColors"
                                :only-display="scanning"
                                @changedIcon="changeIcon"
                                @changedColor="changeColor"
                            />
                            <v-text-field
                                v-model="itemName"
                                :placeholder="scanning ? 'Gegenstand' : 'Name des Gegenstands'"
                                solo
                                :readonly="scanning"
                                hide-details
                            >
                                <template
                                    v-if="!scanning"
                                    v-slot:prepend-inner
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <template
                                    v-if="scanning && itemName"
                                    v-slot:append
                                >
                                    <img
                                        :src="fertigIcon"
                                        class="checkMark"
                                        alt="Fertig"
                                    >
                                </template>
                            </v-text-field>
                        </div>
                        <v-autocomplete
                            v-model="itemAccount"
                            :items="exchangeAccounts"
                            :item-text="item => item.name + ' ' + item.lastName"
                            :placeholder="scanning ? 'Ausgeliehen an' : '(Optional) Wer leiht den Gegenstand aus?'"
                            :append-icon="scanning ? '' : '$dropdown'"
                            return-object
                            :clearable="!scanning"
                            :readonly="scanning"
                            solo
                            hide-details
                        >
                            <template v-slot:item="{ item }">
                                <div>
                                    {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div>
                                    {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                                </div>
                            </template>
                            <template v-slot:prepend-inner>
                                <div>
                                    <v-icon>
                                        mdi-account
                                    </v-icon>
                                </div>
                            </template>
                            <template
                                v-if="scanning && itemAccount"
                                v-slot:append
                            >
                                <div>
                                    <img
                                        :src="fertigIcon"
                                        class="checkMark"
                                        alt="Fertig"
                                    >
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-textarea
                            v-model="itemDescription"
                            label="Beschreibung/Notiz"
                            solo
                            rows="6"
                            :readonly="scanning"
                            hide-details
                            style="margin: 8px 0 0 "
                        >
                            <template v-slot:prepend-inner>
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </template>
                        </v-textarea>
                        <!-- <v-textarea
                            v-model="itemReservationNote"
                            label="Reservieren für (Lehrername), von - bis"
                            :readonly="scanning"
                            hide-details
                            rows="3"
                            style="margin: 8px 0 0"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </template>
                        </v-textarea> -->
                        <div class="d-flex flex-column mt-2">
                            <v-btn
                                elevation="0"
                                color="var(--v-headerblue-base)"
                                class="mx-1 my-1"
                                @click="openReservationDialog"
                            >
                                <p style="color:white">
                                    Reservieren
                                </p>
                            </v-btn>
                            <v-btn
                                elevation="0"
                                color="var(--v-headerblue-base)"
                                class="mx-1 my-1"
                                @click="openReservationList"
                            >
                                <p style="color:white">
                                    Reservierungen anzeigen
                                </p>
                            </v-btn>
                        </div>
                        <v-scroll-x-transition>
                            <p
                                v-if="itemExchangedNotice"
                                class="noticeDialog"
                            >
                                {{ itemExchangedNotice }}
                            </p>
                        </v-scroll-x-transition>
                    </div>

                    <div
                        class="subcontainer"
                        style="justify-content: flex-start;"
                    >
                        <div style="margin-bottom: 20px; margin-top: 3px; display: flex; flex-direction: row; align-items: center">
                            <img
                                :src="kalenderIcon"
                                style="width: 25px; height: 25px; margin-bottom: 3px"
                            >
                            <p class="my-auto ml-2">
                                Ausgeliehen seit: {{ itemDateFrom }}
                            </p>
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: center">
                            <img
                                :src="kalenderIcon"
                                style="width: 25px; height: 25px; margin-bottom: 3px"
                            >
                            <p class="my-auto ml-2">
                                Ausleihfrist:
                            </p>
                        </div>
                        <v-date-picker
                            v-model="itemDateUntil"
                            locale="de-DE"
                            first-day-of-week="1"
                            :allowed-dates="weekdaysOnly"
                            :events="itemReservatedDates"
                            event-color="red"
                            no-title
                            show-week
                            color="var(--v-hellorange-base)"
                            @input="updateDateForItem"
                            @mouseenter:date="alarm"
                        />
                    </div>
                    <div
                        class="subcontainer mt-2"
                        style="justify-content: flex-start;"
                    >
                        <div style="display: flex; align-items: center; justify-content: space-between;  ">
                            <div>
                                <v-icon>
                                    mdi-image
                                </v-icon>
                                <span> Bilder des Gegenstands: </span>
                            </div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        elevation="3"
                                        color="var(--v-gruen-base)"
                                        style="width: 30px; height: 30px; border-radius: 5px"
                                        x-small
                                        v-on="on"
                                        @click="handleUploadButtonClick"
                                    >
                                        <img
                                            :src="hinzufuegenIcon"
                                            alt="Neuen Gegenstand anlegen"
                                            style="filter: brightness(1000%); width: 20px; height: 25px"
                                        >
                                    </v-btn>
                                </template>
                                <span> Bilder in die Galerie hochladen </span>
                            </v-tooltip>
                        </div>
                        <v-carousel
                            v-if="itemFiles.length"
                            :key="forceReload"
                            class="carousel"
                        >
                            <v-carousel-item
                                v-for="(file, index) in itemFiles"
                                :key="file+index"
                                :src="item ? file.hasThumbnail ? file.thumbnail : img_eklaraLogo : file"
                                @click="item ? enableFullscreenPreview(file) : ''"
                            >
                                <div
                                    class="d-flex flex-row justify-end mt-2"
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                style="background-color: #e6221e; border-radius: 3px; width: 35px; height: 35px;"
                                                x-small
                                                v-on="on"
                                                @click="handleDeleteButtonClick(index)"
                                            >
                                                <img
                                                    :src="deleteIcon"
                                                    class="icon20 iconToWhite"
                                                    alt="Löschen"
                                                >
                                            </v-btn>
                                        </template>
                                        <span> Dieses Bild löschen </span>
                                    </v-tooltip>
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                        <p v-else>
                            Aktuell 0 Bilder hochgeladen.
                        </p>
                        <input
                            ref="fileInput"
                            style="display: none; margin-top: 10px"
                            type="file"
                            label="Bilder hinzufügen"
                            multiple
                            @change="previewFiles"
                        >
                    </div>
                </div>
            </div>
            <v-dialog
                v-if="showRecords"
                v-model="showRecords"
                content-class="recordsDialog"
                max-width="50vw"
                @click:outside="showRecords = false"
            >
                <v-card
                    style="display: flex; flex-direction: column; width: 50vw; align-items: center;
                        padding-top: 20px; padding-bottom: 40px;"
                >
                    <v-card-title
                        class="pa-0 "
                        style="position: absolute; top: 5px; right: 5px;"
                    >
                        <v-btn
                            elevation="0"
                            style="min-width: 25px; max-width: 25px; min-height: 25px; max-height: 25px; background-color: transparent"
                            @click="showRecords = false"
                        >
                            <img
                                :src="abbrechenIcon"
                                alt="Fenster schließen"
                                style="height: 25px;"
                            >
                        </v-btn>
                    </v-card-title>
                    <div style="display: flex">
                        <v-icon>
                            mdi-script-text
                        </v-icon>
                        <p> Protokoll: </p>
                    </div>
                    <div
                        v-for="(record, index) in item.history"
                        :key="record+'-'+index"
                        style="display: inline-block"
                    >
                        <span> {{ (record.dateFrom && record.dateUntil) ? new Date(record.dateFrom).toLocaleDateString('de-DE') + ' bis ' + new Date(record.dateUntil).toLocaleDateString('de-DE') + '' : '' }}</span>
                        <br
                            v-if="record.dateFrom && record.dateUntil"
                        >
                        <span>
                            {{ record.account ? ('Im Besitz von ' + record.account.name + ' ' + record.account.lastName + ` ${ record.account.groupNames ? record.account.groupNames : '' }`)
                                : 'Im Inventar' }}
                        </span>
                    </div>
                </v-card>
            </v-dialog>
        </v-card>
        <v-dialog
            v-if="showReservationListDialog"
            v-model="showReservationListDialog"
            max-width="95vw"
            @click:outside="showReservationListDialog = false"
        >
            <v-card
                style="display: flex; flex-direction: column; "
            >
                <v-card-title
                    class="d-flex flex-row justify-space-between pa-0 ma-0"
                >
                    <div class="d-flex flex-row justify-space-around">
                        <v-icon>
                            mdi-script-text
                        </v-icon>
                        <p> Reservierungen für {{ item.name }}:  </p>
                    </div>
                    <v-btn
                        elevation="0"
                        style="min-width: 25px; max-width: 25px; min-height: 25px; max-height: 25px; background-color: transparent"
                        @click="showReservationListDialog = false"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="Fenster schließen"
                            style="height: 25px;"
                        >
                    </v-btn>
                </v-card-title>
               
                
                <table class="table my-2 mx-6">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>von</th>
                            <th>bis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="reservation in exchangeReservations"
                            :key="reservation._id"
                        >
                            <td>{{ getAccountName(reservation.account) }}</td>
                            <td>{{ formatDate(reservation.dateFrom) }}</td>
                            <td>{{ formatDate(reservation.dateUntil) }}</td>
                        </tr>
                    </tbody>
                </table>
            </v-card>
        </v-dialog>
        <ConfirmSavePopup
            v-if="showConfirmSavePopop"
            :show-dialog="showConfirmSavePopop"
            :content-text-prop="item ? 'Sie sind im Begriff die Bearbeitung dieses Gegenstandes abzubrechen. Wollen Sie ihre Änderungen trotzdem speichern?'
                : 'Sie sind im Begriff die Erstellung dieses Gegenstandes abzubrechen. Wollen Sie den Gegenstand trotzdem speichern?'"
            @discard="discardChanges"
            @abortMission="showConfirmSavePopop = false"
            @save="saveItem"
        />
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :files="[]"
            :enable-fullscreen-preview="enableFilePreview"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :usage="'exchange'"
            @close="resetFullscreenPreview"
            @download="downloadFile(previewSelection)"
        />
        <AreYouSurePopup
            v-if="showAreYouSureDelete"
            :show-dialog="showAreYouSureDelete"
            :header-color="'#343e55'"
            :content-text-prop="`Sie sind dabei das Gerät ${item.name} zu löschen. Wollen Sie diese Aktion wirklich ausführen?`"
            ok-btn-color="frot"
            :usage="'exchange'"
            @close="showAreYouSureDelete = false"
            @ok="deleteItem"
        />
        <v-dialog
            v-if="showReservationDialog"
            v-model="showReservationDialog"
            max-width="350px"
            content-class="rounded-4"

            style="border-radius: 16px !important;"
            @click:outside="showReservationDialog = false"
        >
            <v-card
                class="pa-0"
                style="border-radius: 16px;"
            >
                <v-card-title
                    class="d-flex align-center justify-space-between px-2 py-1"
                    style="background-color:var(--v-headerblue-base)"
                >
                    <div class="d-flex flex-row justify-space-around">
                        <v-icon
                            color="white"
                            class="mr-2"
                        >
                            mdi-script-text
                        </v-icon>
                        <p style="color:white">
                            Reservieren
                        </p>
                    </div>
                    <v-btn
                        elevation="0"
                        icon
                        class="icon20"
                        @click="showReservationDialog = false;"
                    >
                        <img
                            :src="abbrechenIcon"
                            alt="Fenster schließen"
                            class="iconToWhite icon20"
                        >
                    </v-btn>
                </v-card-title>
                <div class="d-flex flex-column align-center px-2">
                    <v-autocomplete
                        v-model="reservationAccount"
                        :items="exchangeAccounts"
                        :item-text="item => item.name + ' ' + item.lastName"
                        :placeholder=" 'Reservieren für'"
                        :append-icon="'$dropdown'"
                        return-object
                        :clearable="!scanning"
                        :readonly="scanning"
                        solo
                        hide-details
                    >
                        <template v-slot:item="{ item }">
                            <div>
                                {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div>
                                {{ item.name + ' ' + item.lastName + ` ${ item.groupNames ? item.groupNames : '' }` }}
                            </div>
                        </template>
                        <template v-slot:prepend-inner>
                            <div>
                                <v-icon>
                                    mdi-account
                                </v-icon>
                            </div>
                        </template>
                        <template
                            v-if="scanning && itemAccount"
                            v-slot:append
                        >
                            <div>
                                <img
                                    :src="fertigIcon"
                                    class="checkMark"
                                    alt="Fertig"
                                >
                            </div>
                        </template>
                    </v-autocomplete>
                    <v-date-picker
                        id="my-date-picker"
                        v-model="dateRange"
                        range
                        :allowed-dates="weekdaysAndReservated"
                        :events="itemReservatedDates"
                        event-color="red"
                        color="headerblue"
                        class="mt-2"
                        :first-day-of-week="1"
                        locale="de-De"
                        no-title
                        @change="sortRanges"
                        @mouseenter:date="alarm"
                    />
                    <div
                        class="d-flex flex-column justify-space-around align-center"
                        style="margin-left:-120px;"
                    >
                        <div class="mb-1">
                            Von: {{ dateRange[0]? new Date(dateRange[0]).toLocaleDateString('de-DE'):'' }}
                        </div>
                        <div class="mt-1">
                            Bis: {{ dateRange[1]?new Date(dateRange[1]).toLocaleDateString('de-DE'):'' }}
                        </div>
                    </div>
                </div>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        elevation="0"
                        color="success"
                        @click="createReservation"
                    >
                        Bestätigen                       
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import abbrechenIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg';
import fertigIcon from "../../assets/Icons/fertig-gruen-74.svg";
import speichernIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import kalenderIcon from '@/assets/Icons/FaceliftIcons/kalender_mitDatum.svg';
import hinzufuegenIcon from '@/assets/Icons/FaceliftIcons/plus_hinzufuegen_weiteres.svg';
import img_eklaraLogo from '@/assets/Icons/eklara-logoV2.svg';
import kebabMenuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";

import {mapMutations, mapActions, mapState} from "vuex";
import * as backend from "../../api/backend";
import ColoredIconPicker from "@/components/Utils/ColoredIconPicker";
import ConfirmSavePopup from "@/components/Utils/ConfirmSavePopup";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";


export default {
    name: "ExchangeItemDialog", components: {
        AreYouSurePopup,
        ConfirmSavePopup,
        ColoredIconPicker,
        FilePreviewDialog,
    },
    props: {
        showItemDialog: {type: Boolean, required: true, default: false},
        exchangeAccounts: {type: Array, required: true},
        item: {type: Object, required: false},
        items: {type: Array, required: false},
        account: {type: Object, required: false},
        scanning: {type: Boolean, required: false},
        itemExchangedNotice: {type: String, required: false},
        smartphoneUi: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
            // icons
            kebabMenuIcon,
            abbrechenIcon,
            fertigIcon,
            deleteIcon,
            bearbeitenIcon,
            speichernIcon,
            kalenderIcon,
            hinzufuegenIcon,
            img_eklaraLogo,

            enableFilePreview: false,
            previewSelection: null,

            showItemDialogBool: false,
            createItemDialog: true,

            showConfirmSavePopop: false,

            fileUploadQueue: [],
            fileDeleteQueue: [],

            newItem: null,

            // item creation & update input
            itemIcon: '',
            itemColor: '',
            itemName: '',
            itemDescription: '',
            itemReservationNote:'',
            itemAccount: '',
            itemDateFrom: null,
            itemDateUntil: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            itemFiles: [],

            filesChanged: false,

            blockCloseDialog: false,

            //toggleView
            showRecords: false,

            forceReload: 0,

            // This can only be mdi known icons custom images are possible for the picker but are not known in several
            // other places in the exchange widget
            itemIcons: ['mdi-tablet-android', 'mdi-battery-charging-low', 'mdi-book-open-variant', 'mdi-chess-rook',
                'mdi-guitar-acoustic', 'mdi-marker', 'mdi-laptop', 'mdi-headphones'],
            itemColors: [
                '#fcd20a', '#f0b423', '#ff6941', '#91c364', '#91c887', '#d7875f', '#ebaabe', '#9b91c8',
                '#6ec3dc', '#9bcdc3', '#ff6633', '#707070'
            ],

            // Delete Item
            showAreYouSureDelete: false,

            //reservations
            showReservationDialog: false,
            reservationAccount: false,
            dateRange: [],
            itemReservatedDates: [],
            reservations: new Map(),
            showReservationListDialog: false,
            exchangeReservations: [],
        }
    }, computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        isChanged() {
            if (this.item) {
                if (!this.filesChanged) {
                    if (this.itemIcon === this.item.icon) {
                        if (this.itemColor === this.item.color) {
                            if (this.itemName === this.item.name) {
                                if (this.itemDescription === this.item.description) {
                                    if (this.itemReservationNote === this.item.reservationNote) {

                                        if (this.itemAccount === this.item.account) {
                                            if (this.itemDateFrom === this.item.dateFrom) {
                                                if (this.itemDateUntil === this.item.dateUntil) {
                                                    return false;
                                                    }
                                                }
                                            }
                                        }                                    
                                    }
                                }
                            }
                        }
                    }
                }
            return true;
        },
    }, watch: {
        account() {
            this.itemAccount = this.account;
        }, item: {
            immediate: true, async handler() {
                if (this.item) {
                    // fill form
                    if (this.item.name) {
                        this.itemName = this.item.name;
                    }
                    if (this.item.description) {
                        this.itemDescription = this.item.description;
                    }
                    if (this.item.reservationNote) {
                        this.itemReservationNote = this.item.reservationNote;
                    }
                    if (this.item.account && !this.scanning) {
                        this.itemAccount = this.item.account;
                    }
                    if (this.item.dateFrom) {
                        this.itemDateFrom = this.item.dateFrom;
                    } else {
                        this.itemDateFrom = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                    }
                    if (this.item.dateUntil) {
                        this.itemDateUntil = this.item.dateUntil;
                    }
                    if (this.item.color) {
                        this.itemColor = this.item.color;
                    } else {
                        this.itemColor = '#343e55';
                    }
                    if (this.item.icon) {
                        this.itemIcon = this.item.icon;
                    } else {
                        this.itemIcon = 'mdi-tablet-android';
                    }
                    if (this.item.files) {
                        this.itemFiles = [];
                        // todo clean up old URLS
                        for (let index in this.item.files) {
                            const res = await backend.getExchangeFileThumbnail(this.item.files[index]._id, this.item.files[index].accessToken, this.item._id);
                            let blob = await res.blob();
                            var file = {
                                _id: this.item.files[index]._id,
                                accessToken: this.item.files[index].accessToken,
                                thumbnail: window.URL.createObjectURL(blob),
                                hasThumbnail: blob.type !== 'text/plain; charset=utf-8',
                            }
                            this.itemFiles.push(file);
                        }
                        this.forceReload++;
                    }
                } else {
                    // reset form
                    this.itemName = '';
                    this.itemDescription = '';
                    this.itemReservationNote = '';
                    this.itemAccount = '';
                    this.itemIcon = 'mdi-tablet-android';
                    this.itemColor = '#343e55';
                    this.itemFiles = [];
                    this.itemDateFrom = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                    this.itemDateUntil = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                }
             }
        }
    },
    async mounted() {
        this.showItemDialogBool = this.showItemDialog;
        await this.loadReservations();
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('mimeTypes', ['validateMimeType']),
        formatDate(date) {
            return new Date(date).toLocaleDateString('de-DE');
        },
        openReservationList(){
            this.showReservationListDialog = true;
        },
       getAccountName(id){
        const fullAccount = this.exchangeAccounts.filter((account)=> account._id === id);
        return `${fullAccount[0].name}  ${fullAccount[0].lastName}`;
       },
       alarm(date){
            if(!this.itemReservatedDates.includes(date)){
                return;
            }
            const reservierung = this.getReservationByDate(date);
            this.showSnackbar({message:`Reserviert von: ${reservierung.length ? this.getAccountName(reservierung[0].account) : '??'}` ,color:'warning'});
        },
        getDatesFromRange(startDate, endDate) {
            const dateArray = [];
            let currentDate = new Date(startDate);
            const lastDate = new Date(endDate);

            while (currentDate <= lastDate) {
                dateArray.push(currentDate.toISOString().split('T')[0]);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return dateArray;
            },
        openReservationDialog(){
            this.showReservationDialog = true;
        },
        async loadReservations(){
            const res = await backend.getExchangeReservations(this.item._id);
            const reservationObjects = await res.json();
            this.exchangeReservations = reservationObjects.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom));
            const reservationMap = new Map();
            const dates = reservationObjects.flatMap(obj => {
            const dateRange = this.getDatesFromRange(obj.dateFrom, obj.dateUntil);
                dateRange.forEach(date => {
                    if (!reservationMap.has(date)) {
                    reservationMap.set(date, []);
                    }
                    reservationMap.get(date).push(obj);
                });
                return dateRange;
            });
                                          
            const uniqueDates = new Set(dates);
            this.itemReservatedDates = Array.from(uniqueDates);
            this.reservations = reservationMap;
        },
        getReservationByDate(date) {
            return this.reservations.get(date) || [];
        },
        async createReservation(){
            if(!this.reservationAccount ||
            !this.dateRange.length){
                this.showSnackbar({message:`Bitte wählen Sie ${!this.reservationAccount && !this.dateRange.length   ? 'den Nutzer und das Datum' : !this.reservationAccount ? 'den Nutzer' : 'das Datum' } für die Reservierung aus.` ,color:'error'})
                return;
            }
            const dateFrom = this.dateRange[0];
            const dateUntil = this.dateRange.length === 2 ? this.dateRange[1] : this.dateRange[0];
            
            const body = {
                account: this.reservationAccount._id,
                dateFrom: dateFrom,
                dateUntil: dateUntil
                }
            const res = await backend.postExchangeReservation(this.item._id,body);

            if(res.status === 201){
                this.showSnackbar({message: `${this.item.name} wurde für ${this.reservationAccount.name} ${this.reservationAccount.lastName} erfolgreich reserviert.` , color:'success'})
                this.showReservationDialog = false;
                this.resetReservationDialog();
                await this.loadReservations(); //potential bottleneck, needs more feedback
            }else{
                this.showSnackbar({message: 'Bei der Reservierung ist etwas schiefgelaufen! Laden Sie die Seite neu und versuchen es erneut.',color:'error'})
            }
        },
        resetReservationDialog(){
            this.dateRange = [];
            this.reservationAccount = null;
        },
         weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },
         weekdaysAndReservated(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6  && !this.itemReservatedDates.includes(val);
        },
        sortRanges(val){
             this.dateRange = val.sort((a, b) => new Date(a) - new Date(b));
        },
        deleteItem() {
            this.$emit('deleteItem', this.item);
            this.showAreYouSureDelete = false;
            this.$emit('toggleItemDialog');
        },

        async enableFullscreenPreview(item) {
            const res = await backend.getFileInformation(item._id, item.accessToken);
            const file = await res.json();
            file.thumbnail = item.thumbnail;
            file.hasThumbnail = item.hasThumbnail;
            file.fileAccessToken = item.accessToken;
            this.previewSelection = file;
            this.enableFilePreview = true;
        },

        resetFullscreenPreview() {
            this.previewSelection = null;
            this.enableFilePreview = false;
        },

        async discardChanges() {
            if (this.item) {
                this.showSnackbar({
                    message: 'Ihre Änderungen wurden verworfen.',
                    color: 'success'
                });
                await this.refreshItems();
            }
            this.$emit('toggleItemDialog');
        },
        async closeDialog() {
            if (this.blockCloseDialog) {
                return;
            }
            //TODO: Choosing an account as borrower counts as click:outside, fix it.
            if(this.isChanged) {
                this.showConfirmSavePopop = true;
                return;
            }

            this.$emit('toggleItemDialog');
        },
        async saveItem() {
            if (this.item) {
                await this.patchItem();
            } else {
                await this.createNewItem();
            }
        },
        async updateDateForItem() {
            if(this.scanning && this.item) {
                await backend.updateExchange(this.item._id, { dateUntil: this.itemDateUntil });
                this.$emit('changeItemExchangedNotice', 'Rückgabedatum auf ' + this.itemDateUntil + ' gesetzt');
                this.$emit('refreshItems');
            }
        },

        changeIcon(icon) {
            this.itemIcon = icon;
        },

        changeColor(color) {
            this.itemColor = color;
        },

        refreshItems() {
            this.$emit('refreshItems');
        },
        handleUploadButtonClick() {
            //TODO: find a better way to detect changes to itemFiles
            this.filesChanged = true;
            this.$refs.fileInput.click();
        },

        async previewFiles(event) {
            const files = Array.from(this.$refs.fileInput.files);
            if (await this.validateMimeType(files)) {
                event.target.files.forEach((el) => {
                    var url = URL.createObjectURL(el);
                    this.itemFiles.push(url);
                    this.fileUploadQueue.push(el);
                });
            }
            this.$refs.fileInput.type = '';
            this.$refs.fileInput.type = 'file';
        },

        async handleDeleteButtonClick(index) {
            if (!this.item) {
                // its a local file pushed into the carousel as a preview
                // delete both the preview and the queued upload
                this.itemFiles.splice(index, 1);
                this.fileUploadQueue.splice(index, 1);
                return;
            }
            if (index >= this.item.files.length) {
                // its a local file pushed into the carousel as a preview
                // delete both the preview and the queued upload
                this.itemFiles.splice(index, 1);
                this.fileUploadQueue.splice(index - this.item.files.length, 1);
                return;
            } else {
                // delete file in backed and delete preview
                // todo: verify: does this handle correctly when deleting + adding files in an array of already saved files?
                this.fileDeleteQueue.push(this.item.files[index]);
                this.itemFiles.splice(index, 1);
            }
        },

        async createNewItem() {
            if (!this.itemAccount) {
                this.itemDateFrom = undefined;
                this.itemDateUntil = undefined;
            }
            let body = {
                name: this.itemName,
                account: this.itemAccount ? this.itemAccount._id : undefined,
                description: this.itemDescription,
                reservationNote: this.itemReservationNote,
                dateFrom: this.itemDateFrom,
                dateUntil: this.itemDateUntil,
                icon: this.itemIcon,
                color: this.itemColor,
            }
            let res = await backend.postExchange(body);
            this.newItem = await res.json();
            await this.handleFileUpload();
            this.showSnackbar({
                message: 'Gegenstand wurde erfolgreich erstellt und gespeichert!',
                color: 'green'
            })
            this.itemName = '';
            this.itemAccount = '';
            this.itemDescription = '';
            this.itemReservationNote = '';
            this.dateFrom = null;
            this.dateUntil = null;
            this.itemIcon = '';
            this.itemColor = '';
            this.createItemDialog = false;
            await this.refreshItems();
            this.$emit('toggleItemDialog');
        },

        async patchItem() {

            if (!this.itemAccount) {
                this.itemAccount = '';
            }
            if (this.item.account === null || typeof this.item.acount === undefined) {
                this.item.account = '';
            }
            // did the ownership change?
            if ((this.itemAccount) != (this.item.account)) {
                if (this.itemAccount) {
                    // if given to a new owner, set DateFrom = today
                    this.itemDateFrom = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                } else {
                    // with no owner, unset DateFrom and DateUntil
                    this.itemDateFrom = '';
                    this.itemDateUntil = '';
                }
            }

            let body = {
                name: this.itemName,
                account: this.itemAccount ? this.itemAccount._id : 'remove',
                description: this.itemDescription,
                reservationNote: this.itemReservationNote,
                dateFrom: this.itemDateFrom,
                dateUntil: this.itemDateUntil,
                icon: this.itemIcon,
                color: this.itemColor,
            }
            const res = await backend.updateExchange(this.item._id, body);
            await this.handleFileUpload();
            this.showSnackbar({
                message: 'Gegenstand wurde erfolgreich bearbeitet und gespeichert!',
                color: 'green'
            })

            this.itemName = '';
            this.itemAccount = '';
            this.itemDescription = '';
            this.itemReservationNote = '';
            this.itemDateFrom = null;
            this.itemDateUntil = null;
            this.itemIcon = '';
            this.itemColor = '';
            this.updateItemDialog = false;
            await this.refreshItems();
            this.$emit('toggleItemDialog');
        },
        async handleFileUpload() {
            for (let index=0; index < this.fileUploadQueue.length; index++) {
                let itemId;
                if (this.item) {
                    itemId = this.item._id;
                } else {
                    itemId = this.newItem._id;
                }
                let res = await backend.appendExchangeFile(itemId, this.fileUploadQueue[index]);
            }
            this.fileUploadQueue = [];
            await Promise.all(this.fileDeleteQueue.map(async (el) => {
                await backend.deleteExchangeFile(this.item._id, el);
            }));
            this.fileDeleteQueue = [];
        },
        async downloadFile(file) {
            const res = await backend.getExchangeFile(this.item._id, file._id, file.fileAccessToken);
            let blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        }
    },
}
</script>
<style scoped>
.table {
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: .25rem;
}

.table th {
    padding-top: .25rem;
    padding-bottom: .25rem;
    text-align: left;
    background-color: #f2f2f2;
}
.dialogItem {
        width: 100%;
        height: calc(70vh + 65px);
        border-radius: 16px;
}

    .noticeDialog {
        padding: 8px;
        background-color: var(--v-success-base);
        color: white;
        font-size: large;
        text-align: center;
        border-radius: 8px;
        margin: 8px 0 0 68px;
    }

    .checkMark {
        width: 24px;
        height: 24px;
        animation: poppingZoom 200ms linear 0s 1 forwards;
    }

    .carousel {
        height: 20vh;
        max-height: 50vh;
        max-width: 33vw;
        min-width: 25vw;
        padding-right: 15px;
    }

    @keyframes poppingZoom {
        25% { width: 42px; height: 42px }
        75% { width: 30px; height: 30px }
        100% { width: 42px; height: 42px }
    }

    @media only screen and (max-width: 900px){
        .carousel {
            height: 35vh;
            max-width: 400px;
        }

    }
    @media (max-width: 600px) {
        .subcontainer {
            flex: 1 1 100%; /* Stacks items vertically on small screens */
        }
    }

        .dialogContainer {
            /* overflow-x: scroll; */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px; 
            padding-left: .5rem;
            padding-right: .5rem;
            overflow-x:hidden;
            height: auto;
        }
        .subcontainer {
            flex: 1 1 calc(33.333% - 12px); /* Adjusts item to take up 1/3 of the container width minus the gap */
            box-sizing: border-box;
            display: flex;
            flex-direction: column; /* Enables Flexbox for this item */
            justify-content: center; /* Centers content horizontally */
        }
        .scroll-area {
            height: auto;
            max-height: 69vh;
            overflow-x:hidden;
        }


</style>
<style>
    .recordsDialog {
    display: flex !important;
    justify-content: center !important;
    }
    #my-date-picker .v-btn--disabled {
        pointer-events: auto !important;
    }
</style>
