<template>
    <div
        class="d-flex justify-center align-center flex-column editorTemplateBrowserElement greyBorder"
        @click="itemSelected"
    >
        <div
            v-if="loadingIconActive"
            class="thumbnailSize folderIcon"
            style="flex-wrap: wrap"
        >
            <v-progress-circular
                indeterminate
                size="50"
                color="grey"
            />
        </div>
        <div
            v-else
            style="overflow: hidden"
        >
            <v-img
                v-if="item.type === 'file' && !previewPDF"
                :src="preview"
                contain
                min-width="75px"
                max-width="250px"
                :alt="item.name || item.uploadedName"
                class="thumbnailSize"
            >
                <div class="fill-height bottom-gradient" />
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <!-- <v-progress-circular
                            indeterminate
                            color="grey"
                        /> -->
                    </v-row>
                </template>
            </v-img>
            <!-- Add Icons for templates -->
            <div
                v-else-if="type === 'template'"
                style="width: 200px; overflow: hidden; border: 1px solid var(--v-hellgrau-base); margin-top: 20px;"
            >
                <img
                    :src="item.preview"
                    :alt="`Vorschau für ${item.itemType}-Template`"
                    class="thumbnailSize templatePreview"
                    width="200px"
                    height="50px"
                >
            </div>
            <v-icon
                v-else
                class="pr-1 folderIcon thumbnailSize"
                x-large
            >
                mdi-folder-account
            </v-icon>
        </div>

        <p class="pa-2 mx-auto itemTitle">
            {{ title }}
        </p>

        <v-btn
            v-if="item.type === 'file'"
            elevation="0"
            x-small
            width="35px"
            height="35px"
            class="pa-0 text-none templateBrowserItemOptionsMenuButton"
            @click="loadFullscreenPreview"
        >
            <v-icon style="width: 20px; color: #939393">
                mdi-eye
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import { mapActions } from "vuex";
import img_eklaraLogo from "@/assets/EditorLibraryMock/eklara_logo.png";

export default {
    name: 'EditorTemplateBrowserItem',
    props: {
        type: { required: true, type: String },
        item: { required: true, type: Object },
    },
    data: () =>({
        preview: null,
        previewPDF: false,
        loadingIconActive: false,

        title: null,

    }),
    async mounted() {
        if (this.item.name) {
            this.title = this.item.name;
        }

        await this.initThumbnail();
    },
    methods: {
        ...mapActions('subjects', ['getThumbnail']),

        //#region Thumbnail handling
        async initThumbnail() {
            if(this.type === 'folder') {
                return;
            }
            // request thumbnail identified by fileId
            // TODO nice-to-have: grab a low resolution preview here to save traffic
            // we can use the higher-resolution preview on the fullscreen Dialog instead
            let thumb = null;
            if (this.item.itemType && this.item.itemType === 'Document') {
                const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                    this.item.value,
                    this.item.thumbnail);
                thumb = await thumbnailResponse.blob();
            }
            // Todo find way to distinguish different files, somethings up here
            // else {
            //     thumb = await this.getThumbnail(this.item._id);
            // }
            this.previewPDF = false;
            if(thumb) {
                this.preview = URL.createObjectURL(thumb);
                this.$emit('objectURL',this.preview);
                if (thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.preview = img_eklaraLogo;
            }
        },
        //#endregion

        //#region Selection Handling
        itemSelected() {
            this.loadingIconActive = true;
            setTimeout(() => {
                if (this.type === 'folder' || this.item.itemType === 'Folder') {
                    this.$emit('folderSelected', this.deactivateLoading);
                } else if (this.type === 'template'){
                    this.$emit('templateSelected', this.item.itemType, this.deactivateLoading);
                } else {
                    this.$emit('itemSelected', this.deactivateLoading);
                }
            }, 125);
        },
        //#endregion

        deactivateLoading() {
            this.loadingIconActive = false;
        },
        loadFullscreenPreview(event) {
            event.stopPropagation();
            this.$emit('loadFullscreenPreview', this.item)
        },
    }
}
</script>

<style scoped lang="scss">
.editorTemplateBrowserElement {
    display: flex;
    position: relative;
    border-radius: 4px;
    color: var(--v-dunkelgrau-base);
    box-shadow: 1px 2px 3px silver;
    width: 90%;
    white-space: nowrap;
    background-color: #fff;
    cursor: pointer;
}

.greyBorder {
    border-radius: 6px !important;
    border-width: 1px;
    border-color: #a5a5a5;
    border-style: solid;
    box-shadow: none !important;

    :hover {
        background-color: var(--v-dunkelgrau-base)1f !important;
    }
}

.itemTitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

}

.thumbnailSize {
    max-height: 14vh;
    min-height: 14vh;
    margin-top: 20px;
}

.folderIcon {
    display: flex;
    justify-content: center;
    align-content: space-around;
}

.templatePreview {
    //transform: scale(2.5);
    //filter: contrast(95%) saturate(2);
    overflow: hidden;
}

.templateBrowserItemOptionsMenuButton {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 40px;
    right: 10px;
}
</style>
