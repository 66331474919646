<template>
    <v-card style="border-radius: 16px">
        <v-card-title
            style="background-color: var(--v-userColor-base)"
            class="pa-2 px-2 pl-4"
        >
            <p style="color: white">
                Neuen Standort anlegen
            </p>

            <v-spacer />

            <v-btn
                icon
                @click="()=> { $emit('stopEvent'); resetData(); }"
            >
                <img
                    :src="cancelIcon"
                    class="icon20 iconToWhite"
                    alt="schließen"
                >
            </v-btn>
        </v-card-title>
        <v-card-text>
            <div class="d-flex justify-center align-center mt-2">
                <v-text-field
                    v-model="locationName"
                    class="ml-2"
                    label="Name eingeben"
                    filled
                    dense
                    outlined
                    hide-details
                    style="border-radius: 10px"
                    type="text"
                    @change="validateInput"
                />
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                class="saveButton text-none"
                color="success"
                elevation="0"
                :loading="loadingSave"
                @click="saveLocation"
            >
                Speichern
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

// Import the styles too, globally

export default {
    name: "CreateLocation",

    props: {
        addNewLocation: {type: Function},
    },
    data: () => ({
        cancelIcon,

        showLoadingOverlay: false,
        loadingSave: false,
        selected: [],
        search:"",

        locationName: '',

        fields: {
            name: false,
        },

        height: 0,

    }),
    computed: {
        ...mapGetters('teachers', [ 'getTeacherStatus' ]),
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
    },
    watch: {
    },
    async created() {
        this.height = window.innerHeight * 0.4
    },
    async mounted() {
        this.showLoadingOverlay = true;
        await this.getLocations(true);
    },
    methods: {
        ...mapActions("location", ["getLocations", "createLocation"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

     

        validateInput() {
            let result = true;

            this.fields.name = false;

            if (this.locationName.replace(/\s/g, '') === '') {
                this.fields.name = true;
                result = false;
            }

            return result;
        },
        resetData() {
            this.locationName = "";
        },

        async saveLocation() {
            try {
                this.loadingSave = true;
                this.showLoadingOverlay = true;

                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }


                let data = {
                    name: this.locationName,
                };
                let response = await this.createLocation(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Standortnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    // this.addNewSubject(response);
                    this.$emit('addLocation', response);
                    // this.setSubjectListStatus(true);
                    this.showSnackbar({ message: 'Gespeichert!'});
                    this.$emit('stopEvent');
                }
                this.loadingSave = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table, tr, td {
    border: none;
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.input{
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width:0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance:none
}

.input[disabled]{
    color: grey;
    background-color: #CACACA;
}

.errorInput {
    border: 2px solid red !important;
}
.editContainer {
    width: 25%;
    border-radius: 16px;
    margin: auto;
    background-color:#dfdfdf
}
.innerContainer {
    background-color: #ffffff;
    border-radius: 15px;
    margin: 1.5%;
}
.scrollable{
    max-height: 40vh;
    overflow-y: auto;
}

.iconSelect {
    width: 42px;
    padding: 2px;
    border: solid 2px #EAEAEA;
    border-radius: 10px;
    margin-right: 2px;
}

.iconSelect[selected] {
    width: 42px;
    padding: 2px;
    border: solid 2px #737373;
    border-radius: 10px;
}

.mainControlButtons {
    font-weight: 300;
    font-size: 10px;
    border-radius: 8px;

    & span {
        line-height: 0 !important;
    }
}

.mainFunctionButtons {
    width: 38px !important;
    height: 38px !important;
    border-radius: 8px;

    img {
        width: 27px !important;
        height: 27px !important;
        filter: brightness(1000%);
    }
}

.infoTextToolTip {
    max-width: 250px;
    background-color: var(--v-blau-base);
}
</style>

