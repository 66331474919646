const getWeekDayDate = (dayString, date) => {
    let adjustment = 0;
    // Kann auch auf Deutsch umgestellt werden
    switch (dayString) {
        case 'mon': adjustment = 1; break;
        case 'tue': adjustment = 2; break;
        case 'wed': adjustment = 3; break;
        case 'thu': adjustment = 4; break;
        case 'fri': adjustment = 5; break;
    }

    const newDate = new Date(date);
    let day = newDate.getDay(),
        diff = newDate.getDate() - day + (day === 0 ? (7 - adjustment) : adjustment);
    return new Date(newDate.setDate(diff));
};

const getDayDateLocaleString = (date) => {
    return date.toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: '2-digit' });
};

const shortWeekDayWithLocaleDateString = (date) => {
    return date.toLocaleString('de-DE', { weekday: 'short', year: 'short' });
}

const getDisplayMonthLong = (date) => {
    return date.toLocaleString('default', { month: 'long' });
};

export {
    getWeekDayDate,
    getDayDateLocaleString,
    getDisplayMonthLong,
    shortWeekDayWithLocaleDateString,
}
