import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const state = {
    imageLibraryIndex: [],
    imageLibraryTags: [],
};

const getters = {
};

const mutations = {
    setImageLibraryIndex: (state, index) => {
        state.imageLibraryIndex = index;
    },
    setImageLibraryTags: (state, tags) => {
        state.imageLibraryTags = tags;
    }
};

const actions = {
    async loadImageLibraryIndex({ commit, dispatch }, params) {
        const res = await backend.getImageLibraryIndex();
        await checkResponseStatus(200, res);
        const index = await res.json();
        // console.log('images', index);
        commit('setImageLibraryIndex', index);
        dispatch('extractImageLibraryTags', index);
    },

    async getImageLibraryImage({ commit, dispatch }, imageId) {
        const res = await backend.getImageLibraryImage(imageId);
        await checkResponseStatus(200, res);
        return await res.blob();
    },

    async extractImageLibraryTags({ commit }, imageIndex) {

        const result = imageIndex.reduce((previous, currentEntry) => {
            const tags = currentEntry.tags;
            for(let i = 0; i < tags.length; i++) {
                if (previous.indexOf(tags[i]) === -1) {
                    previous.push(tags[i]);
                }
            }
            return previous;
        }, []);
        // console.log('tags', result);

        commit('setImageLibraryTags', result);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
