<template>
    <div>
        <v-dialog
            v-model="openDialog"
            :fullscreen="openInFullscreen"
            internal-activator
            min-width="1100px"
        >
            <v-card
                scrollable
            >
                <v-card-title
                    id="templateBrowserHeader"
                    class="d-flex flex-column"
                >
                    <div
                        class="d-flex justify-space-between align-center"
                        style="width: 100%"
                    >
                        <div
                            style="width: 15%; min-width: 175px"
                            class="d-flex justify-start align-center"
                        >
                            <v-text-field
                                v-model="searchVModel"
                                outlined
                                hide-details
                                dense
                                background-color="weiss"
                                :placeholder="`${currentLibrary === 'images' ? 'Grafiken' : 'Blätter'} suchen...`"
                                append-icon="mdi-magnify"
                                @input="debounceSearchListener"
                            />
                            <v-tooltip
                                v-if="currentLibrary === 'images'"
                                right
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-if="currentLibrary === 'images'"
                                        class="ml-3"
                                        icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <img
                                            alt="Stichwort Infos"
                                            :src="infoIcon"
                                            style="height: 35px; filter: brightness(150%)"
                                        >
                                    </v-btn>
                                </template>
                                <div style="max-width: 200px">
                                    <p>
                                        Tipp: Wenn Sie nach bestimmten Stichworten filtern wollen,
                                        geben Sie ein Komma (,) nach dem Wort ein.
                                    </p>
                                </div>
                            </v-tooltip>
                        </div>
                        <!-- Switches for images and worksheets -->
                        <div class="d-flex justify-space-between align-center">
                            <v-btn-toggle
                                v-model="currentLibraryIndex"
                                mandatory
                            >
                                <v-btn
                                    style="text-transform: inherit"
                                >
                                    Arbeitsblätter
                                </v-btn>
                                <v-btn
                                    style="text-transform: inherit"
                                >
                                    Grafiken
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                        <div
                            style="width: 15%; min-width: 175px"
                            class="d-flex justify-end"
                        >
                            <v-btn
                                x-small
                                width="45px"
                                height="45px"
                                @click="close"
                            >
                                <img
                                    width="35px"
                                    height="35px"
                                    :src="closeIcon"
                                    alt="Vorlagenbrowser schließen"
                                >
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-if="searchTerms.length > 0"
                        style="width: 100%"
                    >
                        <v-chip
                            v-for="(entry, index) in searchTerms"
                            :key="`term${entry}`"
                            close
                            close-icon="mdi-close"
                            label
                            class="ma-1"
                            @click:close="removeSearchTerm(index)"
                        >
                            {{ entry }}
                        </v-chip>
                    </div>
                </v-card-title>
                <v-card-text id="templateBrowserContent">
                    <div
                        v-if="currentLibrary === 'images'"
                        id="imageBrowserPart"
                        ref="imageBrowserPart"
                        class="mb-2"
                    >
                        <p class="ml-2 mb-3 fontLarge">
                            Grafiken
                        </p>

                        <!-- List part -->
                        <div
                            class="d-flex flex-wrap justify-space-evenly align-center"
                            style="max-width: 100%;"
                        >
                            <EditorImageLibraryItem
                                v-for="(item, index) in displayableImages"
                                :key="`libraryBrowser${index}${item._id}`"
                                :index="index"
                                class="mx-auto my-2 d-flex flex-column align-center justify-center"
                                style="width: 150px; height: 150px; background-color: var(--v-hellgrau-base); border-radius: 5px"
                                tabindex="17"
                                draggable="true"
                                :image-library-image="item"
                                @addImageField="(src, blob) => { $emit('addImageField', src, blob); close()}"
                            />
                            <div v-if="!displayableImages.length">
                                <p>
                                    Es konnten keine Bilder mit diesen Stichworten gefunden werden.
                                    Versuchen Sie ein paar andere Begriffe.
                                </p>
                            </div>
                        </div>
                        <div>
                            <v-btn
                                large
                                style="text-transform: inherit"
                                :disabled="displayableImages.length < (imagesFifty * 50)"
                                @click="() => { imagesFifty++ }"
                            >
                                Mehr
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-else
                        id="docRegistryPart"
                    >
                        <div
                            class="d-flex justify-start align-center"
                            :style="`${currentFolderTree.length > 1 ? 'padding-bottom: 10px; margin-bottom: 20px; border-bottom: 1px solid var(--v-grau-base);' : ''}`"
                        >
                            <v-btn
                                v-if="currentFolderTree.length > 1"
                                text
                                @click="changeCurrentFolder(currentFolderTree[currentFolderTree.length - 2])"
                            >
                                <v-icon style="color: var(--v-dunkelgrau-base)">
                                    fas fa-chevron-left
                                </v-icon>
                            </v-btn>
                            <p class="ml-2 mb-3 fontLarge">
                                Arbeitsblätter
                                <span
                                    v-for="(folder, index) in currentFolderTree"
                                    :key="`folder${folder._id}${index}`"
                                    class="directoryPath"
                                    @click="changeCurrentFolder(folder)"
                                >{{ folder.parent ? `/ ${folder.name}` : 'Online Bibliothek' }}</span>
                            </p>
                        </div>

                        <!-- List part -->
                        <div
                            v-if="displayableDocRegistryElements.length"
                            class="d-flex flex-wrap justify-space-evenly align-center"
                            style="max-width: 100%;"
                        >
                            <EditorTemplateBrowserItem
                                v-for="(item, index) in displayableDocRegistryElements"
                                :key="`templateDoc${index}${item._id}`"
                                :item="item"
                                :type="item.itemType"
                                class="ma-1"
                                style="width: 275px"
                                @folderSelected="(callback) => changeCurrentFolder(item, callback)"
                                @itemSelected="(callback) => documentRegistryDocumentSelected(item, callback)"
                                @loadFullscreenPreview="loadFullscreenPreview"
                            />
                        </div>
                        <div
                            v-else-if="loadingRegistry"
                            class="d-flex justify-center align-center"
                        >
                            <v-progress-circular
                                indeterminate
                                size="100"
                            />
                        </div>
                        <div v-else>
                            <p>Es konnten keine Dateien in diesem Ordner der Online-Bibliothek gefunden werden.</p>
                        </div>
                        <div>
                            <v-btn
                                v-show="displayableDocRegistryElements.length >= 25"
                                large
                                style="text-transform: inherit"
                                :disabled="displayableDocRegistryElements.length < (docsShowMult * 25)"
                                @click="() => { docsShowMult++ }"
                            >
                                Mehr
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions
                    class="d-flex justify-end align-center pt-0"
                    style="bottom: 0; position: absolute; width: 100%; padding: 16px 24px 10px;"
                >
                    <!-- Button zum Schließen oder Editor verlassen wenn ein neues Dokument erstellt wird -->
                    <!-- <v-btn
                        depressed
                        width="15%"
                        min-width="150px"
                        style="text-transform: inherit"
                        @click="leaveEditorWhenCreatingNewDocument"
                    >
                        Editor verlassen
                    </v-btn>-->
                    <v-btn
                        depressed
                        style="text-transform: inherit"
                        @click="close"
                    >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-if="fullscreenPreviewEnabled">
            <v-dialog
                v-model="fullscreenPreviewEnabled"
                scrollable
                style="overflow: hidden"
                transition="dialog-bottom-transition"
                max-width="80vw"
            >
                <div
                    id="previewDialog"
                    ref="previewDialog"
                    style="display: flex; flex-direction: column"
                >
                    <div
                        width="100%"
                        style="position: sticky; top: 0px; display:flex; justify-content: space-between; align-items: center; background-color: white; z-index: 10; height: 7vh"
                        class="pa-5"
                    >
                        <v-btn
                            class="textTransformNone"
                            style="background-color: #ffffff !important; elevation: 0; box-shadow: none; cursor: default"
                            :ripple="false"
                        >
                            <v-icon>mdi-file</v-icon>
                            <span>{{ previewSelection.name }}</span>
                        </v-btn>
                        <v-btn
                            style="background-color: #ffffff !important; elevation: 0; box-shadow: none"
                            @click="() => { fullscreenPreviewEnabled = false }"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <v-card style="background-color: #707070; overflow: auto">
                        <v-card-text
                            class="pa-0"
                            :style="previewPDF ? 'display: flex; justify-content: space-around' : ''"
                        >
                            <!-- <div class="text-h2 pa-12">{{ previewSelection }}</div> -->
                            <v-img
                                v-if="!previewPDF"
                                :src="preview"
                                contain
                                aspect-ratio="1.4"
                                :alt="previewSelection.name || previewSelection.uploadedName"
                                :lazy-src="img_eklaraLogo"
                            >
                                <div class="fill-height bottom-gradient" />
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height fill-width ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <!-- <v-progress-circular
                                            indeterminate
                                            color="grey"
                                        /> -->
                                    </v-row>
                                </template>
                            </v-img>
                            <object
                                v-else
                                :data="preview + '#toolbar=0&navpanes=0&scrollbar=0&view=Fit'"
                                style="height: 80vh; width: 80vw"
                            />
                        </v-card-text>
                    </v-card>
                </div>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import EditorTemplateBrowserItem from "@/components/Editor/EditorTemplateBrowserItem";
import previewTemplateBlank from '@/assets/Icons/Temp/preview-blank-page-editor-template-browser.png';
import previewTemplateLiniert from '@/assets/Icons/Temp/preview-liniert-page-editor-template-browser.png';
import previewTemplateKariert from '@/assets/Icons/Temp/preview-kariert-page-editor-template-browser.png';
import previewTemplateHaus from '@/assets/Icons/Temp/preview-haus-page-editor-template-browser.png';
import previewDefaultWorksheet from '@/assets/Icons/Temp/preview-default-ab-editor-template-browser.png'
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg'
import img_eklaraLogo from "@/assets/EditorLibraryMock/eklara_logo.png";
import * as backend from "@/api/backend";
import EditorImageLibraryItem
    from "@/components/Editortools/Toolbar/EditorLibraryBar/EditorImageLibrary/EditorImageLibraryItem";
import debounce from 'lodash.debounce';

export default {
    name: 'EditorLibraryBrowser',
    components: { EditorImageLibraryItem, EditorTemplateBrowserItem },
    props: {
        activator: { required: true, type: Boolean },
        mode: { required: true, type: String },
        previouslyOpen: { required: false, type: Number, default: 0 },
    },
    data: () => ({
        internalActivator: false,
        currentFolder: {},
        folderCallback: () => { },
        loadingRegistry: false,

        currentFolderTree: [],
        templateSearchString: '',
        searchVModel: '',
        searchTerms: [],
        debounceSearchListener: null,

        //#region switch action
        currentLibraryIndex: 0,
        libraries: ['worksheets', 'images'],
        imagesFifty: 1,
        docsShowMult: 1,
        //#endregion

        //#region preview data
        preview: null,
        previewPDF: false,
        previewSelection: null,
        fullscreenPreviewEnabled: false,
        //#endregion

        //#region Icons
        closeIcon,
        infoIcon,
        img_eklaraLogo,
        //#endregion
    }),
    computed: {
        ...mapState('documentRegistry', [
            'documentRegistryRootFolder',
            'documentRegistryDocuments',
        ]),
        ...mapGetters('documentRegistry', [
            'documentRegistryDocumentsById',
            'documentRegistryFoldersById',
        ]),
        ...mapState('util', ['windowWidth']),
        ...mapState('imageLibrary', ['imageLibraryIndex']),
        displayableImages() {
            let copiedArray = this.imageLibraryIndex.slice();
            if (this.searchTerms.length) {
                copiedArray = copiedArray
                    .filter(item => item.tags
                        .some(tag => this.searchTerms.includes(tag.toLowerCase())));
            }
            // Search images
            return this.searchLibrary(copiedArray, this.templateSearchString).slice(0, this.imagesFifty * 50);
        },
        currentLibrary() {
            return this.libraries[this.currentLibraryIndex];
        },
        openDialog() {
            return this.activator || this.internalActivator
        },
        openInFullscreen() {
            return this.windowWidth < 1100;
        },
        displayableDocRegistryElements() {
            return this.docRegistryElements.items;
        },
        docRegistryElements() {
            if (this.templateSearchString.length > 2) {
                const result = {
                    items: this.documentRegistryDocuments
                        .filter(doc => doc.name.includes(this.templateSearchString))
                        .slice(0, this.docsShowMult * 25)
                        .map(item => {
                            item.value = item._id;
                            item.type = 'file';
                            item.itemType = 'Document';
                            return item;
                        })
                };
                return result;
            }

            const currentFolder = this.currentFolder
                ? { ...this.currentFolder }
                : { items: [ ]};

            const returnFolder = { ...currentFolder };
            returnFolder.items = [];
            const folders = [];
            const files = [];

            if (currentFolder.items) {
                for (let i = 0; i < currentFolder.items.length; i++) {
                    let item = { ...currentFolder.items[i] };

                    let data = null;
                    if (item.itemType === 'Folder') {
                        data = { ...this.documentRegistryFoldersById[item.value] };
                        if (Object.keys(data).length) {
                            item.type = 'folder';
                            item.items = data.items;
                            item.name = data.name;
                            item.parent = data.parent;
                            folders.push(item);
                        }
                    } else {
                        data = { ...this.documentRegistryDocumentsById[item.value] };
                        if (Object.keys(data).length) {
                            item = { ...item, ...data };
                            item.type = 'file';
                            files.push(item);
                        }
                    }
                }

                folders.sort((a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    if (this.sortAlphabeticalDescending) {
                        return (a < b) ? 1 : (a > b) ? -1 : 0;
                    } else {
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }
                })
                files.sort((a, b) => {
                    a = (a.originalname || a.name).toLowerCase();
                    b = (b.originalname || b.name).toLowerCase();
                    if (this.sortAlphabeticalDescending) {
                        return (a < b) ? 1 : (a > b) ? -1 : 0;
                    } else {
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }
                });

                returnFolder.items = folders.concat(files).slice(0, this.docsShowMult * 25);
            }

            return returnFolder;
        }
    },
    async created() {
        this.loadingRegistry = true;
        await this.loadDocumentRegistryDocuments();
        await this.loadDocumentRegistryRootFolder();
        await this.loadDocumentRegistryFolders();
        this.currentFolder = this.documentRegistryRootFolder;
        this.currentFolderTree.push(this.documentRegistryRootFolder);
        this.loadingRegistry = false;
        await this.loadImageLibraryIndex();
    },
    mounted() {
        this.currentLibraryIndex = this.previouslyOpen;
        this.debounceSearchListener = debounce((input) => {
            this.searchInputChanged(input)
        }, 500);
    },
    updated() {
        if (this.displayableDocRegistryElements && this.folderCallback) {
            this.folderCallback();
            this.$nextTick(() => {
                this.folderCallback = () => { };
            });
        }
    },
    methods: {
        ...mapActions('documentRegistry', [
            'loadDocumentRegistryDocuments',
            'loadDocumentRegistryRootFolder',
            'loadDocumentRegistryFolders'
        ]),
        ...mapActions('imageLibrary', ['loadImageLibraryIndex']),

        //#region General
        close() {
            this.internalActivator = false;
            this.$emit('close');
        },
        searchInputChanged(input) {
            if (input.length > 2 || input.length === 0) {
                this.templateSearchString = input;
                this.docsShowMult = 1;

                if (input.includes(',') && this.currentLibrary === 'images') {
                    const currentSearchTerms = input.split(/\s*,+\s*/);
                    if (currentSearchTerms.length) {
                        this.searchTerms.push(currentSearchTerms[0].trim().toLowerCase());
                        this.templateSearchString = '';
                        this.searchVModel = '';
                    }
                }
            }
        },
        removeSearchTerm(index) {
            this.searchTerms.splice(index, 1);
            this.docsShowMult = 1;
        },
        //#endregion

        //#region Methods to return result to parent
        changeCurrentFolder(folder, callback) {
            this.currentFolder = folder
            if (!this.currentFolderTree.includes(folder)) {
                this.currentFolderTree.push(folder)
            } else {
                //find index
                const folderIndex = this.currentFolderTree.findIndex(item => item._id === folder._id);
                // delete everything after index
                this.currentFolderTree.splice(folderIndex + 1);
            }
            this.folderCallback = callback;
            this.docsShowMult = 1;
        },
        async documentRegistryDocumentSelected(document, callback) {
            if (document) {

            const fileResponse = await backend.getDocumentRegistryDocumentFile(
                document._id,
                document.structure.backgroundPdf);

            const fileBlob = await fileResponse.blob();
            const fileArray = new Uint8Array(await fileBlob.arrayBuffer());

            this.$emit('onAddDocFromLibrary', fileArray);
            this.close();
            }
        },
        //#endregion

        //#region Fullscreen Preview
        async loadFullscreenPreview(item) {
            this.previewSelection = item;

            const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                item.value,
                item.files[0]);
            let thumb = await thumbnailResponse.blob();
            this.previewPDF = false;

            if (thumb) {
                this.preview = URL.createObjectURL(thumb);
                if (thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.preview = img_eklaraLogo;
            }

            this.fullscreenPreviewEnabled = true;
        },
        searchLibrary(library, searchTerm) {

            // if left empty, reset to alphabetical sorting
            if (searchTerm === '') {
                // sort alphabetically, descending
                return library.sort((a, b) => a.title.localeCompare(b.title));
            }

            // https://stackoverflow.com/a/36566052
            // using a stackoverflow similarity based algorithm as placeholder
            // since this search will have to change depending on central repository specs
            // and how the tagging infrastructure will be set up on a later date

            function editDistance(s1, s2) {
                s1 = s1.toLowerCase();
                s2 = s2.toLowerCase();

                var costs = new Array();
                for (var i = 0; i <= s1.length; i++) {
                    var lastValue = i;
                    for (var j = 0; j <= s2.length; j++) {
                        if (i == 0)
                            costs[j] = j;
                        else {
                            if (j > 0) {
                                var newValue = costs[j - 1];
                                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                                    newValue = Math.min(Math.min(newValue, lastValue),
                                        costs[j]) + 1;
                                costs[j - 1] = lastValue;
                                lastValue = newValue;
                            }
                        }
                    }
                    if (i > 0)
                        costs[s2.length] = lastValue;
                }
                return costs[s2.length];
            }

            function similarity(s1, s2) {
                let longer = s1;
                let shorter = s2;
                if (s1.length < s2.length) {
                    longer = s2;
                    shorter = s1;
                }
                let longerLength = longer.length;
                if (longerLength === 0) {
                    return 1.0;
                }
                return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
            }
            // generate similarity of every item in loaded library
            for (let item of library) {
                item.similarity = similarity(item.title, searchTerm);
            }
            // sort by descending similarity
            return library.sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        },
    }
}
</script>

<style scoped lang="scss">
.activeLibrary {
    background-color: inherit !important;
}

.librarySwitcher {
    background-color: var(--v-dunkelgrau-base) !important;
}

#templateBrowserHeader {
    background-color: var(--v-hellgrau-base);
}

#templateBrowserContent {
    font-size: 1rem !important;
    min-height: 80vh !important;

    p {
        color: black;
    }
}

#imageBrowserPart {
    padding: 1em;
}

#docRegistryPart {
    padding: 1em;
    margin-bottom: 60px;
}

.fontXLarge {
    font-size: x-large !important;
}

.fontLarge {
    font-size: large !important;
}

.directoryPath {
    border-radius: 6px;
    cursor: pointer;

    :hover {
        background-color: var(--v-hellgrau-base) !important;
    }
}
</style>
