<template>
    <div>
        <div 
            v-if="editors.length"
            :class="usage==='pinboard'? 'pt-2':'avatarContainer'"
        >
            <div
                v-if="!editorsLoading"
            >
                <v-menu
                    offset-y
                    bottom
                    offset-overflow
                    class="ml-0"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="d-flex flex-row"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div
                                v-if="editors.length > 0" 
                                style="z-index: 5"
                            >
                                <Avatar
                                    :profile="editors[0].author"
                                    :size="24"
                                />
                            </div>
                    
                            <!-- <div
                                v-if="editors.length > 1"
                                style="margin-left: -12px; z-index: 4"
                            >
                                <Avatar
                                    :profile="editors[1].author"
                                    :size="24"
                                />
                            </div>
                            <div
                                v-if="editors.length > 2"
                                style="margin-left: -10px; z-index: 3"
                            >
                                <Avatar
                                    :profile="editors[2].author"
                                    :size="24"
                                />
                            </div> -->
                        </div>
                    </template>
                    <v-list
                        dense
                        color="rgba(97, 97, 97, 0.9)"
                    >
                        <p style="color:white; padding-left: 0.25rem;">
                            Zuletzt bearbeitet:
                        </p>
                        <v-list-item
                            v-for="(editor,index) in editors" 
                            :key="index"
                            class="d-flex flex-row justify-space-between align-center"
                        >
                            <div 
                                class="d-flex flex-row align-center"
                                style="color:white;"
                            >
                                <Avatar
                                    :profile="editor.author"
                                    :size="30"
                                />                        
                                <div class="ml-2">
                                    {{ editor.name }}
                                </div>
                            </div>

                            <v-spacer />
                            <div
                                class="ml-2"
                                style=" align-items: flex-end; color:white;"
                            >
                                {{ editor.date }}
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-else>
                <v-progress-circular 
                    indeterminate
                    color="green"
                    style="height: 20px;"
                />
            </div>
        </div>
        <!-- <v-dialog
            v-model="showInfoDialog"
            max-width="290"
            hide-overlay
        >
            <v-card style="min-height: 220px; max-width:290px; border-radius: 8px;">
                <v-card-title
                    class="pa-2 d-flex flex-row justify-space-between"
                    :style="`background-color: ${card.color}; color:white;`"
                >
                    Zuletzt bearbeitet
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="showInfoDialog = false;"
                    >
                        <img
                            :src="closeIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text
                    v-if="editors.length"
                    class="pt-2 pb-0 px-2"
                    stlye="height: 100%;"
                >
                    <div
                        v-for="(editor,index) in editors" 
                        :key="index"
                        class="d-flex flex-column justify-space-between my-2"
                    >
                        <div class="d-flex flex-row  align-center">
                            <Avatar
                                :profile="editor.author"
                                :size="30"
                            />                        
                            <div
                                class="ml-2"
                                style="min-width:50%;"
                            >
                                {{ editor.name }}
                            </div>
                            <div class="ml-2">
                                {{ editor.date }}
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-text v-else>
                    Keine Info vorhanden
                </v-card-text>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'


export default {
    name: 'PinboardLastEdited',
    components: {
        Avatar,
    },
    props: {
        card: { required: true, type: Object },
        usage: {required: false, default: 'card'}
    },
    data: () => ({
        editors:[],
        editorsLoading: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('accounts', ['accountsById']),
        ...mapGetters('pupils', ['pupilsByAccountId','pupilsById']),
        ...mapGetters('teachers', ['teachersByAccountId']),
        ...mapGetters('parents', ['parentsByAccountId' ,'parentsById']),
    },
    async mounted(){
        await this.loadLastEdited()
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),

    async loadLastEdited(){
            this.editorsLoading = true;
            if(this.card.lastEdited && this.usage !== 'pinboard'){
            const mapped = await Promise.all(this.card.lastEdited.map((obj)=>{
                const account = this.accountsById[obj.author];
                let name = 'Verwaltung';
                let user;
                let parent;
                switch(account.role){
                    case 'teacher' :
                        user = this.teachersByAccountId[obj.author];
                        name = `${user.name} ${user.lastName}` ;
                        break;
                    case 'maintainer':
                        name = 'Verwaltung';
                        break;
                    case 'pupil':
                        user = this.pupilsByAccountId[obj.author];
                        name = `${user.name} ${user.lastName}` ;
                        break;
                    case 'parent':
                        parent = this.parentsByAccountId[obj.author];
                        user =  this.pupilsById[parent.pupils[0]];
                        if(parent.pupils.length > 1){
                            name = `Eltern von ${user.name} ${user.lastName}...`
                        }else{
                            name = `Eltern von ${user.name} ${user.lastName}`
                        }
                }
                return {date: this.formatDate(obj.date), author: obj.author, name}
            }
            ))
            this.editors = mapped;
            }else if(this.card.lastEdited && this.usage === 'pinboard'){
                const obj = this.card.lastEdited;
             const account = this.accountsById[obj.author];
                let name = 'Verwaltung';
                let user;
                let parent;
                switch(account.role){
                    case 'teacher' :
                        user = this.teachersByAccountId[obj.author];
                        name = `${user.name} ${user.lastName}` ;
                        break;
                    case 'maintainer':
                        name = 'Verwaltung';
                        break;
                    case 'pupil':
                        user = this.pupilsByAccountId[obj.author];
                        name = `${user.name} ${user.lastName}` ;
                        break;
                    case 'parent':
                        parent = this.parentsByAccountId[obj.author];
                        user =  this.pupilsById[parent.pupils[0]];
                        if(parent.pupils.length > 1){
                            name = `Eltern von ${user.name} ${user.lastName}...`
                        }else{
                            name = `Eltern von ${user.name} ${user.lastName}`
                        }
                }
                this.editors.push({date: this.formatDate(obj.date), author: obj.author, name})
                }else{
                this.editors = [];
            }
            this.editorsLoading = false;
        },
        formatDate(date){
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('de-DE', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                        })  +' ' + 
                     dateObj.toLocaleTimeString('de-DE', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        });
        },
       
    }
}
</script>

<style scoped lang="scss">
//transparent scrollbar track
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.pinboardCardTitle {
    background-color: mediumseagreen;
    color: whitesmoke;
    font-size: 30px;
    width: 220.5px;
}


.avatarContainer {
    position: relative;
    top: -5px;
    // background-color: white;
    // border: 1px solid #ccc; 
    // border-radius: 8px; 
    // min-width: 32px;
    // max-width: 58px; 
    // max-height: 26px;
    // padding-bottom: 0.15rem;
    // padding-left: 0.15rem;
    // padding-right: 0.15rem;
    // padding-top: 0.25rem;
}

@media only screen and (min-width: 725px) and (max-width: 1610px) {
    .titleCardDiv {
        width: 79%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 724px) {
    .titleCardDiv {
        width: 73%;
    }
}

@media only screen and (max-width: 599px) {
    .pinboardCardTitle {
        width: 72vw;
    }
}
</style>
