<template>
    <div class="d-flex justify-space-between align-center">
        <div style="width: 10%">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img
                            :src="statusIcon(account.status)"
                            style="filter: brightness(0%); height: 20px"
                        >
                    </v-btn>
                </template>
                <span> {{ statusTooltip(account.status) }} </span>
            </v-tooltip>
        </div>
        <!-- Todo display parent and babyview (example: checkboxes) data for pupil -->
        <!-- Todo add error correction ui -->
        <div style="width: 20%">
            <p>{{ account.role === 'pupil' ? 'Schüler' : 'Lehrer' }}</p>
        </div>
        <div style="width: 20%">
            <p>
                {{ account.newLastName || account.lastName }}
            </p>
            <input
                v-if="account.error === 'newLastName'
                    || account.error === 'lastName'
                    || (!account.newLastName && !account.lastName)"
                class="accountErrorField"
                type="text"
                @change="(event) => updateValue('newLastName', event)"
            >
        </div>
        <div style="width: 20%">
            <p>
                {{ account.newName || account.name }}
            </p>
            <input
                v-if="account.error === 'newName'
                    || account.error === 'name'
                    || (!account.newName && !account.name)"
                class="accountErrorField"
                type="text"
                @change="(event) => updateValue('newName', event)"
            >
        </div>
        <div
            style="width: 10%"
        >
            <v-checkbox
                v-if="account.newParent"
                v-model="parentStatus"
                label="Eltern"
                disabled
                dense
            />
        </div>
        <div
            style="width: 10%"
        >
            <v-checkbox
                v-if="account.newBabyView !== undefined"
                v-model="babyViewStatus"
                label="Einfach"
                disabled
                dense
            />
        </div>
    </div>
</template>

<script>
import changedIcon from "@/assets/Icons/ausrufezeichen-62.svg";
import newIcon from "@/assets/Icons/plus.svg";
import addedIcon from '@/assets/Icons/arrow-to-right.svg'
import errorIcon from '@/assets/Icons/FaceliftIcons/f-physik.svg';

export default {
    name: "ExcelImportExpansionPanel",
    props: {
        account: { required: true, type: Object },
        index: { required: false, typer: Number, default: 0 },
    },
    data: () => ({
        changedIcon,
        newIcon,
        addedIcon,
        errorIcon,
    }),
    computed: {
        statusIcon() {
            return (status) => {
                switch (status) {
                    case 'new':
                        return this.newIcon;
                    case 'changed':
                        return this.changedIcon;
                    case 'added':
                        return this.addedIcon;
                    case 'error':
                        return this.errorIcon;
                    default: break;
                }
            }
        },
        statusTooltip() {
            return (status) => {
                switch (status) {
                    case 'new': return 'Dieser Nutzer wird neu angelegt, ' +
                        'ein neues Nutzername-Password Paar wird im nächsten Schritt erstellt.';
                    case 'changed': return 'Es wurden Änderungen an diesem Nutzerkonto festgestellt. ' +
                        'Diese werden im nächsten Schritt angewandt.';
                    case 'added': return 'Nutzer wurde der Gruppe neu hinzugefügt.'
                    case 'error': break;
                    default: break;
                }
            }
        },
        parentStatus() {
            return this.account.newParent === 'Ja';
        },
        babyViewStatus() {
            return this.account.newBabyView;
        }
    },
    mounted() {
        console.log('account', this.account);
        console.log('update test')
    },
    methods: {
        updateValue(key, event) {
            console.log('updateValue with event', event);
            const result = {};
            result[key] = event.target?.value;
            this.$emit('errorCorrection', result);
        }
    }
}
</script>

<style lang="scss">
.accountErrorField {
    border: 1px solid red;
}
</style>
