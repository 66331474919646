<template>
    <v-dialog
        v-model="showDialog"
        max-width="337"
        max-height="210"
        @click:outside="close()"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="text-h5 mb-1 d-flex align-center justify-space-between"
                style="background-color: var(--v-headerblue-base); color: white"
            >
                <span>Änderungen speichern?</span>
                <v-spacer />
                <v-btn
                    icon
                    color="transparent"
                    elevation="0"
                    style="margin-right: -8px"
                    @click="close"
                >
                    <img
                        :src="cancelIcon"
                        class="icon30"
                        alt="Fenster schließen"
                        style="filter: brightness(1000%)"
                    >
                </v-btn>
            </v-card-title>

            <v-card-text class="mt-2">
                {{ contentText }}
            </v-card-text>

            <v-card-actions class="d-flex justify-center mb-2">
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="#F5F5F5"
                    @click="discard"
                >
                    <img
                        :src="cancelIcon"
                        class="icon mr-2"
                        alt="abbrechen"
                    >
                    Verwerfen
                </v-btn>
                <v-spacer />
                <v-btn
                    class="optionBtn text-none ml-2"
                    color="var(--v-gruen-base)"
                    dark
                    @click="okClicked"
                >
                    <img
                        :src="finishIcon"
                        class="icon iconToWhite mr-2"
                        alt="bestätigen"
                    >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import finishIcon from '@/assets/Icons/fertig-74.svg';

export default {
    name: 'ConfirmSavePopup',
    props: {
        showDialog: { required: true, type: Boolean },
        contentTextProp: { required: false, type: String, default: '' },
        usage: { required: false, type: String, default: '' },
    },
    data() {
        return {
            cancelIcon,
            finishIcon,
        }
    },
    computed: {
        contentText() {
            return this.contentTextProp || 'Wollen Sie ihre vorgenommenen Änderungen speichern?';
        },
    },
    methods: {
        close() {
            this.$emit('abortMission')
        },
        discard() {
            this.$emit('discard');
        },
        okClicked() {
            this.$emit('save');
        }
    }
}
</script>

<style scoped>
.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
