<template>
    <div>
        <!-- <BadgeExplanations /> -->
        <div
            v-if="!weeklyView"
            class="widgetAlign"
        >
            <v-card
                id="planWidgetContainer"
                :class="babyView ? 'widgetContainerBaby' : ''"
                class="whiteBg widgetContainer"
                @dragenter="onDragEnter"
                @dragover="onDragOver"
                @dragleave="onDragLeave"
                @drop="dropFile"
                @dragover.prevent
                @drop.prevent
            >
                <div
                    v-if="dropUiActive"
                    class="dropUiContainer"
                >
                    <div class="dropUi">
                        <p style="color: var(--v-dunkelgrau-base); font-size: 25px">
                            Dateien hier loslassen!
                        </p>

                        <v-icon
                            class="pr-1 folderDisplay"
                            style="color: var(--v-dunkelgrau-base); font-size: 75px"
                        >
                            mdi-plus
                        </v-icon>
                    </div>
                </div>
                <ClickableBadge
                    v-if="!roomMode"
                    :event-list="badgeEventList"
                    :delete-button="true"
                    usage="splan"
                    @badgeClicked="widgetBadgeClicked"
                    @clearBadges="clearAppointmentBadges"
                />
                <!-- Header of Stundenplan -->
                <div class="header">
                    <div
                        style="max-width: 100%; height: 56px; padding: 0 2%"
                        class="d-flex align-center justify-space-between"
                    >
                        <div class="d-flex justify-space-between align-center">
                            <button
                                v-if="widgetView === 'lesson'"
                                style="display: flex; align-items: center"
                                @click="setWidgetViewThroughHeader('day', false, true)"
                            >
                                <img
                                    :src="zurueckIcon"
                                    alt="zurück"
                                    style="height: 20px; align-self: center; cursor: pointer"
                                    class="iconToWhite mr-2 stundenplanIconDisplay"
                                >
                            </button>
                            <div
                                v-if="widgetView !== 'lesson'"
                                class="d-flex flex-row justify-space-around align-center"
                            >
                                <v-tooltip
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            @click="()=>{ planIconClicked()}"
                                        >
                                            <img
                                                :src="stundenplanIcon"
                                                alt=""
                                                style="height: 20px; align-self: center;"
                                                class="iconToWhite mr-2 stundenplanIconDisplay"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>{{ 'Hier ' + (accountRole === 'pupil' ? 'kannst du' : 'können Sie') + ' den Stundenplan einsehen.' + (accountRole === 'pupil' && !dayBabyView ? ' Klicken um zur einfachen Ansicht zu wechseln.'
                                        : accountRole === 'pupil' && dayBabyView ? ' Klicken um zur vollen Tagesansicht wechseln' : '' ) }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    v-if="(accountRole === 'maintainer' || accountRole === 'teacher') && filterMode"
                                    top
                                    z-index="100"
                                >
                                    <template v-slot:activator="{ on }">
                                        <button
                                            class="d-flex align-center mr-2  square20" 
                                            style="position: relative; cursor: pointer"
                                            v-on="on"
                                            @click="toggleModes('filterMode')"
                                        >
                                            <img
                                                :src="magnifierIcon"
                                                alt="Lehrer filtern"
                                                class="iconToWhite square20"
                                                :style="!filterMode ? 'opacity: 0.4' : ''"
                                            >
                                        </button>
                                    </template>
                                    <span>Hier können Sie nach einzelnen Lehrerstundenplänen filtern.</span>
                                </v-tooltip>
                                <v-badge
                                    v-if="accountRole === 'maintainer' && !hideBadges"
                                    content="4"
                                    color="green"
                                    left
                                    overlap
                                    style="cursor: pointer;  top:-20px !important;"
                                    class="insetBadge"
                                />
                                <v-tooltip
                                    v-if="accountRole === 'maintainer'"
                                    top
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-autocomplete
                                            v-show="filterMode"
                                            v-model="currentFilteredTeacher"
                                            :menu-props="{'z-index': 100}"
                                            auto-select-first
                                            label="Lehrer suchen"
                                            single-line
                                            dark
                                            dense
                                            clearable 
                                            hide-details
                                            :item-text="item => item.name + ' ' + item.lastName"
                                            class="responsiveAutocompletePadding"
                                            style="z-index: 9"
                                            :items="teachers"
                                            return-object
                                            no-data-text="Kein Lehrer vorhanden"
                                            v-on="on"
                                            @change="changeFilteredTeacher"
                                        />
                                        <v-autocomplete
                                            v-show="!filterMode"
                                            v-model="currentGroupModel"
                                            label="Nach Klasse/Gruppe suchen"
                                            single-line
                                            dark
                                            hide-details
                                            item-text="name"
                                            class="mt-0 pt-0"
                                            :items="groups"
                                            :filter="customFilter"
                                            no-data-text="Keine Klasse/Gruppe vorhanden"
                                            return-object
                                            v-on="on"
                                            @change="changeGroup"
                                        />
                                    </template>
                                    <span>{{ filterMode ? 'Lehrer auswählen' : 'Gruppe auswählen' }}</span>
                                </v-tooltip>
                                <v-tooltip
                                    v-if="accountRole === 'teacher' "
                                    top
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-autocomplete
                                            v-show="filterMode"
                                            v-model="currentFilteredTeacher"
                                            :menu-props="{'z-index': 100}"
                                            auto-select-first
                                            label="Lehrer suchen"
                                            single-line
                                            dark
                                            dense
                                            hide-details
                                            clearable 
                                            :item-text="item => item.name + ' ' + item.lastName"
                                            class="responsiveAutocompletePadding"
                                            style="z-index: 9"
                                            :items="teachers"
                                            return-object
                                            :disabled="!filterMode"
                                            no-data-text="Kein Lehrer vorhanden"
                                            v-on="on"
                                            @change="changeFilteredTeacher"
                                        />
                                    </template>
                                    <span>{{ 'Lehrer auswählen' }}</span>
                                </v-tooltip>
                            </div>

                       
                            <v-menu
                                v-if="accountRole === 'maintainer' && currentGroup && currentGroup.name !== 'Alle' && !filterMode"
                                bottom
                                nudge-right="30"
                                right
                                style="z-index: 99"
                            >
                                <template v-slot:activator="{ on }">
                                    <img
                                        v-if="accountRole === 'maintainer' && currentGroup.name !== 'Alle'"
                                        :src="infoIcon"
                                        class="icon20 iconToWhite"
                                        alt="Klasseninfo"
                                        v-on="on"
                                    >
                                </template>
                                <div v-if="accountRole==='maintainer' && currentGroup && currentGroup.name !== 'Alle'">
                                    <v-list
                                        dense
                                        color="rgba(97, 97, 97, 0.9)"
                                        style="min-width: 150px !important;"
                                    >
                                        <p
                                            class="mx-2"
                                            style="font-weight: bold; color: white"
                                        >
                                            Klassenlehrer:
                                        </p>
                                              
                                        <template v-for="element in currentGroup.leaders">
                                            <div
                                                :key="element._id"
                                                style="background-color: transparent; min-width: 150px !important;"
                                            >
                                                <div
                                                    class="d-flex flex-row justify-space-between px-2"
                                                    style="max-width: 28vw;"
                                                >
                                                    <div
                                                        class="justify-flex-start"
                                                        style="font-size: 14px; color: white"
                                                    >
                                                        {{ `${teachersById[element].name} 
                                                                    ${teachersById[element].lastName}` }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <p 
                                            class="mx-2"
                                            style="font-weight: bold; color: white"
                                        >
                                            Schüler:
                                        </p>
                                        <template v-for="element in currentGroup.participants">
                                            <div
                                                :key="element._id"
                                                style="background-color: transparent; min-width: 150px !important;"
                                            >
                                                <div
                                                    class="d-flex flex-row justify-space-between px-2"
                                                    style="max-width: 28vw;"
                                                >
                                                    <div
                                                        class="justify-flex-start"
                                                        style="font-size: 14px; color: white"
                                                    >
                                                        {{ `${element.name} 
                                                                    ${element.lastName}` }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-list>
                                </div>
                            </v-menu>
                            <v-tooltip
                                v-if="pupilOrParent && widgetView !== 'lesson'"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        class="timetableDisplay"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="changeViewBaby"
                                    >
                                        <h1
                                            style="font-size: larger"
                                        >
                                            Stundenplan
                                        </h1>
                                    </div>
                                </template>
                                <span>{{ 'Hier ' + (accountRole === 'pupil' ? 'kannst du' : 'können Sie') + ' den Stundenplan einsehen.' + (accountRole === 'pupil' && !dayBabyView ? ' Klicken um zur einfachen Ansicht zu wechseln.'
                                    : accountRole === 'pupil' && dayBabyView ? ' Klicken um zur vollen Tagesansicht wechseln' : '' ) }}</span>
                            </v-tooltip>
                            <div
                                v-if="accountRole !== 'maintainer' && widgetView === 'lesson'"
                                style="cursor: pointer"
                                @click="setWidgetViewThroughHeader('day', false)"
                            >
                                <h1>Fach</h1>
                            </div>
                        </div>

                        <div
                            v-if="accountRole !== 'maintainer' && !filterMode && widgetView !== 'lesson'"
                            class="d-flex justify-space-around align-center"
                        >
                            <div
                                v-if="widgetView === 'day'"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="subDay()"
                                        >
                                            <img
                                                :src="linksIcon"
                                                class="iconToWhite"
                                                style="height: 20px;"
                                                alt="Vorheriger Tag"
                                            >
                                        </v-btn>
                                    </template>
                                    vorheriger Tag
                                </v-tooltip>
                            </div>
                            <v-menu
                                :key="widgetView"
                                bottom
                                nudge-bottom="40"
                                nudge-left="110"
                                :z-index="10"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                                            <span
                                                v-bind="{ ...attrsMenu, ...attrsTooltip }"
                                                class="d-flex flex-row"
                                                :class="widgetView === 'maintainer' ? 'responsiveDatepickerMaintainer' : 'responsiveDatepicker'"
                                                :aria-label="germanDayStrings[date.getDay()] + ', ' + date.toLocaleDateString('de-DE', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                })"
                                                v-on="{ ...onMenu, ...onTooltip }"
                                            >
                                                {{
                                                    germanDayStrings[date.getDay()].slice(0,2) + ', ' + date.toLocaleDateString('de-DE', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    })
                                                }}
                                            </span>
                                        </template>
                                        Tag auswählen
                                    </v-tooltip>
                                </template>

                                <v-date-picker
                                    v-model="datePickerString"
                                    :allowed-dates="weekdaysOnly"
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                    color="var(--v-timetableColor-base)"
                                    @change="changeDateThroughDatePicker()"
                                />
                            </v-menu>
                            <div
                                v-if="widgetView === 'day'"
                                :style="windowWidth < 300 ? 'max-width: 23px' : ''"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="addDay()"
                                        >
                                            <img
                                                :src="rechtsIcon"
                                                class="iconToWhite"
                                                style="height: 20px;"
                                                alt="Nächster Tag"
                                            >
                                        </v-btn>
                                    </template>
                                    nächster Tag
                                </v-tooltip>
                            </div>
                        </div>

                        <div class="d-flex justify-end align-center">
                            <div
                                v-if="pupilOrParent"
                                class="pa-0 ml-2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            id="StundenplanVollbildButton"
                                            :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                            small
                                            text
                                            min-width="20"
                                            style="width: 20px; height: 20px"
                                            v-on="on"
                                            @click="toggleWeeklyViewWidget"
                                        >
                                            <img
                                                :src="biggerIcon"
                                                class="icon20 iconToWhite"
                                                alt="Stundenplan Wochenansicht im Vollbild"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Wochenansicht</span>
                                </v-tooltip>
                            </div>
                            <div
                                v-if="accountRole === 'maintainer'"
                                class="ml-0 pl-0"
                            > 
                                <ClickableBadge
                                    :event-list="totalSubstitutionCount"
                                    :delete-button="true"
                                    usage="vertretungsplan"
                                    :small-badge="true"
                                    @clearBadges="clearSubstitutionBadges"
                                />
                                <v-menu
                                    offset-y
                                    nudge-left="200"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            icon
                                            v-on="on"
                                        >
                                            <img
                                                :src="optionsIcon"
                                                alt="Optionen"
                                                class="icon30 iconToWhite"
                                            >
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item 
                                            @click="()=>{toggleWeeklyView(); toggleModes('substitutePlan')}"
                                        >
                                            <ClickableBadge
                                                :event-list="totalSubstitutionCount"
                                                :delete-button="true"
                                                usage="vertretungsplan"
                                                :small-badge="true"
                                                @clearBadges="clearSubstitutionBadges"
                                            />
                                            <img
                                                :src="vertretungsIcon"
                                                alt="Vertretungsplan"
                                                class="iconToDunkelgrau icon30"
                                                :style="totalSubstitutionCount > 0 ? 'margin-left: -10px' :!substitutePlan ? 'opacity:0.4' : ''"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Vertretungsplan öffnen
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="editSubjectView = true"
                                        >
                                            <img
                                                :src="fachIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                style="margin-left: -10px"
                                                :style="!editSubjectView ? 'opacity: 0.4' : ''"
                                                @click="editSubjectView = true"
                                            >
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="position: absolute;
                                                left: 24px;
                                                bottom: 10px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                                class="iconToDunkelgrau"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Fächer bearbeiten
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="editRoomsView = true"
                                        >
                                            <img
                                                :src="raumIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                style="margin-left: -10px"
                                                :style="!editRoomsView ? 'opacity: 0.4' : ''"
                                            >
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="position: absolute;
                                                left: 24px;
                                                bottom: 10px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                                class="iconToDunkelgrau"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Räume bearbeiten
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="toggleModes('roomMode')"
                                        >
                                            <img
                                                :src="raumIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                :style="!roomMode ? 'opacity: 0.4' : ''"
                                                style="margin-left: -10px;"
                                            >
                                        
                                            <v-list-item-title class="ml-2">
                                                {{ roomMode ? 'Raum filtern verlassen':'Nach Räumen filtern' }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="exportCurrentWeekPlan"
                                        >
                                            <img
                                                :src="exportIcon"
                                                alt="Exportieren"
                                                class="iconToDunkelgrau icon20"
                                                style="margin-left: -5px; opacity:0.4"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Woche exportieren
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="toggleModes('filterMode')"
                                        >
                                            <img
                                                :src="magnifierIcon"
                                                alt="Lehrer filtern"
                                                class="iconToDunklegrau square25 mr-2"
                                                :style="!filterMode ? 'opacity: 0.4' : ''"
                                                style="margin-left: -5px;"
                                            >
                                            <v-list-item-title>
                                                {{ !filterMode ? 'Lehrer suchen':'Lehrer suchen beenden' }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="toggleWeeklyViewWidget"
                                        >
                                            <img
                                                :src="biggerIcon"
                                                class="icon20 iconToDunkelgrau mr-2"
                                                alt="Stundenplan Wochenansicht im Vollbild"
                                                style="margin-left: -5px"
                                            >
                                            <v-list-item-title>
                                                Wochenansicht
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <v-menu
                                v-if="accountRole === 'teacher' && widgetView !== 'lesson'"
                                offset-y
                                nudge-left="160"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                    >
                                        <img
                                            :src="optionsIcon"
                                            alt="Optionen"
                                            class="icon30 iconToWhite"
                                        >
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        @click="()=>{toggleWeeklySickMode()}"
                                    >
                                        <img
                                            id="sickModeButton"
                                            :src="abwesendIcon"
                                            alt="Abwesend"
                                            class="iconToDunkelgrau icon30"
                                            style="cursor: pointer; margin-left: -10px"
                                        >
                                        <v-list-item-title class="ml-2">
                                            Abwesend melden
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        :disabled="!canEdit"
                                        @click="toggleModes('editMode')"
                                    >
                                        <img
                                            id="editModeButton"
                                            :src="stundenplanBearbeitenIcon"
                                            alt="Bearbeiten"
                                            class="iconToDunkelgrau icon30"
                                            style="cursor: pointer; margin-left: -10px"
                                            :style="!editMode ? 'opacity: 0.4' : '' "
                                        >
                                        <v-list-item-title class="ml-2">
                                            Stunden bearbeiten
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="toggleModes('filterMode')"
                                    >
                                        <img
                                            :src="magnifierIcon"
                                            alt="Lehrer filtern"
                                            class="iconToDunklegrau square25 mr-2"
                                            :style="!filterMode ? 'opacity: 0.4' : ''"
                                            style="margin-left: -5px;"
                                        >
                                        <v-list-item-title>
                                            {{ !filterMode ? 'Lehrer suchen': 'Lehrer suchen beenden' }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="toggleWeeklyViewWidget"
                                    >
                                        <img
                                            :src="biggerIcon"
                                            class="icon20 iconToDunkelgrau mr-2"
                                            alt="Stundenplan Wochenansicht im Vollbild"
                                            style="margin-left: -5px"
                                        >
                                        <v-list-item-title>
                                            Wochenansicht
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </div>

                <div
                    id="planBody"
                    class="planBody"
                >
                    <div
                        v-if="appointmentsLoading"
                        class="d-flex flex-column justify-center align-center"
                        style="padding-top: 30vh"
                    >
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        />
                        <p class="text-center">
                            Stunden werden geladen, dies kann einige Sekunden dauern
                        </p>
                    </div>
                    <div
                        v-else
                    >
                        <!-- date path -->
                        <div
                            style="width: 100%"
                            class="d-flex justify-space-between pt-3 align-center"
                        >
                            <div
                                v-if="((widgetView === 'lesson' && currentAppointment) || widgetView === 'day' || widgetView === 'week')"
                                style="display: flex; flex-direction: row; align-items: center; width: 100%"
                                aria-hidden="true"
                                :class="widgetView === 'lesson' ? 'responsiveDatePath' : ''"
                            >
                                <u class="d-flex flex-row align-center">
                                    <!-- <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        style="display: flex; align-items: center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <img
                                            style="width: 20px; margin: -4px 2px 0 0"
                                            :src="calendarIcon"
                                            alt
                                            class="mr-2"
                                        >
                                        <span
                                            :class="(widgetView === 'week' ? 'datePathText' : '') + ' datePathClickable'"
                                            @click="setWidgetViewThroughHeader('week', false)"
                                        >
                                            KW {{ getMondayOfWeek(date).getWeek() }}
                                        </span>
                                    </div>
                                </template>
                                Übersicht der Kalenderwochen
                                </v-tooltip> -->
                                    <div
                                        style="display: flex; align-items: center"
                                    >
                                        <img
                                            style="width: 20px; margin: -4px 2px 0 0"
                                            :src="calendarIcon"
                                            alt
                                            class="mr-2"
                                        >

                                        KW {{ getMondayOfWeek(date).getWeek() }}
                                    </div>
                                    <v-menu
                                        bottom
                                        nudge-bottom="40"
                                        nudge-left="110"
                                        :z-index="10"
                                        :close-on-content-click="false"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                class="d-flex flex-row"
                                                :class="widgetView === 'maintainer' ? 'responsiveDatepickerMaintainer' : 'responsiveDatepicker'"
                                                :aria-label="germanDayStrings[date.getDay()] + ', ' + date.toLocaleDateString('de-DE', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: '2-digit'
                                                })"
                                                v-on="on"
                                            >
                                                /{{
                                                    germanDayStrings[date.getDay()].slice(0,2) + ', ' + date.toLocaleDateString('de-DE', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: '2-digit'
                                                    })
                                                }}
                                            </span>
                                        </template>

                                        <v-date-picker
                                            v-model="datePickerString"
                                            :allowed-dates="weekdaysOnly"
                                            locale="de-DE"
                                            first-day-of-week="1"
                                            no-title
                                            show-week
                                            color="var(--v-timetableColor-base)"
                                            @change="changeDateThroughDatePicker()"
                                        />
                                    </v-menu>
                                    <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            v-if="(widgetView === 'lesson' || widgetView === 'day') && pupilOrParent"
                                            style="display: flex; flex-direction: row; align-items: center"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span style="white-space: pre"> / </span>
                                            <span
                                                :class="(widgetView === 'day' ? 'datePathText' : '') + ' datePathClickable'"
                                                @click="setWidgetViewThroughHeader('day', false)"
                                            >
                                                {{ date.toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit'}) }}
                                            </span>
                                        </div>
                                    </template>
                                    Tagesansicht
                                </v-tooltip> -->
                                    <div
                                        v-if="widgetView === 'lesson'"
                                        style="display: flex; flex-direction: row; align-items: center; white-space: nowrap; overflow: hidden"
                                    >
                                        <span style="white-space: pre"> / </span>
                                        <div
                                            v-if="currentAppointment !== 'empty'"
                                            style="display: flex; flex-direction: row; align-items: center; overflow: hidden"
                                        >
                                            <img
                                                :src="getWhiteIconSrc(currentAppointment.schoolSubject)"
                                                class="lessonIcon"
                                                :class="currentAppointment.schoolSubject.uploadedIcon ? 'pa-0' : ''"
                                                :style="'background-color: ' + currentAppointment.schoolSubject.color"
                                                alt=""
                                            >
                                            <span
                                                class="datePathText"
                                                style="overflow: hidden; text-overflow: ellipsis"
                                            >
                                                {{ currentAppointment.schoolSubject.name }}
                                            </span>
                                        </div>
                                        <span
                                            v-else
                                            class="datePathText"
                                        >
                                            Hohlstunde
                                        </span>
                                    </div>
                                </u>
                            </div>
                            <v-spacer />
                            <div
                                v-if="widgetView === 'day'"
                                class="justify-flex-end mb-2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            elevation="0"
                                            small
                                            color="hellblau"
                                            class="ml-1 pa-0 text-capitalize"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="jumpToCurrentDay()"
                                        >
                                            <span style="color:white">Heute</span>
                                        </v-btn>
                                    </template>
                                    Zum heutigen Tag springen
                                </v-tooltip>
                            </div>
                        </div>

                        <!-- lesson view -->
                        <p
                            v-if="widgetView === 'lesson' && accountRole === 'maintainer' && !currentGroup"
                            style="font-size: xx-large; padding: 24px 12px"
                        >
                            Wählen Sie eine Gruppe aus.
                        </p>
                        <p
                            v-else-if="widgetView === 'lesson' && noAppointmentsToday && datesOnSameDay(new Date(), date) && accountRole !== 'maintainer'"
                            style="font-size: xx-large; padding: 24px 12px"
                        >
                            {{ `${accountRole === 'pupil' ? 'Du hast' : 'Sie haben'} heute keinen Unterricht.` }}
                        </p>
                        <Fach
                            v-else-if="widgetView === 'lesson' && currentAppointment !== 'empty'"
                            :ref="currentAppointment ? `pupilAppointment${currentAppointment._id}` : ''"
                            :key="forceUpdate"

                            :appointment="currentAppointment"
                            :timeslot="currentSlot"
                            :day="new Date(date.getFullYear(), date.getMonth(), date.getDate())"
                            :is-running="currentSlot ? isTimeslotCurrentlyRunningToday(currentSlot, null) : false"
                            :is-joinable="currentSlot ? isJoinable(currentSlot) : false"
                            :is-open="currentAppointment ? currentAppointment.open : false"
                            :icons="iconUrls"

                            :slots="slots"
                            :subjects="subjects"
                            :teachers="teachers"
                            :groups="groups"
                            :filter-mode="filterMode"
                            :widget-view="widgetView"

                            :person="person"
                            :current-filtered-teacher="currentFilteredTeacher"
                            :current-group="currentGroup"
                            :fullscreen-weekly-view="false"
                            :edit-mode="false"

                            :parent="accountRole === 'parent'"

                            :request-appointments="requestAppointments"

                            :upload-file="fileToUpload"
                            :upload-file-callback="callFileUpload"

                            @fileUploaded="callFileUpload = false"

                            @pass-on-bbb-tut="receivedEmit"
                            @fachBadge="$emit('fachBadge')"
                            @clearFachBadge="$emit('clearFachBadge')"
                            @audioOverlay="handleAudioUrl"
                        />
                        <Fach
                            v-else-if="widgetView === 'lesson' && currentAppointment === 'empty' && accountRole === 'maintainer'"
                            :key="forceUpdate"

                            :timeslot="currentSlot"
                            :day="new Date(date.getFullYear(), date.getMonth(), date.getDate())"
                            :is-running="currentSlot ? isTimeslotCurrentlyRunningToday(currentSlot, null) : false"
                            :is-joinable="currentSlot ? isJoinable(currentSlot) : false"
                            :is-open="currentAppointment ? currentAppointment.open : false"
                            :icons="iconUrls"

                            :slots="slots"
                            :subjects="subjects"
                            :teachers="teachers"
                            :groups="groups"
                            :filter-mode="filterMode"
                            :widget-view="widgetView"

                            :person="person"
                            :current-filtered-teacher="currentFilteredTeacher"
                            :current-group="currentGroup"
                            :fullscreen-weekly-view="false"
                            :edit-mode="false"

                            :parent="accountRole === 'parent'"

                            :request-appointments="requestAppointments"
                            @fileUploaded="callFileUpload = false"

                            @pass-on-bbb-tut="receivedEmit"
                            @cancelCreationEmptyLesson="setWidgetViewThroughHeader('day', false)"
                            @fachBadge="$emit('fachBadge')"
                            @clearFachBadge="$emit('clearFachBadge')"
                            @audioOverlay="handleAudioUrl"
                        />
                        <!-- day view -->
                        <p
                            v-if="widgetView === 'day' && accountRole === 'maintainer' && !currentGroup"
                            style="font-size: xx-large; padding: 24px 12px"
                        >
                            Wählen Sie eine Gruppe aus.
                        </p>
                        <div
                            v-else-if="widgetView === 'day' && ((accountRole === 'teacher' && teacherSlotsAtDate.length === 0) || saturdayOrSunday)"
                            class="d-flex justify-center"
                            style="padding-top: 9vh"
                            :style="accountRole === 'teacher' ? 'cursor: pointer' : ''"
                            @click="accountRole === 'teacher' ? toggleWeeklyEditMode() : ''"
                        >
                            <div
                                class="flex-column"
                            >
                                <div
                                    v-if="!filterMode || currentFilteredTeacher"
                                    class="d-flex flex-row"
                                    style="height: 100px; justify-content: center"
                                >
                                    <img
                                        :src="keineStundenIcon"
                                        alt="Keine Stunden"
                                    >
                                </div>
                                <div v-if="filterMode">
                                    <p
                                        class="mt-2 text-center"
                                        style="font-size: 20px"
                                    >
                                        {{ !currentFilteredTeacher ? 'Wählen Sie einen Lehrer um dessen Stundenplan anzuzeigen' 
                                            : accountRole !== 'maintainer' ? `${currentFilteredTeacher.name} ${currentFilteredTeacher.lastName} hat an diesem Tag keinen Unterricht` :'' }}
                                    </p>
                                </div>
                                <div v-if="!filterMode">
                                    <p
                                        class="mt-2 text-center"
                                        style="font-size: 20px"
                                    >
                                        {{ accountRole === 'teacher' ? 'Sie haben heute keinen Unterricht' : 'Diese Gruppe hat heute keinen Unterricht' }}
                                    </p>
                                    <p
                                        v-if="accountRole === 'teacher'"
                                        class="mt-2 text-center"
                                        style="font-size: 20px"
                                    >
                                        Auf "
                                        <img
                                            :src="stundenplanBearbeitenIcon"
                                            class="iconToDunkelgrau"
                                            style="height: 18px; cursor: pointer; margin-bottom: -3px"
                                            alt="Stundenplan bearbeiten"
                                        >
                                        " oder hier klicken um neue Stunden zu erstellen
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Zeige "Aktuell kein Unterricht" wenn aktuell kein timeslot läuft oder wenn mindestens ein timeslot läuft aber alle anzuzeigende timeslots leer sind -->
                        <div
                            v-else-if="dayBabyView && ((currentlyRunningSlots.length === 0 || (currentlyRunningSlots.length > 0 && currentlyRunningSlots.length === emptySlots.length)))"
                            class="d-flex justify-center"
                            style="padding-top: 10vh"
                        >
                            <div
                                class="flex-column"
                            >
                                <div
                                    class="d-flex flex-row"
                                    style="height: 100px; justify-content: center"
                                >
                                    <img
                                        :src="keineStundenIcon"
                                        alt="Keine Stunden"
                                    >
                                </div>
                                <div
                                    style="font-size: 20px; padding: 8px 12px"
                                >
                                    Aktuell kein Unterricht
                                </div>
                            </div>
                        </div>
                        <div v-else-if="widgetView === 'day'">
                            <v-badge
                                v-if="accountRole === 'maintainer' && !hideBadges"
                                content="5"
                                color="green"
                                left
                                overlap
                                style="cursor: pointer;"
                                class="insetBadge"
                            />
                            <vue-custom-scrollbar
                                :class="accountRole === 'maintainer' ? 'scroll-area-maintainer' : 'scroll-area'"
                                :settings="settings"
                            >
                                <div
                                    v-for="(item,index) in accountRole === 'teacher' ? teacherSlotsAtDate : (dayBabyView ? currentlyRunningSlots : slots)"
                                    :key="'date_' + index"
                                    class="weeklyFachRow"
                                >
                                    <!-- <span>{{ index + 1 + '.' }}</span> -->
                                    <div
                                        v-if="!babyView || !emptySlots.some((emptySlot) => { return emptySlot === item._id })"
                                        style="width: 100%"
                                    >
                                        <div v-if="appointmentsTodayByTimeslot[(item._id || item)]">
                                            <div
                                                v-for="el in appointmentsTodayByTimeslot[(item._id || item)]"
                                                :key="el._id"
                                            >
                                                <Fach
                                                    :ref="`pupilAppointment${el._id}`"
                                                    :key="forceUpdate"
                                                    class="pr-3"

                                                    :appointment="el"
                                                    :is-running="isTimeslotCurrentlyRunningToday(item, null)"
                                                    :is-joinable="isJoinable(item)"
                                                    :is-open="el.open"
                                                    :timeslot="item"
                                                    :icons="iconUrls"
                                                    :filter-mode="filterMode"
                                                    :slots="slots"
                                                    :subjects="subjects"
                                                    :teachers="teachers"
                                                    :groups="groups"
                                                    :hour-number="index+1"

                                                    :request-appointments="requestAppointments"
                                                    :parent="accountRole === 'parent'"

                                                    :person="person"
                                                    :current-filtered-teacher="currentFilteredTeacher"
                                                    :current-group="currentGroup"

                                                    :upload-file="fileToUpload"
                                                    :upload-file-callback="callFileUpload"

                                                    @fileUploaded="callFileUpload = false"

                                                    @pass-on-bbb-tut="receivedEmit"
                                                    @clickedFach="(newDate, appointment) => { setDate(newDate, 'lesson', appointment) }"
                                                    @fachBadge="$emit('fachBadge')"
                                                    @clearFachBadge="$emit('clearFachBadge')"
                                                    @audioOverlay="handleAudioUrl"
                                                />
                                                <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                                <!-- eslint-disable vue/no-v-html -->
                                                <div
                                                    v-if="el.note && babyView && windowWidth > 900"
                                                    style="display: flex; flex-flow: column; height: 100%; justify-content: center; padding-right: 20px;"
                                                    v-html="sanitizeHtml(parseHtmlPreview(el.note))"
                                                />
                                                <!-- eslint-enable vue/no-v-html -->
                                            </div>
                                        </div>
                                        <!--                                <div v-else-if="babyView" />-->
                                        <div v-else>
                                            <Fach
                                                :key="forceUpdate"
                                                :timeslot="item"
                                                :is-running="isTimeslotCurrentlyRunningToday(item, null)"
                                                :is-joinable="isJoinable(item)"

                                                :slots="slots"
                                                :subjects="subjects"
                                                :teachers="teachers"
                                                :icons="iconUrls"
                                                :groups="groups"
                                                :hour-number="index+1"
                                                :filter-mode="filterMode"

                                                :person="person"
                                                :current-filtered-teacher="currentFilteredTeacher"
                                                :current-group="currentGroup"

                                                :request-appointments="requestAppointments"

                                                :upload-file-callback="callFileUpload"

                                                :upload-file="fileToUpload"
                                                @fileUploaded="callFileUpload = false"

                                                @pass-on-bbb-tut="receivedEmit"
                                                @clickedEmptyFach="(timeslot) => { editHohlstunde(timeslot) }"
                                                @fachBadge="$emit('fachBadge')"
                                                @clearFachBadge="$emit('clearFachBadge')"
                                                @audioOverlay="handleAudioUrl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </vue-custom-scrollbar>
                        </div>

                        <!-- week view -->
                        <vue-custom-scrollbar
                            v-else-if="widgetView === 'week'"
                            ref="weeklyViewScrollbar"
                            class="scroll-area"
                            :settings="settings"
                            @ps-scroll-y="weekViewScrollEvent()"
                        >
                            <div style="display: flex; flex-direction: column; width: 100%">
                                <div
                                    v-for="date in futureDates"
                                    :id="'weekContainer-'+ date.date.getFullYear() + '-' + date.date.getWeek()"
                                    :key="date.date + date.date.getWeek()"
                                    style="display: flex; flex-direction: column"
                                >
                                    <div class="d-flex justify-space-between align-center">
                                        <div class="calendarWeekLine" />
                                        <!-- format: KW 15 (11.04. - 15.04) -->
                                        <span style="margin: auto; font-size: 12pt">
                                            {{ getCalendarWeek(date) }}
                                        </span>
                                        <div class="calendarWeekLine" />
                                    </div>

                                    <div
                                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin: auto; justify-content: center"
                                    >
                                        <div
                                            v-for="(day, index) in date.days"
                                            :key="'date_' + index"
                                            style="display: flex; flex-direction: column;  position: relative; cursor: pointer"
                                            @click="setDate(day, 'day')"
                                        >
                                            <!-- v-card is used for that small shadow -->
                                            <v-card
                                                class="calendarDate"
                                                :class="isDateInPast(day) ? 'calendarDateInPast' : ''"
                                            >
                                                <div
                                                    style="height: 35px; width: 150px; background-color: #F4511E; border-radius: 12px 12px 0 0"
                                                />
                                                <span style="font-size: 40pt; padding-top: 5px">
                                                    {{ day.getDate() + "." }}
                                                </span>
                                            </v-card>
                                            <span style="margin: auto">{{ germanDayStrings[day.getDay()] }}</span>
                                        </div>
                                        <!-- placeholder for 6th day -->
                                        <div style="width: 150px; margin: 12px" />
                                    </div>
                                </div>
                            </div>
                        </vue-custom-scrollbar>
                        <div
                            v-if="(accountRole === 'parent' || accountRole === 'pupil') && slots.length < 1"
                            class="mt-4"
                            style="display: flex; flex-direction: column; margin: 16px; align-items: center; font-size: 20px; padding-top: 9vh"
                        >
                            <div
                                class="d-flex flex-row"
                                style="height: 100px; justify-content: center"
                            >
                                <img
                                    :src="keineStundenIcon"
                                    alt="Keine Stunden"
                                    style="width: 80px;height: 80px;"
                                >
                            </div>
                            <p>
                                Der Schüler ist noch keiner Gruppe zugewiesen und hat daher keinen Stundenplan!
                            </p>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>

        <!--fullscreen/weekly view of stundenplan-->
        <div
            v-if="weeklyView"
        >
            <div
                style="border-radius: 16px;  box-shadow: 1px 5px 5px silver; background: white;"
            >
                <!-- Clickable badge -->
                <div style="z-index: 3;">
                    <ClickableBadge
                        v-if="substitutePlan"
                        :event-list="substituteAppointmentList"
                        @badgeClicked="substitutionBadgeClicked"
                    />
                </div>
                <!--#region Header -->
                <div
                    id="weeklyViewHeaderContainer"
                    class="d-flex justify-space-between align-center"
                    style="border-radius: 16px 16px 0px 0px; width: 100%"
                >
                    <div
                        class="d-flex align-center pa-0"
                        style="font-size: x-large; justify-content: flex-start"
                    >
                        <v-tooltip>
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    v-if="accountRole === 'maintainer' ? windowWidth > 315 : true"
                                    :src=" substitutePlan ? vertretungsIcon : stundenplanIcon"
                                    class="iconToWhite icon20"
                                    :style="substitutePlan ? 'padding-right: 4px' : ''"
                                    alt="Stundenplan"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="planIconClicked"
                                >
                            </template>
                            <span>{{ substitutePlan ? 'Vertretungsplan':'Stundenplan' }}</span>
                        </v-tooltip>
                       
                        <v-autocomplete
                            v-if="(accountRole === 'maintainer' || accountRole === 'teacher') && filterMode"
                            v-model="currentFilteredTeacher"
                            :menu-props="{'z-index': 100}"
                            auto-select-first
                            label="Lehrer suchen"
                            single-line
                            dark
                            dense
                            clearable 
                            hide-details
                            :item-text="item => item.name + ' ' + item.lastName "
                            class="pl-2 align-center responsiveAutocompletePadding"
                            style="z-index: 9"
                            :items="teachers"
                            return-object
                            no-data-text="Kein Lehrer vorhanden"
                            @change="changeFilteredTeacher"
                        />
                        <v-autocomplete
                            v-if="((accountRole === 'teacher' && editMode) || (accountRole === 'maintainer' && !filterMode)|| substitutePlan) && !roomMode"
                            v-model="currentGroupModel"
                            :menu-props="{'z-index': 100}"
                            auto-select-first
                            label="Nach Gruppe suchen"
                            single-line
                            dark
                            dense
                            hide-details
                            :item-text="item => item.name === 'Alle' ? 'Alle Klassen' : item.name"
                            class="pl-2 align-center responsiveAutocompletePadding"
                            style="z-index: 9;"
                            :items="substitutePlan ? groupsWithAlle : accountRole === 'maintainer' ? groups : teacherGroups"
                            :filter="customFilter"
                            return-object
                            no-data-text="Keine Gruppen vorhanden"
                            @change="changeGroup"
                        />
                        <v-autocomplete
                            v-if="roomMode"
                            v-model="currentRoomModel"
                            :menu-props="{'z-index': 100}"
                            auto-select-first
                            label="Nach Raum suchen"
                            single-line
                            dark
                            dense
                            hide-details
                            :item-text="item => item.roomName"
                            class="pl-6 responsiveAutocompletePadding"
                            style="z-index: 9"
                            :items="allRooms"
                            return-object
                            no-data-text="Kein Raum vorhanden"
                            @change="changeRoom"
                        />   
                        <v-menu
                            v-if="accountRole === 'maintainer' && currentGroup.name !== 'Alle' && !filterMode"
                            bottom
                            nudge-right="30"
                            right
                            style="z-index: 99"
                        >
                            <template v-slot:activator="{ on }">
                                <img
                                    v-if="accountRole === 'maintainer' && currentGroup.name !== 'Alle'"
                                    :src="infoIcon"
                                    class="icon20 iconToWhite"
                                    alt="Klasseninfo"
                                    v-on="on"
                                >
                            </template>
                            <div v-if="accountRole === 'maintainer' && currentGroup.name !== 'Alle'">
                                <v-list
                                    dense
                                    color="rgba(97, 97, 97, 0.9)"
                                    style="min-width: 150px !important;"
                                >
                                    <p
                                        class="mx-2"
                                        style="font-weight: bold; color: white"
                                    >
                                        Klassenlehrer:
                                    </p>
                                              
                                    <template v-for="element in currentGroup.leaders">
                                        <div
                                            :key="element._id"
                                            style="background-color: transparent; min-width: 150px !important;"
                                        >
                                            <div
                                                class="d-flex flex-row justify-space-between px-2"
                                                style="max-width: 28vw;"
                                            >
                                                <div
                                                    class="justify-flex-start"
                                                    style="font-size: 14px; color: white"
                                                >
                                                    {{ `${teachersById[element].name} 
                                                                    ${teachersById[element].lastName}` }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <p 
                                        class="mx-2"
                                        style="font-weight: bold; color: white"
                                    >
                                        Schüler:
                                    </p>
                                    <template v-for="element in currentGroup.participants">
                                        <div
                                            :key="element._id"
                                            style="background-color: transparent; min-width: 150px !important;"
                                        >
                                            <div
                                                class="d-flex flex-row justify-space-between px-2"
                                                style="max-width: 28vw;"
                                            >
                                                <div
                                                    class="justify-flex-start"
                                                    style="font-size: 14px; color: white"
                                                >
                                                    {{ `${element.name} 
                                                                    ${element.lastName}` }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </v-list>
                            </div>
                        </v-menu>
                    </div>

                    <div
                        v-if="accountRole !== 'maintainer' && !filterMode"
                        class="d-flex justify-space-around align-center"
                        style="justify-content: center"
                    >
                        <v-badge
                            color="#ff0000"
                            :content="substitutePlan ? pastWeekOpenSubstitutions : pastWeekBadges"
                            :value="substitutePlan ? pastWeekOpenSubstitutions : pastWeekBadges"
                            overlap
                            left
                            offset-x="20"
                        >
                            <v-btn
                                class="ml-2 responsiveHeaderDateButton"
                                elevation="0"
                                icon
                                small
                                @click="decreaseDate"
                            >
                                <img
                                    :src="linksIcon"
                                    alt="Vorherige Woche"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </v-badge>

                        <div
                            style="display: flex; flex-direction: row; align-items: center; font-size: medium;"
                            :style="windowWidth < 375 ? 'max-width: 23px' : ''"
                            class="responsiveDate"
                        >
                            <v-menu
                                :key="widgetView"
                                bottom
                                nudge-bottom="40"
                                nudge-left="110"
                                :z-index="10"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                                            <div
                                                v-bind="{ ...attrsMenu, ...attrsTooltip }"
                                                v-on="{ ...onMenu, ...onTooltip }"
                                            >
                                                <span
                                                    v-if="windowWidth >600"
                                                    :class="widgetView === 'maintainer' ? 'responsiveDatepickerMaintainer' : ''"
                                                    :aria-label="germanDayStrings[date.getDay()] + ', ' + date.toLocaleDateString('de-DE', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    })"
                                                    :style="windowWidth > 900 ? 'display: flex; flex-row:':''"
                                                >
                                                    <p
                                                        class="px-0 text-center"
                                                        style="min-width: 80px; font-size: 14px;"
                                                    >
                                                        {{
                                                            substitutePlan && currentGroup.name === 'Alle' ?
                                                                germanDayStrings[date.getDay()] + ' ' + date.toLocaleDateString('de-DE', {
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    year: 'numeric'
                                                                }) :
                                                                getDisplayMonth() + " '" + getMondayOfWeek(date).getFullYear().toString().slice(2, 4)
                                                        }}
                                                
                                                        KW {{ getMondayOfWeek(date).getWeek() }}
                                                    </p>
                                                </span>
                                                <div
                                                    v-else
                                                    class="d-flex flex-row align-center"
                                                >
                                                    <img
                                                        :src="calendarIcon"
                                                        class="iconToWhite icon30"
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                        Tag auswählen
                                    </v-tooltip>
                                </template>

                                <v-date-picker
                                    v-model="datePickerString"
                                    :allowed-dates="weekdaysOnly"
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                    color="var(--v-timetableColor-base)"
                                    @change="changeDateThroughDatePicker()"
                                />
                            </v-menu>
                        </div>
                        <v-badge
                            color="#ff0000"
                            style="z-index: 40"
                            :content="substitutePlan ? futureWeekOpenSubstitutions : futureWeekBadges"
                            :value="substitutePlan ? futureWeekOpenSubstitutions : futureWeekBadges"
                            right
                            offset-x="20"
                            overlap
                        >
                            <v-btn
                                class="mx-2 responsiveHeaderDateButton"
                                elevation="0"
                                icon
                                small
                                @click="increaseDate"
                            >
                                <img
                                    :src="rechtsIcon"
                                    alt="Nächste Woche"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </v-badge>
                        <v-btn
                            v-if="!toggleTodayBtn"
                            class="text-capitalize"
                            :class="accountRole === 'maintainer' ? 'maintainerToday' : 'displayNoneResponsiveToday'"
                            style="background-color: #f8f8f880; color: white"
                            elevation="0"
                            @click="jumpToCurrentDay()"
                        >
                            Heute
                        </v-btn>
                    </div>
                    <div
                        class="d-flex px-0 align-center"
                        style=" justify-content: flex-end;"
                    >
                        <v-tooltip
                            v-if="windowWidth > 600 && copyMode"
                            top
                            z-index="100"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    elevation="0"
                                    class="pa-0"
                                    style="min-height: 36px; min-width: 36px; border-radius: 12px; background-color: red"
                                    v-on="on"
                                    @click="hideCopyMode"
                                >
                                    <img
                                        :src="schliessenIcon"
                                        class="iconToWhite"
                                        style="height: 25px; width: 25px"
                                        alt="Kopieren beenden"
                                    >
                                </v-btn>
                            </template>
                            <span>Kopieren beenden</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="windowWidth > 600"
                            top
                            z-index="100"
                        >
                            <template v-slot:activator="{ on }">
                                <button
                                    class="d-flex align-center mr-2  square20" 
                                    style="position: relative; cursor: pointer"
                                    v-on="on"
                                    @click="toggleModes('filterMode')"
                                >
                                    <img
                                        :src="magnifierIcon"
                                        alt="Lehrer filtern"
                                        class="iconToWhite square20"
                                        :style="!filterMode ? 'opacity: 0.4' : ''"
                                    >
                                </button>
                            </template>
                            <span>Hier können Sie nach einzelnen Lehrerstundenplänen filtern.</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(accountRole === 'maintainer' || accountRole === 'teacher') && windowWidth > 600"
                            top
                            z-index="100"
                        >
                            <template v-slot:activator="{ on }">
                                <div
                                    v-ripple
                                    class="d-flex align-center mr-1 pl-1"
                                    style="position: relative; cursor: pointer"
                                    v-on="on"
                                >
                                    <img
                                        :src="raumIcon"
                                        alt="Bearbeiten"
                                        class="iconToWhite icon30"
                                        :style="!roomMode ? 'opacity: 0.4' : ''"
                                        @click="toggleModes('roomMode')"
                                    >
                                </div>
                            </template>
                            <span>Hier können Sie nach Räumen filtern.</span>
                        </v-tooltip>
                        <!-- <div
                            v-if="(accountRole === 'teacher' && widgetView !== 'lesson')
                                && windowWidth > 600"
                        >
                            <ClickableBadge
                                v-if="!substitutePlan && !weeklyView"
                                :event-list="totalSubstitutionCount"
                                :delete-button="true"
                                usage="vertretungsplan"
                                :small-badge="true"
                                @clearBadges="clearSubstitutionBadges"
                            />
                            <v-tooltip
                                bottom
                                :value="sickAppointments.length ? true : null"
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="toggleWeeklySickMode()"
                                    >
                                        <img
                                            :src="accountRole === 'teacher' ? abwesendIcon : vertretungsIcon"
                                            style="height: 28px; cursor: pointer; opacity:0.4"
                                            :alt="accountRole === 'teacher' ? 'Abwesend melden' : 'Vertretungsplan öffnen'"
                                        >
                                    </v-btn>
                                </template>
                                <span>
                                    {{ accountRole === 'teacher' ? 'Abwesend melden' : 'Vertretungsplan öffnen' }}
                                </span>
                            </v-tooltip>
                        </div> -->
                        <div
                            v-if="accountRole === 'maintainer'"
                            class="d-flex align-center"
                        >
                            <v-tooltip
                                v-if="windowWidth > 600"
                                top
                                z-index="100"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center mr-1 pl-1 headerButton"
                                        style="position: relative; cursor: pointer"
                                    >
                                        <img
                                            :src="exportIcon"
                                            alt="Exportieren"
                                            class="iconToWhite"
                                            style="opacity: 0.4; height: 25px"
                                            v-on="on"
                                            @click="exportCurrentWeekPlan"
                                        >
                                    </div>
                                </template>
                                <span>Exportieren Sie die aktuelle Stundenplan Woche als PDF</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="windowWidth > 600"
                                top
                                z-index="100"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center mr-1 pl-1 headerButton"
                                        style="position: relative; cursor: pointer"
                                    >
                                        <img
                                            :src="fachIcon"
                                            alt="Bearbeiten"
                                            class="iconToWhite icon30"
                                            :style="!editSubjectView ? 'opacity: 0.4' : ''"
                                            v-on="on"
                                            @click="editSubjectView = true"
                                        >
                                        <img
                                            :src="bearbeitenIcon"
                                            alt="Bearbeiten"
                                            style="position: absolute;
                                                right: 10px;
                                                bottom: 5px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                            :class="!editSubjectView ? 'iconToSeeThroughBlue' : 'iconToDarkBlue'"
                                        >
                                    </div>
                                </template>
                                <span>Hier können Sie die Fächer Ihrer Schule verwalten.</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="windowWidth > 600"
                                top
                                z-index="100"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center mr-1 pl-1 headerButton "
                                        style="position: relative; cursor: pointer"
                                    >
                                        <img
                                            :src="raumIcon"
                                            alt="Bearbeiten"
                                            class="iconToWhite icon30"
                                            :style="!editRoomsView ? 'opacity: 0.4' : ''"
                                            v-on="on"
                                            @click="editRoomsView = true"
                                        >
                                        <img
                                            :src="bearbeitenIcon"
                                            alt="Bearbeiten"
                                            style="position: absolute;
                                                right: 10px;
                                                bottom: 5px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                            :class="!editRoomsView ? 'iconToSeeThroughBlue' : 'iconToDarkBlue'"
                                        >
                                    </div>
                                </template>
                                <span>Hier können Sie die Räume Ihrer Schule verwalten.</span>
                            </v-tooltip>
                            <div 
                                v-if="windowWidth > 600"
                            >
                                <ClickableBadge
                                    :event-list="totalSubstitutionCount"
                                    :delete-button="true"
                                    usage="vertretungsplan"
                                    :small-badge="true"
                                    @clearBadges="clearSubstitutionBadges"
                                />
                                <v-tooltip
                                    top
                                    z-index="100"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            v-ripple
                                            class="d-flex align-center mr-1 pl-1"
                                            style="cursor: pointer"
                                        >
                                            <img
                                                :src="vertretungsIcon"
                                                alt="Bearbeiten"
                                                class="icon30"
                                                :style="!substitutePlan ? 'opacity:0.4' : ''"
                                                v-on="on"
                                                @click="toggleModes('substitutePlan')"
                                            >
                                        </div>
                                    </template>
                                    <span>Vertretungsplan</span>
                                </v-tooltip>
                            </div>
                            <v-tooltip
                                v-if="sickAppointments.length > 0 || removeSickAppointments.length > 0"
                                top
                                :value="sickAppointments.length ? true : null"
                                z-index="100"
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center mr-3 pl-1 headerButton"
                                        :style="(sickAppointments.length || removeSickAppointments.length) ? 'background-color: var(--v-chatColor-base); border-radius: 8px;' : ''"
                                        style="cursor: pointer"
                                    >
                                        <img
                                            v-if="(sickAppointments.length || removeSickAppointments.length) "
                                            :src="bestaetigenIcon"
                                            class="iconToWhite mb-1"
                                            :height="40"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="confirmSickMaintainer"
                                        >
                                    </div>
                                </template>
                                <div style="display: flex; flex-direction: column; justify-content: center">
                                    <v-icon
                                        v-if="(sickAppointments.length || removeSickAppointments.length) "
                                        color="white"
                                    >
                                        mdi-menu-up
                                    </v-icon>
                                    <span>{{
                                        (sickAppointments.length || removeSickAppointments.length) ? 'Bestätigen Sie hier Ihre Auswahl' : 'Abwesend melden'
                                    }}</span>
                                </div>
                            </v-tooltip>
                            <div
                                v-if="windowWidth < 601"
                            > 
                                <ClickableBadge
                                    :event-list="totalSubstitutionCount"
                                    :delete-button="true"
                                    usage="vertretungsplan"
                                    :small-badge="true"
                                    @clearBadges="clearSubstitutionBadges"
                                />
                                <v-menu
                                    offset-y
                                    nudge-left="200"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            icon
                                            v-on="on"
                                        >
                                            <img
                                                :src="optionsIcon"
                                                alt="Optionen"
                                                class="icon30 iconToWhite"
                                            >
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item 
                                            @click="toggleModes('substitutePlan')"
                                        >
                                            <ClickableBadge
                                                :event-list="totalSubstitutionCount"
                                                :delete-button="true"
                                                usage="vertretungsplan"
                                                :small-badge="true"
                                                @clearBadges="clearSubstitutionBadges"
                                            />
                                            <img
                                                :src="vertretungsIcon"
                                                alt="Vertretungsplan"
                                                class="iconToDunkelgrau icon30"
                                                :style="totalSubstitutionCount > 0 ? 'margin-left: -10px' :!substitutePlan ? 'opacity:0.4' : ''"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Vertretungsplan {{ substitutePlan ?'schließen' : 'öffnen' }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="editSubjectView = true"
                                        >
                                            <img
                                                :src="fachIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                style="margin-left: -10px"
                                                :style="!editSubjectView ? 'opacity: 0.4' : ''"
                                                @click="editSubjectView = true"
                                            >
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="position: absolute;
                                                left: 24px;
                                                bottom: 10px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                                class="iconToDunkelgrau"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Fächer bearbeiten
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="editRoomsView = true"
                                        >
                                            <img
                                                :src="raumIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                style="margin-left: -10px"
                                                :style="!editRoomsView ? 'opacity: 0.4' : ''"
                                            >
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="position: absolute;
                                                left: 24px;
                                                bottom: 10px;
                                                width: 15px !important;
                                                height: 15px !important;
                                                max-width: 15px !important;
                                                max-height: 15px !important;"
                                                class="iconToDunkelgrau"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Räume bearbeiten
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="toggleModes('roomMode')"
                                        >
                                            <img
                                                :src="raumIcon"
                                                alt="Bearbeiten"
                                                class="iconToDunkelgrau icon30"
                                                :style="!roomMode ? 'opacity: 0.4' : ''"
                                                style="margin-left: -10px;"
                                            >
                                        
                                            <v-list-item-title class="ml-2">
                                                {{ roomMode ? 'Raum filtern verlassen':'Nach Räumen filtern' }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="exportCurrentWeekPlan"
                                        >
                                            <img
                                                :src="exportIcon"
                                                alt="Exportieren"
                                                class="iconToDunkelgrau icon20"
                                                style="margin-left: -5px; opacity:0.4"
                                            >
                                            <v-list-item-title class="ml-2">
                                                Woche exportieren
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="toggleModes('filterMode')"
                                        >
                                            <img
                                                :src="magnifierIcon"
                                                alt="Lehrer filtern"
                                                class="iconToDunklegrau square25 mr-2"
                                                :style="!filterMode ? 'opacity: 0.4' : ''"
                                                style="margin-left: -5px;"
                                            >
                                            <v-list-item-title>
                                                {{ !filterMode ? 'Lehrer suchen':'Lehrer suchen beenden' }}
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            @click="clickCloseButton"
                                        >
                                            <img
                                                :src="biggerIcon"
                                                class="icon20 iconToDunkelgrau mr-2"
                                                alt="Stundenplan Wochenansicht im Vollbild"
                                                style="margin-left: -5px"
                                            >
                                            <v-list-item-title>
                                                Tagesansicht
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                        <ClickableBadge
                            v-if="!substitutePlan && (accountRole !== 'teacher' ? !weeklyView : true) && !(sickAppointments.length || removeSickAppointments.length)"
                            :event-list="totalSubstitutionCount"
                            :delete-button="true"
                            :small-badge="true"
                            usage="vertretungsplan"
                            :teacher-weekly="true"
                            @clearBadges="clearSubstitutionBadges"
                        />
                        <div
                            v-if="accountRole === 'teacher'"
                            class="d-flex "
                        >
                            <v-tooltip
                                v-if="accountRole === 'teacher' && windowWidth > 600"
                                top
                                :value="(sickAppointments.length || removeSickAppointments.length) ? true : null"
                                z-index="100"
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center"
                                        :style="(sickAppointments.length || removeSickAppointments.length) ? 'background-color: var(--v-chatColor-base); border-radius: 8px' : ''"
                                        style="cursor: pointer"
                                    >
                                        <img
                                            v-if="(sickAppointments.length || removeSickAppointments.length) "
                                            :src="bestaetigenIcon"
                                            class="iconToWhite mb-1"
                                            :height="40"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="toggleModes('sickMode')"
                                        >
                                        <img
                                            v-else-if="sickMode"
                                            id="sickModeButton"
                                            :src="abwesendIcon"
                                            alt="Abwesend"
                                            :height="35"
                                            style="cursor: pointer;"
                                            v-on="on"
                                            @click="toggleModes('sickMode')"
                                        >
                                        <img
                                            v-else
                                            id="sickModeButton"
                                            :src="abwesendIcon"
                                            alt="Abwesend"
                                            :height="35"
                                            style="opacity: 0.4; cursor: pointer"
                                            v-on="on"
                                            @click="toggleModes('sickMode')"
                                        >
                                    </div>
                                </template>
                                <div style="display: flex; flex-direction: column; justify-content: center">
                                    <v-icon
                                        v-if="(sickAppointments.length || removeSickAppointments.length)"
                                        color="white"
                                    >
                                        mdi-menu-up
                                    </v-icon>
                                    <span>{{
                                        (sickAppointments.length || removeSickAppointments.length) ? 'Bestätigen Sie hier Ihre Auswahl' : 'Abwesend melden'
                                    }}</span>
                                </div>
                            </v-tooltip>
                            <v-tooltip
                                v-if="accountRole === 'teacher' && windowWidth < 601"
                                top
                                :value="(sickAppointments.length || removeSickAppointments.length) ? true : null"
                                z-index="100"
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <div
                                        v-ripple
                                        class="d-flex align-center"
                                        :style="(sickAppointments.length || removeSickAppointments.length) ? 'background-color: var(--v-chatColor-base); border-radius: 8px' : ''"
                                        style="cursor: pointer"
                                    >
                                        <img
                                            v-if="(sickAppointments.length || removeSickAppointments.length) "
                                            :src="bestaetigenIcon"
                                            class="iconToWhite mb-1"
                                            :height="40"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="toggleModes('sickMode')"
                                        >
                                    </div>
                                </template>
                                <div style="display: flex; flex-direction: column; justify-content: center">
                                    <v-icon
                                        v-if="(sickAppointments.length || removeSickAppointments.length)"
                                        color="white"
                                    >
                                        mdi-menu-up
                                    </v-icon>
                                    <span>{{
                                        (sickAppointments.length || removeSickAppointments.length) ? 'Bestätigen Sie hier Ihre Auswahl' : 'Abwesend melden'
                                    }}</span>
                                </div>
                            </v-tooltip>
                            <v-tooltip
                                v-if="accountRole === 'teacher' && windowWidth > 600"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="headerButton "
                                        icon
                                        :disabled="!canEdit"
                                        v-on="on"
                                        @click="toggleModes('editMode')"
                                    >
                                        <img
                                            id="editModeButton"
                                            :src="stundenplanBearbeitenIcon"
                                            alt="Bearbeiten"
                                            :class="editMode ? 'iconToWhite icon30' : 'iconToLightGray icon30'"
                                            style="cursor: pointer"
                                        >
                                    </v-btn>
                                </template>
                                <span>{{
                                    canEdit ? 'Hier können Sie den Stundenplan Ihrer Klasse bearbeiten.' : 'Sie müssen die/der KlassenlehrerIn einer Klasse sein um deren Stundenplan zu bearbeiten.'
                                }}</span>
                            </v-tooltip>
                            <v-menu
                                v-if="windowWidth < 601"
                                offset-y
                                nudge-left="160"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                    >
                                        <img
                                            :src="optionsIcon"
                                            alt="Optionen"
                                            class="icon30 iconToWhite"
                                        >
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        @click="toggleModes('sickMode')"
                                    >
                                        <img
                                            id="sickModeButton"
                                            :src="abwesendIcon"
                                            alt="Abwesend"
                                            class="iconToDunkelgrau icon30"
                                            style="cursor: pointer; margin-left: -10px"
                                        >
                                        <v-list-item-title class="ml-2">
                                            {{ sickMode ? 'Abwesend melden beenden' :'Abwesend melden' }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        :disabled="!canEdit"
                                        @click="toggleModes('editMode')"
                                    >
                                        <img
                                            id="editModeButton"
                                            :src="stundenplanBearbeitenIcon"
                                            alt="Bearbeiten"
                                            class="iconToDunkelgrau icon30"
                                            style="cursor: pointer; margin-left: -10px"
                                            :style="!editMode ? 'opacity: 0.4' : '' "
                                        >
                                        <v-list-item-title class="ml-2">
                                            {{ editMode ? 'Bearbeiten verlassen' : 'Stunden bearbeiten' }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="toggleModes('filterMode')"
                                    >
                                        <img
                                            :src="magnifierIcon"
                                            alt="Lehrer filtern"
                                            class="iconToDunklegrau square25 mr-2"
                                            :style="!filterMode ? 'opacity: 0.4' : ''"
                                            style="margin-left: -5px;"
                                        >
                                        <v-list-item-title>
                                            {{ !filterMode ? 'Lehrer suchen':'Lehrer suchen beenden' }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="clickCloseButton"
                                    >
                                        <img
                                            :src="biggerIcon"
                                            class="icon20 iconToDunkelgrau mr-2"
                                            alt="Stundenplan Wochenansicht im Vollbild"
                                            style="margin-left: -5px"
                                        >
                                        <v-list-item-title>
                                            Tagesansicht
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div
                            v-if="accountRole !== 'pupil'"
                            class="mr-4 responsiveSplitter"
                            style="height: 40px; background-color: white; width: 2px; opacity: 0.8"
                        />

                        <div
                            v-if="accountRole === 'teacher' || accountRole === 'maintainer' ? windowWidth > 600 : true"
                            v-ripple
                            class="d-flex align-center mr-1 p-2 headerButton"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <img
                                        :src="schliessenIcon"
                                        alt="Wochenansicht schließen"
                                        class="iconToWhite icon30 ml-2"
                                        style="cursor: pointer"
                                        v-on="on"
                                        @click="clickCloseButton"
                                    >
                                </template>
                                <span>Wochenansicht schließen</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <!--#endregion -->
                <WeeklyView
                    v-if="!substitutePlan"
                    :appointments="appointmentsByGroupOrRoom"
                    :edit-mode="accountRole === 'maintainer' ? true : editMode"
                    :can-edit-timeslot="person.canEditTimeslot"
                    :room-mode="roomMode"
                    :person="person"
                    :current-group="currentGroup"
                    :current-room-model="currentRoomModel"
                    :date="date"
                    :subjects="subjects"
                    :slots="slots"
                    :teachers="teachers"
                    :icons="iconUrls"
                    :show-loading-weekly-view="showLoadingWeeklyView"
                    :sick-appointments="sickAppointments"
                    :remove-sick-appointments="removeSickAppointments"
                    :request-appointments="requestAppointments"
                    :request-slots="requestSlots"
                    :get-work-days-of-week="getWorkDaysOfWeek"
                    :dates-on-same-day="datesOnSameDay"
                    :mark-or-unmark-appointment-as-sick="markOrUnmarkAppointmentAsSick"
                    :mark-appointment-to-remove-from-sick-list="markAppointmentToRemoveFromSickList"
                    :sick-mode="sickMode"
                    :close-copy="!copyMode"
                    :filter-mode="filterMode"
                    @changeDate="(string)=>{changeDatePickerDate(string)}"
                    @stopCopyMode="hideCopyMode"
                    @startCopyMode="showCopyMode"
                    @createTeacher="createTeacher()"
                    @setDate="setDate"
                    @fachClicked="(appointment) => { toggleWeeklyViewWidget(); setLessonFromAppointment(appointment) }"
                />
                <div
                    v-else-if="substitutePlan"
                    :settings="settingsVertretungsplan"
                    data-simplebar
                    style="max-height: 80vh; height: auto;"
                >
                    <Vertretungsplan
                        ref="substitutionPlan"
                        :all-appointments="vuexAppointments"
                        :edit-mode="accountRole === 'maintainer' ? true : editMode"
                        :current-group="currentGroup"
                        :date="date"
                        :subjects="subjects"
                        :slots="slots"
                        :teachers="teachers"
                        :groups="groups"
                        :icons="iconUrls"
                        :show-loading-weekly-view="showLoadingWeeklyView"
                        :request-appointments="requestAppointments"
                        :request-slots="requestSlots"
                        :get-work-days-of-week="getWorkDaysOfWeek"
                        :dates-on-same-day="datesOnSameDay"
                        :substitute-plan="substitutePlan"
                        :person="person"
                        :sick-appointments="sickAppointments"
                        :remove-sick-appointments="removeSickAppointments"
                        :mark-or-unmark-appointment-as-sick="markOrUnmarkAppointmentAsSick"
                        :mark-appointment-to-remove-from-sick-list="markAppointmentToRemoveFromSickList"
                        style="margin-bottom: 15px"
                        @changeDate="(string)=>{changeDatePickerDate(string)}"
                        @setDate="setDate"
                        @fachClicked="(appointment) => { toggleWeeklyViewWidget(); setLessonFromAppointment(appointment) }"
                    />
                </div>
            </div>
        </div>
        <BadgeExplanations
            v-if="windowWidth < 900 && !weeklyView"
            :small="true"
        />
        <AreYouSurePopup
            v-if="areYouSurePopup"
            :show-dialog="areYouSurePopup"
            :content-text-prop="areYouSureText"
            :ok-button-text="areYouSureOkButtonText"
            :cancel-button-text="areYouSureOkCancelButtonText"
            :enable-checkbox="true"
            :checkbox-text="'Auch LehrerInnen informieren'"
            :checkbox-info-text="checkboxInfoText"
            :second-checkbox="true"
            :second-checkbox-text="'Schüler und Eltern informieren'"
            :second-checkbox-info-text="secondCheckboxInfoText"
            :header-color="'#6995cd'"
            :usage="'planWidget'"
            :ok-button-icon="null"
            :cancel-button-icon="null"
            @okTwoCheckboxes="(sendNotificationToTeachers, sendNotificationPupilAndParents) => { handleEditAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents)}"
            @okCheckboxTrue="() => { sendNotificationToTeachers = true; handleEditAppointments(); }"
            @okCheckboxFalse="() => { sendNotificationToTeachers = false; handleEditAppointments(); }"
            @close="closeAreYouSurePopup"
        />
        <AreYouSurePopup
            v-if="areYouSurePopupMaintainer"
            :show-dialog="areYouSurePopupMaintainer"
            :content-text-prop="areYouSureTextConfirmMaintainer"
            :ok-button-text="areYouSureOkButtonText"
            :cancel-button-text="areYouSureOkCancelButtonText"
            :enable-checkbox="true"
            :checkbox-text="'Auch LehrerInnen informieren'"
            :checkbox-info-text="checkboxInfoText"
            :second-checkbox="true"
            :second-checkbox-text="'Schüler und Eltern informieren'"
            :second-checkbox-info-text="secondCheckboxInfoText"
            :header-color="'#6995cd'"
            :usage="'planWidgetMaintainer'"
            :ok-button-icon="null"
            :cancel-button-icon="null"
            @okTwoCheckboxes="(sendNotificationToTeachers, sendNotificationPupilAndParents) => { handleEditAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents)}"
            @okCheckboxTrue="() => { sendNotificationToTeachers = true; handleEditAppointments(); }"
            @okCheckboxFalse="() => { sendNotificationToTeachers = false; handleEditAppointments(); }"
            @close="closeAreYouSurePopupMaintainer"
        />
        <v-dialog
            v-if="editRoomsView"
            v-model="editRoomsView"
            overlay-opacity="0.8"
            width="unset"
            @click:outside="editRoomsView = false"
        >
            <ManageRooms
                :header-color="'var(--v-timetableColor-base)'"
                @stopEvent="editRoomsView = false"
            />
        </v-dialog>
        <v-dialog
            v-if="editSubjectView"
            v-model="editSubjectView"
            overlay-opacity="0.8"
            width="unset"
            @click:outside="editSubjectView = false"
        >
            <ManageSubjects
                :header-color="'var(--v-timetableColor-base)'"
                @stopEvent="editSubjectView = false"
            />
        </v-dialog>
    </div>
</template>

<script>

import fullscreenIcon from '@/assets/Icons/redesignV3/fullscreen-white.svg'
import linksIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg'
import zurueckIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg'
import rechtsIcon from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg'
import schliessenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import calendarIcon from '@/assets/Icons/FaceliftIcons/kalender_datum_zeit.svg'
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import stundenplanBearbeitenIcon from '@/assets/Icons/FaceliftIcons/stundenplan_bearbeiten.svg'
import fachIcon from "@/assets/Icons/FaceliftIcons/fach.svg";
import raumIcon from "@/assets/Icons/FaceliftIcons/raum.svg";
import vertretungsIcon from "@/assets/Icons/FaceliftIcons/vertretung_lehrer_wechsel.svg";
import vertretungsIconDunkel from "@/assets/Icons/FaceliftIcons/vertretung_lehrer_wechsel_dunkelgrau.svg";
import stundenplanIcon from '@/assets/Icons/timetable.svg'
import biggerIcon from "@/assets/Icons/FaceliftIcons/vergroessern_minimal.svg";
import abwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer_abwesend_melden.svg";
import abwesendIconGrau from "@/assets/Icons/FaceliftIcons/lehrer_abwesend_melden_grau.svg";
import keineStundenIcon from "@/assets/Icons/FaceliftIcons/keine_stunden.svg";
import bestaetigenIcon from "@/assets/Icons/FaceliftIcons/speichern_bestaetigen.svg";
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import exportIcon from '@/assets/Icons/drucken-filled.svg';
import bioIconWeiss from "@/assets/Icons/FaceliftIcons/f-biologie-weiss.svg";
import chemieIconWeiss from "@/assets/Icons/FaceliftIcons/f-chemie-weiss.svg";
import deutschIconWeiss from "@/assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import englischIconWeiss from "@/assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import erdkundeIconWeiss from "@/assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg";
import geschichteIconWeiss from "@/assets/Icons/FaceliftIcons/f-geschichte-weiss.svg";
import matheIconWeiss from "@/assets/Icons/FaceliftIcons/f-mathe-weiss.svg";
import musikIconWeiss from "@/assets/Icons/FaceliftIcons/f-musik-weiss.svg";
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import physikIconWeiss from "@/assets/Icons/FaceliftIcons/f-physik-weiss.svg";
import politikIconWeiss from "@/assets/Icons/FaceliftIcons/f-politik-weiss.svg";
import religionIconWeiss from "@/assets/Icons/FaceliftIcons/f-religion-weiss.svg";
import sportIconWeiss from "@/assets/Icons/FaceliftIcons/f-sport-weiss.svg";
import wirtschaftIconWeiss from "@/assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg";
import loadingIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import magnifierIcon from '@/assets/Icons/redesignV3/magnifier.svg';


import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {parseHtml} from "@/util/filePreviewUtils";
import sanitizeHtml from '../../../../util/sanitizeHtml'
import debounce from 'lodash.debounce';

import Fach from "./Fach"
import WeeklyView from "@/components/SharedViews/Stundenplan/WeeklyView";
import Vertretungsplan from "@/components/SharedViews/Vertretungsplan/Vertretungsplan";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "@/api/backend";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

import ManageSubjects from "@/components/Verwaltung/ManageSubjects.vue"
import ManageRooms from "@/components/Verwaltung/ManageRooms.vue"
import EventBus from "../../../../util/eventBus";
import exports from "@/util/splanExport";
import BadgeExplanations from "@/components/Utils/BadgeExplanations.vue";


export default {
    name: "PlanWidget",
    components: {
        AreYouSurePopup,
        Fach,
        WeeklyView,
        ClickableBadge,
        vueCustomScrollbar,
        Vertretungsplan,
        ManageSubjects,
        ManageRooms,
        BadgeExplanations
    },
    props: {
        small: {required: false, default: false},
        // Current child selected in header by parent
        currentChild: {required: false},
        reset: {reuquired: false, type: Boolean},
    },
    data: () => ({
        // This component holds all meta info for itself and most of its child components, please don't break the
        // parent-child relationship by requesting stuff in child components.
        autocompleteChanged: false, 
        fileToUpload: '',
        timeslotToFill: null,

        dataLoading: true,

        forceRefreshComputed: 0,

        substitutePlan: false,

        callFileUpload: false,

        // Drag and drop functionality stuff
        dropUiActive: false,

        sickAppointments: [],
        removeSickAppointments: [],

        //slots: [],
        date: new Date(),    // is being changed when date in calendar is changed
        today: new Date(),   // don't change! reference to highlight current day
        datePickerString: new Date().toISOString(),

        // Stuff necessary for displaying the correct widget view
        widgetView: 'day',
        saturdayOrSunday: false,
        editSubjectView: false,
        editRoomsView: false,
        emptySlots: [],
        // weekly view
        futureDates: [],
        germanDayStrings: ["Sontag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        weekViewScrollEvent: null,
        showLoadingWeeklyView: false,

        forceUpdate: 0,

        sanitizeHtml,
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },

        // Badges counter
        futureWeekBadges: 0,
        pastWeekBadges: 0,

        // Edit mode
        // editMode: false,
        currentGroupModel: null,

        // Filter Mode
        filterMode: false, 
        currentFilteredTeacher: null, 

        // Room mode
        roomMode: false,
        allRooms: [],
        currentRoomModel: null,

        areYouSurePopup: false,
        areYouSureText: '',
        areYouSureTextConfirm: `Möchten Sie sich wirklich abwesend melden? Die Verwaltung wird hierüber informiert.
         Sie erscheinen bei Schülern und Eltern im Stundenplan als abwesend.`,
        areYouSureTextClose: 'Sie haben nicht bestätigte Abwesenheitsmeldungen, bitte bestätigen oder verwerfen ' +
            'Sie diese.',
        areYouSureOkButtonText: 'Bestätigen',
        areYouSureOkCancelButtonText: 'Verwerfen',

        sendNotificationToTeachers: false,
        areYouSurePopupMaintainer: false,
        areYouSureTextConfirmMaintainer: `Möchten Sie die ausgewählte Lehrkraft wirklich für die angegebenen Stunden abwesend melden? .
                                            Sie erscheinen bei Schülern und Eltern im Stundenplan als abwesend.`,

        checkboxInfoText: 'LehrerInnen haben die Option sich für eine Vertretung zu melden.',
        secondCheckboxInfoText: 'Schüler und Eltern erhalten eine gesonderte Abwesenheitsmeldung im Chat.',
        toggleTodayBtn: false,

        // Current user
        person: {
            accessibility: {
                screenreader: false,
            }
        },
        babyView: false,
        dayBabyView: false,

        // Vertretungsplan
        //groupsWithAlle: [],
        settingsVertretungsplan: {
            suppressScrollY: true,
            suppressScrollX: false,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
        pastWeekOpenSubstitutions: 0,
        futureWeekOpenSubstitutions: 0,
        currentWeekOpenSubstitutions: 0,
        totalSubstitutions: 0,

        // Icons
        optionsIcon,
        linksIcon,
        rechtsIcon,
        schliessenIcon,
        fullscreenIcon,
        calendarIcon,
        bearbeitenIcon,
        stundenplanBearbeitenIcon,
        zurueckIcon,
        infoIcon,
        // eslint-disable-next-line vue/no-unused-properties
        loadingIcon,
        exportIcon,
        fachIcon,
        raumIcon,
        vertretungsIcon,
        vertretungsIconDunkel,
        stundenplanIcon,
        biggerIcon,
        abwesendIcon,
        keineStundenIcon,
        bestaetigenIcon,
        abwesendIconGrau,
        magnifierIcon, 

        iconUrls: [],

        copyMode: false,

        // These icons are actually used by getWhiteIconSrc
        // eslint-disable-next-line vue/no-unused-properties
        bioIconWeiss, chemieIconWeiss, deutschIconWeiss, englischIconWeiss, erdkundeIconWeiss, geschichteIconWeiss, matheIconWeiss, musikIconWeiss, kunstIconWeiss, physikIconWeiss, politikIconWeiss, religionIconWeiss, sportIconWeiss, wirtschaftIconWeiss
    }),
    computed: {
        ...mapState('weeklyView', ["weeklyView", "editMode", 'sickMode']),
        ...mapGetters('weeklyView', { 'vuexEditMode': 'editMode' }),
        ...mapState('util', ['serverTimeOffset', 'windowWidth', 'dontOpenAppointment']),
        ...mapGetters("auth", ["accountId", "accountRole"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("appointments",["appointmentsById", 'appointmentsByWeek', 'planBadgeEventList', 'appointmentsByRooms','appointmentsByTeacherId','appointmentsLoading']),
        ...mapGetters('appointments', { 'singleAppointment':'appointment', 'vuexAppointments': 'appointments' }),
        ...mapGetters('groups', ['groupById', 'groupsById', 'groupsByLeader']),
        ...mapGetters('groups', { 'vuexGroups': 'groups' }),
        ...mapGetters('pupils', ['pupilsByAccountId', 'pupilsById']),
        ...mapGetters('pupils', { 'vuexPupils':'pupils' }),
        ...mapGetters('parents', ['parentsByAccountId', 'parentsById', 'parentsByPupilId']),
        ...mapGetters('parents', { 'vuexParents': 'parents' }),
        ...mapGetters('teachers', ['teachersById', 'teachersByAccountId']),
        ...mapGetters('teachers', {'vuexTeachers': 'teachers'}),
        ...mapGetters('maintainers', { 'vuexMaintainers': 'maintainers' }),
        ...mapGetters('maintainers', ['hideBadges']),
        ...mapGetters('timeslots', ['timeslotsById', 'timeslotsByGroupId', 'timeslotsForMultipleGroupIds']),
        ...mapGetters('timeslots', { 'vuexTimeslots': 'timeslots' }),
        ...mapGetters('subjects', { 'vuexSubjects': 'subjects' }),

        pupilOrParent(){
            return this.accountRole === 'pupil' || this.accountRole === 'parent';
        },
        teachers() {
            return this.vuexTeachers;
        },

        groups() {
            return this.vuexGroups.filter(group => group.name !== 'Alle');
        },
        groupsWithAlle() {
            return this.vuexGroups;
        },
        appointmentsByGroupOrRoom(){
            if(this.roomMode){
                if (!this.currentRoomModel || this.appointmentsByRooms[this.currentRoomModel._id] === undefined) {
                    return [];
                }else{
                    return this.appointmentsByRooms[this.currentRoomModel._id] 
                }
            }else if(this.filterMode){
                if (!this.currentFilteredTeacher || this.appointmentsByTeacherId[this.currentFilteredTeacher._id] === undefined) {
                    return [];
                }else{
                    return this.appointmentsByTeacherId[this.currentFilteredTeacher._id] 
                }
            }
            else{
               return this.appointments
            }
        },
        slots() {
            let resultingSlots = [];
            // generate slots of the current group for maintainers and weeklyView
            const slotsOfCurrentGroup = () => {
                const slots = this.currentGroup ? this.timeslotsByGroupId[this.currentGroup._id ] : this.timeslotsByGroupId[this.groups[0]._id];
                return slots?.map(slot => {
                    slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2);
                    slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2);
                    return slot;
                });
            };
            // combines timeslots with appointment days
            const timeslotsCombinedWithDays = () => {
                const pauses = this.vuexTimeslots.filter(app => app.category === 'break')
                let existing={};
                // filters pauses for duplicates
                const filteredPauses = pauses.filter(item =>{
                    const valueCheck = item.start.getHours() + item.end.getHours();
                    if (!existing[valueCheck]) {
                        existing[valueCheck] = true;
                        return true;
                    }
                    return false
                    }

                );
                const result = this.appointmentsByGroupOrRoom.reduce((previous, app) => {
                    const slotId = (app.timeslot._id || app.timeslot);
                    const correctDay = previous.find(entry => entry.day.slice(0, 10) === app.day.slice(0, 10));
                    if (correctDay) {
                        if (!correctDay.timeslots.find(slot => slot._id === slotId)) {
                            correctDay.timeslots.push(this.timeslotsById[slotId]);
                        }
                    } else {
                        previous.push({
                            day: app.day,
                            timeslots: [this.timeslotsById[slotId]].concat(filteredPauses),
                        });
                    }
                    return previous;
                }, []);
                result.forEach(day => {
                    day.timeslots
                        .sort((a, b) => (a.start.getHours() * 60 + a.start.getMinutes())
                            - (b.start.getHours() * 60 + b.start.getMinutes()));
                });
                return result;
            };

            if (this.accountRole === 'teacher') {
                // Todo add or check for flag for teacher Edit Mode
                // edit is true if weekylView,
                if (this.vuexEditMode) {
                    resultingSlots = slotsOfCurrentGroup();
                } else {
                    resultingSlots = timeslotsCombinedWithDays();
                }

            } else if (this.accountRole === 'maintainer') {
                const slotsOfGroup = slotsOfCurrentGroup();
                if(slotsOfGroup){

                    resultingSlots = slotsOfGroup.sort((a, b) => {
                    // Extract the hours and minutes from the start dates
                    const startHourA = a.start.getHours();
                    const startMinuteA = a.start.getMinutes();
                    const startHourB = b.start.getHours();
                    const startMinuteB = b.start.getMinutes();

                    // Compare the start hours
                    if (startHourA < startHourB) {
                        return -1;
                    }
                    if (startHourA > startHourB) {
                        return 1;
                    }

                    // If the start hours are the same, compare the start minutes
                    if (startMinuteA < startMinuteB) {
                        return -1;
                    }
                    if (startMinuteA > startMinuteB) {
                        return 1;
                    }

                    // If the start hours and minutes are the same, extract the end hours and minutes
                    const endHourA = a.end.getHours();
                    const endMinuteA = a.end.getMinutes();
                    const endHourB = b.end.getHours();
                    const endMinuteB = b.end.getMinutes();

                    // Compare the end hours
                    if (endHourA < endHourB) {
                        return -1;
                    }
                    if (endHourA > endHourB) {
                        return 1;
                    }

                    // If the end hours are the same, compare the end minutes
                    if (endMinuteA < endMinuteB) {
                        return -1;
                    }
                    if (endMinuteA > endMinuteB) {
                        return 1;
                    }

                    // If both start and end hours and minutes are the same, maintain the original order
                    return 0;
                    });
                }
            } else {
                // pupil and parent: get timeslots for groups
                const participantToSearchFor = this.accountRole === 'parent' ? this.currentChild._id : this.person._id;
                const groups = this.groups.filter((group) => {
                    return group.participants.some((participant) =>  participant._id === participantToSearchFor)
                })
                const allSlots = this.timeslotsForMultipleGroupIds(groups.map((group) => group._id));
                 const uniqueObjectsMap = new Map();
                allSlots.forEach(obj => uniqueObjectsMap.set(obj._id, obj));
                resultingSlots = Array.from(uniqueObjectsMap.values());
            }
            // todo sort for starting time
            //resultingSlots.forEach()
            
            return resultingSlots;
        },

        currentSlot() {
            let result = null;
            // needed for creating new appointments in day-view - Jan
            if (this.timeslotToFill &&
                this.widgetView === 'lesson' &&
                this.accountRole === 'maintainer') {
                result = { ...this.timeslotToFill };
            } else if (!this.$route.query.aId) {
                if (this.accountRole === 'teacher') {
                    const temp = this.slots.find((slot) => this.datesOnSameDay(new Date(slot.day), this.date || this.today));
                    const timeslotsToday = temp && temp.timeslots ? temp.timeslots : null;
                    if (timeslotsToday) {
                        result = timeslotsToday.find((slot) => this.isTimeslotCurrentlyRunningToday(slot, this.date));
                    }
                } else {
                    result = this.slots.find((slot) => this.isTimeslotCurrentlyRunningToday(slot, this.date));
                }
                // check for outside of slot range
                if (!result) {
                    if (this.date < new Date(this.slots[0].start)) {
                        result = this.slots.find((slot) => slot._id === (this.appointmentsToday[0] || {}).timeslot);
                        // Otherwise, check if the time is after the last timeslot (end)
                    } else if (this.date > new Date(this.slots[this.slots.length - 1].end)) {
                        result = this.slots.find((slot) => {
                            return slot._id === (this.appointmentsToday[this.appointmentsToday.length - 1] || {}).timeslot;
                        });
                    }
                }
            } else {
                // find depending on currentAppointment
                if(this.accountRole === 'teacher'){
                    const temp = this.slots.find((slot) => this.datesOnSameDay(new Date(slot.day), this.date || this.today));
                    result = temp.timeslots.find((slot) => slot._id === this.currentAppointment.timeslot._id);
                }else {
                    result = this.slots.find((slot) => slot._id === this.currentAppointment.timeslot);
                }

            }
            return result;
        },

        currentAppointment() {
            let result = null;
            if (!this.$route.query.aId) {
                result = (this.appointmentsTodayByTimeslot[this.currentSlot._id] || {})[0];
            } else {
                // Check query for open details
                const currentQuery = this.$route.query;
                if (currentQuery.aId && !this.dontOpenAppointment) {
                    const appointmentsToSearch = this.appointmentsByGroupOrRoom;
                    result = appointmentsToSearch.find(appointment => appointment._id === currentQuery.aId);
                    if (result) {
                        this.setDate(new Date(result.day), 'lesson', result);
                    }
                } else {
                    this.setDontOpenAppointment(false);
                }
            }
            // edge case for maintainers and empty appointments
            if (!this.vuexEditMode && !result && this.accountRole === 'maintainer') {
                result = 'empty'
            }
            return result;
        },

        appointmentsToday() {
            return this.appointmentsByGroupOrRoom
                .filter((el) => this.datesOnSameDay(new Date(el.day), this.date));
        },

        /**
         * Same as in store, takes appointments and puts them in a JSON referencable by ID
         * Insetad of always calling a loop, this can hopefully reduce render load a bit
         * @returns today's appointments in JSON sorted by timeslot IDs
         */
        appointmentsTodayByTimeslot() {
            return this.appointmentsToday.reduce((acc, el) => {
                if (!acc[`${el.timeslot._id || el.timeslot}`]) {
                    acc[`${el.timeslot._id || el.timeslot}`] = [];
                }
                acc[`${el.timeslot._id || el.timeslot}`].push(el);
                return acc;
            }, {});
        },

        noAppointmentsToday() {
            return this.appointmentsToday.length === 0 && this.slots.length;
        },

        subjects() {
            return this.vuexSubjects;
        },

        appointments() {
            let result = [];

            if (this.accountRole === 'teacher') {
                this.vuexAppointments.forEach(appointment => {
                    // populate the groups in appointment
                    const appointmentGroup = this.groups.find((group) => group._id === appointment.group);
                    result.push({
                        ...appointment,
                        group: appointmentGroup,
                    });
                });
                // If the teacher is in editMode filter the appointments array for the currently selected group,
                // else filter the appointments array, so it only contains appointments where this teacher is in the
                // teachers array of the appointment
                result = result.filter(appointment => {
                    if (this.vuexEditMode && this.weeklyView) {
                        return appointment.group._id === this.currentGroup._id;
                    }
                    return appointment.teachers.some((teacher) => teacher._id === this.person._id);
                });
            } else if (this.accountRole === 'maintainer') {
                // Remove Uploads so no info is exposed and no paperclips/badges are visible for maintainer
                this.vuexAppointments.forEach(appointment => {
                    // push only currentGroup appointments if specified
                    if ((this.currentGroup && this.currentGroup._id === appointment.group) || !this.currentGroup) {
                        const app = {
                            ...appointment,
                            teacherUploads: [],
                            pupilUploads: [],
                        };
                        result.push(app);
                    }
                });
            } else {
                /** pupil and parent are else case, come good from backend*/
                result = this.vuexAppointments;
            }

            return result;
        },

        substitutionAppointments() {
            return this.vuexAppointments.filter(item => {
                if ((this.currentGroup && this.currentGroup._id === item.group) || !this.currentGroup) {
                    return item.substitutions && item.substitutions.length && !item.substitutions[0].substitutionTeacher;
                }
                return false;
            });
        },

        substituteAppointmentList() {
            return this.substitutionAppointments.filter((appointment) => {
                const substitutions = appointment.substitutions || [];
                return substitutions.some((sub) => sub.seenBy.includes(this.accountId) ? false : sub.seenByMaintainer === false);
            })
            .map(item => {
                return {
                    appointment: item._id,
                    date: item.day
                };
            });
        },

        totalSubstitutionCount() {
            // Depends on seenBy-array and whether maintainer has taken any action
            return this.vuexAppointments.filter((appointment) => {
                const substitutions = appointment.substitutions || [];
                return substitutions.some((sub) => sub.seenBy.includes(this.accountId) ? false : sub.seenByMaintainer === false);
            });
        },

        teacherSlotsAtDate() {
            if (this.accountRole === 'teacher' && this.slots.length) {
                const slotsAtDate = this.slots.find((slot) => {
                    return new Date(slot.day).toLocaleDateString('de-DE', {
                        month: '2-digit',
                        day: '2-digit'
                    }).slice(0, 10) === this.date.toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit'});
                });
                if (slotsAtDate) {
                    const slots = slotsAtDate.timeslots;
                    return slots.sort((a, b) => {
                        // Extract the hours from the start dates
                        const startHourA = a.start.getHours();
                        const startHourB = b.start.getHours();

                        // Compare the start hours
                        if (startHourA < startHourB) {
                            return -1;
                        }
                        if (startHourA > startHourB) {
                            return 1;
                        }

                        // If the start hours are the same, compare the end hours
                        const endHourA = a.end.getHours();
                        const endHourB = b.end.getHours();
                        if (endHourA < endHourB) {
                            return -1;
                        }
                        if (endHourA > endHourB) {
                            return 1;
                        }

                        // If both start and end hours are the same, maintain the original order
                        return 0;
                        });
                }
            }
            return [];
        },

        currentlyRunningSlots() {
            // This relies on the correct sort order of timeslots
            let currentAndNextSlot = [];
            const now = new Date().getHours() * 60 + new Date().getMinutes();
            if(this.accountRole === 'pupil' && this.dayBabyView){
                for(let i = 0; i < this.slots.length; i++) {
                    const startTime = this.slots[i].start.getHours() * 60 + this.slots[i].start.getMinutes();
                    const endTime = this.slots[i].end.getHours() * 60 + this.slots[i].end.getMinutes();
                    if(startTime < now && endTime > now) {
                        currentAndNextSlot.push(this.slots[i]);
                        if(i < this.slots.length - 1) {
                            currentAndNextSlot.push(this.slots[i + 1]);
                        }
                        break;
                    }
                }
            }
            return currentAndNextSlot;
        },

        teacherGroups() {
            return this.groups.filter(group => group.leaders.includes(this.person._id));
        },

        currentGroup() {
            if (this.currentGroupModel && this.currentGroupModel._id) {
                return this.currentGroupModel;
            } else {
                // set default modes
                if (this.substitutePlan) {
                    return this.groups.find(el => el.name === 'Alle');
                }
                if (this.accountRole === 'teacher') {
                    return this.teacherGroups[0];
                }
            }
            return this.groups[0]
        },

        canEdit() {
            return this.accountRole === 'teacher' && this.groups.some((group) => group.leaders.some((leader) => leader === this.person._id));
        },

        badgeEventList() {
            return this.planBadgeEventList;
            // const temp = this.forceRefreshComputed;
            // const eventList = [];
            // this.appointments.forEach((el) => {
            //     if (this.accountRole === 'pupil' && !(el.noteSeenByMe || (el.noteSeenBy && el.noteSeenBy.find((accountId) => accountId === this.accountId)))) {
            //         eventList.push({date: el.day, appointment: el._id, upload: null});
            //     }
            //     if (this.accountRole === 'pupil' && typeof el.teacherUploads === "object" && el.teacherUploads.length) {
            //         const filteredUploads = el.teacherUploads.filter((element) => !element.seenByMe);
            //         filteredUploads.forEach(upload => {
            //             eventList.push({date: el.day, appointment: el._id, upload});
            //         });
            //     }
            //     if (this.accountRole === 'teacher' && typeof el.pupilUpload === "object") {
            //         el.pupilUpload.filter((element) => !element.seenByMe)
            //             .forEach(upload => {
            //                 eventList.push({date: el.day, appointment: el._id, upload});
            //             });
            //     }
            // });
            // eventList.sort((a, b) => {
            //     return a.date > b.date ? -1 : 1;
            // });
            // return eventList;
        },
    },
    watch: {
        async currentChild(newVal) {
            // this.dataLoading = true;
            await this.getAppointmentsByWeek({ date: new Date(this.today), pupilId: newVal._id });
            await this.emptyBufferIntoState();
            this.dataLoading = false;

            await this.getNearestWeek();
            // this.appointments = await this.getAppointmentsParent(this.currentChild._id);
            //await this.requestSlots();
        },
        date: {
            handler(newVal, oldVal) {
                this.saturdayOrSunday = this.date.getDay() === 6 || this.date.getDay() === 0;
                this.datePickerString = this.date.toLocaleDateString('fr-CA');
                // if (this.babyView && newVal) {
                //     let emptySlots = [];
                //     if(this.currentlyRunningSlots.length){
                //         this.currentlyRunningSlots.forEach((slot) => {
                //         if(!(this.appointmentsTodayByTimeslot[(slot._id || slot)]
                //             || this.appointmentsTodayByTimeslot[(slot._id || slot)].length)){
                //             emptySlots.push(slot._id);
                //         }
                //         })
                //     }
                //     this.emptySlots = emptySlots;
                // }
            }, deep: true,
            immediate: true,
        },

        currentlyRunningSlots(newVal){
            if (this.accountRole === 'pupil' && this.dayBabyView && newVal) {
                let emptySlots = [];
                if(this.currentlyRunningSlots.length){
                    this.currentlyRunningSlots.forEach((slot) => {
                        if(!(this.appointmentsTodayByTimeslot[(slot._id || slot)]
                            || this.appointmentsTodayByTimeslot[(slot._id || slot)].length)){
                            emptySlots.push(slot._id);
                        }
                    })
                }

                this.emptySlots = emptySlots;
            }
        },

        '$route.query': {
            handler(query) {
                if(query.aId) {
                    const queryAppointment = this.appointmentsById[query.aId];
                    if(queryAppointment) {
                        this.setLessonFromAppointment(queryAppointment);
                    }
                } else {
                    this.widgetView = 'day';
                }
            },
            deep: true,
            immediate: true
        },
        substitutePlan(newVal){
            if(newVal === true){
                this.currentGroupModel = this.groupsWithAlle[0];
            }else{
                if(this.currentGroupModel.name === 'Alle'){
                    this.currentGroupModel = this.groups[0];
                }
            }
        },
        reset(newVal){
             if(newVal && newVal === true ){
                this.setWidgetViewThroughHeader('day', false, true);
                if(this.weeklyView){
                     this.closeWeeklyView();
                }
                this.leaveFilterMode();
                this.leaveEditMode();
                this.leaveSickMode();
                this.date = this.today;
                this.$emit('planRemounted');
             }
        },
        filterMode(newVal){
            if(newVal && newVal === true){
                if(this.accountRole === 'teacher'){
                    this.currentFilteredTeacher = this.teachers.find(teacher=> teacher._id === this.person._id);
                }
            }
        },
        currentFilteredTeacher(newVal){
            if(this.filterMode && newVal && newVal !== undefined && newVal !== null){
                this.getAppointmentsForTeacherByWeek({teacherId: newVal._id, date: new Date(this.today),})
            }
        }
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function () {
            let date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            let week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async mounted() {
        // These calls can be made in parallel for performance enhancement
        this.getSlots();
        this.requestPerson();
        this.date = await this.getDay();
        this.today = this.date;
        if (this.accountRole === "maintainer" || this.accountRole === "teacher") {
            this.getSubjects();
        }
        // console.log('mounted in Planwidget');
        // console.log('appointmentsToday', this.appointmentsToday);
        // console.log('currentSlot', this.currentSlot);
        // pull appointments
        // await this.requestAppointments(null, false, false);
        if(this.accountRole==='parent'){
            await this.getAppointmentsByWeek({ date: new Date(this.today), pupilId: this.currentChild._id });
            await this.emptyBufferIntoState();
        }else{
            await this.getAppointmentsByWeek({date: new Date(this.today)});
            await this.emptyBufferIntoState();
        }
        // await this.requestSlots();
        // It's not necessary to await next line, but it would cost some performance so pls don't
        this.requestFachIconUrls();
        setTimeout(function () {
            speechSynthesis.cancel();
        }, 500);   //if you spam through tutorial this should cancel speaking
        this.generateDates();
        // Debounce for scrolling in weekly view
        this.weekViewScrollEvent = debounce(() => {
            this.scrollingWeek();
        }, 25);
        this.allRooms = await this.getRooms();

        // subjects are requested for FachInfoMaintainer, so that it only needs to be requested once
        // if (this.accountRole === "maintainer" || this.accountRole === "teacher") {
        //     // It's not necessary to await next line, but it would cost some performance so pls don't
        //     this.subjects = await this.getSubjects();
        // }
        this.currentGroupModel = this.accountRole === 'teacher' ? this.teacherGroups[0] : (this.accountRole==='maintainer' && this.groups.length) ? this.groups[0] : null;
        this.initializeEventBus();
        this.dayBabyView = this.accountRole === 'pupil' && this.babyView ? true : false;
        this.dataLoading = false;

        // await this.getNearestWeek();
        // TODO handle badges for appointments
        // Badges werden auch beim ersten Load mitgeschickt (extra query im Backend, trigger per flag, wenn store leer ist ausführen)
        this.$emit('planMounted');
    },
    methods: {
        ...mapActions('weeklyView', ['toggleWeeklyView','toggleEditMode','leaveEditMode', 'toggleSickMode', 'leaveSickMode', 'closeWeeklyView']),
        ...mapActions('subjects', ['getSubjects']),
        ...mapActions("appointments", [
            "getAppointmentsByWeek",
            "getAppointment",
            "getAppointments",
            "getMultipleAppointments",
            "getAppointmentsParent",
            "setAppointmentNoteSeen",
            "patchAppointmentUploadSeenBy",
            'undoSubstitutionsInAppointments',
            'postSubstitutionsInAppointments',
            'updateDisplayTeacherInfo',
            'getAppointmentsByRoomAndWeek',
            'getAppointmentsForTeacherByWeek',
        ]),
        ...mapActions("timeslots", ["getSlots", "getSlotsByGroups"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("util", ["loadServerTime"]),
        ...mapActions("groups", ["getGroups"]),
        ...mapActions("teachers", ["getMeTeacher", "getTeachers", "getProfilePicture"]),
        ...mapActions("parents", ["getMeParent"]),
        ...mapActions('mimeTypes', ['validateMimeType']),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        ...mapActions('maintainers', ['getMeMaintainer']),
        ...mapActions('substitution', ['patchSubstitution']),
        ...mapActions('physicalRooms', ['getRooms']),

        ...mapMutations('subjects', ['setSubjects', 'pushSubject', 'patchSubject', 'deleteSubject']),
        ...mapMutations('timeslots', ['spliceTimeslot', 'addOrUpdateTimeslot']),
        ...mapMutations('badges', ['setTimetableBadge']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("util", ["setDontOpenAppointment"]),
        ...mapMutations("appointments", ["addOrUpdateAppointment", 'addOrUpdateAppointments', 'patchAppointment',
                "removeAppointment", 'setAppointmentBuffer','setAppointmentsByRoomsBuffer', 'setAppointmentsByTeachersBuffer',
                 'emptyBufferIntoState', 'emptyRoomsBufferIntoState','emptyTeachersBufferIntoState']),

        planIconClicked(){
            if(this.accountRole === 'maintainer' || this.accountRole === 'teacher'){
                if(this.weeklyView){
                    this.clickCloseButton()
                }else{
                    this.toggleWeeklyViewWidget()

                }
            }
            else{
                this.jumpToCurrentDay();
                this.changeViewBaby();
            }
        },
        changeDatePickerDate(string){
            this.datePickerString = string;
            this.changeDateThroughDatePicker();
        },
        leaveFilterMode(){
            this.currentFilteredTeacher = null;
            this.filterMode = false;
        },
        showCopyMode() {
            this.copyMode = true;
        },
        hideCopyMode(){
            this.copyMode = false;
        },
        async getNearestWeek() {
            let weekId =`${this.date.getWeek()}-${this.date.getFullYear()}` ;
            let filter = new Date(this.date);
            const weekday = this.date.getDay();

            if (weekday === 1) {
                weekId = `${this.date.getWeek()-1}-${this.date.getFullYear()}`;
                filter = {date: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-3) };

            } else if (weekday === 5) {
                weekId = `${this.date.getWeek()+1}-${this.date.getFullYear()}`;
                filter = {date: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+3) };
            }

            if (!this.appointmentsByWeek[weekId]) {
                if (this.accountRole === 'parent' && this.currentChild) {
                    filter.pupilId = this.currentChild._id;
                }
                if(this.roomMode){
                    const previousWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: filter.date})
                    this.setAppointmentsByRoomsBuffer(previousWeek)
                }else if(this.roomMode){
                    const previousWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: filter.date})
                    this.setAppointmentsByTeachersBuffer(previousWeek)
                }
                else{
                    const previousWeek = await this.getAppointmentsByWeek(filter);
                    await this.setAppointmentBuffer(previousWeek);
                }
            }
        },

        changeViewBaby(){
            if(this.accountRole === 'pupil'){
                this.dayBabyView = !this.dayBabyView;
            }
        },
        async requestTeacher() {
            this.teachers = await this.getTeachers();
            this.teachers = await Promise.all(this.teachers.map(async (teacher) => ({
                ...teacher,
                profilePicture: await this.requestProfilePictures(teacher),
            })));
        },

        async createTeacher(createdTeacher) {
            // this.subjects = await this.getSubjects(true);
        },
        async addDay() {
            const oldDay = this.date.getDay();
            do {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            } while (!(this.date .getDay() % 6));
            const newDay = this.date.getDay();
            if (oldDay === 4 && newDay === 5) {
                const filter = {date: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+4) };
                if (this.accountRole === 'parent' && this.currentChild) {
                    filter.pupilId = this.currentChild._id;
                }
                 if(this.roomMode){
                      const nextWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: filter.date})
                    await this.setAppointmentsByRoomsBuffer(nextWeek)
                 }else if(this.filterMode){
                    const nextWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: filter.date});
                                        await this.setAppointmentsByTeachersBuffer(nextWeek);
                 }
                 else{
                     const nextWeek = await this.getAppointmentsByWeek(filter);
                    await this.setAppointmentBuffer(nextWeek);
                 }
                // DONE: Todo if old day is thursday and new day friday, preload next weeks appointments into buffer
                // Todo BUT ONLY IF new week is not already loaded (sent weekId list in state)

            } else if (oldDay === 5 && newDay === 1) {
                 this.roomMode ? await this.emptyRoomsBufferIntoState() : 
                    this.filterMode ?  await this.emptyTeachersBufferIntoState() 
                        : await this.emptyBufferIntoState();
                // DONE: Todo if old day is friday and new day monday, add buffer to appointments

            }
        },
        async subDay() {
            const oldDay = this.date.getDay();
            do {
                this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-1);
            } while (!(this.date .getDay() % 6))
            const newDay = this.date.getDay();
            if (oldDay === 2 && newDay === 1) {
                const filter = {date: new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-4) };
                if (this.accountRole === 'parent' && this.currentChild) {
                    filter.pupilId = this.currentChild._id;
                }
               if(this.roomMode){
                      const nextWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: filter.date})
                    await this.setAppointmentsByRoomsBuffer(nextWeek)
                 }else if(this.filterMode){
                    const nextWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: filter.date});
                                        await this.setAppointmentsByTeachersBuffer(nextWeek);
                 }
                 else{
                     const nextWeek = await this.getAppointmentsByWeek(filter);
                    await this.setAppointmentBuffer(nextWeek);
                 }
                // Todo BUT ONLY IF new week is not already loaded (sent weekId list in state)

            } else if (oldDay === 1 && newDay === 5) {
                this.roomMode ? await this.emptyRoomsBufferIntoState() : 
                    this.filterMode ?  await this.emptyTeachersBufferIntoState() 
                        : await this.emptyBufferIntoState();
                // Todo if old day is monday and new day friday, add buffer to appointments

            }
        },
        // async addNewTeacher(teacher) {
        //     // this.teachers.push(teacher);
        //     // this.subjects = await this.getSubjects(true);
        // },
        // addNewSubject(subject) {
        //     this.subjects.push(subject);
        // },
        clickCloseButton() {
            if (this.sickAppointments.length || this.removeSickAppointments.length) {
                this.areYouSureText = this.areYouSureTextClose;
                this.areYouSurePopup = true;
                return;
            }
            if (this.vuexEditMode || this.substitutePlan) {
                // Todo teachers array of the appointment
                // if(this.accountRole === 'teacher'){
                //     this.appointments = this.allAppointments.filter((el) => {
                //         return el.teachers.some((teacher) => teacher._id === this.person._id);
                //     });
                // }
                this.currentGroupModel = this.groups[0];
            }
            this.closeWeeklyView();
            // this.vuexEditMode = false;
            this.leaveEditMode();
            this.leaveSickMode();
            this.substitutePlan = false;
            this.roomMode = false;
            this.filterMode = false; 
            // this.requestSlots(false);
        },

        closeAreYouSurePopup(cancel) {
            if (cancel) {
                this.sickAppointments = [];
                this.removeSickAppointments = [];
                this.areYouSurePopup = false;
                this.toggleModes('sickMode');
            } else {
                this.areYouSurePopup = false;
            }
            this.areYouSurePopup = false;
        },
        closeAreYouSurePopupMaintainer() {
            this.areYouSurePopupMaintainer = false;
        },
        async handleEditAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents){
            if(this.sickAppointments.length){
                await this.postSickAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents);
            }

            if(this.removeSickAppointments.length){
                await this.undoSickAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents);
            }
        },
        async undoSickAppointments() {
            try {
                await this.undoSubstitutionsInAppointments({appointmentIds: this.removeSickAppointments, teacherId: this.person._id});
                this.removeSickAppointments = [];
                this.areYouSurePopup = false;
                this.areYouSurePopupMaintainer = false;
                this.showSnackbar({
                    message: 'Die Abwesenheiten wurde erfolgreich bearbeitet!',
                    color: 'success'
                });
            } catch (err) {
                this.showSnackbar({
                    message: 'Bei dem Bearbeiten der Abwesenheit ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
                console.error(err);
            }
        },

        async postSickAppointments(sendNotificationToTeachers, sendNotificationPupilAndParents) {
            try {
                await this.postSubstitutionsInAppointments({
                    appointmentIds: this.sickAppointments, notify:sendNotificationToTeachers,
                     notifyPupilAndParents:sendNotificationPupilAndParents ,currentTeacher: null});
                this.sickAppointments = [];
                this.areYouSurePopupMaintainer = false;
                this.areYouSurePopup = false;
                this.showSnackbar({
                    message: 'Die Abwesenheiten wurde erfolgreich eingetragen!',
                    color: 'success'
                });
            } catch (err) {
                this.showSnackbar({
                    message: 'Bei dem Eintragen der Abwesenheit ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
                console.error(err);
            }
        },

        // TOdo maybe refactor, depends on functionality
        markOrUnmarkAppointmentAsSick(appointment) {
            if (Array.isArray(appointment)) {
                const unmarkedAppointments = appointment.filter((appointmentId) => !this.sickAppointments.includes(appointmentId));
                if (unmarkedAppointments.length === appointment.length || unmarkedAppointments.length !== 0) {
                    this.sickAppointments = this.sickAppointments.concat(unmarkedAppointments);
                } else {
                    appointment.forEach((appointment) => {
                        const index = this.sickAppointments.indexOf(appointment);
                        if (index > -1) { // only splice array when item is found
                            this.sickAppointments.splice(index, 1);
                        }
                    })
                }
            } else {
                const mark = !this.sickAppointments.some((el) => appointment === el);
                if (mark) {
                    this.sickAppointments.push(appointment);
                } else {
                    const index = this.sickAppointments.indexOf(appointment);
                    if (index > -1) { // only splice array when item is found
                        this.sickAppointments.splice(index, 1);
                    }
                }
            }
        },

        markAppointmentToRemoveFromSickList(appointment){
            if (Array.isArray(appointment)) {
                const unmarkedAppointments = appointment.filter((appointmentId) => !this.removeSickAppointments.includes(appointmentId));
                if (unmarkedAppointments.length === appointment.length || unmarkedAppointments.length !== 0) {
                    this.removeSickAppointments = this.removeSickAppointments.concat(unmarkedAppointments);
                } else {
                    appointment.forEach((appointment) => {
                        const index = this.removeSickAppointments.indexOf(appointment);
                        if (index > -1) { // only splice array when item is found
                            this.removeSickAppointments.splice(index, 1);
                        }
                    })
                }
            } else {
                const mark = !this.removeSickAppointments.some((el) => appointment === el);
                if (mark) {
                    this.removeSickAppointments.push(appointment);
                } else {
                    const index = this.removeSickAppointments.indexOf(appointment);
                    if (index > -1) { // only splice array when item is found
                        this.removeSickAppointments.splice(index, 1);
                    }
                }
            }
        },

        async toggleModes(mode) {
            if (mode === 'editMode') {
                if (this.sickAppointments.length || this.removeSickAppointments.length) {
                    this.areYouSureText = this.areYouSureTextClose;
                    this.areYouSurePopup = true;
                    return;
                }

                if (!this.vuexEditMode) {
                    this.showSnackbar({
                        message: 'Hier sehen sie auch Fächer, die andere Lehrerinnen und Lehrer in dieser Klasse unterrichten.',
                        color: '#6995cd',
                    })
                }

                //Add await to requestSlots if weird behaviour happens
                // Todo check this: await this.requestSlots(!this.vuexEditMode);
                this.toggleEditMode();
                // Switch other modes
                this.roomMode = false;
                this.filterMode = false;
                this.currentFilteredTeacher = null;
                this.leaveSickMode();
                this.substitutePlan = false;
                this.showSnackbar({
                    message: 'Stundenplan bearbeiten: Klicken Sie auf „Hinzufügen“ um dann Fächer und Lehrer anzulegen.'
                });
            } else if (mode === 'sickMode') {
                if (this.sickAppointments.length || this.removeSickAppointments.length) {
                    this.areYouSureText = this.areYouSureTextConfirm;
                    this.areYouSurePopup = true;
                    return;
                }
                if(!this.weeklyView){
                    this.toggleWeeklyView();
                }
                this.toggleSickMode();
                this.roomMode = false;
                this.filterMode = false;
                this.currentFilteredTeacher = null;

                // this.vuexEditMode = false;
                this.leaveEditMode();
                this.substitutePlan = false;
                this.showSnackbar({
                    message: 'Abwesend melden: Klicken Sie auf das Männchensymbol im Tag, im Fach und bestätigen Sie die Meldung mit dem grünen Häkchen.'
                });
            } else if (mode === 'substitutePlan' || this.removeSickAppointments.length) {
                if (this.sickAppointments.length) {
                    this.areYouSureText = this.areYouSureTextClose;
                    this.areYouSurePopup = true;
                    return;
                }
                if (!this.substitutePlan) {
                    this.date = new Date(JSON.parse(JSON.stringify(this.today)));
                }
                this.substitutePlan = !this.substitutePlan;
                // Switch other modes
                // this.vuexEditMode = false;
                this.leaveEditMode();
                this.leaveSickMode();
                this.roomMode = false;
                this.filterMode = false;               
                this.currentFilteredTeacher = null;
                if (this.accountRole === 'maintainer') {
                    this.toggleTodayBtn = !this.toggleTodayBtn;
                }
                this.showSnackbar({
                    message: 'Abwesend melden: Klicken Sie auf das Männchensymbol im Tag, im Fach oder die Pfeile daneben und bestätigen Sie die Meldung mit dem grünen Häkchen.'
                });
            } else if (mode === 'roomMode') {
                if (!this.weeklyView) {
                    this.toggleWeeklyViewWidget();
                }
                this.leaveEditMode();
                this.leaveSickMode();
                this.substitutePlan = false;
                this.filterMode = false;
                this.currentFilteredTeacher = null;

                if (!this.currentRoomModel && this.allRooms.length > 0) {
                    this.currentRoomModel = this.allRooms[0];
                    this.changeRoom()
                }
                this.roomMode = !this.roomMode;
                if(!this.roomMode){
                    this.currentRoomModel = null;
                }
                this.showSnackbar({
                    message: 'Räume: Oben links können Sie einen Raum auswählen, nach dem gefiltert wird, um die Raumbelegung übersichtlicher darzustellen.'
                });
            }else if (mode === 'filterMode') {
                if (this.sickAppointments.length || this.removeSickAppointments.length) {
                    this.areYouSureText = this.areYouSureTextClose;
                    this.areYouSurePopup = true;
                    return;
                }

                this.leaveEditMode();
                this.leaveSickMode();
                this.substitutePlan = false;
                this.roomMode = false; 
                this.currentRoomModel = null;
                this.filterMode = !this.filterMode;
                if(!this.filterMode){
                    this.currentFilteredTeacher = null;
                }else{

                this.showSnackbar({
                    message: 'Oben links können Sie einen Lehrer aussuchen um dessen Stundenplan anzuzeigen.',
                    timeout:'10000'
                });
                }
           
            }
        },

        async requestProfilePictures(user) {
            if (user.profilePicture) {
                return await this.getProfilePicture(user._id);
            } else {
                // return profile icon or take standard icon
                const teacherAcc = this.accountsById[user.account];
                const iconObject = {
                    color: teacherAcc.profileColor ? teacherAcc.profileColor : '#ff6633',
                    icon: teacherAcc.profilePicture ? teacherAcc.profilePicture : 'mdi-emoticon-happy-outline'
                }
                return iconObject;
            }
        },

        async changeDateThroughDatePicker() {
           if(this.roomMode){
                const appointmentsForWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: new Date(this.datePickerString)})
                await this.setAppointmentsByRoomsBuffer(appointmentsForWeek)
                await this.emptyRoomsBufferIntoState();
           }else if(this.filterMode){
                const appointmentsForWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, 
                                                                                            date: new Date(this.datePickerString)})
                await this.setAppointmentsByTeachersBuffer(appointmentsForWeek);
                await this.emptyTeachersBufferIntoState();
            }
           else{
                //get appointments for currentWeek and set buffer and push buffer to state
                const appointmentsForWeek = await this.getAppointmentsByWeek({date: new Date(this.datePickerString)});
                this.setAppointmentBuffer(appointmentsForWeek);
                this.emptyBufferIntoState();
           }
            //if day is monday load and push appointments for previous week into state to make sure subDay() works
            if(new Date(this.datePickerString).getDay === 1){
                if(this.roomMode){
                    const appointmentsPreviousWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, 
                                                                                                date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()-7)})
                    await this.setAppointmentsByRoomsBuffer(appointmentsPreviousWeek);
                    await this.emptyRoomsBufferIntoState();
                }else  if(this.filterMode){
                    const appointmentsPreviousWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, 
                                                                                                date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()-7)})
                    await this.setAppointmentsByTeachersBuffer(appointmentsPreviousWeek);
                    await this.emptyTeachersBufferIntoState();
                }
                else{
                    const appointmentsPreviousWeek = await this.getAppointmentsByWeek({date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()-7) })
                    this.setAppointmentBuffer(appointmentsPreviousWeek);
                    this.emptyBufferIntoState();
                }

            //if day is friday load and push appointments for next week into state to make sure addDay() works
            }else if(new Date(this.datePickerString).getDay === 5){
                if(this.roomMode){
                    const appointmentsNextWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, 
                                                                                        date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()+7) })
                    await this.setAppointmentsByRoomsBuffer(appointmentsNextWeek);
                    await this.emptyRoomsBufferIntoState();
                } else if(this.filterMode){
                    const appointmentsNextWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, 
                                                                                        date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()+7) })
                    await this.setAppointmentsByTeachersBuffer(appointmentsNextWeek);
                    await this.emptyTeachersBufferIntoState();
                }
                else{
                    const appointmentsNextWeek = await this.getAppointmentsByWeek({date: new Date(new Date(this.datePickerString).getFullYear(), new Date(this.datePickerString).getMonth(), new Date(this.datePickerString).getDate()+7) })
                    this.setAppointmentBuffer(appointmentsNextWeek);
                    this.emptyBufferIntoState();
                }
            }

            this.date = new Date(this.datePickerString);
            if (this.widgetView === 'week') {
                const weekContainer = document.getElementById("weekContainer-" + this.date.getFullYear() + "-" + this.date.getWeek());
                weekContainer.scrollIntoView();
            }
            if (this.widgetView === 'lesson') {
                this.widgetView = 'day';
            }
        },

        /**
         * Is actually good to use, creates local reference to store icon
         */
        async requestFachIconUrls() {
            await this.subjects.reduce(async (previousPromise, subject) => {
                const previous = await previousPromise;
                if (subject.uploadedIcon) {
                    const iconUrl = await this.getUploadedIcon(subject.icon);
                    this.iconUrls.push({icon: subject.icon, iconUrl: iconUrl})
                }
                previous.push(subject);
                return previous;
            }, Promise.resolve([]));
        },

        /* only allow weekdays to be selected in date picker */
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        /* Format: KW 15 (11.04. - 15.04) */
        getCalendarWeek(date) {
            let cal = `KW ${date.date.getWeek()}`;
            let dayA = new Date(date.days[0]).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit'});
            let dayB = new Date(date.days[4]).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit'});
            return `${cal} (${dayA} - ${dayB})`;
        },

        // #region Drag n Drop
        async dropFile(event) {
            this.limitEvent(event);
            const fileArray = Array.from(event.dataTransfer.files);
            if (await this.validateMimeType(fileArray)) {
                this.fileToUpload = fileArray;
                this.callFileUpload = true;
                this.dropUiActive = false;
            }
        },
        limitEvent(event) {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragEnter(e) {
            this.limitEvent(e);
            e.currentTarget.classList.add('dragFeedback');
            if (this.widgetView === 'lesson') {
                this.dropUiActive = true;
            }

        },
        onDragLeave(e) {
            this.limitEvent(e);
            e.currentTarget.classList.remove('dragFeedback');
            this.$nextTick(() => {
                this.dropUiActive = false;
            });
        },
        onDragOver(e) {
            this.limitEvent(e);

            if (this.widgetView === 'lesson') {
                const path = e.path || (e.composedPath && e.composedPath());
                this.dropUiActive = !!path.find(el => el.id === 'planWidgetContainer');
            }
        },

        // #End Region


        editHohlstunde(timeslot) {
            this.widgetView = "lesson";
            this.timeslotToFill = timeslot;
            // this.currentAppointment = 'empty';
        },

        isDateInPast(date) {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate()) <
                new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
        },

        customFilter(item, queryText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1;
        },

        /**
         * Changing group postprocessing of appointments
         */
        async changeGroup() {
            if (this.accountRole === 'maintainer') {
                this.pastWeekOpenSubstitutions = 0;
                this.futureWeekOpenSubstitutions = 0;
                this.countSubstitutionBadges();
            }
        },
        async changeRoom() {
            const appointments = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date:  this.date = new Date(this.date)})
            await this.emptyRoomsBufferIntoState();
        },
        async changeFilteredTeacher() {
            const appointments = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date:  this.date = new Date(this.date)});
           await this.emptyTeachersBufferIntoState();
        },
        /**
         * Helper function to sort arrays alphabetically
         */
        alphabetically(a, b) {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        },

        /**
         * When scrolling in weekly view this method live updates this.date so that the date path correctly displays
         * the week (Format: KW 24)
         */
        scrollingWeek() {
            const planBody = document.getElementById('planBody').getBoundingClientRect();
            const weekContainer = this.futureDates.map((el) => {
                return document.getElementById("weekContainer-" + el.date.getFullYear() + "-" + el.date.getWeek());
            })
            weekContainer.some((el) => {
                const y = el.getBoundingClientRect().y;
                if (y > planBody.y + 120 && y < planBody.bottom) {
                    const id = el.id.split('-');
                    this.date = this.getDateOfWeek(id[2], id[1]);
                    return true; // works like break;
                }
            })
        },

        /**
         * Generates all dates 6 months prior to today and 6 months in advance of today and puts them into
         * this.futureDates (without timestamp)
         */
        generateDates() {
            let today = new Date();
            const weekInSixMonths = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
            let date = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
            for (let i = 1; weekInSixMonths > date; i++) {
                const days = this.getWorkDaysOfWeek(date);
                this.futureDates.push({
                    date,
                    days,
                });
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
            }
        },

        /**
         * Set the widgetView through a part of the header (tabs or datepath)
         * @param widgetView is the string to which view it should be set
         * @param resetDate if the global date of the widget should be reset
         * @param leaveLesson remove query from url
         */
        async setWidgetViewThroughHeader(widgetView, resetDate, leaveLesson) {
            if (resetDate) {
                this.date = new Date();
            }
            if (leaveLesson && this.currentAppointment !== 'empty') {
                await this.$router.replace({
                    query: { appointment: undefined },
                });
            }
            const widgetViewBefore = this.widgetView;
            this.widgetView = widgetView;
            // widgetViewBefore prevents bug where double-click on week made thisWeekContainer.getBoundingClientRect().y
            // always be 200 which resulted in scrolling to the top
            if (widgetView === 'week' && widgetViewBefore !== 'week') {
                await this.$nextTick();
                const thisWeekContainer = document.getElementById("weekContainer-" + this.date.getFullYear() + "-" + this.date.getWeek());
                if (thisWeekContainer) {
                    this.$refs.weeklyViewScrollbar.$el.scrollTop = thisWeekContainer.getBoundingClientRect().y - 200;
                }
            }
            if (widgetView === "day") {
                this.widgetView = 'day';
            }
        },

        /**
         * Set the widget on a specific date (day or lesson)
         * @param newDate is the new date this.date will be
         * @param target is the view the widget should be set to (here it should be 'day' or 'lesson'
         * @param appointment is the current appointment in case the target view is 'lesson'
         */
        setDate(newDate, target, appointment) {
            this.date = newDate;
            this.widgetView = target;
        },

        /**
         * Calculates the date with a give week and a year.
         * (Reference/origin: https://stackoverflow.com/questions/16590500/calculate-date-from-week-number-in-javascript)
         * @param w the week
         * @param y the year
         * @returns {Date} the resulting date
         */
        getDateOfWeek(w, y) {
            let d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
            d = d - (6 - new Date().getDay())
            return new Date(y, 0, d);
        },

        /**
         * Gets the dates from all workdays of a week with a given day in the specific week
         * (Reference/origin: https://stackoverflow.com/questions/43008354/js-get-days-of-the-week)
         * @param dayInWeek the day in the week from which you want to get all workdays
         * @returns {*[]} an array containing the 5 workdays that are in the same week as dayInWeek
         */
        getWorkDaysOfWeek(dayInWeek) {
            dayInWeek = new Date(dayInWeek);
            const week = [];
            // Starting Monday not Sunday
            dayInWeek.setDate((dayInWeek.getDate() - dayInWeek.getDay() + 1));
            for (let i = 0; i < 5; i++) {
                week.push(
                    new Date(dayInWeek)
                );
                dayInWeek.setDate(dayInWeek.getDate() + 1);
            }
            return week;
        },

        getWhiteIconSrc(iconObject) {
            if (iconObject.icon.includes('Icon')) {
                let searchString = iconObject.icon + "Weiss";
                const resource = this.$data[Object.keys(this.$data).find((el) => el === searchString)];
                if(resource){
                    return resource;
                }else{
                    return '';
                }
            } else {
                const icon = this.iconUrls.find((icon) => icon.icon === iconObject.icon);
                if (icon) {
                    return icon.iconUrl;
                } else {
                    return loadingIcon;
                }
            }
        },

        /**
         * Use this to set the widget to a specific appointment in lesson view
         * @param appointment that the widget should display in lesson view
         */
        setLessonFromAppointment(appointment) {
            this.setDate(new Date(appointment.day), 'lesson', appointment);
        },

        substitutionBadgeClicked(neededEvent) {
            const eventDate = new Date(neededEvent.date);
            const appointemnt = this.appointmentsByGroupOrRoom.find(app => app._id === neededEvent.appointment);
            if (!this.$route.query.aId || (this.$route.query.aId !== neededEvent.appointment) || this.date !== eventDate) {
                this.$router.replace({query: {aId: neededEvent.appointment}});
                this.setDate(eventDate, this.widgetView, appointemnt);
            }

            this.$nextTick(() => {
                if (!this.babyView) {
                    const element = this.$refs.substitutionPlan.$refs[`substitutionAppointment${neededEvent.appointment}`][0];
                    if (element) {
                        element.$el.classList.remove('badgeEventVisualize');
                        element.$el.classList.add('badgeEventVisualize');

                        setTimeout(() => {
                            element.$el.classList.remove('badgeEventVisualize');
                        }, 1050);
                    }
                }
            });
        },

        /**
         * Change to date of most recent event (on badge click)
         * @param neededEvent
         */
        async widgetBadgeClicked(neededEvent) {
            const eventDate = new Date(neededEvent.date);
            const weekId = `${eventDate.getWeek()}-${eventDate.getFullYear()}`;
            if (!this.appointmentsByWeek[weekId]) {
                const newWeek = await this.getAppointmentsByWeek({date: new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate()) })
                await this.setAppointmentBuffer(newWeek);
                await this.emptyBufferIntoState();
            }

            this.$emit('clearAllBadge');

            // automatically change to day view with wanted date
            let targetView = 'day';
            if(this.babyView) {
                targetView = 'lesson';
                // push correct id into query and open correct appointment
                if (!this.$route.query.aId || (this.$route.query.aId !== neededEvent.appointment)) {
                    this.$router.replace({query: {aId: neededEvent.appointment}});
                    this.setDate(eventDate, targetView, this.appointmentsByGroupOrRoom.find(app => app._id === neededEvent.appointment));
                }
            }
            if (this.widgetView !== targetView) {
                this.widgetView = targetView;
            }
            if (this.date !== eventDate) {
                this.date = eventDate;
            }

            this.$nextTick(() => {
                if (!this.babyView) {
                    const element = this.$refs[`pupilAppointment${neededEvent.appointment}`][0];
                    if (element) {
                        element.$el.classList.remove('badgeEventVisualize');
                        element.$el.classList.add('badgeEventVisualize');

                        setTimeout(() => {
                            element.$el.classList.remove('badgeEventVisualize');
                        }, 1050);
                    }
                }
            });
        },

        receivedEmit(callback) {
            /*emit to PlanSchueler*/
            this.$emit('start-bbb-tut', callback);
        },

        async requestAppointments(type, getCurrentAppointment, fetch = true) {
            this.showLoadingWeeklyView = true;
            if(type) {
                if(typeof type === 'string') {
                    await this.getAppointment(type);
                } else if(Array.isArray(type)) {
                    // call store method for multiple appointments
                    await this.getMultipleAppointments(type)
                }
            } else {
                // just get all appointments
                await this.getAppointmentsByWeek({});
                // this.substitutionAppointments = [];
                // If the current user is a parent use the special route (which should be changed to one imo)
                if (this.accountRole === "parent" && this.currentChild) {
                    await this.getAppointmentsByWeek({ pupilId: this.currentChild._id });
                    // await this.getAppointmentsParent(this.currentChild._id);
                } else {
                    // Else get all appointments, this gets the correct appointments depending on role
                    if (this.accountRole === 'teacher') {
                        if (!this.person._id) {
                            await this.requestPerson();
                        }
                    }
                }
                this.countBadges();
            }

            // Stuff that always needs to happen
            this.showLoadingWeeklyView = false;
        },

        async requestSlots(teacherEditMode) {
            // method stub for props, not needed anymore
        },

        /** Todo move this to a more global position, create single getter for this
         * pulls the current persons database entry, not just the account
         */
        async requestPerson() {
            switch (this.accountRole) {
                case 'teacher':
                    this.person = await this.getMeTeacher();
                    break;
                case 'parent':
                    this.person = await this.getMeParent();
                    break;
                case 'pupil':
                    this.person = await this.getMePupil();
                    this.babyView = this.person.babyView;
                    break;
                case 'maintainer':
                    this.person = await this.getMeMaintainer();
                    break;
            }
        },

        jumpToCurrentDay() {
            this.date = this.today;
            this.countBadgesWeek();
        },

        increaseDate() {
            if (this.substitutePlan && this.currentGroup.name === 'Alle') {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1);
                if (this.date.getDay() === 0 || this.date.getDay() === 6) {
                    this.increaseDate();
                }
            } else {
                this.addWeek();
            }
            this.countBadgesWeek();
        },
        decreaseDate() {
            if (this.substitutePlan && this.currentGroup.name === 'Alle') {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 1);
                if (this.date.getDay() === 0 || this.date.getDay() === 6) {
                    this.decreaseDate();
                }
            } else {
                this.subWeek();
            }
            this.countBadgesWeek();
        },

        async subWeek(){
            const currentDay = this.date.getDay();
            let daysToSubtract;
            if (currentDay === 1) { // Monday
                daysToSubtract = 7; // Go back to the previous Monday
            } else {
                daysToSubtract = currentDay + 6; // Go back to the Monday of the previous week
            }
            const monday = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - daysToSubtract);
            const prevMonday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() - 7);
            const filter = { date: monday, subWeek: this.vuexEditMode, };
            const prevFilter = { date: prevMonday, subWeek: this.vuexEditMode, };
            if (this.accountRole === 'parent' && this.currentChild) {
                filter.pupilId = this.currentChild._id;
                prevFilter.pupilId = this.currentChild._id;
            }

            //load appointments for previous week
            this.date = monday;
            if(this.roomMode){
                const appointmentsForWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: filter.date});
                await this.setAppointmentsByRoomsBuffer(appointmentsForWeek);
                await this.emptyRoomsBufferIntoState();
                //calculate previous week to fill buffer
                const previousWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: prevFilter.date});
                //set buffer for previous week
                await this.setAppointmentsByRoomsBuffer(previousWeek);
            } else if(this.filterMode){

                const appointmentsForWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: filter.date});
                await this.setAppointmentsByTeachersBuffer(appointmentsForWeek);
                await this.emptyTeachersBufferIntoState();
                //calculate previous week to fill buffer
                const previousWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: prevFilter.date});
                //set buffer for previous week
                await this.setAppointmentsByTeachersBuffer(previousWeek);
            }
            else {
                 //load appointments for week
                const appointmentsForWeek = await this.getAppointmentsByWeek(filter);
                await this.setAppointmentBuffer(appointmentsForWeek);
                await this.emptyBufferIntoState();

                //calculate next week to fill buffer
                const previousWeek =  await this.getAppointmentsByWeek(prevFilter);
                //set buffer for next week
                await this.setAppointmentBuffer(previousWeek);
            }


        },

        async addWeek(){
            const currentDay = this.date.getDay();
            let daysToAdd;
             if (currentDay === 1) { // Monday
                    daysToAdd = 7; // Skip to the next Monday
                } else {
                    daysToAdd = (8 - currentDay) % 7;
                }
            const monday = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + daysToAdd);
            const nextMonday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 7);
            const filter = { date: monday,addWeek: this.vuexEditMode,  };
            const nextFilter = { date: nextMonday, addWeek: this.vuexEditMode, };
            if (this.accountRole === 'parent' && this.currentChild) {
                filter.pupilId = this.currentChild._id;
                nextFilter.pupilId = this.currentChild._id;
            }
            this.date = monday;
            if(this.roomMode){

                const appointmentsForWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: filter.date});
                await this.setAppointmentsByRoomsBuffer(appointmentsForWeek);
                await this.emptyRoomsBufferIntoState();
                //calculate previous week to fill buffer
                const nextWeek = await this.getAppointmentsByRoomAndWeek({roomId: this.currentRoomModel._id, date: nextFilter.date});
                //set buffer for previous week
                await this.setAppointmentsByRoomsBuffer(nextWeek);
            } else if(this.filterMode){

                const appointmentsForWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: filter.date});
                await this.setAppointmentsByTeachersBuffer(appointmentsForWeek);
                await this.emptyTeachersBufferIntoState();
                //calculate previous week to fill buffer
                const nextWeek = await this.getAppointmentsForTeacherByWeek({teacherId: this.currentFilteredTeacher._id, date: nextFilter.date});
                //set buffer for previous week
                await this.setAppointmentsByTeachersBuffer(nextWeek);
            }
            else {
                 //load appointments for week
                const appointmentsForWeek = await this.getAppointmentsByWeek(filter);
                await this.setAppointmentBuffer(appointmentsForWeek);
                await this.emptyBufferIntoState();

                //calculate next week to fill buffer
                const nextWeek =  await this.getAppointmentsByWeek(nextFilter);
                //set buffer for next week
                await this.setAppointmentBuffer(nextWeek);
            }

        },
        //to display month string in header
        getDisplayMonth() {
           
            return (this.date).toString().slice(4, 7)
        },

        async getDay() {
            let date = new Date(await this.loadServerTime());
            while (!(date.getDay() % 6)) {
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
            }
            return date;
        },

        getMondayOfWeek(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },

        /**
         * This method could be obsolet in the future since badges are now managed by the computed property
         * "badgeEventList" (this.setTimetableBadge(this.badgeEventList.length); will still be necessary)
         */
        countBadges() {
            this.setTimetableBadge(this.badgeEventList.length);
            // Notify Plan[Role] Component about badges
            this.$emit('clearAll');
            if(this.badgeEventList.length) {
                this.$emit('planBadge');
            }
            this.countBadgesWeek();
            this.countSubstitutionBadges();
        },

        countBadgesWeek() {
            this.futureWeekBadges = 0;
            this.pastWeekBadges = 0;
            this.badgeEventList.map((badgeEvent) => {
                const badgeDay = new Date(badgeEvent.date);
                if (!(badgeDay.getWeek() === this.date.getWeek())) {
                    if (badgeDay > this.date) {
                        this.futureWeekBadges ++;
                    } else {
                        this.pastWeekBadges ++;
                    }
                }
            });
        },

        countSubstitutionBadges() {
            let allGroups = true;
            if (this.currentGroup && this.currentGroup.name !== 'Alle') {
                allGroups = false;
            }
            this.futureWeekOpenSubstitutions = 0;
            this.pastWeekOpenSubstitutions = 0;
            this.currentWeekOpenSubstitutions = 0;
            this.totalSubstitutions = 0;

            this.substitutionAppointments.map((appointment) => {
                let seenByMaintainer = false;
                appointment.substitutions.map((substitution) => {
                    if (substitution.seenByMaintainer) {
                        seenByMaintainer = true;
                    }
                });

                if(!seenByMaintainer) {
                    if(!allGroups) {
                        // Compare weeks
                        if(new Date(appointment.day).getWeek() > this.date.getWeek() && new Date(appointment.day).getFullYear() >= this.date.getFullYear()) {
                            this.futureWeekOpenSubstitutions ++;
                        } else if(new Date(appointment.day).getWeek() < this.date.getWeek() && new Date(appointment.day).getFullYear() <= this.date.getFullYear()){
                            this.pastWeekOpenSubstitutions ++;
                        }
                    } else {
                        // Compare days
                        if(new Date(appointment.day) > this.date && !this.datesOnSameDay(new Date(appointment.day), this.date)) {
                            this.futureWeekOpenSubstitutions ++;
                        } else if(new Date(appointment.day) < this.date && !this.datesOnSameDay(new Date(appointment.day), this.date)){
                            this.pastWeekOpenSubstitutions ++;
                        } else if(this.datesOnSameDay(new Date(appointment.day), this.date)) {
                            this.currentWeekOpenSubstitutions ++;
                        }
                    }
                }
            });
            if(this.totalSubstitutions !== this.pastWeekOpenSubstitutions + this.futureWeekOpenSubstitutions + this.currentWeekOpenSubstitutions
                && this.pastWeekOpenSubstitutions + this.futureWeekOpenSubstitutions + this.currentWeekOpenSubstitutions > 0)
            {
                this.totalSubstitutions = this.pastWeekOpenSubstitutions + this.futureWeekOpenSubstitutions + this.currentWeekOpenSubstitutions;
            }
        },

        datesOnSameDay(first, second) {
            return first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        timeInTimeSlot(time, timeslot) {
            return new Date(timeslot.start).toLocaleTimeString('de-DE') <= time.toLocaleTimeString('de-DE')
                && new Date(timeslot.end).toLocaleTimeString('de-DE') > time.toLocaleTimeString('de-DE');
        },

        /**
         * Checks if a timeslot is running at a given date. If no date is given, we check if it runs at the current
         * time.
         * @param timeslot the timeslot to check
         * @param date the date where we check if the timeslot runs in
         * @returns {boolean} if the timeslot runs at the given date/current time
         */
        isTimeslotCurrentlyRunningToday(timeslot, date) {
            const currentTimeAndDay = date ||
                this.date.getDate() === this.today.getDate() && this.date.getMonth() === this.today.getMonth() && this.date.getYear() === this.today.getYear() ?
                    this.today : this.date;
            // Needs a default empty list for asking the first item
            const appointment = (this.appointmentsTodayByTimeslot[(timeslot._id || timeslot)] || [])[0];
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (this.timeInTimeSlot(currentTimeAndDay, timeslot)) {
                        // Current time is between start and end of timeslot
                        return true;
                    }
                }
            }
            return this.timeInTimeSlot(currentTimeAndDay, timeslot);
        },

        /**
         * Lesson is joinable: 15 minutes before start of timeslot until 15 minutes after end of timeslot
         */
        isJoinable(timeslot) {
            const time = new Date(Date.now() + this.serverTimeOffset);
            const localeStart = new Date(timeslot.start);
            localeStart.setMinutes(localeStart.getMinutes() - 15);

            const localeEnd = new Date(timeslot.end);
            localeEnd.setMinutes(localeEnd.getMinutes() + 15);
            return localeStart.toLocaleTimeString('de-DE') <= time.toLocaleTimeString('de-DE')
                && localeEnd.toLocaleTimeString('de-DE') >= time.toLocaleTimeString('de-DE');
        },

        parseHtmlPreview(text) {
            return parseHtml(false, text);
        },

        toggleWeeklyViewWidget() {
            // if on mobile, display snackbar
            if (this.windowWidth < 600) {
                this.showSnackbar({
                    message: 'Wochenansicht - Scrolle nach rechts um mehr Tage der Woche zu sehen.',
                    color: 'info'
                })
            }
            this.toggleWeeklyView();
        },

        toggleWeeklySickMode() {
            this.toggleWeeklyViewWidget();
            if (this.accountRole === 'teacher') {
                this.toggleModes("sickMode");
            }
            if (this.accountRole === 'maintainer') {
                this.toggleModes('substitutePlan');
            }

        },

        toggleWeeklyEditMode() {
            this.toggleWeeklyViewWidget();
            this.toggleModes("editMode");
        },

        handleAudioUrl(audioUrl) {
            this.$emit('audioOverlay', audioUrl);
        },
        confirmSickMaintainer() {
            if (this.sickAppointments.length || this.removeSickAppointments.length) {
                this.areYouSureText = this.areYouSureTextConfirm;
                this.areYouSurePopupMaintainer = true;
            }
        },
        initializeEventBus() {
            const deleteUpload = async (body, uploadType) => {
                const { appointmentId, uploadId } = body;
                if (!appointmentId || !uploadId) {
                    return;
                }
                const foundAppointment = this.appointmentsByGroupOrRoom.find((appointment) => appointment._id === appointmentId);
                if (!foundAppointment) {
                    return;
                }
                foundAppointment[uploadType] = foundAppointment[uploadType].filter((upload) => upload._id !== uploadId);
                await this.addOrUpdateAppointment(foundAppointment);
            };

            const newUpload = async (body, uploadType) => {
                const { createdUpload, appointmentId, fileAccessToken } = body;
                if (!createdUpload || !appointmentId) {
                    return;
                }
                createdUpload.fileAccessToken = fileAccessToken;
                const foundAppointment = this.appointmentsByGroupOrRoom.find((appointment) => appointment._id === appointmentId);
                if (!foundAppointment) {
                    return;
                }
                const updatedUploads = foundAppointment.teacherUploads.concat([createdUpload]);
                const newAppointment = {
                    ...foundAppointment,
                    teacherUploads: updatedUploads,
                };
                await this.addOrUpdateAppointment(newAppointment);
            };

            const appendUpload = async (body, uploadType, appendType) => {
                const { createdUpload, appointmentId } = body;
                if (!createdUpload || !appointmentId) {
                    return;
                }
                const foundAppointment = this.appointmentsByGroupOrRoom.find((appointment) => appointment._id === appointmentId);
                if (!foundAppointment) {
                    return;
                }
                const uploads = foundAppointment[uploadType];
                uploads.push(createdUpload);
                const appendadges = foundAppointment[appendType];
                const foundOriginalUpload = appendadges.find((upload) => upload._id === createdUpload.editParent);
                if (!foundOriginalUpload) {
                    return;
                }
                foundOriginalUpload.editLeafs.push(createdUpload._id);
                // Todo later put these in one
                const updatedAppointment = {
                    ...foundAppointment,
                }
                updatedAppointment[uploadType] = uploads;
                updatedAppointment[appendType] = appendadges;
                await this.addOrUpdateAppointment(updatedAppointment);

            };

            /** Subject live connection */
            EventBus.$off('schoolsubject-delete');
            EventBus.$on('schoolsubject-delete', async (subjectId) => {
                await this.deleteSubject(subjectId);
            });
            EventBus.$off('schoolsubject-new');
            EventBus.$on('schoolsubject-new', async (createdSubject) => {
                if (createdSubject) {
                    await this.pushSubject(createdSubject);
                }
            });
            EventBus.$off('schoolsubject-patch');
            EventBus.$on('schoolsubject-patch', async (updatedSubject) => {
                if (!updatedSubject) {
                    return;
                }
                const foundSubject = this.subjects.find((subject) => subject._id === updatedSubject);
                if (foundSubject) {
                    await this.patchSubject(updatedSubject);
                } else {
                    await this.pushSubject(updatedSubject);
                }
            });
            /** Appointment live connection */
            EventBus.$off('appointment-new');
            EventBus.$on('appointment-new', async (createdAppointments) => {
                // createdAppointments.timeslot = (createdAppointments.timeslot._id || createdAppointments.timeslot);
                if (createdAppointments.length > 1) {
                    await this.addOrUpdateAppointments(createdAppointments)
                } else {
                    await this.addOrUpdateAppointment(createdAppointments);
                }
            });
            EventBus.$off('appointment-delete');
            EventBus.$on('appointment-delete', async (appointmentId) => {
                await this.removeAppointment(appointmentId);
            });
            EventBus.$off('appointment-patch');
            EventBus.$on('appointment-patch', async (updatedAppointment) => {
                await this.addOrUpdateAppointment(updatedAppointment);
            });
            /** Timeslot live connection*/
            EventBus.$off('timeslot-new');
            EventBus.$on('timeslot-new', (timeslot) => {
                this.addOrUpdateTimeslot(timeslot);
            });
            EventBus.$off('timeslot-delete');
            EventBus.$on('timeslot-delete', (timeslotId) => {
                this.spliceTimeslot(timeslotId);
            });
            EventBus.$off('timeslot-patch');
            EventBus.$on('timeslot-patch', (timeslot) => {
                this.addOrUpdateTimeslot(timeslot);
            });
            /** Uploads live connection */
            EventBus.$off('teacherupload-new');
            EventBus.$on('teacherupload-new', async (body) => {
                await newUpload(body, 'teacherUploads');
            });
            EventBus.$off('teacherupload-delete');
            EventBus.$on('teacherupload-delete', async (body) => {
                await deleteUpload(body, 'teacherUploads');
            });
            EventBus.$off('pupilupload-new');
            EventBus.$on('pupilupload-new', async (body) => {
                await newUpload(body, 'pupilUpload');
            });
            EventBus.$off('pupilupload-delete');
            EventBus.$on('pupilupload-delete', async (body) => {
                await deleteUpload(body, 'pupilUpload');
            });
            EventBus.$off('teacherupload-edit');
            EventBus.$on('teacherupload-edit', async (body) => {
                await appendUpload(body, 'pupilUpload', 'teacherUploads');
            });
            EventBus.$off('pupilupload-edit');
            EventBus.$on('pupilupload-edit', async (body) => {
                await appendUpload(body, 'teacherUploads', 'pupilUpload');
            });
        },
        async clearAppointmentBadges() {
            try {
                await this.badgeEventList.map(async (event) => {
                    if (event.upload) { // Unseen upload
                        await this.patchAppointmentUploadSeenBy({
                            uploadId: event.upload._id ? event.upload._id : event.upload,
                            accountId: this.accountId,
                            role: this.accountRole
                        });
                    } else { // Unseen note
                        await this.setAppointmentNoteSeen(event.appointment);
                    }
                });
                await this.getAppointments(true);
                this.showSnackbar({
                    message: 'Benachrichtigungen wurden erfolgreich als gesehen markiert!',
                    color: 'success'
                });
            } catch(error) {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist aufgetreten.',
                    color: 'error'
                });
                console.error(error);
            }
        },
        async clearSubstitutionBadges() {
            try {
                this.totalSubstitutionCount.map((appointment) => {
                    appointment.substitutions.map((sub) => {
                        if (!sub.seenBy.includes(this.accountId)) {
                            let newSeenBy = [];
                            sub.seenBy.forEach((seen) => {
                                newSeenBy.push(seen);
                            })
                            newSeenBy.push(this.accountId);
                            this.patchSubstitution({
                                substitutionId: sub._id, params: {
                                    seenBy: newSeenBy
                                }
                            });
                        }
                    })
                })
                await this.getAppointments(true);
                this.showSnackbar({
                    message: 'Benachrichtigungen wurden erfolgreich als gesehen markiert!',
                    color: 'success'
                });
            } catch(error) {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist aufgetreten.',
                    color: 'error'
                });
                console.error(error);
            }
        },

        async exportCurrentWeekPlan() {
            if (this.weeklyView && this.currentGroup) {
                // get appointments of current week
                const appointmentsOfWeek = this.appointmentsByWeek[`${this.date.getWeek()}-${this.date.getFullYear()}`]
                    .filter(item => item.group === this.currentGroup._id);
                const groupTimeslots = this.timeslotsByGroupId[`${this.currentGroup._id}`]
                // transform appointments
                const appointmentTable = {};
                // create appointmentTable with all dates and timeslots
                // monday has index 1, friday index 5
                const mondayRaw = new Date(this.date);
                const fridayRaw = new Date(this.date);

                let monday = mondayRaw;
                let friday = fridayRaw;
                // set start date to day 1 of corresponding week
                if (mondayRaw.getDay() > 1) {
                    monday.setDate(new Date(mondayRaw).getDate() - (mondayRaw.getDay() - 1));
                } else if (mondayRaw.getDay() === 0) {
                    monday.setDate(new Date(mondayRaw).getDate()+1);
                }
                // set end date to day 5 of corresponding week
                if (fridayRaw.getDay() < 5) {
                    friday.setDate(new Date(fridayRaw).getDate() + (5 - fridayRaw.getDay()));
                } else if (fridayRaw.getDay() === 6) {
                    friday.setDate(new Date(fridayRaw).getDate()-1);
                }

                const currentDate = monday;
                while (currentDate <= friday) {
                    appointmentTable[`${currentDate.toISOString().split("T")[0]}`] = [];
                    // need to do this for every day because they would not be individual instances
                    groupTimeslots.forEach(timeslot => {
                        appointmentTable[`${currentDate.toISOString().split("T")[0]}`].push({
                            _id: timeslot._id,
                            start: timeslot.start.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" }),
                            end: timeslot.end.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" }),
                            appointments: [],
                            isPause: timeslot.category === 'normal' ? false: true,
                        })
                    })

                    // update current date
                    currentDate.setDate(new Date(currentDate).getDate() + 1);
                }
                appointmentsOfWeek.map((appointment) => {
                    const day = appointmentTable[appointment.day.split("T")[0]]
                    const timeslotOnDay = day.find(timeslot => timeslot._id === (appointment.timeslot._id || appointment.timeslot))
                    if (timeslotOnDay) {
                        // what do i need: teacher shorthands, subject name, room name,
                        // refactor appointments into needed data
                        // handle substitutions
                        let sickTeachers = [];
                        let teachers = appointment.teachers;
                        let status = 'normal';
                        if (appointment.substitutions.length) {
                            sickTeachers = appointment.substitutions.map(sub => sub.sickTeacher);
                            teachers = appointment.teachers.filter(teacher => !sickTeachers.find(st => st._id === teacher._id));
                            if (!teachers.length) {
                                status = 'canceled';
                            } else if (teachers.length && sickTeachers.length) {
                                status = 'substituted';
                            }
                        }
                        const neededData = {
                            status,
                            'subject': appointment.schoolSubject.name,
                            'rooms': appointment.rooms.map(r => r.roomName),
                            'teachers': teachers.map(t => `${t.name.substring(0, 1)}.${t.lastName.substring(0, 1)}`),
                            'sickTeachers': sickTeachers.map(t => `${t.name.substring(0, 1)}.${t.lastName.substring(0, 1)}`)
                        }
                        timeslotOnDay.appointments.push(neededData);
                    }
                });
                // feed appointments and group data to export
                const pdfBytes = await exports.splanWeekExport(this.currentGroup, appointmentTable);

                // download created pdf
                let blob = new Blob([pdfBytes], { type: "application/pdf" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `Plan ${this.currentGroup.name} KW${friday.getWeek()}.pdf`;
                link.click();
            }
        },
    }
};
</script>

<style lang="scss" scoped>

.headerButton {
    height: 45px;
    border-radius:50%;
}
.dialogCard {
    max-height: 75%;
    position: absolute;
    top: 80px;
    max-width: 94vw;
    border-radius: 16px;
    margin-bottom: 100px;
}
@media only screen and (min-width: 501px) and (max-width: 900px) {
    .dialogCard {
        bottom: 90px;
        top: 1%;
    }
}
@media only screen and (max-width: 500px){
    .dialogCard {
        top: 1%;
        left: 3%;
    }
}
.v-dialog {
    display: flex;
    max-height: 80vh;
    padding:80px;
    pointer-events: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    overflow-y: hidden;
}

.header {
    height: 56px;
    background-color: var(--v-timetableColor-base);
    border-radius: 16px 16px 0 0;
    color: white;
}

#weeklyViewHeaderContainer {
    background-color: var(--v-timetableColor-base);
    padding: 10px;
    color: white;
    max-height: 56px;
}

.calendarDate {
    width: 150px;
    height: 140px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 12px
}

.calendarDateInPast {
    opacity: 0.5;
}

.calendarWeekLine {
    width: 30%;
    height: 2px;
    background-color: grey;
}

.selectedHeader {
    background-color: #2d415b;
}

.planBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 18px 0 24px;
    background-color: var(--v-widgetBg-base);
    max-height: calc(80vh - 55px);

    @media only screen and (min-width: 901px) {
        //max-width: 33vw;
    }
}

.datePathText {
    text-decoration: underline;
    white-space: unset;
    padding: 2px;
}

.datePathClickable {
    border-radius: 12px;
    padding: 2px;
    cursor: pointer;
}

.datePathClickable:hover {
    background-color: white;
}

.lessonIcon {
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-right: 2px;
    border-radius: 6px;
}

.fach {
    cursor: pointer;
}

.weeklyFachRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.whiteBg {
    background-color: #ffffff;
}

.scroll-area-maintainer {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    max-height: calc(80vh - 130px);
}
.scroll-area {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    max-height: calc(80vh - 110px);
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

.dropzone {
    background-color: grey;
}

.dragFeedback {
    background-color: #b9b9b9;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dropUiContainer {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.dropUi {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
        width: 75%;
        text-wrap: normal;
        text-align: center;
    }
}

.folderDisplay {
    display: flex;
    justify-content: center;
    align-content: space-around;
    font-size: 150px !important;
    color: white;
}

.responsiveArrow {
    margin-right: calc(48px + 2%);
}

.responsiveAutocomplete {
    width: calc(48px + 2% + 24.875px + 5%);
    min-width: calc(48px + 2% + 24.875px + 5%);
    z-index: 4;
}

.responsiveDatepickerMaintainer {
    margin-left: 5%;
}

@media only screen and (max-width: 400px) {
    .responsiveDatePath {
        font-size: 3vw;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1016px) {
    .responsiveDatepicker {
        font-size: 9px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .responsiveArrow {
        margin-right: calc(36px + 2%);
    }
    .responsiveAutocomplete {
        width: calc(36px + 2% + 24.875px);
        min-width: calc(36px + 2% + 24.875px);
    }
    .responsiveDatepickerMaintainer {
        margin-left: 0;
        font-size: 12px;
    }
}

@media only screen and (max-width: 650px) {
    .responsiveNoDisplay {
        display: none;
    }
}

@media only screen and (max-width: 715px) {
    .responsiveDate {
        flex-direction: column !important;
        font-size: small !important;
    }
    .responsiveHeaderButton {
        width: 36px !important;
        height: 36px !important;
        margin-right: 4px !important;
    }

    .responsiveHeaderDateButton {
        margin-right: 4px !important;
        margin-left: 4px !important;
    }


    .responsiveSplitter {
        display: none
    }

    .responsiveAutocompletePadding {
        padding-left: 4px !important;
    }
}

@media only screen and (max-width: 875px) {
    .displayNoneResponsiveToday {
        display: none;
    }
}

.iconToDarkBlue {
    filter:
        brightness(0)
        saturate(100%)
        invert(15%)
        sepia(34%)
        saturate(669%)
        hue-rotate(187deg)
        brightness(91%)
        contrast(89%)
    ;
}

.iconToLightGray {
    filter:
        brightness(0)
        saturate(100%)
        invert(71%)
        sepia(16%)
        saturate(619%)
        hue-rotate(177deg)
        brightness(97%)
        contrast(89%)
    ;
}

.timetableDisplay {
    display: block;
}

.stundenplanIconDisplay {
    margin-left: 0;
}

@media only screen and (max-width: 1384px) and (min-width: 900px) {
    .timetableDisplay {
        display: none;
    }
    .stundenplanIconDisplay {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 461px) {
    .timetableDisplay {
        display: none;
    }
    .stundenplanIconDisplay {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 1075px) {
    .responsiveMaintainerDisplay {
        display: none;
    }
}

@media only screen and (max-width: 950px) {
    .maintainerToday {
        display: none;
    }
}

.iconToSeeThroughBlue{
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(2368%) hue-rotate(176deg) brightness(98%) contrast(88%);
}

</style>
