<template>
    <div
        :id="`pinboardSystemTile${item._id}`"
        class="mx-auto mr-5 thumbnailContainer"
        style="display: flex; justify-content: center; postition: relative; align-items: center"
        :style="{'background-color': item.color}"
        role="button"
    >
        <div
            v-if="!item.uploadedIcon"
            class="thumbnailDisplayNormal my mx-auto"
        >
            <img
                class="thumbnailDisplayNormal my-1 mx-auto"
                style="margin-bottom: 5px; overflow: hidden"
                :src="chooseIcon(item.icon)"
                :style="{'background-color': item.color}"
                :alt="item.title"
                aspect-ratio="1.5"
                @click="openPinboard(item)"
            >
        </div>
        <img
            v-else
            class="thumbnailDisplayCustom my-1 mx-auto pa-0"
            style="margin-bottom: 5px; overflow: hidden"
            :src="item.iconBlob"
            :style="{'background-color': item.color}"
            :alt="item.title"
            @click="openPinboard(item)"
        >
        <div
            class="pinboardIconOverlay"
            @click="openPinboard(item)"
        />
        <PinboardTileMenu
            v-if="this.moderator"
            :item="item"
            :update-pinboard="updatePinboard"
            :close-create-dialog="closeCreateDialog"
            style="width: 100%; height: 85%; z-index: 1"
            class="pinboardButtonPosition"
            @loadEditorPreview="handleClickElement"
            @deleteItem="handleDeleteButtonPress"
            @openMenu="(value) => { openMenu = value }"
        />
        <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
                <v-btn
                    ref="pinboardParticipantButton"
                    x-small
                    icon
                    elevation="0"
                    class="participantPinboardButton participantPinboardButtonPosition"
                    v-on="{ ...tooltip }"
                    @click="openParticipantsDialog"
                >
                    <img
                        :src="groupIcon"
                        style="height: 30px; filter: brightness(1000%); opacity: 1"
                    >
                </v-btn>
            </template>
            <span>{{ (moderator ? "Teilen mit" : "Teilnehmer") }}</span>
        </v-tooltip>
        <PinboardParticipantsDialog
            v-if="showParticipantsDialog"
            :item="item"
            :edit-mode="moderator"
            :edit-id="item._id"
            :file-name="item.title"
            :memberships="item.memberships"
            :update-pinboard="updatePinboard"
            @close="showParticipantsDialog = false"
        />
    </div>
</template>

<script>
import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';
import PinboardParticipantsDialog from "@/components/Pinboard/PinboardParticipantsDialog";
import PinboardTileMenu from "@/components/Pinboard/PinboardTileMenu";


//icons
import groupIcon from '@/assets/Icons/group.svg';
import erdkundeIconWeiss from '../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIconWeiss from '../../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIconWeiss from '../../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIconWeiss from '../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIconWeiss from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import pinIconWeiss from "../../assets/Icons/FaceliftIcons/pin_alt_weiss.svg";
import chemieIconWeiss from '../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg';
import deutschIconWeiss from '../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg';
import geschichteIconWeiss from '../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg';
import matheIconWeiss from '../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg';
import musikIconWeiss from '../../assets/Icons/FaceliftIcons/f-musik-weiss.svg';
import physikIconWeiss from '../../assets/Icons/FaceliftIcons/f-physik-weiss.svg';
import sportIconWeiss from '../../assets/Icons/FaceliftIcons/f-sport-weiss.svg';
import wirtschaftIconWeiss from '../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg';
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import * as backend from "@/api/backend";

export default {
    name: 'PinboardTileElement',
    components: {
        PinboardParticipantsDialog,
        PinboardTileMenu,
    },
    props: {
        index: {required: false, type: Number},
        item: {required: true, type: Object},
        updatePinboard: {required: true, type: Function},
        seen: {required: true}, // type: Object
        closeCreateDialog: {required: true, type: Function}
    },
    data: () => ({
        isEditing: false,
        editedNameValue: null,
        showParticipantsDialog: false,

        moderator: false,

        // Icons
        groupIcon,
        icons:[
            {icon: pinIconWeiss, text: "pinIconWeiss"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIconWeiss, text: "politikIcon"},
            {icon: erdkundeIconWeiss, text: "erdkundeIcon"},
            {icon: religionIconWeiss, text: "religionIcon"},
            {icon: bioIconWeiss, text: "bioIcon"},
            {icon: chemieIconWeiss, text: "chemieIcon"},
            {icon: deutschIconWeiss, text: "deutschIcon"},
            {icon: geschichteIconWeiss, text: "geschichteIcon"},
            {icon: matheIconWeiss, text: "matheIcon"},
            {icon: musikIconWeiss, text: "musikIcon"},
            {icon: kunstIconWeiss, text: "kunstIcon"},
            {icon: physikIconWeiss, text: "physikIcon"},
            {icon: sportIconWeiss, text: "sportIcon"},
            {icon: wirtschaftIconWeiss, text: "wirtschaftIcon"},
        ],

        openMenu: false,

    }),
    computed: {
        ...mapGetters('auth', ['accountRole', 'accountId']),
        ...mapState('util', ['windowWidth']),
    },
    async mounted() {
        this.account = await this.getCurrentAccount();
        if (this.item.uploadedName) {
            this.editedNameValue = this.item.uploadedName;
        }
        this.pinboardMembershipRole(this.item);
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('util', ['insertLastOpenedInEditor']),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions('pinboard', ['markPinboardAsSeen', 'clearThumbnails', 'toggleOpenPinboardLibrary']),

        async openPinboard(item) {
            const title = item.title;
            const id = item._id;
            const color = item.color;
            const icon = item.icon;
            const uploadedIcon = item.uploadedIcon;
            this.$router.push({
                name: 'pinboardbase',
                query:
                    {
                        title: title,
                        id: id,
                        color: color,
                        icon: icon,
                        uploadedIcon: uploadedIcon,
                    }
            })
            this.toggleOpenPinboardLibrary(true);
            await this.markPinboardAsSeen( { pinboardId: item._id, accountId: this.account._id } );
            this.clearThumbnails();
        },

        openParticipantsDialog() {
            this.showParticipantsDialog = true;
        },

        openEdit(item) {
            const title = item.title;
            const pinboard = item.pinboard;
            const id = item._id;
            const group = item.groupName;
            const color = item.fachColor;
            const appointmentId = item.appointmentId;
            this.insertLastOpenedInEditor(id);

            let mode = 'pupil';
            if (this.accountRole === 'teacher') {
                mode = 'creator';
            }
            if (item.displayPupilPinboard) {
                if (this.accountRole === 'teacher') {
                    mode = 'teacher';
                } else if (this.accountRole === 'pupil') {
                    mode = 'viewer';
                }
            }
            if (item.displayCorrectedPinboard) {
                mode = 'viewer';
            }
            this.$router.push({
                name: 'editor',
                params: {mode},
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: title,
                        pId: pinboard,
                        uploadid: id,
                        ug: item.displayPupilPinboard ? 'teacher' : '',
                    }
            });
        },

        handleClickElement() {
            if (!(this.isEditing || this.item.isEditing)) {
                if ((this.type === 'pinboard' || this.type === 'draft') && !this.appointment) {
                    this.$emit('loadFullscreenPreview', this.itemForMenu);
                    return;
                } else if (this.appointment) {
                    this.openEdit(this.item);
                }
                if (this.item.rulesetAppointment) {
                    this.$emit('openAppointment', this.item);
                }
            }
        },

        handleDeleteButtonPress() {
            this.$emit('openDeleteDialog');
        },

        chooseIcon(iconName) {
            for (let i of this.icons) {
                if (i.text === iconName) {
                    return i.icon;
                }
            }
        },

        pinboardMembershipRole(item) {
            const memberships = item.memberships.filter((membership) =>
                membership.accountSet.addressedAccounts.find(
                    (addressedAccount) => addressedAccount === this.account._id
                )
            );

            const isModerator = memberships.find(
                (membership) => membership.role === "moderator"
            );
            if (isModerator) {
                this.moderator = Boolean(isModerator.role = "moderator");
            } else {
                return false;
            }
            return this.moderator;
        }
    }
}
</script>

<style scoped lang="scss">
.pinboardSystemTile {
    display: flex;
    border-radius: 4px;
    color: var(--v-schwarz-base);
    width: 200px;
    white-space: nowrap;
    padding-right: 20px;
}

.thumbnailContainer {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: var(--v-dunkelgrau-base);
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
    margin-right: 20px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

// Needs to be square
.thumbnailDisplayNormal {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: white !important;
    border-radius: 4px;
}

.thumbnailDisplayCustom {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: white !important;
    border-radius: 4px;
}

@media only screen and (max-width: 900px) {
    .pinIcon {
        font-size: xx-large;
    }
}

.pinIcon {
    font-size: xxx-large;
}

.participantPinboardButtonPosition {
    bottom: 45px;
    right: 25px;
}

.participantPinboardButton {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    background-color: transparent;
    position: absolute;
    pointer-events: all;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11),
    0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11),
    0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11),
    0 32px 32px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
}

.pinboardIconOverlay {
    //background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.3) inset;
    min-width: 200px;
    min-height: 200px;
    border-radius: 4px;
    position: absolute;
}

</style>
