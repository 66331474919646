import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const defaultState = {
    showDialogGDPR: false,
    advSigned: false,
};

const mutations = {
    setShowDialogGDPR(state, bool) {
        state.showDialogGDPR = bool;
    },
    setADVSigned(state, bool) {
        state.advSigned = bool;
    },
};

const getters = {
    showDialogGDPR: state => state.showDialogGDPR,
    getADVSigned: state => state.advSigned,
}
const actions = {
    async getIsADVSigned({ commit, dispatch }, params) {
        try {
            const res = await backend.isAVVSigned();
            await checkResponseStatus(200, res);
            let response = await res.json();
            commit('setADVSigned', response.isAVVSigned);
            return response.isAVVSigned
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    }
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
};
