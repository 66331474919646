<template>
    <div
        :class="UI_tablet ? 'SupportContainerMobile' : 'SupportContainer'"
    >
        <!-- Header -->
        <div
            :style="supportChat ? 'padding-bottom: 17px' : 'padding-bottom: 5px; justify-content: space-between'"
            :class="supportChat ? 'd-flex align-center justify-center' : 'd-flex pl-4'"
        >
            <v-btn
                v-if="supportChat"
                style="position: absolute; left: 12px; top: 20px"
                text
                @click="supportChat = false"
            >
                <img
                    :src="arrowIcon"
                    class="icon"
                    style="transform: rotate(90deg)"
                    alt="Zurück"
                >
            </v-btn>
            <div
                :style="unreadTopic.length?'cursor:pointer':''"
                @click="jumpToUnread(currentIndex)"
            >
                <v-badge
                    :value="supportChat&& count(allTopics)>0"
                    :content=" count(allTopics)"
                    color="#ff0000"
                    left
                    top
                    style="margin-top:20px;"
                >
                    <p 
                        v-if="supportChat"
                        style="font-size: x-large;"
                    >
                        Chathilfe
                    </p>
                    <div 
                        v-else
                        style="display: flex; flex-direction: column"
                    >
                        <span style="font-size: x-large; color: red;">
                            Erste-Hilfe-Leitfaden
                        </span>
                        <span class="mt-2">
                            Störungen einfach selbst beheben
                        </span>
                    </div>
                </v-badge>
            </div>
            <v-btn
                style="min-width: 32px !important"
                class="ma-0 pa-0"
                text
                aria-label="Hilfe schließen"
                @click="setSupportPageOpen(false)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <div
                v-if="role === 'moderator'"
                style="position: absolute; left: 20px; top: 20px;cursor:pointer;"
                @click="supportChat= false;"
            >
                <v-icon>mdi-face-agent</v-icon>
                <p>Moderator</p>
            </div>
        </div>
        <!-- Default View of Topics and Menus -->
        <div
            v-if="!chatView"
            style="display: flex; flex-direction: column; flex-grow: 1; overflow-x: hidden;margin-right:10px; max-height: 78%"
            :style="UI_tablet ? 'max-height: 78%' : ''"
        >
            <!-- Bottom Toolbar -->
            <div style="display: flex; flex-direction: column; z-index: 2; background-color: white; padding-bottom: 20px">
                <div v-if="supportChat">
                    <!-- User Input/Search -->
                    <input
                        v-model="searchInput"
                        type="text"
                        style="
                    margin-top: 20px;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-bottom: 10px;
                    background-color: #f5f5f5;
                    height: 48px;
                    width: calc(100% - 20px);
                    padding: 8px;
                    border-radius: 4px;
                    box-shadow: #00000069 1px 2px 3px 0;"
                        placeholder="Welches Problem haben Sie?"
                    >
                    <!-- All Entries -->
                    <div style="padding-left: 20px; flex-grow: 1;">
                        <vue-custom-scrollbar
                            class="scroll-area"
                            :settings="settings"
                        >
                            <div v-if="myTopics.length > 0 && searchInput.length === 0">
                                <p style="font-size: medium; margin-top: 20px">
                                    {{ 'Meine Fragen (' + myTopics.length + ')' }}
                                </p>
                                <div style="margin-bottom: 10px" />
                                <!-- DIV: Open MyTopics -->
                                <div
                                    v-for="(el, index) in myTopics"
                                    :key="index + 'myTopics'"
                                    class="py-1"
                                >
                                    <v-btn
                                        style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                        :ripple="{ center: true }"
                                        @click="openSupportChatTopic(myTopics, index)"
                                    >
                                        <v-icon>mdi-chat-outline</v-icon>
                                        <div
                                            style="display: flex; flex-direction: column; align-items: flex-start;"
                                        >
                                            <p
                                                class="text-none"
                                                style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                            >
                                                {{ el.title }}
                                            </p>
                                            <div
                                                style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                            >
                                                <p
                                                    class="text-capitalize"
                                                    style="font-size: small"
                                                >
                                                    {{ el.messagesLength + ' Beiträge' }}
                                                </p>
                                                <p
                                                    v-if="el.unreadMessageCounter > 0"
                                                    class="text-none"
                                                    style="font-size: small; color: red; margin-left: 5px"
                                                >
                                                    {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                                </p>
                                            </div>
                                        </div>
                                    </v-btn>
                                </div>
                            </div>
                            <div v-if="searchInput.length === 0">
                                <p
                                    style="font-size: medium;"
                                >
                                    {{ allTopics.length > 0 ? 'Alle Einträge (' + allTopics.length + ')' : '' }}
                                </p>

                                <div style="margin-bottom: 10px" />
                                <!-- DIV: open from All Topics -->
                                <div
                                    v-for="(el, index) in allTopics"
                                    :key="index + 'allTopics'"
                                    :ref="'topic' +el._id"
                                    class="py-1"
                                >
                                    <v-badge
                                        :value="el.unreadMessageCounter > 0"
                                        :content="el.unreadMessageCounter"
                                        left
                                        overlap
                                        color="#ff0000"
                                        style="margin-left:10px;"
                                    >
                                        <v-btn
                                            block
                                            style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100vw;"
                                            :ripple="{ center: true }"
                                            @click="openSupportChatTopic(allTopics, index)"
                                        >
                                            <v-icon>mdi-chat-outline</v-icon>
                                            <div
                                                style="display: flex; flex-direction: column; align-items: flex-start;"
                                            >
                                                <span
                                                    class="text-none"
                                                    style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                                >
                                                    {{ el.title }}
                                                </span>
                                                <div
                                                    style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                                >
                                                    <p
                                                        class="text-capitalize"
                                                        style="font-size: small"
                                                    >
                                                        {{ el.messagesLength + ' Beiträge' }}
                                                    </p>
                                                    <p
                                                        v-if="el.unreadMessageCounter > 0"
                                                        class="text-none"
                                                        style="font-size: small; color: red; margin-left: 5px"
                                                    >
                                                        {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-btn>
                                    </v-badge>
                                </div>
                            </div>
                            <div v-else>
                                <p
                                    style="font-size: medium; margin-top: 20px"
                                >
                                    {{ similarTopics.length > 0 ? 'Passende Einträge (' + similarTopics.length + ')' : '' }}
                                </p>
                                <div style="margin-bottom: 10px" />
                                <!-- DIV: open from Similar Topics -->
                                <div
                                    v-for="(el, index) in similarTopics"
                                    :key="index + 'similarTopics'"
                                    class="py-1"
                                >
                                    <v-btn
                                        style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                        :ripple="{ center: true }"
                                        @click="openSupportChatTopic(similarTopics, index)"
                                    >
                                        <v-icon>mdi-chat-outline</v-icon>
                                        <div style="display: flex; flex-direction: column; align-items: flex-start;">
                                            <p
                                                class="text-none"
                                                style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                            >
                                                {{ el.title }}
                                            </p>
                                            <div
                                                style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                            >
                                                <p
                                                    class="text-capitalize"
                                                    style="font-size: small"
                                                >
                                                    {{ el.messagesLength + ' Beiträge' }}
                                                </p>
                                                <p
                                                    v-if="el.unreadMessageCounter > 0"
                                                    class="text-none"
                                                    style="font-size: small; color: red; margin-left: 5px"
                                                >
                                                    {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                                </p>
                                            </div>
                                        </div>
                                    </v-btn>
                                </div>
                                <!-- DIV: Create new Topic -->
                                <v-btn
                                    v-if="searchInput.length > 0"
                                    style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                    :ripple="{ center: true }"
                                    @click="createSupportChatTopic(searchInput)"
                                >
                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                    <p
                                        class="text-none"
                                        style="margin-left: 20px; font-size: medium"
                                    >
                                        Neuer Chat zu diesem Thema
                                    </p>
                                </v-btn>
                                <div style="height: 100px" />
                            </div>
                        </vue-custom-scrollbar>
                    </div>
                </div>
                <div v-else>
                    <div style="display: flex; flex-direction: column; gap: 12px; margin-top: 20px;margin-left: 20px; padding-right: 20px; width:100%;">
                        <v-carousel
                            key="helpCarousel"
                            v-model="carouselModel"
                            hide-delimiters
                            :show-arrows="false"
                            color="#282F44"
                            height="100%"
                        >
                            <v-carousel-item>
                                <p>
                                    Störungen in eklara können oft schnell durch einfache Einstellungen und Softwareupdates an Ihrem Gerät gelöst werden.
                                </p>
                                <p class="mt-6">
                                    <strong> Bei Funktionsstörungen</strong> können folgende Maßnahmen helfen:
                                </p>
                                <ol class="mt-2">
                                    <li>
                                        <p>
                                            <strong>Seite aktualisieren</strong> oder Browser beenden und neu starten.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong><a
                                                href="https://simpliby.com/browser-cache-leeren-alles-was-sie-wissen-muessen/"
                                                target="_blank"
                                            >Löschen</a></strong> Sie in Ihren Browsereinstellungen <strong>gespeicherte Browser-, Webseitendaten, Cookies</strong> und/oder leeren Sie Ihren <strong>Cache</strong>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong><a
                                                href="https://praxistipps.chip.de/browser-aktualisieren-so-gehts_10027"
                                                target="_blank"
                                            >Aktualisieren</a></strong> Sie Ihren <strong>Browser/Betriebssystem</strong> auf den neuesten Stand.
                                        </p>
                                    </li>
                                </ol>
                                <p class="mt-6">
                                    Stellen Sie sicher, dass während der Nutzung von eklara eine <strong>stabile Internetverbindung</strong> vorliegt
                                    <br>Unterstützte Browser: <strong>Safari, Chrome und Firefox</strong>
                                </p>
                            </v-carousel-item>
                            <v-carousel-item>
                                <p>
                                    Foto-, Video-, <strong>Sprachaufnahmen, die Mikrofon- und Kamerafreigabe, die Bildschirmübertragung</strong> 
                                    oder der Download von Dateien funktionieren nicht oder Sie erhalten keine Mitteilungen?
                                </p>
                                <p class="mt-6">
                                    Folgende Maßnahmen können helfen:
                                </p>
                                <ol class="mt-2">
                                    <li>
                                        <p>
                                            Gehen Sie zu Ihren <strong><a
                                                href="https://soethe.net/bbb-browser-berechtigungen-korrigieren/"
                                                target="_blank"
                                            >Geräte- und Browsereinstellungen</a></strong> und geben Sie den 
                                            <strong>Zugriff auf Ihr Mikrofon, Ihre Kamera und Bildschirmaufnahme frei</strong>. 
                                            Hier könnnen auch Mitteilungen und Downloads zugelassen werden.
                                        </p>
                                    </li>
                                </ol>
                                <p class="mt-6">
                                    Sollten Sie bspw. AdBlock, <strong>Browser-Schutz-Plugins, Pop-ups, Werbeblocker oder ähnliche Erweiterungen</strong> nutzen, 
                                    so sind auch hier jegliche <strong>Zugriffsrechte freizugeben</strong> oder Services auszuschalten.
                                </p>
                            </v-carousel-item>
                            <v-carousel-item>
                                <p>
                                    Sie haben einen <strong>eingeschränkten Zugriff und es treten vermehrt Störungen in eklara innerhalb Ihres Schulstandorts und -internets auf?</strong>
                                </p>
                                <p class="mt-6">
                                    Folgende Maßnahmen können helfen:
                                </p>
                                <ol class="mt-2">
                                    <li>
                                        <p>
                                            <strong>Kontaktieren Sie Ihren IT- und Internet-Administrator</strong> (oft Träger/IT-Systemanbieter) mit der Bitte um Prüfung und
                                            <strong>Freigabe möglicher Zugriffsrechte, Firewall-Einstellungen</strong> seinerseits zur Nutzung von eklara.
                                        </p>
                                    </li>
                                </ol>
                                <p class="mt-6">
                                    Sollten alle genannten Maßnahmen nicht geholfen haben, so hilft Ihnen gerne persönlichunser Hilfe-Team weiter.
                                </p>
                                <div 
                                    style="display: flex; flex-direction: column;"
                                    class="mt-6"
                                >
                                    <v-btn
                                        v-if="currentRole !== 'parent'"
                                        text
                                        dark
                                        class="darkgreyBG d-flex"
                                        style="justify-content: flex-start"
                                        width="13rem"
                                        @click="() => { toggleFaq(); setSupportPageOpen(false) }"
                                    >
                                        <img
                                            :src="tippsTutorialsIcon"
                                            alt
                                            height="25"
                                        >
                                        <p
                                            class="ml-2 ma-0 text-capitalize"
                                            style="font-size: large"
                                        >
                                            Tipps, Tutorial
                                        </p>
                                    </v-btn>
                                    <!-- <v-badge
                                        :value="count(allTopics)>0"
                                        :content="count(allTopics)"
                                        color="#ff0000"
                                        overlap
                                        left
                                        class="mt-2"
                                    >
                                        <v-btn
                                            text
                                            dark
                                            class="darkgreyBG d-flex"
                                            style="justify-content: flex-start"
                                            width="13rem"
                                            @click="supportChat = true"
                                        >
                                            <img
                                                :src="blogForumIcon"
                                                alt=""
                                                height="25"
                                            >
                                            <p
                                                class="ml-2 ma-0 text-capitalize"
                                                style="font-size: large"
                                            >
                                                Blog & Forum
                                            </p>
                                        </v-btn>
                                        <v-btn
                                            v-if="currentRole !== 'parent' && currentRole !== 'pupil'"
                                            color="gruen"
                                            width="13rem"
                                            elevation="0"
                                            dark
                                            class="mt-2 d-flex"
                                            style="justify-content: flex-start"
                                            @click="openVideosupport()"
                                        >
                                            <img
                                                :src="videoIcon"
                                                class="iconToWhite"
                                                height="25"
                                                alt=""
                                            >
                                            <p
                                                class="ml-2 ma-0 text-capitalize"
                                                style="font-size: large"
                                            >
                                                Videohilfe
                                            </p>
                                        </v-btn>
                                    </v-badge> -->
                                    <b style="font-size: 18px">
                                        Support-Kontakt
                                    </b>
                                    <div
                                        style="font-size: 17px"
                                        class="d-flex flex-row justify-start align-center mb-1"
                                    >
                                        <img
                                            :src="threemaIcon"
                                            class="icon30 mr-1"
                                            alt="Threema"
                                            style="border-radius: 8px; cursor: pointer;"
                                            @click="openLink('https://threema.id/K6AMJJ3A')"
                                        > 
                                        Threema Id: 
                                        <a
                                            href="https://threema.id/K6AMJJ3A"
                                            target="_blank"
                                            class="ml-1"
                                        >  K6AMJJ3A </a>
                                    </div>
                                    <div
                                        style="font-size: 17px"
                                        class="d-flex flex-row justify-start align-center mb-1"
                                    >
                                        <img
                                            :src="telegramIcon"
                                            class="icon30 mr-1"
                                            alt="Telegram"
                                            style="border-radius: 8px; cursor: pointer;"
                                            @click="openLink('https://t.me/eklara_betreuung')"
                                        > 
                                        Telegram: 
                                        <a
                                            class="ml-1"
                                            href="https://t.me/eklara_betreuung"
                                            target="_blank"
                                        > @eklara_betreuung</a>
                                    </div>
                                    <div
                                        class="d-flex flex-row justify-start align-center"
                                        style="font-size: 17px"
                                    >
                                        <img
                                            :src="whatsappIcon"
                                            class="icon30 mr-1"
                                            alt="Whatsapp"
                                            style="border-radius: 8px; cursor: pointer;"
                                            @click="openLink('https://wa.me/4915110695401')"
                                        > 
                                        Whatsapp: 
                                        <a
                                            class="ml-1"
                                            href="https://wa.me/4915110695401"
                                            target="_blank"
                                        > +49 1511 069 540 1 </a>
                                    </div>
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                        <div style="display: flex; justify-content: space-between; align-items: center; position: absolute; bottom: 30px; width: 90%; align-self: center">
                            <v-btn
                                text
                                @click="carouselModel = Math.min(carouselModel - 1, 1)"
                            >
                                <p
                                    class="text-capitalize"
                                    style="font-size: large"
                                >
                                    Zurück
                                </p>
                            </v-btn>
                            <p>
                                {{ (carouselModel + 1) + ' / 3' }}
                            </p>
                            <v-btn
                                text
                                @click="carouselModel = Math.min(carouselModel + 1, 2)"
                            >
                                <p
                                    class="text-capitalize"
                                    style="font-size: large"
                                >
                                    Weiter
                                </p>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Live Chat Window -->
        <div
            v-else-if="chatView"
            style="flex-grow: 1; overflow-x: hidden;"
        >
            <!-- Top Navigation Pane -->
            <div
                class="greyBG"
                style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-top: 5px; padding-bottom: 5px;"
            >
                <v-btn
                    text
                    style="margin-right: 5px; margin-left: 5px"
                    @click="chatView = false; leaveSupportChatTopic()"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <p>{{ chatLog.title }}</p>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            style="margin-right: 5px"
                            v-on="on"
                            @click="deleteSupportChatTopic(chatLog.title)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Diskussion löschen</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            style="margin-right: 5px"
                            v-on="on"
                        >
                            <v-icon>mdi-eye-off</v-icon>
                        </v-btn>
                    </template>
                    <span>Die Frage ist für Moderatoren sichtbar</span>
                </v-tooltip>
            </div>
            <!-- Chat Messages -->
            <vue-custom-scrollbar
                id="ChatLogContainer"
                class="scroll-area-chat"
                :settings="settings"
            >
                <v-spacer style="margin-top: 10px" />
                <div
                    v-for="(message, index) in chatLog.messages"
                    :key="index + 'message'"
                    :ref="'chatMessageRef-' + index"
                    style="display: flex; flex-direction: column"
                >
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; margin-left: 5%; margin-right: 5%"
                    >
                        <div
                            style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px"
                        >
                            <div v-if="role === 'moderator'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-if="message.role === 'schoolAccount' || message.role === undefined"
                                            x-large
                                            v-on="on"
                                        >
                                            mdi-account-circle
                                        </v-icon>
                                    </template>
                                    <span>{{ message.authorPK ? message.authorPK : 'Missing Identifier' }}</span>
                                </v-tooltip>
                            </div>
                            <v-icon
                                v-else-if="message.role === 'schoolAccount' || message.role === undefined"
                                x-large
                            >
                                mdi-account-circle
                            </v-icon>
                            <v-icon
                                v-if="message.role === 'moderator'"
                                x-large
                            >
                                mdi-face-agent
                            </v-icon>
                            <p style="font-size: large; margin-left: 10px">
                                {{ message.author }}
                            </p>
                        </div>
                        <p>{{ message.timestamp }}</p>
                    </div>
                    <div
                        style="display: flex; flex-direction: row; margin-left: 5%; margin-right: 5%; margin-bottom: 5%"
                    >
                        <p
                            class="greyBG pa-3"
                            style="border-radius: 3px"
                            :class="message.role === 'moderator' ? 'moderatorBG' : ''"
                        >
                            {{ message.content }}
                        </p>
                    </div>
                </div>
            </vue-custom-scrollbar>
            <!-- Bottom Input Area -->
            <div
                class="greyBG"
                style="position: absolute; bottom: 0; width: 100%"
            >
                <div style="display: flex; flex-direction: row">
                    <v-text-field
                        v-model="messageInput"
                        rounded
                        placeholder="Nachricht eingeben..."
                        height="20px"
                        style="margin: 10px; background-color: white"
                        @keypress.enter="sendMessage()"
                    />
                    <div
                        v-if="messageInput.length === 0 && false"
                        style="display: flex; flex-direction: row; align-items: center"
                    >
                        <v-btn
                            text
                            style="margin-right: 5px"
                        >
                            <v-icon>mdi-paperclip</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            style="margin-right: 10px"
                        >
                            <v-icon>mdi-microphone</v-icon>
                        </v-btn>
                    </div>
                    <div
                        v-else
                        style="display: flex; flex-direction: row; align-items: center"
                    >
                        <v-btn
                            text
                            style="margin-right: 10px"
                            @click="sendMessage()"
                        >
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import tutorialIcon from "@/assets/Icons/redesignV3/whiteboard.svg";
import tippsTutorialsIcon from "@/assets/Icons/tipps_tutorials.svg";
import videoIcon from '@/assets/Icons/video.svg';
import arrowIcon from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import vueCustomScrollbar from "vue-custom-scrollbar";
import blogForumIcon from "@/assets/Icons/blog_forum.svg";
import threemaIcon from "@/assets/Icons/FAQ/Threema_Logo_Kontakt.svg"
import telegramIcon from "@/assets/Icons/FAQ/Telegram_Logo_Kontakt.svg"
import whatsappIcon from "@/assets/Icons/FAQ/Whatsapp_Logo_Kontakt.svg"
import io from "socket.io-client";
import * as backend from "@/api/backend";

export default {
    name: "SupportChat",
    components: {
        vueCustomScrollbar,
    },
    data() {
        return {
            person: {},
            author: '',
            role: 'schoolAccount',
            currentRole: null,

            allTopics: [],
            myTopics: [],
            similarTopics: [],
            unreadTopic: [],
            currentIndex: 0,
            currentOpen: '',

            today: new Date(),

            // state management
            chatView: false,
            insideAsyncRoom: false,
            connected: false,

            // ui size
            screenX: 0,
            screenY: 0,
            UI_tablet: false,

            // chat
            socket: null,
            chatLog: {
                title: 'Topic',
                messages: [],
            },
            messageInput: '',

            searchInput: '',

            // scrollbar
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },

            supportChat: false,

            carouselModel: 0,

            // icons
            tutorialIcon,
            tippsTutorialsIcon,
            videoIcon,
            arrowIcon,
            blogForumIcon,
            threemaIcon, 
            telegramIcon,
            whatsappIcon,
        };
    },
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
    },
    watch: {
        searchInput: function (newVal, oldVal) {
            if (this.allTopics.length > 0) {
                this.refreshSearchList();
            }
        },
    },
    async mounted() {
        this.evalScreenSize();
        // todo nice to have: figure out common low-end iphone resolution and implement UI_phone
        if (this.screenY <= 900 || this.screenX <= 900) {
            this.UI_tablet = true;
        }
        this.currentRole = await this.accountRole();
        if (this.currentRole === 'teacher') {
            this.person = await this.getMeTeacher();
        }
        // maintainer information
        let backendAddress;
        if (this.currentRole === 'maintainer') {
            backendAddress = await backend.getBackend();
            backendAddress = await backendAddress.json();
        }
        // how to gain moderator status: add property 'supportChatModerator: true' on teacherModel
        if (this.person.supportChatModerator) {
            this.author = 'EKLARA Support';
            this.authorPK = this.author; // same PK -> share unread Msg counter among team (used in case of moderator)
            this.role = 'moderator';
        } else if (this.currentRole === 'maintainer') {
            this.author = 'Sekretariat';
            this.authorPK = 'sekretariat@' + backendAddress;
            this.role = 'schoolAccount';
        }
        else {
            this.author = this.person.name.charAt(0) + '. ' + this.person.lastName;
            this.authorPK = this.person.account + '@' + this.person.school;
            this.role = 'schoolAccount';
        }
        this.socket = io("https://backend.dr1.eklara.de", {
            withCredentials: true,
            extraHeaders: {
                'author-pk': this.authorPK,
            },
            path: '/supportChat',
        });
        this.messageInput = '';
        this.socket.on('connect', () => {
            this.chatView = false;
            this.connected = true;
            this.socket.onAny((event, ...args) => {
                // console.log(`Recieved event ${event}: ${args}`);
                switch (event) {
                    case 'message':
                        this.appendMessage(args);
                        break;
                    case 'allTopics':
                        this.allTopics = args[0];
                        // console.log(this.allTopics);
                        this.allTopics = this.allTopics.map((topic) => {
                            topic.unreadMessageCounter = topic.messagesLength;
                            topic.seenBy.forEach((client) => {
                                if (client.authorPK === this.authorPK) {
                                    topic.unreadMessageCounter = topic.messagesLength - client.counter;
                                }
                            })
                            // console.log('unreadMessageCounter: '+topic.unreadMessageCounter)
                            return topic;
                        })
                        this.myTopics = [];
                        // eslint-disable-next-line no-case-declarations
                        let leaveRoom = true;
                        this.allTopics.forEach((el) => {
                            if (el.authorPK === this.authorPK) {
                                this.myTopics.push(el);
                            }
                            if (this.chatView) {
                                if (this.chatLog.title === el.title) {
                                    leaveRoom = false;
                                }
                            }
                            if(el.unreadMessageCounter>0){
                                this.unreadTopic.push(el);
                            }
                        });
                        // currently open room was not found in refreshed List of Topics
                        // therefore it has to be deleted (catch this in proper event when rewriting)
                        if (this.chatView && leaveRoom && !this.insideAsyncRoom) {
                            this.chatView = false;
                            this.showSnackbar({
                                message: 'Der Chat wurde gelöscht.'
                            })
                        }
                        // Bandaid-Change: Due to negative Appearance, public chat topics have been hidden, only your myTopics visible
                        // the moderator needs to see all topics
                        if (!this.person.supportChatModerator) {
                            this.allTopics = [];
                        }
                        // ensure newly created room can be found everywhere to avoid confusion
                        this.refreshSearchList();
                        this.insideAsyncRoom = false;
                        break;
                    case 'chatLog':
                        // console.log(args[0].messages);
                        this.chatLog.messages = args[0].messages;
                        break;
                }
            });
            this.refreshLoop();
        });
        this.socket.on('disconnect', () => {
            this.chatView = false;
            this.connected = false;
            this.socket.removeAllListeners();
            this.socket.offAny(); // possibly redundant with the above
        });
    },
    async unmounted() {
        this.connected = false;
        this.socket.removeAllListeners();
        this.socket.offAny(); // possibly redundant with the above
    },
    methods: {
        ...mapMutations("support", ["setSupportPageOpen"]),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions("faq", ["toggleFaq"]),
        ...mapActions("teachers", ["getMeTeacher"]),
        ...mapGetters('auth',[ 'accountRole' ]),

        openLink(link) { 
            window.open(link)
        },
        openSupportChatTopic(list, index) {
            this.chatView = true;
            this.chatLog.title = list[index].title;
            this.socket.emit('join', this.chatLog.title);
            this.currentOpen = list[index]._id;
        },
        count(el) {
            let returnValue = 0;

        el.forEach((element) => {
            returnValue += element.unreadMessageCounter;
        });

            return returnValue;
        },
        jumpToUnread(){
            const topic= this.unreadTopic[this.currentIndex];
            const element = this.$refs[`topic${topic._id}`][0];
            // scroll element into view
            element.scrollIntoView();
            this.currentIndex++;
            if (this.currentIndex === this.unreadTopic.length) {
                this.currentIndex = 0;
            }

        },
        leaveSupportChatTopic() {
            this.socket.emit('leave', this.chatLog.title);
            this.insideAsyncRoom = false;
            //remove Topic from unread List
            this.unreadTopic=this.unreadTopic.filter((topic)=>{
                topic._id !== this.currentOpen;
            });
            this.refresh();
        },
        openVideosupport() {
             //window.location.href = "https://schule.eklara.de/join-external/6321de55a6cabb001959c46f?token=goh1fq0mgper";
             window.open("https://schule.eklara.de/join-external/6321de55a6cabb001959c46f?token=goh1fq0mgper").focus();
        },
        createSupportChatTopic(searchInput) {
            this.insideAsyncRoom = true;
            this.socket.emit('create', {
                author: this.author,
                searchInput: this.searchInput,
            });
            this.chatView = true;
            this.chatLog = {
                title: searchInput,
                messages: [
                ],
            };
        },

        deleteSupportChatTopic(title) {
            this.socket.emit('delete', {
                title: title,
            })
            this.chatView = false;
        },

        sendMessage() {
            let timestamp = this.today.getDate() + '.' + (this.today.getMonth() + 1) + '.' + this.today.getFullYear();
            this.socket.emit('message', {
                topic: this.chatLog.title,
                content: this.messageInput,
                timestamp: timestamp,
                author: this.author,
                authorPK: this.authorPK,
                role: this.role,
            });
            this.appendMessageSelf(this.messageInput, timestamp);
            this.messageInput = '';
        },

        appendMessage(args) {
            this.chatLog.messages.push({
                author: args[0][0].author,
                authorPK: args[0][0].authorPK,
                role: args[0][0].role,
                timestamp: args[0][0].timestamp,
                content: args[0][0].content,
            });
        },

        appendMessageSelf(args, timestamp) {
            this.chatLog.messages.push({
                author: this.author,
                role: this.role,
                timestamp: timestamp,
                content: args,
            });
        },

        refreshSearchList() {
            this.similarTopics = this.searchTopics(this.allTopics).slice(0, 5);
        },

        refresh() {
            this.socket.emit('refresh');
        },

        refreshLoop() {
            if (this.connected) {
                this.refresh();
                setTimeout(this.refreshLoop, 60000);
            } else {
                return;
            }
        },

        searchTopics(allTopics) {

            // if left empty, reset to alphabetical sorting
            if (this.searchInput === '') {
                // sort alphabetically, descending
                return allTopics.sort((a, b) => a.title.localeCompare(b.title));
            }

            function editDistance(s1, s2) {
                s1 = s1.toLowerCase();
                s2 = s2.toLowerCase();

                var costs = new Array();
                for (var i = 0; i <= s1.length; i++) {
                    var lastValue = i;
                    for (var j = 0; j <= s2.length; j++) {
                        if (i == 0)
                            costs[j] = j;
                        else {
                            if (j > 0) {
                                var newValue = costs[j - 1];
                                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                                    newValue = Math.min(Math.min(newValue, lastValue),
                                        costs[j]) + 1;
                                costs[j - 1] = lastValue;
                                lastValue = newValue;
                            }
                        }
                    }
                    if (i > 0)
                        costs[s2.length] = lastValue;
                }
                return costs[s2.length];
            }

            function similarity(s1, s2) {
                let longer = s1;
                let shorter = s2;
                if (s1.length < s2.length) {
                    longer = s2;
                    shorter = s1;
                }
                let longerLength = longer.length;
                if (longerLength === 0) {
                    return 1.0;
                }
                return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
            }
            // generate similarity of every item in loaded library
            for (let topic of allTopics) {
                topic.similarity = similarity(topic.title, this.searchInput);
            }
            // sort by descending similarity
            return allTopics.sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
        },

    },
}
</script>
<style scoped>
.SupportContainer {
    width: max(35vw, 300px);
    height: calc(100vh - 74px);
    background-color: white;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 74px;

    display: flex;
    flex-direction: column;
    overflow: auto;
}

.SupportContainerMobile {
    width: max(50vw, 300px);
    height: calc(100vh);
    background-color: white;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    overflow: auto;
}

.greyBG {
    background-color: var(--v-hellgrau-base);
}

.darkgreyBG {
    background-color: var(--v-dunkelgrau-base);
}

.moderatorBG {
    background-color: #48cfe5cc;
}

.scroll-area {
    position: relative;
    max-height: calc(80vh - 75px);
}


.scroll-area-chat {
    position: relative;
    max-height: calc(80vh);
}

.icon {
    width: 20px;
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToDarkBlue {
    filter:
        brightness(0)
        saturate(100%)
        invert(15%)
        sepia(34%)
        saturate(669%)
        hue-rotate(187deg)
        brightness(91%)
        contrast(89%)
    ;
}
</style>
