import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const state = {
  thumbnails: new Map(),
};

const getters = {
    getThumbnail: state => (fileId) => {
        const foundThumbnail = state.thumbnails.get(fileId);
    }
};
const actions = {
    async getCalendarFileThumbnail({commit, dispatch}, params) {
        const foundThumbnail = state.thumbnails.get(params.fileId);
        if (foundThumbnail) {
            return foundThumbnail;
        }
        try {
            const res = await backend.getCalendarFileThumbnail(params.fileId, params.fileAccessToken, params.calendarId);
            return res.blob();
        } catch (e) {
            console.error(e);
            // return empty blob url
            const blob = new Blob([], { type: 'image/png' });
            return {
                type: 'image/png',
                link: window.URL.createObjectURL(blob)};
        }
    },

    async createNewCalendarEvent({commit, dispatch}, params) {
        try {
            const res = await backend.postNewCalenderEvent(params.data);
            await checkResponseStatus(201, res);
            const resJs = await res.json();
            resJs.status = res.status;

            if (params.files.length > 0) {
                await Promise.all(params.files.map(async (el) => {
                    const fileRes = await backend.postNewCalendarEventFile(resJs._id, el);
                    await checkResponseStatus(201, fileRes);
                }));
            }
            return resJs;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async createNewRepeatingEvent({commit, dispatch}, params) {
        try {
            const res = await backend.postNewRepeatingEvent(params.events);
            await checkResponseStatus(201, res);
            const resJs = await res.json();
            resJs.status = res.status;

            if (params.files.length > 0) {
                await Promise.all(params.files.map(async (el) => {
                    const fileRes = await backend.postNewRepeatingEventFile(resJs[0].serie, el);
                    await checkResponseStatus(201, fileRes);
                }));
            }
            return resJs;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async createNewReminder({commit, dispatch}, params) {
        try {
            const res = await backend.createCalendarReminder(params);
            await checkResponseStatus(201, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.repsonse?.status;
        }
    },

    async editCalendarEvent({commit, dispatch}, params) {
        try {
            const id = params.data._id;
            delete params.data._id;
            const deletedFiles = params.deletedFiles;
            const newFiles = params.newFiles;
            params = params.data;
            const res = await backend.patchCalendarEvent(id, params);
            await (checkResponseStatus(200, res));

            if (deletedFiles.length > 0) {
                await Promise.all(deletedFiles.map(async (file) => {
                    const delRes = await backend.deleteEventFile(id, file);
                    await checkResponseStatus(200, delRes);
                }));
            }

            if (newFiles.length > 0) {
                await Promise.all(newFiles.map(async (file) => {
                    const upRes = await backend.postNewCalendarEventFile(id, file);
                    await checkResponseStatus(201, upRes);
                }));
            }

            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editRepeatingEvent({commit, dispatch}, data) {
        try {
            const res = await backend.patchRepeatingEvent(data.serie, data.body);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.repsonse?.status;
        }
    },

    async editRepeatingEventData({commit, dispatch}, data) {
        try {
            const res = await backend.patchRepeatingEventData(data.serie, data.data);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteCalendarEvent({commit, dispatch}, id) {
        try {
            const res = await backend.deleteCalendarEvent(id);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteRepeatingEvent({commit, dispatch}, serie) {
        try {
            const res = await backend.deleteRepeatingEvent(serie);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteCalendarReminder({commit, dispatch}, eventId) {
        try {
            const res = await backend.deleteCalendarReminder(eventId);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.repsonse?.status;
        }
    },

    async getCalendarEvents ({commit, dispatch}) {
        try {
            const res = await backend.getCalendarEvents();
            await checkResponseStatus(200, res);
            const events = await res.json();
            return events;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getCalendarEventById ({commit, dispatch}, id) {
        try {
            const res = await backend.getCalendarEventById(id);
            await checkResponseStatus(200, res);
            const event = await res.json();
            return event;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getCalendarEventsFromSerie ({commit, dispatch}, serie) {
        try {
            const res = await backend.getCalendarEventsFromSerie(serie);
            await checkResponseStatus(200, res);
            const events = await res.json();
            return events;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getCalendarEventsForMonth ({commit, dispatch}, month) {
        try {
            const res = await backend.getCalendarEventsForMonth(month);
            await checkResponseStatus(200, res);
            const events = await res.json();
            return events;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getUnseenEventsForUser({commit, dispatch}) {
        try {
            const res = await backend.getUnseenEventsForUser();
            await checkResponseStatus(200, res);
            const unseen = await res.json();
            return unseen;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async setCalenderEventSeen({commit, dispatch}, eventIds) {
        try {
            const res = await backend.setCalenderEventSeen({eventIds});
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.repsonse?.status;
        }
    },
    async getCalendarEventFile({commit, dispatch}, params) {
        try {
            const res = await backend.getCalendarEventFile(params.fileId, params.fileAccessToken);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.repsonse?.status;
        }
    },
    async getCalendarEventFileInfo({commit, dispatch}, params) {
        try {
            const res = await backend.getCalendarEventFileInfo(params.fileId, params.fileAccessToken);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
}

export default {
    namespaced: true,
    actions,
  };
