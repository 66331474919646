<template>
    <div
        v-click-outside="handleOutside"
        class="sidebarContainer"
    >
        <ProfileManagement
            :current-child="currentChild"
            @currentChildChange="emitChildChange"
            @closeDialog="handleClickOutside"
            @saving="profileSaving"
            @extraDialog="stickOrUnstickSidebar"
            @openUsageAgreement="openUsageAgreement"
            @hideEmailBadge="hideBadge"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ProfileManagement from "@/components/ProfileManagement";

import flagDe from '@/assets/Icons/deutsch-59.svg';
import flagFr from '@/assets/Icons/französisch-60.svg';
import flagEn from '@/assets/Icons/englisch-61.svg';

export default {
    name: "Sidebar",
    components: {ProfileManagement},
    props: {
        sidebarOpen: { type: Boolean, required: true, default: false },
        mobile: { type: Boolean, required: false, default: false },
        currentChild: { required: false, type: Object },
    },
    data: () => ({
        language: null,
        languages: [
            {text: 'Deutsch', icon: flagDe},
            {text: 'Englisch', icon: flagFr},
            {text: 'Französisch', icon: flagEn},
        ],
        sidebarStuck: false,
        saving: false,
    }),
    computed: {
        ...mapGetters('avatar', ['getAvatarPickerOpen']),
    },
    mounted() {
        this.language = this.languages[0];
    },
    methods: {
        ...mapActions('auth', ['getCurrentAccount', 'logoutUser', 'getSecurityQuestions']),
        ...mapActions('parents', ['getMeParent']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('school', ['getSchool', 'postSchool']),
        ...mapActions('teachers', ['getMeTeacher', 'editTeacher']),
        ...mapActions('videoTutorial', ['getTutorialProperties']),
        ...mapMutations('snackbar', ['showSnackbar']),

        openUsageAgreement(data){
            this.$emit('openUsageAgreement',data);
        },
        hideBadge(){
            this.$emit('hideBadge');
        },
        stickOrUnstickSidebar(openStatus) {
            this.sidebarStuck = openStatus;
        },

        emitChildChange(child) {
            this.$emit('currentChildChanged', child);
        },

        handleClickOutside() {
            if (this.sidebarOpen && !this.sidebarStuck) {
                this.saving = false;
                this.$emit('closeSidebar');
            }
        },

        profileSaving() {
            this.saving = true;
        },

        handleOutside(event) {
            let pickerOpen = this.getAvatarPickerOpen;
            if (!pickerOpen && !this.sidebarStuck && event.target.classList.contains("v-overlay__scrim") &&!this.saving) {
                this.$emit('closeSidebar');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.sidebarContainer {
    position: absolute;
    right: 0;
    top: 74px;
    width: 31vw;
    height: calc(100vh - 74px);
    background-color: white;
    z-index: 30;
}

.sidebarSection {
    p {
        font-weight: bold;
    }
}

@media only screen and (max-width: 900px) {
    .sidebarContainer {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 70px;
        width: 400px;
        max-width: 100vw;
        height: calc(100vh - 70px);
        z-index: 30;
        padding: 0;
        margin: 0;
    }
}
</style>
