<template>
    <!-- teacher -->
    <div>
        <v-btn
            v-if="big"
            class="pa-0"
            style="text-transform: inherit; height: 40px;"
            block
            elevation="0"
            @click.stop.prevent="areYouSureLeaveChatRoom"
        >
            <div
                class="d-flex align-center"
                style="width: 100%"
            >
                <img
                    :src="verlassenIcon"
                    alt="Chat verlassen"
                    class="iconToRed mx-2"
                    style="height: 15px;"
                >
                <p
                    class="mr-auto"
                    style="color: red"
                >
                    Chat verlassen
                </p>
            </div>
        </v-btn>
        <v-btn
            v-else
            id="verlassenBtn"
            icon
            @click.stop.prevent="areYouSureLeaveChatRoom"
        >
            <img
                :src="verlassenIcon"
                alt="Chat verlassen"
            >
        </v-btn>
        <AreYouSurePopup
            v-if="showAreYouSure"
            :show-dialog="showAreYouSure"
            :content-text-prop="areYouSureText"
            :header-color="'var(--v-chatColor-base)'"
            :cancel-button-text="'Zurück'"
            :ok-button-text="'Verlassen'"
            :ok-btn-color="'#e6231e'"
            :ok-button-icon="''"
            @close="showAreYouSure = false"
            @ok="leaveChatRoom"
        />
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

import verlassenIcon from "../../assets/Icons/beitretensvg-06.svg";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";

export default {
    name: "LeaveChatRoomButton",
      components: {
        AreYouSurePopup,
        // BadgeExplanations,
    },
    props: {
        chatRoom: { required: true, type: Object },
        big: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        verlassenIcon,
        showAreYouSure: false,
        areYouSureText: 'Wollen Sie diesen Chat wirklich endgültig verlassen?'
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("translation", ["targetLang"]),
        ...mapState("util", ["windowWidth"]),
    },

    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),

        async leaveChatRoom() {
            this.$emit("leaveChatRoom");
        },
        areYouSureLeaveChatRoom(){
            this.showAreYouSure = true;
        }
    },
};
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

#verlassenBtn {
    img {
        width: 24px;
        height: 24px;
        filter: brightness(1000%);
    }
}
</style>
