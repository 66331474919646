import { render, staticRenderFns } from "./LibraryApp.vue?vue&type=template&id=05a395bc&scoped=true&"
import script from "./LibraryApp.vue?vue&type=script&lang=js&"
export * from "./LibraryApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a395bc",
  null
  
)

export default component.exports