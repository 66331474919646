var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"outerDiv",staticClass:"video-container",style:(`min-width: ${_vm.primaryMinWidth}px; min-height: ${_vm.primaryMinHeight}px; ${_vm.pinboardSettings ? '' : 'width: ' + _vm.primaryVideoWidth + 'px;'} ${_vm.pinboardSettings ? '' : 'height: ' + _vm.primaryVideoHeight + 'px;'}  resize: both; border: 2px solid grey; padding: 10px; overflow: auto; background-color: #000`),attrs:{"tabindex":"0"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;_vm.$refs.outerVideo.paused ? _vm.$refs.outerVideo.play() : _vm.$refs.outerVideo.pause()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;_vm.$refs.outerVideo.currentTime += 1},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;_vm.$refs.outerVideo.currentTime -= 1}]}},[(_vm.primaryVideoSrc)?_c('video',{ref:"outerVideo",attrs:{"id":"outer-video","playsinline":"","poster":_vm.videoPoster,"controls":""},on:{"play":_vm.onPlay,"pause":_vm.onPause,"seeked":_vm.timeChanged}},[_c('source',{attrs:{"src":_vm.primaryVideoSrc}}),(_vm.subtitleSrc)?_c('track',{attrs:{"default":"","label":"Deutsch","kind":"subtitles","srclang":"de","src":_vm.subtitleSrc}}):_vm._e(),_vm._v(" Ihr Browser unterstützt dieses Video leider nicht, bitte benutzen Sie einen anderen Browser. ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondaryVideoSrc),expression:"secondaryVideoSrc"}],ref:"innerDiv",style:({
                'position': 'absolute',
                'min-width': _vm.secondaryMinWidth + 'px',
                'min-height': _vm.secondaryMinHeight + 'px',
                'width': _vm.secondaryVideoWidth + 'px',
                'height': _vm.secondaryVideoHeight + 'px',
                'resize': 'both',
                'overflow' : 'auto',
                'background-color': 'rgba(10,10,10,1)',
                'left': _vm.innerVideoX + 'px',
                'top': _vm.innerVideoY + 'px'
            }),attrs:{"id":"innerDiv"},on:{"mouseup":_vm.resizeEnd}},[_c('div',{ref:"innerMovingDiv",attrs:{"id":"innerMovingDiv"}},[(_vm.secondaryVideoSrc)?_c('video',{ref:"innerVideo",attrs:{"id":"inner-video","muted":"","poster":_vm.videoPoster,"playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.secondaryVideoSrc}}),_vm._v(" Ihr Browser unterstützt dieses Video leider nicht, bitte benutzen Sie einen anderen Brwoser. ")]):_vm._e()])])]),(_vm.error.length > 0)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('img',{staticStyle:{"height":"15px"},attrs:{"src":_vm.infoIcon,"alt":""}})]),_c('v-col',[_c('div',{staticClass:"error--text",domProps:{"textContent":_vm._s(_vm.error)}})])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }