<template>
    <div>
        <v-menu
            v-if="windowWidth > 900"
            attach="#roomBtn"
            offset-y
            class="px-0 mx-0"
            style="background-color: white; z-index: 10"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    id="roomBtn"
                    style="padding: 8px"
                >
                    <v-btn
                        v-if="windowWidth > 900"
                        color="gruen"
                        elevation="0"
                        class="text-none px-2"
                        style="height: 50px; border-radius: 8px;"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img
                            :src="videoIcon"
                            height="25"
                            alt="Videoraum auswählen"
                        >
                        <p
                            style="font-size: large"
                            class="pl-2 white--text"
                        >
                            Videoräume
                        </p>
                    </v-btn>
                </div>
            </template>

            <div
                class="d-flex flex-column justify-space-between align-start pa-2"
                style="background-color: var(--v-headerblue-base); gap: 8px"
            >
                <v-btn
                    v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                    class="joinBtn d-flex px-2"
                    style="width: 100%; justify-content: flex-start"
                    color="gruen"
                    elevation="0"
                    @click="joinTeachersBBBRoom"
                >
                    <img
                        :src="videoIcon"
                        class="mr-2"
                        alt="Lehrerzimmer beitreten"
                    >
                    <p>Lehrer</p>
                </v-btn>

                <!--#region Classroom -->
                <div
                    v-if="accountRole === 'teacher'"
                    class="closableRoomRow"
                >
                    <v-btn
                        class="joinBtn px-2"
                        style="justify-content: flex-start !important; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                        :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
                        elevation="0"
                        @click="joinKlassenraum(teacherClassroomId)"
                    >
                        <img
                            :src="videoIcon"
                            class="mr-2"
                            :alt="teacherClassroomIsOpen ? 'Klassenraum geöffnet' : 'Klassenraum geschlossen'"
                        >
                        <p>Schüler</p>
                    </v-btn>

                    <v-btn
                        class="joinBtn lockBtn"
                        :color="teacherClassroomIsOpen ? 'gruen' : 'fgrau'"
                        elevation="0"
                        :small="windowWidth <= 900 && windowWidth > 770"
                        :x-small="windowWidth <= 770"
                        @click="toggleKlassenraum"
                    >
                        <img
                            :src="lockIcon"
                            :alt="teacherClassroomIsOpen ? 'Raum geöffnet' : 'Raum geschlossen'"
                        >
                    </v-btn>
                </div>
                <!--#endregion-->

                <!--#region Parent room -->
                <div
                    v-if="accountRole === 'teacher'"
                    class="closableRoomRow"
                >
                    <v-btn
                        class="joinBtn px-2"
                        style="justify-content: flex-start !important; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                        :color="roomIsOpen ? 'gruen' : 'fgrau'"
                        elevation="0"
                        @click="joinRoom(teacherRoomId)"
                    >
                        <img
                            :src="videoIcon"
                            class="mr-2"
                            alt="Videokamera"
                        >
                        <p>Eltern</p>
                    </v-btn>

                    <v-btn
                        :color="roomIsOpen ? 'gruen' : 'fgrau'"
                        class="joinBtn lockBtn"
                        elevation="0"
                        :small="windowWidth <= 900 && windowWidth > 770"
                        :x-small="windowWidth <= 770"
                        @click="toggleRoom"
                    >
                        <img
                            :src="lockIcon"
                            :alt="roomIsOpen ? 'Raum geöffnet' : 'Raum geschlossen'"
                        >
                    </v-btn>
                </div>
                <!--#endregion -->

                <v-btn
                    v-if="accountRole === 'maintainer' || accountRole === 'teacher'"
                    class="joinBtn d-flex px-2"
                    style="width: 100%; justify-content: flex-start"
                    color="gruen"
                    elevation="0"
                    @click="clickExternalButton"
                >
                    <img
                        :src="videoIcon"
                        class="mr-2"
                        alt="Externe Räume-Dialog öffnen"
                    >
                    <p>Extern</p>
                </v-btn>
            </div>
        </v-menu>

        <JoinExternalDialog
            v-if="dialogActive"
            @closeDialog="closeDialog"
        />

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import videoIcon from '@/assets/Icons/video.svg';
import lockIcon from '@/assets/Icons/lock.svg';

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from "@/api/backend";

import VideoTutorial from '@/components/Tutorial/VideoTutorial.vue';
import JoinExternalDialog from "@/components/JoinExternal/JoinExternalDialog";

export default {
    name: "BBBDropdown",
    components: {
        JoinExternalDialog,
        VideoTutorial,
    },
    data: () => ({
        videoIcon,
        lockIcon,

        // External
        dialogActive: false,

        // Classroom
        teacherClassroomId: '',
        teacherClassroomIsOpen: null,

        // Parent room
        teacherRoomId: '',
        roomIsOpen: null,

        videoTutorial: false,
        showVideoTutorial: false,           // set to true when button is clicked
        videoTutorialClosed: false,         // set to false when VideoTutorial is closed
        videoTutorialCloseAction: null,     // what method to execute
        videoTutorialOnCloseJoinRoomId: '',
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('parents', ['currentChild']),
    },
    mounted() {
        this.videoTutorial = (this.accountRole === 'teacher' || this.accountRole === 'maintainer')
            ? this.videoTutorialsByFilename['Tutorial_bbb_Lehrer_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_bbb_Eltern_Schueler.mp4'];

        this.getTeacherBbbRoom();
        this.getTeacherRoom();
    },
    methods: {
        ...mapActions('rooms', [ 'teacherClassroom', 'changeConferenceRoomStatus', 'changeRoomStatus', 'getMyConferenceTeacher', 'joinClassroom', 'joinConferenceRoom' ]),
        ...mapActions('util', ['toggleLoading']),
        ...mapMutations('snackbar', ['showSnackbar']),

        async clickExternalButton() {
            this.dialogActive = true;
        },

        closeDialog() {
            this.dialogActive = false;
        },

        async joinBbb(externalBbbRoom) {
            let res;
            if (this.accountRole === 'parent') {
                res = await backend.getExternalBbbRoomJoinAsModeratorUrl(
                    externalBbbRoom._id,
                    this.currentChild._id,
                );
            } else {
                res = await backend.getExternalBbbRoomJoinAsModeratorUrl(
                    externalBbbRoom._id
                );
            }
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();

            if (res.status === 409) {
                this.showSnackbar({message: "Aktuell ist der Raum geschlossen!", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec join external room again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinExternalRoom = externalBbbRoom;
                this.videoTutorialCloseAction = 'externalRoom';
                return;
            }
            window.location.href = redirectUrl;
            this.toggleLoading(false);
        },
        //#endregion

        //#region Classroom methods
        async joinKlassenraum(id) {
            this.toggleLoading(true);

            const response = await this.joinClassroom(id);
            if(this.accountRole === 'teacher') {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if(!response.redirectUrl) {
                    this.showSnackbar({message: "Dieser Klassenraum ist geschlossen.", color: "fgrau"});
                    this.toggleLoading(false);
                    return;
                }
            }

            if(!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Um den Klassenraum beitreten zu können, müssen Sie ihn erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinRoomId = id;
                this.videoTutorialCloseAction = 'classroom';
                return;
            }
            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },

        async toggleKlassenraum() {
            let newStatus = !this.teacherClassroomIsOpen;
            let data = {
                classroomId: this.teacherClassroomId,
                newStatus: newStatus,
            }
            let res = await this.changeRoomStatus(data);

            if(res) {   // if successful also change the local one
                this.teacherClassroomIsOpen = res.isOpen;

                // and show snackbar
                if (this.teacherClassroomIsOpen) {
                    this.showSnackbar({message: "Dieser Klassenraum ist nun geöffnet.", color: "success"});
                } else {
                    this.showSnackbar({message: "Der Klassenraum wurde geschlossen.", color: "success"});
                }
            }
        },
        //#endregion

        //#region Parent room
        async getTeacherRoom() {
            if (this.accountRole === 'teacher') {
                let room = await this.getMyConferenceTeacher();
                this.teacherRoomId = room._id;
                this.roomIsOpen = room.isOpen;
            }
        },

        async toggleRoom() {
            let newStatus = !this.roomIsOpen;
            let data = {
                classroomId: this.teacherRoomId,
                newStatus: newStatus,
            }
            let res = await this.changeConferenceRoomStatus(data);

            if (res) {   // if successful also change the local one
                this.roomIsOpen = res.isOpen;

                // and show snackbar
                if (this.roomIsOpen) {
                    this.showSnackbar({ message: "Das Sprechzimmer ist nun geöffnet.", color: "success" });
                } else {
                    this.showSnackbar({ message: "Das Sprechzimmer wurde geschlossen.", color: "success" });
                }
            }
        },

        async joinRoom(id) {
            this.toggleLoading(true);

            const response = await this.joinConferenceRoom({ roomId: id, childrenId: this.accountRole === 'parent' ? this.currentChild._id : ''});
            if (this.accountRole === 'teacher') {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Um das Sprechzimmer betreten zu können, müssen Sie es erst öffnen. Dazu müssen Sie das Schloss anklicken.", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            } else {
                if (!response.redirectUrl) {
                    this.showSnackbar({message: "Das Sprechzimmer ist geschlossen!", color: 'fgrau'});
                    this.toggleLoading(false);
                    return;
                }
            }

            if (!response || response === 422 || response === 404) {
                this.showSnackbar({message: "Das Sprechzimmer konnte nicht geladen werden - Bitte aktualisieren Sie die Seite!", color: 'error'});
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinRoomId = id;
                this.videoTutorialCloseAction = 'parentRoom';
                return;
            }

            window.location.href = response.redirectUrl;
            this.toggleLoading(false);
        },
        //#endregion

        async getTeacherBbbRoom() {
            if (this.accountRole === 'teacher') {
                let room = await this.teacherClassroom();
                this.teacherClassroomId = room._id;
                this.teacherClassroomIsOpen = room.isOpen;
            }
        },

        async joinTeachersBBBRoom() {
            this.toggleLoading(true);
            const response = await backend.joinTeachersRoomBbbMeetingUrl();

            if (!response.status || response.status === 422 || response.status === 404) {
                this.showSnackbar({ message: 'Das Lehrerzimmer konnte nicht geladen werden. Bitte aktualisieren Sie die Seite!', color: 'error' });
                this.toggleLoading(false);
                return;
            }
            else if (response.status === 409) {
                this.showSnackbar({ message: 'Das Lehrerzimmer ist aktuell geschlossen!', color: 'error' });
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinTeachersBBBRoom again.
                this.showVideoTutorial = true;
                this.videoTutorialCloseAction = 'teachersRoom';
                return;
            }

            const { redirectUrl } = await response.json();
            window.location.href = redirectUrl;
            this.toggleLoading(false);
        },

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;

            switch (this.videoTutorialCloseAction) {
                case 'teachersRoom':
                    this.joinTeachersBBBRoom();
                    return;
                case 'externalRoom':
                    this.joinBbb(this.videoTutorialOnCloseJoinExternalRoom);
                    return;
                case 'classroom':
                    this.joinKlassenraum(this.videoTutorialOnCloseJoinRoomId);
                    return;
                case 'parentRoom':
                    this.joinRoom(this.videoTutorialOnCloseJoinRoomId);
                    return;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.joinBtn {
    color: white;
    text-transform: inherit;
    height: 50px !important;;
    border-radius: 8px;

    img {
        height: 25px;
        filter: brightness(1000%);
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

.lockBtn {
    height: 50px;
    min-width: 50px;
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-style: solid;
    border-color: rgba(0, 0, 0, 0.33) !important;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

.videoIconPopup {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.boardIconDialog {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.input {
    display: block;
    width: 200px;
    border-radius: 5px;
    border-width: 0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance: none;
}

.createRoomButton {
    color: white;
    margin-left: auto;
}

.externalRoomItem {
    border-width: 1px;
    border-bottom-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.button {
    height: 36px;
    min-width: 36px !important;
    width: 38px;
    box-shadow: none;

    img {
        height: 20px !important;
        width: 20px !important;
        margin: 0 !important;
        filter: brightness(1000%);
    }
}

@media only screen and (max-width: 900px) {
    .joinBtn {
        height: 40px !important;
        padding: 8px;
        margin-left: 8px;

        p {
            font-size: small;
        }

        img {
            height: 20px !important;
        }
    }

    .lockBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

// When change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .joinBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 104px;
    }

    .lockBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

        img {
            margin: 0 !important;
        }
    }
}

.closableRoomRow {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .joinBtn {
        width: 70% !important;
    }

    .lockBtn {
        width: 28% !important;
        min-width: 28% !important;
    }
}
</style>
