<template>
    <div style="visibility: hidden">
        <!-- <div style="display: flex; height: 500px; width: 500px">
    <v-col> -->
        <!-- <span> {{ 'lastClicked: '+clicked.messageType+' '+clicked.text }} </span> <br/> -->
        <!-- <span> {{ pairs }} </span> <br/> -->
        <!-- <span> {{ pairedElements }} </span> <br/>
      <span> {{ selectedElement.messageType+','+pairingEdit+','+pairingComponentIndex }} </span> -->
    <!-- </v-col>
  </div> -->
    </div>
</template>

<script>
// empty/invisible component that handles some logic of pairing to prevent
// clutter of EditorBaseComponent. Other logic sits in TaskMenu of Sidebar(deprecated), EditorElement, EditorBaseComponent
// no central saving because of ._uid to ._id conversion
// see end of EditorBaseComponent.safeAllEditorMessages() for actual Save

import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import papierkorbIcon from "../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";
import moveIcon from '@/assets/Icons/Temp/move.svg';
import eyeIconClosed from "../../assets/Icons/eye-off.svg";
import incorrectIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import correctIcon from "../../assets/Icons/done-round.svg";
import eyeIconOpen from "../../assets/Icons/eye.svg";
import settingsIcon from "../../assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import dropDownIcon from "../../assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";

export default {
    name: 'Pairing',
    props: {
        config: { type: Object, required: true},
        state: { type: String, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        elementIndex: { type: Number, required: true },
    },
    data() {
        return {
          // most recently clicked Element
          clicked : {},

          // state managment
          removePairing : false,
          // if the handling of the element should be performed on el1 or el2
          handleFirst: false,

          // schema
          pairs: [{
            el1: null,
            el2: null,
          }],

          pupilPairs: [{
            el1: null,
            el2: null,
          }],

          // // state===pupil only:
          // // when pupil is editing, this serves as a copy of the original pairing state (teacher selection)
          // // during save, we return pairs -> pupilPairs, then originalPairs -> pairs
          // originalPairs: [{}],
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapGetters("pairing", ["selectedElement", "notify", "pairedElements", "pupilElements", "teacherElements", "pairingEdit", "pairingComponentIndex"]),
    },
    watch: {
        notify: {
            handler(val, oldVal) {
              
              if(this.clicked === this.selectedElement) {
                // repeated selection, flag for removal
                this.removePairing = true;
              }
              
              this.clicked = this.selectedElement;
              
              this.clicked = this.standardize(this.clicked);
              
              this.handlePairing(this.clicked);
            },
        },
    },
    mounted() {
        this.resetPairing();
        this.setSpawnedPairing(true);
        this.setPairingEdit(true);
        this.setPairingComponentIndex(this.elementIndex);
        // load from saved config
        
        if(this.config.pairingConfig || this.state === 'pupil') {
          if(this.config.pairingConfig.done) {
            this.setPairingDone(this.config.pairingConfig.done);
          }
          if(this.config.pairingConfig.pairedElements) {
            this.setPairedElements(this.config.pairingConfig.pairedElements);
          } else {
            // case: null load / bad load
            // consider self-deleting component if this case happens
            // means something went wrong during saving/initializing and
            // there is no work to be done for pupil
            this.setPairedElements([{}]);
          }
          this.pairs = this.pairedElements;
          // trim possible empty object inserts
          for(let i=0; i<=this.config.pairingConfig.pupilElements.length-1; i++) {
            if (Object.keys(this.config.pairingConfig.pupilElements[i]).length === 0) {
              this.config.pairingConfig.pupilElements.splice(i,1);
              i--;
            }
          }
          this.setPupilElements(this.config.pairingConfig.pupilElements);
          this.pupilPairs = this.pupilElements;
          if(this.config.pairingConfig.done) {
            // case: correction View, swap pupil and teacher Elements so that initial View shows pupils submission
            this.swapPairedLists();
          }
          // case: pupil wants to build their pairings, we use this.pairs for their selection
          if (this.state === 'pupil') {
            this.setTeacherElements(this.pairs);
            this.pairs = this.pupilPairs;
            this.setPairedElements(this.pairs);
          }
        } else {
          // case: clean start
          this.setPairedElements([{}]);
          this.setSelected(null);
          this.pairs = this.pairedElements;
          this.pupilPairs = [{}];
        }
        // bandaid fix: sometimes the pupilElements is lead by empty entry
        if(this.pupilElements[0]) {
            if(Object.keys(this.pupilElements[0]).length === 0) {
                this.pupilElements.shift();
            }
        }
    },
    methods: {
        ...mapMutations("pairing", ["setSelected", "setPairedElements", "setSpawnedPairing", "setPairingComponentIndex", "setPupilElements", "setTeacherElements", "setPairingEdit", "setPairingDone", "swapPairedLists", "resetPairing"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        handlePairing(el) {
          el = { messageType: el.messageType, text: el.text, _id: el._id, _uid: el._uid};
          if(el._id && el._uid) {
            delete el._uid;
          }
          const index = this.getIndex(el);
          if(!this.removePairing) {
            
            // append el
            if(this.handleFirst) {
              this.pairs[index].el1 = el;
            } else {
              this.pairs[index].el2 = el;
            }
          } else {
            
            // remove el
            if(this.handleFirst) {
              this.pairs[index].el1 = null;
            } else {
              this.pairs[index].el2 = null;
            }
          }
          // save to Store
          this.setPairedElements(this.pairs);
        },

        // returns Index 'first occurence of incomplete pair' || flags element for removal if it exists
        getIndex(el) {
          let index = null;
          this.removePairing = false;
          for(let i=0; i<this.pairs.length; i++) {
            if (index === null) {
              if (this.pairs[i].el1 === null) {
                index = i;
                this.handleFirst = true;
              } else
              if (this.pairs[i].el2 === null) {
                index = i;
                this.handleFirst = false;
              }
            }
            if (this.compare(this.pairs[i].el1,el)) {
              this.removePairing = true;
              this.handleFirst = true;
              index = i;
            } else if
              (this.compare(this.pairs[i].el2,el)) {
              this.removePairing = true;
              this.handleFirst = false;
              index = i;
            }
          }
          if (index === null) {
            //every pair is complete, push next
            this.pairs.push({el1:null, el2:null});
            this.handleFirst = true;
            return this.pairs.length-1;
          }
          return index;
        },

        // establish shared comparator, then evaluate
        compare(el1, el2) {
          let comp1, comp2 = null;
          if (el1 && el2) {
            if(el1._id)  {
              comp1 = el1._id;
            } else if (el1._uid) {
              comp1 = el1._uid;
            }
            if(el2._id)  {
              comp2 = el2._id;
            } else if (el2._uid) {
              comp2 = el2._uid;
            }
            if (comp1 == comp2 && comp1 !== null && comp2 !== null) {
              return true;
            }
          }
          return false;
        },

        // ensure clicked element holds .text .messageType
        standardize(el) {
          if(el) {
            if(!el.messageType) {
              el.messageType = 'Object';
            }
            if(!el.text) { // todo: grab texts from components props
              el.text = 'Element';
            }
          }
          return el;
        },
    },
};
</script>

<style scoped lang="scss">
.userExerciseAnswerButton {
    float: right;
}

.icon {
    width: 25px;
    height: 25px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

</style>

<style lang="scss">

div.v-input, div.v-input div {
    //height: 90%;
    //min-height: 10px !important;
}
</style>
