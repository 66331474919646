<template>
    <v-row class="actionbar hidden-print">
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLoginSimple"
        >
            Alice
        </v-btn>
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLoginPupil"
        >
            Monika
        </v-btn>
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLoginParent"
        >
            Ben
        </v-btn>
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLoginTeacher"
        >
            Kim
        </v-btn>
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLoginMaintainer"
        >
            Verwaltung
        </v-btn>
        <v-btn
            tabindex="-1"
            aria-hidden="true"
            class="ml-1"
            color="primary"
            x-small
            @click="clickLogoutUser"
        >
            Logout
        </v-btn>
    </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { AuthorizationError, ConnectionRefusedError } from '../../util/error';

// v-if="process.env.NODE_ENV === 'development'"
export default {
    name: "UserSwitch",
    props: {},
    data: () => ({
        password: "example123",
    }),
    computed: {},
    methods: {
        ...mapActions("auth", {
            authLoginUser: "loginUser",
            authChangeStayLoggedIn: "changeStayLoggedIn"
        }),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions('documentRegistry', [ 'postDocumentRegistryDocument' ]),
        async clickLoginTeacher() {
            const loginSuccessful = await this.loginUser({ accountName: "Kim", password: "Kim" });
            if (loginSuccessful) {
                this.$router.replace({ name: 'lehrer.plan', query: this.$route.query });
            }
        },
        async clickLoginMaintainer() {
            const loginSuccessful = await this.loginUser({ accountName: "Verwaltung", password: this.password });
            if (loginSuccessful) {
                this.$router.replace({ name: 'verwaltung.plan', query: this.$route.query });
            }
        },
        async clickLoginPupil() {
            // Logout user before in case user Alice is already logged in an rerendering is not triggered
            if (!this.$route.name.includes('login')) {
                await this.logoutUser();
                this.$router.push({ name: 'login' });
            }
            const loginSuccessful = await this.loginUser({ accountName: "Monika", password: "Monika" });
            if (loginSuccessful) {
                this.$router.replace({ name: 'schueler.plan', query: this.$route.query });
            }
        },
        async clickLoginSimple() {
            // Logout user before in case user Monika is already logged in an rerendering is not triggered
            if (!this.$route.name.includes('login')) {
                await this.logoutUser();
                this.$router.push({ name: 'login' });
            }
            const loginSuccessful = await this.loginUser({ accountName: "Alice", password: "Alice" });
            if (loginSuccessful) {
                this.$router.replace({ name: 'schueler.plan', query: this.$route.query });
            }

        },
        async clickLoginParent() {
            const loginSuccessful = await this.loginUser({ accountName: "Ben", password: "Ben" });
            if (loginSuccessful) {
                this.$router.replace({ name: 'eltern.plan', query: this.$route.query });
            }
        },
        async clickLogoutUser() {
            await this.logoutUser();
            this.$router.push({ name: 'login' });
        },
        async loginUser({accountName, password}) {
            try {
                const loggedInUser = await this.authLoginUser({ accountName, password });

                // login successful
                return true;
            } catch (err) {
                if (err instanceof ConnectionRefusedError) {
                    this.showSnackbar({ message: "Server nicht erreichbar. Haben Sie Internet? ", color: "error" });
                } else if (err instanceof AuthorizationError) {
                    this.showSnackbar({ message: "Kein Nutzer mit diesen Daten gefunden.", color: "error" });
                } else {
                    console.error(err);
                    this.showSnackbar({ message: "Unerwarteter Fehler.", color: "error" });
                }
                return false;
            }
        },
    },
};
</script>
<style scoped>
.actionbar {
    position: fixed;
    bottom: 8px;
    right: 16px;
    z-index: 1;
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    .actionbar {
        bottom: 78px;
    }
}

@media only screen and (max-width: 900px) {
    .actionbar {
        bottom: 75px;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}
</style>
