<template>
    <div
        id="elementOuter"
        ref="elementOuter"
        v-click-outside="elementClicked"
        :element-active="elementActive"
        :class="paired ? 'pairingOutline' : pairingEdit ? !pairingDone ? ((accountRole === 'pupil' && vfx_shake) || accountRole === 'teacher') ? 'shakeMe' : '' : '' : ''"
        :style="paired ? 'box-shadow: 0 0 0 4px '+pairingColor() : 'box-shadow: none !important; color: '+pairingColor()"
        @click="elementClicked"
    >
        <img
            v-if="(accountRole === 'teacher' || element.author === accountId) && moveIconNeeded"
            v-show="!exportMode"
            id="movingIcon"
            ref="movingIcon"
            :src="moveIcon"
            style="cursor: grab;"
            height="25"
            width="25"
            class="editorOptionsFade"
        >
        <div
            style="cursor: pointer"
            @click="setPairingEdit(true)"
        >
            <img
                v-if="paired"
                :src="pairingIcon"
                style="position: absolute; right: 5px; top: 5px"
                :style="'background-color: '+pairingIconColor"
            >
        </div>
        <div
            ref="hoverContainer"
            style="display: flex;"
            :style="`
              height: ${(element.height * canvasHeight)}px;
              width: ${(element.width * canvasWidth)}px;
              resize: ${resizeAble ? 'both !important' : 'none'};
              overflow: ${resizeAble ? 'hidden' : 'visible'}`"
            @mouseup="resize"
        >
            <!-- List of HTML Editor Elements, is differentiated over messageType -->
            <EditorAnswerField
                v-if="element.messageType === 'answerField'"
                :key="element.text"
                :answer-entry="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorAnswerFieldPlus
                v-if="element.messageType === 'answerFieldPlus'"
                :key="element.text"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :element-on-current-page="elementOnCurrentPage"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorMoveableText
                v-if="element.messageType === 'moveableText'"
                :text="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @removeElement="removeElement"
            />
            <EditorDragnDrop
                v-if="element.messageType === 'dragndrop'"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :export-mode="exportMode"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :element-on-current-page="elementOnCurrentPage"
                @removeElement="removeElement"
            />
            <EditorDragnDropFree
                v-if="element.messageType === 'dragndropfree'"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :export-mode="exportMode"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @removeElement="removeElement"
            />
            <EditorDragnDropFreeTarget
                v-if="element.messageType === 'dragndropfreetarget'"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :export-mode="exportMode"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :element-on-current-page="elementOnCurrentPage"
                @removeElement="removeElement"
            />
            <EditorImageObject
                v-if="element.messageType === 'imageObject'"
                :element="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasHeight"
                :element-on-current-page="elementOnCurrentPage"
                @removeElement="removeElement"
                @resizeInStages="resizeInStages"
            />
            <EditorVideoObject
                v-if="element.messageType === 'videoObject'"
                :element="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasHeight"
                @removeElement="removeElement"
                @resizeInStages="resizeInStages"
            />
            <EditorAudioMessage
                v-if="element.messageType === 'audio'"
                :key="
                    element.startFile
                        ? element.startFile.toString()
                        : JSON.stringify(element)
                "
                :upload-message="element"
                :index="index"
                :mode="mode"
                :preview-pupil-u-i="previewPupilUI"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
                @recordingMade="audioMessageRecordingMade"
            />
            <EditorTextMessage
                v-if="element.messageType === 'text'"
                :message="element"
                :index="index"
                :mode="mode"
                :preview-pupil-u-i="previewPupilUI"
                @removeElement="removeElement"
            />
            <EditorRectangle
                v-if="element.messageType === 'rectangle'"
                :rect="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @removeElement="removeElement"
            />
            <EditorLine
                v-if="element.messageType === 'line'"
                :line="element"
                :mode="mode"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :export-mode="exportMode"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorTextTask
                v-if="element.messageType === 'texttask'"
                ref="editorTextTaskEl"
                :key="element.startTextTask"
                :task="element"
                :teachers="[]"
                :state="mode === 'creator' || mode === 'teacher' ? 'start' : 'answer'"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorTextTaskCombined
                v-if="element.messageType === 'texttaskcombined'"
                ref="editorTextTaskEl"
                :key="element.startTextTask"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :target-lang="targetLang"
                :element-on-current-page="elementOnCurrentPage"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorMultipleChoice
                v-if="element.messageType === 'multipleChoice'"
                :key="element.width"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :show-results="mode === 'teacher' || mode === 'viewer'"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :export-mode="exportMode"
                :element-on-current-page="elementOnCurrentPage"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />
            <EditorAudioObject
                v-if="element.messageType === 'audioObject'"
                :element="element"
                :canvas-height="canvasHeight"
                :canvas-width="canvasHeight"
                @removeElement="removeElement"
            />
            <EditorCalculation
                v-if="element.messageType === 'calculation'"
                :key="'calculation' + element.width"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasHeight"
                :index="index"
                :export-mode="exportMode"
                :element-on-current-page="elementOnCurrentPage"
                @removeElement="removeElement"
                @history="(originalData) => $emit('history', originalData)"
                @resizeCall="testMethod"
            />
            <EditorCloze
                v-if="element.messageType === 'cloze'"
                :key="element.text"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :export-mode="exportMode"
                :element-on-current-page="elementOnCurrentPage"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />

            <EditorCalculationWall
                v-if="element.messageType === 'wall'"
                :config="element"
                :state="mode"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :export-mode="exportMode"
                :element-on-current-page="elementOnCurrentPage"
                :preview-pupil-u-i="previewPupilUI"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />

            <EditorBuchstabensuppe
                v-if="element.messageType === 'buchstabensuppe'"
                :key="element.text"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                :export-mode="exportMode"
                @history="(originalData) => $emit('history', originalData)"
                @removeElement="removeElement"
            />

            <EditorPairing
                v-if="element.messageType === 'pairing'"
                :key="element.text"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :element-index="index"
                :canvas-height="canvasHeight"
                :canvas-width="canvasWidth"
                @removeElement="removeElement"
            />

            <EditorRowCalculation
                v-if="element.messageType === 'rowCalculation'"
                :key="'rowCalculation' + element.width"
                :config="element"
                :state="mode"
                :preview-pupil-u-i="previewPupilUI"
                :canvas-height="canvasHeight"
                :canvas-width="canvasHeight"
                :index="index"
                :export-mode="exportMode"
                @removeElement="removeElement"
                @history="(originalData) => $emit('history', originalData)"
                @resizeCall="testMethod"
            />
        </div>
        <!-- Edit Icon -->
        <div v-if="element.messageType !== 'pairing'">
            <v-tooltip
                v-if="isEditButtonVisible"
                :disabled="!isEditButtonVisible"
                right
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="isEditButtonVisible"
                        v-show="!exportMode"
                        id="editElementButton"
                        style="width: 48px; height: 48px; min-width: 48px; margin-top: 22px"
                        class="editorOptionsFade"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="edit"
                    >
                        <img
                            :src="editIcon"
                            alt="Bearbeiten des Elements"
                            class="icon"
                        >
                    </v-btn>
                </template>
                <span>Bearbeiten</span>
            </v-tooltip>

            <!-- Delete Button -->
            <v-tooltip
                v-if="isDeleteButtonVisible"
                :disabled="!isDeleteButtonVisible"
                right
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="isDeleteButtonVisible"
                        v-show="!exportMode"
                        id="deleteElementButton"
                        style="width: 48px; height: 48px; min-width: 48px"
                        class="mt-1 editorOptionsFade"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="removeElement"
                    >
                        <img
                            :src="papierkorbIcon"
                            alt="Bearbeiten des Elements"
                            class="icon iconToRed"
                        >
                    </v-btn>
                </template>
                <span>Löschen</span>
            </v-tooltip>
            <!-- change layer/order of element -->
            <div
                v-if="accountRole === 'teacher'"
                v-show="!exportMode"
                id="editIndexContainer"
                class="d-flex ml-2 editorOptionsFade"
                style="height: 35px"
                :style="element.messageType === 'imageObject' ? 'margin-bottom: -78px' : ''"
            >
                <div>
                    {{ index + 1 }}
                </div>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :key="'btnLayerDown' + index"
                                :ripple="false"
                                tabindex="0"
                                aria-label="Das Element eine Ebene nach oben verschieben"
                                text
                                x-small
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="$emit('moveLayerDown')"
                            >
                                <img
                                    :src="arrowDown"
                                    style="transform: rotate(180deg)"
                                >
                            </v-btn>
                        </template>
                        <span>Element eine Ebene nach oben verschieben</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :key="'btnLayerUp' + element._id"
                                :ripple="false"
                                tabindex="0"
                                aria-label="Das Element eine Ebene nach unten verschieben"
                                text
                                x-small
                                class="ml-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="$emit('moveLayerUp')"
                            >
                                <img :src="arrowDown">
                            </v-btn>
                        </template>
                        <span>Element eine Ebene nach unten verschieben</span>
                    </v-tooltip>
                </div>
            </div>
        </div>
        <input
            ref="videoUpload"
            type="file"
            style="display: none"
            hidden
            name="file"
            accept="video/mp4,video/mpeg,video/mov,video/*"
            @change="videoSelected"
        >
        <input
            ref="imgUpload"
            type="file"
            style="display: none"
            hidden
            name="file"
            accept="image/*"
            @change="imgSelected"
        >
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";
import editIcon from "../../assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import moveIcon from "@/assets/Icons/Temp/move.svg";
import pairingIcon from "@/assets/Icons/Pairing-Icon.svg";
import EditorAnswerField from "./EditorAnswerField";
import EditorAnswerFieldPlus from "./EditorAnswerFieldPlus";
import EditorMoveableText from "./EditorMoveableTextAlt";
import EditorDragnDrop from "./EditorDragnDrop";
import EditorDragnDropFree from "@/components/Editortools/EditorDragnDropFree";
import EditorDragnDropFreeTarget from "@/components/Editortools/EditorDragnDropFreeTarget";
import EditorImageObject from "./EditorImageObject";
import EditorAudioMessage from "./EditorAudioMessage";
import EditorRectangle from "./EditorRectangle";
import EditorLine from "./EditorLine";
import EditorTextMessage from "./EditorTextMessage";
import EditorVideoObject from "./EditorVideoObject";
import EditorTextTask from "./EditorTextTask";
import EditorTextTaskCombined from "./EditorTextTaskCombined";
import EditorMultipleChoice from "./EditorMultipleChoice";
import EditorCalculation from "@/components/Editortools/Subjects/Mathe/EditorCalculation";
import EditorCloze from "@/components/Editortools/EditorCloze";
import EditorCalculationWall from "@/components/Editortools/Subjects/Mathe/EditorCalculationWall";
import EditorBuchstabensuppe from "./EditorBuchstabensuppe";
import EditorPairing from "./EditorPairing";
import EditorRowCalculation from "@/components/Editortools/Subjects/Mathe/EditorRowCalculation";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";
import EditorAudioObject from "@/components/Editortools/EditorAudioObject";
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";

export default {
    name: "EditorElement",
    components: {
        // Editor Elements
        EditorAudioObject,
        EditorMultipleChoice,
        EditorTextTask,
        EditorTextTaskCombined,
        EditorVideoObject,
        EditorTextMessage,
        EditorLine,
        EditorRectangle,
        EditorAudioMessage,
        EditorImageObject,
        EditorMoveableText,
        EditorDragnDrop,
        EditorDragnDropFree,
        EditorDragnDropFreeTarget,
        EditorAnswerField,
        EditorAnswerFieldPlus,
        EditorCalculation,
        EditorCloze,
        EditorCalculationWall,
        EditorBuchstabensuppe,
        EditorPairing,
        EditorRowCalculation,
    },
    props: {
        element: {required: true, type: Object},
        currentPage: {required: true, Number},
        canvasHeight: {required: true, type: Number},
        canvasWidth: {required: true, type: Number},

        index: {required: false, type: Number},
        mode: {required: false, type: String, default: "viewer"},
        exportMode: {required: false, type: Boolean, default: false},
        previewPupilUI: { type: Boolean, required: false, default: false },

        // Translation Props
        translate: {required: false, type: Boolean, default: false},
        targetLang: {required: false, type: String, default: "de"},
    },
    data: () => ({
        // Drag and drop
        dragStartX: 0,
        dragStartY: 0,
        oldWidth: 0,
        oldHeight: 0,

        // pairing
        paired: false,
        vfx_shake: false,
        hasEmittedPairingState: false,

        // on each page change this gets reevaluated, used to disable tooltips on inactive pages
        elementOnCurrentPage: null,

        // UI
        moveIcon,
        arrowDown,
        editIcon,
        papierkorbIcon,
        pairingIcon,
        pairingIconColor: '#FFFFFF',
        elementActive: false,
    }),
    computed: {
        ...mapGetters("auth", ['accountRole', 'accountId']),
        ...mapGetters("pairing", ["selectedElement", "notify", "pairingColors", "pairedElements", "pupilElements", "teacherElements", "pairingEdit", "pairingDone", "spawnedPairing"]),

        moveIconNeeded() {
            // Checks if messagetype is planned with a moveIcon in mind
            switch (this.element.messageType) {
                case "answerField":
                case "texttask":
                case "texttaskcombined":
                case "imageObject":
                case "videoObject":
                case "dragndrop":
                case "dragndropfree":
                case "dragndropfreetarget":
                case "multipleChoice":
                case "audioObject":
                case "rectangle":
                case "line":
                case "buchstabensuppe":
                case "answerFieldPlus":
                case "calculation":
                case "cloze":
                case "wall":
                case "text":
                case "audio":
                    return true;
                default:
                    return false;
            }
        },
        resizeAble() {
            if (this.accountRole !== "teacher" || this.element.author !== this.accountId) return false;
            // Checks if messagetype should be resizable (default is true)
            switch (this.element.messageType) {
                case "text":
                case "audio":
                case "moveableText":
                case "audioObject":
                case "calculation":
                case "wall":
                case 'buchstabensuppe':
                case "dragndropfreetarget":
                    return false;
                default:
                    return true;
            }
        },
        isEditButtonVisible() {
            let doesMessageTypeFit = false;
            // Checks if messagetype should be resizable (default is true)
            switch (this.element.messageType) {
                case "moveableText":
                case "imageObject":
                case "videoObject":
                case "audioObject":
                case "texttask":
                    doesMessageTypeFit = true;
                    break;
                default:
                    doesMessageTypeFit = false;
            }

            return (this.accountRole === 'teacher'
                || this.element.author === this.accountId)
                && doesMessageTypeFit && this.elementOnCurrentPage && !this.previewPupilUI;
        },
        isDeleteButtonVisible() {
            return (this.accountRole === 'teacher' || this.element.author === this.accountId) && this.elementOnCurrentPage && !this.previewPupilUI;
        },
        pairingColor() {
            // checks if elements are contained in pairedElement lists, returns their color and toggles the border
            return () => {
                const el = this.element;
                // this.paired = false; //causes infinite loop, probably from watcher
                if (el) {
                    let index = null;
                    for (let i = 0; i < this.pairedElements.length; i++) {
                        if (this.pairedElements[i].el1) {
                            if (this.pairedElements[i].el1._id) {
                                if (el._id == this.pairedElements[i].el1._id) {
                                    index = i;
                                    this.paired = true;
                                    this.emitPairingState(true);
                                }
                            }
                            if (this.pairedElements[i].el1._uid) {
                                if (el._uid == this.pairedElements[i].el1._uid) {
                                    index = i;
                                    this.paired = true;
                                }
                            }
                        }
                        if (this.pairedElements[i].el2) {
                            if (this.pairedElements[i].el2._id) {
                                if (el._id == this.pairedElements[i].el2._id) {
                                    index = i;
                                    this.paired = true;
                                    this.emitPairingState(true);
                                }
                            }
                            if (this.pairedElements[i].el2._uid) {
                                if (el._uid == this.pairedElements[i].el2._uid) {
                                    index = i;
                                    this.paired = true;
                                }
                            }
                        }
                    }
                    for (let i=0; i < this.teacherElements.length; i++) {
                        if (this.teacherElements[i].el1) {
                            if (el._id == this.teacherElements[i].el1._id) {
                                this.emitPairingState(true);
                            }
                        }
                        if (this.teacherElements[i].el2) {
                            if (el._id == this.teacherElements[i].el2._id) {
                                this.emitPairingState(true);
                            }
                        }
                    }
                    if (index == null) {
                        this.paired = false;
                        this.emitPairingState(false);
                        this.pairingIconColor = '#FFFFFF';
                        return 0;
                    }
                    this.pairingIconColor = this.pairingColors[index];
                    return this.pairingColors[index];
                } else {
                    this.paired = false;
                    this.emitPairingState(false);
                    this.pairingIconColor = '#FFFFFF';
                    return 0;
                }
            }
        },
    },
    watch: {
        index(newVal) {
            this.dragElement(
                this.moveIconNeeded ? this.$refs.movingIcon : this.$refs.elementOuter
            );
        },
        currentPage: {
            handler(val, oldVal) {
                this.hasEmittedPairingState = false;
                this.elementOnCurrentPage = this.currentPage === this.element.page;
            }
        },
    },
    mounted() {
        this.dragElement(
            this.moveIconNeeded ? this.$refs.movingIcon : this.$refs.elementOuter
        );
        this.oldWidth = Number.parseFloat(this.element.width);
        this.oldHeight = Number.parseFloat(this.element.height);
        this.init_vfx_shake();
        this.elementOnCurrentPage = this.currentPage === this.element.page;
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapMutations('editorStore', ["removeDragnDropField", 'dropAllDragnDropFields']),
        ...mapMutations("pairing", ["setSelected", "resetPairing", "swapPairedLists", "setPairingEdit"]),

        testMethod(data) {

            // this.$nextTick(() => {
            //   this.element.width = this.element.width || 0.1;
            //
            //   this.$emit("transformChanged", {
            //     width: (this.element.width || 0) + 0.1,
            //   });
            // });
        },

        /**
         * method to check if element was click on or if it was clicked outside
         * spannend, spannend
         */
        elementClicked(event) {
            this.elementActive = false;
            if (event && event.path && event.path.includes(this.$refs.elementOuter)) {
                this.elementActive = true;
                this.$emit('focusMe');
            } else if (!event.path) {
                const path = [];
                let currentElem = event.target;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                if (path.includes(this.$refs.elementOuter)) {
                    this.elementActive = true;
                    this.$emit('focusMe');
                }
            }
        },

        /**
         * transform Methods (Position, Dimension, Rotation)
         * pairing component latches onto the resize event to trigger Element selection
         */
        resize(event) {
            // determine if we should toggle between submission and original pairing view
            if (this.spawnedPairing) {
                if (this.pairingDone) {
                    let issueSwap = false;
                    if (this.element._id !== undefined) {
                        for (let i = 0; i < this.pairedElements.length; i++) {
                            if (this.pairedElements[i].el1) {
                                if (this.pairedElements[i].el1._id === this.element._id) {
                                    issueSwap = true;
                                    break;
                                }
                            }
                            if (this.pairedElements[i].el2) {
                                if (this.pairedElements[i].el2._id === this.element._id) {
                                    issueSwap = true;
                                    break;
                                }
                            }
                        }
                        if (!issueSwap) {
                            for (let i = 0; i < this.pupilElements.length; i++) {
                                if (this.pupilElements[i].el1) {
                                    if (this.pupilElements[i].el1._id === this.element._id) {
                                        issueSwap = true;
                                        break;
                                    }
                                }
                                if (this.pupilElements[i].el2) {
                                    if (this.pupilElements[i].el2._id === this.element._id) {
                                        issueSwap = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (issueSwap) {
                            this.swapPairedLists();
                        }
                    }
                } else {
                    // trigger click -> pairing
                    let selectedElement = this.element;
                    // incase page has not been uploaded, no _ids exist. append vues internal ._uid as placeholder until saving
                    selectedElement._uid = this._uid;
                    this.setSelected(selectedElement);

                }
            }

            if (this.accountRole !== "teacher") return;
            if (event.dataTransfer) {
                this.dragEnd(event);
            }

            this.emitNewTransform();
        },
        resizeInStages(newStage) {
            this.$emit(
                "transformChanged",
                {
                    width: this.oldWidth + (this.oldWidth * (0.4 * newStage)),
                    height: this.oldHeight + (this.oldHeight * (0.4 * newStage)),
                    x: this.element.x,
                    y: this.element.y,
                }
            );
        },

        dragElement(element) {
            let _this = this;
            var pos1 = 0,
                pos2 = 0,
                pos3 = 0,
                pos4 = 0;
            let lastMove = null;
            let isDuplicating = false;
            let moveOnlyX = false;

            if (
                element &&
                element.id &&
                document.getElementById(element.id + "header")
            ) {
                // if present, the header is where you move the DIV from:
                document.getElementById(element.id + "header").onmousedown = dragMouseDown;
                document.getElementById(element.id + "header").ontouchstart = dragMouseDown;
            } else {
                // otherwise, move the DIV from anywhere inside the DIV:
                if (element) {
                    element.onmousedown = dragMouseDown;
                    element.ontouchstart = dragMouseDown;
                }
            }
            _this.dragStartX = Number.parseFloat(_this.element.x);
            _this.dragStartY = Number.parseFloat(_this.element.y);

            function dragMouseDown(e) {
                _this.$emit("focusMe");
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                _this.dragStartX = Number.parseFloat(_this.element.x);
                pos4 = e.clientY;
                _this.dragStartY = Number.parseFloat(_this.element.y);

                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;

                if (e.altKey) {
                    isDuplicating = true;
                    _this.$emit("duplicateElementAltDrag", {
                        x: _this.dragStartX,
                        y: _this.dragStartY,
                    });
                }

                moveOnlyX = e.shiftKey;
            }

            const elementDrag = (e) => {
                e = e || window.event;
                lastMove = e;
                if (e.type !== "touchmove") {
                    e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = e.clientX ? e.clientX : e.touches[0].clientX;
                pos4 = e.clientY ? e.clientY : e.touches[0].clientY;

                // set the element's new position:
                const top =
                    Number.parseFloat(_this.element.y) - (pos2 / _this.canvasHeight) * 100;
                const left =
                    Number.parseFloat(_this.element.x) - (pos1 / _this.canvasWidth) * 100;

                if (left > -10 && left < 110 && top > -10 && top < 110) {
                    _this.element.x = left;
                    if (!moveOnlyX) {
                        _this.element.y = top;
                    }
                }
            };

            const closeDragElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                try {
                    _this.click(
                        e.clientX ? e.clientX : lastMove.touches[0].clientX,
                        e.clientY ? e.clientY : lastMove.touches[0].clientY
                    );
                } catch (e) {
                    console.error(e);
                }
                lastMove = null;

                const eventType = isDuplicating ? 'elementDuplicated' : 'transformChanged';
                _this.emitNewTransform(eventType);
                isDuplicating = false;
                moveOnlyX = false;
            };
        },

        emitNewTransform(event = 'transformChanged') {
            // lets try this
            const width =
                this.$refs.hoverContainer.getBoundingClientRect().width /
                this.canvasWidth;
            const height =
                this.$refs.hoverContainer.getBoundingClientRect().height /
                this.canvasHeight;

            this.$emit(
                event,
                {
                    width,
                    height,
                    x: this.element.x,
                    y: this.element.y,
                },
                {
                    x: this.dragStartX,
                    y: this.dragStartY,
                }
            );

            this.dragStartX = this.element.x;
            this.dragStartY = this.element.y;
        },

        init_vfx_shake() {
            if (this.element._id !== undefined) {
                for (let i = 0; i < this.teacherElements.length; i++) {
                    if (this.teacherElements[i].el1) {
                        if (this.teacherElements[i].el1._id === this.element._id) {
                            this.vfx_shake = true;
                            return;
                        }
                    }
                    if (this.teacherElements[i].el2) {
                        if (this.teacherElements[i].el2._id === this.element._id) {
                            this.vfx_shake = true;
                            return;
                        }
                    }
                }
                this.vfx_shake = false;
                return;
            }
            this.vfx_shake = false;
            return;
        },

        emitPairingState(state) {
            if(!this.hasEmittedPairingState && this.currentPage === this.element.page) {
                this.$emit('pairingState', state);
                this.hasEmittedPairingState = true;
            }
        },

        /**#region element specific methods */
        audioMessageRecordingMade(type, recording) {
            if (type === 'start') {
                this.element.startFile = recording;
            }

            if (type === 'answer') {
                this.element.answerFile = recording;
            }
        },
        /**#endregion */

        /**
         * General Events (remove, add, edit)
         */
        removeElement() {
            if (this.element.messageType === 'pairing') {
                this.resetPairing();
            }
            this.$emit("removeElement", this.element);
            if (this.element.dragndropFreeTargetConfig) {
                this.removeDragnDropField(this.element.dragndropFreeTargetConfig.index);
            }
            if (this.element.dragndropFreeConfig && this.element.dragndropFreeConfig.fields.length) {
                this.dropAllDragnDropFields();
                // Find other Elements and remove them from the editor
                this.$emit('dropAllDragnDropFields');
            }
        },
        edit() {

            switch (this.element.messageType) {
                case "imageObject":
                    this.$refs.imgUpload.click();
                    break;
                case "videoObject":
                    this.$refs.videoUpload.click();
                    break;
                case "texttask":
                    this.$refs.editorTextTaskEl.teacherEditExercise();
                    break;
                default:
                    this.$emit("editElement", this.element);
                    break;
            }
        },
        imgSelected() {
            if (this.$refs.imgUpload.files.length > 0) {
                let file = this.$refs.imgUpload.files[0];
                let url = window.URL.createObjectURL(file);
                this.element.fileUrl = url;
                this.element.fileBlob = file;
                // this.$emit('addImageField', url, file);
                this.$refs.imgUpload.value = "";
            }
        },
        videoSelected() {
            if (this.$refs.videoUpload.files.length > 0) {
                let file = this.$refs.videoUpload.files[0];
                let url = window.URL.createObjectURL(file);
                this.element.fileUrl = url;
                this.element.fileBlob = file;
                //this.$emit('addVideoField', url, file);
                this.$refs.videoUpload.value = "";
            }
        },
    },
};
</script>

<style scoped lang="scss">
#elementOuter {
    -webkit-transform-origin: top left !important; // vllt hilft das ja sogar, sonst hab ich das mal für morgen reingeschrieben :D thx. bb.
    background-color: #00000000;
    display: block;
}

.icon {
    width: 20px;
    height: 20px;
    filter: brightness(0);
}

.pairingOutline {
    // outline-style: solid;
    // outline-width: 5px;
    border-radius: 4px;
    outline: none;
    border: none;
}

#movingIcon {
    position: absolute;
    top: 0;
    left: -30px;
}

#editElementButton {
    position: absolute;
    top: 40px;
    right: -56px;
}

#deleteElementButton {
    position: absolute;
    top: 0;
    right: -56px;

    img {
        width: 25px !important;
        height: 25px !important;
    }
}

#editIndexContainer {
    height: 35px;
    position: absolute;
    bottom: -40px;
    left: 0;
    overflow: visible;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}
</style>
<style lang="scss">
// Used for grey background in all Editor Components
.greyDiv {
    background-color: var(--v-editorGrey-base);
    border-radius: 12px;
}

// Used for border around all Editor Components
.greyDivBorderColor {
    border: solid 3px;
    border-radius: 12px;
    border-color: #b2b2b200 !important;
}

.greyDivBorderColor:hover {
    border: solid 3px;
    border-radius: 12px;
    border-color: var(--v-editorprimary-base) !important;
}

// Used for disabled control buttons in Editor Components
.controlsDisabled {
    cursor: default !important;
    color: rgba(0, 0, 0, 0.3) !important;
}

.elementHidden {
    opacity: 0.4;
}

// Übergangsweise fade Transition für v-if Ausblendungen, die genau wie die fade out transition von .editorOptionsFade wirken soll
.fade-leave-active {
    transition: opacity 1s;
}

.fade-leave-to {
    opacity: 0;
}

// Fade out transitions of all options that should be hidden when unhovered:
// For not displaying an Element
.editorOptionsFade {
    opacity: 0;
    pointer-events: none;
    //transition: 1s ease-out 0.1s;
}

// For hiding the background of an Element
.editorGreyDivFade {
    background-color: transparent;
    //transition: 1s ease-out 0.1s;
}

#elementOuter[element-active=true] {
    & .editorOptionsFade {
        opacity: 1;
        transition: none;
        pointer-events: all;
    }

    & .editorGreyDivFade {
        background-color: var(--v-editorGrey-base);
        transition: none;
    }
}

// Styling der Buttons die rechts rausgeschoben sind
.editorComponentOptionsBtn {
    width: 48px;
    height: 48px !important;
    min-width: 48px !important;
    margin-bottom: 6px;
}

// Font size classes for sizeState in Components
.editorFontSize1 {
    font-size: large;
}

.editorFontSize2 {
    font-size: x-large;
}

.editorFontSize3 {
    font-size: xx-large;
}

// Often used classes for all Editor components to set the primary colors of all Editor components
// (primary color changeable in vuetify.js)

// EditorColorPrimary
.editorColorPrimary {
    color: var(--v-editorprimary-base) !important;
}

.editorColorPrimaryBackground {
    background-color: var(--v-editorprimary-base) !important;
}

.editorColorPrimaryBorderColor {
    border-color: var(--v-editorprimary-base) !important;
}

.editorColorPrimaryBackgroundOnHover:hover {
    background-color: var(--v-editorprimary-base) !important;
}

.editorColorPrimaryBackgroundOnHover:hover .editorColorPrimaryOnHoverChildColorToWhite {
    color: white !important;
}

// Children of Elements with EditorColorPrimary Classes
.editorColorPrimaryBackgroundOnHover:hover .iconToWhiteHover {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

// EditorColorWrong
.editorColorWrong {
    color: var(--v-editorWrong-base);
}

.editorColorWrongBackground {
    background-color: var(--v-editorWrong-base) !important;
}

.editorColorWrongBackgroundOnHover:hover {
    background-color: var(--v-editorWrong-base) !important;
}

.editorColorWrongBackgroundOnHover:hover .editorColorWrongOnHoverChildColorToWhite {
    color: white !important;
}

// EditorColorNeutral
.editorColorNeutral {
    color: var(--v-editorNeutral-base);
}

.editorColorNeutralBackground {
    background-color: var(--v-editorNeutral-base) !important;
}

</style>

<style lang="sass">
// Language needs to be sass to be able to use the random() method

// Iterate over the different text-items to have an individual animation-delay between the items (so they don't shake all at the same time)
@for $i from 1 through 30
    .shakeMe:nth-of-type(#{$i})
        animation: shake 5s linear infinite #{random(4000)}ms
    // Pause the animation when the cursor hovers an item
    .shakeMe:nth-of-type(#{$i}):hover
        -webkit-animation-play-state: paused
        -moz-animation-play-state: paused
        -o-animation-play-state: paused
        animation-play-state: paused

@keyframes shake
    0%
        transform: translate(1px, 0px) rotate(-1deg)
    2%
        transform: translate(-1px, 0px) rotate(-1deg)
    5%
        transform: translate(-3px, 0px) rotate(1deg)
    8%
        transform: translate(3px, 0px) rotate(0deg)
    11%
        transform: translate(0px, 0px) rotate(0deg)
    100%
        transform: translate(0px, 0px) rotate(0deg)

</style>
