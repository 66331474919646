<template>
    <v-dialog
        v-model="show"
        scrollable
        max-width="600px"
        @close="() => { $emit('close') }"
        @click:outside="() => { $emit('close') }"
        @keydown.esc="(e) => { $emit('close'); e.stopPropagation(); }"
    >
        <v-card
            class="cardStyle"
            style="min-height: 80vh; max-height: 80vh; border-radius: 16px"
        >
            <v-card-title 
                class="d-flex justify-space-between"
                style="background-color: var(--v-fileArchiveOrange-base); color: white; position: sticky; top: 0px; z-index: 5"
            >
                <p> {{ isStation ? editMode ? 'Lernstation bearbeiten' : 'Lernstation anlegen' : editMode ? 'Lernpfad bearbeiten' : 'Lernpfad anlegen' }} </p>
                <v-spacer />
                <v-btn
                    color="success"
                    style="text-transform: inherit"
                    @click="startTransaction"
                >
                    <img
                        v-if="editMode"
                        :src="saveIcon"
                        class="iconToWhite"
                        style="height: 15px"
                    >
                    <img
                        v-else
                        :src="plusIcon"
                        class="iconToWhite"
                        style="height: 15px"
                    >
                    <p 
                        v-if="windowWidth > 470"
                        class="ml-2"
                    >
                        {{ editMode ? 'Speichern' : 'Hinzufügen' }}
                    </p>
                </v-btn>
                <v-btn
                    icon
                    style="margin-left: 5%"
                    @click="() => { $emit('close') }"
                >
                    <img
                        :src="closeIcon"
                        class="icon20 iconToWhite"
                        alt="Schließen des Teilen Dialogs"
                        style="width: 30px; height: 30px;"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text style="height: 90%">
                <!-- Content of Dialog -->
                <div style="display: flex; margin-top: 5px">
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }"> -->
                    <div
                        style="cursor: default"
                        v-on="on"
                    >
                        <img
                            :src="deutschIcon"
                            class="iconBtn colored"
                        >
                        <!-- <v-icon
                            style="color: white; position: relative; left: -25px; top: -10px; text-shadow: 2px 2px 4px #00000042;"
                        >
                            mdi-pencil
                        </v-icon> -->
                    </div>
                    <!-- </template>
                        Hier können in Zukunft Farbe und Bild geändert werden
                    </v-tooltip> -->
                    <v-text-field
                        v-model="itemName"
                        :placeholder="isStation ? 'Name der Lernstation' : 'Name des Lernpfads'"
                        filled
                        hide-details
                        style="margin-top: 5px; border-radius: 8px; border-style: none !important"
                    />
                </div>
                <v-expansion-panels 
                    v-model="panel"
                    accordion
                    focusable
                    multiple
                    style="border-radius: 16px"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ isStation ? 'Zugewiesene Nutzer, Gruppen' : 'Nutzer, Gruppen zuweisen' }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div
                                class="d-flex align-center pl-2 pr-2"
                                style="margin-top: 10px"
                            >
                                <img
                                    :src="searchIcon"
                                    alt="Suche"
                                    class="mr-2 ml-1"
                                    style="height: 20px"
                                >

                                <v-text-field
                                    v-model="searchTreeView"
                                    class="ma-0 pt-0 pb-2"
                                    hide-details
                                    filled
                                    dense
                                    clearable
                                    placeholder="Suchen unter Nutzern"
                                />
                            </div>
                            <v-treeview
                                v-model="receiverAccounts"
                                :items="possibleReceivers"
                                item-children="participants"
                                item-key="_id"
                                item-text="displayName"
                                :search="searchTreeView"
                                selected-color="var(--v-chatColor-base)"
                                selectable
                                dense
                                :disabled="isStation"
                                class="mt-2"
                                return-object
                            >
                                <template v-slot:prepend="{ item }">
                                    <div class="d-flex align-center">
                                        <img
                                            v-if="item.gender"
                                            :src="pupilIcon"
                                            class="iconToDunkelgrau"
                                            height="20"
                                            alt=""
                                        >
                                        <img
                                            v-else
                                            :src="groupIcon"
                                            alt=""
                                        >
                                    </div>
                                </template>
                            </v-treeview>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Veröffentlichungseinstellungen
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-timeline
                                v-if="!isStation && sortedStations.length > 0"
                                dense
                                align-top
                            >
                                <v-timeline-item
                                    v-for="(item, index) in sortedStations"
                                    :key="'Station'+index"
                                    :color="timelineColors[Math.round(index/2-0.5) % timelineColors.length]"
                                    :large="!item.stationSettings"
                                    fill-dot
                                    :class="item.stationSettings ? item.expandTimeline ? 'expandedTimelineSettings' : 'collapsedTimelineSettings' :
                                        item.expandTimeline ? 'expandedTimeline' : 'collapsedTimeline'"
                                >
                                    <div
                                        v-if="item.stationSettings && !item.expandTimeline"
                                        style="display: flex; cursor: pointer"
                                        @click="toggleExpandTimeline(index)"
                                    >
                                        <span
                                            class="mt-2"
                                            style="white-space: nowrap"
                                        >
                                            {{ item.revealedByPrevious ? index == 0 ? 'Sichtbarer Startpunkt' : `Sichtbar nach Abschluss der vorigen Station` :
                                                item.revealedByDate ? item.displayDatePicker ? '' : `Sichtbar ab dem ${item.revealedByDate.getDate()}.${item.revealedByDate.getMonth()+1}.${item.revealedByDate.getFullYear()}` :
                                                `Die Station ist immer sichtbar` }}
                                        </span>
                                    </div>
                                    <template v-slot:icon>
                                        <div
                                            style="cursor: pointer"
                                            @click="toggleExpandTimeline(index)"
                                        >
                                            <v-icon dark>
                                                {{ item.stationSettings ? item.revealedByDate ? 'mdi-calendar' : item.revealedByPrevious ? 'mdi-arrow-down-bold' : 'mdi-eye' :
                                                    'mdi-flag-variant' }}
                                            </v-icon>
                                        </div>
                                    </template>
                                    <v-card
                                        v-if="!(item.stationSettings && !item.expandTimeline)"
                                        dark
                                        :color="timelineColors[Math.round(index/2-0.5) % timelineColors.length]"
                                        class="mr-1"
                                        @click="toggleExpandTimeline(index)"
                                    >
                                        <div
                                            class="ml-1 pt-1 mr-1"
                                            style="display: flex; justify-content: space-between; white-space: nowrap"
                                        >
                                            {{ item.stationSettings ? `Station ${Math.round(index/2-0.5)+1} Erscheinungsdatum:` :
                                                `Station ${Math.round(index/2-0.5)+1}` }}
                                            <v-btn
                                                icon
                                                x-small
                                            >
                                                <v-icon
                                                    :style="item.expandTimeline ? 'transform: rotate(180deg)' : ''"
                                                >
                                                    {{ item.stationSettings ? 'mdi-close' : 'mdi-menu-down' }}
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                        <v-card-title>
                                            <!-- I'm having trouble catching the click:outside or blur of date-picker, does not work -->
                                            <div
                                                v-if="item.expandTimeline && item.stationSettings && item.displayDatePicker"
                                                style="display: flex; flex-direction: column"
                                                @click:outside.stop="toggleDatePicker(index)"
                                                @click="preventBubble"
                                            >
                                                <div
                                                    style="display: flex"
                                                >
                                                    <v-spacer />
                                                    <v-btn
                                                        dark
                                                        small
                                                        style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; background-color: #424242"
                                                        @click.stop="toggleDatePicker(index)"
                                                    >
                                                        <v-icon>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                <v-date-picker
                                                    v-model="revealedByDate"
                                                    locale="de-DE"
                                                    first-day-of-week="1"
                                                    no-title
                                                    style="z-index: 10; height: 0; border-top-right-radius: 0px"
                                                    @change="datePickerOnChange"
                                                />
                                            </div>
                                            <p :style="item.stationSettings ? 'white-space: nowrap; font-size: smaller; overflow: hidden' : ''">
                                                {{
                                                    item.stationSettings ? item.revealedByPrevious ? index == 0 ? 'Sichtbarer Startpunkt' : `Sichtbar nach Abschluss der vorigen Station` :
                                                        item.revealedByDate ? item.displayDatePicker ? '' : `Sichtbar ab dem ${item.revealedByDate.getDate()}.${item.revealedByDate.getMonth()+1}.${item.revealedByDate.getFullYear()}` :
                                                        `Die Station ist immer sichtbar` :
                                                    item.name
                                                }}
                                            </p>
                                        </v-card-title>
                                        <v-card-text
                                            v-if="item.expandTimeline && !item.stationSettings"
                                        >
                                            <!-- Information for a Station -> show folder and files -->
                                            <div>
                                                <div v-if="item.files.length > 0">
                                                    <div>
                                                        <p style="color: white">
                                                            Material:
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-for="(file, fileIndex) in item.files"
                                                        :key="`item.${index}.file.${fileIndex}`"
                                                        style="display: flex"
                                                    >
                                                        <v-icon
                                                            class="mr-2"
                                                            x-small
                                                        >
                                                            mdi-file-outline
                                                        </v-icon>
                                                        <p> {{ file.uploadedName }} </p>
                                                    </div>
                                                </div>
                                                <div v-if="item.folders.length > 0">
                                                    <div>
                                                        <p style="color: white">
                                                            Ordner:
                                                        </p>
                                                    </div>
                                                    <div
                                                        v-for="(folder, folderIndex) in item.folders"
                                                        :key="`item.${index}.file.${folderIndex}`"
                                                        style="display: flex"
                                                    >
                                                        <v-icon
                                                            class="mr-2"
                                                            x-small
                                                        >
                                                            mdi-folder
                                                        </v-icon>
                                                        <p> {{ folder.name }} </p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="item.files.length == 0 && item.folders.length == 0"
                                                >
                                                    <div>
                                                        <p style="color: white; white-space: nowrap">
                                                            Die Station ist noch nicht gefüllt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card-text>
                                        <v-card-actions
                                            style="white-space: nowrap"
                                        >
                                            <div v-if="!item.stationSettings">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            icon
                                                            :disabled="index <= 1"
                                                            elevation="1"
                                                            class="ml-1 mr-1"
                                                            style="background-color: #00000020"
                                                            @click.stop="swapStations(index, -1)"
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-arrow-up </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Station nach oben verschieben
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            icon
                                                            :disabled="index >= sortedStations.length-1"
                                                            elevation="1"
                                                            class="ml-1 mr-1"
                                                            style="background-color: #00000020"
                                                            @click.stop="swapStations(index, 1)"
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-arrow-down </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Station nach unten verschieben
                                                </v-tooltip>
                                            </div>
                                            <div v-else>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            icon
                                                            :elevation="item.revealedByPrevious ? '7' : '1'"
                                                            :outlined="item.revealedByPrevious"
                                                            class="ml-1 mr-1"
                                                            :style="item.revealedByPrevious ? '' : 'background-color: #00000020'"
                                                            @click.stop="setStationRevealedBy(index, false, true)"
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-arrow-down-bold </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    In Reihenfolge
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            icon
                                                            :elevation="(!!!item.revealedByDate && !item.revealedByPrevious) ? '7' : '1'"
                                                            :outlined="!!!item.revealedByDate && !item.revealedByPrevious"
                                                            class="ml-1 mr-1"
                                                            :style="(!!!item.revealedByDate && !item.revealedByPrevious) ? '' : 'background-color: #00000020'"
                                                            @click.stop="setStationRevealedBy(index, false, false)"
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-eye </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Ohne Reihenfolge
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            icon
                                                            :elevation="!!item.revealedByDate ? '7' : '1'"
                                                            :outlined="!!item.revealedByDate"
                                                            class="ml-1 mr-1"
                                                            :style="item.revealedByDate ? '' : 'background-color: #00000020'"
                                                            @click.stop="toggleDatePicker(index)"
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-calendar </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Nach Datum
                                                </v-tooltip>
                                            </div>
                                        </v-card-actions>
                                    </v-card>
                                </v-timeline-item>
                            </v-timeline>
                            <div
                                v-else-if="isStation"
                                class="ma-2"
                            >
                                <p> 
                                    {{ station.revealedByDate ? `Die Station ist ab dem ${station.revealedByDate.getDate()}.${station.revealedByDate.getMonth()}.${station.revealedByDate.getFullYear()} sichtbar.` :
                                        station.revealedByPrevious ? 'Die Station ist sichtbar, sobald alle Aufgaben der vorigen Station gelöst wurden.' : 'Die Station ist immer sichtbar.'
                                    }}
                                </p>
                                <p>
                                    Die Einstellungen für alle Stationen werden im übergeordneten Lernpfad verändert.
                                </p>
                            </div>
                            <div
                                v-else
                                class="ma-2"
                            >
                                <p> Sobald Stationen angelegt wurden, können Sie diese hier verwalten. </p>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import searchIcon from '@/assets/Icons/lupe-18.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import groupIcon from '@/assets/Icons/group.svg';
import { mapActions, mapGetters, mapState } from "vuex";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import deutschIcon from "@/assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import plusIcon from "@/assets/Icons/plus.svg";
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';

export default {
    name: 'LearningPathDialog',
    components: {
    },
    props: {
        item: { required: true, type: Object },
        editMode: { required: true, type: Boolean, default: false },
        interactable: { required: false, type: Boolean, default: true }
    },
    data: () => ({
        // Icons
        closeIcon,
        searchIcon,
        pupilIcon,
        groupIcon,
        deutschIcon,
        plusIcon,
        saveIcon,
        show: true,
        itemName: '',
        searchTreeView: '',
        possibleReceivers: [],
        receiverAccounts: [],
        // default opens first panel
        panel: [0, 1],
        // is this a learning path or station? depends if a learningPath model exists and if the action happens in the pathVFSOrigin
        isStation: false,
        station: {},
        timelineColors: ['green lighten-1', 'blue lighten-2', 'purple lighten-2', 'red lighten-1', 'orange lighten-1'],

        sortedStations: [],
        revealedByDate: null,
        displayDatePickerIndex: 0,

        screenX: null,

        // watcher
        blockedWatcher: false,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState('util', ['windowWidth']),
    },
    watch: {
        receiverAccounts: {
            async handler() {
                if (!this.blockedWatcher) {
                    this.blockedWatcher = true;
                    for (let i = 0; i < this.possibleReceivers.length; i++) {
                        // if pupil is sharing to anyone, also force share to teachers
                        if (this.accountRole === 'pupil' && this.receiverAccounts.length > 0 && this.possibleReceivers[i].displayName === 'Lehrer') {
                            for (let j = 0; j < this.possibleReceivers[i].participants.length; j++) {
                                this.receiverAccounts.push(this.possibleReceivers[i].participants[j]);
                            }
                        }
                    }
                    // the v-treeview updates with heavy delay, without sleep this causes infinite loop. needs testing on slower devices
                    await new Promise(r => setTimeout(r, 100));
                    this.blockedWatcher = false;
                }
            }
        },
    },
    async mounted() {
        await this.getPossibleReceivers();
        if (this.editMode) {
            // the parsed item is the existing learningPath-vfs
            this.isStation = this.item._id !== this.item.learningPath.pathVFSOrigin;
            this.itemName = this.item.name;
        } else {
            // the parsed item is the currentFolder (where a new subfolder is to be inserted)
            this.isStation = !!this.item.learningPath;
            this.panel = [0];
        }
        // initialize sorted Stations (elements of timeline)
        if (!this.isStation && this.editMode) {
            // sort stations to order: ascending
            this.sortedStations = this.item.folders.sort((a,b) => {
                return this.compareStations(a,b);
            });
            // after every station an element is appended which displays and configures revealedBy settings
            const stationSettingItems = await Promise.all(this.sortedStations.map((folder) => {
                const station = this.item.learningPath.stations.find((el) => {
                    return el.vfs == folder._id;
                });
                // 'stationSettings' flags for custom handling
                const slot = {
                    stationSettings: true,
                    vfs: station ? station.vfs : null,
                    revealedByDate: station ? station.revealedByDate ? new Date(station.revealedByDate) : null : null,
                    revealedByPrevious: station ? station.revealedByPrevious ? station.revealedByPrevious : false : false,
                };
                return slot;
            }));
            const temp = [];
            for (let i = 0; i < this.sortedStations.length; i++) {
                temp.push(stationSettingItems[i]);
                temp.push(this.sortedStations[i]);
            }
            this.sortedStations = temp;
        } else if (this.editMode) {
            this.station = this.item.learningPath.stations.find((el) => el.vfs == this.item._id);
            if (this.station.revealedByDate) {
                this.station.revealedByDate = new Date(this.station.revealedByDate);
            }
        }
        this.evaluateScreen();
        if (this.screenX < 900) {
            // due to better visual clarity, pre-expand all items
            for (let i = 0; i < this.sortedStations.length; i++) {
                this.sortedStations[i].expandTimeline = true;
            }
        }
    },
    methods: {
        ...mapActions("virtualFileSystem", [ "getVFSSharingV2", "updateVFSFolderV2", "createVFSFolderV2" ]),

        async startTransaction() {
            let sharing_Accounts = await Promise.all(this.receiverAccounts.map(el => {
                return el.account;
            }));
            let data = {
                currentFolderId: this.item._id ? this.item._id : null,
                name: this.itemName,
                sharing_Accounts,
                sharing_enabled: sharing_Accounts.length > 0,
                sharing_discoverable: sharing_Accounts.length > 0,
                createLearningPath: !this.editMode ? !this.isStation : false,
            };
            if (this.isStation || this.editMode) {
                delete data.createLearningPath;
                if (this.editMode) {
                    data._id = data.currentFolderId;
                    delete data.currentFolderId;
                    if (!this.isStation) {
                        // prepare the stations payload to update settings (order, revealedBy, ...)
                        const stations = await Promise.all(this.item.learningPath.stations.map((station) => {
                            for (let i = 0; i < this.sortedStations.length; i++) {
                                if (this.sortedStations[i]._id == station.vfs) {
                                    // the index now represent our new Order
                                    station.order = i;
                                    // sortedStations[i-1] is our settings item from the timeline
                                    station.revealedByDate = this.sortedStations[i-1].revealedByDate;
                                    station.revealedByPrevious = this.sortedStations[i-1].revealedByPrevious;
                                }
                            }
                            return station;
                        }));
                        data.stations = stations;
                    }
                    await this.updateVFSFolderV2(data);
                } else {
                await this.createVFSFolderV2(data);
                }
                } else {
                await this.createVFSFolderV2(data);
            }
            // should be res.status handled but some multiselect error blocks this right now..
            this.$emit('close');
        },
        async getPossibleReceivers() {
            try {
                let addItemText;
                if(this.interactable && this.editMode) {
                    addItemText = await this.getVFSSharingV2(this.item.parentFolder._id || this.item.parentFolder);
                } else {
                    addItemText = await this.getVFSSharingV2(this.item._id);
                }
                for (let i = 0; i < addItemText.length; i++) {
                    // grab the name of the Group
                    addItemText[i].displayName = addItemText[i].name;
                    // visually preselect possible recievers with currently set receivers
                    // for every possible receiver account
                    for (let j = 0; j < addItemText[i].participants.length; j++) {
                        // for every currently Shared AccountId
                        if (this.item.sharing_Accounts) {
                            for (let k = 0; k < this.item.sharing_Accounts.length; k++) {
                                // add to selected Model if matching (user sees the menu with current setting)
                                if (this.item.sharing_Accounts[k] == addItemText[i].participants[j].account) {
                                    this.receiverAccounts.push(addItemText[i].participants[j]);
                                }
                            }
                        }
                    }
                }
                this.possibleReceivers = addItemText;
            } catch (e) {
                console.error(e);
            }
        },
        // receives vfs.folders, matches them with learningPath.stations to get attributes like order
        compareStations(a, b) {
            const stationA = this.item.learningPath.stations.find((station) => station.vfs == a._id);
            const stationB = this.item.learningPath.stations.find((station) => station.vfs == b._id);
            if (!stationA || !stationB) {
                return 0;
            }
            return stationA.order == stationB.order ? 0 : stationA.order > stationB.order ? 1 : -1;
        },
        swapStations(index, direction) {
            const tempStation = this.sortedStations[index];
            const tempSetting = this.sortedStations[index-1];
            this.sortedStations[index] = this.sortedStations[index+direction*2];
            this.sortedStations[index+direction*2] = tempStation;
            this.sortedStations[index-1] = this.sortedStations[index-1+direction*2];
            this.sortedStations[index-1+direction*2] = tempSetting;
            this.triggerReactive();
        },
        toggleDatePicker(index) {
            this.sortedStations[index].displayDatePicker = !this.sortedStations[index].displayDatePicker;
            this.displayDatePickerIndex = index;
            this.triggerReactive();
        },
        datePickerOnChange(date) {
            this.setStationRevealedBy(this.displayDatePickerIndex, date, false);
            this.toggleDatePicker(this.displayDatePickerIndex);
            this.displayDatePickerIndex = 0;
        },
        setStationRevealedBy(index, revealedByDate, revealedByPrevious) {
            if (revealedByDate) {
                this.sortedStations[index].revealedByDate = new Date(revealedByDate);
                this.sortedStations[index].revealedByPrevious = false;
            } else if (revealedByPrevious) {
                this.sortedStations[index].revealedByDate = null;
                this.sortedStations[index].revealedByPrevious = true;
            } else {
                this.sortedStations[index].revealedByDate = null;
                this.sortedStations[index].revealedByPrevious = false;
            }
            this.triggerReactive();
        },
        toggleExpandTimeline(index) {
            if (this.screenX >= 900) {
                this.sortedStations[index].expandTimeline = !this.sortedStations[index].expandTimeline;
                this.triggerReactive();
            }
        },
        preventBubble(event) {
            event.stopPropagation();
            event.preventDefault();
        },
        // use after manipulating the sortedStations array to trigger reactive listeners
        triggerReactive() {
            this.sortedStations.push({});
            this.sortedStations.pop();
        },
        evaluateScreen() {
            // this dialog does not look well on mobile
            // bandaid: always show the full dialog (no collapse) past screenWidth lower treshhold
            const win = window;
            const doc = document;
            const docElem = doc.documentElement;
            const body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
        }
    }
}
</script>

<style scoped lang="scss">

.cardStyle {
  .v-card__text {
    .v-input__control > .v-input__slot {
      border-radius: 8px;
    }

    // remove border bottom line/underline from all the inputs in v-cards with this class
    .v-input__control > .v-input__slot:before,
    .v-input__control > .v-input__slot:after {
      border-style: none !important;
    }
  }
}
.collapsedTimeline {
    animation: collapseTimeline .75s;
    max-width: 65%;
    // max-height: 20vh;
}

@keyframes collapseTimeline {
    0%      { max-width: 100%; max-height: 100vh; }
    100%    { max-width: 65% }  
}

.expandedTimeline {
    animation: expandTimeline .75s;
    max-width: 100%;
    max-height: 100vh;
}

@keyframes expandTimeline {
    0%      { max-width: 65% }
    100%    { max-width: 100%; max-height: 100vh; }  
}

.collapsedTimelineSettings {
    animation: collapseTimelineSettings 1s;
    max-width: 40%;
}

@keyframes collapseTimelineSettings {
    0%      { max-width: 100%; }
    100%    { max-width: 25%; }  
}

.expandedTimelineSettings {
    animation: expandTimelineSettings 1s;
    max-width: 100%;
}

@keyframes expandTimelineSettings {
    0%      { max-width: 25%; }
    100%    { max-width: 100%; }  
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconBtn {
    width: 48px !important;
    height: 48px !important;
    margin: 6px;
    border-radius: 8px;
}

.colored {
    background-color: var(--v-fhellblau-base);
}
</style>
