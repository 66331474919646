import { v4 as uuidv4 } from 'uuid';
import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import { AuthorizationError, HTTPConflictError } from '../../util/error';
import backendWebSocket from '../../api/backendWebSocket';

const defaultState = {
    stayLoggedIn: true,
    token: "",
    account: {},
    // Used for identifying users device,
    // should be generated once per device
    deviceToken: null,
    // when true, prevent this device from being logged out on receiving account-forcelogout
    preventLogout: false,
    //used for Pin
    messagesPinActivated: false,
    pinActivated: false,
    pinValid: false,
    accountHasPin: false,
};

const mutations = {
    login(state, token) {
        state.token = token;
    },
    setAccount(state, account) {
        state.account = account;
    },
    setStayLoggedIn(state, stay) {
        state.stayLoggedIn = stay;
    },
    setDeviceToken(state, deviceToken) {
        state.deviceToken = deviceToken;
    },
    logout(state) {
        state.token = "";
        state.account = null;
    },
    setPreventLogout(state, preventLogout) {
        state.preventLogout = preventLogout;
    },
    setPinActivated(state, pinActivated){
        state.pinActivated = pinActivated;
    },
    setMessagesPinActivated(state, messagesPinActivated){
        state.messagesPinActivated = messagesPinActivated;
    },
    setPinValid(state, pinValid){
        state.pinValid = pinValid;
    },
    setAccountHasPin(state, accountHasPin){
        state.accountHasPin = accountHasPin;
    }
};

const actions = {
    async loginUser({ commit, dispatch, state }, { accountName, password, twoFactor, qrLogin }) {
        commit('setPinValid',false);
        commit('setAccountHasPin',false);
        commit('blackboard/setAllBlackboardEntries',[],{ root: true });
        commit('appointments/setAppointmentBuffer', [], { root: true });
        commit('appointments/setAppointments', [], { root: true });
        commit('diary/setDiaries', [], { root: true });
        commit('diary/setDiaryEntries', [], { root: true });
        commit('lateTimes/setLateTimesWithAppointments', [], { root: true });
        commit('lateTimes/setLateTimes', [], { root: true });

        const regexNoWhitespacesAtStartEnd = /^\s+|\s+$/gm;
        password = password.replace(regexNoWhitespacesAtStartEnd, '');

        if (!state.deviceToken) {
            commit('setDeviceToken', uuidv4());
        }

        const res = await backend.loginAccount({
            accountName,
            password,
            deviceToken: state.deviceToken,
            twoFactor: twoFactor,
            qrLogin: qrLogin,
        });
        if(res.status === 409){
            commit('logout');
            throw new HTTPConflictError('No Logins left')
        } 
        else if (res.status === 403 || res.status === 401) {
            commit('logout');
            throw new AuthorizationError('Login credentials seem to be wrong.');
        }



        const body = await res.json();

        if (body.twoFA) {
            return body;
        }
        if(body.loginPinActivated){
            return body;
        }
        if(body.error) {
            return body;
        }

        const { token } = body;

        commit('login', token);
        // Get account only once when logging in
        const account = await dispatch('getAccount', true);
        if(account.role === 'maintainer'){
            await commit('setPinActivated',account.pinActivated);
        }else{
            await commit('setPinActivated',account.pinActivated);
            await commit('setMessagesPinActivated',account.messagesPinActivated);
        }
        if(account.role !== 'pupil' && (state.pinActivated || state.messagesPinActivated)){
            const hasPinRes = await backend.accountHasPin(account._id);
            if(hasPinRes.status === 200){
                await commit('setAccountHasPin',true);
            }else{
                await commit('setAccountHasPin',false);
            }
        }
        if(account._id){
            const loginHistory = await backend.postLoginHistory({type:'Anmeldung', accountId: account._id});     
        }
        return account;
    },
    // Request backend with current token to see if it is still valid
    // and return current user
    async revalidateUserLogin({ commit, getters }) {
        try {
            if (getters.account) {
                return getters.account;
            }
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            commit('setAccount', account);
            return account;
        }
        catch (err) {
            commit('logout');
            return null
        }
    },
    async getAccount({ commit, dispatch, getters }, update = false) {
        if (getters.account && !update) {
            return getters.account;
        }
        const resCurrentAccount = await backend.getCurrentAccount();
        try {
            const account = await resCurrentAccount.json();
            commit('setAccount', account);
            dispatch('accounts/pullMe', account, { root: true });
            return account;
        } catch (error) {
            commit('logout');
        }
    },
    async togglePlanAnimation({commit},accountId){
        try{
            const res = await backend.togglePlanAnimation(accountId);
            await checkResponseStatus(200, res);
            const accountObject = await res.json();
            commit('setAccount', accountObject);
        }catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async acceptTermsOfService({ dispatch }) {
        try {
            const res = await backend.acceptTermsOfService();
            await checkResponseStatus(200, res);
            dispatch('getAccount');
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async changeStayLoggedIn({ commit }, stay) {
        commit('setStayLoggedIn', stay);
    },
    async logoutUser({ commit, state, getters }) {
        await backend.unsubscribe({ deviceToken: state.deviceToken});
        commit('appointments/setAppointments', [], { root: true });
        commit('blackboard/setAllBlackboardEntries',[],{ root: true });
        commit('appointments/setAppointmentBuffer', [], { root: true });
        commit('diary/setDiaries', [], { root: true });
        commit('diary/setDiaryEntries', [], { root: true });
        commit('lateTimes/setLateTimesWithAppointments', [], { root: true });
        commit('lateTimes/setLateTimes', [], { root: true });

        commit('setPinValid',false);
        commit('setAccountHasPin',false);
        const loginHistory = await backend.postLoginHistory({type:'Abmeldung', accountId: getters.accountId});
        commit('logout');
    },
    async isAccountNameAvailable({ commit, dispatch }, accountName) {
        try {
            const res = await backend.postNameAvailable({accountName});
            await checkResponseStatus(200, res);
            const available = await res.json();
            return available;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getCurrentAccount({ commit, dispatch, getters },update = false) {
        try {
            if (!update && getters.account) {
                return getters.account;
            }
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            return account;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSecurityQuestions({ commit, dispatch }) {
        try {
            const res = await backend.getSecurityQuestions();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async updateSubscription({ commit, state }, subscription) {
        await backend.subscribe({
            subscription,
            deviceToken: this.deviceToken,
        });
    },
    async has2FA({ commit, dispatch }, accountName) {
        const res = await backend.has2FA({ accountName });
        return await res.json();
    },

    async get2FA({ commit, dispatch }, account) {
        const res = await backend.get2FA({ account});
        return await res.json();
    },
    async tryResetLockedAccount({ commit, dispatch },data){
        const res = await backend.resetLockedAccountRequest(data);
        if(res.status === 404){
            return 'denied';
        }else if(res.status === 204){
            return 'accepted';
        }else{
            return 'error';
        }
    },
    async setPinValidTrue({commit,state}, pinValid){
        commit('setPinValid',true);
    }
};

const getters = {
    accountId: state => state.account ? state.account._id : undefined,
    accountRole: state => state.account ? state.account.role : undefined,
    account: state => state.account ? state.account : undefined,
    preventLogout: state => state.preventLogout ? state.preventLogout : undefined,
    accountPinValid: state => state.pinValid,
    diaryPinActivated: state => state.pinActivated,
    messagesPinActivated: state => state.messagesPinActivated,
    accountHasPin: state => state.accountHasPin,
    deactivatedAnimation: state => state.account ? state.account.deactivatedAnimation : false,
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
