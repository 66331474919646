<template>
    <v-menu
        v-model="selectionMenu"
        offset-y
        :content-class="selectionMenu ? 'noBoxShadowTop' : ''"
        :close-on-content-click="false"
        :disabled="onlyDisplay"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                class="selectWrapper"
                :class="`${selectionMenu ? 'selectedWrapperOpened' : ''} ${!onlyDisplay ? 'selectWrapperHoverAnimation' : ''}`"
                :style="onlyDisplay ? 'cursor: default' : ''"
                v-on="on"
            >
                <div
                    class="selectContainer"
                    :style="'background-color: ' + selectedColor"
                >
                    <v-icon
                        v-if="selectedIcon && selectedIcon.startsWith('mdi')"
                        class="selectedIcon"
                    >
                        {{ selectedIcon }}
                    </v-icon>
                    <img
                        v-else
                        :src="selectedIcon"
                        class="selectedIcon iconToWhite"
                        alt=""
                    >
                </div>
            </div>
        </template>
        <v-card
            max-width="400px"
            class="vCard"
        >
            <div class="iconPicker">
                <div
                    v-for="(icon, index) in icons"
                    :key="index"
                    class="listEntry"
                    @click="changeIcon(icon)"
                >
                    <div
                        class="selectContainer"
                        :style="'background-color: ' + selectedColor"
                    >
                        <v-icon
                            v-if="icon && icon.startsWith('mdi')"
                            class="selectedIcon"
                        >
                            {{ icon }}
                        </v-icon>
                        <img
                            v-else
                            :src="icon"
                            class="selectedIcon iconToWhite"
                            alt=""
                        >
                    </div>
                </div>
            </div>
            <div class="colorPicker">
                <div
                    v-for="(color, index) in colors"
                    :key="index"
                    class="selectContainer listEntry"
                    :style="'background-color: ' + color"
                    @click="changeColor(color)"
                />
            </div>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "ColoredIconPicker",
    props: {
        selectedIcon: { type: String, required: false },
        selectedColor: { type: String, required: false },
        icons: { type: Array, required: true },
        colors: { type: Array, required: false },
        onlyDisplay: { type: Boolean, required: false }
    },
    data() {
        return {
            selectionMenu: false,
        }
    },
    mounted() {
        if(this.selectedIcon === '' && this.icons.length > 0) {
            this.changeIcon(this.icons[this.getRandomIntInclusive(0, this.icons.length - 1)]);
        }
        if(this.selectedColor === '' && this.colors.length > 0) {
            this.changeColor(this.colors[this.getRandomIntInclusive(0, this.colors.length - 1)]);
        }
    },
    methods: {
        changeIcon(icon) {
            this.$emit('changedIcon', icon);
        },

        changeColor(color) {
            this.$emit('changedColor', color);
        },

        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min +1)) + min;
        },
    }
}
</script>

<style scoped>
    .selectWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 12px;
    }

    .selectWrapperHoverAnimation:hover {
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    }

    .selectedWrapperOpened {
        border-radius: 12px 12px 0 0;
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    }

    .noBoxShadowTop {
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
        border-radius: 0 4px 4px 4px;
    }

    .noBoxShadowTop .v-sheet.v-card {
        border-radius: 0 4px 4px 4px;
    }

    .selectContainer {
        width: 48px;
        height: 48px;
        border-radius: 12px;
    }

    .selectedIcon {
        height: 48px;
        width: 48px;
        margin: auto;
        color: white;
    }

    .vCard {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .listEntry {
        margin: 4px;
        cursor: pointer;
    }

    .iconPicker {
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 12px;
    }

    .colorPicker {
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 12px;
    }
</style>