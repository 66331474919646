<template>
    <div class="group-chat-wrapper">
        <div class="group-chat-header d-flex align-center justify-space-between">
            <v-tooltip
                :disabled="!showTooltips"
                bottom
                style="z-index: 9;"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        id="backButton"
                        class="ma-2 group-chat-header-btn"
                        color="rgba(248, 248, 248, 0.4)"
                        small
                        v-on="on"
                        @click="() => { clickBack() }"
                    >
                        <img
                            :src="pfeilIcon"
                            class="icon iconToWhite"
                            style="transform: rotate(90deg)"
                            alt="Zurück"
                        >
                    </v-btn>
                </template>
                <span>Zurück</span>
            </v-tooltip>
            <h2
                id="chatName"
                tabindex="0"
                class="group-chat-header-text mb-0"
                style="cursor: pointer"
                @click="showGroupInfo(room)"
            >
                {{ room.name.includes('private Room') || room.name.includes('group Room') ? checkRoomName(room.name) : room.name }}
            </h2>
            <v-spacer />
            <!-- Can be switched to menu component later -->
            <DeleteChatButton
                v-if="canUserDeleteRoom(room)"
                :room="room"
                @updateRoomList="() => {
                    $emit('updateRoomList');
                    clickBack();
                }"
            />
        </div>
        <div
            v-if="room"
            id="chatBody"
            class="chat-body"
            :style="`height: calc(75vh - ${51 + offsetHeightChat}px);`"
        >
            <ScrollAreaMagnifier
                ref="scrollAreaMagnifier"
                :offset-height-chat="offsetHeightChat"
                :settings="settings"
                :usage="'chatWidget'"
                @ps-y-reach-start="loadMore"
            >
                <v-overlay
                    v-if="poll"
                    absolute
                    overlay-opacity="0.8"
                />
                <div
                    class="my-2"
                    style="position: relative"
                >
                    <div
                        v-for="(event) in events"
                        :key="event.event.event_id"
                        style="display: flex; flex-direction: column; flex-wrap: nowrap"
                    >
                        <div v-if="event.getType() === 'm.room.message'">
                            <div
                                style="display: flex"
                                :class="( isMe(event) ? 'myMessage ' : ' ') + 'chat-message'"
                                :var="teacherPic = getTeacherPic(event)"
                            >
                                <v-row dense>
                                    <v-col>
                                        <v-row
                                            v-long-press="1000"
                                            dense
                                            @long-press-start="(isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && isTouchDevice ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                        >
                                            <v-col class="pa-0 greyText">
                                                <div style="display:inline-flex">
                                                    <p
                                                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                                        tabindex="0"
                                                        class="mb-0"
                                                        style="width: fit-content; height: fit-content; font-weight: bold"
                                                    >
                                                        {{ getUsername(event) }}
                                                    </p>
                                                    <p
                                                        tabindex="0"
                                                        class="mb-0 visually-hidden"
                                                        style="width: fit-content; float: right"
                                                    >
                                                        {{
                                                            'Nachricht von ' + getUsername(event) + ' am ' + new Date(getTime(event)).toLocaleDateString() + ' ' + getAccessibilityTime(new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3))
                                                        }}
                                                    </p>
                                                </div>
                                            </v-col>
                                            <v-col
                                                class="text-right greyText"
                                                style="font-size: smaller;"
                                            >
                                                <p
                                                    :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                                    tabindex="0"
                                                    class="mb-0"
                                                    style="width: fit-content; float: right"
                                                >
                                                    {{ new Date(getTime(event)).toLocaleDateString() + ' ' + new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3) }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            v-long-press="1000"
                                            dense
                                            :class="( isMe(event) ? 'myMessage-content ' : ' ') + 'chat-message-content'"
                                            @long-press-start="(isMe(event) && event.getContent().body) && isTouchDevice ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                        >
                                            <!-- //TODO FIX ME
                                            :class="( isMe(event) ? 'myMessage-content ' : (getGender(event) === 'm' ? 'male-chat ' : (getGender(event) === 'w' ? 'female-chat ' : ''))) + 'chat-message-content'"-->
                                            <div
                                                class="pa-0"
                                                style="width: 100%"
                                            >
                                                <div v-if="event.getContent().body">
                                                    <!-- voice message/audio message -->
                                                    <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                        <div class="d-flex">
                                                            <div
                                                                style="line-height: 20px"
                                                                @keypress.enter="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0 ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))"
                                                                @click="loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0 ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))"
                                                            >
                                                                <div
                                                                    class="d-flex align-center"
                                                                    style="min-height: 24px"
                                                                >
                                                                    <p
                                                                        tabindex="0"
                                                                        class="mb-0 mr-1"
                                                                    >
                                                                        Sprachnachricht
                                                                    </p>
                                                                    <v-tooltip
                                                                        v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0"
                                                                        :disabled="!showTooltips"
                                                                        top
                                                                        style="z-index: 9;"
                                                                    >
                                                                        <template v-slot:activator="{ on }">
                                                                            <img
                                                                                tabindex="0"
                                                                                role="button"
                                                                                :src="downloadIcon"
                                                                                alt="Sprachnachricht laden"
                                                                                :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'"
                                                                                v-on="on"
                                                                            >
                                                                        </template>
                                                                        <span>Laden</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip
                                                                        v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')"
                                                                        :disabled="!showTooltips"
                                                                        top
                                                                        style="z-index: 9;"
                                                                    >
                                                                        <template v-slot:activator="{ on }">
                                                                            <img
                                                                                tabindex="0"
                                                                                role="button"
                                                                                :src="lautsprecherIcon"
                                                                                alt="Sprachnachricht anhören"
                                                                                :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'"
                                                                                v-on="on"
                                                                            >
                                                                        </template>
                                                                        <span>Vorlesen</span>
                                                                    </v-tooltip>
                                                                    <img
                                                                        v-else
                                                                        tabindex="0"
                                                                        role="button"
                                                                        :src="lautsprecherAusIcon"
                                                                        alt=""
                                                                        :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'"
                                                                    >
                                                                </div>
                                                            </div>
                                                            <v-spacer />
                                                            <v-tooltip
                                                                :disabled="!showTooltips"
                                                                top
                                                                style="z-index: 9;"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        v-if="(isMe(event) || fromPupil(event)) && accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                        x-small
                                                                        class="pa-0"
                                                                        style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px"
                                                                        v-on="on"
                                                                        @click="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                        @keypress.enter="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                    >
                                                                        <img
                                                                            :src="papierkorbIcon"
                                                                            alt="Sprachnachricht löschen"
                                                                            height="20px"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>Löschen</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>

                                                    <div v-else-if="event.getContent().body.startsWith('$imageFileId:')">
                                                        <div class="d-flex">
                                                            <div
                                                                style="line-height: 20px"
                                                                @keypress.enter="loadedImages.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length === 0 ? loadImageFile(event.getContent().body.replace('$imageFileId:','')) : {}"
                                                                @click="loadedImages.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length === 0 ? loadImageFile(event.getContent().body.replace('$imageFileId:','')) : {}"
                                                            >
                                                                <div
                                                                    class="d-flex align-center"
                                                                    style="min-height: 24px"
                                                                >
                                                                    <div
                                                                        v-if="!loadedImages.filter((el) => { return el.file === event.getContent().body.replace('$imageFileId:','') }).length > 0"
                                                                        style="display: flex"
                                                                    >
                                                                        <p
                                                                            :id="'bild_' + event.getContent().body"
                                                                            style="margin-right: 4px"
                                                                            @click="loadedImages.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length === 0 ? loadImageFile(event.getContent().body.replace('$imageFileId:','')) : {}"
                                                                        >
                                                                            Bild
                                                                        </p>
                                                                        <v-tooltip style="z-index: 9;">
                                                                            <template v-slot:activator="{ on }">
                                                                                <img
                                                                                    tabindex="0"
                                                                                    role="button"
                                                                                    :src="downloadIcon"
                                                                                    alt="Bild laden"
                                                                                    :class="(!isMe(event) ? 'iconToWhite ' : '') + 'icon'"
                                                                                    v-on="on"
                                                                                >
                                                                            </template>
                                                                            <span>Laden</span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                    <img
                                                                        v-else
                                                                        :id="event.getContent().body"
                                                                        :src="getThisImage(event.getContent().body.replace('$imageFileId:',''))"
                                                                        style="cursor: pointer; max-width: 100%"
                                                                        @click="imgFullscreen = getThisImage(event.getContent().body.replace('$imageFileId:',''))"
                                                                    >
                                                                </div>
                                                            </div>
                                                            <v-spacer />
                                                            <div style="display: flex; flex-direction: column; padding-left: 8px">
                                                                <v-tooltip 
                                                                    top 
                                                                    style="z-index: 9;"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            v-if="(isMe(event) || fromPupil(event)) && accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                            x-small
                                                                            class="pa-0"
                                                                            style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px"
                                                                            v-on="on"
                                                                            @click="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                            @keypress.enter="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                        >
                                                                            <img
                                                                                :src="papierkorbIcon"
                                                                                alt="Bild löschen"
                                                                                height="20px"
                                                                            >
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Löschen</span>
                                                                </v-tooltip>
                                                                <v-tooltip 
                                                                    top 
                                                                    style="z-index: 9;"
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            x-small
                                                                            class="pa-0"
                                                                            style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px"
                                                                            :style="(isMe(event) || fromPupil(event)) && accountRole === 'teacher' ? 'margin-top: 8px' : ''"
                                                                            v-on="on"
                                                                            @click="downloadImage(getThisImage(event.getContent().body.replace('$imageFileId:','')))"
                                                                            @keypress.enter="downloadImage(getThisImage(event.getContent().body.replace('$imageFileId:','')))"
                                                                        >
                                                                            <img
                                                                                :src="downloadIcon"
                                                                                alt="Bild herunterladen"
                                                                                height="20px"
                                                                            >
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Herunterladen</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- poll message -->
                                                    <div v-else-if="event.getContent().body.startsWith('$pollId:')">
                                                        <poll
                                                            :poll-id="event.getContent().body.replace('$pollId:', '')"
                                                            :in-chat="true"
                                                        />
                                                    </div>

                                                    <!-- text message -->
                                                    <div
                                                        v-else
                                                        style="display: inline-flex; width: 100%"
                                                    >
                                                        <div
                                                            v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))"
                                                            style="position: absolute; top: 30px; right: -42px"
                                                            :style="teacherPic!==null ? 'top: 58px !important' : ''"
                                                        >
                                                            <v-tooltip
                                                                :disabled="!showTooltips"
                                                                top
                                                                style="z-index: 9;"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                                                        elevation="0"
                                                                        icon
                                                                        class="ml-2 pa-0 preventColorInvert"
                                                                        style="width: 40px; height: 40px"
                                                                        v-on="on"
                                                                        @click="greet(event.getContent().body, $event)"
                                                                    >
                                                                        <img
                                                                            :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                                                                            :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                                                                            style="height: 30px;"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>Nachricht vorlesen</span>
                                                            </v-tooltip>
                                                        </div>

                                                        <div
                                                            v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))"
                                                            style="position: absolute; top: 30px; left: -50px"
                                                            :style="teacherPic!==null ? 'top: 58px !important' : ''"
                                                        >
                                                            <v-tooltip
                                                                :disabled="!showTooltips"
                                                                top
                                                                style="z-index: 9;"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                                                        elevation="0"
                                                                        icon
                                                                        class="ml-2 pa-0 preventColorInvert"
                                                                        style="width: 40px; height: 40px"
                                                                        v-on="on"
                                                                        @click="greet(event.getContent().body, $event)"
                                                                    >
                                                                        <img
                                                                            :src="isReading ? lautsprecherAusIcon : lautsprecherIcon"
                                                                            :alt="isReading ? 'Vorlesen stoppen' : 'Nachricht vorlesen'"
                                                                            style="height: 30px;"
                                                                        >
                                                                    </v-btn>
                                                                </template>
                                                                <span>Nachricht vorlesen</span>
                                                            </v-tooltip>
                                                        </div>
                                                        <!-- Nachricht -->
                                                        <!-- Disable v-html xss checking entry.content is provided only by developers -->
                                                        <!-- eslint-disable vue/no-v-html -->
                                                        <p
                                                            tabindex="0"
                                                            style="min-width: calc(100% - 25px); max-width: 225px; word-wrap: break-word; white-space: pre-wrap;"
                                                            class="mb-0"
                                                            v-html="sanitizeHtml(urlify(event.getContent().body))"
                                                        />
                                                        <!-- eslint-enable vue/no-v-html -->
                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            top
                                                            style="z-index: 9;"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    v-if="(isMe(event) || fromPupil(event)) && accountRole === 'teacher' || accountRole === 'maintainer'"
                                                                    x-small
                                                                    class="pa-0"
                                                                    style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px"
                                                                    v-on="on"
                                                                >
                                                                    <img
                                                                        :src="papierkorbIcon"
                                                                        height="20px"
                                                                        alt="Nachricht löschen"
                                                                        @click="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                        @keypress.enter="((isMe(event) || fromPupil(event) && accountRole === 'teacher' || accountRole === 'maintainer') && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Löschen</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                <!-- message was deleted -->
                                                <div
                                                    v-else
                                                    style="font-style: italic"
                                                >
                                                    <p
                                                        tabindex="0"
                                                        class="mb-0"
                                                        style="width: fit-content"
                                                    >
                                                        Diese Nachricht wurde gelöscht.
                                                    </p>
                                                </div>
                                            </div>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollAreaMagnifier>
        </div>

        <!-- text area for composing message -->
        <div v-if="!poll">
            <div
                v-if="room"
                class="chat-send-wrapper align-center"
                style="width: 100%"
            >
                <v-textarea
                    id="chatTextArea"
                    ref="chatTextArea"
                    v-model="inputMessage"
                    label="Nachricht"
                    hide-details
                    flat
                    solo
                    auto-grow
                    no-resize
                    oncontextmenu="return false;"
                    placeholder="Nachricht eingeben..."
                    rows="1"
                    row-height="25"
                    style="max-height: 100px; overflow-x: hidden; overflow-y: auto;"
                    class="chat-send-input pl-5 my-3"
                    @focusin="scrollWidgetContainer"
                    @keypress="textAreaChatChanged"
                    @keyup.enter.exact.prevent="sendMessage"
                    @keydown.enter.shift.exact.prevent="inputMessage += '\r';"
                >
                    <template v-slot:append>
                        <v-btn
                            v-if="accountRole === 'teacher'"
                            icon
                            class="pointer d-flex justify-center"
                            @click="poll = true"
                        >
                            <v-icon
                                style="transform: rotate(90deg);"
                                class="bigIcon"
                                alt="Umfrage erstellen"
                            >
                                mdi-poll
                            </v-icon>
                        </v-btn>
                        <v-btn
                            v-if="accountRole === 'teacher' || teacherInMatrixRoom"
                            icon
                            class="pointer d-flex justify-center"
                            @click="$refs.attachmentInputChat.click()"
                        >
                            <img
                                :src="paperClipIcon"
                                class="bigIcon"
                                alt="Anhang einfügen"
                            >
                        </v-btn>
                        <input
                            ref="attachmentInputChat"
                            class="visually-hidden"
                            hidden
                            type="file"
                            accept="image/*"
                            @change="sendFileMessage"
                        >
                    </template>
                </v-textarea>

                <div
                    v-if="!inputMessage && audioCheck"
                    oncontextmenu="return false;"
                    style="z-index: 10 !important"
                >
                    <v-tooltip
                        v-model="recordingTooltip"
                        top
                        :open-on-hover="false"
                    >
                        <template v-slot:activator="{ attrs }">
                            <v-btn
                                v-long-press="500"
                                v-bind="attrs"
                                fab
                                icon
                                class="ma-0"
                                @long-press-start="startRecording"
                                @click="keyboard ? (toggle ? stopRecording() : startRecording()) : null"
                                @long-press-stop="stopRecording()"
                                @keypress.enter="startRecording"
                                @keyup.enter="stopRecording()"
                                @keypress.space="startRecording"
                                @keyup.space="stopRecording()"
                            >
                                <div
                                    id="micIcon"
                                    :class="toggle ? 'iconToRecording' : ''"
                                />
                            </v-btn>
                        </template>
                        <span> {{ recordingTime }}</span>
                    </v-tooltip>
                </div>
                <v-tooltip
                    v-else
                    :disabled="!showTooltips"
                    top
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            fab
                            text
                            class="ma-0"
                            v-on="on"
                            @click="sendMessage"
                        >
                            <img
                                :src="sendenIcon"
                                alt="Nachricht senden"
                                height="30"
                            >
                        </v-btn>
                    </template>
                    <span>Senden</span>
                </v-tooltip>
            </div>
            <div v-if="!room">
                Keine Gruppe ausgewählt
            </div>
        </div>

        <!-- creat poll card/bottom sheet -->
        <v-card v-if="poll">
            <v-card-text style="background-color: white">
                <Poll
                    :chat-mode="true"
                    @closePollCard="poll = false"
                />
            </v-card-text>
        </v-card>

        <v-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            overlay-opacity="0.8"
            persistent
            max-width="400"
        >
            <v-card 
                class="cardStyle"
                style="height: 80vh; overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="chat-header d-flex align-center justify-space-between">
                    <p>
                        Nachricht löschen?
                    </p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p
                        tabindex="0"
                        class="mb-0"
                        style="width: fit-content"
                    >
                        Wollen Sie diese Nachricht wirklich löschen?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />

                    <v-btn
                        color="#bdbdbd"
                        class="optionBtn text-none"
                        style="color: white"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon iconToWhite mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="rot"
                        class="optionBtn text-none"
                        style="color: white"
                        @click="() => {deleteMessage(); deleteDialog = false}"
                    >
                        <img
                            :src="papierkorbIcon"
                            class="icon iconToWhite mr-2"
                            alt="löschen"
                        >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Fullscreen image dialog -->
        <FullscreenImageDialog
            :image="imgFullscreen"
            :file-name="'eKlara_chat_bild'"
            :image-alt-text="'Bild im Chat'"
            @close="imgFullscreen = null"
        />
        <v-dialog
            v-if="groupDialog"
            v-model="groupDialog"
            width="400"
        >
            <v-card>
                <v-card-title class="d-flex align-center justify-space-between">
                    {{ groupName }}
                    <v-btn
                        icon
                        @click="groupDialog = false"
                    >
                        <v-icon style="transform: rotate(45deg)">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <h1 class="formHeader">
                        {{ groupParticipants.length }} Teilnehmer
                    </h1>

                    <p
                        v-for="participant in groupParticipants"
                        :key="participant"
                        class="mt-2"
                        style="font-size: 16px"
                    >
                        {{ participant }}
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import * as sdk from "matrix-js-sdk";
import * as backend from "../../api/backend";

import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-aus-89.svg'
import papierkorbIcon from '../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import pfeilIcon from '../../assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg'
import downloadIcon from '../../assets/Icons/download-116.svg'
import sendenIcon from '../../assets/Icons/FaceliftIcons/absenden_versenden_flugzeug.svg'
import paperClipIcon from '@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

import {mapActions, mapState, mapGetters} from 'vuex';
import sanitizeHtml from '../../util/sanitizeHtml';
import Recorder from 'js-audio-recorder';
import Poll from '@/components/Poll';
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";
import DeleteChatButton from "@/components/Chat/Components/DeleteChatButton";
import FullscreenImageDialog from "@/components/Utils/FullscreenImageDialog";

import urlify from '../../util/urlify';

export default {
    name: "ChatWidget",
    components: {
        FullscreenImageDialog,
        DeleteChatButton,
        ScrollAreaMagnifier,
        Poll,
    },
    props: {
        showTooltips: {required: false, default: true},
        room: {required: true},
        matrixClient: {required: true},
        currentChild: {required: false, type: Object}
    },
    data: () => ({
        sanitizeHtml,
        urlify,

        inputMessage: "",
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: true
        },
        isReading: false,
        offsetHeightChat: 32,

        lautsprecherIcon,
        lautsprecherAusIcon,
        papierkorbIcon,
        pfeilIcon,
        downloadIcon,
        sendenIcon,
        paperClipIcon,
        cancelIcon,

        recordingTime: '',
        elapsedTimeIntervalRef: '',
        recordingTooltip: false,
        selectedEvent: null,
        deleteDialog: false,
        toggle: false,
        showTextTimeout: null,
        audio: new Audio(),
        audioSrc: '',
        matrixUsers: [],
        realMatrixUsers: [],
        maintainers: [],
        teachers: [],
        pupils: [],
        teacherPics: [],
        audioCheck: false,
        loadedAudios: [],
        loadedImages: [],
        recorder: new Recorder(),
        poll: false,
        person: {
            accessibility: {
                screenreader: false,
            }
        },
        imgFullscreen: null,
        waitForMessageWatcherEnabled: false,
        isTouchDevice: false,
        teacherInMatrixRoom: false,
        groupDialog: false,
        groupName: '',
        groupParticipants: [],
        systemCreatedMatrixRoomsIds: [],   // ids of group chats generated by the system
    }),
    computed: {
        ...mapState("util", ["keyboard", "windowWidth"]),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState('translation', ['targetLang']),
        ...mapState("auth", [ "token"]),
        ...mapState("magnifier", ["magnifier"]),
        events: function () {
            return this.room.timeline;
        },
        canUserDeleteRoom() {
            return (room) => {
                const notTeacherChatroom = room.name !== 'Lehrer';
                const notSystemCreatedRoom = !this.systemCreatedMatrixRoomsIds.includes(room._id);
                return this.accountRole === 'teacher' && notTeacherChatroom && notSystemCreatedRoom;
            };
        },
    },
    watch: {
        currentChild: async function() {
            if (this.accountRole === 'parent') {
                const appointments = await this.getAppointmentsParent(this.currentChild._id);
                appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })
                })
            }
        },
        room: function (newVal, oldVal) {
            // watch it
        },
        events() {
            if(this.waitForMessageWatcherEnabled) {
                this.waitForMessageWatcherEnabled = false;
                const message = this.events[0].getContent().body;
                setTimeout(() => {
                    if (message) {
                        if (message.startsWith('$imageFileId:')) {
                            this.loadImageFile(message.replace('$imageFileId:', ''));
                        } else if (message.startsWith('$fileId:')) {
                            this.loadFile(message.replace('$fileId:', ''))
                        }
                    }
                }, 200);
            }
        }
    },
    async created() {
        while (!this.$refs.scroll) {
            await this.sleep(500);
        }
        this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
    },
    async mounted() {
        await Promise.all([this.requestAppointments(), this.requestPupil()]);
        this.requestTeachers();
        this.requestMaintainers();
        this.checkPermission();
        this.requestPerson();
        this.realMatrixUsers = await this.getMatrixUsersInRoom(this.room._id);
        this.teacherInMatrixRoom = this.isTeacherInMatrixRoom();
        this.events.forEach((el) => {
            const message = el.getContent().body;
            if(message) {
                if (message.startsWith('$imageFileId:')) {
                    this.loadImageFile(message.replace('$imageFileId:', ''));
                } else if(message.startsWith('$fileId:')) {
                    this.loadFile(message.replace('$fileId:', ''))
                }
            }
        })
        setTimeout(() => {
            this.$refs.scrollAreaMagnifier.sendMessage();
        }, 500)
        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
        this.isTouchDevice = (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));

        this.getSystemCreateRoomIds();
    },
    async unmounted() {
        this.loadedAudios.forEach(async (el) => {
            URL.revokeObjectURL(el[1]);
        });
        this.teacherPics.forEach(async (pic) => {
            if(pic[2]) {
                URL.revokeObjectURL(pic[2]);
            }
        });
    },
    methods: {
        ...mapActions("matrix", ['removeMessage', 'getMatrixName', 'getMatrixUsersInRoom', 'getMatrixUsersFullNameByRoomId']),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('appointments', ['getAppointments', 'getAppointmentsParent']),
        ...mapActions('pupils', ['getPupils', 'getMePupil']),
        ...mapActions('teachers', ['getTeachers', 'getProfilePicture']),
        ...mapActions('parents', ['getParents']),
        ...mapActions('maintainers', ['getMaintainers']),
        ...mapActions('groups', ['getGroupsInfo']),

        isTeacherInMatrixRoom() {
            return this.realMatrixUsers.some((user) => {
                return this.teachers.some((teacher) => teacher.account === user.account)
            });
        },

        downloadImage(fileUrl) {
            let el = document.createElement("a");
            el.setAttribute("href", fileUrl);
            el.setAttribute("download", 'eKlara_Chat_Bild');
            document.body.appendChild(el);
            el.click();
            el.remove();
        },

        getThisImage(fileId) {
            const img = this.loadedImages.find((el) => {
                return el.file === fileId;
            });
            if(img && img.url) {
                return img.url;
            } else {
                return img;
            }
        },

        sendFileMessage() {
            const file = Array.from(this.$refs.attachmentInputChat.files);
            const formData = new FormData();
            formData.append("file", file[0], file[0].name);

            this.XmlHttpRequest = backend.postFileMessage(
                file,
                this.room._id,
            );

            this.XmlHttpRequest.onerror = (e) => {
                console.error("Private upload error:", e);
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.onabort = (e) => {
                console.warn("Private upload aborted");
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.addEventListener("load", (e) => {
                if (this.XmlHttpRequest.status !== 201) {
                    console.error(
                        "Private upload failed:",
                        this.XmlHttpRequest.response
                    );
                }
                const fileId = JSON.parse(this.XmlHttpRequest.response)._id;
                this.$emit('sendMessage', '$imageFileId:' + fileId);
                this.loadImageFile(fileId);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scrollAreaMagnifier.sendMessage();
                }, 500)
                this.XmlHttpRequest = null;
            });
            this.XmlHttpRequest.send(formData);
        },

        async requestPerson() {
            if (this.accountRole === 'pupil') {
                this.person = await this.getMePupil();
            }
        },
        textAreaChatChanged() {
            let el = document.getElementById("chatTextArea");
            this.offsetHeightChat = el.offsetHeight > 100 ? 100 : el.offsetHeight;
        },
        scrollWidgetContainer() {
            if (this.windowWidth < 900) {
                // use scrollintoview
                this.$refs.chatTextArea.$el.scrollIntoView(true);
            }
        },

        async loadMore() {
            this.matrixClient.scrollback(
                this.room,
                1,
                (err, res) => {
                    if (err) {
                        console.error(err);
                    }
                });
            this.waitForMessageWatcherEnabled = true;
        },

        async requestAppointments() {
            if(this.accountRole === 'pupil') {
                const appointments = await this.getAppointments();
                appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })

                })
            }
            if(this.accountRole === 'parent') {
                const appointments = await this.getAppointmentsParent(this.currentChild._id);
                appointments.forEach((el) => {
                    el.teachers.forEach((element) => {
                        const index = this.matrixUsers.indexOf(element);
                        if (index === -1) {
                            this.matrixUsers.push(element);
                        }
                    })
                })
            }
        },
        async requestPupil() {
            if(this.accountRole === 'teacher') {
                const pupils = await this.getPupils();
                this.pupils = pupils;
                    const teachers = await this.getTeachers();
                    const parents = await this.getParents();

                    const mappedPupils = pupils.map(pupil => pupil);
                    const mappedTeachers = teachers.map(teacher => teacher);
                    const mappedParents = parents.map(parent => ({
                        ...parent,
                        name: pupils.find((pupil) => pupil._id === parent.pupils[0]).name,
                        lastName: 'Eltern',
                    }));

                    this.matrixUsers = [
                        ...mappedPupils,
                        ...mappedTeachers,
                        ...mappedParents
                    ];
            }
        },
        async requestTeachers() {
            this.teachers = await this.getTeachers();
            if(this.accountRole === 'maintainer') {
                this.matrixUsers = this.teachers;
            }
            //trim down the ridiculous size of duplicate matrixUsers, only keep unique entries
            this.matrixUsers = [
                ...new Set(this.matrixUsers.map((el) => JSON.stringify(el)))]
                .map((str) => JSON.parse(str));

            await this.setTeacherProfilePics();
        },
        async requestMaintainers() {
            if(this.accountRole === 'teacher') {
                this.maintainers = await this.getMaintainers();
            }
        },
        async setTeacherProfilePics() {
            this.teachers.map(async (teacher) => {
                let matchingTeacher = this.matrixUsers.find((matrixUser) => {
                    return teacher.account == matrixUser.account;
                });
                if (matchingTeacher){
                  const matchingTeacherMatrixName = await this.getMatrixName(matchingTeacher.account);
                  if (matchingTeacher.profilePicture) {
                      let profilePictureBlob = await this.getProfilePicture(matchingTeacher._id);
                      const objectURL = URL.createObjectURL(profilePictureBlob);
                      this.teacherPics.push([matchingTeacher.account, matchingTeacherMatrixName, objectURL]);
                  } else {
                    this.teacherPics.push([matchingTeacher.account, matchingTeacherMatrixName, false]);
                  }
                }
            });
        },
        getTeacherPic(event) {
          const matchingTeacherPic = this.teacherPics.find((teacherPic) => {
            return teacherPic[1] == event.sender.userId;
          });
          if (matchingTeacherPic){
            return matchingTeacherPic[2];
          }

          return null;
        },
        checkRoomName(name) {
            // group room names look like: group Room <id, groupName>, so return substring between , and >
            if (name.includes("group Room")) {
                const start = name.indexOf(',');
                const end = name.indexOf('>');
                return name.slice(start+1, end).trim();
            }
            const id = name.replace(this.accountId, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", "");
            let secondUser = this.matrixUsers.find((el) => {
                return el.account === id;
            });
            if(secondUser) {
                return secondUser.lastName +  ' ' + secondUser.name;
            } else {
                if(this.accountRole === 'teacher') {
                    secondUser = this.maintainers.find((el) => {
                        return el.account === id;
                    });
                    if(secondUser) {
                        return 'Verwaltung';
                    } else {
                        return 'Unbekannter Nutzer';
                    }
                }
                return 'Unbekannter Nutzer';
            }
        },
        checkPermission() {
            Recorder.getPermission().then(() => {
                this.audioCheck = true;
            }, (error) => {
                console.error(`${error.name} : ${error.message}`);
            });
        },
        async loadFile(file) {
            const res = await backend.getFileMessage(
                this.room._id,
                file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this.loadedAudios.push({'file' : file, 'url': url})
                });
        },

        async loadImageFile(file) {
            const res = await backend.getFileMessage(
                this.room._id,
                file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this.loadedImages.push({'file' : file, 'url': url})
                });
        },

        playPause(file) {
            if(this.audio) {
                this.audio.pause();
                this.audio = null;
                if(this.audioSrc === file) {
                    this.audioSrc = '';
                    return
                }
                this.audioSrc = '';
            }
            this.audio = new Audio(this.loadedAudios.find(el => el.file === file).url);
            this.audioSrc = file;
            this.audio.play();
            this.audio.addEventListener("ended", () => {
                this.audioSrc = '';
            });

        },

        startRecording() {
            if(!this.toggle) {
                this.startTimer();
                this.recordingTooltip = true;
                this.toggle = true;

                this.recorder.startRecord();
            }
        },
        stopRecording() {
            this.recordingTooltip = false;
            this.toggle = false;
            this.recorder.stopRecord();
            this.handleRecording(this.recorder.getWAVBlob());
            setTimeout(() => {
              this.stopTimer();
            }, 500);
        },
        handleRecording(blob) {
            const formData = new FormData();
            formData.append("file", blob, new Date().toLocaleDateString().replaceAll(".", "_") + '.wav');

            this.XmlHttpRequest = backend.postVoiceMessage(
                blob,
                this.room._id,
            );

            this.XmlHttpRequest.onerror = (e) => {
                console.error("Private upload error:", e);
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.onabort = (e) => {
                console.warn("Private upload aborted");
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.addEventListener("load", (e) => {
                if (this.XmlHttpRequest.status !== 201) {
                    console.error(
                        "Private upload failed:",
                        this.XmlHttpRequest.response
                    );
                }
                const fileId = JSON.parse(this.XmlHttpRequest.response)._id;
                this.$emit('sendMessage', '$fileId:' + fileId);
                this.loadFile(fileId);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scrollAreaMagnifier.sendMessage();
                }, 500)
                this.XmlHttpRequest = null;
            });
            this.XmlHttpRequest.send(formData);
        },

        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        getTime(event) {
            if(event.sentTime) {
                return event.sentTime;
            }
            let age = event.getUnsigned().age;
            if(age === undefined) {
                event.sentTime = new Date(Date.now()).toString();
            } else {
                event.sentTime = new Date(Date.now() - age).toString();
            }
            // TODO: proper format/hide date if message was sent today and always show time
            return new Date(Date.now() - age).toString();
        },
        //name is sliced as it ends with the gender char. eg. Fritzm means name=Fritz, gender=male

        getUsername(event) {
            /*return event.sender.rawDisplayName.slice(0, event.sender.rawDisplayName.length-1);*/ //TODO and me too
            return event.sender.rawDisplayName;
        },
        isMe(event) {
            return (this.matrixClient.credentials.userId === event.sender.userId);
        },
        fromPupil(event) {
            const senderMatrixUser = this.realMatrixUsers.find((user) => {
                return user.name === event.sender.userId;
            });
            if(senderMatrixUser) {
                return this.pupils.filter((pupil) => {
                    return pupil.account === senderMatrixUser.account;
                }).length > 0;
            }
            return true;
        },
        sendMessage() {
            if ((this.inputMessage.trim())) {
                this.$emit('sendMessage', this.inputMessage);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scrollAreaMagnifier.sendMessage();
                }, 500)
            }
        },
        async deleteMessage() {
            if(this.isMe(this.selectedEvent)) {
                this.$emit('deleteMessage', this.selectedEvent);
            } else {
                const params = {
                    eventId: this.selectedEvent.event.event_id,
                    roomId: this.room.roomId,
                    txnId: this.matrixClient.makeTxnId(),
                }
                this.removeMessage(params);

            }

            if(this.selectedEvent.getContent().body.startsWith('$fileId:')) {
                const res = await backend.deleteVoiceMessage(
                    this.room._id,
                    this.selectedEvent.getContent().body.replace('$fileId:','')
                );
            }
            if(this.selectedEvent.getContent().body.startsWith('$imageFileId:')) {
                const res = await backend.deleteFileMessage(
                    this.room._id,
                    this.selectedEvent.getContent().body.replace('$imageFileId:','')
                );
            }

        },
        clickBack() {
            this.$emit("exitRoom");
        },

        async greet(message, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== 'de') {
                    message = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: message
                    });

                    this.setTranslatedText(message);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = message;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = () => {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        startTimer() {
          var startTime = new Date();
          this.recordingTime = this.getElapsedTime(startTime);
          this.elapsedTimeIntervalRef = setInterval(() => {
            this.recordingTime = this.getElapsedTime(startTime);
          }, 1000);
        },
        stopTimer() {
          if (typeof this.elapsedTimeIntervalRef !== "undefined") {
            clearInterval(this.elapsedTimeIntervalRef);
            this.elapsedTimeIntervalRef = undefined;
          }
          this.recordingTime = '';
        },
        getElapsedTime(startTime) {
          // Record end time
          let endTime = new Date();
          // Compute time difference in milliseconds
          let timeDiff = endTime.getTime() - startTime.getTime();
          // Convert time difference from milliseconds to seconds
          timeDiff = timeDiff / 1000;
          // Extract integer seconds that don't form a minute using %
          let seconds = Math.floor(timeDiff % 60); //ignoring incomplete seconds (floor)
          // Pad seconds with a zero if necessary
          let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
          // Convert time difference from seconds to minutes using %
          timeDiff = Math.floor(timeDiff / 60);
          // Extract integer minutes that don't form an hour using %
          let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, because they've been handled as seconds
          // Pad minutes with a zero if necessary
          let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
          // Convert time difference from minutes to hours
          timeDiff = Math.floor(timeDiff / 60);
          // Extract integer hours that don't form a day using %
          let hours = timeDiff % 24; //no need to floor possible incomplete hours, because they've been handled as seconds
          // Convert time difference from hours to days
          timeDiff = Math.floor(timeDiff / 24);
          // The rest of timeDiff is number of days
          let days = timeDiff;
          let totalHours = hours + (days * 24); // add days to hours
          let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours + "";
          if (totalHoursAsString === "00") {
            return minutesAsString + ":" + secondsAsString;
          } else {
            return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
          }
        },
        getAccessibilityTime(time) {
            const charArray = time.split('');

            let spelledOutTime = '';
            if(charArray[0] !== '0') {
                spelledOutTime = spelledOutTime + charArray[0];
            }
            spelledOutTime = spelledOutTime + charArray[1];
            spelledOutTime = spelledOutTime + ' Uhr ';
            if(charArray[3] !== '0') {
                spelledOutTime = spelledOutTime + charArray[3];
            }
            spelledOutTime = spelledOutTime + charArray[4];
            return spelledOutTime;
        },

        async showGroupInfo(room) {
            this.groupName = this.checkRoomName(room.name);
            this.groupParticipants = await this.getMatrixUsersFullNameByRoomId(room._id);
            this.groupParticipants.sort();
            this.groupDialog = true;
        },

        // saves the ids of the system created matrix room ids into systemCreateMatrixRoomsIds
        async getSystemCreateRoomIds() {
            let groups = await this.getGroupsInfo();
            this.systemCreatedMatrixRoomsIds = groups.map(el => el.matrixRoom);
            this.systemCreatedMatrixRoomsIds =
                this.systemCreatedMatrixRoomsIds.filter(el => { return el !== undefined });
        }
    },
};
</script>

<style>
.chat-send-input .v-input__append-inner {
    margin-top: 8px !important;
}

.chat-send-input .v-text-field__slot textarea {
    padding-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.chat-header {
  background-color: #8ab450;
  border-radius: 15px 15px 0 0;
  font-size: larger;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: white;
}

.chat-send-input {
    background-color: white;
    border-radius: 50px;
    width: 100%;
}

.chat-send-wrapper {
    display: inline-flex;
    background-color: #f2f2f2;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    right: 0;
}

.group-chat-wrapper {
    flex: auto;
    max-height: calc(80vh - 55px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white !important;
    border-radius: 0 0 15px 15px;
}

.chat-body {
    flex: auto;
    overflow-x: hidden;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
    border-radius: 8px;
    width: 36px !important;
    height: 36px !important;
}

.group-chat-header-text {
    color: white;
}

.group-chat-header {
    background-color: #95c94b; // 3caa68 - 5% Luminosity
    min-height: 56px;
    height: 56px;
}

.chat-header-btn {
    background-color: white !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 176px);
    height: calc(80vh - 179px);
    overflow: hidden;
    margin-bottom: 2px;
}

.scroll-area-disabled {
  position: relative;
  margin: auto;
  max-height: calc(80vh - 176px);
  height: calc(80vh - 179px);
  overflow: hidden;
  margin-bottom: 2px;
}

.chat-message {
    position: relative;
    border-radius: 5px;
    max-width: 75%;
    margin: 2px 2px 2px 19px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}
.chat-message:before {
    opacity: 0 !important;
}

.chat-message-content {
    border-radius: 0 5px 5px 5px;
    box-shadow: 1px 2px 3px silver;
    background-color: var(--v-blau-base);
    /*background-color: #9b91c8;*/ //use me when using chat gender colors again
    color: white;
    padding: 9px;
}
.male-chat {
    background-color: var(--v-blau-base) !important;
}
.female-chat {
    background-color: var(--v-pink-base) !important;
}

.myMessage-content {
    border-radius: 5px 5px 0 5px !important;
    background-color: var(--v-grau-base);
    color: black;
}

.progressbar {
    border-radius: 5px;
}

.icon {
    height: 20px;
}

.bigIcon {
    height: 30px;
}

.pointer {
    cursor: pointer;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText{
    color: var(--v-dunkelgrau-base);
}

.iconToRecording {
    background-image: url('../../assets/Icons/recording-dot.svg') !important;
}

#micIcon {
    background-image: url('../../assets/Icons/FaceliftIcons/mikrofon_an.svg');
    width: 30px;
    height: 30px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
  display: inherit;
  font-size: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
  margin-left: inherit;
  margin-right: inherit;
  font-weight: inherit;
}

#mandatoryFloatBtn {
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translateY(-50%);
    bottom: 2%;
}

.formHeader {
    font-size: 16px;
    margin-bottom: 20px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
  width: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
  border-radius: 6px;
  width: 11px;
}
</style>
