<template>
    <div>
        <progress-bar
            :title="teacherUploadProgress === 100 ? 'Konvertieren' : 'Hochladen'"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :indeterminate="teacherUploadProgress === 100"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() }: () => {}"
        />

        <v-dialog
            v-model="visible"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">
                    Video in Video
                </v-card-title>
                <v-window
                    v-model="step"
                    touchless
                >
                    <v-window-item :value="1">
                        <v-card-text>
                            <vue-custom-scrollbar
                                class="scroll-area-fachPopup"
                                :settings="scrollSettings"
                            >
                                <div>
                                    <v-row align="center">
                                        <v-col
                                            cols="auto"
                                            align-self="center"
                                            class="ml-1"
                                        >
                                            <v-btn
                                                elevation="3"
                                                outlined
                                                color="grey lighten-1"
                                                @click="showInfoPopup"
                                            >
                                                <img
                                                    style="width: 25px; opacity: 0.8"
                                                    :src="infoIcon"
                                                >
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            Laden Sie ein zweites Video hoch, das anschließend im primären Video abgespielt wird.
                                        </v-col>
                                    </v-row>
                                    <div class="text-center">
                                        Primäres Video
                                    </div>
                                    <v-row
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col cols="10">
                                            <video
                                                v-if="videoBlobs.firstUrl"
                                                ref="primaryVideoPlayer"
                                                :src="videoBlobs.firstUrl"
                                                style="background-color: black; width: 100%"
                                                height="auto"
                                                @ended="$refs.videoRecorder.stopRecording()"
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                                            <div v-else>
                                                <v-progress-circular indeterminate />
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div class="text-center">
                                        Sekundäres Video aufnehmen
                                    </div>
                                    <v-row
                                        align="center"
                                        justify="center"
                                    >
                                        <v-col cols="10">
                                            <video-recorder
                                                ref="videoRecorder"
                                                :on-record-started="recordStarted"
                                                :on-record-stopped="recordStopped"
                                                :on-video-available="videoRecorded"
                                                width="100%"
                                                height="auto"
                                            />
                                        </v-col>
                                    </v-row>
                                    <input
                                        ref="fileUpload"
                                        type="file"
                                        style="display: none"
                                        hidden
                                        name="file"
                                        accept=".mp4"
                                        @change="videoSelected"
                                    >
                                    <v-row
                                        justify="center"
                                        align="center"
                                    >
                                        <v-col
                                            cols="5"
                                            class="px-1"
                                        >
                                            Stattdessen ein sekundäres Video hochladen
                                        </v-col>
                                        <v-col
                                            cols="auto"
                                            class="px-1"
                                        >
                                            <v-btn
                                                @click="$refs.fileUpload.click()"
                                            >
                                                Hochladen
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </vue-custom-scrollbar>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                            <vue-custom-scrollbar
                                class="scroll-area-fachPopup"
                                :settings="scrollSettings"
                            >
                                <div>
                                    Video Vorschau
                                </div>
                                <v-row>
                                    <v-col class="mb-10">
                                        <video-in-video
                                            ref="videoInVideo"
                                            :primary-video-src="videoBlobs.firstUrl"
                                            :secondary-video-src="videoBlobs.secondUrl"
                                            primary-video-width="100%"
                                            secondary-video-width="180"
                                            :size-controls="false"
                                            alt-error="Ihr Browser unterstützt diese Vorschau möglicherweise nicht. Nach dem Hochladen funktioniert die Video in Video Funktion allerdings wie gewohnt."
                                        />
                                    </v-col>
                                </v-row>
                            </vue-custom-scrollbar>
                        </v-card-text>
                    </v-window-item>
                </v-window>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="() => {
                            if(step === 1){
                                visible = false;
                                this.$refs.videoRecorder.stopPreview();
                            }else{
                                step--;
                                this.$refs.videoInVideo.stop();
                            }
                        }"
                    >
                        {{ step === 1 ? 'Abbrechen' : 'Zurück' }}
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        :disabled="!videoBlobs.secondUrl"
                        @click="() => {
                            if(step === 1) {
                                step++;
                            } else {
                                uploadFile();
                            }
                        }"
                    >
                        {{ step === 1 ? 'Weiter' : 'Speichern' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="infoPopupVisible"
            max-width="650"
            scrollable
            width="85vw"
        >
            <v-card>
                <v-toolbar
                    color="orange"
                    dense
                    style="max-height: 65px"
                >
                    <v-toolbar-title class="white--text">
                        Information
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        elevation="2"
                        x-small
                        class="ml-2 pa-0"
                        style="width: 35px; height: 35px; opacity: 0.8"
                        @click="infoPopupVisible = false"
                    >
                        <img
                            :src="schliesenIcon"
                            alt="Schließensymbol"
                            style="height: 24px;"
                        >
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    <div
                        class="font-weight-bold"
                        style="font-size: large"
                    >
                        Bitte beachten Sie beim Aufnehmen von Videos in Gebärdensprache die Vorgaben der BITV
                    </div>
                    <div
                        class="mt-3"
                        style="font-size: larger"
                    >
                        <div class="my-1">
                            Anlage 2, Teil 1 BITV 2.0:
                        </div>
                        <ol>
                            <li>
                                Schatten auf dem Körper der Darstellerin oder des Darstellers sind zu vermeiden. Die Mimik und das Mundbild müssen gut sichtbar sein.
                            </li>
                            <li>
                                Der Hintergrund ist statisch zu gestalten. Ein schwarzer oder weißer Hintergrund ist zu vermeiden.
                            </li>
                            <li>
                                Der Hintergrund sowie die Kleidung und die Hände der Darstellerin oder des Darstellers stehen im Kontrast zueinander. Dabei soll die Kleidung dunkel und einfarbig sein.
                            </li>
                            <li>
                                Das Video ist durch das Logo für die Deutsche Gebärdensprache gekennzeichnet. Die farbliche Gestaltung des Logos kann dem jeweiligen Design des Auftritts angepasst werden.
                            </li>
                        </ol>
                    </div>
                    <div class="mt-2">
                        Alle Informationen finden Sie <a href="https://bik-fuer-alle.de/was-verlangt-die-bitv-138.html">hier</a>.
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import VideoRecorder from "@/components/Utils/VideoRecorder";
import * as backend from "@/api/backend";
import {mapState} from "vuex";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import ProgressBar from "@/components/ProgressBar";
import vueCustomScrollbar from "vue-custom-scrollbar";
import schliesenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";

export default {
    name: "UploadVideoInVideoPopup",
    components: {ProgressBar, VideoInVideo, VideoRecorder, vueCustomScrollbar},
    props: {
        onClose: {
            required: false
        }
    },
    data() {
        return{
            infoIcon,
            schliesenIcon,
            teacherUploadProgress: 0,
            showTeacherUploadProgress: false,
            teacherXmlHttpRequest: null,
            visible: false,
            step: 1,
            primaryFile: null,
            appointment: null,
            videoBlobs: {
                firstBlob: null,
                firstUrl: null,
                secondBlob: null,
                secondUrl: null
            },
            scrollSettings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            infoPopupVisible: false,
        }
    },
    computed: {
        ...mapState("auth", ["token"]),
    },
    methods: {
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties,
        show(primaryVideo, appointment){
            this.visible = true;
            if(this.$refs.videoRecorder)
              this.$refs.videoRecorder.requestCamera();
            this.videoBlobs.firstBlob = null;
            this.videoBlobs.firstUrl = null;
            this.videoBlobs.secondBlob = null;
            this.videoBlobs.secondUrl = null;
            this.step = 1;
            if(this.$refs && this.$refs.videoRecorder)
                this.$refs.videoRecorder.reset();
            this.getPrimaryVideo(primaryVideo, appointment);
        },
        async getPrimaryVideo(primaryVideo, appointment){
            if(primaryVideo && appointment){
                this.primaryFile = primaryVideo;
                this.appointment = appointment;
                const res = await backend.getTeacherUpload(appointment._id, primaryVideo.file);
                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                }).then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoBlobs.firstUrl = url;
                });
            }else
                console.error("Can not open without a primary video");
        },
        recordStarted(){
            if(this.$refs.primaryVideoPlayer) {
                this.$refs.primaryVideoPlayer.currentTime = 0;
                this.$refs.primaryVideoPlayer.play();
            }
        },
        recordStopped() {
            this.$refs.primaryVideoPlayer.pause();
            this.step++;
        },
        videoRecorded(blob){
            var url = window.URL.createObjectURL(blob);
            this.videoBlobs.secondUrl = url;
            this.videoBlobs.secondBlob = blob;
        },
        async setPipReference(pipId){
            const response = await backend.patchTeacherUploadPip(this.appointment._id, this.primaryFile._id, { pipReference: pipId });


            if(response.ok){
                this.visible = false;
                this.$refs.videoRecorder.stopPreview();
                if(this.onClose){
                    this.onClose();
                }
            }
        },
        videoSelected(evt){


            let file = this.$refs.fileUpload.files[0];

            var url = window.URL.createObjectURL(file);

            this.videoBlobs.secondUrl = url;
            this.videoBlobs.secondBlob = file;
            this.step++;
        },
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // // eslint-disable-next-line vue/no-unused-properties,
        async uploadFile() {
            if (this.videoBlobs.secondBlob) {
                if (this.videoBlobs.secondBlob > 1000000000) {
                    console.error("Video too big")
                    // this.showSnackbar({ message: "Datei zu groß, max. 1 GB" });
                    return;
                }

                const formData = new FormData();
                formData.append("file", this.videoBlobs.secondBlob, `Record_${new Date().toISOString().slice(0,10).replace(/-/g,"_")}.webm`);
                formData.append('visibleFor', JSON.stringify([]));
                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    this.appointment._id,
                    this.videoBlobs.secondBlob
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;

                    console.error(e);
                    if(e && e.target && e.target.response){

                        let data = JSON.parse(e.target.response);
                        this.setPipReference(data.schoolSubjectAppointmentUploadId);
                    }
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },
        showInfoPopup() {
            this.infoPopupVisible = true;
        }
    }
}
</script>

<style scoped>

.scroll-area-fachPopup {
    /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
    max-height: calc(100vh - 250px);
}

</style>
