<template>
    <div
        class="d-flex"
    >
        <div
            style="line-height: 20px"
        >
            <div
                class="d-flex align-center"
                :class="chatRoomEvent.isDeleted ? 'outdated-image' : ''"
                style="min-height: 24px"
            >
                <!--
                <div
                    v-if="['application/pdf'].includes(loadedFileType)"
                    @click="() => { loadFullscreenPreview(loadedFile); }"
                >
                    <object
                        v-if="loadedFile"
                        :data="loadedFile + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'"
                        type="application/pdf"
                        class="iOS_ResponsiveHack"
                        style="cursor: pointer; pointer-events: none; width: 100%"
                    />
                </div>
                -->
                <p
                    v-if="chatRoomEvent.isDeleted"
                    style="font-size: 14px; color: white; margin-right: 0.5rem;"
                >
                    Gelöscht:
                </p>

                <div
                    v-if="['image/gif'].includes(loadedFileType)"
                    class="py-0 d-flex justify-center align-center"
                    @click="() => { loadFullscreenPreview(loadedFile); }"
                >
                    <img
                        :id="chatRoomEvent.text"
                        :src="loadedFile"
                        :alt="chatRoomEvent.fileName"
                        style="cursor: pointer; max-width: 100%"
                    >
                </div>
                <!--
                <div
                    v-else-if="['application/doc', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(loadedFileType)"
                    class="py-0 d-flex justify-center align-center"
                    @click="downloadFile(loadedFile)"
                >
                    <v-icon
                        class="mb-2"
                        align="center"
                        color="#414141"
                        size="45px"
                        contain
                    >
                        {{ uploadIcon(chatRoomEvent.fileName) }}
                    </v-icon>
                    <p
                        v-if="chatRoomEvent.fileName"
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        {{ chatRoomEvent.fileName }}
                    </p>
                    <p
                        v-else
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        Datei herunterladen
                    </p>
                </div>
                -->
                <div
                    v-else-if="(['video/x-ms-wmv', 'video/x-flv', 'video/mpeg', 'video/mp4', 'video/quicktime'].includes(loadedFileType))"
                    class="py-0 justify-center"
                    style="margin-bottom: 5px"
                >
                    <video
                        :src="loadedFile"
                        width="100%"
                        height="300px"
                        controls
                    />
                </div>
                <div
                    v-else-if="['audio/wav', 'audio/mpeg', 'audio/x-ms-wma', 'audio/m4a', 'audio/aac', 'audio/ogg'].includes(loadedFileType)"
                    class="py-0 d-flex justify-center align-center"
                    @click="playAudio"
                >
                    <v-icon
                        class="mb-2"
                        color="#414141"
                        size="45px"
                        contain
                    >
                        mdi-music-note
                    </v-icon>
                    <p
                        v-if="chatRoomEvent.fileName"
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        {{ chatRoomEvent.fileName }}
                    </p>
                    <p
                        v-else
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        Datei herunterladen
                    </p>
                </div>
                <div
                    v-else-if="isFileThumbnailCompatibleExtension(loadedFileType)"
                    class="py-0 d-flex justify-center align-center"
                    @click="handleFileClicked"
                >
                    <img
                        v-if="hasThumbnail"
                        :id="chatRoomEvent.text"
                        :src="loadedFile"
                        :alt="chatRoomEvent.fileName"
                        style="cursor: pointer; max-width: 100%"
                    >
                    <img
                        v-else
                        :id="chatRoomEvent.text"
                        :src="img_eklaraLogo"
                        :alt="chatRoomEvent.fileName"
                        width="200"
                        height="200"
                        style="cursor:pointer; min-height:100%; min-width:100%; max-width:100%; max-height:100%"
                    >
                </div>
                <div
                    v-else-if="['application/json'].includes(loadedFileType)"
                    class="py-0 d-flex justify-center align-center"
                >
                    <v-icon
                        class="mb-2"
                        color="#414141"
                        size="45px"
                        contain
                    >
                        mdi-file
                    </v-icon>
                    <p
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        Fehler beim Laden der Datei
                    </p>
                </div>
                <div
                    v-else
                    class="py-0 d-flex justify-center align-center"
                    @click="downloadFile(loadedFile)"
                >
                    <v-icon
                        class="mb-2"
                        color="#414141"
                        size="45px"
                        contain
                    >
                        mdi-file
                    </v-icon>
                    <p
                        v-if="chatRoomEvent.fileName"
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        {{ chatRoomEvent.fileName }}
                    </p>
                    <p
                        v-else
                        class="fileName"
                        :style="{'color': fontColor}"
                    >
                        Datei herunterladen
                    </p>
                </div>
            </div>
            <div>
                <p
                    v-if="isFileThumbnailCompatibleExtension(loadedFileType) && ['application/pdf'].includes(loadedFileType)"
                    style="font-size: 12px;"
                >
                    Voschaubild. Um die ganze Datei anzuschauen, laden sie diese bitte herunter.
                </p>
            </div>
        </div>
        <v-spacer />
        <div
            :ref="`menuBtns${chatRoomEvent._id}`"
            class="d-flex flex-column align-center"
            :class="eventFromMe ? 'ownMessage' : 'otherMessage'"
            style="position: absolute;"
        >
            <ChatRoomEventListItemDeleteButton
                v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
                :chat-room-event="chatRoomEvent"
            />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="pa-0 mt-1 prchatRoomEventColorInvert chatMessageBtn"
                        elevation="2"
                        v-on="on"
                        @click="downloadFile(loadedFile)"
                        @keypress.enter="downloadFile(loadedFile)"
                    >
                        <img
                            :src="herunterladenIcon"
                            alt="Bild herunterladen"
                            height="20px"
                        >
                    </v-btn>
                </template>
                <span>Herunterladen</span>
            </v-tooltip>
        </div>
        <v-overlay
            v-if="audioUrl"
            opacity="0.8"
            z-index="95"
        >
            <div class="audioDialogChild">
                <audio
                    :src="audioUrl"
                    controls
                    :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
                >Your browser does not support the audio tag.
                </audio>
                <div class="audioFullScreenOptionsContainer audioMargin">
                    <img
                        :src="cancelIcon"
                        alt="Vollbild schließen"
                        class="iconToWhite pointer optionsIcon"
                        @click="audioUrl = false"
                    >
                    <img
                        :src="downloadIcon"
                        alt="Audio herunterladen"
                        class="iconToWhite pointer optionsIcon"
                        @click="() => downloadFile(audioUrl)"
                    >
                </div>
            </div>
        </v-overlay>
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :files="tempFiles"
            :enable-fullscreen-preview="enableFilePreview"
            :enable-editing="true"
            :is-user-author="eventFromMe"
            :preview-selection="loadedFile"
            :preview-information="chatRoomEvent"
            :preview-selection-index="previewSelectionIndex"
            :thumbnail-url="loadedFile"
            :chat-mode="true"
            :disable-editor="true"
            :file-name="chatRoomEvent.fileName"
            :usage="'chat'"
            @openEditor="openFileInEditor"
            @openLibre="openFileInLibre"
            @close="resetFilePreview"
            @download="downloadFile"
        />
    </div>
</template>
<script>
import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import downloadIcon from "../../../../assets/Icons/download-116.svg";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import {mapActions, mapMutations, mapState} from 'vuex';
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import {isFileThumbnailCompatibleExtension} from "../../../../util/MimetypeHelper";
import img_eklaraLogo from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import * as backend from "../../../../api/backend";

export default {
    name: "ChatRoomEventListFileItem",
    components: {
        ChatRoomEventListItemDeleteButton,
        FilePreviewDialog
    },
    props: {
        chatRoom: { type: Object, required: true },
        chatRoomEvent: { type: Object, required: true },
        chatRoomMembershipRole: { required: true, type: String },
        fontColor: { required: true, type: String }
    },
    data: () => ({
        isFileThumbnailCompatibleExtension,
        loadedFile: null,
        loadedFileType: '',
        hasThumbnail: false,
        audioUrl: null,
        originalFile: null,

        previewFile: null,

        // FilePreviewDialog
        enableFilePreview: false,
        previewSelectionIndex: 0,
        tempFiles: [],

        cancelIcon,
        downloadIcon,
        herunterladenIcon,
        img_eklaraLogo,
    }),
    computed: {
        ...mapState("auth", ["account"]),
        eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
        }
    },
    async mounted() {
        if (this.chatRoomEvent.hasThumbnail) {
            this.loadedFileType = this.chatRoomEvent.loadedFileType;
            this.hasThumbnail = this.chatRoomEvent.hasThumbnail;
            this.loadedFile = this.chatRoomEvent.thumbnail;
            return;
        }
        await this.loadImageFile(this.chatRoomEvent._id);
    },
    unmount() {
        if (this.loadedFile) URL.revokeObjectURL(this.loadedFile);
    },
    methods: {
        ...mapActions('chat', ['getChatRoomEventFile', 'getChatFileThumbnail']),
        ...mapActions('mimeTypes', ['getFileExtension']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async openFileInLibre() {
            let res = await backend.getCopyOfFile(this.chatRoomEvent.file, this.chatRoomEvent.fileAccessToken);
            const copy = await res.json();
            res = await backend.getLibreDocFromFile(copy._id);
            const doc = await res.json();

            const libreAccessToken = doc.accessToken;
            const libreDocId = doc.libreDoc._id;
            const file = doc.file;
            const fileType = await this.getFileExtension(file.mimetype);

            const query = {
                ... this.$route.query,

                fileId: file._id,
                libreDocId: libreDocId,
                accessToken: libreAccessToken,
                fileAccessToken: copy.accessToken,
                fileType: fileType,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },
        openFileInEditor() {

        },
        handleFileClicked() {
            const isFileDeleted = this.checkForDeletion(this.chatRoomEvent);
            if (isFileDeleted) return;
            if (isFileThumbnailCompatibleExtension(this.loadedFileType)) {
                this.loadFullscreenPreview(this.loadedFile);
            } else {
                this.playAudio();
            }
        },
        async loadImageFile(chatRoomEventId) {
            // Call old file route for videos, audios and gifs
            if (['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv', 'wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg', 'gif']
                .includes(this.chatRoomEvent.fileName.split('.').pop().toLowerCase())) {
                const blob = await this.getChatRoomEventFile(chatRoomEventId);
                this.loadedFileType = blob.type;
                const url = URL.createObjectURL(blob);
                this.loadedFile = url;
                this.originalFile = url;
            } else { // Call thumbnail converter route
                const res = await this.getChatFileThumbnail({
                    chatRoomId :this.chatRoom._id,
                    chatRoomEventId
                });
                this.loadedFileType = res.blob.type;
                this.loadedFile = URL.createObjectURL(res.blob);
                this.hasThumbnail = res.hasThumbnail;
            }
        },
        async downloadFile() {
            const isFileDeleted = this.checkForDeletion(this.chatRoomEvent);
            if (isFileDeleted) return;
            if (!this.originalFile) {
                const res = await backend.getFileBlob(this.chatRoomEvent.file, this.chatRoomEvent.fileAccessToken);
                const blob = await res.blob();
                this.originalFile = URL.createObjectURL(blob);
            }
            let el = document.createElement("a");
            el.setAttribute("href", this.originalFile);
            if (this.chatRoomEvent.fileName) {
                el.setAttribute("download", this.chatRoomEvent.fileName);
            } else {
                el.setAttribute("download", 'download');
            }
            document.body.appendChild(el);
            el.click();
            el.remove();
        },
        uploadIcon(name) {
            var icon;
            if (['doc', 'docx', 'txt'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-file-document-outline";
            }
            if (['ppt', 'pptx'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-presentation";
            }
            if (['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase())) {
                icon = "mdi-file-table-outline";
            }
            return icon;
        },
        async playAudio() {
            const isFileDeleted = this.checkForDeletion(this.chatRoomEvent);
            if (isFileDeleted) return;
            this.audioUrl = this.loadedFile;
        },
        async loadFullscreenPreview(el) {
            const isFileDeleted = this.checkForDeletion(el);
            if (isFileDeleted) return;
            this.tempFiles.push(el);
            for (let i = 0; i < this.tempFiles.length; i++) {
                if (el == this.tempFiles[i]) {
                    this.previewSelectionIndex = i;
                }
            }
            const thumbnail = this.loadedFile;
            this.chatRoomEvent.thumbnail = thumbnail;
            this.enableFilePreview = true;
            setTimeout(() => {
                // go to html element of vue component to get correct focus
                this.$refs.previewDialog.$el.focus();
            }, 150);
        },
        resetFilePreview() {
            this.tempFiles = [];
            this.previewSelectionIndex = 0;
            this.enableFilePreview = false;
        },
        checkForDeletion(event) {
            if (event.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return true;
            }
            return false;
        },
    }
};
</script>
<style scoped>
.chatMessageBtn{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
}
.ownMessage{
    left: -30px; top: 25px;
}
.otherMessage{
    right: -30px; top:25px;
}

.audioDialogChild {
    display: flex;
    flex-direction: row;
    position: relative;
    max-height: 90%;
}

.audioFullScreenOptionsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    margin-top: -25px;
    margin-left: 5px;
}

.optionsIcon {
    height: 50px;
    cursor: pointer;
}

.iOS_ResponsiveHack {
    width: 1px;
    min-width: 100%;
    *width: 100%;
}
</style>
