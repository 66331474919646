<template>
    <v-dialog
        v-if="showDialog"
        v-model="showDialog"
        overlay-opacity="0.7"
        :persistent="true"
        width="500"
        content-class="elevation-0"
        opacity="0.8"
    >
        <v-card
            id="card"
            style="border-radius: 16px"
        >
            <v-card-title
                :style="`background-color: ${headerColor}`"
            >
                <h1
                    tabindex="0"
                    style="color: white"
                >
                    Dateiname
                </h1>

                <v-spacer />

                <v-btn
                    icon
                    color="transparent"
                    elevation="0"
                    x-small
                    style="width: 30px; min-width: 30px; height: 30px"
                    @click="close"
                >
                    <img
                        class="iconToWhite icon30"
                        alt="Hochladen abbrechen"
                        :src="cancelIcon"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="pa-0">
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <v-col>
                            <v-text-field
                                v-model="newFileName"
                                label="Dateiname"
                                class="mt-0"
                                hide-details
                                @keydown.enter="confirmAndCallback"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                elevation="0"
                                class="text-none"
                                color="secondary"
                                :aria-label="'Datei ' + newFileName + ' hochladen bestätigen'"
                                @click="confirmAndCallback"
                            >
                                {{ changeWording ? 'Speichern' : 'Absenden' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="group && bonusFunctions.subtitle && isSubtitleReady"
                        class="mt-0 pt-0"
                        align="center"
                        justify="center"
                    >
                        <v-col style="">
                            <v-checkbox
                                v-model="createSubtitles"
                                style="margin-top: 0"
                            >
                                <template v-slot:label>
                                    Untertitel aktivieren
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-bind="attrs"
                                                :src="questionMarkCircleIcon"
                                                class="ml-2"
                                                alt="Fragezeichensymbol"
                                                height="22"
                                                v-on="on"
                                            >
                                        </template>
                                        <p
                                            align="center"
                                            justify="center"
                                            style="margin: auto; max-width: 200px"
                                        >
                                            Hierbei handelt es sich um eine Betafunktion.
                                            Diese ist aktuell nur mit Videos von einer Länge bis zu einer Minute kompatibel.
                                            Bis die Untertitel erstellt und angezeigt werden, kann es einige Minuten und eine Aktualisierung der Seite benötigen.
                                        </p>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <div
                        v-if="group && showIsAssignment"
                        class="ma-0 my-4"
                        style="width: 100%"
                    >
                        <div
                            v-if="accountRole === 'teacher' && showIsAssignment"
                            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
                        >
                            <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="date"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formattedDate"
                                        label="Veröffentlichungsdatum"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        hide-details
                                        class="mt-0"
                                        style="max-width: 200px"
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    scrollable
                                    locale="de-DE"
                                    first-day-of-week="1"
                                >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        color="primary"
                                        class="text-none"
                                        @click="modal = false"
                                    >
                                        Abbruch
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        class="text-none"
                                        @click="$refs.dialog.save(date)"
                                    >
                                        Ok
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            <div style="display: flex; flex-direction: row; align-items: center; max-width: 75%">
                                <v-checkbox
                                    v-model="isAssignment"
                                    label="Abgabe"
                                    class="mt-0"
                                    hide-details
                                />
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkCircleIcon"
                                            class="icon20 ml-2"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        Als Abgabe markierte Materialien müssen von Schülern bearbeitet werden.
                                    </p>
                                </v-tooltip>
                                <v-checkbox
                                    v-if="showTeamworkCheckbox"
                                    v-model="isTeamwork"
                                    label="Gruppenarbeit"
                                    class="mt-0"
                                    hide-details
                                />
                                <v-tooltip
                                    v-if="showTeamworkCheckbox"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            :src="questionMarkCircleIcon"
                                            class="icon20 ml-2"
                                            alt="Erklärung"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        Wenn aktiviert, können ausgewählte Schüler*innen parallel an der Hausaufgabe arbeiten
                                    </p>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <v-row
                        v-if="group && showIsAssignment"
                        class="ma-0"
                        align="center"
                        justify="center"
                        style="width: 100%"
                    >
                        <v-data-table
                            :headers="tableHeader"
                            :items="group.participants"
                            disable-pagination
                            hide-default-footer
                            class="ma-0"
                            show-select
                            disable-sort
                            no-data-text="Keine Empfänger verfügbar"
                            style="width: 100%; max-height: 300px; overflow-y: auto"
                            mobile-breakpoint="10"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:header.data-table-select>
                                <v-checkbox
                                    v-model="allParticipantsSelected"
                                    :indeterminate="atLeastOneButNotAllParticipantsSelected"
                                    rounded
                                    small
                                    light
                                    @change="headerCheckboxChanged"
                                />
                            </template>
                            <template v-slot:item="row">
                                <tr>
                                    <td>
                                        <v-checkbox
                                            v-model="selectedParticipants"
                                            rounded
                                            :value="row.item.account"
                                            small
                                            light
                                        />
                                    </td>
                                    <td>{{ row.item.name }}</td>
                                    <td>{{ row.item.lastName }}</td>
                                    <td>
                                        <v-textarea
                                            v-if="userNotes[row.index] !== undefined && userNotes[row.index].note !== undefined"
                                            v-model="userNotes[row.index].note"
                                            :disabled="!selectedParticipants.includes(row.item.account)"
                                            style="padding: 4px 0; min-width: 140px"
                                            outlined
                                            no-resize
                                            auto-grow
                                            hide-details
                                            dense
                                            placeholder="Notiz"
                                            rows="1"
                                        />
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import cancelIcon from '../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import questionMarkCircleIcon from '@/assets/Icons/redesignV3/questionmark.svg';
import slugify from 'slugify';

import { mapState, mapGetters, mapActions } from "vuex";
export default {
    name: "NameFile",
    model: {
        prop: 'showDialog',
        event: 'showChange'
    },
    props: {
        showDialog: {type: Boolean, required: true},
        showIsAssignment: {type: Boolean, required: false, default: true},
        originalName: {type: String, required: false, default: ''},
        callback: {type: Function, required: true},
        isSubtitleReady: {type: Boolean, required: false, default: null},
        group: {type: Object, required: false, default: null},
        appointmentDate: {required: false, default: null, type: Date, },
        changeWording: {type: Boolean, required: false, default: false},
        showTeamworkCheckbox: { type: Boolean, required: false, default: false},
        headerColor: {type: String, required: false, default: 'var(--v-headerblue-base)'}
    },
    data: () => ({
        cancelIcon,
        questionMarkCircleIcon,

        isAssignment: true,
        createSubtitles: false,
        newFileName: '',
        selectedParticipants: [],
        userNotes: [],
        date: null,
        modal: false,
        isTeamwork: false,
        allParticipantsSelected: false,
        tableHeader: [{ text: 'Vorname'}, { text: 'Nachname' }, { text: 'Notiz'}],
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapState("util", ["bonusFunctions"]),

        atLeastOneButNotAllParticipantsSelected() {
            return this.selectedParticipants.length > 0 && this.selectedParticipants.length < this.group.participants.length;
        },
        formattedDate() {
            if(this.date){
                return this.date.slice(8, 10) + "." + this.date.slice(5, 7) + "." + this.date.slice(0, 4);
            }
            else{
                return ''
            }
        }
    },
    watch: {
        showDialog(val, prev) {
            if (val && !prev) {
                this.newFileName = this.originalName;
                if (this.group) {
                    this.selectedParticipants = this.group.participants.map(a => a.account);
                }
            }
        },
        group(val) {
            if(val && val.participants) {
                this.selectedParticipants = val.participants.map(a => a.account);
            }
        },
        selectedParticipants() {
            if(this.selectedParticipants.length === this.group.participants.length) {
                this.allParticipantsSelected = true;
            } else if(this.selectedParticipants.length < this.group.participants.length){
                this.allParticipantsSelected = false;
            }
        },
        appointmentDate() {
            this.date = this.appointmentDate.toISOString().substr(0, 10);

        }
    },
    async beforeMount() {
        this.newFileName = this.originalName;
        if (this.group !== null && this.accountRole !== 'pupil') {
            this.getParticipants();
        }
    },
    mounted() {
        if(this.appointmentDate) {
            const newDate = new Date(this.appointmentDate)
            newDate.setHours(-newDate.getTimezoneOffset() / 60);
            this.date = newDate.toISOString().slice(0, 10);
        } else {
            this.date = new Date().toISOString().slice(0, 10);
        }
    },
    methods: {
        ...mapActions("groups", ["getGroup"]),
        async confirmAndCallback() {
            let notes = JSON.parse(JSON.stringify(this.userNotes));
            for(let i=0; i < notes.length; i++) {
                notes[i].account = this.group.participants[i].account;
            }
            notes = notes.filter((el) => {
                return el.note !== "";
            })
            await this.callback(
                this.sanitizeFilename(this.newFileName),
                this.selectedParticipants,
                notes,
                this.date,
                (this.isAssignment && this.showIsAssignment),
                this.createSubtitles,
                this.isTeamwork,);
            this.createSubtitles = false;
        },
        close() {
            document.getElementById('uploadInput').value = null;
            this.$emit('showChange', false);
            this.$emit('close');
        },
        headerCheckboxChanged() {
            if(this.allParticipantsSelected) {
                this.selectedParticipants = this.group.participants.map((participant) => participant.account);
            } else {
                this.selectedParticipants = [];
            }
        },
        getParticipants(){
            this.group.participants.map(() => this.userNotes.push({ note: "" }));
            this.selectedParticipants = this.group.participants.map(a => a.account);
        },
        sanitizeFilename(filename) {
            return slugify(filename, {
                replacement: '_',    // replace spaces with underscores
                lower: false,          // preserve case
                strict: true,         // strip special characters except replacement
                locale: 'de'          // use German locale to handle ä, ö, ü correctly
            });
        }
    },
}
</script>
