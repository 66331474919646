import * as backend from '../../api/backend';

const defaultState = {
  supportPageOpen: false,
};


const mutations = {
  setSupportPageOpen: (state, bool) => {
    console.log('settingsuzpp', bool);
    state.supportPageOpen = bool;
  },
};

const getters = {
  supportPageOpen: state => state.supportPageOpen,
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
};
