<template>
    <div
        id="sideBarContainer"
    >
        <div
            style="min-height: 110px"
            :zoomVisible="parentToolbarState"
            :class="`d-flex flex-column ${smartphoneUi ? 'hideAndSeek' : ''}`"
        >
            <div :class="`d-flex flex-column ${smartphoneUi ? 'darkBackground pl-3' : ''}`">
                <div class="e-row">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                id="plusZoomButton"
                                aria-label="Vergrößern"
                                aria-hidden="true"
                                elevation="3"
                                x-small
                                class="mr-3 sidebarButton"
                                :class="smartphoneUi ? 'mb-1' : ''"
                                :style="smartphoneUi ? 'height: 40px !important; width: 40px !important' : ''"
                                v-on="on"
                                @click="changeZoom(1)"
                            >
                                <p>+</p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Vergrößern</span>
                        </div>
                    </v-tooltip>
                </div>
                <div class="e-row">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                aria-label="Verkleinern"
                                aria-hidden="true"
                                tabindex="0"
                                elevation="3"
                                x-small
                                class="mr-3 sidebarButton"
                                :style="smartphoneUi ? 'height: 40px !important; width: 40px !important' : ''"
                                v-on="on"
                                @click="changeZoom(-1)"
                            >
                                <p>-</p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Verkleinern</span>
                        </div>
                    </v-tooltip>
                </div>
            </div>
            <div
                v-if="!smartphoneUi && currentZoom > 1.0"
                class="e-row"
            >
                <p
                    class="pr-3 mb-0"
                    style="text-align: center"
                >
                    {{ `${(currentZoom * 100).toFixed(0)}%` }}
                </p>
            </div>

            <v-divider
                v-if="!smartphoneUi && !isViewerOpen"
                aria-hidden="true"
                class="my-2 mr-3 ml-1"
            />
        </div>

        <div
            class="d-flex flex-column justify-start"
        >
            <!-- Interactive Elements -->
            <EditorInteractiveElementsList
                v-if="displayTools"
                :preview-active="previewActive"
                :smartphone-ui="smartphoneUi"
                :smartphone-extended-elements-on="smartphoneExtendedElementsOn"
                @opened="$emit('opened')"
                @elementSelected="elementSelected"
                @close="showInteractiveElements = false"
            />

            <div style="min-height: 49px">
                <!-- Vorherige Seite Button -->
                <v-tooltip
                    v-if="pageCount > 1"
                    right
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="changePageBtn mt-2"
                            style="border-radius: 8px"
                            :style="currentPageIndex - 1 < 0 ? 'color: grey' : ''"
                            aria-label="vorherige Seite"
                            :x-small="windowWidth < 345"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => { if((currentPageIndex - 1) >= 0) { $emit('changePage', -1) }}"
                        >
                            <v-icon large>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Vorherige Seite</span>
                </v-tooltip>
            </div>
        </div>

        <div style="min-height: 110px !important; display: flex; flex-direction: column; justify-content: flex-end">
            <!-- Activate Toolbar button  -->
            <v-tooltip
                v-if="isToolbarToggleVisible && (getImageIcon || getMdiIcon) && !smartphoneUi"
                right
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        aria-label="Toolbar Aktivieren"
                        aria-hidden="true"
                        tabindex="-1"
                        elevation="3"
                        x-small
                        class="mr-3 my-1 sidebarButton toggleToolbarButton"
                        :style="`${localToolbarToggle ? `background-color: ${currentColor}` : 'background-color: #3ba934'}`"
                        :disabled="previewActive"
                        v-on="on"
                        @click="toggleToolbar"
                    >
                        <img
                            v-if="getImageIcon"
                            class="toggleToolbarButton"
                            height="35px"
                            :src="getImageIcon"
                            style="padding: 2px; filter: brightness(1000%)"
                        >
                        <v-icon
                            v-if="getMdiIcon"
                            style="font-size: 22px !important;"
                            :color="`${localToolbarToggle ? 'white' : currentColor }`"
                        >
                            {{ getMdiIcon }}
                        </v-icon>
                    </v-btn>
                </template>
                <span v-if="localToolbarToggle">Werkzeuge deaktivieren</span>
                <span v-else>Werkzeuge aktivieren</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import stiftIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import textIcon from '@/assets/Icons/Textfunktion-67.svg';
import radiererIcon from '@/assets/Icons/radierer-73.svg';
import EditorInteractiveElementsList from "@/components/Editortools/Toolbar/EditorInteractiveElementsList";

export default {
    name: "EditorSideBar",
    components: {EditorInteractiveElementsList, /*MemoMenu, MediaMenu, FormMenu, TaskMenu*/ },
    props: {
        currentPageIndex: { required: true, type: Number },
        mode: { required: true, type: String },
        elementToEdit: { required: false, type: Object, default: null },
        previewActive: { required: false, type: Boolean, default: false },
        isToolbarToggleVisible: { required: true, type: Boolean, default: true },
        parentToolbarState: { required: false, type: Boolean, default: false },
        currentColor: { required: false, type: String, default: '#3ba934' },
        currentZoom: { required: false, type: Number, default: 1.0 },
        pageCount: { required: true, type: Number },
        windowWidth: { required: true, type: Number },
        simpleEditorForTeachers: { required: false, type: Boolean, default: false },
        currentIcon: { required: true, type: String },
        currentStempel: { required: true, type: String },
        smartphoneUi: { required: false, type: Boolean, default: false},
        smartphoneExtendedElementsOn:{ required: false, type: Boolean, default: false},
    },
    data: () => ({
        stiftIcon,
        textIcon,
        radiererIcon,

        // Menu Toggles
        mediaMenu: false,
        taskMenu: false,
        localToolbarToggle: false,
        watcherForMenus: 0,

        showInteractiveElements: false,
    }),
    computed: {
        isViewerOpen() {
            return this.mode === 'viewer';
        },
        displayTools() {
          return (this.mode === 'creator' || this.mode === 'teacher') && !this.simpleEditorForTeachers;
        },
        getImageIcon() {
            switch (this.currentIcon) {
                case 'pen':
                    return this.stiftIcon;
                case 'text':
                    return this.textIcon;
                case 'eraser':
                    return this.radiererIcon;
                case 'stempel':
                    return this.currentStempel;
                default:
                    return ''; // It's a mdi-icon
            }
        },
        getMdiIcon() {
            switch (this.currentIcon) {
                case 'marker':
                    return 'fas fa-highlighter';
                case 'color':
                    return 'fas fa-circle';
                default:
                    return ''; // It's an image
            }
        }
    },
    watch: {
        elementToEdit(newVal) {

            if (newVal && newVal.element) {
                if (newVal.element.messageType === 'multipleChoice' || newVal.element.messageType === 'moveableText') {
                    this.taskMenu = true;
                } else if (newVal.element.messageType === 'imageObject' || newVal.element.messageType === 'videoObject') {
                    this.mediaMenu = true;
                }

                this.$nextTick(() => this.watcherForMenus += 1);
            }
        },
        parentToolbarState(newVal, oldVal) {
            this.localToolbarToggle = newVal;
        }
    },
    methods: {
        changeZoom(factor) {
            if (factor > 0)
                this.$emit('zoomIn');
            if (factor < 0)
                this.$emit('zoomOut');
        },
        elementSelected(options) {
            const type = options.type;
            delete options.type;

            this.$emit('addElement', type, options);
        },
        toggleToolbar() {
            this.localToolbarToggle = !this.localToolbarToggle;
            this.$emit('toggleToolbar', this.localToolbarToggle);
        },
    }
}
</script>
<style scoped lang="scss">
.hideAndSeek {
    // Animation Stuff
    transform: translateX(-150px);
    transition: all 0.25s ease-out;
}

.hideAndSeek[zoomVisible=true] {
    transform: translateX(0);
}

.hideAndSeek[sidebarVisible=true] {
    transform: translateX(0);
}

#sideBarContainer {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    position: fixed;
    left: 10px;
    bottom: 15px;
    z-index: 4 !important;
    height: calc(var(--vh, 1vh) * 87.5);
    pointer-events: none;
}

.darkBackground {
    padding: 2px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
}

.e-row {
    margin: 4px 0px 4px 0px;
}

#pageCount {
    font-weight: bold;
    font-size: larger;
    margin-bottom: 0;
    text-align: center;
}

.sidebarButton {
    z-index: 3;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;
    pointer-events: all;

    p {
        line-height: 0;
        font-size: 55px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }
}

.sidebarButtonActive {
    background-color: var(--v-orange-base) !important;

    img {
        filter: brightness(1000%);
    }
}

#interactiveElementsActivator {
    z-index: 3;
    height: 150px !important;

    img {
        filter: brightness(1000%);
    }
}

.changePageBtn {
    background-color: white !important;
    box-shadow: none;
    width: 30px;
    height: 49px !important;
    pointer-events: all;
    border-radius: 0;
}

.changePageBtn:focus, .changePageBtn:hover {
    background-color: #dbdbdb !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: 49px !important;
}
</style>
