var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"height":"90vh"}},[_c('v-progress-linear',{staticStyle:{"position":"fixed","top":"0 !important","z-index":"203"},style:(_vm.showLoading ? {} : {opacity: 0}),attrs:{"indeterminate":"","buffer-value":"100","color":_vm.backendReachable ? 'gruen' : 'error',"height":"5","aria-hidden":"true"}}),_c('progress-bar',{attrs:{"title":"Hochladen","show-progress":_vm.showPupilUploadProgress,"progress":_vm.pupilUploadProgress,"abort-progress":_vm.pupilXmlHttpRequest
                ? () => {
                    _vm.pupilXmlHttpRequest.abort();
                }
                : () => {},"aria-hidden":"true"}}),_c('label',{staticStyle:{"display":"none"},attrs:{"for":"uploadInput"}},[_vm._v("Datei hochladen")]),_c('input',{ref:"uploadInput",attrs:{"id":"uploadInput","type":"file","hidden":"","multiple":""},on:{"change":() => _vm.uploadInputChange()}}),(_vm.showNameFile)?_c('NameFile',{attrs:{"original-name":_vm.uploadFileName,"callback":_vm.fileNameCallback,"is-subtitle-ready":_vm.isSubtitleReady,"show-teamwork-checkbox":_vm.showTeamworkCheckbox,"group":_vm.currentUploadGroup === 'pupil' ? null : (_vm.currentlyOpenAppointment ? _vm.currentlyOpenAppointment.group : null),"header-color":_vm.accountRole === 'pupil' ? 'var(--v-timetableColor-base)' : 'var(--v-headerblue-base)',"show-is-assignment":!(
                _vm.currentUploadGroup === 'teacherFolder' ||
                _vm.currentUploadGroup === 'privateFolder' ||
                _vm.currentUploadGroup === 'groupFolder' ||
                _vm.currentUploadGroup === 'baby'
            )},model:{value:(_vm.showNameFile),callback:function ($$v) {_vm.showNameFile=$$v},expression:"showNameFile"}}):_vm._e(),(_vm.developmentMode)?_c('UserSwitch',{staticStyle:{"z-index":"5"}}):_vm._e(),_c('router-view'),_c('snackbar'),(_vm.showTranslatedText)?_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTranslation),expression:"closeTranslation"}],staticStyle:{"background-color":"#343e55"},attrs:{"id":"translatedTextShow"}},[_c('v-card-text',{staticClass:"py-2",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"scroll-area-translated",attrs:{"data-simplebar":"","data-simplebar-auto-hide":"false"}},[_c('p',{staticClass:"my-1 mx-2",staticStyle:{"color":"white !important"},attrs:{"id":"translatedTextText"},domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.translatedText, true))}})])])],1):_vm._e(),_c('keyboard'),(_vm.magnifier)?_c('magnifier'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }