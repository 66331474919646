<template>
    <div
        :class="inChat ? '' : 'mb-4'"
        style="width: 100%"
    >
        <!-- displaying a poll -->
        <div v-if="pollId">
            <!-- id is being used to jump to a mandatory poll in chat via button click -->
            <div
                v-if="poll && poll.mandatory"
                id="mandatoryPoll"
                class="d-flex"
                style="margin-bottom: 10px;"
            >
                <v-icon
                    height="17"
                    color="#6f6e6e"
                    style="transform: rotate(90deg);"
                    :class="letterMode || fontColor === 'white' ? 'iconToWhite' : ''"
                >
                    mdi-poll
                </v-icon>
                <p 
                    style="font-weight: bold; margin-left: 10px;"
                    :style="{'color': fontColor}"
                >
                    Verpflichtende Umfrage
                </p>
            </div>

            <div v-if="poll">
                <div class="d-flex flex-row ">
                    <p
                        class="mb-1"
                        :style="{'color': fontColor}"
                    >
                        {{ poll.question }}
                    </p>
                    <v-tooltip
                        v-if="poll.multiselect"
                        top
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                v-if="poll.multiselect"
                                :src="infoIcon"
                                class="icon20 pl-1"
                                alt="Erklärung"
                                v-bind="attrs"
                                v-on="on"
                            >
                        </template>
                        <span style="max-width: 250px">
                            Es können mehrere Auswahlmöglichkeiten gleichzeitig gewählt werden
                        </span>
                    </v-tooltip>
                </div>
                <div
                    v-for="option in poll.options"
                    :key="option._id"
                    style="position: relative; cursor: pointer"
                    @click="vote(option._id)"
                >
                    <v-tooltip
                        top
                        nudge-right="100"
                        :disabled="poll.showVotes ? false : poll.account !== accountId || showVoters(option).length === 0"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <!-- percentage bar for option -->
                            <div
                                v-if="poll.showVotes"
                                class="voteBarPercentage"
                                :class="userVotedOption(option._id)? 'voteBarPercentageVoted' : '' "
                                :style="{width: poll.showVotes ? getPercentage(poll, option) : '0%'}"
                                v-bind="attrs"
                                v-on="on"
                            />
                            <!-- content of options -->
                            <div
                                class="d-flex align-center justify-space-between px-2 mb-1"
                                :class="userVotedOption(option._id)? 'votedAnswer' : 'pollAnswer'"
                                :style="poll.showVotes && userVotedOption(option._id)? 'background: var(--v-dunkelgrau-base)' : ''"
                                style="width: 100%"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <div class="d-flex">
                                    <v-radio
                                        v-if="!votedOption"
                                        readonly
                                        class="mr-1"
                                        style="z-index: 1"
                                        :aria-label="option.text"
                                    />
                                    <!--                            <v-checkbox v-if="!votedOption" readonly hide-details class="ma-0 pa-0 pl-1" style="z-index: 1"></v-checkbox>-->
                                    <p
                                        aria-hidden="true"
                                        class="voteBarContent"
                                        :style="userVotedOption(option._id)? 'color: white' : 'color: black'"
                                    >
                                        {{ option.text }}
                                    </p>
                                </div>
                                <div style="position:absolute; bottom: 3px; line-height: 14px;  right: 10px;">
                                    <div class="d-flex flex-row justify-space-around align-center">
                                        <img
                                            style="border-radius: 6px; background-color: #D1BC8A; padding: 1px;"
                                            class="icon20 mr-1"
                                            :src="avatar"
                                            @click.stop="clickAvatar($event)"
                                        >
                                        <p
                                            v-if="poll.showVotes || role === 'teacher'"
                                            style=" font-size: small; max-height: 14px; overflow: hidden;"
                                            :style="userVotedOption(option._id)? 'color: white' : 'color: black'"
                                            aria-hidden="true"
                                        >
                                            {{ option.votes + '/' + poll.votes.length }}
                                        </p>
                                    </div>
                                    <span
                                        v-if="poll.showVotes || role === 'teacher'"
                                        class="visually-hidden"
                                    >{{ option.text + ' hat ' + option.votes + ' Stimmen' }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <span>{{ showVoters(option) }}</span>
                    </v-tooltip>
                </div>
                <div
                    v-if="poll.allowUserOptions"
                    class="pollAnswer d-flex align-center justify-space-between px-2 mb-1"
                >
                    <v-text-field
                        v-model="ownOptionText"
                        :placeholder="poll.userOptionPlaceholder ? poll.userOptionPlaceholder : 'Eigene Antwort eingeben...'"
                        class="ownOption pa-0"
                        solo
                        flat
                        dense
                        hide-details
                        @keydown.enter="createNewOption"
                    />
                </div>
            </div>
        </div>

        <!-- poll creation -->
        <div
            v-else
            :class="!letterMode ? 'createPollDiv' : ''"
        >
            <v-row
                class="align-center mx-1"
            >
                <v-row
                    class="mx-0 mb-1"
                    :class="letterMode ? '' : 'titleWidth'"
                    :style="letterMode ? 'max-width: 98%' : 'max-width: 75%'"
                >
                    <v-text-field
                        v-model="question"
                        :placeholder="letterMode ? 'Hier Frage eingeben...' : 'Titel der Umfrage eingeben'"
                        style="height: 42px; margin-left: 2px; margin-bottom: -10px; font-weight: bold;"
                        :color="!letterMode ? 'white' : ''"
                        :dark="!letterMode"
                        dense
                        hide-details
                        clearable
                        clear-icon="mdi-delete"
                    >
                        <template
                            v-if="question === null || this.question === ''"
                            v-slot:append
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        :class="!letterMode ? 'iconToWhite' : ''"
                                        tabindex="0"
                                        v-bind="attrs"
                                        style="height: 23px; width: 23px; padding-top: 0px"
                                        :src="stiftIconDark"
                                        alt
                                        v-on="on"
                                    >
                                </template>
                                <span>Textfeld bearbeiten</span>
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-row>
                <v-btn
                    v-if="chatMode"
                    x-small
                    elevation="0"
                    style="position: absolute; right: 0; height: 30px; width: 30px"
                    class="mr-5 mb-2"
                    @click="emitClose"
                >
                    <img
                        :src="closeIcon"
                        alt="Schließen"
                    >
                </v-btn>
            </v-row>
            <div class="d-flex flex-column">
                <!-- option is unused, but still needed, otherwise you will get duplicate keys -->
                <div
                    v-for="(option, index) in options"
                    :key="index"
                    class="rowHeight d-flex align-center mx-1 mb-2"
                >
                    <div
                        class="d-flex rowHeight align-center pa-0 inputBorder mr-1"
                        style="flex: 1 1 0; border-radius: 8px"
                        :style="letterMode ? 'background-color: var(--v-hellgrau-base)' : 'background-color: white'"
                    >
                        <v-text-field
                            :id="`option${index}`"
                            v-model="options[index]"
                            :placeholder="'Auswahl ' + (index + 1) + ' eingeben'"
                            :background-color="letterMode ? 'hellgrau' : ''"
                            solo
                            clearable
                            clear-icon="mdi-delete"
                            flat
                            dense
                            hide-details
                            @keyup.enter="addOption(index)"
                            @keyup.delete="deleteOption(index)"
                        >
                            <template
                                v-if="((option === null || option === ''))"
                                v-slot:append
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            tabindex="0"
                                            v-bind="attrs"
                                            style="height: 23px; width: 23px; padding-right: 5px"
                                            :src="stiftIconDark"
                                            alt
                                            v-on="on"
                                        >
                                    </template>
                                    <span>Textfeld bearbeiten</span>
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </div>
                    <div v-if="index > 1">
                        <v-btn
                            elevation="0"
                            style="width: 25%; border-radius: 8px; height: 42px"
                            aria-label="Auswahl entfernen"
                            @click="removeOption"
                        >
                            <p class="redText symbolBtn">
                                -
                            </p>
                        </v-btn>
                    </div>
                </div>

                <v-row class="d-flex justify-space-between mx-1 mb-2">
                    <v-btn
                        elevation="0"
                        :color="letterMode ? 'hellgrau' : 'rgba(255, 255, 255, 0.2)'"
                        style="width: 100%; border-radius: 8px; height: 42px"
                        class="mr-1"
                        aria-label="Auswahl hinzufügen"
                        @click="addOption"
                    >
                        <img
                            :src="plusIcon"
                            :class="letterMode ? 'iconToDunkelgrau' : 'iconToWhite'"
                            style="height: 30px"
                            alt="Plus"
                        >
                    </v-btn>
                </v-row>
                <v-row
                    class="mx-1 mb-1"
                    :class="allowUserOptions ? 'inputBorder' : 'inputBorderTransparent'"
                    style="border-radius: 8px"
                    :style="letterMode ? 'background-color: var(--v-hellgrau-base)' : 'background-color: rgba(255, 255, 255, 0.2)'"
                    tabindex="-1"
                >
                    <v-text-field
                        v-model="userOptionPlaceholder"
                        :placeholder="allowUserOptions ? 'Platzhaltertext eingeben...' : 'Option „Sonstige“ deaktiviert'"
                        :readonly="!allowUserOptions"
                        :background-color="letterMode ? 'hellgrau' : 'rgba(255, 255, 255, 0)'"
                        class="mb-2"
                        solo
                        flat
                        dense
                        hide-details
                        :dark="!letterMode"
                        :tabindex="allowUserOptions ? '0' : '-1'"
                    >
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        tabindex="0"
                                        v-bind="attrs"
                                        style="margin-right: 5px; margin-top: 5px"
                                        :src="allowUserOptions ? eye : eyeSlash"
                                        :alt="allowUserOptions ? 'Auge' : 'Auge durchgestrichen'"
                                        :class="letterMode ? '' : 'iconToWhite'"
                                        @click="toggleAllowUserOptions"
                                        @keydown.space="toggleAllowUserOptions"
                                        v-on="on"
                                    >
                                </template>
                                <span
                                    v-text="allowUserOptions ? 'Freitextoption deaktivieren' : 'Freitextoption aktivieren'"
                                />
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-row>
                <div
                    class="d-flex mt-2 mb-1 checkmarkDiv"
                >
                    <div class="mr-2">
                        <label
                            class="checkmarkContainer"
                        >
                            <input
                                v-model="mandatory"
                                type="checkbox"
                                aria-label="Umfrage verpflichtend"
                                class="ma-1 pa-0 checkbox"
                            >
                            <span class="checkmark" />
                            <p
                                style="margin-left: -10px"
                                :style="!letterMode ? 'color: white' : ''"
                                aria-hidden="true"
                            >
                                Verpflichtend
                            </p>
                        </label>
                    </div>

                   
                    <div class="mr-2">
                        <label
                            class="checkmarkContainer"
                        >
                            <input
                                v-model="showVotes"
                                type="checkbox"
                                aria-label="Ergebnisse der Umfrage für die Teilnehmer und Teilnehmerinnen sichtbar machen"
                                class="ma-1 pa-0 checkbox"
                            >
                            <span class="checkmark" />
                            <p
                                style="margin-left: -10px"
                                :style="!letterMode ? 'color: white' : ''"
                                aria-hidden="true"
                            >
                                Ergebnisse sichtbar
                            </p>
                        </label>
                    </div>
                  
                    <div class="mr-2 d-flex">
                        <label
                            class="checkmarkContainer"
                        >
                            <input
                                v-model="multiselect"
                                type="checkbox"
                                aria-label="mehrere Auswahlmöglichkeiten"
                                class="ma-1 pa-0 checkbox"
                            >
                            <span class="checkmark" />
                            <p
                                style="margin-left: -10px"
                                :style="!letterMode ? 'color: white' : ''"
                                aria-hidden="true"
                            >
                                Mehrere Auswahlmöglichkeiten
                            </p>
                        </label>
                        <v-tooltip
                            top
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="infoIcon"
                                    class="icon20 pl-1"
                                    alt="Erklärung"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <span style="max-width: 250px">
                                Es können mehrere Auswahlmöglichkeiten gleichzeitig gewählt werden
                            </span>
                        </v-tooltip>
                    </div>
                </div>
                <div 
                    v-if="chatMode"
                    class="d-flex flex-column pb-1"
                >
                    <div class="d-flex justify-center">
                        <v-btn
                            id="custom-disabled"
                            elevation="0"
                            :disabled="pollValid"
                            dark
                            class="text-capitalize"
                            color="gruen"
                            style="background-color: var(--v-chatColor-base); width: 100%; border-radius: 8px"
                            @click="savePoll"
                        >
                            <img
                                :src="sendenIconWeiss"
                                class="icon20 iconToWhite mr-2"
                                style="height: 25px"
                                alt="Abschicken"
                            >
                            <span>Abschicken</span>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <!-- This div actually needs to exist so that the computed property pollValid gets executed and can regularly emit calls to its parent component. -->
        <div v-if="pollValid" />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { bus } from "@/main";

import eye from '@/assets/Icons/eye.svg';
import eyeSlash from '@/assets/Icons/eye-off.svg';
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import sendenIconWeiss from "../assets/Icons/FaceliftIcons/absenden_versenden_flugzeug.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import stiftIconDark from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import plusIcon from "../assets/Icons/plus.svg";
import avatar from "@/assets/Icons/Avatar3.svg";

export default {
    name: "Poll",
    props: {
        chatMode: { type: Boolean, default: false },
        letterMode: { type: Boolean, default: false },
        pollId: { type: String, required: false },
        inChat: { type: Boolean, default: false },
        fontColor: { required: false, type: String, default: 'black' }
    },
    data() {
        return {
            eye,
            eyeSlash,
            closeIcon,
            sendenIconWeiss,
            stiftIconDark,
            plusIcon,
            infoIcon,

            role: null,
            poll: null,         // if not null, then this component displays the poll, otherwise it shows poll creation
            votedOption: '',    // to highlight voted option
            ownOptionText: '',
            question: '',
            allowUserOptions: false,
            multiselect: false,
            userOptionPlaceholder: '',      // poll creator can type in a placeholder text if allowUserOptions is true
            mandatory: false,
            showVotes: false,
            options: [],

            avatar,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('auth', ['accountId']),
        ...mapGetters('accounts', ['accountsById']),
        pollValid() {
            let returnValue = true;
            let counter = 0;
            let arrEmpty = this.checkArrayForEmpty(this.options);

            // poll must contain a question
            if (this.question !== null) {
                if (!(this.question.trim() === '')) {
                    counter++;
                }
            }

            // poll must either contain two answer options
            if (this.options.length >= 2 && !arrEmpty && this.allowUserOptions === false) {
                counter++;
            }
            // or must have user options enabled
            else if (this.allowUserOptions) {
                counter++;
            }
            // if poll is valid return false, so that :disabled is false
            if (counter === 2) {
                returnValue = false;

                // emit to MessagesWidget to activate letter send btn
                this.$emit('pollValid');
            } else {
                // emit to MessagesWidget to deactivate letter send btn
                this.$emit('pollInvalid');
            }

            return returnValue;
        }
    },
    async mounted() {
        this.role = await this.accountRole();

        if (this.pollId) {
            await this.requestPoll();
        }

        this.options = ['', ''];

        bus.$off('createPollAndEmit');
        // listens to emitted event via bus (bus is created in main.js)
        bus.$on('createPollAndEmit', this.savePollAndPassToParent);
    },
    methods: {
        ...mapGetters('auth',[ 'accountRole' ]),
        ...mapActions('poll', [ 'createPoll', 'getPoll', 'voteOption', 'createOption' ]),
        userVotedOption(optionId){
            const votes = this.poll.votes;
            const retVal = votes.findIndex(vote => (vote.account.toString() === this.accountId.toString()) && (vote.option.toString() === optionId.toString()));
            return retVal >= 0 ? true: false;
            
        },
        clickAvatar(event){
            event.stopPropagation();
        },
        toggleAllowUserOptions() {
            this.allowUserOptions = !this.allowUserOptions;
            if (!this.allowUserOptions) {
                this.userOptionPlaceholder = ''
            }
        },

        addOption(index) {
            // just checking if (index) doesn't work for 0
            if (index >= 0) {
                this.options.splice(index+1, 0, '');

                // now focus the new element added
                let a = "option";
                let b = index+1;
                let id = a.concat(b);
                document.getElementById(id).focus();
            } else {
                this.options.push('');
            }
        },

        // remove last option in array
        removeOption() {
            if (this.options.length > 2 || this.allowUserOptions) {
                this.options.splice(this.options.length - 1, 1);
            }
        },

        // delete option with given index if there are > 2 options
        deleteOption(index) {
            if (this.options[index] === '' && this.options.length > 2) {
                this.options.splice(index, 1);
            }
        },

        emitClose() {
            this.$emit('closePollCard');
        },

        // used if poll is created in ChatWidgetGroupChat
        async savePoll() {
            const optionsArray = [];
            for (let i = 0; i < this.options.length; i++) {
                optionsArray.push({ text: this.options[i], votes: 0 });
            }

            let body = {
                question: this.question,
                allowUserOptions: this.allowUserOptions,
                userOptionPlaceholder: this.userOptionPlaceholder,
                mandatory: this.mandatory,
                showVotes: this.showVotes,
                options: optionsArray,
                multiselect:this.multiselect,
            }
            let poll = await this.createPoll(body);
            this.$emit('closePollCard');
            bus.$emit('sendPollMessage', poll);
        },

        // used if poll is created in MessagesWidget
        async savePollAndPassToParent() {
            const options = [];
            for (let i = 0; i < this.options.length; i++) {
                options.push({ text: this.options[i], votes: 0 });
            }

            let body = {
                question: this.question,
                allowUserOptions: this.allowUserOptions,
                mandatory: this.mandatory,
                showVotes: this.showVotes,
                options,
                multiselect:this.multiselect,
            }
            let response = await this.createPoll(body);
            bus.$emit('pollCreated', response._id);
        },

        async requestPoll() {
            if (this.pollId === 'undefined') {
                console.warn('pollId is undefined');
            } else {
                this.poll = await this.getPoll(this.pollId);
                for (let i = 0; i < this.poll.votes.length; i++) {
                    if (this.poll.votes[i].account === this.accountId) {
                        this.votedOption = this.poll.votes[i].option;
                    }
                }
            }
        },

        async vote(optionId) {
            let params = {
                pollId: this.pollId,
                optionId: optionId
            };
            let res = await this.voteOption(params);
            if (res.status === '200') {
                this.votedOption = optionId;
                this.poll = res;    // update poll with the new values
            }
        },

        async createNewOption() {
            let option = { text: this.ownOptionText, votes: 0 };

            let params = {
                pollId: this.pollId,
                option: option,
            }
            this.ownOptionText = '';
            this.poll = await this.createOption(params); // update poll with the new values
        },

        getPercentage(poll, option) {
            let percentage;
            if (option.votes > 0) {
                percentage = ((option.votes/poll.hasVoted.length) * 100) + '%';
                // well 100% overlaps when showVotes is true, with this genius if-clause it doesn't ¯\_(ツ)_/¯
                if (percentage === '100%') {
                    percentage = '99%';
                }
            } else {
                percentage = '0%'
            }
            return percentage;
        },

        checkArrayForEmpty(array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === null || array[i].trim() === '')
                    return true;
            }
            return false;
        },

        showVoters(option) {
            if (option.votes === 0) {
                return 'Keine Stimmen';
            }
            let voters = '';
            this.poll.votes.map((vote) => {
                if (vote.option === option._id) {
                    const account = this.accountsById[vote.account];
                    voters += (voters.length > 0 ? ', ' : '') + (account && account?.displayName ? account?.displayName : '');
                }
            });
            return voters;
        }
    }
}
</script>

<style scoped>
/*removes the padding for the text-field where you can give your own poll option*/
.ownOption .v-input__slot {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
// style disabled button
#custom-disabled.v-btn--disabled {
    background-color: var(--v-hellgrau-base) !important;
}

.redText {
    color: var(--v-rot-base);
}

.inputBorder {
    border-radius: 8px;
}

.inputBorder:focus-within {
    border: 2px solid grey;
    border-radius: 8px;
}

.inputBorderTransparent {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
}

.symbolBtn {
    font-size: 1.5rem;
    font-weight: bold;
}

.rowHeight {
    height: 42px !important;
}

.pollAnswer {
    height: auto;
    min-height: 42px;
    border-radius: 8px;
    background: #eeeeee;
}

.votedAnswer {
    color: white;
    height: auto;
    min-height: 42px;
    border-radius: 8px;
    background: var(--v-parentLetterPurple-base);
}

.voteBarContent {
    z-index: 5;
    white-space: normal;
    overflow: auto;
}

.voteBarPercentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--v-grau-base);
    border-radius: 8px;
    margin-left: 1px;
    margin-top: 1px;
    margin-right: 1px;
    height: 40px !important;
}

.voteBarPercentageVoted {
    background-color: var(--v-dunkelgrau-base) !important;
    border-radius: 8px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.createPollDiv {
    max-width: 22vw;
}

.checkmarkDiv {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Customize the label (the container) */
.checkmarkContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkmark {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmarkContainer .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 900px) {
    .createPollDiv {
        max-width: 64.5vw;
    }
}

@media only screen and (max-width: 380px), (min-width: 901px) and (max-width: 1150px) {
    .checkmarkDiv {
        flex-direction: column;
    }
}

@media only screen and (max-width: 325px), (min-width: 901px) and (max-width: 1050px) {
    .titleWidth {
        max-width: 60% !important;
    }
}

.inputBorder[data-v-12596722]:focus-within {
    border: none !important;
}

.largeMaxWidth {
    max-width: 50vw;
}

.smallMaxWidth {
    max-width: 17vw;
}
</style>
