<template>
    <div>
        <v-dialog
        v-if="openDialog"
            v-model="openDialog"
            persistent
            fullscreen
            scrollable
            hide-overlay
            :transition="!openPinboardLibrary ? 'dialog-bottom-transition' : ''"
            @keydown.esc="notifyCloseList"
        >
            <v-card style="background-color: white">
                <v-toolbar
                    color="var(--v-headerblue-base)"
                    style="max-height: 65px"
                >
                    <v-toolbar-title
                        v-if="windowWidth > 330"
                        style="min-width: 250px"
                    >
                        <v-row class="ml-2">
                            <img
                                :src="pinIcon"
                                alt="Pin Platzhalter"
                                style="height: 50px; margin-right: 5px"
                            >
                            <p style="color: white; margin-top: 10px">
                                Pinnwände
                            </p>
                        </v-row>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items class="align-center">
                        <v-btn
                            x-small
                            text
                            elevation="0"
                            @click="notifyCloseList"
                        >
                            <img
                                :src="abbrechenIcon"
                                alt="Pinnwandliste schließen"
                                style="height: 40px; filter: brightness(1000%)"
                            >
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="sheet">
                    <div
                        v-for="(el, index) in allPinboards"
                        :key="el._id + index + 'pinboard'"
                        style="position: relative; float: left"
                        class="mr-2"
                    >
                        <ClickableBadge
                            v-if="el.changes && !el.changes.seen"
                            :event-amount="el.changes.amount"
                        />
                        <PinboardTileElement
                            :index="index"
                            :item="el"
                            :seen="el.changes"
                            :update-pinboard="updatePinboard"
                            :close-create-dialog="closeCreateDialog"
                            @openDeleteDialog="() => openDeleteDialog(el)"
                            @openPinbaord="(item) => setPinboardSeen(item)"
                        />
                        <div
                            class="mt-2 mb-2"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 200px; cursor: pointer"
                            @click="showSpeakerBtn(el.title, el._id)"
                        >
                            <div class="d-flex flex-row justify-space-between align-center">
                                <div :id="el._id">
                                    {{ el.title }}
                                </div>
                                <div v-if="el.lastEdited"> 
                                    <PinboardLastEdited
                                        :card="el"
                                        :usage="'pinboard'"
                                        class="pinboardLastEditedWrapper"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="accountRole !== 'pupil'"
                    >
                        <div
                            class="createTile createContainer align-center justify-center"
                            style="margin-bottom: 5px; overflow: hidden"
                            @click="openCreateDialog"
                        >
                            <img
                                :src="plusIcon"
                                class="mb-2 iconToDunkelgrau"
                                style="color: var(--v-dunkelgrau-base); height: 50px"
                                alt="Plus"
                            >
                        </div>
                        <p
                            class="mt-2 d-flex"
                            style="padding-left: 0px"
                        >
                            Pinnwand erstellen
                        </p>
                    </div>
                    <p
                        v-if="accountRole === 'pupil' && pinboards.length === 0"
                        class="mt-5 text-center"
                        style="font-size: 30px; padding-top: 27vh"
                    >
                        Keine Pinnwände vorhanden
                    </p>
                </div>
            </v-card>
        </v-dialog>
        <PinboardCreateDialog
            v-if="showPinboardCreateDialog"
            @close="showPinboardCreateDialog = false; closeCreateDialog()"
            @closeButtonClicked="showPinboardCreateDialog = false;"
        />
        <PinboardElementDeleteDialog
            v-if="deleteDialog"
            :items="itemsToDelete"
            @deleteComplete="deleteComplete"
            @close="() => { deleteDialog = false; }"
        />
        <div
            :ref="`speaker${idToRead}`"
            class="showSpeaker"
        >
            <v-btn
                v-if="accountRole === 'pupil' || accountRole === 'parent'"
                elevation="6"
                absolute
                x-small
                style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; bottom: 5px; right: 5%;"
                @click="readText(nameToRead, $event)"
            >
                <img
                    alt="Vorlesen"
                    :src="lautsprecherIcon"
                    style="height: 20px;"
                >
            </v-btn>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import PinboardTileElement from "../../components/Pinboard/PinboardTileElement";
import PinboardCreateDialog from "./PinboardCreateDialog";
import PinboardElementDeleteDialog from "./PinboardElementDeleteDialog";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import abbrechenIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import pinIcon from "../../assets/Icons/pinnwand.svg";
import plusIcon from '@/assets/Icons/plus.svg';

import sanitizeHtml from '@/util/sanitizeHtml';
import {textToSpeech} from "@/util/textToSpeech";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import PinboardLastEdited from './PinboardCardElements/PinboardLastEdited.vue';
import urlify from '../../util/urlify';


export default {
    name: "PinboardApp",
    components: {
        PinboardCreateDialog,
        PinboardTileElement,
        PinboardElementDeleteDialog,
        ClickableBadge,
        PinboardLastEdited,
    },
    props:  {
        pinboards: { required: false, type: Array }
    },
    data() {
        return {
            openDialog: true,
            showPinboardCreateDialog: false,
            abbrechenIcon,
            pinIcon,
            allPinboards: [],
            pinboardSeen: [],
            plusIcon,
            itemsToDelete: [],
            deleteDialog: false,
            babyView: false,
            crtAccount: null,

            sanitizeHtml,
            urlify,
            idToRead: '',
            nameToRead: '',
            lautsprecherIcon,
            lautsprecherAusIcon,
        }
    },
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('pinboard', ['openPinboardLibrary']),
        ...mapGetters('auth', ['accountRole']),
        ...mapState('translation', ['targetLang',]),
    },
    watch: {
        async pinboards() {
            this.allPinboards = this.pinboards;
            const newPinboard = this.allPinboards[this.allPinboards.length - 1];
            if (!newPinboard) return;
            this.allPinboards = this.allPinboards.map((pinboard) => {
                if (!pinboard.changes) {
                    pinboard.changes = { seen: false, amount: 2};
                }
                return pinboard;
            });
            newPinboard.changes = { seen: false, amount: 2};
            this.allPinboards[this.allPinboards.length -1] = newPinboard;
        }
    },
    async mounted() {
        this.crtAccount = await this.getCurrentAccount();
        await this.evaluateBabyView();
        await this.refreshPinboards();
        this.$emit('pinboardMounted');
    },
    methods: {
        ...mapActions('pinboard', ['getPinboards', 'hasUserSeenPinboard', 'toggleOpenPinboardLibrary']),
        ...mapActions('groups', ['getGroup']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        setPinboardSeen(openedPinboard) {
            this.allPinboards = this.allPinboards.map((pinboard) => {
                if (pinboard._id === openedPinboard._id) {
                    pinboard.changes = {
                        seen: true,
                        amount: 0,
                    }
                }
                return pinboard;
            });
        },
        async getHasUserSeenPinboard() {
            this.pinboardSeen = [];

            for(var i = 0; i < this.allPinboards.length; i++) {
                const crtPinboard = this.allPinboards[i];
                crtPinboard.changes = await this.hasUserSeenPinboard( { pinboardId: crtPinboard._id, accountId: this.crtAccount._id } );
                this.allPinboards[i] = crtPinboard;
            }
        },
        notifyCloseList() {
            this.$emit('pinboardMounted');
            this.$emit('closeList');
            this.toggleOpenPinboardLibrary(false);
        },

        openCreateDialog() {
            this.showPinboardCreateDialog = true;
        },

        async refreshPinboards(update = false) {
            if (!this.pinboards || this.pinboards.length === 0 || update) {
                this.allPinboards = await this.getPinboards();
            } else {
                this.allPinboards = this.pinboards;
            }
            await this.getHasUserSeenPinboard();
            await this.requestUploadedIcons();
        },
        async requestUploadedIcons() {
            this.allPinboards = await Promise.all(this.allPinboards.map(async (pinboardItem) => ({
                    ...pinboardItem,
                    iconBlob: pinboardItem.uploadedIcon ?
                        await this.getUploadedIcon(pinboardItem.icon) : null,
                })
            ));
        },

        openDeleteDialog(el) {
            this.itemsToDelete = [el]
            this.deleteDialog = true;
        },
        deleteComplete(itemId) {
            this.deleteDialog = false;
            this.itemsToDelete = [];
            this.allPinboards = this.allPinboards.filter((pinboard) => {
                return pinboard._id != itemId;
            })
        },
        updatePinboard(newPinboard, itemId) {
            this.refreshPinboards(true);
            // TODO: Update pinboard list locally below after patchPinboard
            /*this.allPinboards = this.allPinboards.map((pinboard) => {
                if (pinboard._id === itemId) {
                    return newPinboard;
                }
                return pinboard;
            })*/
        },
        closeCreateDialog() {
            this.allPinboards = [];
            this.pinboards = [];
            this.refreshPinboards();
        },
        async evaluateBabyView() {
            if (this.crtAccount.role === 'pupil') {
                const pupil = await this.getMePupil();
                this.babyView = pupil.babyView;
            }
        },

        showSpeakerBtn(name, id){
            let refsid;
            let element;

            if (id) {
                this.idToRead = id;
                refsid = id;
            } else {
                refsid = name;
            }
            this.nameToRead = name;

            const targetDiv = document.getElementById(`${refsid}`);

            this.$nextTick(() => {
                element = this.$refs[`speaker${refsid}`];

                if (element) {
                    targetDiv.appendChild(element);
                    element.classList.remove('showSpeaker');

                    setTimeout(() => {
                        element.classList.add('showSpeaker');
                    }, 5000);
                }
                this.idToRead = '';
            });
        },
        async readText(item, event) {
            console.log(item);
            let txt;
            let text;
            text = this.sanitizeHtml(this.urlify(item));

            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Diese Pinnwand hat keinen Namen.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
                event.target.alt = 'Stop';
            }, () => {
                event.target.src = lautsprecherIcon;
                event.target.alt = 'Vorlesen';
            });
        },
    }
}
</script>

<style>
.v-dialog--fullscreen {
    height: 100%;
    overflow-y: hidden;
}
</style>

<style scoped>
.showSpeaker{
    display: none;
}

.sheet {
    padding: 14px;
    padding-bottom: 20px;
    overflow-y: scroll;
}

.createBtn {
    color: white;
    max-height: 40px;
    margin-top: 10px;
    padding: 0 !important;
    border-radius: 8px;
    min-width: 40px !important;
    width: 40px;
    background-color: rgba(128, 128, 128, 0.829);
}

.createTile {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: rgba(80, 80, 80, 0.2);
    border-radius: 5px;
    align-content: center;
    align-items: center;
    margin-right: 20px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.createContainer {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    height: 100%;
    min-height: 200px;
    max-height: 200px;
}

@media only screen and (max-width: 900px) {
    .plusCreateIcon {
        max-width: 20vmax;
        max-height: 20vmax;
    }
}

</style>
