import {
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor, allowedExtensionsForLibre,
    allowedExtensionsForVideoPlayer
} from "@/constants/allowedMimeTypes";

// Conditions
const playButtonCondition = (file) => {
    return !isDraftCondition(file) && (allowedExtensionsForVideoPlayer.includes(file.title.split('.').pop().toLowerCase()) ||
        allowedExtensionsForAudioPlayer.includes(file.title.split('.').pop().toLowerCase()));
};
const editButtonCondition = (file, usage) => {
    return isDraftCondition(file) ||
        usage !== 'abgabenPupil' &&
        usage !== 'correctedTeacher' &&
        (allowedExtensionsForEditor.includes(file.title.split('.').pop().toLowerCase()) ||
            allowedExtensionsForLibre.includes(file.title.split('.').pop().toLowerCase()));
};
const doneButtonCondition = (file, accountRole, usage) => {
    return accountRole === 'pupil' &&
        usage !== 'abgabenPupil'
};
const infoButtonCondition = (infoButtonUsage, usage, file, accountRole, person) => {
    let userNote = ''
    if(infoButtonUsage === 'userNote' && accountRole === 'pupil' && file.userNotes && file.userNotes.length) {
        userNote = file.userNotes.find((el) => el.account === person.account);
    }
    return !isDraftCondition(file) &&
        ((infoButtonUsage === 'fileFormat' &&
                !(allowedExtensionsForVideoPlayer.includes(file.title.split('.').pop().toLowerCase()) ||
                    allowedExtensionsForAudioPlayer.includes(file.title.split('.').pop().toLowerCase()) ||
                    allowedExtensionsForEditor.includes(file.title.split('.').pop().toLowerCase()) ||
                    allowedExtensionsForLibre.includes(file.title.split('.').pop().toLowerCase()))) ||
            (infoButtonUsage === 'userNote' &&
                accountRole === 'pupil' &&
                '' !== userNote) ||
            (infoButtonUsage === 'noAnswer' && accountRole === 'teacher' && usage === 'correctedTeacher' && wasMoved(file.isMoved)));
};
const fileListEyeButtonCondition = (file, usage) => {
    return !isDraftCondition(file) &&
        ((usage === 'correctedTeacher' || usage === 'abgabenPupil') &&
            (allowedExtensionsForEditor.includes(file.title.split('.').pop().toLowerCase())
        || allowedExtensionsForLibre.includes(file.title.split('.').pop().toLowerCase())));
};
const fileListVideoInVideoButtonCondition = (file, accountRole, usage) => {
    return !isDraftCondition(file) && ( accountRole === 'teacher' &&
        usage !== 'toCorrectTeacher' &&
        usage !== 'correctedTeacher' &&
        allowedExtensionsForVideoPlayer.includes(file.title.split('.').pop().toLowerCase())
        && (!('canPip' in file) || file.canPip));
};
const fileListOptionsMenuCondition = (file, usage) => {
    return isDraftCondition(file) ||
        (usage !== 'abgabenPupil' &&
        usage !== 'toCorrectTeacher' &&
        usage !== 'correctedTeacher')
};
const isDraftCondition = (file) => {
    return file.draft !== undefined;
};

// Don't simplify it, "return isMoved.length > 0" will not do the same
const wasMoved = (isMoved) => {
    return isMoved.length <= 0 ? false : true;
};

export {
    playButtonCondition,
    editButtonCondition,
    doneButtonCondition,
    infoButtonCondition,
    fileListEyeButtonCondition,
    fileListVideoInVideoButtonCondition,
    fileListOptionsMenuCondition,
    isDraftCondition,
}
