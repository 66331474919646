import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const actions = {
  async updateDeactivatedFeatures({ commit, dispatch, },params) {
    try {
      const res = await backend.toggleDeactivateFeature(params);
      await checkResponseStatus(200, res);
      const updatedDeactivatedFeatures = await res.json();
      commit('setDeactivatedFeatures',updatedDeactivatedFeatures);
      return updatedDeactivatedFeatures;
    }
    catch (err) {
        console.error(err);
        return err?.response?.status;
    }
  },
  async fetchDeactivatedFeatures({ commit, dispatch, getters }, update = false) {
    try {
        if (getters.deactivatedFeatures && getters.deactivatedFeatures.length > 0 && !update) {
            return getters.deactivatedFeatures;
        }

        const res = await backend.getDeactivatedFeature();
        await checkResponseStatus(200, res);
        const deactivatedFeatures = await res.json();
        commit('setDeactivatedFeatures', deactivatedFeatures);
        return deactivatedFeatures;
    }
    catch(err) {
        console.error(err);
        return err?.response?.status;
    }
},
};

const mutations = {
    setDeactivatedFeatures(state, deactivatedFeatures) {
        state.deactivatedFeatures = deactivatedFeatures;
    },
};

const getters = {
   deactivatedFeatures: state => state.deactivatedFeatures,

   appsDeactivated: state => state.deactivatedFeatures.apps,
   chatDeactivated: state => state.deactivatedFeatures.chat,
   brettDeactivated: state => state.deactivatedFeatures.blackboard,
   planDeactivated: state => state.deactivatedFeatures.plan,
   messagesDeactivated: state => state.deactivatedFeatures.messages,
   filesDeactivated: state => state.deactivatedFeatures.files,
   calendarDeactivated: state => state.deactivatedFeatures.calendar,
   diaryDeactivated: state => state.deactivatedFeatures.diary,
   pinboardDeactivated: state => state.deactivatedFeatures.pinboard,

};

const state = {
    deactivatedFeatures: [],
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
