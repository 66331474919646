import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const defaultState = {
    roomListInvalid: false,
    rooms: []
};

const mutations = {
    setRoomListInvalid: (state, status) => {
        state.roomListInvalid = status;
    },
};

const getters = {
    getRoomInvalidStatus: state => state.roomListInvalid,
    rooms: state => state.rooms,
};

const actions = {
    setRoomListStatus ({ commit }, status) {
      commit('setRoomListInvalid', status);
    },
  
    async createRoom ({ commit, dispatch }, params) {
      try {
        const res = await backend.postRoom(params);
        await checkResponseStatus(201, res);
        const room = await res.json();
        return room;
      }
      catch(err) {
        console.error(err);
        return err?.response?.status;
      }
    },
  
    async editRoom ({ commit, dispatch }, params) {
      try {
        const id = params._id;
        delete params._id;
        const res = await backend.patchRoom(id, params);
        const room = await res.json();
        return room;
      }
      catch(err) {
        console.error(err);
              return err?.response?.status;
      }
    },
  
    async deleteRoom ({ commit, dispatch }, id) {
      try {
        const res = await backend.deleteRoom(id);
        await checkResponseStatus(204, res);
        return true;
      }
      catch(err) {
        console.error(err)
        return false;
      }
    },
  
    async getRooms ({ commit, dispatch, getters }, update = false) {
      try {
          if(getters.rooms && getters.rooms.length > 0 && !update)
          return getters.rooms;
          const res = await backend.getRooms();
          await checkResponseStatus(200, res);
          const rooms = await res.json();
          return rooms;
      }
      catch(err) {
          console.error(err);
          return err?.response?.status;
      }
    },

    async getRoomById({ commit, dispatch, getters }, { roomId, update = false }) {
      try {
          if(getters.rooms && getters.rooms.length > 0 && !update) {
            const requestedRoom = getters.rooms.find((room) => room._id === roomId);
            if (requestedRoom) {
              return requestedRoom;
            }
          }
          const res = await backend.getRoomById(roomId);
          await checkResponseStatus(200, res);
          const room = await res.json();
          return room;
      }
      catch(err) {
          console.error(err);
          return err?.response?.status;
      }
    },

    async getRoomsForTimeslot({ commit, dispatch }, {timeslotId, day,  update = false }) {
      try {
        const res = await backend.getRoomsForTimeslot(timeslotId, day);
        await checkResponseStatus(200, res);
        const rooms = await res.json();
        return rooms;
      } catch (err) {
        console.error(err);
        return err?.response?.status;
      }
    },
  };
  
  export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
  };
  