import * as backend from '../../api/backend';
import {checkResponseStatus} from "@/util/check";
import { UnexpectedResponseStatusError } from '../../util/error';

const defaultState = {
    school: null,
    schoolLogo: null
};

const mutations = {
    setSchool: (state, list) => {
        state.school = list;
    },
    setSchoolLogo: (state, list) => {
        state.schoolLogo = list;
    },
};

const getters = {
    school: state => state.school,
    setSchoolLogo: state => state.setSchoolLogo,
}

const actions = {
    async getSchool({ commit, dispatch, getters }, update = false) {
        try {
            if (getters.school && getters.school.length > 0 && !update) {
                return getters.school;
            }

            const res = await backend.getSchool();
            if (res.status !== 200 && res.status !== 201) {
                throw UnexpectedResponseStatusError;
            }
            const school = await res.json();
            commit('setSchool', school);
            return school;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postSchool({ commit, dispatch }, params) {
        try {
            const res = await backend.createAndUpdateSchool(params);
            await checkResponseStatus(201, res);
            const school = await res.json();
            commit('setSchool', school);
            return school;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSchoolLogo({ commit, dispatch, getters }, { schoolId, schoolLogo, type, update = false }) {
        try {
            if (getters.schoolLogo && getters.schoolLogo.length > 0 && !update) {
                if (type) {
                    return {url: getters.schoolLogo, type: blob.type};
                } else {
                    return getters.schoolLogo;
                }
            }

            const res = await backend.getSchoolLogo(schoolId, schoolLogo);
            await checkResponseStatus(200, res);
            let blob = await res.blob();
            const url = window.URL.createObjectURL(blob);
            commit('setSchoolLogo', url)
            if (type) {
                return {url: url, type: blob.type};
            } else {
                return url;
            }
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
}
