<template>
    <div>
        <vue-custom-scrollbar
            class="scroll-area"
            :settings="settings"
        >
            <div
                v-if="appointmentsLoading"
                style="margin-bottom: 20px; height: 74vh; position: relative"
            >
                <div
                    class="d-flex flex-column justify-space-around align-center"
                    style="position: relative; top: 40%"
                >
                    <v-progress-circular
                        indeterminate
                        color="var(--v-blackboardGrey-base)"
                    />
                    <p
                        class="text-center align-center"
                        style="color: black"
                    >
                        Stunden der Woche werden geladen, dies kann einige Sekunden dauern.
                    </p>
                </div>
            </div>
            <div
                v-else
                style="margin-bottom: 20px; height: 74vh; position: relative"
            >
                <div
                    style="display: flex; flex-direction: row; width: 100%; padding-left: 3px;"
                >
                    <div
                        v-if="editMode && (accountRole === 'maintainer' || (accountRole === 'teacher' && canEditTimeslot))"
                        :class="editMode ? 'columnTimeEdit': 'columnTime'"
                        style="position: absolute; height: 29px; display: flex; align-items: center; justify-content: center; z-index: 10;"
                    >
                        <v-btn
                            class="text-none ml-1 pr-2 mt-2"
                            style="background-color: var(--v-success-base); color: white; min-width: 60px; height: 35px"
                            :disabled="editTimeslotDialog"
                            @click="openCreateTimeslot()"
                        >
                            <img
                                :src="plusIcon"
                                class="icon20 iconToWhite mr-2"
                                alt="Zeitslot erstellen"
                            >
                        </v-btn>
                    </div>
                    <div
                        v-else
                        :class="editMode ? 'columnTimeEdit': 'columnTime'"
                        style="position: absolute; height: 29px; display: flex; align-items: center; justify-content: center; z-index: 10;"
                    >
                        <img
                            :src="clockIcon"
                            alt="Uhr"
                            style="height: 21px"
                        >
                    </div>
                    <div
                        :class="editMode ? 'marginToTimeEdit': 'marginToTime'"
                        style="display: flex; flex-direction: row"
                    >
                        <div
                            v-for="(dayString, index) in dayStrings"
                            :key="`dailyHeaderInWeek${dayString}${index}`"
                            class="centerHeader columnDay"
                            :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : ''}`"
                            style="border-radius: 5px 5px 0 0"
                        >
                            <div
                                :class="`${isDisplayDateTodayShort(nicelyFormattedWeekDayDate(dayString)) ?
                                    'colHeaderCurrent' : 'colHeader'} text-center`"
                                style="width: 100%; padding-top: 5px"
                                class="d-flex flex-row align-center pa-0 justify-center"
                            >
                                <v-menu
                                    bottom
                                    nudge-bottom="40"
                                    nudge-left="110"
                                    :z-index="10"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <u>{{ dayString === 'mon' ? `KW ${getMondayOfWeek(date).getWeek()}/` :'' }}{{ nicelyFormattedWeekDayDate(dayString) }}</u>
                                        </div>
                                    </template>

                                    <v-date-picker
                                        v-model="datePickerString"
                                        :allowed-dates="weekdaysOnly"
                                        locale="de-DE"
                                        first-day-of-week="1"
                                        no-title
                                        show-week
                                        color="var(--v-timetableColor-base)"
                                        @change="$emit('changeDate',datePickerString)"
                                    />
                                </v-menu>
                                <v-tooltip
                                    v-if="sickMode && !editMode && someAppointmentsToday(dayString)"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="ml-2 pa-0"
                                            small
                                            style="height: 36px; width: 36px; border-radius: 12px"
                                            min-width="29px"
                                            :style="`background-color: ${sickAtWholeDay(dayString) ? 'var(--v-error-base)' : 'white'}`"
                                            v-on="on"
                                            @click="handleDayStringClick(dayString)"
                                        >
                                            <img
                                                v-if="!sickAtWholeDay(dayString)"
                                                :src="anwesendIcon"
                                                alt="Für den ganzen Tag Abwesend melden"
                                                style="height: 18px"
                                            >
                                            <img
                                                v-else
                                                :src="abwesendIcon"
                                                alt="Abwesenheit für diesen Tag zurücknehmen"
                                                class="iconToWhite"
                                                style="height: 18px"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>
                                        {{
                                            sickAtWholeDay(dayString) ?
                                                'Abwesenheit für diesen Tag zurücknehmen' : 'Für den ganzen Tag Abwesend melden'
                                        }}
                                    </span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="editMode && ((accountRole === 'teacher' && canEditTimeslot) || accountRole === 'maintainer') &&
                        (!editMode ? !teacherSlotsInWeek.length : !slots.length)"
                    style="display: flex; flex-direction: column; margin-right: 8px; align-items: center;"
                >
                    <v-btn
                        class="my-4 text-none"
                        style="background-color: var(--v-success-base); color: white"
                        @click="openCreateTimeslot()"
                    >
                        <img
                            :src="plusIcon"
                            class="icon20 iconToWhite mr-2"
                            alt="Plus"
                        >
                        Neue Zeitslots erstellen
                    </v-btn>
                    <p>
                        Es sind keine Zeitslots für diese Klasse vorhanden, um den Stundenplan anzeigen zu können.
                    </p>
                    <p>
                        Bitte drücken Sie auf den "Neue Zeitslots erstellen"-Knopf, um neue Zeitslots anzulegen.
                    </p>
                </div>
                <div
                    v-if="!teacherSlotsInWeek.length && !editMode && accountRole === 'teacher'"
                    class="mt-4"
                    style="display: flex; flex-direction: column; margin: 16px; align-items: center; font-size: 20px; padding-top: 9vh"
                >
                    <div v-if="filterMode">
                        <p>
                            Bitte wählen Sie einen Lehrer aus um dessen Stundenplan anzuzeigen oder verlassen Sie den Suchmodus um ihren Stundenplan anzuzeigen.
                        </p>
                    </div>
                    <div v-else>
                        <p>
                            Es sind keine Unterrichtsstunden für diese Klasse vorhanden, um den Stundenplan anzeigen zu können.
                        </p>
                        <p>
                            Bitte den Bearbeitungsmodus aktivieren und neue Unterrichtsstunden anlegen.
                        </p>
                    </div>
                </div>
                <div
                    v-if="(accountRole === 'parent' || accountRole === 'pupil') && slots.length < 1"
                    class="mt-4"
                    style="display: flex; flex-direction: column; margin: 16px; align-items: center; font-size: 20px; padding-top: 9vh"
                >
                    <div
                        class="d-flex flex-row"
                        style="height: 100px; justify-content: center"
                    >
                        <img
                            :src="keineStundenIcon"
                            alt="Keine Stunden"
                            style="width: 80px;height: 80px;"
                        >
                    </div>
                    <p>
                        Der Schüler ist noch keiner Gruppe zugewiesen und hat daher keinen Stundenplan!
                    </p>
                </div>
                <v-dialog
                    v-if="editTimeslotDialog"
                    v-model="editTimeslotDialog"
                    max-width="290px"
                    min-width="290px"
                    width="290"
                    hide-overlay
                    @click:outside="resetEditDialog"
                >
                    <Timeslot
                        :key="editTimeslotDialog"
                        :timeslot="timeslotToEdit"
                        :edit-mode="true"
                        @showAreYouSure="(timeslot)=>{showDeleteDialog(timeslot)}"
                        @saveTimeslot="(timePickerStart,timePickerEnd)=>{saveEditedTimeslot(timePickerStart,timePickerEnd)}"
                        @closeEditTimeslot="()=>{ editTimeslotDialog = !editTimeslotDialog; resetEditDialog();}"
                    />
                </v-dialog>
                <div
                    v-for="(timeslot,index) in accountRole === 'teacher' && !editMode ? teacherSlotsInWeek : filteredAndsortedSlots"
                    :key="timeslot._id"
                    style="display: flex; flex-direction: row; width: 100%; align-items: center; padding-left: 3px;"
                >
                    <!--#region Timeslots -->
                    <div
                        v-if="(!editMode && timeslot.category === 'normal') || editMode"
                        style="color: var(--v-dunkelgrau-base); font-weight: normal; position:absolute"
                        class="d-flex align-center justify-center py-4"
                        :class="editMode ? 'columnTimeEdit': 'columnTime'"
                    >
                        <div
                            v-if="(accountRole === 'teacher' && editMode && canEditTimeslot) || accountRole === 'maintainer'"
                            style="display: flex; flex-direction: row; align-items: center"
                        >
                            <div
                                :id="`timeslot${timeslot._id}`"
                                :class="timeslot._id === timeslotToEdit?._id ? 'editingTimeslot':''"
                                @click="openEditDialog(timeslot)"
                            >
                                <v-text-field
                                    v-model="timeslot.timePickerStart"
                                    readonly
                                    hide-details
                                    dense
                                    solo
                                    single-line
                                    class="timeslotField"
                                    :style="timeslot.isMain || timeslot.version === 1 ? 'font-weight: bold;':''"
                                />
                                <v-text-field
                                    v-model="timeslot.timePickerEnd"
                                    readonly
                                    hide-details
                                    dense
                                    solo
                                    single-line
                                    class="timeslotField"
                                    :style="timeslot.isMain || timeslot.version === 1 ? 'font-weight: bold;':''"
                                />
                            </div>
                        </div>
                        <div
                            v-else
                            :style="windowWidth < 600 ? 'font-size: 11px' : ''"
                        >
                            {{ ("0" + timeslot.start.getHours()).slice(-2) + ":" + ("0" + timeslot.start.getMinutes()).slice(-2) }}
                            <br>
                            {{ ("0" + timeslot.end.getHours()).slice(-2) + ":" + ("0" + timeslot.end.getMinutes()).slice(-2) }}
                        </div>
                    </div>                 
                    <!--#endregion -->

                    <!--#region Days -->
                    <div
                        :class="editMode ? 'marginToTimeEdit': 'marginToTime'"
                        style="display: flex; flex-direction: row"
                    >
                        <!-- can't rename index in v-for -->
                        <div
                            v-for="(dayString) in dayStrings"
                            :key="`dayInWeek${dayString}`"
                            class="d-flex columnDay"
                            :class="`${dayString === 'tue' || dayString === 'thu' ? 'greyDayBody' : ''}`"
                        >
                            <div
                                :style="index !== Object.keys(slots).length - 1 ? '' : 'border-radius: 0 0 10px 10px'"
                                style="width: 100%"
                            >
                                <div
                                    v-for="appointment in getAppointmentsPerSlotOnDay(timeslot._id, dayString)"
                                    :key="appointment._id + dayString + refreshKey + (appointmentsThisWeek||[]).length"
                                >
                                    <Fach
                                        class="py-0"
                                        :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                        :appointment="appointment"
                                        :timeslot="timeslot"
                                        :request-appointments="requestAppointments"
                                        :slots="slots"
                                        :me="mePupil"
                                        :parent="accountRole === 'parent'"
                                        :edit-mode="editMode"
                                        :room-mode="roomMode"
                                        :fullscreen-weekly-view="true"
                                        :subjects="subjects"
                                        :teachers="teachers"
                                        :current-group="currentGroup"
                                        :current-room-model="currentRoomModel"
                                        :day="getDateFromWeekday(dayString)"
                                        :sick-mode="sickMode"
                                        :copy-mode="copyMode"
                                        :current-copied-appointment="currentCopiedAppointment"
                                        :icons="icons"
                                        :add-new-teacher="addNewTeacher"
                                        :person="person"
                                        :groups="groups"
                                        :filter-mode="filterMode"
                                        :sick-appointments="sickAppointments"
                                        :remove-sick-appointments="removeSickAppointments"
                                        :is-joinable="isTimeslotCurrentlyRunning(timeslot, dayString) ? isJoinable(timeslot) : false"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                        @clickedFach="openLessonOutside"
                                        @setCopyLesson="setCopyLesson"
                                        @stopCopyMode="stopCopyMode"
                                        @createTeacher="$emit('createTeacher')"
                                        @sickClick="handleSickClick"
                                        @undoSick="handleUndoSick"
                                    />
                                </div>
                                <div
                                    v-if="!getAppointmentsPerSlotOnDay(timeslot._id, dayString).length"
                                    style="width: 100%"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(timeslot, dayString)"
                                        :request-appointments="requestAppointments"
                                        :edit-mode="editMode"
                                        :room-mode="roomMode"
                                        :fullscreen-weekly-view="true"
                                        :subjects="subjects"
                                        :teachers="teachers"
                                        :current-group="currentGroup"
                                        :current-room-model="currentRoomModel"
                                        :timeslot="timeslot"
                                        :copy-mode="copyMode"
                                        :icons="icons"
                                        :sick-mode="sickMode"
                                        :groups="groups"
                                        :filter-mode="filterMode"
                                        :current-copied-appointment="currentCopiedAppointment"
                                        :day="getDateFromWeekday(dayString)"
                                        :person="person"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                        @createTeacher="$emit('createTeacher')"
                                        @sickClick="handleSickClick"
                                        @undoSick="handleUndoSick"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-custom-scrollbar>
        <v-snackbar
            :value="copyMode"
            bottom
            timeout="3500"
            color="var(--v-hellblau-base)"
            style="bottom: 9vh"
        >
            <div style="display: flex; flex-direction: row; justify-content: space-between">
                <span style="font-size: large; margin-right: 8px">
                    <span style="font-weight: bold">Kopiermodus:</span> Klicken Sie auf einen freien Zeitslot um die kopierte Stunde einzufügen.
                </span>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            elevation="0"
                            style="height: 55px; width: 55px; border-radius: 12px; background-color: red"
                            v-on="on"
                            @click="stopCopyMode()"
                        >
                            <img
                                :src="cancelIcon"
                                class="iconToWhite"
                                style="height: 35px; width: 35px"
                                alt="Kopieren beenden"
                            >
                        </v-btn>
                    </template>
                    <span>Kopieren beenden</span>
                </v-tooltip>
            </div>
        </v-snackbar>
        <v-dialog
            v-if="createTimeslotDialog"
            v-model="createTimeslotDialog"
            width="290"
        >
            <Timeslot 
                :edit-mode="false"
                :new-time-start="'08:00'"
                :new-time-end="'09:00'"
                @closeCreateTimeslot="createTimeslotDialog = false"
                @createTimeslot="(newTimeslot)=>{createTimeslot(newTimeslot)}"
            />
        </v-dialog>

        <v-dialog
            v-if="areYouSureDeleteDialog"
            v-model="areYouSureDeleteDialog"
            width="400"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                    style="background-color: var(--v-timetableColor-base)"
                >
                    <p style="color: white">
                        Sind Sie sicher?
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="resetAreYouSure()"
                    >
                        <img
                            :src="cancelIcon"
                            class="iconToWhite icon30"
                            alt="abbrechen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p
                        class="mt-5"
                        style="color: var(--v-error-base); font-size: 15px"
                    >
                        {{ areYouSureText }}
                    </p>
                </v-card-text>
                <v-card-actions
                    class="mb-2"
                    style="display: flex; justify-content: space-between"
                >
                    <v-btn
                        class="optionBtn text-none"
                        color="#F5F5F5"
                        @click="resetAreYouSure()"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                            alt="Erstellen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="optionBtn text-none"
                        style="background-color: var(--v-error-base); color: white"
                        @click="deleteTimeslot"
                    >
                        <img
                            :src="trashIcon"
                            class="icon20 iconToWhite mr-2"
                            alt="Löschen"
                        >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay
            :value="showLoadingWeeklyView"
            z-index="9999"
            opacity="0.2"
        >
            <v-progress-circular
                indeterminate
                size="100"
                color="white"
            />
        </v-overlay>
    </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters, mapState} from "vuex";

import Fach from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/Fach";
import Timeslot from '@/components/Verwaltung/Timeslot.vue';
import vueCustomScrollbar from "vue-custom-scrollbar";
import clockIcon from '@/assets/Icons/FaceliftIcons/stundenplan_uhr.svg'
import abwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer_abwesend.svg";
import anwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import editIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import trashIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import plusIcon from "@/assets/Icons/plus.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import keineStundenIcon from "@/assets/Icons/FaceliftIcons/keine_stunden.svg";
import { getWeekDayDate, getDayDateLocaleString } from "@/util/timetable";

export default {
    components: {
        Fach,
        vueCustomScrollbar,
        Timeslot,
    },
    props: {
        appointments: { required: true, type: Array },
        sickMode: {default: false, type: Boolean, required: false},
        filterMode: {default: false, type: Boolean, required: false},
        editMode: { required: false, type: Boolean },
        roomMode: {default: false, type: Boolean, required: false},
        person: { required: false, type: Object },
        currentGroup: { required: false },
        currentRoomModel: {type: Object, required: false, default: () => {}},
        date: { required: false },
        subjects: { required: true },
        slots: { required: true },
        teachers: { required: true },
        icons: { type: Array, required: false},
        sickAppointments: { type: Array, required: false},
        removeSickAppointments: { type: Array, required: false},
        showLoadingWeeklyView: { type: Boolean, required: false },
        requestAppointments: {type: Function},
        requestSlots: {type: Function},
        getWorkDaysOfWeek: {type: Function},
        datesOnSameDay: {type: Function},
        markOrUnmarkAppointmentAsSick: {type: Function},
        markAppointmentToRemoveFromSickList: {type: Function, required: false},
        addNewTeacher: {type: Function},
        canEditTimeslot: { required: false, type: Boolean, default: false},
        closeCopy: { required: false, type: Boolean, default: true}
    },
    data: () => ({
        editTimeslotDialog: false,
        settings: {
            suppressScrollY: false,
            suppressScrollX: false,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
        refreshKey: false,
        today: new Date(),
        appointmentsThisWeek: [],
        localOpen: false,
        dayStrings: ['mon', 'tue', 'wed', 'thu', 'fri'],

        mePupil: null,
        teacher: null,

        // Copy Feature
        copyMode: false,
        currentCopiedAppointment: null,

        // Timeslots
        timeslotForAllGroups: true,
        createTimeslotDialog: false,
        areYouSureDeleteDialog: false,
        areYouSureText: 'Dieser Zeitslot und alle Stunden dieser Gruppe, die in diesem Zeitslot stattfinden/stattgefunden haben, werden mit ihren Inhalten gelöscht. Diese Änderung betrifft alle Mitglieder dieser Gruppe.',
        newTimeslotTimePickerStart: '',
        newTimeslotTimePickerEnd: '',
        timeslotToDelete: null,
        timeslotToEdit: null,
        isNewTimeslotBreak: false,

        // Icons
        clockIcon,
        cancelIcon,
        editIcon,
        abwesendIcon,
        anwesendIcon,
        trashIcon,
        plusIcon,
        infoIcon,
        keineStundenIcon,
        
        datePickerString: new Date().toISOString(),
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters("appointments",["appointmentsLoading"]),
        ...mapGetters('groups', { 'vuexGroups': 'groups' }),
        ...mapState('util', ['windowWidth']),
        ...mapState('util', ['serverTimeOffset', 'windowWidth', 'dontOpenAppointment']),

        groups() {
            return this.vuexGroups.filter(group => group.name !== 'Alle');
        },
        filteredAndsortedSlots(){
            const slots = this.slots;
            const sorted = slots.sort((a, b) => {
                // Extract the hours and minutes from the start dates
                const startHourA = a.start.getHours();
                const startMinuteA = a.start.getMinutes();
                const startHourB = b.start.getHours();
                const startMinuteB = b.start.getMinutes();

                // Compare the start hours
                if (startHourA < startHourB) {
                    return -1;
                }
                if (startHourA > startHourB) {
                    return 1;
                }

                // If the start hours are the same, compare the start minutes
                if (startMinuteA < startMinuteB) {
                    return -1;
                }
                if (startMinuteA > startMinuteB) {
                    return 1;
                }

                // If the start hours and minutes are the same, extract the end hours and minutes
                const endHourA = a.end.getHours();
                const endMinuteA = a.end.getMinutes();
                const endHourB = b.end.getHours();
                const endMinuteB = b.end.getMinutes();

                // Compare the end hours
                if (endHourA < endHourB) {
                    return -1;
                }
                if (endHourA > endHourB) {
                    return 1;
                }

                // If the end hours are the same, compare the end minutes
                if (endMinuteA < endMinuteB) {
                    return -1;
                }
                if (endMinuteA > endMinuteB) {
                    return 1;
                }

                // If both start and end hours and minutes are the same, maintain the original order
                return 0;
            });
            return sorted;
        },

        teacherSlotsInWeek() {
            let slots = [];
            const daysOfWeek = this.getWorkDaysOfWeek(this.date);
            // Todo refactor this into one loop plus sort at the end
            const filteredSlotsByDay = this.slots.filter((slot) => {
                return daysOfWeek.some((day) => {
                    // the format of slot can differ, when the current mode is edit
                    // slot will either be { day, timeslots } or { timeslot }
                    // if we dont have a day and just the timeslot, assume its v1 timeslots that build the baseline
                    // in which case we pass the filter function
                    return slot.day ? this.datesOnSameDay(day, new Date(slot.day)) : !!slot;
                });
            })
            filteredSlotsByDay.forEach((slotsByDay) => {
                // same as above, due to different format we can sometimes just concat slotsByDay
                slots = slots.concat(slotsByDay.timeslots ? slotsByDay.timeslots : slotsByDay);
            });
            slots = slots.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
            slots.sort((a, b) => {
                        // Extract the hours from the start dates
                        const startHourA = a.start.getHours();
                        const startHourB = b.start.getHours();

                        // Compare the start hours
                        if (startHourA < startHourB) {
                            return -1;
                        }
                        if (startHourA > startHourB) {
                            return 1;
                        }

                        // If the start hours are the same, compare the end hours
                        const endHourA = a.end.getHours();
                        const endHourB = b.end.getHours();
                        if (endHourA < endHourB) {
                            return -1;
                        }
                        if (endHourA > endHourB) {
                            return 1;
                        }

                        // If both start and end hours are the same, maintain the original order
                        return 0;
                        });
            slots.forEach((slot) => {
                this.$set(slot, "timePickerStart", ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2));
                this.$set(slot, "timePickerEnd", ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2));
            });
            return slots;
        },

        canEdit() {
            return this.currentGroup !== null && this.currentGroup !== undefined;
        },
        nicelyFormattedWeekDayDate() {
            return (dayString) => {
                let shortDay = 'Mo., ';
                switch (dayString) {
                    case 'mon': shortDay = 'Mo., '; break;
                    case 'tue': shortDay = 'Di., '; break;
                    case 'wed': shortDay = 'Mi., '; break;
                    case 'thu': shortDay = 'Do., '; break;
                    case 'fri': shortDay = 'Fr., '; break;
                }

                const weekDayDate = this.getWeekDayDateWrapper(dayString, this.date);
                return shortDay + getDayDateLocaleString(weekDayDate);
            }
        },
    },
    watch: {
        date() {
            this.appointmentsOfThisWeek();
        },
        appointments(newVal) {
            this.appointmentsOfThisWeek();
        },
        editMode() {
            if(this.editMode === false) {
                this.stopCopyMode();
            }
        },
        closeCopy(newVal,oldVal){
            if(newVal === true && oldVal === false){
                this.stopCopyMode();
            }
        }
    },
    async mounted() {
        this.localOpen = true;
        if(this.accountRole === "teacher") {
            this.teacher = this.person;
        } else if (this.accountRole === 'pupil') {
            this.mePupil = this.person;
        } else if(this.accountRole === 'maintainer') {
            this.slots.forEach((slot) => {
                slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) + ':' + ('0' + slot.start.getMinutes()).slice(-2);
                slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) + ':' + ('0' + slot.end.getMinutes()).slice(-2);
            });
        }
        if(this.setEditMode === true) {
            if(this.canEdit) {
                this.setEditMode(true);
            } else {
                this.showSnackbar({ message: 'Sie sind kein Klassenlehrer. Nur Klassenlehrer können die Stundenpläne ihrer Klasse bearbeiten.', color: 'error', timeout: 6500 });
            }
            this.$emit("resetEditModeChanger");
        }
        this.appointmentsOfThisWeek();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments", "getAppointmentsParent", "getAppointmentRuleById"]),
        ...mapActions("timeslots", ["getSlots", "editSlot", "createSlot", "deleteSlot"]),
        ...mapActions("groups", ["getGroups", "setGroupInvalidStatus"]),
        ...mapActions("copypaste", ["turnOffCopyMode"]),
        ...mapActions('teachers', ['editTeacher']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations('weeklyView', ['setEditMode']),

        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },
        getMondayOfWeek(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        someAppointmentsToday(dayString) {
            const slots = this.accountRole === 'teacher' && !this.editMode ? this.teacherSlotsInWeek : this.slots;
            return slots.some((slot) => this.getAppointmentsPerSlotOnDay(slot._id, dayString).length);
        },

        sickAtWholeDay(dayString) {
            let foundAppointments = [];
            const array = this.accountRole === 'teacher' && !this.editMode ? this.teacherSlotsInWeek : this.slots;
            array.forEach((slot) => {
                foundAppointments = foundAppointments.concat(this.getAppointmentsPerSlotOnDay(slot._id, dayString));
            });
            foundAppointments = foundAppointments.filter((appointment) => {
                if(appointment.substitutions) {
                    return !appointment.substitutions.some(el => el.sickTeacher._id === this.person._id);
                }
                return true;
            });
            const markedAppointments = foundAppointments.filter((appointment) => this.sickAppointments.includes(appointment._id));
            return markedAppointments.length === foundAppointments.length;
        },

        async handleDayStringClick(dayString) {
            if (!this.sickMode) {
                return;
            }

            let foundAppointments = [];
            const array = this.accountRole === 'teacher' && !this.editMode ? this.teacherSlotsInWeek : this.slots
            array.forEach((slot) => {
                foundAppointments = foundAppointments.concat(this.getAppointmentsPerSlotOnDay(slot._id, dayString));
            });
            let alreadySickAppointments = [];
            let newSickAppointments = [];
            newSickAppointments = foundAppointments.filter((appointment) => {
                if(appointment.substitutions) {
                    alreadySickAppointments.push(appointment);
                    return !appointment.substitutions.some(el => el.sickTeacher._id === this.person._id);
                }
                return true;
            });

            if(alreadySickAppointments.length > 0 && alreadySickAppointments.length === foundAppointments.length && newSickAppointments.length === 0){
                    alreadySickAppointments.forEach(appointment => {
                        this.markAppointmentToRemoveFromSickList(appointment._id);
                    })

            }
            this.markOrUnmarkAppointmentAsSick(newSickAppointments.map(el => el._id));
        },
        async handleSickClick(appointment) {
            if (!appointment) {
                return;
            }
            this.markOrUnmarkAppointmentAsSick(appointment._id);
        },
        async handleUndoSick(appointment) {
            if (!appointment) {
                return;
            }
            this.markAppointmentToRemoveFromSickList(appointment._id);
        },
        setNewTimeslotTimePickerStart(time) {
            this.newTimeslotTimePickerStart = time;
            this.$refs.newTimeslotTimePickerStart.save(this.newTimeslotTimePickerStart);
        },

        setNewTimeslotTimePickerEnd(time) {
            this.newTimeslotTimePickerEnd = time;
            this.$refs.newTimeslotTimePickerEnd.save(this.newTimeslotTimePickerEnd);
        },

        openCreateTimeslot() {
            this.newTimeslotTimePickerStart = '08:00';
            this.newTimeslotTimePickerEnd = '09:00';
            this.createTimeslotDialog = true;
        },

        resetCreateDialog() {
            this.createTimeslotDialog = false;
               this.newTimeslotTimePickerStart='08:00';
               this.newTimeslotTimePickerStart='09:00';
        },
        resetAreYouSure(){
            this.areYouSureDeleteDialog = false; 
            this.timeslotToEdit = null;
            this.imeslotToDelete = false;
        },
        async createTimeslot(newTimeslot) {   
            const start = new Date();
            const end = new Date();
            start.setHours(parseInt(newTimeslot.start.slice(0, 3)), parseInt(newTimeslot.start.slice(3, 5)));
            end.setHours(parseInt(newTimeslot.end.slice(0, 3)), parseInt(newTimeslot.end.slice(3, 5)));
            if(newTimeslot.timeslotForAllGroups && this.accountRole === 'maintainer'){
                const responses =   await Promise.all(this.groups.map(async (group)=>{
                    const res = await this.createSlot({
                    start,
                    end,
                    group: group._id,
                    category: newTimeslot.isNewTimeslotBreak ? 'break' : 'normal',
                    isMain: true,
                    });
                    return res;
                }))
                if(responses.length === this.groups.length){
                    this.showSnackbar({ message: 'Gespeichert!', snackbarClosable: false });
                    //this needs to be called so the getter timeslotsByGroupId has the group with updated timeslots
                    await this.getGroups(true);
                }else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen!', color: 'error'});
                }
                await this.requestSlots(true);
                this.resetCreateDialog();
            }else{
                const res = await this.createSlot({
                    start:start,
                    end: end,
                    group: this.currentGroup._id,
                    category: newTimeslot.isNewTimeslotBreak ? 'break' : 'normal',
                });
                if(typeof res === 'object') {
                    this.showSnackbar({ message: 'Gespeichert!', snackbarClosable: false });
                    //this needs to be called so the getter timeslotsByGroupId has the group with updated timeslots
                    await this.getGroups(true);
                } else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen!', color: 'error'});
                }
                await this.requestSlots(true);
                this.resetCreateDialog();
            }

        },

        async deleteTimeslot() {
            await this.deleteSlot({
                id: this.timeslotToDelete._id,
                groupId: this.currentGroup._id,
            });
            await this.getGroups(true);
            await this.requestSlots(true);
            // await this.requestAppointments();
            this.areYouSureDeleteDialog = false;
            this.createTimeslotDialog = false;
            this.resetAreYouSure();
        },
        showDeleteDialog(timeslot){
            this.timeslotToDelete = timeslot;
            this.editTimeslotDialog = false;
            this.areYouSureDeleteDialog = true;
        },
        openEditDialog(timeslot){
        this.editTimeslotDialog = true;
        this.timeslotToEdit = timeslot;
        },
        resetEditDialog(){
            this.timeslotToEdit = null;
        },
        async saveEditedTimeslot(timePickerStart,timePickerEnd) {
            const timeslot = this.timeslotToEdit;
            timeslot.timePickerStart = timePickerStart;
            timeslot.timePickerEnd = timePickerEnd;
            const startTimeMinutes = parseInt(timePickerStart.slice(0, 3)) * 60 + parseInt(timePickerStart.slice(3, 5));
            const endTimeMinutes = parseInt(timePickerEnd.slice(0, 3)) * 60 + parseInt(timePickerEnd.slice(3, 5));

            if(startTimeMinutes >= endTimeMinutes || endTimeMinutes <= startTimeMinutes) {
                this.setAndResetTimeslotTooltip(timeslot, "error");
                this.showSnackbar({message: 'Ende darf nicht vor Start liegen!', color:'error'})
             } else {
                timeslot.start.setHours(parseInt(timeslot.timePickerStart.slice(0, 3)), parseInt(timeslot.timePickerStart.slice(3, 5)));
                timeslot.end.setHours(parseInt(timeslot.timePickerEnd.slice(0, 3)), parseInt(timeslot.timePickerEnd.slice(3, 5)));
                this.setAndResetTimeslotTooltip(timeslot, "success");
                
            }
        },

        setEndTime(timeslot) {
            let menu = this.$refs['endTimeMenu' + timeslot._id];
            menu[0].save(timeslot.timePickerEnd);

            let timePickerStart = timeslot.timePickerStart;
            let timePickerEnd = timeslot.timePickerEnd;

            const startTimeMinutes = parseInt(timePickerStart.slice(0, 3)) * 60 + parseInt(timePickerStart.slice(3, 5));
            const endTimeMinutes = parseInt(timePickerEnd.slice(0, 3)) * 60 + parseInt(timePickerEnd.slice(3, 5));

            if(startTimeMinutes >= endTimeMinutes || endTimeMinutes <= startTimeMinutes) {
                this.setAndResetTimeslotTooltip(timeslot, "error");
            } else {
                timeslot.timePickerEnd = timePickerEnd;
                timeslot.end.setHours(parseInt(timeslot.timePickerEnd.slice(0, 3)), parseInt(timeslot.timePickerEnd.slice(3, 5)));
                this.setAndResetTimeslotTooltip(timeslot, "success");
            }
        },

        async setAndResetTimeslotTooltip(timeslot, state) {
            if(timeslot.timeout) {
                clearTimeout(timeslot.timeout);
                this.$set(timeslot, "tooltip", "");
                timeslot.timeout = setTimeout(async() => {
                    if(state === "success") {
                        await this.saveTimeslot(timeslot);
                        timeslot = this.slots.find(slot => slot._id === timeslot._id);
                    }
                    this.$set(timeslot, "tooltip", state);
                }, 50);
                timeslot.timeout = setTimeout(() => {
                    this.$set(timeslot, "tooltip", "");
                }, 5050);
            } else {
                if(state === "success") {
                    await this.saveTimeslot(timeslot);
                    // This is neccessary beacause timeslot reference won't exist anymore after we called
                    // this.requestSlots() in this.saveTimeslot()
                    timeslot = this.slots.find(slot => slot._id === timeslot._id);
                }
                this.$set(timeslot, "tooltip", state);
                timeslot.timeout = setTimeout(() => {
                    this.$set(timeslot, "tooltip", "");
                }, 5000);
            }
        },

        async saveTimeslot(timeslot) {
            const edited = await this.editSlot({
                _id: timeslot._id,
                group: this.currentGroup._id,
                start: timeslot.start,
                end: timeslot.end,
            });
            if(edited !== 409){
                this.showSnackbar({message: 'Zeitslot erfolgreich geändert!',color: 'success'})
            }else{
                this.showSnackbar({message: 'Beim Bearbeiten ist etwas schiefgelaufen, bitte laden sie die seite neu!',color: 'success'})
            }
            this.resetEditDialog();
            this.editTimeslotDialog = false;
            await this.requestSlots(true);
            await this.requestAppointments();
        },

        stopCopyMode() {
            this.copyMode = false;
            this.currentCopiedAppointment = null;
            this.$emit('stopCopyMode');
        },

        async setCopyLesson(appointment) {
            // Copy Copy Copy hehehe
            const deepCopyOfCopiedAppointment = JSON.parse(JSON.stringify(appointment));
            if(this.currentCopiedAppointment === null) {
                this.copyMode = true;
                this.$emit('startCopyMode');
            }
            // Get rule object from rule id
            if(typeof deepCopyOfCopiedAppointment.rule === "string") {
                deepCopyOfCopiedAppointment.rule = await this.getAppointmentRuleById(deepCopyOfCopiedAppointment.rule);
            }
            this.currentCopiedAppointment = deepCopyOfCopiedAppointment;
        },

        getDateFromWeekday(dayString) {
            const weekDayDate = this.getWeekDayDateWrapper(dayString, this.date);
            return new Date(weekDayDate.getFullYear(), weekDayDate.getMonth(), weekDayDate.getDate());
        },

        openLessonOutside(appointmentDate, appointment) {
            this.$emit("fachClicked", appointment);
        },

        receivedEmit(callback) {
            this.$emit('start-bbb-tut', callback);
        },
        getWeekDayDateWrapper(dayString, date) {
            return getWeekDayDate(dayString, date);
        },
        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            const heute = getDayDateLocaleString(this.today);
            return (compareDate.toString()).slice(5, compareDate.toString().length) === heute;
        },
        getAppointmentsPerSlotOnDay(slot, day) {
            day = getWeekDayDate(day, this.date);
            return this.appointmentsThisWeek.filter((el) => {
                return (el.timeslot._id || el.timeslot) === slot && this.datesOnSameDay(new Date(el.day), day);
            })
        },
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentsPerSlotOnDay(timeslot._id, day)[0];
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (timeslot.start.toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && timeslot.end.toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },
        appointmentsOfThisWeek() {
            this.appointmentsThisWeek = this.appointments.filter((el) => {
                const dayOfAppointment = new Date(el.day);
                return dayOfAppointment.getWeek() === this.date.getWeek()
                    && dayOfAppointment.getFullYear() === this.date.getFullYear();
            });
        },
        /**
         * Lesson is joinable: 15 minutes before start of timeslot until 15 minutes after end of timeslot
         */
        isJoinable(timeslot) {
            const time = new Date(Date.now() + this.serverTimeOffset);
            const localeStart = new Date(timeslot.start);
            localeStart.setMinutes(localeStart.getMinutes() - 15);

            const localeEnd = new Date(timeslot.end);
            localeEnd.setMinutes(localeEnd.getMinutes() + 15);
            return localeStart.toLocaleTimeString('de-DE') <= time.toLocaleTimeString('de-DE')
                && localeEnd.toLocaleTimeString('de-DE') >= time.toLocaleTimeString('de-DE');
        },
    }
}
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table,
tr,
td {
    border: none;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.fach {
    cursor: pointer;
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg)
    brightness(87%) contrast(82%);
}

.scroll-area {
    margin: auto;
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: var(--v-timetableColor-base) !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader {
    font-weight: bold;
    color: black;
    width: 18%;
}

.colHeaderCurrent {
    font-weight: bold;
    color: var(--v-timetableColor-base);
    width: 18%;
}

.greyDayHeader {
    background-color: var(--v-hellgrau-base);
    border-radius: 10px 10px 0 0;
}

.greyDayBody {
    background-color: var(--v-hellgrau-base);
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

.addOrRemoveButton {
    width: 18px;
    height: 18px;
    margin: 3px 8px 3px 0;
}

.errorTimepicker {
    background-color: var(--v-error-base);
    border-radius: 6px;
}

.columnDay {
    width: 235px;
    padding: 0 1%;
}

.columnTime {
    width: 8%;
    background-color: white;
    z-index: 9;
}

.marginToTime {
    margin-left: 8%;
    width: 92%;
}
.columnTimeEdit {
    width: 10%;
    background-color: white;
    z-index: 9;
}

.marginToTimeEdit {
    margin-left: 10%;
    width: 90%;
}

@media only screen and (max-width: 1850px) {
    .columnDay {
        min-width: 235px;
        padding: 0 4px;
    }
    // .columnTime {
    //     min-width: 80px;
    // }
    // .marginToTime {
    //     margin-left: 80px;
    // }
}

@media only screen and (min-width: 1340px) {
    .columnDay {
        width: 17vw;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .clockIcon {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .clockIcon {
        display: initial;
    }
    .responsiveEditButton {
        margin-left: -7px !important;
    }
}

@media only screen and (min-width: 901px) {
    .columnTime {
        width: 5% !important;
    }
    .marginToTime {
        margin-left: 5% !important;
    }
    .columnTimeEdit {
        width: 6% !important;
    }
    .marginToTimeEdit {
        margin-left: 6% !important;
    }
}

// Don't delete this, this works
:deep(.ps__rail-x) {
    opacity: 1;
    height: 20px;
}

:deep(.ps__thumb-x) {
    opacity: 1;
    height: 14px;
}

:deep(.ps__rail-x:hover>.ps__thumb-x) {
    height: 18px;
    background-color: #999;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
.timeslotField{
    min-width: 60px;
    max-width: 60px; 
    height: 50%; 
    padding: 0; 
}
.editingTimeslot{
    border: 4px solid #6996cd;
    border-radius: 4px;
}
</style>
