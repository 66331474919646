import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const defaultState = {
    pupils: [],
    changedPupils: [],
    tutorialProgress: {},
    pupilListInvalid: false,
    mePupil: null,
};

const mutations = {
    SET_TUTORIAL_PROGRESS: (state, {account, progress}) => {
        state.tutorialProgress = {
            ...state.tutorialProgress,
            [account]: progress,
        };
    },
    SET_PUPIL_LIST_STATUS: (state, status) => {
        state.pupilListInvalid = status;
    },
    SET_PUPILS: (state, list) => {
        state.pupils = list;
    },
    PUPIL_CHANGED: (state, changedPupil) => {
        const index = state.changedPupils.findIndex(pupil => pupil._id === changedPupil._id);
        if (index >= 0) {
            // pop - assume newer change is the better one
            state.changedPupils.splice(index, 1);
        }
        state.changedPupils.push(changedPupil);
    },
    UPDATE_PUPILS_LOCALLY: (state) => {
        if (state.changedPupils.length) {
            let pupilsCopy = [...state.pupils];

            state.changedPupils = [];
        }
    },
    pushPupilToChanged: (state, changedPupil) => {
        // Todo check if already inside
        state.changedPupils.push(changedPupil);
    },
    updatePupilState: (state) => {
        // move pupils into json representation for better search conditions
        const updatedPupilsById = state.pupils.reduce((acc, pupil) => {
            acc[pupil._id] = pupil;
            return acc;
        }, {});
        for (let i = 0; i < state.changedPupils.length; i++) {
            // find pupil in json and update
            updatedPupilsById[state.changedPupils[i]._id] = state.changedPupils[i];
        }
        // return json to array and set state
        const result = [];
        const keys = Object.keys(updatedPupilsById);
        for (let i = 0; i < keys.length; i++) {
            result.push(updatedPupilsById[keys[i]]);
        }
        state.pupils = result;
    },
    setCurrentPupil: (state, pupil) => {
        state.mePupil = pupil;
    },
    clearCurrentPupil: (state) => {
        state.mePupil = null;
    },
};

const getters = {
    getTutorialProgress: state => state.tutorialProgress,
    getPupilListStatus: state => state.pupilListInvalid,
    pupilsByAccountId: state => state.pupils.reduce((acc, pupil) => {
        acc[pupil.account] = pupil;
        return acc;
    }, {}),
    pupilsById: state => state.pupils.reduce((acc, pupil) => {
        acc[pupil._id] = pupil;
        return acc;
    }, {}),
    // Todo: find vuexPupils and destro... i mean refactor
    vuexPupils: state => state.pupils,
    pupils: state => state.pupils,
    currentPupil: state => state.mePupil,
};

const actions = {
    async getPupils({commit, dispatch, getters}, update = false) {
        try {
            if (getters.pupils && getters.pupils.length > 0 && !update) {
                return getters.pupils;
            }

            const res = await backend.getPupils();
            await checkResponseStatus(200, res);
            const pupils = await res.json();
            commit('SET_PUPILS', pupils);
            return pupils;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    setPupilListStatus({commit}, status) {
        commit('SET_PUPIL_LIST_STATUS', status);
    },
    setTutorialProgress({getters, commit}, {account, progress}) {
        const accountProgress = getters.getTutorialProgress[account] || [];
        let newAccountProgress = [];
        if (Array.isArray(progress)) {
            newAccountProgress = [...accountProgress, ...progress]
        } else {
            newAccountProgress = [...accountProgress, progress]
        }
        commit('SET_TUTORIAL_PROGRESS', {account, progress: newAccountProgress});
    },

    async createPupil({commit, dispatch}, params) {
        try {
            const res = await backend.postPupil(params);
            await checkResponseStatus(201, res);
            const account = await res.json();
            return account;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editPupil({commit, dispatch}, params, readyToUpdate = true) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchPupil(id, params);
            if (res.status === 409) {
                return 409;
            }
            const resJson = await res.json();
            return resJson;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editPupilEnhanced({commit}, {pupilParams, readyToUpdate = true}) {
        try {
            const id = pupilParams._id
            delete pupilParams._id;
            const res = await backend.patchPupil(id, pupilParams);
            await checkResponseStatus(201, res);
            const resJson = await res.json();
            commit('pushPupilToChanged', resJson);
            if (readyToUpdate) {
                commit('updatePupilState');
            }
            return resJson;
        } catch (e) {
            // Todo handle error of checkResponseStatus
            console.error(e)
            return e;
        }
    },

    async deletePupile({commit, dispatch}, id) {
        try {
            const res = await backend.deletePupil(id);
            if (res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err)
            return false;
        }
    },

    async getPupil({commit, dispatch}, id) {
        try {
            const res = await backend.getPupil(id);
            await checkResponseStatus(200, res);
            const pupil = await res.json();
            return pupil;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMePupil({commit, dispatch, getters}) {
        try {
            if (getters.mePupil) {
                return getters.mePupil;
            }
            const res = await backend.getMePupil();
            await checkResponseStatus(200, res);

            const resJson = await res.json();
            commit("setCurrentPupil", resJson);
            // save translation allowed status in translation.js store module
            dispatch('translation/setTranslationAllowed', !!resJson.translationAllowed, {root: true});
            return resJson;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async removeParentFromPupil({ commit, dispatch }, params) {
        try {
            const { pupilId, parentId } = params;
            const res = await backend.removeParentFromPupil(pupilId, parentId);
            if (res.status === 409) {
                return 409;
            }
            const resJson = await res.json();
            return resJson;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
