import * as backend from '../../api/backend';
import {checkResponseStatus} from "@/util/check";

const state = {

};

const actions = {
    deleteDocumentDraft: async ({commit}, draftId) => {
        try {
            const response = await backend.deleteDocumentDraft(draftId);
            await checkResponseStatus(200, response);
            return await response.json();
        } catch (e) {
            return e;
        }
    },
};

const mutations = {

};

const getters = {

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
