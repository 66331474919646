const state = {
    avatarChanged: false,
    open: false,
}

const actions = {
    setAvatarChanged({commit}, state) {
        commit('SET_AVATAR_CHANGED', state);
    },
    setAvatarPickerOpen({commit}, state) {
        commit('SET_PICKER_OPEN', state);
    },
}

const getters = {
    getAvatarPickerOpen: state => state.open,
}

const mutations = {
    SET_AVATAR_CHANGED: (state, value) => {
        state.avatarChanged = value;
    },
    SET_PICKER_OPEN: (state, open) => {
        state.open = open;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
