const getMatrixAddress = () => {
  if(!process.env.VUE_APP_MATRIX_ADDRESS) {
    console.warn("VUE_APP_MATRIX_ADDRESS not defined. Have set up your .env?")
  }

  if(process.env.VUE_APP_MATRIX_ADDRESS === 'PRODUCTION_MATRIX_ADDRESS') {
    return `https://${window.location.host}`
  }
  return process.env.VUE_APP_MATRIX_ADDRESS;
}

export {
  getMatrixAddress,
}