var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('div',{ref:"box",class:{ 'soft-box-shadow': _vm.accountRole === 'teacher' },style:(`
            border: ${_vm.rect.startTextTask ? _vm.rect.startTextTask : 'none'};
            background-color: ${_vm.rect.text};
            width: 100%; height: 100%;
            max-width: ${_vm.canvasWidth}px; max-height: ${_vm.canvasHeight}px;
            pointer-events: none;
            z-index: 2;
        `)})])
}
var staticRenderFns = []

export { render, staticRenderFns }