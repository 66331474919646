import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import {searchVFSV2} from "../../api/backend";

const defaultState = {
    privateFolderSelection: 0,  // amount of elements in current selection
    teacherFolderSelection: 0,  // amount of elements in current selection
    groupFolderSelection: 0, // amount of elements in current selection
    docRegistryFolderSelection: 0, // amount of elements in current selection
    privateFolderSortDescending: false,
    teacherFolderSortDescending: false,
    groupFolderSortDescending: false,
    docRegistryFolderSortDescending: false,
    // how many elements can be shown per row.
    // at 100% it changes into a list view,
    // at or below 50% in shows preview images
    vfsGridWidthPct: 50,
    // this controls the APP library view
    vfsGridWidthPctLibrary: 50,
    // folderType groupFolder stores data of current VFS for editor handling
    vfs: null,
    // provide Information to LibreOfficeEditor
    libreIsTask: false,
    libreFileId: null,

    reloadVfs: false,
};

const actions = {
    async searchVFS ({commit, dispatch}, params) {
        try {
            const res = await backend.searchVFSV2(params);
            await checkResponseStatus(200, res);
            const queryResult = await res.json();
            return queryResult;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

const mutations = {
    setPrivateFolderSelection: (state, num) => {
      state.privateFolderSelection = num;
    },
    setTeacherFolderSelection: (state, num) => {
      state.teacherFolderSelection = num;
    },
    setGroupFolderSelection: (state, num) => {
      state.groupFolderSelection = num;
    },
    setDocRegistryFolderSelection: (state, num) => {
      state.docRegistryFolderSelection = num;
    },
    setPrivateFolderSortDescending: (state, bool) => {
      state.privateFolderSortDescending = bool;
    },
    setTeacherFolderSortDescending: (state, bool) => {
      state.teacherFolderSortDescending = bool;
    },
    setGroupFolderSortDescending: (state, bool) => {
      state.groupFolderSortDescending = bool;
    },
    setDocRegistryFolderSortDescending: (state, bool) => {
      state.docRegistryFolderSortDescending = bool;
    },
    mutateVfsGridWidthPct: (state) => {
      switch(state.vfsGridWidthPct) {
        case 33:
          state.vfsGridWidthPct = 50;
          break;
        case 50:
            // TODO figure out list styling in design stage
          state.vfsGridWidthPct = 100;
          // state.vfsGridWidthPct = 33;
          break;
        case 100:
          state.vfsGridWidthPct = 33;
          break;
        default:
          state.vfsGridWidthPct = 50;
          break;
      }
    },
    mutateVfsGridWidthPctLibrary: (state) => {
      switch(state.vfsGridWidthPctLibrary) {
        case 33:
          state.vfsGridWidthPctLibrary = 50;
          break;
        case 50:
          state.vfsGridWidthPctLibrary = 100;
          break;
        case 100:
          state.vfsGridWidthPctLibrary = 33;
          break;
        default:
          state.vfsGridWidthPctLibrary = 50;
          break;
      }
    },
    setVfs: (state, obj) => {
        state.vfs = obj;
        // Completion for reload stuff (sry)
        state.reloadVfs = false;
    },
    setOriginalFileIdForVFSDraft: (state, fileIdValue) => {
        state.currentFileIdForVfsDraft = fileIdValue;
    },
    setLibreFileId: (state, value) => {
      state.libreFileId = value;
    },
    setLibreIsTask: (state, value) => {
      state.libreIsTask = value;
    },
    // Mutation to trigger a reload of the current VFS Folder (Vfs data must be set in store via setVfs())
    triggerReloadOfVfs: (state ) => {
        if (state.vfs && !state.reloadVfs) {
            state.reloadVfs = true;
            setTimeout(() => {
                state.reloadVfs = false
            }, 500);
        }
    },
};

const getters = {
    privateFolderSelection: state => state.privateFolderSelection,
    teacherFolderSelection: state => state.teacherFolderSelection,
    groupFolderSelection: state => state.groupFolderSelection,
    docRegistryFolderSelection: state => state.docRegistryFolderSelection,
    privateFolderSortDescending: state => state.privateFolderSortDescending,
    teacherFolderSortDescending: state => state.teacherFolderSortDescending,
    groupFolderSortDescending: state => state.groupFolderSortDescending,
    docRegistryFolderSortDescending: state => state.docRegistryFolderSortDescending,
    vfsGridWidthPct: state => state.vfsGridWidthPct,
    vfsGridWidthPctLibrary: state => state.vfsGridWidthPctLibrary,
    vfs: state => state.vfs,
    currentFileIdForVfsDraft: state => state.currentFileIdForVfsDraft,
    libreFileId: state => state.libreFileId,
    libreIsTask: state => state.libreIsTask,
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
