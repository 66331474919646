<template>
    <v-card
        class="group-chat-wrapper"
        @click.native="showNav"
    >
        <div
            v-if="!searchMode"
            class="group-chat-header d-flex align-center justify-space-between"
        >
            <v-tooltip
                :disabled="!showTooltips"
                bottom
                style="z-index: 9;"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        id="backButton"
                        text
                        class="ma-2 group-chat-header-btn"
                        color="rgba(248, 248, 248, 0.4)"
                        small
                        v-on="on"
                        @click="clickBack"
                    >
                        <img
                            :src="pfeilIcon"
                            class="icon iconToWhite"
                            style="transform: rotate(90deg)"
                            alt="Zurück"
                        >
                    </v-btn>
                </template>
                <span>Zurück</span>
            </v-tooltip>
            <div
                v-if="chatRoom.type === 'system'"
                class="d-flex justify-space-around align-center mr-2"
                style="height: 35px; width: 35px; border-radius: 8px; background-color: #ff6941;"
            >
                <img
                    aria-hidden="true"
                    :src="infoIcon"
                    class="iconToWhite icon20"
                    alt="Gruppenchat"
                >
            </div>
            <Avatar
                v-else-if="currentPicture"
                class="mr-2"
                alt="Chat"
                :profile="privateRoomPicture"
                :size="30"
            />
            <div
                v-else-if="chatRoom.icon && chatRoom.color"
                class="d-flex justify-space-around align-center mr-2"
                style="height: 35px; width: 35px; border-radius: 8px"
                :style="`background-color: ${chatRoom.color}`"
            >
                <img
                    aria-hidden="true"
                    :src="roomIcon"
                    class="icon20"
                    alt="Gruppenchat"
                    :style="getHeight"
                >
            </div>
            <div
                v-else
                class="d-flex justify-space-around align-center mr-2"
                style="height: 35px; width: 35px; border-radius: 8px; background-color: #6994cc;"
            >
                <img
                    aria-hidden="true"
                    :src="groupIcon"
                    class="iconToWhite icon20"
                    alt="Gruppenchat"
                >
            </div>
            <h2
                id="chatName"
                tabindex="0"
                class="group-chat-header-text mb-0"
                style="cursor: pointer"
                @click="showChatRoomInfo(chatRoom)"
            >
                {{ chatRoom.displayName }}
            </h2>
            <v-spacer />
            <v-tooltip
                v-if="windowWidth < 901 || windowWidth >= 1150" 
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        height="20"
                        color="white"
                        class="pr-1"
                        v-on="on"
                        @click="triggerReload"
                    >
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                </template>
                <span>Chat neu laden</span>
            </v-tooltip>
            <v-tooltip
                v-if="chatRoom.type === 'privateChat' && !parentPupilChat && windowWidth >= 1150"
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        icon
                        class="mr-2"
                        v-on="on"
                        @click="deletePrivateChat()"
                    >
                        <img
                            :src="deleteIcon"
                            class="iconToWhite"
                            alt="Löschen"
                        >
                    </v-btn>
                </template>
                <span>Chat löschen</span>
            </v-tooltip>
            <!-- <ToggleChatNotificationsButton
                :big="true"
                style="height: 50px"
            /> -->
            <v-tooltip
                v-if="windowWidth < 901 || windowWidth >= 1150" 
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        class="pr-1"
                        height="20"
                        :style="chatMuted ? 'opacity: 1.0' : 'opacity: 0.4'"
                        v-on="on"
                        @click="muteOrUnmuteChat"
                    >
                        <img
                            class="icon20 iconToWhite"
                            :src="bellIcon"
                            alt="Stummschalten"
                        >
                    </v-btn>
                </template>
                <span>{{ !chatMuted ? 'Chat stummschalten' : 'Stummschaltung aufheben' }}</span>
            </v-tooltip>
        
            <ChatWidgetMenu
                :show-tooltips="showTooltips"
                :current-room="chatRoom"
                :chat-muted="chatMuted"
                :settings="chatRoomMembershipRole === 'moderator'"
                :can-user-leave-chat-room="chatRoomMembershipRole === 'moderator' && (accountRole === 'teacher' || accountRole === 'maintainer') "
                :parent-pupil-chat="parentPupilChat"
                :chat-room-membership-role="chatRoomMembershipRole"
                @startSearchMode="startSearch"
                @updateRoomList="clickBack"
                @deleteChatRoom="deleteChatRoom"
                @deletePrivateChat="deletePrivateChat"
                @leaveChatRoom="confirmDelete"
                @muteorunmute="muteOrUnmuteChat"
                @triggerReload="triggerReload"
            />
        </div>
        <div
            v-else
            class="group-chat-header d-flex align-center justify-space-between pl-4 "
        >
            <v-text-field 
                v-model="searchChatMessages"
                :append-icon="searchChatMessages.trim().length > 0 ? 'mdi-close' : ''"
                hide-details
                dark
                dense
                tabindex="0" 
                class="mb-1 chat-search-input"
                placeholder="Chat durchsuchen..."
                @click:append="()=>{ searchChatMessages = ''; jumpToIndex = 0;}"
                @change="()=>{ jumpToIndex = 0; }"
            >
                <template v-slot:prepend>
                    <img
                        :src="suchIcon"
                        class="icon20 iconToWhite"
                        alt="suchen"
                    >
                </template>
            </v-text-field>
            <v-btn
                text
                color="white"
                style="text-transform: inherit; font-weight: bold;"
                @click="resetSearchMode"
            >
                Abbrechen
            </v-btn>
        </div>
        <!-- show no area to send Messages, because Chatroom is locked -->
        <div
            v-if="chatRoom && !chatRoom.isOpen"
            class="chat-send-wrapper align-center justify-center"
            style="width: 100%; height: 72px"
        >
            <img
                :src="lockIcon"
                class="iconToRed mr-2"
                style="width: 20px; height: 20px; opacity: 0.4"
                alt="Chat geschlossen"
            >
            Chat gesperrt. Nachrichten können nur noch von Moderatoren gesendet werden.
        </div>
        <v-overlay
            v-if="selectMode"
            absolute
            :value="selectMode"
            class="overlayBlur"
            @click.stop="removeSelectedMessage"
        />
        <div 
            v-if="showJumpToStart && !focused"
            style="position: absolute; top: 65vh; right: 8px; z-index: 90"
        >
            <v-btn
                fab
                small
                dark
                style="background-color:var(--v-chatColor-base); "
                @click="jumpTo"
            >
                <img
                    :src="pfeilIcon"
                    class="icon20 iconToWhite"
                    :style="scrollDown ? '' :'transform: rotate(180deg)'"
                    alt="Pfeil nach oben"
                >
            </v-btn>
        </div>
        <div
            v-if="chatRoom"
            id="chatBody"
            class="chat-body"
            style="background-color: white !important; border-radius: 16px;"
        >
            <div
                v-if="searchMode && searchChatMessages !== ''"
                class="d-flex flex-row justify-space-between"
                style="background-color: #f2f2f2;"
            >
                <p
                    style=""
                    class="px-4"
                >
                    {{ searchInfoText }}
                </p>
                <div
                    class="d-flex mr-2"
                    style="cursor:pointer; align-items:center;"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                icon
                                small
                                v-on="on"
                                @click="jumpToPreviousMessage"
                            >
                                <img
                                    class="iconToDunkelgrau icon10"
                                    :src="downThinIcon"
                                    alt="vorherige Nachricht"
                                    style="transform: rotate(180deg);"
                                >
                            </v-btn>
                        </template>
                        <span>zur vorherigen Nachricht</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                icon
                                small
                                v-on="on"
                                @click="jumpToNextMessage"
                            >
                                <img
                                    class="iconToDunkelgrau icon10"
                                    :src="downThinIcon"
                                    alt="nächste Nachricht"
                                >
                            </v-btn>
                        </template>
                        <span>zur nächsten Nachricht</span>
                    </v-tooltip>
                </div>
            </div>
            <ScrollAreaMagnifier
                ref="scrollAreaMagnifier"
                class="scroll-area-magnifier "
                :settings="settings"
                :usage="'chatWidget'"
                style="border-radius: 0 0 16px 16px !important;"
                :message="messageSent"
                @scrollY="scrolled"
                @ps-y-reach-start="loadMore"
            >
                <div
                    v-for="event in mappedChatRoomEvents"
                    :key="event._id+event.updatedAt"
                >
                    <div
                        v-if="isFirstEventOnDay(event)"
                        class="d-flex flex-row justify-space-around mt-5"
                    >
                        <p
                            class="px-5" 
                            style="font-size: 12px; border-radius: 16px; background-color: #f2f2f2;"
                        >
                            {{ dayname[new Date(event.createdAt).getDay()] }}.
                            {{
                                new Date(event.createdAt).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit'
                                })
                            }}
                        </p>
                    </div>
                    <ChatRoomEventListItem
                        :ref="`chatevent${event._id}`"
                        :chat-room-membership-role="chatRoomMembershipRole"
                        :chat-room-event="event"
                        :chat-room="chatRoom"
                        :chat-search="searchChatMessages"
                        class="my-2"
                        style="
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    "
                        @fontColor="(color) => fontColor = color"
                        @answerMessage="setAnswerMessage"
                        @jumpToAnswered="jumpToMessage"
                        @selectedMessage="selectMessage"
                        @removeSelectedMessage="removeSelectedMessage"
                    />
                </div>
                <div
                    v-if="poll"
                    style="display: flex"
                    class="myMessage chat-message"
                >
                    <div
                        class="d-flex"
                        style="width: 100%"
                    >
                        <v-row
                            dense
                            :style="`border-radius: 5px 5px 0px 5px !important; box-shadow: 1px 2px 3px silver; background-color: ${account.profileColor ? account.profileColor : 'var(--v-headerblue-base)'}; color: black; padding: 9px;`"
                        >
                            <Poll
                                ref="chatpoll"
                                :chat-mode="true"
                                :in-chat="true"
                                :font-color="fontColor"
                                @closePollCard="poll = false"
                            />
                        </v-row>
                    </div>
                </div>
            </ScrollAreaMagnifier>
        </div>
        <!-- text area for composing message -->
        <div
            v-if="answerMessage"
            class="d-flex flex-row chat-send-wrapper align-center"
            style="border-radius: 0 0 0 0 !important"
        >
            <v-icon
                class="mr-5"
                style="color:grey"
            >
                mdi-arrow-left
            </v-icon>
            <div class="d-flex flex-column justify-space-between">
                <p style="font-size:14px; font-weight:bold;">
                    {{ getAuthorName(answerMessage.author) }}
                </p>
                <p class="answerMessageText">
                    {{ answerMessage.text }}
                </p>
            </div>
            <v-spacer />
            <img
                :src="cancelIcon"
                class="iconToDunkelgrau icon20 justify-end mr-2"
                @click="answerMessage = null"
            >
        </div>
        <div
            v-if="chatRoom && (chatRoom.isOpen || (!chatRoom.isOpen && chatRoomMembershipRole === 'moderator'))&& chatRoom.type !== 'system'"
            class="chat-send-wrapper align-center d-flex flex-row"
            style="width: 100%"
        >
            <div
                class="chat-send-input-wrapper align-center"
            >
                <!-- <v-btn
                    v-if="!focused && !(windowWidth > 899 && !(usage === 'BabyChat'))"
                    class="pa-0"
                    icon
                    bottom
                    left
                    style="z-index: 20 !important; height: 20px !important;"
                    fab
                    @click="() => { focusButtonClicked() }"
                >
                    <img
                        tabindex="0"
                        style="height: 18px; width: 18px; padding-top: 0px"
                        :src="pencilIcon"
                    >
                </v-btn> -->
                <EmojiPicker
                    v-if="windowWidth > 899 && !(usage === 'BabyChat')"
                    style="z-index: 20 !important; margin-left: -5px !important"
                    @selectedEmoji="pasteEmoji"
                />
                <v-textarea
                    id="chatTextArea"
                    ref="chatTextArea"
                    v-model="inputMessage"
                    hide-details
                    flat
                    solo
                    auto-grow
                    no-resize
                    label="Nachricht eingeben"
                    placeholder="Nachricht eintippen"
                    rows="1"
                    style="z-index: 20 !important;max-height: 18.25rem; overflow-x: hidden; overflow-y: auto; border-radius: 12px; background-color: #f2f2f2;"
                    class="chat-send-input"
                    @blur="blurInput"
                    @focusin="scrollWidgetContainer"
                    @keypress="textAreaChatChanged"
                    @keydown.enter.shift="sendMessage(false)"
                />
                <v-menu
                    top
                    left
                    z-index="20"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            icon
                            style="margin-right: -12px !important"
                            v-on="on"
                        >
                            <img
                                :src="plusIcon"
                                class="icon20 iconToDunkelgrau"
                                alt="Aktionen"
                            >
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="windowWidth < 900 || usage === 'BabyChat'"
                            class="pointer d-flex justify-center"
                        >
                            <EmojiPicker
                                :usage="usage==='BabyChat'? 'BabyList':'list'"
                                @selectedEmoji="pasteEmoji"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="accountRole !== 'pupil'"
                            dense
                            @click="poll = true"
                        >
                            <v-btn
                                icon
                                class="pointer d-flex justify-center"
                            >
                                <v-icon
                                    style="transform: rotate(90deg);"
                                    class="bigIcon"
                                    alt="Umfrage erstellen"
                                >
                                    mdi-poll
                                </v-icon>
                            </v-btn>
                            <v-list-item-title style="font-size:14px;">
                                Umfrage
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            dense
                            ripple
                        >
                            <ChatRoomFileUploadButton
                                :chat-room="chatRoom"
                                @showLoading="showLoadingOverlay = true"
                                @fileUploadFinished="showLoadingOverlay = false"
                            />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <ChatRoomMicrophoneAudioUploadButton
                v-if="!inputMessage"
                oncontextmenu="return false;"
                style="z-index: 20 !important"
                :chat-room-id="chatRoom._id"
            />
            <v-tooltip
                v-else
                :disabled="!showTooltips"
                top
                style="z-index: 20;"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        text
                        class="ma-0"
                        max-width="38"
                        v-on="on"
                        @click="sendMessage(true)"
                    >
                        <img
                            :src="sendenIcon"
                            alt="Nachricht senden"
                            height="30"
                        >
                    </v-btn>
                </template>
                <span>Senden</span>
            </v-tooltip>
        </div>
        <ChatRoomInfoDialog
            v-if="showChatRoomInfoDialog"
            :chat-room="chatRoom"
            @closeDialog="showChatRoomInfoDialog = false"
        />

        <AreYouSurePopup
            v-if="deleteDialog"
            :show-dialog="deleteDialog"
            :content-text-prop="contentTextProp"
            :usage="'chat'"
            ok-btn-color="frot"
            :header-color="'#3ba934'"
            @close="deleteDialog = false"
            @ok="confirmDelete"
        />

        <!-- Loading overlay -->
        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { bus } from "@/main";

import * as backend from "@/api/backend";
import pfeilIcon from "../../assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import sendenIcon from "../../assets/Icons/FaceliftIcons/absenden_versenden_flugzeug.svg";
import lockIcon from "../../assets/Icons/FaceliftIcons/schloss_zu.svg";
import plusIcon from "@/assets/Icons/plus.svg";
import groupIcon from "../../assets/Icons/group.svg";
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import bellIcon from'@/assets/Icons/FaceliftIcons/benachrichtigung_aus.svg';
import pencilIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import suchIcon from '@/assets/Icons/suche-26.svg';
import downThinIcon from "@/assets/Icons/Icon_Pfeil_unten.svg";
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";


import Poll from "@/components/Poll";
import ScrollAreaMagnifier from "@/components/ScrollAreaMagnifier";
import ChatRoomEventListItem from "./Components/ChatRoomEventListItem/ChatRoomEventListItem.vue";
import ChatRoomFileUploadButton from "./Components/ChatRoomFileUploadButton.vue";
import ChatRoomMicrophoneAudioUploadButton from "./Components/ChatRoomMicrophoneAudioUploadButton.vue";
import ChatWidgetMenu from "@/components/ChatV2/Components/ChatWidgetMenu";
import ChatRoomInfoDialog from "@/components/ChatV2/Components/ChatRoomInfoDialog";
import ToggleChatNotificationsButton
    from "@/components/ChatV2/Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup"
import Avatar from "@/components/SharedViews/Profile/Avatar";
import EmojiPicker from '@/components/Utils/EmojiPicker.vue';

import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";

export default {
    name: "ChatWidgetGroupChatV2",
    components: {
        Avatar,
        ChatWidgetMenu,
        ScrollAreaMagnifier,
        ChatRoomEventListItem,
        ChatRoomFileUploadButton,
        ChatRoomMicrophoneAudioUploadButton,
        ChatRoomInfoDialog,
        Poll,
        // ToggleChatNotificationsButton,
        AreYouSurePopup,
        EmojiPicker,
    },
    props: {
        showTooltips: { required: false, default: true },
        chatRoomId: { required: true, type: String },
        currentChild: { required: false, type: Object },
        currentPicture: {required: false, type: Object },
        usage:{required: false, type: String, default: ''},
    },
    data: () => ({
        showLoadingOverlay: false,
        dayname: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],

        //when sending message increment this so scrollareaMagnifier autoscrolls
        messageSent: 0,

        inputMessage: "",
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: true,
        },
        offsetHeightChat: 32,

        pfeilIcon,
        sendenIcon,
        lockIcon,
        plusIcon,
        groupIcon,
        deleteIcon,
        infoIcon,
        bellIcon,
        pencilIcon,
        suchIcon,
        downThinIcon,
        cancelIcon,

        searchMode:false,

        amountOfLoadedChatMessages: 9,
        poll: false,
        // waitForMessageWatcherEnabled: false,
        isTouchDevice: false,
        showChatWidgetMenu: false,
        showChatRoomInfoDialog: false,
        fontColor: 'black',
        deleteDialog: false,
        contentTextProp: '',
        roomIcon: null,

        icons: [
            {icon: groupIcon, text: "Gruppen-Icon"},
            {icon: avatar1, text: "avatar1"},
            {icon: avatar2, text: "avatar2"},
            {icon: avatar3, text: "avatar3"},
            {icon: avatar4, text: "avatar4"},
            {icon: avatar5, text: "avatar5"},
            {icon: avatar6, text: "avatar6"},
            {icon: avatar7, text: "avatar7"},
            {icon: avatar8, text: "avatar8"},
            {icon: avatar9, text: "avatar9"},
            {icon: avatar10, text: "avatar10"},
            {icon: avatar11, text: "avatar11"},
            {icon: avatar12, text: "avatar12"},
            {icon: avatar13, text: "avatar13"},
            {icon: avatar14, text: "avatar14"},
        ],
        censorChatActivated: false,
        focused: false,

        searchChatMessages: '',
        jumpToIndex: 0,
        showJumpToStart: false,
        timesScrolled: null,
        previousScrolled: 0,
        scrollDown: false,
        mountedHeight: null,

        initialInput: '',

        answerMessage:null,
        selectedMessage: null,
        selectMode: false,
    }),
    computed: {
        ...mapState("util", ["keyboard", "windowWidth","windowHeight"]),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState("translation", ["targetLang"]),
        ...mapState("utilUnpersistent", ["hideNavbar"]),
        ...mapState("auth", ["token", "account"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("chat", ["chatRooms", "chatRoomEvents"]),
        ...mapGetters("chat", ["chatRoomEventsByChatRoom", "chatRooms"]),
        ...mapGetters("accounts", ["accountsById"]),
        
        searchInfoText(){
            return this.searchMappedChatRoomEvents.length <  1 ? 'Keine Übereinstimmungen gefunden'
                            :`${this.jumpToIndex +1} von ${this.searchMappedChatRoomEvents.length}`
        },
        chatRoom() {
            // Don't deliver currentChatRoom over props because it might be outdated.
            // e.g. it contains the lastReadTimestamps which should be always up-to-date.

            // Use chatRoom object via store where it is updated regularly via websockets
            return this.chatRooms.find(
                (chatRoom) => chatRoom._id === this.chatRoomId
            );
        },
        chatMuted(){
            if (this.chatRoom.muted && this.chatRoom.muted.includes(this.account._id)){
                return true
            }
            return false;
        },
        privateRoomPicture() {
            const allMemberAccounts = [];
            this.chatRoom.memberships.forEach(member => {
                allMemberAccounts.push(member.addressedAccounts[0])
            });
            const allOtherAccounts = allMemberAccounts.filter(acc => acc !== this.accountId);
            return allOtherAccounts[0];
        },
        mappedChatRoomEvents() {
            return this.chatRoomEventsByChatRoom[this.chatRoom._id] ? this.chatRoomEventsByChatRoom[this.chatRoom._id].slice(-this.amountOfLoadedChatMessages) : [];
        },
        searchMappedChatRoomEvents(){
            return this.searchChatMessages === '' ? this.mappedChatRoomEvents 
                        : this.searchChatMessages !== '' && this.chatRoomEventsByChatRoom[this.chatRoom._id] ? 
                            this.chatRoomEventsByChatRoom[this.chatRoomId].filter((chatEvent)=> chatEvent.text && chatEvent.text.toLowerCase().includes(this.searchChatMessages.toLowerCase()))
                            : this.mappedChatRoomEvents;
        },
        chatRoomMembershipRole() {
            const memberships = this.chatRoom.memberships.filter((membership) =>
                membership.addressedAccounts.find(
                    (addressedAccount) => addressedAccount === this.account._id
                )
            );

            const isModerator = memberships.find(
                (membership) => membership.role === "moderator"
            );

            return isModerator ? "moderator" : "member";
        },
        parentPupilChat() {
            if (this.chatRoom.type === 'privateChat') {
                const otherMemberId = this.chatRoom.memberships
                    .filter((membership) => membership.addressedAccounts[0] !== this.account._id)
                    .map((membership) => membership.addressedAccounts[0]);

                const otherMemberRole = this.accountsById[otherMemberId[0]].role;
                if (this.account.role === 'pupil' && otherMemberRole === 'parent') {
                    return true;
                }
                if (this.account.role === 'parent' && otherMemberRole === 'pupil') {
                    return true;
                }
            }
            return false;
        },
        getHeight() {
            if (this.roomIcon) {
                if (!this.roomIcon.toLowerCase().includes('avatar') && !this.roomIcon.toLowerCase().includes('group')) {
                    return 'height: 35px; width: 35px; border-radius: 8px;'
                }
            }
            return '';
        },
        firstEventOnDayMap() {
            const firstObjectsMap = {};
            if(this.mappedChatRoomEvents.length){
                this.mappedChatRoomEvents.forEach(obj => {
                                const date = new Date(obj.createdAt).toISOString().split('T')[0];
                                if (!firstObjectsMap[date] || obj.createdAt < firstObjectsMap[date].createdAt) {
                                firstObjectsMap[date] = obj;
                                }
                            });

            return firstObjectsMap;

            }else{
                return []
            }
        }
    },
    watch: {
        currentChild: async function () {
            if (this.accountRole === "parent") {
                // TODO
            }
        },
        mappedChatRoomEvents: {
            immediate: true,
            // Update the last seen timestamp if there are new unread messages
            async handler(events) {
                const accountTimestampObject =  this.chatRoom.lastSeenTimestamps.find(({ timestamp, account }) => {
                    if (account === this.account._id) {
                        return true;
                    }
                });
                const lastSeenTimestamp = accountTimestampObject?.timestamp || 0;

                const chatRoomEvents =
                    this.chatRoomEventsByChatRoom[this.chatRoom._id] || [];
                const newChatRoomEvents = chatRoomEvents.filter(
                    (chatRoomEvent) =>
                        new Date(chatRoomEvent.createdAt) >= new Date(lastSeenTimestamp)
                );

                if (newChatRoomEvents.length > 0) {
                    await this.updateChatRoomLastSeenTimestamp(this.chatRoom._id);
                }
            },
        },
        inputMessage(newVal){
            const lines = newVal.split('\n');
            if(lines.length > 14 && lines.length < 16){
                this.showSnackbar({message:'Tipp: Bei Problemen mit dem Scrollen bei zu langen Nachrichten, teilen sie die Nachricht einfach in kleinere Teilnachrichten.', color: 'warning', timeout: 10000})
            }
        },
        poll(newVal){
            if(newVal && newVal === true){
                setTimeout(() =>{
                const element = this.$refs[`chatpoll`];
                    element.$el.scrollIntoView({
                        behaviour: 'smooth',
                        block: 'end',
                    });
                }, 200)
            }
        }
    },
    async created() {
        while (!this.$refs.scroll) {
            await this.sleep(500);
        }
        this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
    },
    async mounted() {
        bus.$off("sendPollMessage");
        // listens to emitted event via bus (bus is created in main.js)
        bus.$on("sendPollMessage", (pollId) => {
            this.sendPollMessage(pollId);
        });
        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
        this.isTouchDevice =
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0;
        // if (this.chatRoomMembershipRole === 'moderator') {
        //     this.showChatWidgetMenu = true;
        // }
        // if (this.accountRole === 'maintainer' && this.chatRoom.type !== 'privateChat'){
        //     this.showChatWidgetMenu = true;
        // }
        await this.getGroupIcon();
        await backend.updateLastChatAccess(this.accountId);
        const maintainers = await this.getMaintainers();
        this.censorChatActivated = maintainers[0].censorChatActivated;
        // let textarea = document.getElementById('chatTextArea');
        // textarea.style.margin = '10px 0 0 0';
        this.mountedHeight = this.windowHeight;
        window.visualViewport.addEventListener('resize',this.handleViewportHeightChange ); 
        if(this.getDraftMessage(this.chatRoom) !== ''){
            const input = this.getDraftMessage(this.chatRoom);
            this.inputMessage = input;
             this.initialInput = input;
        }

    },
    async beforeDestroy() {
        window.visualViewport.removeEventListener('resize',this.handleViewportHeightChange);
        await this.clearBadgesFromChat(this.chatRoom._id);

         if(this.inputMessage.trim() !== this.initialInput.trim()){
                const date = new Date();
                const updateRes = await backend.addDraftMessage(this.chatRoom._id,{message: this.inputMessage.trim()});
                //needs to be under observation, possible performance issue, maybe better to update with store
                // await this.fetchChats(date.toISOString);
            }
    },
    methods: {
        ...mapActions("translation", [
            "setTranslatedText",
            "showTranslation",
            "translateToTargetLang",
        ]),
        ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
        ...mapActions("pupils", ["getPupils", "getMePupil"]),
        ...mapActions("teachers", ["getTeachers", "getProfilePicture"]),
        ...mapActions("groups", ["getGroupsInfo"]),
        ...mapActions("chat", ["updateChatRoomLastSeenTimestamp", "postChatRoomEvent","fetchChats","clearBadgesFromChat"]),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapMutations('utilUnpersistent',['setHideNavbar']),
         ...mapActions("maintainers", ["getMaintainers"]),
        selectMessage(id){
            this.selectedMessage = id;
              this.selectMode = true;
        },
        removeSelectedMessage(){
            const element = this.$refs[`chatevent${this.selectedMessage}`][0];
            if(element){
                element.$el.classList.remove('zindexhighlight');

            }
            this.selectMode = false;
        },
        getAuthorName(authorId){
            const author = this.accountsById[authorId];
            return author.displayName;
        },
        setAnswerMessage(id){
            const index = this.mappedChatRoomEvents.findIndex(event=> event._id === id);
            const message = this.mappedChatRoomEvents[index];
            if(message.text !== ''){
                this.answerMessage = message;
            }
        },
        getDraftMessage(room){
            if(room.draftMessage){
                const draft = room.draftMessage.filter(message=> message.account === this.accountId)
                if(draft.length > 0){
                    return draft[0].message;

                }else{
                    return '';
                }
            }
            return '';
        },
        handleViewportHeightChange(){
            const changeHeight = window.visualViewport.height;
            //if viewport returns to originalheight, show navbar
            if (this.mountedHeight === changeHeight && this.hideNavbar){
                this.setHideNavbar(false);
            }
        },
         isFirstEventOnDay(event){
            const targetDate = new Date(event.createdAt).toISOString().split('T')[0];
            return this.firstEventOnDayMap[targetDate] === event;
         },
        resetSearchMode(){
            this.searchMode = false; 
            this.searchChatMessages = '';
            this.jumpToIndex = 0;
            this.jumpToStart();
        },
        jumpToMessage(messageId){
            if(this.amountOfLoadedChatMessages !== this.chatRoomEventsByChatRoom[this.chatRoomId].length)
            {
                this.amountOfLoadedChatMessages = this.chatRoomEventsByChatRoom[this.chatRoomId].length;
            }
            this.$nextTick(() => {
            const index = this.mappedChatRoomEvents.findIndex(chatEvent=> chatEvent._id === messageId);
            const element = this.$refs[`chatevent${this.mappedChatRoomEvents[index]._id}`][0];
            element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            }); 
           
        },
        jumpTo(){
            this.amountOfLoadedChatMessages = this.chatRoomEventsByChatRoom[this.chatRoomId].length;
            const index = this.scrollDown ?  this.mappedChatRoomEvents.length -1 : 0;
            const element = this.$refs[`chatevent${this.mappedChatRoomEvents[index]._id}`][0];
             element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            setTimeout(() => {
            this.showJumpToStart = false;
                    }, 500);
        },
         jumpToStart(){
            this.amountOfLoadedChatMessages = this.chatRoomEventsByChatRoom[this.chatRoomId].length;
            const element = this.$refs[`chatevent${this.mappedChatRoomEvents[this.mappedChatRoomEvents.length -1]._id}`][0];
             element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            setTimeout(() => {
            this.showJumpToStart = false;
                    }, 500);
        },
        scrolled(scrollAmount) {
            if(scrollAmount > 2){

            if(this.timesScrolled > 1){
                this.showJumpToStart = scrollAmount > 0;            
            }
            this.timesScrolled += 1;
            if(this.previousScrolled  <= scrollAmount ){
                this.scrollDown = true;
            }
            else{
                this.scrollDown = false;
            }
            this.previousScrolled = scrollAmount;
            }
        },
        jumpToPreviousMessage(){
            this.jumpToIndex = this.jumpToIndex > 0 ? this.jumpToIndex - 1 : this.searchMappedChatRoomEvents.length -1 ;

            const element = this.$refs[`chatevent${this.searchMappedChatRoomEvents[this.jumpToIndex]._id}`][0];
            element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            this.highlightMessage(element.$el)
           
        },
        jumpToNextMessage(){
            this.jumpToIndex = this.jumpToIndex + 1 <= this.searchMappedChatRoomEvents.length -1 ? this.jumpToIndex + 1 : 0;

            const element = this.$refs[`chatevent${this.searchMappedChatRoomEvents[this.jumpToIndex]._id}`][0];
            element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });

            this.highlightMessage(element.$el)


        },
        highlightMessage(element){

             this.$nextTick(() => {
                if (element) {
                    element.classList.remove('jumpVisualize');
                    element.classList.add('jumpVisualize');

                    setTimeout(() => {
                        element.classList.remove('jumpVisualize');
                    }, 1050);
                }
            });

        },
        startSearch(){
            this.searchMode = true;
            this.amountOfLoadedChatMessages = this.chatRoomEventsByChatRoom[this.chatRoomId] ? this.chatRoomEventsByChatRoom[this.chatRoomId].length : this.amountOfLoadedChatMessages;
            this.jumpToStart();
        
        },
        showNav(){
            if(this.hideNavbar && !this.focused){
                this.setHideNavbar(false);
            }
        },
        async muteOrUnmuteChat(){
            if(this.chatMuted){
                const res = await backend.unmuteChatRoom(this.chatRoomId);
                this.showSnackbar({message: 'Stummschaltung aufgehoben'});
            }else{
                const res = await backend.muteChatRoom(this.chatRoomId);
                this.showSnackbar({message: 'Chat stummgeschalten'});
            }
            await this.fetchChats();
        },
         async triggerReload(){
            await this.fetchChats();
         },
        deletePrivateChat() {
            if (this.account.role === 'teacher' || this.account.role === 'maintainer') {
                this.contentTextProp = 'Soll dieser Chat endgültig gelöscht werden?';
            } else {
                this.contentTextProp = 'Es wird eine Löschanfrage an ' + this.chatRoom.displayName + ' geschickt. '
                    + (this.accountRole === 'pupil' ? 'Willst du' : 'Wollen Sie') + ' diese Aktion wirklich ausführen?'
            }
            this.deleteDialog = true;
        },

        async sendPollMessage(poll) {
            await this.postChatRoomEvent({
                chatRoom: this.chatRoom._id,
                type: "pollMessage",
                poll: poll._id,
            });
        },
        deleteChatRoom() {
            this.$emit("deleteChatRoom", this.chatRoomId);
        },
        confirmDelete() {
            this.deleteDialog = false;
            if (this.accountRole === 'teacher' || this.accountRole === 'maintainer') {
                this.$emit("leaveChatRoom", this.chatRoomId);
            } else {
                this.postChatRoomEvent({
                    type: "textMessage",
                    text: 'Ich bitte um die Löschung dieses Chats.',
                    chatRoom: this.chatRoom._id
                });
                this.showSnackbar({ message: "Löschanfrage wurde versendet." });
            }
        },
        textAreaChatChanged() {
            let el = document.getElementById("chatTextArea");
            this.offsetHeightChat = el.offsetHeight > 100 ? 100 : el.offsetHeight;
        },
        scrollWidgetContainer() {
            this.focused = true;
            if (this.windowWidth < 900) {
                this.setHideNavbar(true);
                // use scrollintoview
                this.$refs.chatTextArea.$el.scrollIntoView(true);
            }
        },
        blurInput(){
             if (this.windowWidth < 900) {
                if(this.inputMessage === ''){
                    this.setHideNavbar(false);
                }
             }
            this.focused = false;
        },
        async loadMore() {
            this.amountOfLoadedChatMessages += 1;
        },

        sleep(milliseconds) {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
        },
        async sendMessage(button) {
            if(button || this.windowWidth > 899){
                this.setHideNavbar(false);
                if (this.inputMessage.trim()) {
                    const res = await this.postChatRoomEvent({
                        type: "textMessage",
                        text: this.inputMessage,
                        chatRoom: this.chatRoom._id,
                        censorChat: this.censorChatActivated,
                        ...(this.answerMessage && this.answerMessage._id && { answerTo: this.answerMessage._id }),
                    });
                    this.inputMessage = "";
                    this.answerMessage = null;
                    if(res.status === 201){
                        this.showSnackbar({message: 'Nachricht erfolgreich versendet!', color: 'success'});
                        this.messageSent += 1; 
                     }
                }
            }
        },
        async clickBack() {
            if(this.hideNavbar){
                this.setHideNavbar(false);
            }
            this.amountOfLoadedChatMessages = 9;
             
            this.$emit("exitRoom");
            await backend.updateLastChatAccess(this.accountId);
        },

        async showChatRoomInfo(room) {
            this.showChatRoomInfoDialog = true;
        },
        async pasteEmoji(emoji){
            this.inputMessage = this.inputMessage + emoji;
        },
        async getGroupIcon() {
            if (this.chatRoom.icon && this.chatRoom.color) {
                if (!this.chatRoom.icon.includes('avatar') && !this.chatRoom.icon.includes('group')) {
                    this.roomIcon = await this.getUploadedIcon(this.chatRoom.icon);
                } else {
                    const icon = this.icons.find((icon) => {
                        return icon.icon.toLowerCase().includes(this.chatRoom.icon)
                    })
                    if (icon) {
                        this.roomIcon = icon.icon;
                    }
                    return null
                }
            }
        },
    },
};
</script>

<style>
.chat-send-input .v-input__append-inner {
    margin-top: 8px !important;
}

.chat-send-input .v-text-field__slot textarea {
    padding-right: 0.5rem !important;
}

.chat-send-input.v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-label{
    top: 16px !important;
}
.chat-send-input.v-textarea textarea{
    line-height: 1.25rem !important;
}
.chat-send-input.v-textarea.v-text-field.v-text-field--solo .v-input__control{
    min-height:1.0rem !important; 
}
.chat-send-input.v-textarea.v-text-field.v-text-field--solo .v-input__control .v-input__slot .v-text-field__slot{
    background-color:white !important;
    padding-left:5px;
    padding-right:5px;
}
.chat-send-input.v-textarea .v-label {
  position: unset !important;
  min-width: 80%;
}

.chat-search-input.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
    background-color: transparent;
    color: white;
}

</style>

<style lang="scss" scoped>
.chat-search-input {
    border-radius: 20px;
    background-color: transparent;
    align-self: center;
}
.chat-search-input ::v-deep .v-input__prepend-outer {
    align-self: center;
}
.chat-send-input ::v-deep .v-input__prepend-outer {
    align-self: center;
    margin-left: 0.5rem;
    margin-right: 0.1rem;
}
.chat-send-input {
    background-color: white;
    width: 100%;
}

.chat-send-wrapper {
    background-color: #f2f2f2;
    padding: 4px;
    border-radius: 0 0 16px 16px !important;
}

.chat-send-input-wrapper {
    display: inline-flex;
    background-color: white;
    padding: 0 6px;
    border-radius: 20px !important;
    width: 100%
}

.group-chat-wrapper {
    flex: auto;
    display: flex;
    flex-direction: column;
    // overflow: auto;
    background-color: white !important;
    border-radius: 16px;
}

.scroll-area-magnifier {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
}

.chat-body {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
    border-radius: 8px;
    width: 36px !important;
    height: 36px !important;
    flex: none;
}

.group-chat-header-text {
    color: white;
}

.group-chat-header {
    background-color: var(--v-chatColor-base);
    height: 56px;
    flex: none;
    border-radius: 16px 16px 0 0 !important;
}

.chat-header-btn {
    background-color: white !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.chat-message {
    position: relative;
    border-radius: 5px;
    max-width: 75%;
    margin: 2px 2px 2px 19px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}
.chat-message:before {
    opacity: 0 !important;
}

.chat-message-content {
    border-radius: 0 5px 5px 5px;
    box-shadow: 1px 2px 3px silver;
    background-color: var(--v-blau-base);
    /*background-color: #9b91c8;*/ //use me when using chat gender colors again
    color: white;
    padding: 9px;
}
.male-chat {
    background-color: var(--v-blau-base) !important;
}
.female-chat {
    background-color: var(--v-pink-base) !important;
}

.myMessage-content {
    border-radius: 5px 5px 0 5px !important;
    background-color: var(--v-grau-base);
    color: black;
}

.progressbar {
    border-radius: 5px;
}

.icon {
    height: 20px;
}

.bigIcon {
    height: 30px;
}

.pointer {
    cursor: pointer;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

#mandatoryFloatBtn {
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translateY(-50%);
    bottom: 2%;
}

.formHeader {
    font-size: 16px;
    margin-bottom: 20px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
    width: 7px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 6px;
    width: 11px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 19px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}

.chat-message {
    position: relative;
    border-radius: 5px;
    margin: 2px 2px 2px 19px;
}
.jumpVisualize {
    animation: pinnedRipple 1000ms ease-in-out;
}

@keyframes pinnedRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.408);
    }
    10% {
        background-color: rgba(105, 149, 205, 0.567);
    }
    75% {
        background-color: rgba(105, 149, 205, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}
.answerMessageText{
    max-width: 20vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
@media only screen and (max-width: 900px){
    .answerMessageText{
        max-width: 60vw;
    }
}
.overlayBlur{
     background-color: rgba(145, 150, 158, 0.6);
    backdrop-filter: blur(8px);
    z-index: 25 !important;
}
</style>
