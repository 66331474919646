import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const actions = {
  //#region JoinKlassenraumButton actions
  async teacherClassroom({commit, dispatch}) {
      try {
          const res = await backend.teacherClassroom();
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },

  async changeRoomStatus({commit, dispatch}, params) {
      try {
          const id = params.classroomId;
          const res = await backend.changeRoomStatus(id, params);
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },

  async joinClassroom({commit, dispatch}, id) {
      try {
          const res = await backend.joinClassroom(id);
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },
    //#endregion


  //#region JoinSprechzimmerButton actions
  async getMyConferenceTeacher({commit, dispatch}) {
      try {
          const res = await backend.getMyConferenceTeacher();
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },

  async getMyConferenceParent({commit, dispatch}, params) {
    try {
      const res = await backend.getMyConferenceParent(params);
      await checkResponseStatus(200, res);
      return await res.json();
    } catch (err) {
      console.error(err);
            return err?.response?.status;
    }
  },

  async changeConferenceRoomStatus({commit, dispatch}, params) {
      try {
          const id = params.classroomId;
          const res = await backend.changeConferenceRoomStatus(id, params);
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },

  async joinConferenceRoom({commit, dispatch}, params) {
      try {
          const res = await backend.joinConferenceRoom(params.roomId, params.childrenId);
          await checkResponseStatus(200, res);
          return await res.json();
      } catch (err) {
          console.error(err);
            return err?.response?.status;
      }
  },
    //#endregion
}

export default {
  namespaced: true,
  actions,
};
