<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn
                class="fileListButton"
                :style="`background-color: ${primaryColor ? primaryColor : 'var(--v-chatColor-base)'}; width: ${buttonSize}px; height: ${buttonSize}px`"
                x-small
                elevation="0"
                v-on="on"
                @click="doneFile"
            >
                <img
                    :src="fertigIcon"
                    alt="Unbearbeitet abgeben"
                    style="filter: brightness(1000%)"
                    class="fileListButtonIcon"
                >
            </v-btn>
        </template>
        <span>Unbearbeitet abgeben</span>
    </v-tooltip>
</template>

<script>
import fertigIcon from "@/assets/Icons/fertig-74.svg";
import {mapMutations} from "vuex";

export default {
    name: "FileListEditButton",
    props: {
        file: { type: Object, required: true },
        primaryColor: { type: String, required: false },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        fertigIcon,
    }),
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),

        doneFile(event) {
            if (this.file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return;
            }
            event.stopPropagation();
            this.$emit('doneFile');
        }
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
}
</style>
