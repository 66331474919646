import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';
import Vue from "vue";


const defaultState = {
    locations: [],
};

const mutations = {
    setLocations: (state, list) => {
        state.locations = list;
    },
    addOrUpdateLocation: (state, location) => {
        const index = state.locations.findIndex(el => {
            return  el._id === location._id;
        });

        if (index >= 0){
            Vue.set(state.locations, index, location);

        }else{
            state.locations.push(location);
        }
    },
};

const getters = {
    locations: state => state.locations,
    
}

const actions = {
    async createLocation({ commit, dispatch }, params) {
        try {
            const res = await backend.postLocation(params);
            await checkResponseStatus(201, res);
            const location = await res.json();
            commit('addOrUpdateLocation', location);
            
            return location;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editLocation({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchLocation(id, params);
            if (res.status === 409) {
                return 409;
            }else{
                const location = await res.json();
                commit('addOrUpdateLocation', location);
                return location;
            }
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getLocations({ commit, dispatch, getters }, update = false) {
        try {
            if (getters.locations && getters.locations.length > 0 && !update) {
                return getters.locations;
            }

            const res = await backend.getLocations();
            await checkResponseStatus(200, res);
            const locations = await res.json();
            commit('setLocations', locations);
            return locations;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    
};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};
