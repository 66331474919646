import * as backend from '../../api/backend';
import { checkResponseStatus } from '@/util/check';
import Vue from "vue";

const state = {
    accountSets: [],
};


const getters = {
    accountSets: (state, getters, rootState, rootGetters) => state.accountSets,
    
    accountSetsById: (state, getters, rootState, rootGetters) => {
        return state.accountSets.reduce((acc, accountSet) => {
            acc[accountSet._id] = accountSet;
            return acc;
        }, {});
    },
};

const mutations = {
    setAccountSets: (state, accountSets) => {
        state.accountSets = accountSets;
    },
};

const actions = {
    async fetchAccountSets({ commit, dispatch }, params) {
        try {
            const res = await backend.getAccountSets(params);
            await checkResponseStatus(200, res);
            const accountSets = await res.json();
            commit('setAccountSets', accountSets);
            return res.status;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
