import {PageSizes, PDFDocument, rgb} from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";

const splanWeekExport = async (group, appointmentTable) => {
    console.log('splanWeekExport');

    // create pdf basics
    const pdfDoc = await PDFDocument.create();
    pdfDoc.registerFontkit(fontkit);
    const robotoBytes = await fetch(roboto).then((res) =>
        res.arrayBuffer()
    );
    const robotoBoldBytes = await fetch(robotoBold).then((res) =>
        res.arrayBuffer()
    );
    const robotoFont = await pdfDoc.embedFont(robotoBytes);
    const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);
    const fonts = {robotoFont, robotoBoldFont};

    // add horizontal page to start
    const page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]);
    const allPages = pdfDoc.getPages();

    // header
    const fontSizeHeader = 18;
    const fontSize = 14;
    const lineHeight = fontSize + 2;
    const fontHeightHeader = fonts.robotoFont.heightAtSize(fontSizeHeader);
    const fontHeight = fonts.robotoFont.heightAtSize(fontSize);
    const padding = 3;
    const defaultHeaderY = page.getHeight() - 10 - 2*fontHeightHeader - 2*padding;

    // add header with groupname and dates
    // TODO sort dates
    const dates = Object.keys(appointmentTable);
    const dateStrings = dates.map(date => new Date(date).toLocaleDateString('de-DE', {weekday: 'short', year: '2-digit', month: 'numeric', day: 'numeric' }))
    const weekNumber = new Date(dates[0]).getWeek();
    const toAndFrom = `${dateStrings[0]} bis ${dateStrings[4]}`
    const headerText = `Plan ${group.name} KW${weekNumber} - ${toAndFrom}`;

    page.drawText(headerText, {
        x: 10,
        y: page.getHeight() - 10- lineHeight, // to account for padding and Border
        size: fontSizeHeader,
        font: fonts.robotoBoldFont,
        color: rgb(0.1, 0.1, 0.1)
    });

    const edgePadding = {x:10, y:10};
    const metaData = {
        title: { text: headerText, x: edgePadding.x, y: page.getHeight() - edgePadding.y },
        timeslots: { x: edgePadding.x, width: 40 }
    };
    // calculate date header values
    const colWidth = (page.getWidth() - 2*edgePadding.x - metaData.timeslots.width)/5;
    const textHeightHeader = fonts.robotoFont.heightAtSize(fontSizeHeader);
    dates.forEach((date, index) => {
       const dateKey = new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
       const textWidth = fonts.robotoFont.widthOfTextAtSize(dateStrings[index], fontSizeHeader);

       metaData[dateKey] = {
           x: edgePadding.x + metaData.timeslots.width + index * colWidth,
           y: defaultHeaderY,
           textWidth,
           textHeight: textHeightHeader,
           width: colWidth,
           height: textHeightHeader + 2*padding,
           padding: {
               x: (colWidth - textWidth) / 2,
               y: padding
           },
           text: dateStrings[index],
       };

       // draw day header
       page.drawText(dateStrings[index], {
           x: metaData[dateKey].x + metaData[dateKey].padding.x,
           y: metaData[dateKey].y,
           size: fontSizeHeader,
           font: fonts.robotoFont,
           color: rgb(0.1, 0.1, 0.1)
       });
    });

    // starting with appointment data
    // i sorted the array the wrong way around - should be timeslots and dates not dates and timeslots
    const maxTimeslotNumber = appointmentTable[dates[0]].length
    let linePosY = 1.5*lineHeight + padding;
    for (let i = 0; i < maxTimeslotNumber; i++) {
        // draw timeslot text
        const timeslot = appointmentTable[dates[0]][i];
        page.drawText(`${timeslot.start} ${timeslot.end}`, {
            x: metaData.timeslots.x,
            y: defaultHeaderY - linePosY,
            size: fontSize,
            lineHeight: lineHeight,
            maxWidth: metaData.timeslots.width,// to account for padding
            font: fonts.robotoFont,
            color: rgb(0.1, 0.1, 0.1)
        });
        // go over appointmentTable dates
        let maxPrintHeight = 0;
        for (let j = 0; j < dates.length; j++) {
            const dateKey = new Date(dates[j]).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
            const daySlot = appointmentTable[dates[j]][i];
            const appointments = daySlot.appointments;
            if(daySlot.isPause){
                //print pause, which should be displayed smaller
                const yPos = defaultHeaderY - linePosY + fontHeight - padding -8; //-8 to center pause
                const printHeight = printPause(page, fonts, metaData[dateKey].x, yPos, metaData[dateKey]);
                 if ((printHeight + padding) > maxPrintHeight) {
                    maxPrintHeight = (printHeight + padding);
                    if ((printHeight + padding) > maxPrintHeight) {
                        maxPrintHeight = (printHeight + padding);
                    }
                }
            }
            else if (!appointments.length) {
                // print hohlstunde
                const yPos = defaultHeaderY - linePosY + fontHeight - padding;
                const printHeight = printEmptyLesson(page, fonts, metaData[dateKey].x, yPos, metaData[dateKey]);
                if ((printHeight + padding) > maxPrintHeight) {
                    maxPrintHeight = (printHeight + padding);
                }
            } else {
                let localMaxPrintHeight = 0;
                for (let k = 0; k < appointments.length; k++) {
                    // print appointment and return height
                    const yPos = defaultHeaderY - linePosY + fontHeight - padding - localMaxPrintHeight;
                    const printHeight = printAppointmentLesson(page, fonts, metaData[dateKey].x, yPos, metaData[dateKey], appointments[k]);
                    localMaxPrintHeight += printHeight + padding;
                }
                if (localMaxPrintHeight > maxPrintHeight) {
                    maxPrintHeight = localMaxPrintHeight;
                }
            }

        }
        // update linePosY
        linePosY += maxPrintHeight + padding
    }

    // TODO comment back in once schools inevitably want the separating lines back
    // // draw vertical lines
    // const keysForLines = Object.keys(metaData).filter(item => item !== 'title' && item !== 'timeslots');
    // keysForLines.forEach(key => {
    //    const xCoord = metaData[key].x;
    //
    //    page.drawLine({
    //        start: { x: xCoord, y: defaultHeaderY },
    //        end: { x: xCoord, y: defaultHeaderY - linePosY },
    //        thickness: 2,
    //        color: rgb(0.75, 0.75, 0.75),
    //        opacity: 0.75,
    //    });
    // });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    return await pdfDoc.save();
};

const printEmptyLesson = (page, fonts, xPos, yPos, dayMetaData) => {
    const padding = {
        xInner: 3,
        xOuter: 7,
        yInner: 3,
        yOuter: 3,
    };
    const rectHeight = 40;
    const fontSize = 14;

    page.drawRectangle({
        x: xPos + padding.xOuter,
        y: yPos - rectHeight,
        width: dayMetaData.width - 2*padding.xOuter,
        height: rectHeight,
        borderWidth: 1,
        borderColor: rgb(0.8, 0.8, 0.8),
    });
    // write hohlstunde inside
    // Fucking height at size delivers weird values
    const textOffest = (rectHeight/2 - fonts.robotoFont.heightAtSize(fontSize, { descender: false })/2);
    page.drawText('Hohlstunde', {
        x: xPos + padding.xOuter + 2*padding.xInner,
        y: yPos - rectHeight + textOffest,
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.65, 0.65, 0.65)
    });

    return rectHeight;
}
const printPause = (page, fonts, xPos, yPos, dayMetaData) =>{
    const padding = {
        xInner: 3,
        xOuter: 7,
        yInner: 3,
        yOuter: 3,
    };
    const rectHeight = 20;
    const fontSize = 12;

    page.drawRectangle({
        x: xPos + padding.xOuter,
        y: yPos - rectHeight,
        width: dayMetaData.width - 2*padding.xOuter,
        height: rectHeight,
        borderWidth: 1,
        borderColor: rgb(0.8, 0.8, 0.8),
    });
    // write pause inside
    const textOffest = (rectHeight/2 - fonts.robotoFont.heightAtSize(fontSize, { descender: false })/2);
    page.drawText('Pause', {
        x: xPos + padding.xOuter + 2*padding.xInner,
        y: yPos - rectHeight + textOffest,
        size: fontSize,
        font: fonts.robotoFont,
        color: rgb(0.65, 0.65, 0.65)
    });

    return rectHeight + 20; // + 20 because rectHeight is 20 smaller
}

const printAppointmentLesson = (page, fonts, xPos, yPos, dayMetaData, appointment) => {
    const padding = {
        xInner: 3,
        xOuter: 7,
        yInner: 3,
        yOuter: 3,
    }
    const outerXPadding = 7
    const rectHeight = 40;
    const fontSize = 14;
    const fontSizeSmall = 10;
    const colors = {
        lightGrey: rgb(0.8, 0.8, 0.8),
        grey: rgb(0.5, 0.5, 0.5),
        darkGrey: rgb(0.2, 0.2, 0.2),
        red: rgb(1, 0, 0)
    };

    page.drawRectangle({
        x: xPos + padding.xOuter,
        y: yPos - rectHeight,
        width: dayMetaData.width - 2*padding.xOuter,
        height: rectHeight,
        borderWidth: 1,
        borderColor: appointment.status !== 'normal' ? colors.red : colors.lightGrey,
    });
    // write subject name inside and scale if necessary
    let subjectFontSize = fontSize;
    const textAreaWidth = dayMetaData.width - 2*padding.xOuter - 4*padding.xInner;
    const subjectTextWidth = fonts.robotoFont.widthOfTextAtSize(appointment.subject, subjectFontSize);
    if (subjectTextWidth > textAreaWidth) {
        // scale fontsize accordingly
        subjectFontSize = fontSize * (textAreaWidth / subjectTextWidth);
    }
    // heightAtSize needs descender falls or else it will take to much space
    const subjectHeight = fonts.robotoFont.heightAtSize(subjectFontSize, { descender: false });
    const subjectOffset = (rectHeight/2 - subjectHeight/2);
    page.drawText(appointment.subject, {
        x: xPos + padding.xOuter + 2*padding.xInner,
        y: yPos - rectHeight + subjectOffset,
        size: subjectFontSize,
        font: fonts.robotoFont,
        color: colors.darkGrey
    });
    // check status
    if (appointment.status === 'canceled') {
        // draw line over text
        page.drawLine({
            start: {x: xPos + padding.xOuter + padding.xInner, y: yPos - rectHeight + subjectOffset + 0.5*subjectHeight},
            end: {x: xPos + padding.xOuter + 3*padding.xInner + subjectTextWidth, y:yPos - rectHeight + subjectOffset + 0.5*subjectHeight},
            thickness: 2,
            color: colors.red,
        })
    }

    // Additional info
    const smallHeight = fonts.robotoFont.heightAtSize(fontSizeSmall, { descender: false });
    // draw teachers
    let sickTeacherXOffset = 0;
    // handle sick teachers in splan
    if (appointment.sickTeachers.length) {
        const sickTeacherString = appointment.sickTeachers.join(', ');
        const sickTeacherStringWidth = fonts.robotoFont.widthOfTextAtSize(sickTeacherString, fontSizeSmall);
        const startingCoordX = xPos + dayMetaData.width - sickTeacherStringWidth - padding.xOuter - padding.xInner;
        page.drawText(sickTeacherString, {
            x: startingCoordX,
            y: yPos - smallHeight - 1.5*padding.yInner,
            size: fontSizeSmall,
            font: fonts.robotoFont,
            color: colors.grey
        });
        page.drawLine({
            start: {x: startingCoordX, y: yPos - 0.5*smallHeight - 1.5*padding.yInner},
            end: {x: startingCoordX + sickTeacherStringWidth, y: yPos - 0.5*smallHeight - 1.5*padding.yInner},
            thickness: 1,
            color: colors.red,
        })
        sickTeacherXOffset = sickTeacherStringWidth + padding.xInner;
    }
    const teacherString = appointment.teachers.join(', ');
    const teacherStringWidth = (sickTeacherXOffset ? fonts.robotoBoldFont : fonts.robotoFont)
        .widthOfTextAtSize(teacherString, fontSizeSmall);
    page.drawText(teacherString, {
        x: xPos + dayMetaData.width - teacherStringWidth - padding.xOuter - padding.xInner - sickTeacherXOffset,
        y: yPos - smallHeight - 1.5*padding.yInner,
        size: fontSizeSmall,
        font: (sickTeacherXOffset ? fonts.robotoBoldFont : fonts.robotoFont),
        color: colors.grey
    });

    // draw Rooms
    if (appointment.rooms.length) {
        const roomString = appointment.rooms.join(', ');
        const roomStringWidth = fonts.robotoFont.widthOfTextAtSize(roomString, fontSizeSmall)
        page.drawText(roomString, {
            x: dayMetaData.x + dayMetaData.width - roomStringWidth - padding.xOuter - padding.xInner,
            y: yPos - rectHeight + smallHeight - padding.yInner,
            size: fontSizeSmall,
            font: fonts.robotoFont,
            color: colors.grey
        });
    }


    return rectHeight;
}

export default {
    splanWeekExport
}
