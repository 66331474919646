<template>
    <div>
        <v-menu
            v-model="avatarPickerOpen"
            :close-on-content-click="false"
            offset-y
            :z-index="11"
            :attach="'#avatarCreatorProfileButton'"
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    x-small
                    elevation="0"
                    :class="mobile ? 'avatarContainerMobile' : 'avatarContainer'"
                    :style="'background-color: ' + currentProfileColor"
                    width="45"
                    height="45"
                    aria-label="Profilbild bearbeiten"
                    v-on="on"
                >
                    <img
                        v-if="hasUploadedProfilePicture && profilePictureBlob"
                        :src="profilePictureBlob"
                        style="height: 45px; width: 45px; border-radius: 6px"
                        alt="Profilbild bearbeiten"
                    >

                    <div v-else-if="hasUploadedProfilePicture && !profilePictureBlob">
                        <v-progress-circular
                            indeterminate
                            color="white"
                        />
                    </div>

                    <v-icon
                        v-else-if="currentProfilePicture.text"
                        :size="mobile ? 20 : 45"
                        color="white"
                    >
                        {{ 'mdi-' + currentProfilePicture.text }}
                    </v-icon>
                    <v-img
                        v-else
                        width="45"
                        height="45"
                        :src="currentProfilePicture"
                    />
                    <img
                        class="colorIcon"
                        :src="bearbeitenIconSchwarz"
                    >
                </v-btn>
                <div
                    id="avatarCreatorProfileButton"
                />
            </template>

            <progress-bar
                title="Hochladen"
                :show-progress="showProfilePicUploadProgress"
                :progress="profilePicUploadProgress"
                :abort-progress="profilePicXmlHttpRequest ? () => {profilePicXmlHttpRequest.abort();}: () => {}"
            />

            <v-card id="avatarCreatorContainer">
                <ImageUpload
                    :usage="'profilePicture'"
                    :activate-delete-btn="hasUploadedProfilePicture"
                    @upload="handleUploading"
                    @finished="finishedUploading"
                    @uploaded="uploadedPictureChange(true);"
                    @deleted="uploadedPictureChange(false);"
                />
                <!-- Avatar -->
                <v-btn-toggle
                    id="avatarCreatorContainer"
                    v-model="toggleExclusive"
                    mandatory
                    light
                    background-color="rgba(98,98,98,255)"
                >
                    <v-btn
                        v-for="(picture, index) in availableProfilePictures"
                        :key="index"
                        :value="picture"
                        :class="{ 'selected' : picture === currentProfilePicture }"
                        elevation="0"
                        style="margin: 6px; border-radius: 8px; width: 48px; background-color: #000000"
                        @click="changeProfilePicture(picture)"
                    >
                        <v-icon
                            v-if="picture.text"
                            size="45"
                            color="white"
                        >
                            {{ 'mdi-' + picture.text }}
                        </v-icon>

                        <v-img
                            v-else
                            width="45"
                            height="45"
                            color="white"
                            :src="picture.img"
                        />
                    </v-btn>
                </v-btn-toggle>
                <v-divider
                    dark
                    aria-hidden="true"
                />
                <!-- Color -->
                <v-tooltip
                    v-for="(color, index) in availableProfileColors"
                    :key="index"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="colorButton"
                            :class="{ 'selected': color === currentProfileColor }"
                            :style="`background-color: ${color}`"
                            x-small
                            elevation="0"
                            :aria-label="getColorName(color)"
                            v-bind="attrs"
                            v-on="on"
                            @click="changeProfileColor(color)"
                        />
                    </template>
                    <span>{{ getColorName(color) }}</span>
                </v-tooltip>

                <v-divider
                    dark
                    aria-hidden="true"
                />

                <!-- Profile picture upload -->
                <!-- <div
                    v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                    class="d-flex justify-center align-center"
                >
                    <v-btn
                        elevation="0"
                        class="text-none"
                        style="margin: 6px;"
                        @click="cropDialog = true;"
                    >
                        Profilbild hochladen
                    </v-btn>
                </div>

                <div
                    v-if="accountRole === 'teacher' || accountRole === 'maintainer'"
                    class="d-flex justify-center align-center"
                >
                    <v-btn
                        v-if="hasUploadedProfilePic"
                        elevation="0"
                        class="text-none"
                        style="margin: 6px;"
                        @click="deleteProfilePic"
                    >
                        Profilbild löschen
                    </v-btn>
                </div> -->
            </v-card>

            <!-- <v-dialog
                v-model="cropDialog"
                width="600"
            >
                <v-card>
                    <v-card-title>
                        <h1>Profilbild zuschneiden</h1>

                        <v-spacer />

                        <v-btn
                            x-small
                            elevation="0"
                            style="width: 30px; height: 30px"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20"
                                alt="Dialog schließen"
                                @click="cropDialog = false"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <div class="d-flex justify-end align-center mb-2">
                            <v-spacer />

                            <v-btn
                                class="text-none"
                                elevation="0"
                                @click="cropDialog = false"
                            >
                                Abbrechen
                            </v-btn>

                            <v-btn
                                class="text-none ml-2"
                                elevation="0"
                                :disabled="!fileSelected"
                                @click="clickCropperSaveBtn"
                            >
                                Hochladen
                            </v-btn>
                        </div>

                        <vue-anka-cropper
                            :options="{
                                aspectRatio: 1,
                                closeOnSave: true,
                                cropArea: 'box',
                                croppedHeight: 400,
                                croppedWidth: 400,
                                cropperHeight: 800,
                                dropareaMessage: 'Bild hier ablegen oder Knopf nutzen',
                                layoutBreakpoint: 900,
                                maxFileSize: 5000000,
                                selectButtonLabel: 'Bild auswählen',
                                skin: 'eklara',
                                showPreview: false
                            }"
                            @cropper-saved="uploadFile"
                            @cropper-file-selected="fileSelected = true"
                        />

                        <p class="pt-4 text-center red--text">
                            <b>Stellen Sie sicher, dass Sie die Nutzungsrechte am Bild besitzen.
                                Das Bild können andere Nutzer sehen. Die Dateigröße ist auf maximal 5 MB beschränkt.</b>
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog> -->
        </v-menu>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import ImageUpload from '@/components/Utils/ImageUpload';
import bearbeitenIconSchwarz from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg'
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";
import {getColorNameByHex} from "@/util/colorNameConverter";

export default {
    name: "Avatar",
    components: {  ProgressBar, ImageUpload },
    props: {
        mobile: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        account: null,

        currentProfileColor: null,
        availableProfileColors: [
            '#f0b423', '#343e55', '#e6231e', '#3ba934', '#D1BC8A', '#815a30', '#F3a4e7',
            '#95509C','#6995cd', '#40E0D0', '#ff6633'
        ],
        currentProfilePicture: '',
        saveProfilePicture: '',
        availableProfilePictures: [
            {img: avatar1},
            {img: avatar2},
            {img: avatar3},
            {img: avatar4},
            {img: avatar5},
            {img: avatar6},
            {img: avatar7},
            {img: avatar8},
            {img: avatar9},
            {img: avatar10},
            {img: avatar11},
            {img: avatar12},
            {img: avatar13},
            {img: avatar14},

        ],
        defaultPictures: {
            Avatar1: avatar1,
            Avatar2: avatar2,
            Avatar3: avatar3,
            Avatar4: avatar4,
            Avatar5: avatar5,
            Avatar6: avatar6,
            Avatar7: avatar7,
            Avatar8: avatar8,
            Avatar9: avatar9,
            Avatar10: avatar10,
            Avatar11: avatar11,
            Avatar12: avatar12,
            Avatar13: avatar13,
            Avatar14: avatar14,
        },
        toggleExclusive: [],
        avatarPickerOpen: false,

        profilePicXmlHttpRequest: null,
        hasUploadedProfilePicture:false,
        profilePictureBlob: null,
        showProfilePicUploadProgress: false,
        profilePicUploadProgress: 0.0,

        bearbeitenIconSchwarz,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('accounts', ['accountsById']),
        ...mapGetters('teachers', ['teachersByAccountId']),
    },
    watch: {
        avatarPickerOpen() {
            this.setAvatarPickerOpen(this.avatarPickerOpen);
        },
    },
    async mounted() {
        await this.setAvatarValues();
    },
    methods: {
        ...mapActions('auth', ['getAccount']),
        ...mapActions('accounts', ['patchAccount']),
        ...mapActions('avatar', ['setAvatarPickerOpen', 'setAvatarChanged']),
        ...mapActions('maintainers', ['getMaintainers', 'getMaintainerProfilePicture', 'deleteMaintainerProfilePicture']),
        ...mapActions('teachers', ['getTeachers', 'getProfilePicture', 'deleteProfilePicture']),
        ...mapMutations('snackbar', ['showSnackbar']),

        getColorName(hex) {
            return getColorNameByHex(hex);
        },
        async uploadedPictureChange(uploadedPicture){
            if (uploadedPicture) {
                this.hasUploadedProfilePicture = true;
                await this.getTeachers(true);
                if (this.accountRole === 'teacher') {
                    const thisTeacher = this.teachersByAccountId[this.account._id];
                    try{
                        this.profilePictureBlob = await this.getProfilePic(thisTeacher._id);
                    } catch(err){
                        console.log(err);
                    }
                } else if (this.accountRole === 'maintainer') {
                    let allMaintainers = await this.getMaintainers();
                    let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                    this.profilePictureBlob = await this.getProfilePic(thisMaintainer._id);
                }
                this.setAvatarChanged(true);
            } else {
                this.hasUploadedProfilePicture = false;
                await this.getTeachers(true);
            }
        },
        async setAvatarValues() {
            const currentAccount = await this.getAccount();
            //take account from 'accounts.js' -sore and not auth, since we patch only accounts store
            this.account = this.accountsById[currentAccount._id]
            if (this.accountRole === 'teacher') {
                const thisTeacher = this.teachersByAccountId[this.account._id];
                if (thisTeacher.profilePicture) {
                    try{
                    this.profilePictureBlob = await this.getProfilePic(thisTeacher._id);
                    } catch(err){
                        console.log(err);
                    }
                }
            } else if (this.accountRole === 'maintainer') {
                let allMaintainers = await this.getMaintainers();
                let thisMaintainer = allMaintainers.find(el => el.account === this.account._id);
                if (thisMaintainer.profilePicture) {
                    this.profilePictureBlob = await this.getProfilePic(thisMaintainer._id);
                }
            }

            if (this.profilePictureBlob) {
                this.hasUploadedProfilePicture = true;
            } else if (this.account) {
                if (this.account.profilePicture) {
                    let avatarKey = 'Avatar3';
                    if (this.account.profilePicture
                        && this.account.profilePicture.img
                        && this.account.profilePicture.img.includes('Avatar')) {
                        avatarKey = this.account.profilePicture.img.replace('/', '').replace('.svg', '');
                    }
                    this.currentProfilePicture = this.defaultPictures[`${avatarKey}`];
                } else {
                    this.currentProfilePicture = this.availableProfilePictures['Avatar3'];
                }
            }
              if (this.account.profileColor) {
                    this.currentProfileColor = this.account.profileColor;
                } else {
                    this.currentProfileColor = this.availableProfileColors[10]; // simple grey
                }

            this.setAvatarChanged(false);
        },

        async changeProfilePicture(picture) {
            if (this.hasUploadedProfilePicture) {
                document.getElementById('deleteUploadedPictureBtn').click();
                this.hasUploadedProfilePicture = false;
            }
            try {
                /**  set avatar.svg as profile picture string */
                const split1 = (picture.img || picture).split('/');
                // console.log('split1', split1);
                const split2 = split1[split1.length - 1].split('.')[0];
                // console.log('split2', split2);
                this.saveProfilePicture = {
                    img: `/${split2}.svg`,
                }
                this.currentProfilePicture = this.defaultPictures[`${split2}`];
            } catch (e) {
                console.warn(e);
            }

            await this.saveAvatar();
        },

        async changeProfileColor(color) {
            this.currentProfileColor = color;
            await this.saveAvatar();
        },

        // Save Avatar from Combustion Man
        async saveAvatar() {
            if(!this.currentProfileColor){
                this.currentProfileColor = '#ff6633';
            }
            // await backend.patchAccount(this.account._id, {
            //     profilePicture: this.currentProfilePicture || undefined,
            //     profileColor: this.currentProfileColor,
            // });
            await this.patchAccount({
                accountId: this.account._id,
                payload: {
                    profilePicture: this.saveProfilePicture || undefined,
                    profileColor: this.currentProfileColor,
                }
            })


            //this.setAvatarChanged(true);
        },

        //#region Profile picture
        // clickCropperSaveBtn() {
        //     const collection = document.getElementsByClassName("ankaCropper__saveButton");
        //     collection[0].click();
        // },

        // If there's an uploaded profile picture for teacher or maintainer
        async getProfilePic(id) {
            try {
                if (this.accountRole === 'teacher') {
                    return await this.getProfilePicture(id);
                } else if (this.accountRole === 'maintainer') {
                    return await this.getMaintainerProfilePicture(id);
                }
            } catch (e) {
                console.error(e);
            }
        },
        handleUploading() {
            this.$emit('upload');
        },
        finishedUploading() {
            this.$emit('finishedUpload');
        },
    }
}
</script>

<style>
@import '../../../../node_modules/vue-anka-cropper/dist/VueAnkaCropper.css';

/* Creates a custom skin called eklara */
.ankaCropper.eklara {
    background: #fff;
    color: #000;
}

.ankaCropper.eklara .ankaCropper__droparea {
    border: 2px dashed #3e424b
}

.ankaCropper.eklara .ankaCropper__saveButton,
.ankaCropper.eklara .ankaCropper__selectButton {
    background: #334f90;
    color: #fff;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ankaCropper.eklara .ankaCropper__saveButton svg {
    margin-right: 4px;
}

.ankaCropper.eklara .ankaCropper__saveButton:hover,
.ankaCropper.eklara .ankaCropper__selectButton:hover {
    background: #335dbe;
}

.ankaCropper.eklara .ankaCropper__navigation {
    background: #1d2227;
}

.ankaCropper.eklara .ankaCropper__navButton {
    background: #272c31;
    height: 36px !important;
    width: 36px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ankaCropper.eklara .ankaCropper__navButton svg {
    stroke: #eee;
}

.ankaCropper.eklara .ankaCropper__navButton:hover {
    background: #000;
}

</style>
<style lang="scss" scoped>
.avatarContainer {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatarContainerMobile {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#avatarCreatorContainer {
    max-width: 240px;
    height: 100px;
    display: table;
    border-radius: 8px;
    background-color: rgba(98,98,98,255);
}

.selection {
    width: 50px;
    height: 50px;
    margin: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -8px 7px 9px -5px rgba(0,0,0,0.31);
}

.profilePicture {
    color: white;
}

.selected {
    outline: 3px solid #62aed2 !important;
}

.colorButton {
    width: 48px !important;
    height: 48px !important;
    margin: 6px;
    border-radius: 8px;
    border: none;

    & img {
      color: white;
      padding: 5px;
    }
}

.colorIcon{
    position: absolute;
    right: -8px;
    bottom: -8px;
    width: 20px !important;
    height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
}
</style>
