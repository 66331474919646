<template>
    <v-card
        class="contentCard"
    >
        <v-card-title
            :style="`background-color: ${headerColor}`"
            class="pa-2 px-2 pl-4"
            style="height: 50px"
        >
            <img
                :src="fachIcon"
                class="iconToWhite icon30 mr-2"
            >
            <p style="color: white">
                Fächer verwalten
            </p>

            <v-spacer />

            <v-btn
                icon
                @click="checkDeleteAvailable"
            >
                <img
                    :src="deleteIcon"
                    :class="deleteMode ? 'icon20 iconToRed' : 'icon20 iconToWhite'"
                    alt="Löschen"
                >
            </v-btn>

            <v-btn
                icon
                @click="()=> { $emit('stopEvent') }"
            >
                <img
                    :src="cancelIcon"
                    class="icon20 iconToWhite"
                    alt="schließen"
                >
            </v-btn>
        </v-card-title>
        <v-card-text
            style="height: 500px; display: flex; padding:0"
        >
            <div style="width: 100%; height: 100%">
                <div style="display: flex">
                    <v-text-field
                        v-model="search"
                        placeholder="Nach Fach suchen"
                        single-line
                        hide-details
                        class="pl-6 pr-6"
                    >
                        <template #prepend-inner>
                            <div>
                                <img
                                    :src="suchIcon"
                                    style="height: 20px"
                                    alt="Suchen-Symbol"
                                >
                            </div>
                        </template>
                    </v-text-field>
                </div>
                <div
                    class="pl-6 pr-6"
                    style="margin-top: 8px; display: flex; flex-wrap: wrap"
                >
                    <icon-picker
                        :current-color="addSubjectColor"
                        :current-icon="addSubjectIcon"
                        :callback="(result) => { addSubjectColor = result }"
                        :callback-icon="(result) => { addSubjectIcon = result }"
                        :group-icons="groupIcons"
                        color-picker-usage="fachErstellen"
                        style="align-self: center"
                    />
                    <v-text-field
                        v-model="addSubjectName"
                        placeholder="Neues Fach erstellen"
                        single-line
                        hide-details
                        style="padding-top: 0; margin:0; align-self: center"
                        class="ml-4"
                        @keydown.enter="addSubject"
                    />
                    <div class="d-flex align-center justify-center ml-3 mt-2 mr-2">
                        <v-checkbox
                            v-model="isSport"
                            rounded
                            small
                            light
                            class="mt-4 mr-1"
                        />
                        <p style="font-size: 16px;">
                            Schulsport
                        </p>
                    </div>
                    <div class="d-flex">
                        <v-select
                            v-model="addSubjectTeachers"
                            :items="teachers"
                            item-value="_id"
                            :item-text="item => item.name + ' ' + item.lastName"
                            :menu-props="{ offsetY: true }"
                            outlined
                            placeholder="Lehrer auswählen"
                            dense
                            multiple
                            hide-details
                            return-object
                            style="align-self: center; width: 200px;"
                            class="ml-4"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0 || index === 1">
                                    <span>{{ item.lastName }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 2"
                                    style="color: grey"
                                >
                                    (+{{ addSubjectTeachers.length - 2 }} weitere)
                                </span>
                            </template>
                        </v-select>
                        <v-btn
                            color="success"
                            x-small
                            width="40px"
                            height="40px"
                            elevation="1"
                            style="align-self: center"
                            class="ml-4"
                            @click="addSubject"
                        >
                            <v-icon
                                class="iconToWhite"
                                style="height: 24px"
                            >
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-data-table
                    ref="dataTable"
                    :headers="headers"
                    :items="subjects"
                    :search="search"
                    hide-default-footer
                    fixed-header
                    disable-pagination
                    :show-select="deleteMode"
                    sort-by="name"
                    class="dataTableStyle"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                    @toggle-select-all="selectAll"
                    @change="requestSubjects"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td
                                v-if="deleteMode"
                                class="smallPadding"
                            >
                                <v-checkbox
                                    dense
                                    hide-details
                                    :value="row.item.selected"
                                    style="padding:0; margin:0"
                                    @click="toggleSelectSubject(row.item)"
                                />
                            </td>
                            <td class="smallPadding">
                                <icon-picker
                                    :current-color="row.item.color"
                                    :current-icon="row.item.icon"
                                    :callback="(result) => { initCurrentSubject(row.item); row.item.color = result; initNewSubject(row.item); editThisSubject(row.item) }"
                                    :callback-icon="(result) => { initCurrentSubject(row.item); row.item.icon = result; initNewSubject(row.item); editThisSubject(row.item) }"
                                    :group-icons="groupIcons"
                                    color-picker-usage="fachErstellen"
                                />
                            </td>
                            <td class="smallPadding">
                                <v-text-field
                                    :key="row.item._id"
                                    v-model="row.item.newName"
                                    flat
                                    solo
                                    dense
                                    hide-details
                                    style="padding-top: 0; margin:0"
                                    :style="row.item.name.replace(/\s/g, '') === '' ? 'background-color: red; padding: 1.9px' : ''"
                                    placeholder="Geben Sie einen Namen ein..."
                                    @focus="initCurrentSubject(row.item)"
                                    @blur="row.item.name = row.item.newName; initNewSubject(row.item); editThisSubject(row.item)"
                                />
                            </td>
                            <td
                                class="smallPadding"
                            >
                                <v-checkbox
                                    v-model="row.item.isSport"
                                    dense
                                    hide-details
                                    style="padding:0;"
                                    @click="initNewSubject(row.item); editThisSubject(row.item)"
                                />
                            </td>
                            <td class="smallPadding">
                                <v-select
                                    v-model="row.item.teachers"
                                    :items="teachers"
                                    item-value="_id"
                                    :item-text="item => item.name + ' ' + item.lastName"
                                    :menu-props="{ offsetY: true }"
                                    placeholder="Niemand ausgewählt"
                                    outlined
                                    dense
                                    multiple
                                    hide-details
                                    return-object
                                    @focus="initCurrentSubject(row.item)"
                                    @blur="initNewSubject(row.item); editThisSubject(row.item)"
                                >
                                    <template v-slot:selection="{ index }">
                                        <span
                                            v-if="index === 0"
                                            style="color: grey"
                                        >
                                            ({{ row.item.teachers.length }} ausgewählt)
                                        </span>
                                    </template>
                                </v-select>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <!-- Delete dialog-->
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="400"
                overlay-opacity="0.8"
            >
                <v-card
                    class="cardStyle"
                    style="overflow: hidden; border-radius: 16px"
                >
                    <v-card-title
                        style="background-color: var(--v-timetableColor-base); color: white"
                        class="text-h5 mb-1 d-flex align-center justify-space-between"
                    >
                        <p style="margin-left: -8px">Fach löschen?</p>
                        <v-btn
                            icon
                            color="transparent"
                            elevation="0"
                            style="margin-right: -8px"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-4">
                        Sind Sie sicher, dass Sie die ausgewählten Fächer löschen möchten?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center mb-2">
                        <v-btn
                            class="optionBtn text-none"
                            color="#F5F5F5"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 mr-2"
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none"
                            color="rot"
                            dark
                            @click="deleteSelectedSubjects"
                        >
                            <img
                                :src="deleteIcon"
                                class="icon20 mr-2 iconToWhite"
                            >
                            Löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-overlay
                absolute
                :value="showLoadingOverlay"
                style="width: 100% !important;"
            >
                <v-progress-circular
                    v-if="showLoadingOverlay"
                    indeterminate
                    size="100"
                />
            </v-overlay>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import suchIcon from '@/assets/Icons/suche-26.svg'
import fachIcon from "@/assets/Icons/FaceliftIcons/fach.svg";

import bioIcon from '@/assets/Icons/FaceliftIcons/f-biologie.svg'
import chemieIcon from '@/assets/Icons/FaceliftIcons/f-chemie.svg'
import deutschIcon from '@/assets/Icons/FaceliftIcons/f-deutsch.svg'
import englischIcon from '@/assets/Icons/FaceliftIcons/f-englisch.svg'
import erdkundeIcon from '@/assets/Icons/FaceliftIcons/f-erdkunde.svg'
import geschichteIcon from '@/assets/Icons/FaceliftIcons/f-geschichte.svg'
import matheIcon from '@/assets/Icons/FaceliftIcons/f-mathe.svg'
import musikIcon from '@/assets/Icons/FaceliftIcons/f-musik.svg'
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst.svg';
import physikIcon from '@/assets/Icons/FaceliftIcons/f-physik.svg'
import politikIcon from '@/assets/Icons/FaceliftIcons/f-politik.svg'
import religionIcon from '@/assets/Icons/FaceliftIcons/f-religion.svg'
import sportIcon from '@/assets/Icons/FaceliftIcons/f-sport.svg'
import wirtschaftIcon from '@/assets/Icons/FaceliftIcons/f-wirtschaft.svg'

import IconPicker from "@/components/Utils/IconPicker.vue";

export default {
    name: "CreateRoom",
    components: {
        IconPicker
    },
    props: {
        headerColor: { type: String, required: false, default: '#467abb' },
    },
    data: () => ({
        cancelIcon,
        suchIcon,
        deleteIcon,
        fachIcon,

        groupIcons: [
            {icon: bioIcon, text: "bioIcon"},
            {icon: chemieIcon, text: "chemieIcon"},
            {icon: deutschIcon, text: "deutschIcon"},
            {icon: englischIcon, text: "englischIcon"},
            {icon: erdkundeIcon, text: "erdkundeIcon"},
            {icon: geschichteIcon, text: "geschichteIcon"},
            {icon: matheIcon, text: "matheIcon"},
            {icon: musikIcon, text: "musikIcon"},
            {icon: kunstIcon, text: "kunstIcon"},
            {icon: physikIcon, text: "physikIcon"},
            {icon: politikIcon, text: "politikIcon"},
            {icon: religionIcon, text: "religionIcon"},
            {icon: sportIcon, text: "sportIcon"},
            {icon: wirtschaftIcon, text: "wirtschaftIcon"},
        ],
        addSubjectColor: '#fcd20a',
        addSubjectIcon: 'deutschIcon',
        addSubjectName: '',
        addSubjectTeachers: [],

        showLoadingOverlay: false,

        deleteDialog: false,
        deleteMode: false,

        search: '',
        headers: [
            { text: 'Icon', value: 'icon', filterable: false, sortable: false, width: '10%'},
            { text: 'Name', value: 'name', width: '40%' },
            { text: 'Schulsport', value: 'sport', filterable: false, sortable: false, width: '10%'},
            { text: 'Lehrer', value: 'teacher', filterable: false, sortable: false,  width: '50%' },
        ],

        subjects: [],
        teachers: [],
        selectedSubject: null,
        selectedSubjects: [],
        selectedCount: 0,
        currentTeachers: [],
        currentName: '',
        currentIcon: '',
        currentColor: '',
        currentSport: false,
        newName: '',
        newIcon: '',
        newColor: '',
        newSport: false,
        selectedTeachers: [],

        fields: {
            name: false,
            color: false,
            teacher: false,
        },

        isSport: false,
    }),
    computed: {
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
    },
    watch: {
        getSubjectInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSubjects(true);
            }
        }
    },
    async mounted() {
        await this.requestSubjects(true);
        await this.requestTeachers();
    },
    methods: {
        ...mapActions("subjects", ["getSubjects","createSubject", "editSubject", "deleteSubject", "setSubjectListStatus"]),
        ...mapActions("teachers", ["getTeachers","createTeachers", "patchTeacher", "deleteTeacher", "setTeacherListStatus"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async requestSubjects(update) {
            this.subjects = [];
            this.subjects = await this.getSubjects(update);
            this.subjects = this.subjects.map(el => {
                el.selected = false;
                el.newName = el.name;
                return {...el}
            });
            this.setSubjectListStatus(false);
        },

        async requestTeachers() {
            this.teachers = await this.getTeachers();
        },

        toggleSelectSubject(subject) {
            let index = -1;
            for (let i = 0; i < this.selectedSubjects.length; i++) {
                if (this.selectedSubjects[i]._id === subject._id) {
                    index = i;
                } else {
                    index = -1;
                }
            }
            if (index === -1) {
                this.selectedSubjects.push(subject);
                this.selectedCount++;
                this.subjects.forEach(el => {
                    el._id === subject._id ? el.selected = true : el.selected = false
                });
            } else {
                this.selectedSubjects.splice(index, 1);
                this.selectedCount--;
                this.subjects.forEach(el => {
                    el._id === subject._id ? el.selected = false : el.selected = false
                });
            }
        },

        async selectAll() {
            // $refs.dataTable workaround necessary because vuetify data-table doesn't offer way of getting the filtered/searched items easily
            if(this.selectedCount === this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.length) {
                this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value = this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.map(el => {
                    el.selected = false;
                    return el
                });
                this.selectedSubjects = [];
                this.selectedCount = 0;
            } else {
                this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value = this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.map(el => {
                    el.selected = true;
                    this.selectedCount++;
                    return el
                });
                if (this.selectedSubjects.length === 0) {
                    for (let index = 0; index < this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.length;
                            index++)
                    {
                        this.selectedSubjects.push(this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[index]);
                    }
                } else {
                    for (let i = 0; i < this.selectedSubjects.length; i++){
                        for (let j = 0; j < this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.length; j++) {
                            if (!this.selectedSubjects[i]._id === this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[j]._id) {
                                this.selectedSubjects.push(this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[j]);
                            }
                        }
                    }
                }
            }
        },

        stopEdit() {
            this.currentTeachers = [];
            this.currentName = '';
            this.currentIcon = '';
            this.currentColor = '';
            this.isSport = false;
            this.newName = '';
            this.newColor = '';
            this.newIcon = '';
            this.newSport = false;
            this.selectedTeachers = [];
            this.addSubjectIcon = 'deutschIcon';
            this.addSubjectColor = '#fcd20a';
            this.addSubjectName = '';
            this.addSubjectTeachers = [];
        },

        initCurrentSubject(subject) {
            subject.teachers.forEach(el => {this.currentTeachers.push(el._id)});
            this.currentName = subject.name;
            this.currentIcon = subject.icon;
            this.currentColor = subject.color;
            this.isSport = subject.isSport;
        },

        initNewSubject(subject) {
            subject.teachers.forEach(el => {this.selectedTeachers.push(el._id)});
            this.newName = subject.newName;
            this.newIcon = subject.icon;
            this.newColor = subject.color;
            this.newSport = subject.isSport;
        },

        validateInput(subject) {
            let result = true;

            this.fields.name = false;
            this.fields.color = false;

            if(subject) {
                if (subject.name.replace(/\s/g, '') === '') {
                    this.fields.name = true;
                    result = false;
                }
            } else {
                if (this.addSubjectName.replace(/\s/g, '') === '') {
                    this.fields.name = true;
                    result = false;
                }
            }
            return result;
        },

        async editThisSubject(subject) {
            // if selected teachers are still the same as before
            if (this.currentTeachers.sort().join(',') === this.selectedTeachers.sort().join(',')
                    && this.currentName === this.newName
                    && this.currentIcon === this.newIcon
                    && this.currentColor === this.newColor
                    && this.isSport === this.newSport
                ) {
                return;
            } else {
                try {
                    this.showLoadingOverlay = true;

                    if (!this.validateInput(subject)) {
                        this.showSnackbar({ message: 'Bitte tragen Sie einen Namen für das Fach ein!', color: 'error'});
                        this.showLoadingOverlay = false;

                    } else {
                        let sport = subject.isSport;
                        if (typeof subject.isSport === 'undefined') {
                            sport = false;
                        }

                        let data = {
                            _id: subject._id,
                            name: subject.name,
                            color: subject.color,
                            icon: subject.icon,
                            teachers: subject.teachers,
                            uploadedIcon: subject.icon.includes('Icon') ?  false : true,
                            isSport: sport,
                        };

                        let response = await this.editSubject(data);

                        if (Number.isInteger(response)) {
                            if (response === 409) {
                                this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                            } else {
                                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            }
                        } else {
                            const index = this.subjects.indexOf(this.selectedSubject);
                            if (index > -1) {
                                this.subjects.splice(index, 1);
                            }
                            this.setSubjectListStatus(true);
                            this.stopEdit();
                            this.showSnackbar({ message: 'Gespeichert!'});
                            this.$emit('requestSubjects');
                        }

                        this.showLoadingOverlay = false;
                        this.expanded = [];
                    }
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            }
        },

        checkDeleteAvailable() {
            if (!this.deleteMode) {
                this.deleteMode = true;
            } else {
                if (this.selectedSubjects.length === 0) {
                    this.deleteMode = false;
                } else {
                    this.deleteDialog = true;
                }
            }
        },

        async deleteSelectedSubjects() {
            this.showLoadingOverlay = true;
            for (let i = 0; i < this.selectedSubjects.length; i++) {
                let response = await this.deleteSubject(this.selectedSubjects[i]._id);
                if (response) {
                    const index = this.subjects.indexOf(this.selectedSubjects[i]);
                    if (index > -1) {
                        this.subjects.splice(index, 1);
                    }
                    this.setSubjectListStatus(true);
                } else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    return;
                }
            }
            this.showSnackbar({ message: 'Löschen erfolgreich!'});
            this.$emit('requestSubjects');
            this.selectedSubjects = [];
            this.subjects = this.subjects.map(el => {
                el.selected = false;
                return el
            });
            this.deleteMode = false;
            this.showLoadingOverlay = false;
            this.deleteDialog = false;
        },

        async addSubject() {
            try {
                this.showLoadingOverlay = true;

                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte tragen Sie einen Namen für das Fach ein!', color: 'error'});
                    this.showLoadingOverlay = false;

                } else {
                    let data = {
                        name: this.addSubjectName,
                        color: this.addSubjectColor,
                        icon: this.addSubjectIcon,
                        teachers: this.addSubjectTeachers,
                        uploadedIcon: this.addSubjectIcon.includes('Icon') ?  false : true,
                        isSport: this.isSport,
                    };

                    let response = await this.createSubject(data);

                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        this.subjects.push(response);
                        this.setSubjectListStatus(true);
                        this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                }
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    .contentCard {
        border-radius: 16px;
        height: 61vh;
        width: 50vw;
        overflow: hidden;
    }

    .dataTableStyle {
        margin: 12px 24px 0px;
        height: 70% !important;
        overflow-y: scroll;
    }

    @media only screen and (max-width:900px) {
        .dataTableStyle {
            height: 100% !important;
        }
        .contentCard {
            height: 70vh;
            width: 85vw;
            overflow-y: hidden;
        }
    }

    @media only screen and (min-width:900px) and (max-width:1300px) {
        .contentCard {
            height: 85vh;
            width: 85vw;
            overflow-y: hidden;
        }
        .dataTableStyle {
            height: 85% !important;
        }
    }

    .optionBtn {
        border-radius: 8px;
        min-width: 35px !important;
        height: 35px !important;
    }

    .borderRadiusDialog {
        border-radius: 16px;
    }

    @media only screen and (max-width:656px) {
        .smallPadding {
            padding: 0 !important;
        }
    }
</style>
