<template>
    <div
        class="d-flex"
        style="flex-direction: column; height: 100%"
        :style="backgroundCssProps"
    >
        <div
            class="d-flex"
            style="background-color: var(--v-headerblue-base); height: 74px; align-items: center"
        >
            <v-btn
                class="videoButton"
                elevation="0"
                style="pointer-events: none"
                :color="'gruen'"
            >
                <img
                    :class="windowWidth < 466 ? 'ml-5' : ''"
                    :src="videoIcon"
                    alt=""
                >
                <p
                    id="videoBtn"
                >
                    Besprechung
                </p>
            </v-btn>
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="ml-4 animateIcon closeButton"
                        icon
                        x-small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="goBack()"
                    >
                        <img
                            :src="schliessenIcon"
                            style="max-width: 25px; max-height: 25px;"
                            alt="Zurück"
                        >
                    </v-btn>
                </template>
                <span>Zurück</span>
            </v-tooltip>
        </div>
        <div
            class="background"
        >
            <h1 class="subHeader">
                Meeting
            </h1>
            <h2 class="mainHeader">
                {{ roomName }}
            </h2>

            <v-text-field
                v-model="username"
                label="Name eingeben"
                outlined
                light
                hide-details
                class="font-weight-bold usernameInput mb-4"
                @keydown="onKeyDown"
            />

            <v-btn
                class="joinRoomButton"
                elevation="0"
                :color="'gruen'"
                @click="clickJoinBbbRoom"
            >
                <img
                    :src="videoIcon"
                    alt=""
                >
                <p>Raum beitreten</p>
            </v-btn>
        </div>
        <div
            class="d-flex pt-5"
            style="justify-content: flex-end"
        >
            <v-tooltip
                top
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="background-color: #343e55; width: 50px; height: 50px; border-radius: 8px; right: 2rem"
                        x-small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="reloadPage()"
                    >
                        <img
                            :src="refreshIcon"
                            alt="Aktualisieren"
                            class="iconToWhite"
                            style="max-width: 25px; max-height: 25px;"
                        >
                    </v-btn>
                </template>
                <span>Aktualisieren</span>
            </v-tooltip>
        </div>
        <Nutzungsbedingungen
            :usage-agreement-not-signed="showUsageAgreement"
            :revoke="false"
            :usage="'extern'"
            @closeDialog="close"
        />
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import * as backendApi from '@/api/backend';

import backgroundPattern from '@/assets/Bilder/Background_Pattern.png';
import videoIcon from '@/assets/Icons/video.svg';
import refreshIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';
import schliessenIcon from '../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import Nutzungsbedingungen from '../components/Policys/Nutzungsbedingungen.vue';

export default {
    components:{
        Nutzungsbedingungen,
    },
    data: () => ({
        videoIcon,
        refreshIcon,
        schliessenIcon,

        backgroundCssProps: {
            backgroundColor: '#fff',
            backgroundPosition: '0px 0px',
            backgroundPositionX: "0px",
            backgroundPositionY: "0px",
            backgroundRepeat: "repeat !important",
            backgroundSize: "560px",
            backgroundClip: "border-box",
            backgroundImage: `url(${backgroundPattern})`,
        },

        username: "",
        externalBbbRoomId: "",
        token: "",
        roomName: '',
        showUsageAgreement: '',
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('auth', ['accountRole']),
        ...mapGetters('parents', ['currentChild']),
    },
    async mounted() {
        this.externalBbbRoomId = this.$route.params.externalBbbRoomId || "";
        this.token = this.$route.query.token || "";

        try {
            const guestDataRes = await backendApi.getExternalBbbRoomGuestData(this.externalBbbRoomId, this.token, this.accountRole === 'parent' ? this.getCurrentChild._id : '');
            const { isOpen, joinAsGuestUrlm, name } = await guestDataRes.json();

            this.roomName = name;

            if (!isOpen) {
                this.showSnackbar({ message: "Raum nicht geöffnet. Bitte versuche es später nochmal.", color: "error" });
                return;
            }
        } catch (err) {
            this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten", color: "error" });
        }
        if(this.accountRole === undefined){
            this.showUsageAgreement = true;
        }
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        onKeyDown(keyEvent) {
            if (keyEvent.key === 'Enter') {
                this.clickJoinBbbRoom();
            }
        },
        close(name){
            console.log('closingName',name);
            this.showUsageAgreement = false;
            this.username = name;
        },
        async clickJoinBbbRoom() {
            try {
                const res = await backendApi.getExternalBbbRoomJoinAsGuestUrl(this.externalBbbRoomId, this.token, this.username || "Gast");
                if (res.status === 409) {
                    this.showSnackbar({ message: "Raum derzeit nicht geöffnet. Bitte versuche es später noch einmal", color: "error" });
                } else if (res.status === 404) {
                    this.showSnackbar({ message: "Raum nicht gefunden. Wurde er vielleicht inzwischen gelöscht?", color: "error" });
                } else {
                    const { redirectUrl } = await res.json();
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    }
                }
            } catch (err) {
                this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten", color: "error" });
            }
        },
        reloadPage() {
            window.location.reload();
        },
        goBack() {
            window.history.back();
        },
    }
}
</script>

<style lang="scss" scoped>
.background {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 10%;
}

.subHeader {
    font-size: 4rem;
    font-weight: 800;
    color: gray;
}

.mainHeader {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.usernameInput {
    background-color: white;
    max-height: 4rem !important;
    width: 20rem;
    color: var(--v-dunkelgrau-base) !important;
}

.joinRoomButton {
    color: white;
    text-transform: inherit;
    height: 4rem !important;
    padding: 2rem 1.5rem !important;
    width: 20rem;

    img {
        height: 2rem;
        filter: brightness(1000%);
        margin-right: 1.5rem;
    }

    p {
        color: white;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

.closeButton {
    border-radius: 8px;
    width: 3rem;
    height: 3rem !important;
    position: absolute;
    right: 2rem;

    img {
        filter: brightness(1000%);
        width: 2rem;
        height: 2rem !important;
    }
}

.videoButton {
    color: white;
    text-transform: inherit;
    height: 3rem !important;
    width: 16rem;
    left: 2rem;

    img {
        height: 2rem;
        filter: brightness(1000%);
        margin-right: 1.5rem;
    }

    p {
        color: white;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

@media only screen and (max-width: 465px) {
    #videoBtn {
        display: none;
    }
    .videoButton {
        width: 3rem !important;
    }
}
</style>
