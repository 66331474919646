import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const state = {
    parents: [],
    meParent: null,
    // currently selected Child
    currentChild: null,
}

const getters = {
    parents: state => state.parents,
    parentsByAccountId: state => state.parents.reduce((acc, parent) => {
        acc[parent.account] = parent;
        return acc;
    }, {}),
    parentsById: state => state.parents.reduce((acc, parent) => {
        acc[parent._id] = parent;
        return acc;
    }, {}),
    parentsByPupilId: state => state.parents.reduce((acc, parent) => {
        parent.pupils.forEach(pupil => {
            acc[pupil._id] = parent;
        });
        return acc;
    }, {}),
    meParent: state => state.meParent,
    currentChild: state => state.currentChild,
}

const mutations = {
    setParents: (state, parents) => {
        state.parents = parents;
    },
    pushParentToState: (state, parent) => {
        state.parents.push(parent);
    },
    setMeParent: (state, meParent) => {
        state.meParent = meParent;
    },
    clearCurrentParent: (state) => {
        state.meParent = null;
    },
    setCurrentChild: (state, child) => {
        localStorage.setItem('currentChild', JSON.stringify(child));
        state.currentChild = child;
    },
}

const actions = {
    async getParents({ commit, getters }, update = false) {
        try {
            if(getters.parents && getters.parents.length > 0 && !update) {
                return getters.parents;
            }
            const res = await backend.getParents();
            await checkResponseStatus(200, res);
            let parents = await res.json();
            commit('setParents', parents);
            return parents;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getParent({ commit, getters }, parentId) {
        try {
            if (getters.parentsById[parentId]) {
                return getters.parentsById[parentId];
            }
            const res = await backend.getParent(parentId);
            await checkResponseStatus(200, res);
            const parent = await res.json();
            if (parent) {
                commit('pushParentToState', parent);
            }
            return parent;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // Returns pupilParent for requesting parent
    async getMeParent ({ commit, dispatch, getters }) {
        try {
            if (getters.meParent) {
                return getters.meParent;
            }
            const res = await backend.getMeParent();
            await checkResponseStatus(200, res);
            const meParent = await res.json();
            commit('setMeParent', meParent);

            dispatch('translation/setTranslationAllowed', true, { root: true });

            return meParent;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async createParent({ commit, dispatch }, params) {
        try {
            const res = await backend.postParent(params);
            await checkResponseStatus(201, res);
            commit('pushParentToState', res);
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editParent({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchParent(id, params);
            if (res.status === 409) {
                return 409;
            }else{
                const parent = await res.json();
                commit('setMeParent', parent);
                return parent;
            }        
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteParent({ commit, dispatch }, id) {
        try {
            const res = await backend.deleteParent(id);
            if (res.status === 204) {
                return 204;
            }
            return await res.json();
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getChildTeacher({ commit, dispatch }, childId) {
        try {
            const res = await backend.getChildTeacher(childId)
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },
}

export default {
    state,
    getters,
    mutations,
    namespaced: true,
    actions,
};
