import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const state = {
    newFileId : '',
};

const mutations = {
    // communicates to EditorBaseComponent, when a (new) File has been uploaded and granted a new FileId.
    setNewFileId(state, value) {
        state.newFileId = value;
    },
};

const actions = {

  async getUploadMessages ({ commit, dispatch }, params) {

    try {
      const res = await backend.getUploadMessages(params);
      await checkResponseStatus(200, res);
      const uploadMessages = await res.json();
      return uploadMessages;
    }
    catch(err) {
      console.error(err);
            return err?.response?.status;
    }
  },

    async getUploadMessageEntry ({ commit }, uploadMessageId) {
        try {
            const res = await backend.getUploadMessageEntry(uploadMessageId);
            await checkResponseStatus(200, res);
            const uploadMessages = await res.json();
            return uploadMessages;
        }
        catch(err) {
            return err.response.status;
        }
    },

  async getUploadMessageAudio ({ commit, dispatch }, {fileId }) {
    try {
      const res = await backend.getUploadMessage(fileId);
      await checkResponseStatus(200, res);
      return await res.json();
    } catch (e) {
      return e.response.status;
    }
  },

  async createUploadMessages ({ commit, dispatch }, params) {

    try {

      const res = await backend.postUploadMessages(params);
      const uploadMessages = await res.json();

      return uploadMessages;
    }
    catch(err) {
        console.error(err);
        return err?.response?.status;
    }
  },

  async postPairing ({ commit, dispatch }, body) {
    try {

        const res = await backend.postUploadMessagesPairing(body);
        const fullRes = await res.json();
        return fullRes;
    }
    catch(err) {
        console.error(err);
        return err?.response?.status;
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
