<template>
    <div
        @click="$emit('focusMe')"
    >
        <div
            class="d-flex"
            style="height: 100%; width: 100%;"
        >
            <audio
                v-if="audioUrl && audioUrl.length > 0"
                controls
                :style="`border: ${accountRole === 'teacher' ? '1px solid grey' : 'none'};`"
                :class="{ 'soft-box-shadow': accountRole === 'teacher' }"
                aria-label="Audio Datei"
                :tabindex="tabIndex"
            >
                <source :src="audioUrl">
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import { mapGetters } from "vuex";

export default {
  name: "EditorAudioObject",
  props: {
    element: {type: Object, required: true},
    canvasWidth: {type: Number, required: true},
    canvasHeight: {type: Number, required: true},
    tabIndex: { type: Number, required: false, default: 0 },},
  data: () => ({
    audioUrl: "",
  }),
  computed: {
    ...mapGetters("auth", [ 'accountRole' ]),
  },
  mounted() {

    if (!this.element.fileUrl || !this.element.fileBlob)
      this.loadAudio();
    else
      this.audioUrl = this.element.fileUrl;
  },
  methods: {
    async loadAudio() {
      // fileUrl: url, fileBlob: blob,
      const res = await backend.getUploadMessage(this.element.start);
      const blob = await res.blob();
      this.element.fileBlob = blob;
      this.element.fileUrl = window.URL.createObjectURL(blob);
      this.audioUrl = this.element.fileUrl;
    },
  }
}
</script>

<style scoped lang="scss">
</style>
