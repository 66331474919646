<template>
    <div>
        <div class="widgetAlign">
            <div
                class="whiteBg widgetContainer"
                style="border-radius: 16px !important"
                :style="hideNavbar ? 'max-height: 95vh !important; height: 95vh;':''"
            >
                <!-- Clickable badge -->
                <div
                    v-if="!selectedChatRoomId || (!accountPinValid)"
                    style="z-index: 3;"
                >
                    <ClickableBadge
                        :event-list="chatBadgeEventList"
                        :small-event-list="smallEventList"
                        :delete-button="true"
                        usage="chat"
                        @badgeClicked="chatBadgeClicked"
                        @clearBadges="clearChatBadges"
                    />
                </div>

                <!--Header of Chat-->
                <div
                    v-if="accountRole === 'maintainer' && (usePin && !accountPinValid)"
                    class="widgetHeader"
                >
                    <img
                        :src="chatIcon"
                        alt
                        class="iconToWhite mr-2 ml-4"
                        style="height: 20px"
                        @click="readWidgetInfoText()"
                    >
                    <h1
                        tabindex="0"
                        class="mb-0"
                        style="font-size: larger"
                        @click="readWidgetInfoText()"
                    >
                        Chat
                    </h1>

                    <div style="flex: auto">
                        <!-- Spacer, fill empty space -->
                    </div>
                    <!-- <ToggleChatNotificationsButton /> -->

                    <img
                        :src="infoIcon"
                        alt
                        class="mr-2"
                        style="height: 15px"
                        @click="readWidgetInfoText()"
                    >
                </div>

                <v-overlay
                    v-if="accountRole === 'maintainer' && (usePin && (!accountPinValid)) && !dataLoading"
                    :color="'white'"
                    opacity="1.0"
                    class="overlaySize"
                    @click="pinDialog = true"
                >
                    <div
                        class="d-flex flex-column justify-space-around align-center"
                    >
                        <img
                            class="icon30 iconToRed"
                            style="position: relative; margin: auto;"
                            alt="gesperrt"
                            :src="lockIcon"
                        >
                        <p style="color:black">
                            Klicken um Chats anzuzeigen
                        </p>
                    </div>
                </v-overlay>
                <ChatWidgetGroupListV2
                    v-else-if="!selectedChatRoomId"
                    ref="chatList"
                    :data-loading="dataLoading"
                    :current-child="currentChild"
                    @roomSelected="handleRoomSelected"
                    @chatBadge="$emit('chatBadge')"
                    @chatEntryCount="chatEntryCount"
                    @clearAllBadge="$emit('clearAllBadge')"
                />
                <ChatWidgetGroupChatV2
                    v-else
                    :current-child="currentChild"
                    :chat-room-id="selectedChatRoomId"
                    :show-tooltips="showTooltips"
                    :current-picture="currentPictureObject"
                    :usage="usage"
                    @exitRoom="handleExitRoom"
                    @deleteChatRoom="deleteChat"
                    @leaveChatRoom="leaveChat"
                />
            </div>
        </div>
        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
        <PinDialog
            :show-pin-dialog="pinDialog"
            :account-has-pin="accountHasPin"
            :account="account"
            :usage="'chat'"
            @close="pinDialog = false"
            @accountPinValid="()=>{pinDialog = false}"
        />
    </div>
</template>

<script>
import ChatWidgetGroupListV2 from "./ChatWidgetGroupListV2";
import ChatWidgetGroupChatV2 from "./ChatWidgetGroupChatV2";
import * as BackendApi from "../../api/backend";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import ClickableBadge from "@/components/Utils/ClickableBadge";

import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import lockIcon from '@/assets/Icons/lock-closed.svg';

import ToggleChatNotificationsButton from "./Components/ToggleBrowserChatNotifications/ToggleChatNotificationsButton";
import EventBus from "../../util/eventBus";

import BadgeExplanations from '../Utils/BadgeExplanations.vue';
import PinDialog from "@/components/PinDialog.vue";
export default {
    name: "ChatWidgetV2",
    components: {
        ChatWidgetGroupChatV2,
        ChatWidgetGroupListV2,
        // ToggleChatNotificationsButton,
        ClickableBadge,
        // BadgeExplanations,
        PinDialog,
    },
    props: {
        showTooltips: { required: false, default: true },
        currentChild: { required: false, type: Object },
        usage: { required: false, type: String, default:''},
        reset: { required: false, type: Boolean}
    },
    data: () => ({
        chatIcon,
        infoIcon,
        lockIcon,

        selectedChatRoomId: null,
        person: {
            accessibility: {
                screenreader: false,
            },
        },
        devices: [],
        currentPictureObject: null,
        eventList: [],
        dataLoading: true,
        // accountPinIsValid: false,
        pinDialog: false,
        accountHasPin: false,
    }),
    computed: {
        ...mapGetters("accounts", ["accounts"]),
        ...mapState("translation", ["targetLang"]),
        ...mapState('utilUnpersistent', ['hideNavbar']),
        ...mapGetters("auth", ["accountRole",'accountPinValid','diaryPinActivated']),
        ...mapState("auth", ["deviceToken", "account"]),
        ...mapGetters("accountSets", ["accountSets", "accountSetsById"]),
        ...mapGetters("chat", ["chatRoomEventByFile","chatBadgeEventList"]),
        ...mapState('util', ['windowWidth']),
        usePin(){
            return this.diaryPinActivated;
        },
        mappedDevices() {
            return this.devices.map((device) => ({
                ...device,
                isThisDevice: device.token === this.deviceToken,
                lastLoginPretty: new Date(device.lastLoginTime).toLocaleTimeString(),
            }));
        },
        thisDevice() {
            return this.mappedDevices.find((device) => device.isThisDevice) || {};
        },
        badgeEventList() {
            this.$emit('updateMessageCount', this.eventList.length);
            return this.eventList;
        },

        smallEventList() {
            let smallEventList = [];
            let roomId = '';
            for (let i = 0; i < this.chatBadgeEventList.length; i++) {
                if(roomId !== this.chatBadgeEventList[i].chatRoom) {
                    roomId = this.chatBadgeEventList[i].chatRoom;
                    smallEventList.push(this.chatBadgeEventList[i]);
                }
            }
            return smallEventList;
        }
    },
    watch:{
        reset(newVal, oldVal){
            if(newVal && newVal === true ){
                this.selectedChatRoomId = null;
                this.$emit('chatRemounted');
            }
        }
    },
    async mounted() {
        const t0 = performance.now();
        await this.loadDevices();
        window.speechSynthesis.getVoices()
        this.requestPerson();
        // this.calculateBadge();
        EventBus.$off('new-thumbnail-chat');
        EventBus.$on('new-thumbnail-chat', async (fileId) => {
            const foundChatEvent = this.chatRoomEventByFile(fileId);
            if (!foundChatEvent) {
                return;
            }
            const response = await this.getChatFileThumbnail(
                {
                    chatRoomId: foundChatEvent.chatRoom,
                    chatRoomEventId: foundChatEvent._id,
                });
            const url =  URL.createObjectURL(response.blob);
            const loadedFileType = response.blob.type;
            this.setChatRoomEventThumbnail({
                chatRoomEventId: foundChatEvent._id,
                url,
                loadedFileType,
            });
            EventBus.$emit('thumbnail-chat-ready', foundChatEvent._id);
        });
         const t1 = performance.now();
        console.debug(`ChatMounted in: ${t1 - t0} ms.`);
        this.$emit('chatMounted');
        this.dataLoading = false;
    },
    methods: {
        ...mapActions("translation", [
            "setTranslatedText",
            "showTranslation",
            "translateToTargetLang",
        ]),
        ...mapActions("chat", ["getChatFileThumbnail"]),
        ...mapMutations("chat", ["setChatRoomEventThumbnail",'clearAllChatBadges']),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("chat", ["fetchChats", "deleteChatRoom", "leaveChatRoom", "updateChatRoomLastSeenTimestamp"]),
        ...mapMutations("badges", ["setChatBadge"]),
        ...mapActions("accounts", ["getDevices"]),

        async loadDevices(update) {
            this.devices = await this.getDevices({
                accountId: this.account._id,
                update
            });
        },

        // async subscribe() {
        //     navigator.serviceWorker.getRegistrations().then(async (registrations) => {
        //         // Register Push
        //         const subscription = await registrations[0].pushManager.subscribe({
        //             userVisibleOnly: true,
        //             applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
        //         });
        //         await backend.subscribe({
        //             subscription,
        //             deviceToken: this.deviceToken,
        //         });
        //     });
        //     this.showSnackbar({
        //         message: 'Benachrichtigungen aktiv',
        //         color: 'info'
        //     })
        // },
        async deleteChat(chatRoomId) {
            // Close group chat
            this.selectedChatRoomId = null;

            const deleteResult = await this.deleteChatRoom(chatRoomId);
            if (deleteResult.status === 204) {
                this.showSnackbar({
                    message: "Chatraum erfolgreich gelöscht",
                    color: "success",
                });
            } else {
                this.showSnackbar({
                    message: "Fehler beim Löschen des Chatraums",
                    color: "error",
                });
            }
        },
        async leaveChat(chatRoomId) {
            // Close group chat
            this.selectedChatRoomId = null;

            const deleteResult = await this.leaveChatRoom(chatRoomId);
            if (deleteResult.status === 204) {
                this.showSnackbar({
                    message: "Chatraum erfolgreich verlassen",
                    color: "success",
                });
                await this.fetchChats();
            } else {
                this.showSnackbar({
                    message: "Fehler beim Verlassen des Chatraums",
                    color: "error",
                });
            }
        },
        async requestPerson() {
            if (this.accountRole === "pupil") {
                this.person = await this.getMePupil();
            }
        },
        async handleExitRoom() {
            this.selectedChatRoomId = null;
            // document.getElementById("gruppenHeading").focus();
        },

        // async changeRoomStatus(room, isOpen) {
        //     if (room.isOpen === isOpen) {
        //         return;
        //     }
        //     this.chatRooms = this.chatRooms.map((el) => {
        //         if (el.roomId === room.roomId) {
        //             el.isOpen = isOpen;
        //         }
        //         return el;
        //     });
        // },

        async handleRoomSelected(chatRoom) {
            if(!chatRoom._id){
                return
            }
            if(chatRoom && chatRoom.roomPictureObject !== 'groupPicture'){
                if (chatRoom.roomPictureObject?.color) {
                    this.currentPictureObject = chatRoom.roomPictureObject;
                } else {
                    this.currentPictureObject = { uploadedPicture: true, src: chatRoom.roomPictureObject };
                }
            }
            else{
                this.currentPictureObject = null;
            }
            this.selectedChatRoomId = chatRoom._id;
            await this.$nextTick();
            document.getElementById("backButton").focus();
        },

        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ("speechSynthesis" in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return;
                }

                let text;
                if (this.accountRole === "pupil") {
                    text = "Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.";
                } else if (this.accountRole === "teacher") {
                    text = "Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.";
                } else if (this.accountRole === "parent") {
                    text =
                        "Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.";
                } else {
                    text = "Im Chat können Sie sich mit Ihren Kontakten austauschen.";
                }

                if (this.targetLang !== "de") {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text,
                    });
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000);

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (
                    window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)
                ) {
                    return true;
                }
            }

            return false;
        },

        chatEntryCount(eventList) {
            this.eventList = eventList.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1;});
            this.calculateBadge();
        },

        // scroll chat into view and set is as seen
        chatBadgeClicked(neededEvent) {
            // find referenced chat element
            const element = this.$refs.chatList.$refs[`chat${neededEvent.chatRoom}`][0];
            // scroll element into view
            element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            this.$nextTick(() => {
                if (element.$el) {
                    element.$el.classList.remove('badgeEventVisualize');
                    element.$el.classList.add('badgeEventVisualize');

                    setTimeout(() => {
                        element.$el.classList.remove('badgeEventVisualize');
                    }, 1050);
                }
            });
        },
        // calculateBadge() {
        //     if (this.eventList.length <= 0) {
        //         this.setChatBadge('');
        //     } else {
        //         this.setChatBadge(this.eventList.length);
        //     }
        // },
        async clearChatBadges() {
            try {
                this.clearAllChatBadges();
                this.smallEventList.map(async (event) => {
                    await this.updateChatRoomLastSeenTimestamp(event.chatRoom);
                })
                this.showSnackbar({
                    message: 'Benachrichtigungen wurden erfolgreich als gesehen markiert!',
                    color: 'success'
                });
            } catch(error) {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist aufgetreten.',
                    color: 'error'
                });
                console.error(error);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.chat-header {
    background-color: var(--v-chatColor-base);
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    color: white;
}

.chat-header-btn {
    background-color: #f8f8f880 !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    max-height: calc(80vh - 75px);
}

.whiteBg {
    background-color: #ffffff;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.widgetHeader {
    background-color: var(--v-chatColor-base);
    border-radius: 15px 15px 0 0;
    flex: 0 0 55px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    display: flex;
    align-items: center;
}
.overlaySize {
     width: 30vw;
    max-width: 30vw;
    min-height: calc(82vh - 56px);
    max-height: 82vh;
    border-radius: 0 0 16px 16px !important;
    position: relative;
    z-index: 2!important;
}
@media only screen and (max-width: 900px) {
    .overlaySize {
        width: 90vw;
        max-width: 90vw;
    }
}
</style>
