import translation from '../store/modules/translation'
import store from "@/store";

const isSupported = () => {
    return 'speechSynthesis' in window;
};
const textToSpeech = async (text, language = 'de', onStartCallback = null, onEndCallback = null) => {
    if (!isSupported || !text) return false;
    // Cancel any ongoing speech if necessary
    let targetLang = language;
    if(store.getters['auth/accountRole'] === 'teacher'){
        targetLang = 'de'
    }

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
        if (onEndCallback) {
            onEndCallback();
        }
    } else {
        // Handle language-specific translation logic
        const translatedText = await handleTranslation(text, targetLang);
        
        // Split the text into smaller chunks
        const textChunks = splitTextIntoChunks(translatedText, 180); // 200-character chunks
        
        // Speak each chunk sequentially
        const speakChunk = (index) => {
            if (index >= textChunks.length) {
                if (onEndCallback) onEndCallback();
                return;
            }

            const msg = new SpeechSynthesisUtterance(textChunks[index]);
            msg.lang = getVoiceLanguage(targetLang);

            msg.onstart = () => {
                if (onStartCallback) onStartCallback(msg.text);
            };
            msg.onend = () => {
                speakChunk(index + 1); // Move to the next chunk
            };

            window.speechSynthesis.speak(msg);
        };

        // Start speaking the first chunk
        speakChunk(0);

        return translatedText;
    }

    return true;
};

// Helper function to handle translations
const handleTranslation = async (text, language) => {
    let translatedText = text;

    if (language !== 'de' && store.getters['auth/accountRole'] !== 'teacher') {
        translatedText = await translation.actions.translateToTargetLang(
            { commit: null, dispatch: null },
            { targetLang: language, textToTranslate: text }
        );
    } else if (language === 'de' && store.getters['auth/accountRole'] === 'teacher') {
        translatedText = await translation.actions.translateToTargetLang(
            { commit: null, dispatch: null },
            { targetLang: 'de', textToTranslate: text }
        );
    }

    return translatedText;
};

// Helper function to split text into smaller chunks
const splitTextIntoChunks = (text, chunkSize) => {
    const chunks = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
        let chunk = text.substring(currentIndex, currentIndex + chunkSize);

        // Prefer splitting at punctuation marks (.,?!)
        const punctuationRegex = /[.,?!]/;
        const lastPunctuationIndex = chunk.search(punctuationRegex);
        const lastSpaceIndex = chunk.lastIndexOf(' ');

        if (lastPunctuationIndex !== -1 && lastPunctuationIndex < chunkSize - 1) {
            chunk = chunk.substring(0, lastPunctuationIndex + 1);  // Split after punctuation
        } else if (lastSpaceIndex > -1) {
            chunk = chunk.substring(0, lastSpaceIndex);  // Split at the last word boundary
        }

        chunks.push(chunk.trim());

        // Move index forward (accounting for spaces and punctuation)
        currentIndex += chunk.length + 1;
    }

    return chunks;
};

// Helper function to get the correct language for speech synthesis
const getVoiceLanguage = (language) => {
    if (navigator.userAgent.includes('Edg/')) {
        return language;
    }

    const supportedVoices = window.speechSynthesis.getVoices();
    if (supportedVoices.some(voice => voice.lang.slice(0, 2) === language)) {
        return language;
    } else if (language === 'uk') {
        return 'ru'; // Use Russian for Ukrainian
    } else if (['ro', 'tr'].includes(language)) {
        return 'en'; // Use English for Romanian and Turkish
    } else {
        const errorText = "Dein Browser besitzt kein Sprachpaket für die ausgewählte Sprache. " +
                          "Bitte lade das passende Sprachpaket herunter oder verwende den Microsoft Edge Browser.";
        store.commit('snackbar/showSnackbar',
        {
            message: errorText,
            color:"error",
            timeout: 8000,
            showTop: true,
        },{root:true}
        )
        return null;
     }
};

export {
    textToSpeech,
    isSupported,
}
