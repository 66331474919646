const allowedExtensionsForAudioPlayer = [
    "mp3",
    "mp4",
    "mpeg",
    "aac",
    "asc",
    "ogg",
    "wav",
    "x-wav",
];

const allowedExtensionsForLibre = [
    'docx',
    'pptx',
    'xlsx',
    'doc',
    'ppt',
    'xls',
    'rtf',
    'txt',
    'odp',
    'ods',
    'odt'
];

const allowedExtensionsForEditor = [
    'pdf',
    'png',
    'jpg',
    'jpeg',
    'tiff',
    'tif',
    'bmp',
    'HEIC'
];

const allowedExtensionsForVideoPlayer = [
    "mp4",
    "mov",
    "bmpeg",
    "webm",
]

const allowedMimeTypesForAudioPlayer = [
    "audio/mp3",
    "audio/mpeg",
    "audio/mp4",
    "audio/MP4A-LATM",
    "audio/asc",
    "audio/mpeg",
    "audio/aac",
    "audio/ogg",
    "audio/wav",
    "audio/x-wav",
    // "application/octet-stream",
];

const allowedMimeTypesForLibre = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-excel',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'text/plain',
];

const allowedMimeTypesForEditor = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/tiff',
    'image/bmp',
    'image/heic'
];

const allowedMimeTypes = [
    "application/mp4",
    "application/pdf",
    "application/rtf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel xlsx
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word docx
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint
    "application/vnd.sealed.xls",
    "application/vnd.sealed.ppt",
    "application/vnd.sealed.tiff",
    "application/vnd.ms-xpsdocument",
    "application/xls",
    "application/x-zip-compressed",
    "application/zip",
    "application/rtf",
    "application/msword",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.graphics",
    "application/octet-stream",

    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/tiff",
    "image/tiff-fx",
    "image/png",
    "image/jp2",
    "image/svg+xml",
    "image/gif",
    'image/heic',

    "audio/mp3",
    "audio/mpeg",
    "audio.mp4",
    "audio/MP4A-LATM",
    "audio/asc",
    "audio/mpeg",
    "audio/aac",
    "audio/ogg",
    "audio/wav",
    "audio/x-wav",

    "video/mp4",
    "video/MP4V-ES",
    "video/vnd.dece.mp4",
    "video/vnd.nokia.mp4vr",
    "video/vnd.uvvu.mp4",
    "video/vnd.sealedmedia.softseal.mov",
    "video/BMPEG",
    "video/mpeg4-generic",
    "video/quicktime",
    "video/webm",

    "text/csv",
    "text/rtf",
    "text/plain",
    "text/vnd.ascii-art",
    "text/csv",
    "text/csv-schema"
];

export {
    allowedMimeTypes,
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre,
    allowedExtensionsForVideoPlayer,
    allowedMimeTypesForAudioPlayer,
    allowedMimeTypesForLibre,
    allowedMimeTypesForEditor,
}
