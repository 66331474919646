import Vue from 'vue';
import QrCodeVue from "qrcode.vue";

// https://css-tricks.com/creating-vue-js-component-instances-programmatically/
// Encode a string as a qr code image data url
const encodeAsQrCodeImage = ({ size = 200, value }) => {
    // Crea QrCodeVue instance
    const qrCodeVueClass = Vue.extend(QrCodeVue);
    const qrCodeVueInstance = new qrCodeVueClass({
        propsData: { 
            value, 
            size 
        }
    });

    // Run mount to render qrCode image
    qrCodeVueInstance.$mount();

    // return image as dataUrl
    return qrCodeVueInstance.$refs["qrcode-vue"].toDataURL(
        "image/png"
    );
};

export default {
    encodeAsQrCodeImage
}