import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import Vue from 'vue';

const defaultState = {
    timeslotsInvalid: false,
    timeslots: []
};

const mutations = {
    setTimeslotsInvalid : (state, status) => {
        state.timeslotsInvalid = status;
    },
    setTimeslots : (state, timeslots) => {
        Vue.set(state, 'timeslots', timeslots);
    },
    clearTimeslots : (state) => {
        state.timeslots = [];
    },
    // Methods primarily used for live updates
    spliceTimeslot: (state, slotIdToSpliceOut) => {
        state.timeslots.splice(state.timeslots.findIndex(slot => slot._id === slotIdToSpliceOut), 1);
    },
    addOrUpdateTimeslot: (state, slot) => {
        slot.end = new Date(Date.parse(slot.end));
        slot.start = new Date(Date.parse(slot.start));
        slot.timePickerStart = ('0' + slot.start.getHours()).slice(-2) +
            ':' + ('0' + slot.start.getMinutes()).slice(-2);
        slot.timePickerEnd = ('0' + slot.end.getHours()).slice(-2) +
            ':' + ('0' + slot.end.getMinutes()).slice(-2);

        // now this should update better
        const index = state.timeslots.findIndex(el => el._id === slot._id);
        if (index >= 0)
            Vue.set(state.timeslots, index, slot);
        else
            state.timeslots.push(slot);
    },
};

const getters = {
    getTimeslotsInvalid: state => state.timeslotsInvalid,
    timeslots: (state) => {
        const timeslots = state.timeslots;
        let returningTimeslots = [];
        timeslots.map((timeslot) => {
            const obj = {
                ...timeslot,
                end: new Date(timeslot.end),
                start: new Date(timeslot.start),
            }
            returningTimeslots.push(obj);
        });
        return returningTimeslots;
    },
    timeslotsByGroupId: (state, getters, rootState, rootGetters) => {
        // get groups and combine them with timeslots
        const pulledGroups = rootGetters['groups/groups'];
        return state.timeslots.reduce((acc, slot) => {
            pulledGroups.forEach(group => {
               if (!acc[group._id]) {
                   acc[group._id] = [];
               }
               if (group.timeslots && group.timeslots
                   .find(item => (item._id || item) === slot._id)) {
                   acc[group._id].push(slot);
               }
            });
            return acc;
        }, {});
    },
    timeslotsForMultipleGroupIds: (state, getters) => groupIds => {
      if (groupIds && groupIds.length > 0) {
          let timeslotsForMultipleGroups = [];
          groupIds.forEach(groupId => {
              timeslotsForMultipleGroups = [
                  ...timeslotsForMultipleGroups,
                  ...getters.timeslotsByGroupId[groupId]
              ];
          });
          return timeslotsForMultipleGroups;
      }
      return [];
    },
    timeslotsById: state => state.timeslots.reduce((acc, slot) => {
        acc[slot._id] = slot;
        return acc;
    }, {}),
};

const actions = {
    setTimeslotsStatus({ commit }, status) {
        commit('setTimeslotsInvalid', status);
    },
    async createSlot ({ commit, dispatch }, params) {
        try {
            const res = await backend.postTimeslot(params);
            await checkResponseStatus(201, res);
            const slot = await res.json();
            commit('addOrUpdateTimeslot',slot)
            return slot;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editSlot ({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchTimeslot(id, params);
            if(res.status === 409) {
                return 409;
            }
            const account = await res.json();
            return account;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteSlot ({ commit, dispatch }, params) {
        try {
            const res = await backend.deleteTimeslot(params.id, params.groupId);
            if(res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            return true;
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },

    async getSlots ({ commit, getters, rootGetters }, update = false) {
        try {
            if (!update && getters.timeslots.length > 0) {
                return getters.timeslots;
            }
            const res = await backend.getTimeslots();
            await checkResponseStatus(200, res);
            const slots = await res.json();

            const enhancedSlots = slots.map(slot => ({
                ...slot,
                start: new Date(slot.start),
                end: new Date(slot.end),
            }))
            commit('setTimeslots', enhancedSlots);

            return JSON.parse(JSON.stringify(enhancedSlots));
        } catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getSlotsByGroups ({ commit, dispatch }, groupIds) {
        try {
            const body = {
                groupIds,
            };
            const res = await backend.getTimeslotsByGroups(body);
            await checkResponseStatus(200, res);
            const slots = await res.json();
            await Promise.all(slots.map(async (slot) => {
                if (slot) {
                    slot.start = new Date(slot.start);
                    slot.end = new Date(slot.end);
                }
            }));
            return slots;
        } catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

}

export default {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};
