<template>
    <v-row no-gutters> 
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-card 
                    class="pinboardContentPlus mb-10 d-flex justify-center mt-3"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    @click="addNewContentCard"
                >
                    <img
                        class="iconToDunkelgrau"
                        style="max-width: 30px; align-self: center;"
                        alt="Neue Notiz anfangen"
                        :src="plusIcon"
                    >
                </v-card>
            </template>
            <span>Notiz hinzufügen</span>
        </v-tooltip>
    </v-row>
</template>

<script>
import plusIcon from '@/assets/Icons/plus.svg';

export default {
    name: 'PinboardCardElementPlus',
    props: {
        // eslint-disable-next-line
        position_y: { required: true, type: Number },
        // eslint-disable-next-line
        position_x: { required: true, type: Number },
        cardColor: { required: false, type: String },
        createContentCard: { required: true, type: Function },
    },
    data: () => ({
        plusIcon
    }),
    methods: {
        addNewContentCard() {
            this.createContentCard('', this.position_x, this.position_y, this.cardColor);
        }
    }
}
</script>

<style scoped lang="scss">
.pinboardContentPlus {
  background-color: #dddcdd;
  font-size: 30px;
  width: 220.5px;
  height: 50px;
}

@media only screen and (max-width: 599px) {
    .pinboardContentPlus {
        width: 72vw;
    }
}
</style>
