<template>
    <v-dialog
        width="428"
        height="100%"
        :value="showDialog"
    >
        <v-card
            class="cardStyle"
            style="height: 90vh; overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="d-flex align-center justify-space-between"
                style="height: 56px; background-color: var(--v-headerblue-base)"
            >
                <div class="d-flex align-center">
                    <img
                        v-if="editMode"
                        :src="shareIcon"
                        class="iconToWhite mr-2"
                        style="height: 25px; width: 25px; margin-top: -5px"
                    >
                    <p style="color: white">
                        {{ (editMode ? "Teilen mit" : "Teilnehmer") }}
                    </p>
                </div>
                <div
                    class="d-flex align-center justify-space-between"
                >
                    <v-tooltip
                        v-if="editMode"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                class="mr-2"
                                v-bind="attrs"
                                width="25"
                                height="25"
                                color="success"
                                v-on="on"
                                @click="saveParticipants(true)"
                            >
                                <img
                                    :src="saveIcon"
                                    class="icon20 iconToWhite"
                                    alt="speichern"
                                >
                            </v-btn>
                        </template>
                        <span>Speichern</span>
                    </v-tooltip>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="closeDialog"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(80vh - 55px - 13px)"
            >
                <div
                    v-if="!editMode"
                    class="d-flex align-end"
                >
                    <img
                        :src="item.uploadedIcon ? customIcon : chooseIcon(item.icon)"
                        class="ml-2 boardIcon"
                        style="margin-bottom: 7px"
                        :class="item.uploadedIcon ? 'pa-0' : ''"
                        :style="{'background-color': item.color}"
                        aspect-ratio="1.5"
                        :alt="item.title"
                    >
                    <div>
                        <h1
                            class="formHeader"
                            style="margin-left: 22px; font-size: 20px"
                        >
                            Pinnwandname:
                        </h1>
                        <p
                            class="mb-2"
                            style="max-width: 500px; margin-left: 35px; font-size: 18px"
                        >
                            {{ itemName }}
                        </p>
                        <v-tooltip
                            bottom
                            max-width="400px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="infoIcon"
                                    class="icon20"
                                    alt="Erklärung"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                            </template>
                            <span>Wenn 'Alle' augewählt werden, haben alle Nutzer Zugriff auf die Pinnwand. Ansonsten stehen in der unteren Auflistung alle Nutzer sowie Gruppen/Klassen zur Auswahl und können mit dem Suchfeld gefunden werden.</span>
                        </v-tooltip>
                    </div>
                </div>
                <h1
                    v-if="editMode"
                    class="mt-2"
                    style="font-size: 18px"
                >
                    Teilnehmer bearbeiten
                    <v-tooltip
                        bottom
                        max-width="400px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                :src="infoIcon"
                                class="icon20"
                                alt="Erklärung"
                                v-bind="attrs"
                                v-on="on"
                            >
                        </template>
                        <span>Wenn 'Alle' augewählt werden, haben alle Nutzer Zugriff auf die Pinnwand. Ansonsten stehen in der unteren Auflistung alle Nutzer sowie Gruppen/Klassen zur Auswahl und können mit dem Suchfeld gefunden werden.</span>
                    </v-tooltip>
                </h1>
                <div
                    v-if="editMode"
                    class="d-flex"
                    style="flex-direction: row; align-items: center"
                >
                    <p>
                        Alle:
                    </p>
                    <v-select
                        v-model="selectAll"
                        class="pl-4 mt-1"
                        placeholder="Keine Aktion ausgewählt"
                        :items="['Sehen', 'Bearbeiten', 'Kein Mitglied']"
                        rounded
                    />
                </div>
                <div class="d-flex align-center mt-5">
                    <v-text-field
                        v-if="editMode"
                        v-model="searchAccountSetName"
                        filled
                        dense
                        hide-details
                        clearable
                        placeholder="Teilnehmer suchen"
                        style="max-width: 500px"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="pupilIcon"
                                class="icon20 iconToGrey mr-2 mb-2"
                                alt=""
                            >
                        </template>
                    </v-text-field>
                    <p
                        v-else
                        style="font-size: 20px"
                    >
                        Teilnehmerliste:
                    </p>
                </div>
                <vue-custom-scrollbar
                    class="scroll-area"
                >
                    <v-data-table
                        :model-value="selectedAccountSetIds"
                        :headers="headers"
                        :items="filteredSelectableAccountSets"
                        item-key="id"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td
                                    style="min-width: 109px"
                                >
                                    {{ item.displayName }}
                                </td>
                                <td>
                                    <v-select
                                        v-model="item.role"
                                        :items="['Sehen', 'Bearbeiten', 'Moderator', 'Kein Mitglied']"
                                        :disabled="!editMode"
                                        @input="saveParticipants()"
                                    />
                                </td>
                                <td
                                    style="min-width: 109px"
                                >
                                    {{ item.joinedBy }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </vue-custom-scrollbar>
            </v-card-text>
            <div
                v-if="editMode"
                class="d-flex flex-column justify-center"
            >
                <div
                    class="d-flex flex-column"
                    style="align-items: center"
                >
                    <p>{{ 'oder ' + (this.account.role === 'pupil' ? 'verschicke' : 'verschicken Sie') + ' einen Einladungslink' }}</p>
                    <v-btn
                        class="mr-2 mb-4 toolSelector"
                        color="gruen"
                        min-width="90px"
                        elevation="0"
                        style="border-radius: 8px; max-width: 86%"
                        @click="showExternalDialog = true"
                    >
                        <p
                            class="text-capitalize"
                            style="font-size: large; color: white; margin-left: 4px"
                        >
                            Einladungslinks
                        </p>
                    </v-btn>
                </div>
            </div>
        </v-card>
        <PinboardExternalDialog
            v-if="showExternalDialog"
            :pinboard="item"
            :load-pinboard="pinboardBase ? loadPinboard : updatePinboard"
            @close="showExternalDialog = false"
        />
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";

import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import pupilIcon from "../../assets/Icons/pupil.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import pinIconWeiss from "../../assets/Icons/FaceliftIcons/pin_alt_weiss.svg";
import englischIconWeiss from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import politikIconWeiss from "../../assets/Icons/FaceliftIcons/f-politik-weiss.svg";
import erdkundeIconWeiss from "../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg";
import religionIconWeiss from "../../assets/Icons/FaceliftIcons/f-religion-weiss.svg";
import bioIconWeiss from "../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg";
import chemieIconWeiss from "../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg";
import deutschIconWeiss from "../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import geschichteIconWeiss from "../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg";
import matheIconWeiss from "../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg";
import musikIconWeiss from "../../assets/Icons/FaceliftIcons/f-musik-weiss.svg";
import physikIconWeiss from "../../assets/Icons/FaceliftIcons/f-physik-weiss.svg";
import sportIconWeiss from "../../assets/Icons/FaceliftIcons/f-sport-weiss.svg";
import wirtschaftIconWeiss from "../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg";
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';

import shareIcon from '@/assets/Icons/group.svg';
import PinboardExternalDialog from "./PinboardExternalDialog";

export default {
    name: "PinboardParticipantsDialog",
    components: {
        vueCustomScrollbar,
        PinboardExternalDialog,
    },
    props: {
        editMode: { required: false, type: Boolean, default: false },
        editId: {required: false, type: String},
        item: { required: false, type: Object },
        fileName: { required: false, type: String },
        updatePinboard: { required: false, type: Function, default: () => {} },
        memberships: { required: true, type: Array },
        loadPinboard: { required: false, type: Function, default: () => {} },
        pinboardBase: { required: false, type: Boolean, default: false }, // If you come from PinboardBase.vue
    },
    data: () => ({
        infoIcon,
        saveIcon,
        pupilIcon,
        cancelIcon,
        showDialog: true,
        itemName: '',
        searchAccountSetName: "",
        selectedAccountSetIds: [],
        icons:[
            {icon: pinIconWeiss, text: "pinIconWeiss"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIconWeiss, text: "politikIcon"},
            {icon: erdkundeIconWeiss, text: "erdkundeIcon"},
            {icon: religionIconWeiss, text: "religionIcon"},
            {icon: bioIconWeiss, text: "bioIcon"},
            {icon: chemieIconWeiss, text: "chemieIcon"},
            {icon: deutschIconWeiss, text: "deutschIcon"},
            {icon: geschichteIconWeiss, text: "geschichteIcon"},
            {icon: matheIconWeiss, text: "matheIcon"},
            {icon: musikIconWeiss, text: "musikIcon"},
            {icon: kunstIconWeiss, text: "kunstIcon"},
            {icon: physikIconWeiss, text: "physikIcon"},
            {icon: sportIconWeiss, text: "sportIcon"},
            {icon: wirtschaftIconWeiss, text: "wirtschaftIcon"},
        ],
        customIcon: '',
        shareIcon,
        headers: [
            {
                text: "Mitglied",
                align: "left",
                value: "name"
            },
            { text: "Rechte" },
            { text: "beigetreten über" },
        ],
        selectAll: '',
        showExternalDialog: false,
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),

        selectableAccountSets() {
            if (this.editMode) {
                return this.accountSets
                    .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                    .map((accountSet) => ({
                        ...accountSet,
                        displayName: this.getAccountSetDisplayName(accountSet),
                        role: this.getMembershipRole(accountSet),
                        joinedBy: this.getJoinedBy(accountSet),
                    }))
                    .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"))
                    .filter((accountSet) => !accountSet.displayName.includes("Verwaltungen"))
                    .filter((accountSet) => !accountSet.displayName.includes("Alle"));
            } else {
                return this.accountSets
                    .filter((accountSet) => {
                        const newSet = this.selectedAccountSetIds.filter((accountId) => accountSet._id === accountId)
                        return newSet.length;
                    })
                    .map((accountSet) => ({
                        ...accountSet,
                        displayName: this.getAccountSetDisplayName(accountSet),
                        role: this.getMembershipRole(accountSet),
                        joinedBy: this.getJoinedBy(accountSet),
                    }))
                    .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"))
                    .filter((accountSet) => !accountSet.displayName.includes("Verwaltungen"))
                    .filter((accountSet) => !accountSet.displayName.includes("Alle"));
            }
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
        filteredSelectableAccountSets(){
            return this.selectableAccountSets.filter((set)=> set.displayName.toLowerCase().includes(this.searchAccountSetName.toLowerCase()));
       }
    },
    watch: {
        selectAll(newState) {
            this.selectableAccountSets.map((accountSet) => {
                accountSet.role = newState;
            })
        },
         searchAccountSetName(newVal){
            if(newVal === null){
                this.searchAccountSetName = '';
            }
        }
    },
    async mounted() {
        await this.fillInDialog();
        if (this.item.uploadedIcon) {
            this.customIcon = await this.getUploadedIcon(this.item.icon);
        }
        this.selectedAccountSetIds = this.item.memberships.map(
            (membership) => membership.accountSet._id
        );
    },
    methods: {
        ...mapActions("pinboard", ["getPinboard", "patchPinboard", 'postInvitation']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("uploadedIcons", ["getUploadedIcon"]),

        async fillInDialog() {
            if (!this.editMode) {
                await this.fillInPinboardname();
            }
        },

        async fillInPinboardname() {
            this.itemName = this.item.title;
        },

        getAccountSetDisplayName(accountSet) {
            if (accountSet.type === "account") {
                const account = this.accountsById[accountSet.account];
                if(account){
                    return account ? account.displayName : account._id;
                }else {
                    return 'Unbekannter Account';
                }
            } else if (accountSet.type === "groupLeaders") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Gruppenleiter`;
            } else if (accountSet.type === "groupAppointmentLeaders") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Fachlehrer`;
            } else if (accountSet.type === "groupParticipants") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Schüler`;
            } else if (accountSet.type === "groupParticipantsParents") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Eltern`;
            } else if (accountSet.type === "allTeachers") {
                return "Alle Lehrer";
            } else if (accountSet.type === "allMaintainers") {
                return "Alle Verwaltungen";
            } else if (accountSet.type === "allPupils") {
                return "Alle Schüler";
            } else if (accountSet.type === "allParents") {
                return "Alle Eltern";
            } else {
                // For debugging if there is a new type
                console.log('Problematic new type:', accountSet.type);
                return 'Unbekannt'
            }
        },

        async saveParticipants(close) {
            const accountSetsToPatch = this.selectableAccountSets.filter((accountSet) => accountSet.role !== 'Kein Mitglied');
            const otherMembership = accountSetsToPatch.map((accountSet) => ({
                accountSet: accountSet._id,
                role: this.getMembershipRoleForBackend(accountSet),
                joinedBy: accountSet.joinedBy
            }));
            const myMembership = {
                accountSet: this.myAccountSet._id,
                role: "moderator",
            };
            const pinboardResponse = await this.patchPinboard({ cardId: this.editId, body: {
                    memberships: [myMembership, ...otherMembership],
                } });
            const newPinboard = await pinboardResponse.json();
            this.updatePinboard(newPinboard, this.editId);
            if (pinboardResponse.status === 200) {
                this.showSnackbar({ message: 'Gespeichert!'});
            } else if (pinboardResponse.status === 403) {
                this.showSnackbar({ message: 'Du hast nicht die Rechte zum Bearbeiten der Pinnwand!', color: 'error'});
            } else {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen!', color: 'error'});
            }
            if (close) {
                this.closeDialog(true);
            }  
        },

        closeDialog(loadPinboard = false) {
            this.$emit("close", loadPinboard);
        },

        chooseIcon(iconName) {
            for(let i of this.icons){
                if(i.text === iconName){
                    return i.icon;
                }
            }
        },

        getMembershipRole(accountSet) {
            const foundMembership = this.memberships.find((membership) => membership.accountSet._id === accountSet._id);
            if (foundMembership) {
                const membershipRole = foundMembership.role;
                if (membershipRole === 'moderator') {
                    return 'Moderator';
                } else if (membershipRole === 'member') {
                    return 'Bearbeiten';
                } else if (membershipRole === 'visitor') {
                    return 'Sehen';
                }
            } else {
                return 'Kein Mitglied';
            }
        },

        getMembershipRoleForBackend(accountSet) {
            if (accountSet.role === 'Bearbeiten') {
                return 'member';
            } else if (accountSet.role === 'Sehen') {
                return 'visitor';
            } else if (accountSet.role === 'Moderator') {
                return 'moderator';
            }
        },

        getJoinedBy(accountSet) {
            const foundMembership = this.memberships.find((membership) => membership.accountSet._id === accountSet._id);
            if (foundMembership) {
                if (!foundMembership.joinedBy) {
                    return 'Moderator';
                }
                if (foundMembership.joinedBy !== 'Moderator') {
                    return 'Link: ' + foundMembership.joinedBy;
                }
                return foundMembership.joinedBy;
            } else {
                return undefined;
            }
        }
    },
};
</script>

<style scoped lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}

.group-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.scroll-area {
    flex: 1;
}

.formHeader {
    font-size: 16px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: var(--v-grau-base) !important;
    color: white !important;
}

.boardIcon {
    height: 50px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.iconSheet {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px !important;
    width: 60px;
    border-radius: 12px;
}

.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}
</style>
