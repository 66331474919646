import store from "@/store";
import Vue from "vue";

const defaultState = {
    weeklyView: false,
    editMode: false,
    sickMode: false,
  };

  const mutations = {
    setWeeklyView(state, value) {
      state.weeklyView = value;
    },
    setEditMode(state, value){
      Vue.set(state,'editMode' ,value);
    },
    setSickMode(state, value){
      Vue.set(state,'sickMode' ,value);
    }
  };
  const getters = {
    editMode: state => state.editMode,
  }

  const actions = {
    toggleWeeklyView({ commit, state }) {
      commit("setWeeklyView", !state.weeklyView);
      store.commit('utilUnpersistent/setBlockMobileWidgetChangeByDrag', state, { root: true });

    },
    toggleEditMode({commit,state}){
      commit("setEditMode", !state.editMode);
    },
    leaveEditMode({commit,state}){
      commit("setEditMode", false);
    },
    toggleSickMode({commit,state}){
      commit("setSickMode", !state.sickMode);
    },
    leaveSickMode({commit,state}){
      commit("setSickMode", false);
    },
    closeWeeklyView({ commit, state }) {
      commit("setWeeklyView", false);
      store.commit('utilUnpersistent/setBlockMobileWidgetChangeByDrag', false, { root: true });
    },
  };

  export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
  };
