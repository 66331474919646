import {allowedMimeTypes, allowedMimeTypesForEditor, allowedMimeTypesForLibre,
    allowedExtensionsForVideoPlayer} from "@/constants/allowedMimeTypes";
import store from "@/store";
const mime = require('mime-types');

const actions = {
    async isEditorCompatible ({ commit, dispatch }, mimetype) {
       return allowedMimeTypesForEditor.includes(mimetype);
    },
    /**
     * This method checks if a file can be opened and interpreted by LibreOffice (CollaboraOnline)
     *
     * @param mimetype mimetype of file
     * @returns {Promise<boolean>} if the file can be interpreted
     */
    async isCollaboraCompatible ({ commit, dispatch }, mimetype) {
        return allowedMimeTypesForLibre.includes(mimetype);
    },
    /**
     * This method is used to detect a file extension by using the files mimeType
     *
     * For example: "application/msword" as mimetype = "doc" as file extension
     * @param mimetype the mimeType of the file
     * @returns {Promise<*>} the file extension
     */
    async getFileExtension ({ commit, dispatch }, mimetype) {
        return mime.extension(mimetype);
    },
    /**
     * This method serves as a globalized way to check mime types in all the different file upload areas. mimeTypes.js
     * uses the constant allowedMimeTypes.js which contains a list of all the allowed mime types for eklara. This list
     * can always be updated by adding or removing your desired mime types.
     *
     * @param fileArray file array of which each of the files contains the "type" attribute which is crucial for the validation below
     * @returns {Promise<boolean|*>} if all of the uploaded files' mime types are validated for this application (or if file size is too big)
     */
    async validateMimeType ({ commit, dispatch }, fileArray) {
        try {
            // Check file size first
            const validatedFileSize = await dispatch('validateFileSize', fileArray);
            if (!validatedFileSize) return false;

            // Mimetype validation
            const validated = fileArray.every((file) => allowedMimeTypes.includes(file.type) || (file.type === "" && file.name.includes('.wscdoc')));
            if (fileArray !== '' && validated) {
                return true;
            } else {
                let message = "Dieses Dateiformat wird nicht unterstützt."
                if (fileArray.length > 1) {
                    message = "Eins oder mehrere dieser Dateiformate werden nicht unterstützt."
                }
                store.commit('snackbar/showSnackbar', {
                    color: "error",
                    message: message,
                }, { root: true });
                return false;
            }
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    /**
     * This method checks uploaded files for their sizes. Size limits for different file types can be found in the
     * first lines of the function body.
     *
     * @param fileArray file array to check
     * @returns {Promise<boolean|*>} if all of the uploaded files' sizes are validated for this application
     */
    async validateFileSize ({ commit, dispatch}, fileArray) {
        const firstLimit = 25 * 1000000; // Limit for every file type (25MB)
        const secondLimit = 100 * 1000000; // Limit for videos (100MB)
        let fileSizeTooBig = false;
        fileArray.forEach((file) => {
            if (allowedExtensionsForVideoPlayer.includes(file.type.split('/').pop().toLowerCase())) {
                if (file.size > secondLimit) {
                    fileSizeTooBig = true;
                }
            } else {
                if (file.size > firstLimit) {
                    fileSizeTooBig = true;
                }
            }
        })
        if (fileSizeTooBig) {
            let message = "Die Datei ist zu groß.";
            if (fileArray.length > 1) {
                message = "Eine dieser Dateien ist zu groß.";
            }
            store.commit('snackbar/showSnackbar', {
                color: "error",
                message: message + " Bitte komprimieren Sie diese (https://www.wintotal.de/tipp/dateien-komprimieren/)" +
                    " oder laden Sie diese bei einem Cloud-Anbieter hoch (https://www.experte.de/cloud-speicher).",
                timeout: 10000
            }, { root: true });
            return false;
        }
        return true;
    }
}

export default {
    namespaced: true,
    actions,
};
