<template>
    <quill-editor
        ref="advancedEditor"
        v-model="internalValue"
        style="font-size: 16px; overflow-y: scroll;"
        :options="pinboardCard ? editorOptionPinboard : editorOption"
        @change="onEditorChanged"
    />
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AdvancedQuillEditor",
    props: {
        value: {
            required: false,
            type: String,
            default: ""
        },
        placeholder: {
            required: false,
            default: ""
        },
        hideImageBtn: {
            required: false,
            default: false
        },
        pinboardCard: {
            required: false,
            default: false
        },
        textDown: {
            required: false,
            default: false
        },
        activeToolbar: {
            required: false,
            default: false
        },
        maxLength: {
            required: false,
            type: Number,
            default: 0
        },
    },
    data: () => ({
        toolbar: null,
        editorOption: {
            placeholder: "",
            modules: {
                toolbar: [
                    [{'size': [false, 'large', 'huge']}],
                    [{'color': []}],
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    ['link', 'image', 'video'],
                ],
            },
            theme: "snow"
        },
        editorOptionPinboard: {
            placeholder: "",
            modules: {
                toolbar: [
                    {'color': []}, 'bold', 'italic', 'link', 'video', {'size': [false, 'large', 'huge']},
                ],
            },
            theme: "snow"
        },
        toolbarHideBlocked: false,
        internalValue: "",
        firstFocus: true,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),

    },
    watch: {
        value: {
            handler(val) {
                this.internalValue = val;
                if(this.firstFocus && val !== "") {
                        setTimeout(() => {
                            document.getElementsByClassName("ql-editor")[0].blur();
                        }, 1)
                    this.firstFocus = false;
                } else {
                    this.firstFocus = false;
                }
            }, immediate: true
        }
    },
    mounted() {
        if(this.hideImageBtn){
            // Hide the button via css, since quill does not support dynamic changes.. :(
            document.getElementsByClassName('ql-image')[0].style.display = "none";
        }
        this.internalValue = this.value;
        // Set the placeholder
        this.$refs.advancedEditor.quill.root.dataset.placeholder = this.placeholder;

        // Register on text-change events to add link previews
        this.$refs.advancedEditor.quill.on('text-change', this.onEditorTextChanged);

        // Find the 'add video' button and center it - sometimes its off screen
        let els = document.getElementsByClassName("ql-video");
        if (els.length > 0) {
            els[0].onclick = () => {
                // Move the URL dialog to the center since its always offset by default
                let dialog = document.getElementsByClassName("ql-tooltip ql-editing");
                if (dialog.length > 0) {
                    dialog[0].style.left = 0;
                    dialog[0].childNodes[1].placeholder = 'URL-Link einfügen'
                }
            };
        }

        this.toolbar = document.getElementsByClassName("ql-toolbar")[0];
        this.toolbar.style.display = "none";
        const container = document.getElementsByClassName("quill-editor")[0];
        // Makes the font size and color picker menu overflow the quill editors boundaries
        container.style.overflow = "visible";
        if(this.pinboardCard){
            this.pinboardEditorStyling();
        } else if (this.textDown) {
            this.toolbarHideBlocked = true;
            this.toolbar.style.display = "block";
            this.toolbar.style.marginBlockStart = "0px";
            this.toolbar.style.marginBottom = "-2px";
        } else if (this.activeToolbar) {
            this.toolbar.style.display = "block";
        } else {
            container.addEventListener('focusout', (event) => {
                if(event.relatedTarget) {
                    const et = event.relatedTarget.classList;
                    if (
                        et.includes("ql-picker-label") ||
                        et.includes("ql-active") ||
                        et.includes("ql-picker-label") ||
                        et.includes("ql-picker-item") ||
                        et.includes("ql-size") ||
                        et.includes("ql-color") ||
                        et.includes("ql-bold") ||
                        et.includes("ql-italic") ||
                        et.includes("ql-underline") ||
                        et.includes("ql-list") ||
                        et.includes("ql-link") ||
                        et.includes("ql-image") ||
                        et.includes("ql-video")
                    ) {
                        return;
                    }
                }
                if(!this.toolbarHideBlocked) {
                    this.toolbar.style.display = "none";
                }
            });
        }
        container.addEventListener('focusin', () => {
            this.toolbar.style.display = "block";
        });

        document.getElementsByClassName("ql-link")[0].addEventListener('click', () => {
            this.toolbarHideBlocked = true;
            this.$emit('linkClicked');
        })
        document.getElementsByClassName("ql-video")[0].addEventListener('click', () => {
            this.toolbarHideBlocked = true;
        })
    },
    methods: {
        onEditorChanged(val) {
            // Trigger the input event for the v-model to work
            this.$emit('input', val.html);
        },
        onEditorTextChanged(delta, deltaOld, source) {
            if (source === "user") {
                // Get the current text from the editor
                const text = this.$refs.advancedEditor.quill.getText();
                // Check if the text exceeds the maximum length
                if (this.maxLength && text.length > this.maxLength) {
                // Truncate the text to the maximum length
                const truncatedText = text.slice(0, this.maxLength);
                // Set the text in the editor to the truncated text
                this.$refs.advancedEditor.quill.setText(truncatedText);
                // Emit an event to notify the parent component that the text was truncated
                this.$emit('lengthExceeded',this.maxLength);
                }
            }
            // Only youtube auto preview is supported => Check if its youtube
            if (delta.ops && source === "user") {
                let url = "";
                // If the text box is empty
                if (delta.ops.length > 0 && delta.ops[0].insert &&
                    (delta.ops[0].insert.toString().startsWith("https://www.youtu") || delta.ops[0].insert.toString().startsWith("https://youtu"))) {
                    url = delta.ops[0].insert;
                } else if (delta.ops.length > 1 && delta.ops[1].insert &&
                    (delta.ops[1].insert.toString().startsWith("https://www.youtu") || delta.ops[1].insert.toString().startsWith("https://youtu"))) {
                    // If the text box is not empty
                    url = delta.ops[1].insert;
                }
                if (url.trim().length > 0) {
                    let quill = this.$refs.advancedEditor.quill;
                    let range = quill.getSelection(true);
                    this.$refs.advancedEditor.quill.insertText(range.index, '\n', "user");
                    // The link must be formatted as an youtube-embedded link
                    if (url.includes("watch?v="))    // If the browser url was used
                        url = url.replace("watch?v=", "embed/");
                    else if (!url.includes("embed") && url.toString().startsWith("https://youtu.be/"))   // If the url was copied from the video
                        url = url.replace("https://youtu.be/", "https://www.youtube-nocookie.com/embed/")
                    this.$refs.advancedEditor.quill.insertEmbed(range.index + 1, 'video', url, "user");
                }

            }
        },
        pinboardEditorStyling() {
            this.toolbarHideBlocked = true;
            this.toolbar.style.display = "block";
            this.toolbar.style.marginBlockStart = "5px";
            this.toolbar.style.marginBottom = "-2px";
            this.toolbar.style.height = "31px";
            let tools = document.getElementsByClassName("ql-formats");
            for (let i = 0; i < tools.length; i++) {
                tools[i].style.position = "absolute";
                tools[i].style.marginBlockStart =  "-30px";
                tools[i].style.background =  "rgb(245, 245, 245)";
                tools[i].style.borderRadius =  "15px";
                tools[i].style.boxShadow =  "0px 5px 9px #525252";
                tools[i].style.padding =  "5px";
            }
            let els = document.getElementsByClassName("ql-video");
            if (els.length > 0) {
                els[0].onclick = () => {
                    let dialog = document.getElementsByClassName("ql-tooltip ql-editing");
                    if (dialog.length > 0) {
                        dialog[0].style.maxWidth = "100%";
                        dialog[0].style.overflow = "auto";
                        dialog[0].style.top = "10px !important"
                    }
                };
            }
            let placeholder = document.getElementsByClassName("ql-editor ql-blank");
            for (let i = 0; i < placeholder.length; i++) {
                placeholder[i].style.setProperty("padding-top", "10px", "important");
                placeholder[i].style.setProperty("font-size", "27px", "important");

            }
            let placeholder2 = document.getElementsByClassName("ql-editor");
            for (let i = 0; i < placeholder2.length; i++) {
                placeholder2[i].style.setProperty("padding-top", "10px", "important");
                placeholder2[i].style.setProperty("font-size", "27px", "important");
            }
        },
    }
}
</script>

<style lang="scss">
@mixin placeholder($color, $size:"") {
    &[data-placeholder] {
        color: $color !important;
        @if $size != "" {
            font-size: $size;
        }
    }
}

.ql-editor.ql-blank::before {
    color: #bdbdbd;
}

.ql-editor p {
    min-height: 24px !important;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
    content: 'Link einfügen:' !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Speichern' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: 'Mittel' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: 'Groß' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: 'Mittel' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: 'Groß' !important;
}

.ql-snow .ql-tooltip[data-mode=video]::before {
    content: 'Video einfügen:' !important;
}
.ql-snow .ql-tooltip[data-mode=video]::before {
    content: 'Video einfügen:' !important;
}
.ql-snow .ql-tooltip::before {
    content: 'Webseite besuchen: '  !important;
}
.ql-snow .ql-tooltip a.ql-action::after{
    content: 'Bearbeiten';
    white-space: normal !important;
    margin-left: 0px !important;
}
.ql-snow .ql-tooltip a.ql-remove::before{
    content: 'Entfernen';
}

.ql-snow .ql-tooltip a.ql-preview {
    white-space: normal !important;
}

.ql-snow .ql-tooltip{
    position: static !important;
    white-space: normal !important;
}
</style>
