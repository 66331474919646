<template>
    <v-col
        :cols="windowWidth >= 600 ? '1' : '10'"
        lg="2"
        md="3"
        sm="4"
    >
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-card 
                    class="pinboardCardPlus mt-5 d-flex justify-center"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    @click="addNewCard('addNewCard')"
                >
                    <img
                        class="iconToDunkelgrau"
                        style="max-width: 35px; align-self: center; color: var(--v-dunkelgrau-base)"
                        alt="Neue Spalte"
                        :src="plusIcon"
                    >
                </v-card>
            </template>
            <span>Spalte hinzufügen</span>
        </v-tooltip>
    </v-col>
</template>

<script>
import {mapState} from "vuex";
import plusIcon from '@/assets/Icons/plus.svg';

export default {
    name: 'PinboardCardTitleElementPlus',
    data: () => ({
        plusIcon,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
    },
    methods: {
        addNewCard(identifier, payload = null) {
            this.$emit(identifier, payload);
        },
    },
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 900px) {
    .plusCreateIcon {
        max-width: 20vmax;
        max-height: 20vmax;
    }
}

.pinboardCardPlus {
  background-color: #dddcdd;
  font-size: 30px;
  width: 220.5px;
  height: 65px;
}

@media only screen and (max-width: 599px) {
    .pinboardCardPlus {
        width: 72vw;
    }
}
</style>
