<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn
                dark
                x-small
                class="fileListButton"
                elevation="0"
                :style="`background-color: ${primaryColor ? primaryColor : 'var(--v-chatColor-base)'}; ${activatorStyle}; width: ${buttonSize}px; height: ${buttonSize}px`"
                v-on="on"
                @click="clickInfoButton"
            >
                <img
                    :src="infoIcon"
                    :alt="usage === 'userNote' && userNote ? 'Notiz/Anweisung: ' + userNote.note : usage === 'fileFormat' ? fileFormatText : noAnswerText"
                    style="filter: brightness(1000%)"
                    class="fileListButtonIcon"
                >
            </v-btn>
        </template>
        <span>{{ usage === 'userNote' && userNote ? "Notiz/Anweisung: " + userNote.note : usage === 'fileFormat' ? fileFormatText : noAnswerText }}</span>
    </v-tooltip>
</template>

<script>
import {
    allowedExtensionsForVideoPlayer,
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre
} from "@/constants/allowedMimeTypes";
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import {mapMutations} from "vuex";

export default {
    name: "FileListInfoButton",
    props: {
        file: { type: Object, required: true },
        primaryColor: { type: String, required: false },
        usage: { type: String, required: true },
        person: { type: Object, required: false },
        activatorStyle: { type: String, required: false },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        show: false,
        userNote: '',
        fileFormatText: 'Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte laden Sie die Datei herunter.',
        noAnswerText: 'Der/Die Schüler/-in hat die Datei unbearbeitet abgegeben',
        infoIcon,
    }),
    mounted() {
        if(this.usage === 'userNote') {
            this.userNote = this.file.userNotes.find((el) => el.account === this.person.account);
        }
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),

        async clickInfoButton(event) {
            event.stopPropagation();
            let message = '';
            switch (this.usage) {
                case 'fileFormat':
                    message = this.fileFormatText;
                    break;
                case 'userNote':
                    message = this.userNote.note;
                    break;
                case 'noAnswer':
                    message = this.noAnswerText;
                    break;
            }
            this.showSnackbar({
                message,
                color: "warning"
            });
        },
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
}
</style>
