const defaultState = {
    spawnedPairing: false, // acts like a singleton limiter for component initialization
    pairingEdit: false,
    pairingComponentIndex: null, // to delete the pairing Component via Sidebar 
    selectedElement: null, // transmits element information sampled from click Events to subcomponents
    notify: false,  // every mutation to selectedElement raises the notifiy-handlers
    pairedElements: [{}], // struct: [{el1, el2}]
    pupilElements: [{}], // struct: [{el1, el2}]
    teacherElements: [{}], // struct: [{el1, el2}]
    pairingColors: [  '#518ecb','#a9c936','#f49924','#94519b',
                        '#e6272e','#fdd44a','#8ed3ef','#f4b4d0', //first 2 rows are from eklara logo
                        '#73C6B6','#E67E22'],
    pairingDone: false, // stored in pairingConfig.done but needs to be available to many layers
    pairingShowSubmission: false, // toggles between view of pupil or teacher selection (true=pupil)
};

const mutations = {
    setSelected: (state, el) => {
        
        if(state.pairingEdit) {
            state.selectedElement = el;
            state.notify = !state.notify;
            
            }
    },
    setPairedElements: (state, json) => {
        state.pairedElements = json;
    },
    setPupilElements: (state, json) => {
        state.pupilElements = json;
    },
    setTeacherElements: (state, json) => {
        state.teacherElements = json;
    },
    removePairedElements: (state, index) => {
        state.pairedElements.splice(index,1);
    },
    setSpawnedPairing: (state, bool) => {
        state.spawnedPairing = bool;
    },
    setPairingEdit: (state, bool) => {
        state.pairingEdit = bool;
    },
    setPairingComponentIndex: (state, index) => {
        state.pairingComponentIndex = index;
    },
    resetPairing: (state) => {
        state.spawnedPairing = false;
        state.pairingEdit = false;
        state.pairingShowSubmission = false;
        state.pairingDone = false;
        state.pairingComponentIndex = null;
        state.selectedElement = null;
        state.pairedElements = [{}];
        state.pupilElements = [{}];
    },
    setPairingDone: (state, bool) => {
        state.pairingDone = bool;
    },
    swapPairedLists: (state) => {
        let temp = state.pairedElements;
        state.pairedElements = state.pupilElements;
        state.pupilElements = temp;
        state.pairingShowSubmission = !state.pairingShowSubmission;
        
    },
};

const getters = {
    pairedElements: state => state.pairedElements,
    pupilElements: state => state.pupilElements,
    teacherElements: state => state.teacherElements,
    selectedElement: state => state.selectedElement,
    notify: state => state.notify,
    pairingColors: state => state.pairingColors,
    spawnedPairing: state => state.spawnedPairing,
    pairingComponentIndex: state => state.pairingComponentIndex,
    pairingEdit: state => state.pairingEdit,
    pairingDone: state => state.pairingDone,
    pairingShowSubmission: state => state.pairingShowSubmission,
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
};
