<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: var(--v-fgrau-base); border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">
                    <div style="display: flex; width: 50%">
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px; filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Suchen-Symbol"
                        >
                        <v-text-field
                            v-model="search"
                            label="Nach Fach suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                        />
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="fachIcon"
                        style="height: 24px; margin-right: 10px"
                        alt=""
                    >
                    Fächer
                </div>
                <v-data-table
                    :headers="headers"
                    :items="subjects"
                    :search="search"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    sort-by="name"
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.name }}</td>
                            <td :style="'color:' +row.item.color ">
                                <v-btn
                                    :style="'background-color:' +row.item.color"
                                    style="border-radius: 8px; max-width: 42px!important; min-width: 36px!important;"
                                    light
                                />
                            </td>
                            <td>
                                <v-btn
                                    class="mx-2"
                                    color="#EAEAEA"
                                    small
                                    style="text-transform: inherit; border-radius: 5px"
                                    elevation="1"
                                    light
                                    @click="edit(row.item)"
                                >
                                    Fach bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>

            <!-- Fach anlegen / bearbeiten -->
            <v-row
                id="edit"
                class="mx-0 my-3"
            >
                <div
                    class="editContainer"
                    style="background-color: #ffffff;"
                >
                    <v-row
                        class="mx-0"
                        style="background-color: var(--v-fgrau-base); border-radius: 15px 15px 0 0"
                    >
                        <v-col
                            style="color: white; font-size: larger;"
                            class="ma-1 ml-5"
                        >
                            <v-avatar
                                style="width: 24px; "
                                color="rgba(255,255,255,0.8)"
                            >
                                <img
                                    :src="fachIcon"
                                    alt="Fach-Symbol"
                                >
                            </v-avatar>
                            {{ oldSubject ? 'Fach bearbeiten' : 'Neues Fach anlegen' }}
                        </v-col>
                        <v-col
                            class="mr-6"
                            style="display: flex; justify-content: flex-end;"
                        >
                            <v-btn
                                color="#FFFFFF40"
                                x-small
                                class="mainFunctionButtons ml-3 my-auto"
                                :disabled="!oldSubject"
                                @click="deleteDialog = true"
                            >
                                <img
                                    :src="loeschIcon"
                                    alt="löschen-Symbol"
                                >
                            </v-btn>
                            <v-tooltip
                                top
                                nudge-right="100"
                                :open-on-click="true"
                                :open-on-hover="false"
                                content-class="infoTextToolTip"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="#FFFFFF40"
                                        x-small
                                        class="mainFunctionButtons ml-3 my-auto"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <img
                                            :src="infoIcon"
                                            alt="Info-Symbol"
                                        >
                                    </v-btn>
                                </template>
                                <span>
                                    Hier können Sie Fächer für Ihre Schule anlegen. Wählen Sie eine Farbe, einen Leiter und
                                    ein Icon. Sie können die Farben und Icons auch mehrfach benutzen.
                                </span>
                            </v-tooltip>
                            <v-btn
                                class="mainControlButtons ml-3 my-auto pa-5"
                                color="#FFFFFF40"
                                dark
                                elevation="0"
                                @click="stopEdit"
                            >
                                <span>Abbrechen</span>
                            </v-btn>
                            <v-btn
                                v-if="oldSubject"
                                class="mainControlButtons ml-3 my-auto pa-5"
                                color="#69CD70"
                                dark
                                elevation="4"
                                @click="editThisSubject"
                            >
                                <span>Speichern</span>
                            </v-btn>
                            <v-btn
                                v-else
                                class="mainControlButtons ml-3 my-auto pa-5"
                                color="#69CD70"
                                dark
                                elevation="4"
                                @click="saveSubject"
                            >
                                <span>Speichern</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="mx-2 mb-2">
                        <v-row>
                            <div style="min-width: 25px!important;" />
                            <v-col>
                                <div style="font-weight: bold; margin-bottom: 10px">
                                    Fachdaten
                                </div>
                                <v-row>
                                    <v-col cols="2">
                                        Name:
                                    </v-col>
                                    <v-col cols="7">
                                        <input
                                            v-model="name"
                                            type="text"
                                            class="input"
                                            :class="{ errorInput: fields.name }"
                                            @change="validateInput"
                                        >
                                    </v-col>
                                </v-row>

                                <v-dialog
                                    v-model="dialog"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-row>
                                            <v-col cols="2">
                                                <v-row>
                                                    <v-col>
                                                        Lehrer:
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="pl-0">
                                                        <v-btn
                                                            style="margin-top: -18px; max-height: 25px; color: black"
                                                            dark
                                                            small
                                                            color="#EAEAEA"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="() => { oldMembers = JSON.parse(JSON.stringify(members)) }"
                                                        >
                                                            Auswählen
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="7">
                                                <v-col cols="2">
                                                    <v-row
                                                        v-for="(item, index) in members.filter((el) => el.selected)"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="px-3 py-1 mb-1"
                                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                                        >
                                                            {{ item.name + ' ' + item.lastName }}
                                                        </div>
                                                    </v-row>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="#6995CD"
                                        >
                                            <v-toolbar-title>
                                                <v-text-field
                                                    v-model="search2"
                                                    prepend-icon="mdi-magnify"
                                                    label="Nach Personen suchen"
                                                    single-line
                                                    dark
                                                    hide-details
                                                    class="pl-6"
                                                />
                                            </v-toolbar-title>
                                            <v-spacer />
                                            <v-toolbar-items>
                                                <v-btn
                                                    dark
                                                    elevation="3"
                                                    style="background-color: #3BAA69; color: white; max-height: 40px; margin-top: 10px; border-radius: 8px"
                                                    text
                                                    @click="confirmSelection()"
                                                >
                                                    Auswählen
                                                </v-btn>
                                                <v-btn
                                                    x-small
                                                    class="mx-4"
                                                    elevation="3"
                                                    style="background-color: #e6221e80; border-radius: 8px; margin-top: 10px; width: 40px; max-height: 40px"
                                                    @click="() => { members = JSON.parse(JSON.stringify(oldMembers)); dialog = false; search2 = '' }"
                                                >
                                                    <img
                                                        :src="abbrechenIcon"
                                                        style="height: 40px; filter: brightness(1000%)"
                                                        alt="abbrechen-Symbol"
                                                    >
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>

                                        <v-data-table
                                            :key="oldSubject ? oldMembers._id : members._id"
                                            :headers="headers2"
                                            :items="oldSubject ? oldMembers : members"
                                            :search="search2"
                                            disable-pagination
                                            hide-default-footer
                                            fixed-header
                                            class="ma-3"
                                            locale="de-DE"
                                            no-data-text="Keine Daten verfügbar"
                                            no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                                        >
                                            <template v-slot:item="row">
                                                <tr>
                                                    <td>{{ row.item.lastName }}</td>
                                                    <td>{{ row.item.name }}</td>
                                                    <td>{{ row.item.gender }}</td>
                                                    <td>{{ row.item.note }}</td>
                                                    <td>
                                                        <v-checkbox
                                                            v-model="row.item.selected"
                                                            class="mx-2"
                                                            rounded
                                                            small
                                                            light
                                                        />
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                                class="mt-8"
                            >
                                <v-row>
                                    <v-col cols="3">
                                        Farbe:
                                    </v-col>
                                    <v-col cols="9">
                                        <icon-picker
                                            :current-color="color"
                                            :callback="(result) => { color = result; validateInput(); }"
                                            :group-icons="groupIcons"
                                            :color-picker-usage="'fachErstellen'"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="3">
                                        Icon:
                                    </v-col>
                                    <v-col cols="9">
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'noIcon'"
                                            :src="noIcon"
                                            alt="Kein Symbol"
                                            @click="icon = 'noIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'englischIcon'"
                                            :src="englischIcon"
                                            alt="Englisch-Symbol"
                                            @click="icon = 'englischIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'deutschIcon'"
                                            :src="deutschIcon"
                                            alt="Deutsch-Symbol"
                                            @click="icon = 'deutschIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'matheIcon'"
                                            :src="matheIcon"
                                            alt="Mathe-Symbol"
                                            @click="icon = 'matheIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'bioIcon'"
                                            :src="bioIcon"
                                            alt="Biologie-Symbol"
                                            @click="icon = 'bioIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'chemieIcon'"
                                            :src="chemieIcon"
                                            alt="Chemie-Symbol"
                                            @click="icon = 'chemieIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'erdkundeIcon'"
                                            :src="erdkundeIcon"
                                            alt="Erdkunde-Symbol"
                                            @click="icon = 'erdkundeIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'geschichteIcon'"
                                            :src="geschichteIcon"
                                            alt="Geschichte-Symbol"
                                            @click="icon = 'geschichteIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'physikIcon'"
                                            :src="physikIcon"
                                            alt="Physik-Symbol"
                                            @click="icon = 'physikIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'politikIcon'"
                                            :src="politikIcon"
                                            alt="Politik-Symbol"
                                            @click="icon = 'politikIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'religionIcon'"
                                            :src="religionIcon"
                                            alt="Religion-Symbol"
                                            @click="icon = 'religionIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'sportIcon'"
                                            :src="sportIcon"
                                            alt="Sport-Symbol"
                                            @click="icon = 'sportIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'wirtschaftIcon'"
                                            :src="wirtschaftIcon"
                                            alt="Wirtschaft-Symbol"
                                            @click="icon = 'wirtschaftIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'musikIcon'"
                                            :src="musikIcon"
                                            alt="Musik-Symbol"
                                            @click="icon = 'musikIcon'"
                                        >
                                        <img
                                            class="iconSelect"
                                            :selected="icon === 'kunstIcon'"
                                            :src="kunstIcon"
                                            alt="Kunst-Symbol"
                                            @click="icon = 'kunstIcon'"
                                        >
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-row>

            <!-- Delete dialog-->
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="400"
            >
                <v-card
                    class="cardStyle"
                    style="overflow: hidden; border-radius: 16px"
                >
                    <v-card-title 
                        style="background-color: var(--v-timetableColor-base)"
                        class="text-h5 mb-1 d-flex align-center justify-space-between"
                    >
                        <p>Fach löschen?</p>
                        <v-btn
                            icon
                            color="transparent"
                            elevation="0"
                            style="margin-right: -8px"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-4">
                        Sind Sie sicher, dass Sie das Fach löschen möchten?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center mb-2">
                        <v-btn
                            class="optionBtn text-none"
                            color="#F5F5F5"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 mr-2"
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none"
                            color="rot"
                            dark
                            @click="() => {deleteThisSubject(); deleteDialog = false}"
                        >
                            <img
                                :src="deleteIcon"
                                class="icon20 mr-2 iconToWhite"
                            >
                            Löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import suchIcon from '../../assets/Icons/suche-26.svg'

import bioIcon from '../../assets/Icons/FaceliftIcons/f-biologie.svg'
import chemieIcon from '../../assets/Icons/FaceliftIcons/f-chemie.svg'
import deutschIcon from '../../assets/Icons/FaceliftIcons/f-deutsch.svg'
import englischIcon from '../../assets/Icons/FaceliftIcons/f-englisch.svg'
import erdkundeIcon from '../../assets/Icons/FaceliftIcons/f-erdkunde.svg'
import geschichteIcon from '../../assets/Icons/FaceliftIcons/f-geschichte.svg'
import matheIcon from '../../assets/Icons/FaceliftIcons/f-mathe.svg'
import musikIcon from '../../assets/Icons/FaceliftIcons/f-musik.svg'
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst.svg';
import physikIcon from '../../assets/Icons/FaceliftIcons/f-physik.svg'
import politikIcon from '../../assets/Icons/FaceliftIcons/f-politik.svg'
import religionIcon from '../../assets/Icons/FaceliftIcons/f-religion.svg'
import sportIcon from '../../assets/Icons/FaceliftIcons/f-sport.svg'
import wirtschaftIcon from '../../assets/Icons/FaceliftIcons/f-wirtschaft.svg'
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import abbrechenIcon from "../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import fachIcon from "../../assets/Icons/fächer-05.svg";
import loeschIcon from '../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';

// Import the styles too, globally

import IconPicker from "@/components/Utils/IconPicker.vue";

export default {
    name: "Faecher",
    components: { IconPicker    },
    data() {
        return {
            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            kunstIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,
            infoIcon,
            abbrechenIcon,
            fachIcon,
            loeschIcon,
            suchIcon,
            cancelIcon,
            deleteIcon,
            groupIcons: [
                bioIcon,
                chemieIcon,
                deutschIcon,
                englischIcon,
                erdkundeIcon,
                geschichteIcon,
                matheIcon,
                musikIcon,
                kunstIcon,
                physikIcon,
                politikIcon,
                religionIcon,
                sportIcon,
                wirtschaftIcon
            ],

            showLoadingOverlay: false,
            deleteDialog: false,
            dialog: false,

            icon: 'noIcon',
            name: '',
            color: '#fcd20a',
            uploadedIcon: false,

            fields: {
                name: false,
                color: false,
                teacher: false
            },

            oldSubject: null,
            height: 0,
            search: '',
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Farbe', value: 'color', sortable: false},
                { text: '', sortable: false},
            ],

            search2: '',
            headers2: [
                { text: 'Name', value: 'lastName' },
                { text: 'Vorname', value: 'name' },
                { text: 'Geschlecht', value: 'gender' },
                { text: 'Notiz', value: 'note' },
                { text: 'Ausgewählt', value: 'selected'},
            ],
            members: [],
            oldMembers: [],
            subjects: [],
        };
    },
    computed: {
        ...mapGetters('teachers', [ 'getTeacherStatus' ]),
        ...mapGetters('subjects', ['getSubjectInvalidStatus']),
    },
    watch: {
        getTeacherStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestTeachers();
            }
        },
        getSubjectInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestSubjects();
            }
        },
    },
    async created() {
        this.height = window.innerHeight * 0.4
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.requestSubjects();
        this.requestTeachers();
    },
    methods: {
        ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
        ...mapActions("subjects", ["getSubjects","createSubject", "editSubject", "deleteSubject", 'setSubjectListStatus']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async requestTeachers() {
            this.showLoadingOverlay = true;
            this.members = await this.getTeachers();
            this.showLoadingOverlay = false;
            this.setTeachersInvalidStatus(false);
        },
        async requestSubjects() {
            this.subjects = await this.getSubjects();
            this.setSubjectListStatus(false);
        },

        confirmSelection() {
            this.dialog = false;
            this.search2 = '';

            // If editing an existing subject, update members to show selected teachers correctly next to "Lehrer:"
            if (this.oldSubject) {
                this.members = JSON.parse(JSON.stringify(this.oldMembers));
            }
        },

        async edit(subject) {
            this.$vuetify.goTo('#edit');
            this.oldSubject = subject;
            this.name = subject.name;
            this.color = subject.color;
            this.icon = subject.icon;
            this.uploadedIcon = subject.uploadedIcon ? subject.uploadedIcon : false,
            this.members.forEach((el) => {
                el.selected = false;
            });
            subject.teachers.forEach((el) => {
                this.members.find((element) => {
                    return el.account == element.account}).selected = true;
            })
        },
        stopEdit() {
            this.name = '';
            this.color = '#fcd20a';
            this.icon = 'deutschIcon';
            this.members.forEach((el) => {
                el.selected = false;
            });
            this.oldSubject = null;

            this.fields.name = false;
            this.fields.color = false;
        },

        async deleteThisSubject() {
            try {
                this.showLoadingOverlay = true;
                let response = await this.deleteSubject(this.oldSubject._id);
                if (response) {
                    const index = this.subjects.indexOf(this.oldSubject);
                    if (index > -1) {
                        this.subjects.splice(index, 1);
                    }
                    this.stopEdit();
                    this.setSubjectListStatus(true);
                    this.showSnackbar({ message: 'Gespeichert!'});
                }
                else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },

        validateInput() {
            let result = true;

            this.fields.name = false;
            this.fields.color = false;

            if (this.name.replace(/\s/g, '') === '') {
                this.fields.name = true;
                result = false;
            }

            if (this.color.replace(/\s/g, '') === '') {
                this.fields.color = true;
                result = false;
            }

            return result;
        },

        async editThisSubject() {
            try {
                this.dialog = false;
                this.showLoadingOverlay = true;
                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }
                let teachers = this.oldMembers.filter((el) => {
                    return el.selected;
                });

                let sendTeachers = [];
                teachers.forEach((el) => {
                    sendTeachers.push(el._id)
                });

                let data = {
                    _id: this.oldSubject._id,
                    color: this.color,
                    icon: this.icon,
                    teachers: sendTeachers,
                    uploadedIcon: this.icon.includes('Icon') ?  false : true,
                };

                if (this.name !== this.oldSubject.name) {
                    data['name'] = this.name;
                }
                let response = await this.editSubject(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    const index = this.subjects.indexOf(this.oldSubject);
                    if (index > -1) {
                        this.subjects.splice(index, 1);
                    }
                    this.subjects.push(response);
                    this.setSubjectListStatus(true);
                    this.stopEdit();
                    this.showSnackbar({ message: 'Gespeichert!'});
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async saveSubject() {
            try {
                this.dialog = false;
                this.showLoadingOverlay = true;
                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }
                let teachers = this.members.filter((el) => {
                    return el.selected;
                });

                let sendTeachers = [];
                teachers.forEach((el) => {
                    sendTeachers.push(el._id)
                });

                let data = {
                    name: this.name,
                    color: this.color,
                    icon: this.icon,
                    teachers: sendTeachers,
                    uploadedIcon: this.icon.includes('Icon') ?  false : true,
                };
                let response = await this.createSubject(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Fachnamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    this.subjects.push(response);
                    this.setSubjectListStatus(true);
                    this.stopEdit();
                    this.showSnackbar({ message: 'Gespeichert!'});
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width: 0;
        color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: var(--v-hellgrau-base);
        overflow: hidden;
        appearance:none
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .iconSelect {
        width: 42px;
        padding: 2px;
        border: solid 2px #EAEAEA;
        border-radius: 10px;
        margin-right: 2px;
    }

    .iconSelect[selected] {
        width: 42px;
        padding: 2px;
        border: solid 2px #737373;
        border-radius: 10px;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em;
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: var(--v-blau-base);
    }

    .optionBtn {
        border-radius: 8px;
        min-width: 35px !important;
        height: 35px !important;
    }
</style>
