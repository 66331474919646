<template>
    <div>
        <v-btn
            id="joinLehrerzimmerBtn"
            class="d-flex"
            style="justify-content: left"
            color="gruen"
            elevation="0"
            @click="joinLehrerzimmer"
        >
            <img
                :src="videoIcon"
                alt="Videokamera"
            >
            <p
                style="padding-right: 52px"
            >
                Lehrer
            </p>
        </v-btn>

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import * as backend from '@/api/backend';
import { mapActions, mapMutations, mapGetters } from 'vuex';

import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";

import videoIcon from '@/assets/Icons/video.svg';

export default {
    name: "JoinLehrerzimmerButton",
    components: {
        VideoTutorial,
    },
    data: () => ({
        videoIcon,

        // Show video Tutorial is set to true when button is clicked
        // videoTutorialClosed is set to false when VideoTutorial is closed
        videoTutorial: false,
        showVideoTutorial: false,
        videoTutorialClosed: false,
    }),
    computed: {
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        ...mapGetters("auth", ["accountRole"]),
    },
    mounted() {
        this.videoTutorial = (this.accountRole === 'teacher' || this.accountRole === 'maintainer')
            ? this.videoTutorialsByFilename['Tutorial_bbb_Lehrer_Verwaltung.mp4']
            : this.videoTutorialsByFilename['Tutorial_bbb_Eltern_Schueler.mp4'];
    },
    methods: {
        ...mapActions('util', ['toggleLoading']),
        ...mapActions('auth', ['reloadDontLogout']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.videoTutorialClosed = true;
            this.joinLehrerzimmer();
        },
        async joinLehrerzimmer() {
            this.toggleLoading(true);
            const response = await backend.joinTeachersRoomBbbMeetingUrl();

            if (!response.status || response.status === 422 || response.status === 404) {
                this.showSnackbar({ message: 'Das Lehrerzimmer konnte nicht geladen werden. Bitte aktualisieren Sie die Seite!', color: 'error' });
                this.toggleLoading(false);
                return;
            }
            else if (response.status === 409) {
                this.showSnackbar({ message: 'Das Lehrerzimmer ist aktuell geschlossen!', color: 'error' });
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                return;
            }

            const { redirectUrl } = await response.json();
            window.location.href = redirectUrl;
            this.toggleLoading(false);
        }
    }
}
</script>

<style scoped lang="scss">
#joinLehrerzimmerBtn {
    color: white;
    text-transform: inherit;
    height: 50px;
    border-radius: 8px;
    width: 160px;

    img {
        height: 25px;
        filter: brightness(1000%);
        margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    #joinLehrerzimmerBtn {
        height: 40px;
        padding: 8px;

        img {
            height: 20px;
        }

        p {
            font-size: small;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinLehrerzimmerBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
    }
}
</style>
