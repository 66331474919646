import { getBackendWebSocketAddress } from "../util/getBackendAddress";
import * as BackendApi from "./backend";

// Same WebSocket states as in the npm ws packagae on the serverside
const webSocketStates = {
    CONNECTING: "CONNECTING", // Websocket currently tries to connect
    OPEN: "OPEN", // Websocket is open and messages can be sent
    CLOSING: "CLOSING", // Websocket is closing gracefully, dont send messages anymore
    CLOSED: "CLOSED", // Websocket is now closed
};

let webSocket;
let webSocketStatus = webSocketStates.CLOSED;

let routeHandlers = [];

// Expect dataObject similar to req (request) object in express
// This has no technical reason it is more a organizational decision
// so that a request from a backend to a frontend is similar to a HTTP request from a frontend to our express backend 
// e.g. 
// { 
//  path: '/chat-room-event/:chatRoomEventId'
//  params: {chatRoomEventId: '622b28db356411216a814f7c'}.
//  query: {}, // This is probably empty most of the time, but maybe you want to add a limit or sort by option
//  body: { chatRoom: "622b5853599846463009b9a7", text: "d\n", type: "textMessage" },
// }
const handleRequest = (request) => {
    for (const routeHandler of routeHandlers) {
        if (request.method !== routeHandler.method) {
            continue;
        }
        if (request.route !== routeHandler.route) {
            continue;
        }
        console.debug(`[ws-handleRequest] ${request.method} ${request.route}`)
        routeHandler.handler(request);
        return;
    }
    console.error(`[ws-handleRequest] Couldn't find a handler for request, Request:`, request);

};

const initWebSocket = async () => {
    if (webSocket
        && (webSocketStatus === webSocketStates.CONNECTING || webSocketStatus === webSocketStates.OPEN)) {
        console.debug('[ws-info] initWebSocket called but connection already exists. ');
        return;
    }

    let pingTimeout;

    const heartbeat = (webSocket) => {
        clearTimeout(pingTimeout);

        // Use `WebSocket#terminate()`, which immediately destroys the connection,
        // instead of `WebSocket#close()`, which waits for the close timer.
        // Delay should be equal to the interval at which your server
        // sends out pings plus a conservative assumption of the latency.
        pingTimeout = setTimeout(() => {
            webSocket.close();
            webSocketStatus = webSocketStates.CLOSING;
        }, 30000 + 1000);
    };

    let webSocketConnectionTokenRequest;
    try {
        webSocketConnectionTokenRequest =
            await BackendApi.getWebSocketConnectionToken();
    } catch (error) {
        console.log(error);

        console.debug("[ws-error] Backend unreachable.");
        setTimeout(() => {
            console.debug(
                "[ws-retry] Retry gaining a webSocket connection after Backend unreachable"
            );
            initWebSocket();
        }, 5000);
        return;
    }
    if (webSocketConnectionTokenRequest.status === 404) {
        console.error("BackendApi.getWebSocketConnectionToken returned 404. This probably means that the user is deleted. Don't retry connecting. Reload page to retry.");
        return;
    }
    if (webSocketConnectionTokenRequest.status === 401) {
        console.error(`BackendApi.getWebSocketConnectionToken returned 401. Probably the authorization data is in a invalid state e.g. logging into the backend after logout. Fix it!.  Don't retry connecting. Reload page to retry.`);
        return;
    }

    const { connectionToken } = await webSocketConnectionTokenRequest.json();
    const backendWebSocketAddress = new URL(getBackendWebSocketAddress());
    backendWebSocketAddress.searchParams.append(
        "connectionToken",
        connectionToken
    );
    webSocketStatus = webSocketStates.CONNECTING;
    webSocket = new WebSocket(backendWebSocketAddress);
    console.debug("[ws-connecting] Connecting to Server");


    let counter = 0;
    let startTime = 0;
    // const latencyTester = () => {
    //     webSocket.send(`${this.account._id}`);
    // }
    webSocket.onopen = function (e) {
        webSocketStatus = webSocketStates.OPEN;
        console.debug("[ws-connected] Connection established");
        heartbeat(webSocket);

        startTime = performance.now();
        // latencyTester();
    };

    webSocket.onmessage = (event) => {
        webSocketStatus = webSocketStates.OPEN;

        // const messageInt = parseInt(event.data);
        // if (event.data === this.account._id) {
        //     counter = counter + 1;
        //     if (counter % 100 === 0) {
        //         const endTime = performance.now();
        //         const duration = endTime - startTime;
        //         const packetsPerSecond = (counter / (duration / 1000)).toFixed(0);
        //         console.log(`Performance ${packetsPerSecond} messages / s`);
        //         counter = 1;
        //         startTime = performance.now();
        //     }
        //     // latencyTester();
        //     return;
        // }

        // console.log(`[ws-recieve] ${event.data}`);
        if (event.data === "ping") {
            webSocket.send("pong");
            heartbeat(webSocket);
            return;
        }

        // if (event.data === "updateData") {
        //     this.fetchChats();
        //     this.loadDevices();
        //     return;
        // }

        try {

            const dataObject = JSON.parse(event.data);
            handleRequest(dataObject);

        } catch (error) {
            console.error("[ws-error] Failed to parse message from Server as JSON. Data is:", event.data);
            console.error(error);
        }
    };

    webSocket.onclose = (event) => {
        clearTimeout(pingTimeout);
        webSocketStatus = webSocketStates.CLOSED;
        webSocket = null; 
        
        if (event.wasClean) {
            console.debug(
                `[ws-close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
                event
            );
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log("[ws-close] Connection died unexpectedly", event);

            setTimeout(() => {
                console.debug("[ws-retry] Retry gaining a webSocket connection");
                initWebSocket();
            }, 5000);
        }
    };

    webSocket.onerror = function (error) {
        console.log(`[ws-error] see next line`);
        console.log(error);
    };
};

const closeWebSocket = () => {
    if (webSocket) {
        webSocket.close();
        webSocketStatus = webSocketStates.CLOSING;
    }

    webSocketStatus = webSocketStates.CLOSED;
}

const registerGetHandler = (route, handler) => {
    routeHandlers.push({
        route,
        method: 'GET',
        handler,
    })
};

const registerPutHandler = (route, handler) => {
    routeHandlers.push({
        route,
        method: 'PUT',
        handler,
    })
};

const registerPostHandler = (route, handler) => {
    routeHandlers.push({
        route,
        method: 'POST',
        handler,
    })
};

const registerDeleteHandler = (route, handler) => {
    routeHandlers.push({
        route,
        method: 'DELETE',
        handler,
    })
};

const registerPatchHandler = (route, handler) => {
    routeHandlers.push({
        route,
        method: 'PATCH',
        handler,
    })
};

export default {
    webSocket,

    initWebSocket,
    closeWebSocket,


    get: registerGetHandler,
    put: registerPutHandler,
    post: registerPostHandler,
    delete: registerDeleteHandler,
    patch: registerPatchHandler,

    handleRequest,
}