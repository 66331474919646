<template>
    <div
        class="d-flex flex-row justify-space-between align-center pointer"
        @click="$refs.attachmentInputChat.click()"
    >
        <v-btn
            icon
            class="pointer"
        >
            <img
                :src="paperClipIcon"
                class="bigIcon"
                alt="Anhang einfügen"
            >
            <input
                ref="attachmentInputChat"
                hidden
                type="file"
                @change="showImageRightsWarning"
            >
        </v-btn>
        <div style="color: black; font-size: 14px;">
            Anhang
        </div>
        <v-dialog
            v-if="showWarningDialog"
            v-model="showWarningDialog"
            max-width="337"
            content-class="rounded-4"
            @click:outside="closeWarning"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                    style="background-color: #fd7e14"
                >
                    <p
                        style="color: white"
                    >
                        Achtung
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="closeWarning"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon30 iconToWhite"
                            alt="Abbrechen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text class="mt-2">
                    Eltern folgender Kinder haben Bildrechte eingeschränkt: <strong
                        v-for="(name, index) in warningDialogNames"
                        :key="index"
                    > {{ name }}</strong>. 
                    Bitte gehen Sie sicher das keines dieser Kinder in Ihrem Inhalt vorkommen.
                </v-card-text>

                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="closeWarning"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        {{ windowWidth >= 350 ? 'Abbrechen' : '' }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="fhellgruen"
                        dark
                        @click="sendFileMessage"
                    >
                        <img
                            :src="checkIcon"
                            class="icon20 iconToWhite mr-2"
                            alt="bestätigen"
                        >
                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import * as backend from "../../../api/backend";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import paperClipIcon from "@/assets/Icons/FaceliftIcons/anhang_senkrecht.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';

export default {
    name: "ChatRoomFileUploadButton",

    props: {
        chatRoom: { required: true, type: Object},
    },
    data: () => ({
        paperClipIcon,
        showWarningDialog: false,
        checkIcon,
        cancelIcon,
        warningDialogNames: '',
    }),
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapGetters("accounts", {'vuexMe': 'me'}),
        ...mapGetters("groups", {'vuexGroups': 'groups'}),
        ...mapGetters("groups", ['groupsById', 'groupsByLeader']),
        ...mapGetters('accounts', ['accounts', 'accountsById']),
        ...mapGetters('pupils', ['pupilsById']),
         ...mapState("util", ["windowWidth"]),

        myGroups() {
            if(this.accountRole === 'pupil'){
                const corresAcc = this.accountsById[this.vuexMe.account];
                const pupilGroups = corresAcc.groups.map((group) => this.groupsById[group]);
                return pupilGroups;
            }else if(this.accountRole === 'parent'){
                const pupilAccounts = this.vuexMe.pupils.map(pupilId => this.accountsById[this.pupilsById[pupilId].account])
                let pupilGroups = [];
                const map = pupilAccounts.map(acc => pupilGroups = pupilGroups.concat(acc.groups))
                const allPupilsGroups = pupilGroups.map((group) => this.groupsById[group]);
                const uniqueGroups =  Array.from(new Set(allPupilsGroups));
                return uniqueGroups;
            }else if(this.accountRole === 'maintainer'){
                return this.vuexGroups;
            }else{
                return  this.groupsByLeader(this.vuexMe._id);
            }
          
        },
    },

    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("mimeTypes", ["validateMimeType"]),
        ...mapActions("chat", ["postChatRoomEvent", "postChatRoomEventFile"]),
        ...mapActions("imageRights",['getImageRightNamesForGroups']),

         async showImageRightsWarning(){
            const names = await this.getImageRightNamesForGroups(this.myGroups)
            this.warningDialogNames = names;
            if(names.length){
                this.showWarningDialog = true;
            }else{
                this.sendFileMessage();
            }
        },
        closeWarning(){
            this.showWarningDialog = false;
            this.warningDialogNames = '';
        },
        async sendFileMessage() {
            this.$emit('showLoading');
            const files = Array.from(this.$refs.attachmentInputChat.files);
            if (await this.validateMimeType(files)) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > '10000000') { // show snackbar info if file is bigger than 10MB and takes more time to upload
                        this.showSnackbar({
                            message: "Die Datei ist etwas größer und beansprucht daher mehr Zeit zum Hochladen. Bitte haben Sie Geduld.",
                            color: "var(--v-fileArchiveOrange-base)"
                        });
                    }
                    const createdChatRoomEventResponse = await this.postChatRoomEvent({
                        type: "fileMessage",
                        chatRoom: this.chatRoom._id,
                        fileName: files[i].name
                    });
                    const createdChatRoomEvent = await createdChatRoomEventResponse.json();
                    const formData = new FormData();
                    formData.append("file", files[i], files[i].name);

                    const fileXmlHttpRequest = await this.postChatRoomEventFile({
                        chatRoomId: createdChatRoomEvent._id,
                        files
                    });

                    fileXmlHttpRequest.onerror = (e) => {
                        console.error("attachment upload error:", e);
                        // this.showAttachmentUploadProgress = false;
                        this.$refs.attachmentInputChat.value = "";
                    };
                    fileXmlHttpRequest.onabort = () => {
                        console.warn("attachment upload aborted");
                        // this.showAttachmentUploadProgress = false;
                        this.$refs.attachmentInputChat.value = "";
                    };
                    fileXmlHttpRequest.addEventListener("load", () => {
                        if (
                            createdChatRoomEventResponse.status === 201 &&
                            fileXmlHttpRequest.status === 201
                        ) {
                            if ((files[0]['type'] === 'image/tiff')) {
                                this.showSnackbar({message: "Dieses Format (.tiff/.tif) kann nicht angezeigt werden.", color: "warning"});
                            } else {
                                this.showSnackbar({ message: "Datei versendet." });
                            }
                        } else if (fileXmlHttpRequest.status !== 201) {
                            this.showSnackbar({
                                message: "Beim Hochladen der Datei ist ein Fehler aufgetreten. Für Hilfestellungen, besuchen Sie bitte die FAQ.",
                                color: "error",
                            });
                        }
                        // this.showAttachmentUploadProgress = false;
                        this.$refs.attachmentInputChat.value = "";
                    });
                    fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                        // this.attachmentUploadProgress = (e.loaded / e.total) * 100;
                    });
                    // this.attachmentUploadProgress = 0.0;
                    // this.showAttachmentUploadProgress = true;
                    fileXmlHttpRequest.send(formData);
                }
            }
            this.$emit('fileUploadFinished');
            this.closeWarning();
        }
    }
};
</script>
<style scoped>
.pointer {
    cursor: pointer;
}

.bigIcon {
    height: 27px;
}
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
