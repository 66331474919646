
const parseHtml = (displayLink, text, width = 350, height = 200) => {
    if(text){
        let html = "<div style='max-width: 100%; overflow: auto; position: relative; display: flex; justify-content: center;'>";
        let data = "";

        if(text.includes("<iframe")) {
            data = text.substring(text.indexOf("<iframe"), text.indexOf("</iframe>") + 9);
            // Set max width to 100%, otherwise the iFrame overflows on small devices
            data = data.replace("<iframe", "<iframe width='" + width + "px' height='" + height +"px' style='max-width: 100%;'");
        } else if(text.includes("<img")) {
            data = text.substring(text.indexOf("<img"), text.indexOf(">", text.indexOf("<img")) + 1)
            // Set max width of the image to 100%, otherwise it overflows
            data = data.replace("<img", "<img style='max-width: 100%;'");
        } else if(text.includes("<p") && displayLink) {
            // Remove unnecessary tags
            text = text.replace("<p><br> </p>", "");
  
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            //puts found URLs in an array
            let urlsArray = text.match(urlRegex);
            
            let button = '';
            
            //change style of div so every link has it's own line
            html = html.replace("display: flex; justify-content: center;", "");
            
            if(urlsArray) {
              for (let i = 0; i < urlsArray.length; i++) {
                // Extract pure URL
                let url = urlsArray[i]
                let sourceString = urlsArray[i].replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/)[0];
    
                button += "<form action=\"urlVal\" method=\"get\" target=\"_blank\"><input type='submit' style='padding: 0;min-width: 50px; min-height: 50px; font-size: xx-large ; background-color: #6894cd; color:white; border-radius: 8px;' value='@ -'/></form>";
    
                button = button.replace("urlVal", url);
                button = button.replace("@ -", "@ "+sourceString);
                button = button.replace("font-size: x-large", "font-size: medium");
                button = button.replace("padding: 0", "padding-left: 10px; padding-right: 10px; max-width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;")
              }
            }
  
/*          // Extract pure URL
            let url = text.substring(text.indexOf("<p>")+3, text.indexOf("</p>"))
            let sourceString = url.replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/)[0];*/

/*
            // let button = "<v-btn href='urlVal' target='_blank'>Hier könnte ihre Werbung stehen</v-btn>";
            let button = "<form action=\"urlVal\" method=\"get\" target=\"_blank\"><input type='submit'  style='padding: 0;min-width: 50px; min-height: 50px; font-size: xx-large ; background-color: #6894cd; color:white; border-radius: 8px;' value='@'/></form>";
            // let button = "<a href='urlVal' target='_blank'>Hier könnte ihre Werbung stehen</a>"
            // let linktext = "<p>textLink</p>"
          
            button = button.replace("urlVal", url);
            button = button.replace("@", "@ - "+sourceString);
            button = button.replace("font-size: x-large", "font-size: medium");
            button = button.replace("padding: 0", "padding-left: 10px; padding-right: 10px");
*/

            // linktext = linktext.replace("textLink", sourceString);
            data = button;
            // data += linktext;
        }
        html += data + "</div>";
        
        //count is not needed in babyView
        if(!displayLink) {
          var regex = /<iframe/gi, result, count = 0;
          while ((result = regex.exec(text))) {
              count++;
          }
          regex = /<img/gi;
          while ((result = regex.exec(text))) {
              count++;
          }
          // eslint-disable-next-line no-useless-escape
          regex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
  
          while((result = regex.exec(text))){
            count++;
          }
          if(--count >= 1){
              html += "<div style='display: flex; justify-content: center; align-content: center;'><div class='caption' style='max-width: 100%; padding: 2px 6px 2px 6px; margin-top: 7px; overflow: auto; background-color: gray; border: none; border-radius: 20px; color: white;'>+ " + count + " weitere</div></div>";
          }
        }
        return html;
    }
    return "";
}

export { parseHtml };
