<template>
    <div
        class="group-list-container"
    >
        <div class="group-chat-header pa-4">
            <span class="group-chat-header-text">
                <div
                    v-if="addNew"
                    style="display: flex; width: 100%;"
                >
                    <img
                        :src="suchIcon"
                        alt="Suchensymbol"
                        style="height: 20px; margin-left: 0; margin-top: 2px;"
                        class="iconToWhite"
                    >
                    <v-autocomplete
                        id="searchArea"
                        v-model="contact"
                        label="Nach Kontakt suchen"
                        single-line
                        dark
                        hide-details
                        :item-text="(el) => {return el.name + ' ' + el.lastName}"
                        item-value="account"
                        class="pl-2 pt-0 mt-0"
                        item-color="gruen"
                        height="24"
                        style="z-index: 10"
                        :items="matrixUsers"
                        :filter="customFilter"
                        no-data-text="Kein passender Kontakt"
                    >
                        <template
                            v-if="this.accountRole === 'teacher' || this.accountRole === 'maintainer'"
                            v-slot:prepend-item
                        >
                            <v-list-item
                                style="height: 48px;"
                                @click="addNew = false; contact = ''; newGroupChatName = ''; newGroupChatMembers = []; createGroupChatDialog = true"
                            >
                                <v-list-item-icon class="my-1 mr-2">
                                    <v-avatar
                                        fab
                                        color="#6994cc"
                                        size="40"
                                    >
                                        <img
                                            :src="groupIcon"
                                            style="width: 25px !important; height: 25px !important"
                                            alt="Gruppensymbol"
                                        >
                                    </v-avatar>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Neue Gruppe erstellen
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <hr>
                        </template>
                    </v-autocomplete>
                    <v-tooltip
                        :disabled="!showTooltips"
                        top
                        style="z-index: 9;"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                x-small
                                fab
                                plain
                                text
                                min-width="20"
                                style="height: 20px; width: 20px; margin-top: 2px;"
                                class="ml-2"
                                v-on="on"
                                @click="() => { addNew=false; contact=''; setFocusOn('newChatButton') }"
                            >
                                <img
                                    :src="abbrechenIcon"
                                    alt="Abbrechen"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </template>
                        <span>Abbrechen</span>
                    </v-tooltip>
                    <v-tooltip
                        :disabled="!showTooltips"
                        top
                        style="z-index: 9;"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                x-small
                                fab
                                plain
                                text
                                min-width="20"
                                style="height: 20px; width: 20px; margin-top: 2px;"
                                class="ml-2"
                                v-on="on"
                                @click="() => { startPrivateRoom(); setFocusOn('newChatButton') }"
                            >
                                <img
                                    :src="erledigtIcon"
                                    alt="Fertig"
                                    style="height: 20px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </template>
                        <span>Fertig</span>
                    </v-tooltip>
                </div>
                <div
                    v-else
                    style="width: 100%; display: flex;"
                >
                    <p
                        id="gruppenHeading"
                        tabindex="0"
                        class="mb-0"
                    >Gruppen</p>
                    <v-tooltip
                        :disabled="!showTooltips"
                        top
                        style="z-index: 9;"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                id="newChatButton"
                                tabindex="0"
                                x-small
                                fab
                                plain
                                text
                                min-width="20"
                                class="pa-0"
                                style="margin-left: auto; margin-right: 0; cursor: pointer; height: 20px; width: 20px;"
                                v-on="on"
                                @click="() => { addNew = true; contact = ''; setFocusOn('searchArea');}"
                            >
                                <img
                                    :src="hinzufuegenIcon"
                                    style="display: block; height: 20px;"
                                    alt="Neuer Chat"
                                >
                            </v-btn>
                        </template>
                        <span>Neuer Chat</span>
                    </v-tooltip>
                </div>
            </span>
        </div>
        <vue-custom-scrollbar
            v-if="rooms.length > 0"
            class="scroll-area"
            :settings="settings"
        >
            <div class="chat-body pa-0">
                <div
                    v-for="(room, index) in roomsWithMessages"
                    :key="index"
                    style="display: flex; justify-content: flex-start; align-items: center; border-bottom: solid; border-color: rgba(0, 0, 0, 0.12); border-width: 1px"
                    class="group-item"
                    tabindex="0"
                    :color="room.isOpen ? '' : 'red'"
                    @click="() => clickSelectRoom(room)"
                    @keypress.enter="() => clickSelectRoom(room)"
                    @keypress.space="() => clickSelectRoom(room)"
                >
                    <div class="ma-3">
                        <v-badge
                            color="#ff0000"
                            :content="room.newMessagesCount"
                            :value="room.newMessagesAvailable > 10 ? '10+': room.newMessagesAvailable"
                            overlap
                            offset-x="15"
                            style="z-index: 9"
                        >
                            <v-avatar
                                medium
                                fab
                                color="#6994cc"
                                height="56"
                                width="56"
                            >
                                <img
                                    v-if="isTeacher(room)"
                                    aria-hidden="true"
                                    alt="Chat"
                                    :src="hasProfilePic(room) ? getTeachPic() : lehrerIcon"
                                    :style="hasProfilePic(room) ? 'height: 56px' : 'height: 25px'"
                                    :class="hasProfilePic(room) ? 'roundImage' : 'iconToWhite'"
                                >
                                <img
                                    v-else-if="isPupil(room)"
                                    aria-hidden="true"
                                    alt="Chat"
                                    :src="schuelerIcon"
                                    class="iconToWhite chatIcon"
                                >
                                <img
                                    v-else-if="isGroup(room)"
                                    aria-hidden="true"
                                    alt="Chat"
                                    :src="groupIcon"
                                    class="chatIcon"
                                    style="width: 35px !important; height: 35px !important;"
                                >
                                <img
                                    v-else
                                    aria-hidden="true"
                                    :src="chatIcon"
                                    alt="Chat"
                                    class="iconToWhite chatIcon"
                                >
                            </v-avatar>
                        </v-badge>
                    </div>
                    <div
                        role="button"
                        class="greyText chat-name"
                        v-text="room.name.includes('private Room') || room.name.includes('group Room') ?
                            checkRoomName(room.name) : room.name"
                    />
                    <v-spacer />
                    <!-- Can be switched to menu component later -->
                    <DeleteChatButton
                        v-if="canUserDeleteRoom(room)"
                        :room="room"
                        @updateRoomList="$emit('updateRoomList')"
                    />
                    <ToggleMatrixRoomButton
                        v-if="!isTeacher(room) && room.name !== 'Lehrer'"
                        class="mr-1"
                        :room="room"
                        @lockStatusChanged="handleLockStatusChanged"
                    />
                </div>
            </div>
        </vue-custom-scrollbar>

        <div
            v-else
            class="pa-4"
            style="color: var(--v-dunkelgrau-base)"
        >
            <p
                tabindex="0"
                class="mb-0"
            >
                Keine Gruppen
            </p>
        </div>

        <v-dialog
            v-if="createGroupChatDialog"
            v-model="createGroupChatDialog"
            width="400"
        >
            <v-card
                class="cardStyle"
                style="height: 80vh; overflow: hidden"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Gruppenchat erstellen</p>
                    <v-btn
                        icon
                        @click="createGroupChatDialog = false"
                    >
                        <v-icon style="transform: rotate(45deg)">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text
                    class="group-list-container"
                    style="height: calc(80vh - 55px - 60px);"
                >
                    <vue-custom-scrollbar
                        class="scroll-area"
                        settings="settings"
                    >
                        <h1 class="formHeader">
                            Gruppenname<span class="red--text bold">*</span>
                        </h1>
                        <v-text-field
                            v-model="newGroupChatName"
                            filled
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 500px;"
                        />

                        <h1 class="formHeader">
                            Teilnehmer hinzufügen (mind. 2)<span class="red--text bold">*</span>
                        </h1>
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="searchTreeView"
                                filled
                                dense
                                hide-details
                                clearable
                                style="max-width: 500px;"
                            >
                                <template v-slot:prepend-inner>
                                    <img
                                        :src="searchIcon"
                                        alt="Suche"
                                        class="icon mr-2"
                                    >
                                </template>
                            </v-text-field>
                        </div>
                        <v-treeview
                            v-model="newGroupChatMembers"
                            :items="matrixUsers"
                            item-text="fullName"
                            item-key="account"
                            item-value="account"
                            :search="searchTreeView"
                            selected-color="var(--v-chatColor-base)"
                            selectable
                            dense
                            class="mt-2"
                            style="font-size: 16px"
                        />
                    </vue-custom-scrollbar>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        id="eK-button-disabled"
                        color="fhellgruen"
                        class="text-none"
                        dark
                        :disabled="newGroupChatName.trim() === '' || newGroupChatMembers.length < 2"
                        @click="createGroupChat()"
                    >
                        Erstellen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from "vuex";
import ToggleMatrixRoomButton from "./ToggleMatrixRoomButton";
import DeleteChatButton from "@/components/Chat/Components/DeleteChatButton";
import vueCustomScrollbar from "vue-custom-scrollbar";
import suchIcon from '../../assets/Icons/suche-26.svg';
import abbrechenIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import erledigtIcon from '../../assets/Icons/fertig-74.svg';
import hinzufuegenIcon from '../../assets/Icons/plus.svg';
import chatIcon from '../../assets/Icons/chat-alternative-filled-102.svg';
import lehrerIcon from '../../assets/Icons/teacher.svg';
import schuelerIcon from '../../assets/Icons/profile.svg';
import groupIcon from '@/assets/Icons/group.svg';
import searchIcon from '@/assets/Icons/lupe-18.svg';

export default {
        name: "ChatWidget",
        components: {DeleteChatButton, vueCustomScrollbar, ToggleMatrixRoomButton},
        props: {
            showTooltips: {required: false, default: true},
            rooms: {required: true},
            currentChild: {required: false, type: Object}
        },
        data: () => ({
            contact: '',
            matrixUsers: [],
            maintainers: [],
            notificationArray: [],
            addNew: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            chatIds: [],

            teachers: [],
            teacherPics: [], //collection of teacher profile pictures

            createGroupChatDialog: false,
            newGroupChatName: '',
            newGroupChatMembers: [],
            searchTreeView: '',

            systemCreatedMatrixRoomsIds: [],   // ids of group chats generated by the system

            stopTimeout: false,
            refreshKey: 0,

            suchIcon,
            abbrechenIcon,
            erledigtIcon,
            hinzufuegenIcon,
            chatIcon,
            lehrerIcon,
            schuelerIcon,
            groupIcon,
            searchIcon,
        }),
        computed: {
            ...mapGetters("auth", ["accountRole", "accountId"]),
            roomsWithMessages() {
                // mention this key to manually trigger recomputes via intervals
                this.refreshKey;
                const temp = [];
                for (let i = 0; i < this.rooms.length; i++) {
                    temp[i] = this.rooms[i];
                    temp[i].newMessagesCount = this.notificationArray[i]?.newMessagesCount || 0;
                    temp[i].newMessagesAvailable = this.notificationArray[i]?.newMessagesAvailable || false;
                }
                return temp;
            },
            canUserDeleteRoom() {
                return (room) => {
                    const notTeacherChatroom = room.name !== 'Lehrer';
                    const notSystemCreatedRoom = !this.systemCreatedMatrixRoomsIds.includes(room._id);
                    return this.accountRole === 'teacher' && notTeacherChatroom && notSystemCreatedRoom;
                };
            }
        },
        watch: {
            async currentChild() {
                if (this.accountRole === 'parent' && this.currentChild) {
                    const appointments = await this.getAppointmentsParent(this.currentChild._id);
                    appointments.forEach((el) => {
                        if (el.teachers) {
                            if(el.teachers instanceof Array) {
                                el.teachers.forEach((element) => {
                                    const index = this.matrixUsers.indexOf(element);
                                    if (index === -1) {
                                        this.matrixUsers.push(element);
                                    }
                                });
                            } else {
                                this.matrixUsers.indexOf(el.teachers);
                                if (this.matrixUsers.indexOf(el.teachers) === -1) {
                                    this.matrixUsers.push(el.teachers);
                                }
                            }
                        }
                    })
                }
            },
        },
        async mounted() {
            this.notificationArray = [];
            this.refreshRoomsWithMessages();
            await Promise.all([this.requestAppointments(), this.requestPupil()]);
            await this.requestTeachers();
            await this.requestMaintainers();
            this.getSystemCreateRoomIds();
            this.refreshIntervalKey();
        },
        async unmounted() {
            this.teacherPics.forEach(async (el) => {
                if(el[2]) {
                    URL.revokeObjectURL(el[2]);
                }
            });
            this.stopTimeout = true;
        },
        methods: {
            ...mapActions("appointments", ["getAppointments", "getAppointmentsParent"]),
            ...mapActions("pupils", ["getPupils"]),
            ...mapActions("matrix", ['createCustomRoom']),
            ...mapActions("parents", ["getParents"]),
            ...mapActions("teachers", ["getProfilePicture", "getTeachers", 'getMeTeacher']),
            ...mapActions("maintainers", ["getMaintainers"]),
            ...mapActions('groups', ['getGroupsInfo']),
            ...mapMutations("snackbar", ["showSnackbar"]),
            ...mapMutations('badges', [ 'setChatBadge' ]),

            async setFocusOn(elementId) {
                await this.$nextTick();
                document.getElementById(elementId).focus();
            },

            async startPrivateRoom() {
                if (this.contact) {
                    if (!this.chatIds.includes(this.contact)) {
                        await this.createCustomRoom({second: this.contact});
                        this.$emit("updateRoomList");
                        this.addNew = false;
                        this.contact = '';
                    } else {
                        this.showSnackbar({ message: "Sie haben schon einen Chat mit diesem Nutzer" });
                    }
                }
            },
            async requestAppointments() {
                if (this.accountRole === 'pupil') {
                    const appointments = await this.getAppointments();
                    appointments.forEach((el) => {
                        if (el.teachers) {
                            if (el.teachers instanceof Array) {
                                el.teachers.forEach((element) => {
                                    const index = this.matrixUsers.indexOf(element);
                                    if (index === -1) {
                                        this.matrixUsers.push(element);
                                    }
                                });
                            } else {
                                this.matrixUsers.indexOf(el.teachers);
                                if (this.matrixUsers.indexOf(el.teachers) === -1) {
                                    this.matrixUsers.push(el.teachers);
                                }
                            }
                        }
                    })
                }
                if (this.accountRole === 'parent' && this.currentChild) {
                    const appointments = await this.getAppointmentsParent(this.currentChild._id);
                    appointments.forEach((el) => {
                        if (el.teachers) {
                            if (el.teachers instanceof Array) {
                                el.teachers.forEach((element) => {
                                    const index = this.matrixUsers.indexOf(element);
                                    if (index === -1) {
                                        this.matrixUsers.push(element);
                                    }
                                });
                            } else {
                                this.matrixUsers.indexOf(el.teachers);
                                if (this.matrixUsers.indexOf(el.teachers) === -1) {
                                    this.matrixUsers.push(el.teachers);
                                }
                            }
                        }
                    })
                }
            },
            async requestPupil() {
                if (this.accountRole === 'teacher') {
                    const pupils = await this.getPupils();
                    const teachers = await this.getTeachers();
                    const parents = await this.getParents();

                    const mappedPupils = pupils.map(pupil => ({ ...pupil }));
                    let mappedTeachers = teachers.map(teacher => ({ ...teacher }));
                    const mappedParents = parents.map(parent => ({
                        ...parent,
                        account: parent.account,
                        name: pupils.find((pupil) => pupil._id === parent.pupils[0])?.name || "Unbekannt",
                        lastName: 'Eltern',
                    }));

                    // filter out teacher that's using the chat so he can't try to start chat with himself
                    const me = await this.getMeTeacher();
                    mappedTeachers = mappedTeachers.filter(teacher => teacher.name !== me.name && teacher.lastName !== me.lastName);

                    this.matrixUsers = [
                        ...mappedPupils,
                        ...mappedTeachers,
                        ...mappedParents
                    ].sort((a, b) => a.name.localeCompare(b.name));
                    this.matrixUsers.map(el => el.fullName = el.name + ' ' + el.lastName);
                }
            },
            async requestTeachers() {
                this.teachers = await this.getTeachers();
                if (this.accountRole === 'maintainer') {
                    this.matrixUsers = await JSON.parse(JSON.stringify(this.teachers));
                    this.matrixUsers.map(el => el.fullName = el.name + ' ' + el.lastName);
                    this.matrixUsers.sort((a, b) => a.name.localeCompare(b.name));
                }

                //trim down the ridiculous size of duplicate matrixUsers, only keep unique entries
                this.matrixUsers = [
                    ...new Set(this.matrixUsers.map((el) => JSON.stringify(el)))]
                    .map((str) => JSON.parse(str));
                this.matrixUsers.map(el => el.fullName = el.name + ' ' + el.lastName);
                await this.setTeacherProfilePics();
            },
            async requestMaintainers() {
                if (this.accountRole === 'teacher') {
                    this.maintainers = await this.getMaintainers();
                }
            },
            checkRoomName(name) {
                // group room names look like: group Room <id, groupName>, so return substring between , and >
                if (name.includes("group Room")) {
                    const start = name.indexOf(',');
                    const end = name.indexOf('>');
                    return name.slice(start+1, end).trim();
                }
                const id = name.replace(this.accountId, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", "");
                let secondUser = this.matrixUsers.find((el) => {
                    return el.account === id;
                });
                if (secondUser) {
                    if (!this.chatIds.includes(id)) {
                      this.chatIds.push(id);
                    }
                    return secondUser.lastName +  ' ' + secondUser.name;
                } else {
                    if (this.accountRole === 'teacher') {
                        secondUser = this.maintainers.find((el) => {
                            return el.account === id;
                        });
                        if (secondUser) {
                            return 'Verwaltung';
                        } else {
                            return 'Unbekannter Nutzer';
                        }
                    }
                    return 'Unbekannter Nutzer';
                }
            },
            clickSelectRoom(room) {
                this.$emit("roomSelected", room);
            },
            async checkForNewMessages(room, index) {
                const lastReadId = await room.getEventReadUpTo(room.myUserId, false);
                let unreadEventsCount = 0;
                let unreadMessageCount = 0;

                for (let i = 0; i < room.timeline.length; i++) {
                    if (lastReadId === room.timeline[i].event.event_id) {
                        unreadEventsCount = room.timeline.length - i - 1;
                        break;
                    }
                    unreadEventsCount = room.timeline.length;
                }
                for (let i = 0; i < unreadEventsCount; i++) {
                    if(room.timeline[room.timeline.length - 1 - i].event.type === "m.room.message") {
                        unreadMessageCount++;
                    }
                }
                this.notificationArray[index] = {
                    newMessagesCount: unreadMessageCount,
                    newMessagesAvailable: unreadMessageCount > 0,
                };
            },
            customFilter(item, queryText, itemText) {
                const textOne = itemText.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1;
            },
            refreshRoomsWithMessages() {
                for (let i = 0; i < this.rooms.length; i++) {
                    this.checkForNewMessages(this.rooms[i], i);
                }
                let allNewMessagesCount = 0;
                this.rooms.forEach((room) => {
                        allNewMessagesCount += room.newMessagesCount;
                    });
                this.setChatBadge(allNewMessagesCount);
            },
            // this loop terminates once component is unmounted
            refreshIntervalKey() {
                if (!this.stopTimeout) {
                    this.refreshKey++;
                    setTimeout(() => {
                        this.refreshRoomsWithMessages();
                        this.refreshIntervalKey();
                    }, 15000);
                }
            },
            async setTeacherProfilePics() {
                this.teachers.map(async (teacher) => {
                    let matchingTeacher = this.matrixUsers.find((matrixUser) => {
                        return teacher.account == matrixUser.account;
                    });
                    if (matchingTeacher){
                        if (matchingTeacher.profilePicture) {
                            let profilePictureBlob = await this.getProfilePicture(matchingTeacher._id);
                            const objectURL = URL.createObjectURL(profilePictureBlob);
                            this.teacherPics.push([matchingTeacher.name, matchingTeacher.lastName, objectURL]);
                        }
                    }
                });
            },

            // get teacher profile pic from teacherPics array
            // this method looks questionable, its doing the work of setTeacherProfilePics
            // when it would be easier to just match matrixUser.account to the teacherPics array
            getTeachPic() {
                for (let i = 0; i < this.teachers.length; i++) {
                    let teacher = this.matrixUsers.find((el) => {
                        return el.account === this.teachers[i].account;
                    });

                    if (teacher) {
                        if (teacher.profilePicture) {
                            for (let j = 0; j < this.teachers.length; j++) {
                                let currTeacher = this.teacherPics[j];
                                if (currTeacher === undefined) {
                                    continue;
                                } else if (currTeacher[0] === teacher.name && currTeacher[1] === teacher.lastName) {
                                    return currTeacher[2];
                                }
                            }
                        }
                    }
                }
                return lehrerIcon;
            },
            //checks if chat opponent has a profile pic
            hasProfilePic(room) {
                let name = this.checkRoomName(room.name)
                let names = [];
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.teacherPics.length; j++) {
                        let currTeacher = this.teacherPics[j];
                        if (currTeacher === undefined) {
                            continue;
                        } else if (currTeacher[0] === names[1] && currTeacher[1] === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            //checks if chat opponent a teacher
            isTeacher(room) {
                let name = this.checkRoomName(room.name)
                let names = []; //lastname, firstname
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.teachers.length; j++) {
                        let currTeacher = this.teachers[j];
                        if (currTeacher === undefined) {
                            continue;
                        } else if (currTeacher.name === names[1] && currTeacher.lastName === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            // check if chat opponent is a pupil
            isPupil(room) {
                let name = this.checkRoomName(room.name)
                let names = []; //lastname, firstname
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.matrixUsers.length; j++) {
                        let currentPupil = this.matrixUsers[j];
                        if (currentPupil && currentPupil.name === names[1] && currentPupil.lastName === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            },

            isGroup(room) {
                return room.name.includes("group Room");
            },

            async handleLockStatusChanged(isOpen, roomId) {
                this.$emit("handleLockStatusChanged", isOpen, roomId);
            },

            async createGroupChat() {
                this.newGroupChatMembers.push(this.accountId);  // add creator to members list
                await this.createCustomRoom({groupName: this.newGroupChatName, members: this.newGroupChatMembers});
                this.createGroupChatDialog = false;
                this.$emit("updateRoomList");
            },

            // saves the ids of the system created matrix room ids into systemCreateMatrixRoomsIds
            async getSystemCreateRoomIds() {
                let groups = await this.getGroupsInfo();
                this.systemCreatedMatrixRoomsIds = groups.map(el => el.matrixRoom);
                this.systemCreatedMatrixRoomsIds =
                    this.systemCreatedMatrixRoomsIds.filter(el => { return el !== undefined });
            }
        }
    };
</script>


<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control>.v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control>.v-input__slot:before, .v-input__control>.v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>
<style lang="scss" scoped>
    .group-list-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .group-chat-header-btn {
        padding: 0 !important;
        min-width: 0 !important;
    }

    .group-chat-header-text {
        color: white;
    }

    .group-chat-header {
        background-color: #95c94b; // 8CBD46 - 5% Luminosity
    }

    .chat-container {
        height: auto;
        width: 90%;
        min-height: 80vh;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto auto 5em;
    }

    .chat-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .chat-header {
        background-color: #8ab450;
        border-radius: 15px 15px 0 0;
        font-size: larger;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        color: white;
    }

    .chat-header-btn {
        background-color: #f8f8f880 !important;
        width: 35px;
        height: 35px !important;
    }

    .chat-header-img {
        height: 20px;
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .scroll-area {
        flex: 1;
    }

    .greyText{
        color: var(--v-dunkelgrau-base);
    }

    .icon {
        height: 20px;
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .roundImage {
        border-radius: 50%;
        height: 56px;
        width: 56px;
    }

    .chatIcon {
        height: 25px;
    }

    #eK-button-disabled.v-btn--disabled {
        opacity: 0.5;
        background-color: var(--v-grau-base) !important;
        color: white !important;
    }

    .formHeader {
        font-size: 16px;
        margin-top: 40px !important;
        margin-bottom: 20px !important;
    }
</style>
