import * as backend from "../../api/backend";
import { checkResponseStatus } from "../../util/check";

const defaultState = {
  speechControl: false,
  word: '',
};

const mutations = {
  setSpeechControl(state, value) {
    state.speechControl = value;
  },
  setSpeechControlWord(state, value) {
    state.word = value;
  }
};

const actions = {
  toggleSpeechControl({ commit, state }, param) {
    commit("setSpeechControl", param);
  },

  turnOffSpeechControl({ commit, state }) {
    commit("setSpeechControl", false);
  },
  setSpeechControlWord({ commit, state }, word) {
    commit("setSpeechControlWord", word);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
