<template>
    <div
        ref="editorTextMessageContainer"
        v-click-outside="setNoteText"
        class="textMessageOuter"
    >
        <!-- Toggle to open Post-it -->
        <v-tooltip
            v-if="!postItOpen"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="!postItOpen"
                    fab
                    elevation="2"
                    :tabindex="tabIndex"
                    color=""
                    v-on="on"
                    @click="openTextMessageMenu"
                    @keypress.enter="openTextMessageMenu"
                >
                    <img
                        alt="Notiz"
                        :src="noteIcon"
                        style="height: 30px; position: absolute;"
                    >
                </v-btn>
            </template>
            <span>Textnotiz öffnen</span>
        </v-tooltip>
        <!-- Post-It (with Textarea if no text came from backend) -->
        <div
            v-if="postItOpen"
            id="postItContainer"
            :style="`font-size: ${sizeState ? (sizeState === 1 ? 'x-large' : 'xx-large') : 'large'}`"
        >
            <textarea
                v-if="showAuthorUI"
                v-model="message.text"
                placeholder="Geben Sie hier die Notiz ein..."
                class="pa-4 postItUiComponent"
                style="pointer-events: all; height: 100%; width: 100%; border: none; resize: none;"
                :style="`font-size: ${sizeState ? (sizeState === 1 ? 'x-large' : 'xx-large') : 'large'}`"
                @focusout="focusOutClose"
            />
            <!-- Disable v-html xss checking entry.content is provided only by developers -->
            <!-- eslint-disable vue/no-v-html -->
            <p
                v-else
                class="pa-4 postItUiComponent"
                style="word-break: break-all"
                v-html="sanitizeHtml(message.text)"
            />
            <!-- eslint-enable vue/no-v-html --> 
        </div>
        <!-- Absolute Buttons -->
        <!-- close post-it -->
        <v-tooltip
            v-if="postItOpen"
            right
        >
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                    v-if="postItOpen"
                    class="sideButton editorOptionsFade postItUiComponent"
                    x-small
                    :style="`top: ${message.author === accountId ? '55' : '0'}px;`"
                    v-on="{ ...onTooltip }"
                    @click="setNoteText"
                >
                    <img
                        :src="schliesenIcon"
                        alt="Textnotiz schließen"
                    >
                </v-btn>
            </template>
            <span>Textnotiz schließen</span>
        </v-tooltip>
        <!-- set fontsize -->
        <v-tooltip
            v-if="postItOpen && accountRole === 'teacher' && !previewPupilUI"
            right
        >
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                    v-if="postItOpen && (accountRole === 'teacher' || message.author === accountId)"
                    ref="changeSizePostItButton"
                    class="sideButton editorOptionsFade postItUiComponent"
                    x-small
                    style="top: 105px;"
                    v-on="{ ...onTooltip }"
                    @click="changeSize"
                >
                    <img
                        :src="currentSizeIcon"
                        alt="Größe ändern"
                    >
                </v-btn>
            </template>
            <span>Größe ändern</span>
        </v-tooltip>
    </div>
</template>

<script>
import noteIcon from '../../assets/Icons/text-note.svg';
import schliesenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import { mapActions, mapState, mapGetters } from 'vuex';
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";

import sanitizeHtml from '../../util/sanitizeHtml';


export default {
    name: 'EditorTextMessage',
    props: {
        message: { type: Object, required: true },
        mode: { type: String, required: true },
        index: { type: Number, required: true },
        targetLang: { type: String, required: false, default: 'de' },
        translate: { type: Boolean, required: false, default: false },
        tabIndex: { type: Number, required: false, default: 0 },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            sanitizeHtml,

            //Icons
            noteIcon,
            schliesenIcon,
            sizeSmallIcon,
            sizeMediumIcon,
            sizeLargeIcon,

            postItOpen: false,
            sizeState: 0,

            // Note
            noteTextValue: '',

            // Translate
            alreadyTranslated: false,   //whether the texts were already translated once
            untranslatedMessage: null,  //store original text
            translatedMessage: null,    //store translated text (so translating is only done once)
            person: {
                accessibility: {
                    screenreader: false,
                }
            }
        };
    },
    computed: {
        ...mapGetters("auth",["accountRole", 'accountId']),
        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        showAuthorUI() {
            return this.mode === 'creator' || this.message.author === this.accountId;
        }
    },
    watch: {
        translate() {
            this.toggleTextLanguage();
        },
    },
    mounted() {
        this.storeOriginalTexts();
        this.requestPerson();
        this.sizeState = this.message.fontSize || 0;
    },
    methods: {
        ...mapActions('translation', [ 'translateToTargetLang' ]),
        ...mapActions('pupils', [ 'getMePupil' ]),

        openTextMessageMenu(event) {
            event.stopPropagation()
            this.postItOpen = true;
        },

        changeSize() {
            this.sizeState !== 2 ? this.sizeState++ : this.sizeState = 0;
            this.message.fontSize = this.sizeState;
        },

        focusOutClose(event) {
            // if (!document.querySelector(":focus")[0].classList.contains('postItUiComponent')) {
                this.postItOpen = !this.postItOpen;
            // }
        },

        async requestPerson() {
            this.person = await this.getMePupil();
        },

        storeOriginalTexts() {
            this.untranslatedMessage = this.message.text;
        },

        async toggleTextLanguage() {
            //translate and replace displayed texts
            if (this.translate && !this.alreadyTranslated) {
                this.translatedMessage = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: this.message.text
                });
                this.message.text = this.translatedMessage;

                this.alreadyTranslated = true;
            }
            //just replace displayed texts with stored translated texts
            else if (this.translate && this.alreadyTranslated) {
                this.message.text = this.translatedMessage;
            }
            //set texts to original language
            else {
                this.message.text = this.untranslatedMessage;
            }
        },
        setNoteText() {
            if (!this.message.text) {
                this.message.text = this.noteTextValue;
            }
            this.postItOpen = false;
        },
    }
}
</script>

<style scoped lang="scss">
    .textMessageOuter {
        z-index: 7 !important;
        max-width: 300px;
    }
    /* ----------------------------------------------
    * Generated by Animista on 2021-1-19 12:22:3
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info.
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation heartbeat
     * ----------------------------------------
     */
    @-webkit-keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .heartbeat {
        -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
        animation: heartbeat 2s ease-in-out 2s infinite both;
    }

    @keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .iconToWhite {
        filter: brightness(1000%);
    }

    .playButton {
        text-transform: inherit;
        color: white;
    }

    .notesButtonsImage {
        height: 30px;
        filter: brightness(0%);
    }

    .acceptEntryButton {
        width: 36px !important;
        height: 36px !important;

        img {
            width: 20px !important;
            height: 20px !important;
        }
    }

    #postItContainer {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        background-color: #FFFFA5;
        box-shadow: 5px 3px 4px var(--v-fdunkelgrau-base);
    }

    .sideButton {
        position: absolute;
        right: -55px;
        width: 45px !important;
        min-width: 45px !important;
        height: 45px !important;
        border-radius: 2px;

        img {
            width: 25px;
            height: 25px;
        }
    }
</style>
