import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'typeface-roboto/index.css';
import {VSelect} from "vuetify/lib/components";

Vue.use(Vuetify, {
    iconfont: 'mdi'
});
// Monkey patch to override standard text to be german for v-select if there is no data
VSelect.options.props.noDataText.default = 'Keine Daten vorhanden';

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#6996cd',
                secondary: '#3ba934',
                success: '#3ba934',
                error: '#e6231e',
                info: '#343e55',

                // TODO: replace color codes with variables in all components
                // HOW TO USE: if the Vue component supports color prop use it like color="gruen"
                // otherwise create a class and use like background-color: var(--v-gruen-base);
                headerblue: '#343e55',      // Header and bottom navigation bar
                widgetBg: '#ffffff',        // Background color for widget container
                weiss: '#f8f8f8',           // Standard Hintergrundfarbe
                hellgrau: '#eaeaea',        // Buttonfarbe #1
                grau: '#dbdbdb',            // Buttonfarbe #3
                dunkelgrau: '#707070',      // Schriftfarbe (#4)
                

                // Widget specific colors
                editorprimary: '#3ba934',
                editorWrong: '#E62D2D',
                editorNeutral: '#538ECB',
                editorGrey: '#ededed',
                fileArchiveOrange: '#FF6633',
                dunkelgruen: '#3caa69',     // Diary widget
                chatColor: '#3ba934',
                exchangeprimary: '#3caa69', // Exchange Widget main color
                userColor: '#E6272F',       // Users Widget
                timetableColor: '#6995CD',  // Plan Widget
                sickColor: '#ef2b2a',
                blackboardGrey: '#3c3d3b',  // Blackboard Widget
                parentLetterPurple: '#95509c',

                gelb: '#fac837',
                hellorange: '#f5a032',
                orange: '#f07d32',
                pink: '#e6415a',
                rot: '#e6231e',

                hellblau: '#6996cd',
                blau: '#505faa',
                gruen: '#3ba934',

                // Fach colors
                fgelb: '#f5be2d',
                forange: '#f0a03c',
                fpink: '#e67dff',
                frot: '#d50000',

                fhellblau: '#009ae4',
                fblau: '#3f4fb3',
                fhellgruen: '#2fb277',
                fgruen: '#0b8348',

                fmittelblau: '#7587c7',
                flila: '#94519B',
                fgrau: '#bdbdbd',
                fdunkelgrau: '#636363',
            },
        },
    },
});
