const getColorNameByHex = (hex) => {
    const hsl = hexToHsl(hex);
    const rgb = hexToRgb(hex)
    return addIntensity([rgb.r.toString(), rgb.g.toString(), rgb.b.toString()]) + ' ' + classifyColor(hsl);
}

const hexToHsl = (hex) => {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (hex.length == 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex.length == 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0)
        h = 0;
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    else if (cmax == g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}

const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const classifyColor = (hsl) => {
    var l = Math.floor(hsl.l), s = Math.floor(hsl.s), h = Math.floor(hsl.h);
    if (s <= 10 && l >= 90) {
        return ("Weiß")
    } else if (l <= 15) {
        return ("Schwarz")
    } else if ((s <= 10 && l <= 70) || s === 0) {
        return ("Grau")
    } else if ((h >= 0 && h <= 15) || h >= 346) {
        return ("Rot");
    } else if (h >= 16 && h <= 35) {
        if (s < 90) {
            return ("Braun");
        } else {
            return ("Orange");
        }
    } else if (h >= 36 && h <= 54) {
        if (s < 90) {
            return ("Braun");
        } else {
            return ("Gelb");
        }
    } else if (h >= 55 && h <= 165) {
        return ("Grün");
    } else if (h >= 166 && h <= 260) {
        return ("Blau")
    } else if (h >= 261 && h <= 290) {
        return ("Lila")
    } else if (h >= 291 && h <= 345) {
        return ("Rosa")
    }
}

const addIntensity = (rgb) => {
    var hex = "";
    hex += Number(rgb[0]).toString(16), hex += Number(rgb[1]).toString(16), hex += Number(rgb[2]).toString(16);
    let txt = "";
    var rgbParse = parseInt(hex, 16);
    var r = (rgbParse >> 16) & 0xff;
    var g = (rgbParse >>  8) & 0xff;
    var b = (rgbParse >>  0) & 0xff;
    var inten = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    if(inten >= 80 && inten <= 100){
        txt = "Dunkleres";
    }    else if(inten < 40){
        txt = "Dunkles";
    }    else{
        txt = "Helles";
    }
    return txt;
}

export { getColorNameByHex };
