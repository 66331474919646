<template>
    <div
        style="background-color: #f8f8f8;"
        class="backgroundImg"
    >
        <!--bottom navigation bar, used only for mobile devices-->
        <div
            v-if="!hideNavbar"
            class="btmNav"
        >
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%"
            >
                <v-btn
                    v-for="item in btmNavItems"
                    :key="item.name"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="currSlide === item.goToIndex ? 'background-color:' + item.bgColor + '!important;' : ''"
                    @click="switchSlide(item.goToIndex)"
                >
                    <v-badge
                        :content="getBadge(item.goToIndex)"
                        :value="getBadge(item.goToIndex)"
                        offset-x="27"
                        color="#ff0000"
                        style="z-index: 1;"
                    >
                        <img
                            v-if="item.icon"
                            :src="item.icon"
                            class="btmNavIcon"
                            :style="currSlide === item.goToIndex ? 'filter: brightness(1000%);' : item.color"
                            :alt="item.name"
                        >
                        <v-icon
                            v-if="item.mdi"
                            size="xx-large"
                            :style="currSlide === item.goToIndex ? 'color: white' : `color: ${item.bgColor}`"
                        >
                            {{ item.mdi }}
                        </v-icon>
                    </v-badge>
                </v-btn>

                <!--textToSpeech when text is selected-->
                <!-- <v-btn
                    v-if="textSelected"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-size="'35px'"
                    />
                </v-btn> -->

                <v-badge
                    v-if="showEmailBadge"
                    content="1"
                    offset-x="-30"
                    offset-y="20"
                    class="align-self-start insetBadge"
                    color="#3ba934"
                    style="z-index: 9; margin-left: -16px;"
                />
                <div @click="sidebarMobile = true">
                    <Avatar
                        :profile="accountId"
                        :show-burger="true"
                        alt="Profil öffnen"
                    />
                </div>
            </div>
        </div>
        <v-overlay
            v-if="sidebar || sidebarMobile || supportPageOpen"
            z-index="10"
            overlay-opacity="0.8"
        />
        <v-overlay
            v-if="!hidePreloader && (
                windowWidth < 901 && !allWidgetsMounted ||
                windowWidth > 900 && page === 0 && !page0WidgetsMounted || 
                windowWidth > 900 && page === 1 && !page1WidgetsMounted
            )"
            z-index="50"
            :opacity="1.0"
            color="white"
        >
            <Preloader />
        </v-overlay>
        <Sidebar
            v-if="sidebarMobile"
            v-model="sidebarMobile"
            :mobile="sidebarMobile"
            :sidebar-open="sidebarMobile"
            :current-child="currentChild"
            @currentChildChanged="overrideCurrentChild"
            @closeSidebar="()=>{sidebar = false; sidebarMobile = false;}"
            @hideBadge="showEmailBadge = false"
            @openUsageAgreement="revokeUsageAgreement"
        />
        <!--top menu bar-->
        <v-overlay
            v-if="supportPageOpen || sidebar"
            z-index="11"
            overlay-opacity="0.8"
        />
        <v-row
            style="background-color: var(--v-headerblue-base); z-index: 12 !important;"
            class="displayHide"
        >
            <v-col
                class="d-flex align-center"
                style="margin-left: 2.5%"
                cols="4"
            >
                <!-- Sprechzimmer Button -->
                <join-sprechzimmer-button :current-child="currentChild" />
            </v-col>

            <!--name and options-->
            <v-col
                class="d-flex justify-end align-center pl-0"
                style="margin-right: 2.5%"
            >
                <div
                    v-if="children.length > 1"
                    class="d-flex align-center"
                >
                    <p class="ma-0 ml-2 ellipsis white--text">
                        {{ person ? 'Eltern von' : '' }}
                    </p>
                    <v-select
                        v-model="currentChild"
                        :items="children"
                        :item-text="item => item.name + '. ' + Array.from(item.lastName)[0]"
                        item-value="_id"
                        return-object
                        hide-details="true"
                        class="ma-0 ml-2 pa-0"
                        dark
                        style="max-width: 150px;"
                        @change="overrideCurrentChild(currentChild)"
                    />
                </div>

                <p
                    v-else
                    class="white--text"
                >
                    {{ 'Eltern von ' + children[0].name }}
                </p>

                <v-badge
                    :value="showCalendarBadge"
                    left
                    offset-x="10"
                    offset-y="15"
                    color="#ff0000"
                    class="ml-4"
                    :content="eventsNotSeen"
                >
                    <v-btn
                        v-if="!calendarDeactivated"
                        x-small
                        style="width: 50px; height: 50px; border-radius: 8px; background-color: #FFFFFF"
                        elevation="0"
                        @click="openCalendar"
                    >
                        <img
                            :src="kalenderIcon"
                            alt="Kalender"
                            class="iconToHeaderblue"
                            style="max-width: 25px; max-height: 25px;"
                        >
                    </v-btn>
                </v-badge>

                <v-tooltip
                    v-if="magnifier"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            style="background-color: var(--v-chatColor-base) !important; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            elevation="0"
                            class="ml-4"
                            v-on="on"
                            @click="turnOffMagnifier();"
                        >
                            <img
                                :src="lupenIcon"
                                style="max-width: 25px; max-height: 25px;"
                                alt="Lupe einschalten/ausschalten (Alt + L)"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span><span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)</span>
                        <img
                            v-if="person.accessibility.hoeren"
                            :src="lupeMetacom"
                            width="100"
                            style="margin: auto"
                            alt="Lupe"
                        >
                    </div>
                </v-tooltip>

                <v-tooltip
                    v-if="keyboard"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            x-small
                            elevation="0"
                            class="ml-4"
                            style="background-color: var(--v-chatColor-base) !important; width: 50px; height: 50px; border-radius: 8px;"
                            v-on="on"
                            @click="toggleKeyboard()"
                        >
                            <img
                                :src="keyboardIcon"
                                alt="Tastatursteuerung einschalten/ausschalten (Alt + T)"
                                height="25"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span><span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)</span>
                        <img
                            v-if="person.accessibility.hoeren"
                            :src="tastaturTippenMetacom"
                            width="100"
                            style="margin: auto"
                            alt="Tastatursteuerung"
                        >
                    </div>
                </v-tooltip>

                <v-btn
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    x-small
                    elevation="0"
                    class="ml-4 animateIcon"
                    @click="reloadPage()"
                >
                    <img
                        class="iconToHeaderblue"
                        :src="aktualisierenIcon"
                        style="max-width: 25px; max-height: 25px;"
                        alt="aktualisieren"
                    >
                </v-btn>

                <!--textToSpeech when text is selected-->
                <!-- <v-btn
                    v-if="textSelected"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0 ml-4"
                    style="background-color: #6995CD !important"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-color="'white'"
                    />
                </v-btn> -->

                <v-badge
                    v-if="showEmailBadge"
                    content="1"
                    overlap
                    left
                    offset-y="10"
                    class="ml-4 align-self-start insetBadge"
                    color="#3ba934"
                    style="z-index: 9; align-base"
                />
                <div
                    class="pointer"
                    :class="showEmailBadge ? '' : 'ml-4'"
                    @click="sidebar = true"
                >
                    <Avatar
                        :profile="accountId"
                        :show-burger="true"
                        :alt="'Profil öffnen'"
                    />
                </div>
                <Sidebar
                    v-if="sidebar"
                    v-model="sidebar"
                    :mobile="false"
                    :sidebar-open="sidebar"
                    :current-child="currentChild"
                    @currentChildChanged="overrideCurrentChild"
                    @closeSidebar="sidebar = false;"
                    @hideBadge="showEmailBadge = false"
                    @openUsageAgreement="revokeUsageAgreement"
                />
            </v-col>
        </v-row>

        <div
            v-if="windowWidth > 900 && !profileManagement "
            class="big mt-4"
            style="justify-content: space-around; align-items: center"
        >
            <v-tooltip
                v-if="!weeklyView && showNextSlide"
                right
            >
                <template v-slot:activator="{ on }">
                    <v-badge
                        :value="isUpdatesOnOtherPage"
                        right
                        offset-x="10"
                        offset-y="15"
                        class="badgeClass"
                        style="z-index: 1"
                        color="#ff0000"
                    >
                        <v-card
                            style="height: 50vh; display: flex"
                            class="buttonFocusHover"
                            @click="changePage(-1)"
                            v-on="on"
                        >
                            <v-btn
                                fab
                                small
                                color="#eaeaea"
                                style="width: 2vw"
                                class="align-self-center mx-1 mb-8"
                                icon
                            >
                                <img
                                    style="position: relative; margin: auto; width: 30px"
                                    :src="left"
                                    class="imgFocusHover"
                                    :alt="page === 0 ? 'Vorherige Seite mit Elternbriefen' : page === 1 ? 'Vorherige Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Vorherige Seite'"
                                >
                            </v-btn>
                        </v-card>
                    </v-badge>
                </template>
                <span>Vorherige Seite</span>
            </v-tooltip>
            <div>
                <BadgeExplanations
                    v-if="!weeklyView"
                    :small="false"
                />
                <keep-alive>
                    <PlanWidget
                        v-if="page === 0 && !planDeactivated"
                        :style="weeklyView ? 'width: 94vw;' : '' "
                        :current-child="currentChild"
                        @planBadge="isFirstPageUpdated.push('plan')"
                        @clearAllBadge="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                        @planMounted="planMounted = true"
                    />
                </keep-alive>
                <keep-alive>
                    <MessagesWidget
                        v-if="page === 1 && !weeklyView && !messagesDeactivated"
                        :role="'parent'"
                        :child-id="currentChild.account"
                        @badge="isSecondPageUpdated.push('parental')"
                        @clearBadge="isSecondPageUpdated.splice(isSecondPageUpdated.indexOf('parental'), 1);"
                        @clearAllBadges="isSecondPageUpdated = isSecondPageUpdated.filter((el) => el !== 'parental')"
                        @messagesMounted="messagesMounted = true"
                    />
                </keep-alive>
                <!--                    <FileWidget v-if="page === 1" style="height: 100vh"></FileWidget>-->
            </div>
            <div v-if="!weeklyView">
                <keep-alive>
                    <BrettWidget
                        v-if="page === 0 && !brettDeactivated"
                        :current-child="currentChild"
                        @brettBadge="isFirstPageUpdated.push('brett')"
                        @clearBrettBadge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                        @clearAllBadges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                        @brettMounted="brettMounted = true"
                    />
                </keep-alive>
                <keep-alive>
                    <AppWidget
                        v-if="page === 1 && !appsDeactivated"
                        :pinboards="allPinboards"
                        @appwidgetMounted="appwidgetMounted = true"
                    />
                </keep-alive>
            </div>
            <div v-if="!weeklyView">
                <keep-alive>
                    <chat-widget
                        v-if="page === 0 && !chatDeactivated"
                        :current-child="currentChild"
                        @chatMounted="chatMounted = true"
                    />
                </keep-alive>

                <!-- Placeholder -->
                <div
                    v-if="page === 1"
                    style="box-shadow: none"
                    class="widgetContainer"
                />
            </div>
            <v-tooltip
                v-if="!weeklyView && showNextSlide"
                left
            >
                <template v-slot:activator="{ on }">
                    <v-badge
                        :value="isUpdatesOnOtherPage"
                        left
                        offset-x="10"
                        offset-y="15"
                        class="badgeClass"
                        color="#ff0000"
                    >
                        <v-card
                            style="height: 50vh; display: flex"
                            class="buttonFocusHover"
                            @click="changePage(1)"
                            v-on="on"
                        >
                            <v-btn
                                fab
                                small
                                color="#eaeaea"
                                style="width: 2vw;"
                                class="align-self-center mx-1 mb-8"
                                icon
                            >
                                <img
                                    style="position: relative; margin:auto; width: 30px;"
                                    :src="right"
                                    class="imgFocusHover"
                                    :alt="page === 0 ? 'Nächste Seite mit Elternbriefen' : page === 1 ? 'Nächste Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Nächste Seite'"
                                >
                            </v-btn>
                        </v-card>
                    </v-badge>
                </template>
                <span>Nächste Seite</span>
            </v-tooltip>
        </div>
        <ProfileManagement
            v-else-if="profileManagement"
            style="height: 100%; min-height: 100vh"
        />
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="resetTutorial()"
        />
        <VueSlickCarousel
            v-else
            ref="carousel"
            class="small topPadding"
            :style="weeklyView ? 'width: 90vw; margin-left: auto; margin-right: auto;' : ''"
            :arrows="false"
            :initial-slide="initialSlide"
            :infinite="false"
            :dots="false"
            :touch-move="false"
            @afterChange="vueSlickCarouselAfterChange"
        >
            <PlanWidget
                v-if="!planDeactivated"
                :small="true"
                style="height: 100vh"
                :current-child="currentChild"
                :reset="reloadPlan"
                @planRemounted="reloadPlan = false"
                @planBadge="isFirstPageUpdated.push('plan')"
                @clearAllBadge="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                @planMounted="planMounted = true"
            />
           
            <BrettWidget
                v-if="!brettDeactivated"
                style="height: 100vh"
                :class="weeklyView ? 'ml-14' : ''"
                :current-child="currentChild"
                :reset="reloadBrett"
                @brettRemounted="reloadBrett = false"
                @brettBadge="isFirstPageUpdated.push('brett')"
                @clearBrettBadge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                @clearAllBadges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                @brettMounted="brettMounted = true"
            />
            <!-- <v-lazy v-if="!chatDeactivated"> -->
            <chat-widget
                v-if="!chatDeactivated"
                :current-child="currentChild"
                class="mt-4"
                :reset="reloadChat"
                @chatRemounted="reloadChat = false"
                @chatMounted="chatMounted = true"
            />
            <!-- </v-lazy> -->
            <MessagesWidget
                v-if="!messagesDeactivated"
                style="height: 100vh"
                class="mt-4"
                :role="'parent'"
                :child-id="currentChild.account"
                :reset="reloadMessages"
                @messagesRemounted="reloadMessages = false"
                @badge="isSecondPageUpdated.push('parental')"
                @clearBadge="isSecondPageUpdated.splice(isSecondPageUpdated.indexOf('parental'), 1);"
                @clearAllBadges="isSecondPageUpdated = isSecondPageUpdated.filter((el) => el !== 'parental')"
                @messagesMounted="messagesMounted = true"
            />
            <AppWidget
                v-if="!appsDeactivated"
                style="height: 100vh"
                class="mt-4"
                :pinboards="allPinboards"
                :reset="reloadAppwidget"
                @appWidgetRemounted="reloadAppwidget = false"
                @appwidgetMounted="appwidgetMounted = true"
            />
        </VueSlickCarousel>


        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
        <ForceLogoutDialog
            :current-account="crtAccount._id"
            :show-force-logout-dialog="showForceLogoutDialog"
            :other-connected-devices="otherConnectedDevices"
            @closeForceLogout="(showSnackbar)=>{if(showSnackbar){ confirmForceLogout();} showForceLogoutDialog = false}"
            @confirmForceLogout="confirmForceLogout"
        />
        <SlideTutorial
            v-if="getPrivacyPolicy"
            :force-tutorial="getForcePrivacyPolicy"
            :content="privacyPolicySlidesContent"
            :subtitles-german="privacyPolicySlidesSubtitle"
            :show-skip-button="!getForcePrivacyPolicy"
            @close="closePrivacyPolicyTutorial()"
        />

        <calendar-app
            v-if="showCalendarWidget"
            @close="() => {showCalendarWidget = false; hasUserNewEvents();}"
        />

        <Nutzungsbedingungen
            :usage-agreement-not-signed="usageAgreementNotSigned"
            :current-account="crtAccount"
            :revoke="revokeAgreement"
            @closeDialog="()=>{usageAgreementNotSigned = false}"
        />
        <v-tooltip
            v-if="textSelected"
            :disabled="!showTooltips"
            bottom
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    elevation="0"
                    style="z-index: 201; padding: 0px !important;"
                    :style="buttonStyle"
                    max-width="30"
                    width="30"
                    height="30"
                    icon
                    max-height="30"
                    color="var(--v-headerblue-base)"
                    v-on="on"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-color="'white'"
                        alt="Vorlesen"
                    />
                </v-btn>
            </template>
            <span>Vorlesen</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import TTSSmall from "../TextToSpeechSmall";
import PlanWidget from "../SharedViews/Stundenplan/Schueler_und_Lehrer/PlanWidget";
import BrettWidget from "@/components/BrettWidget";
import ChatWidget from "../ChatV2/ChatWidgetV2";
import MessagesWidget from "@/components/MessagesWidget";
import AppWidget from "@/components/Apps/AppWidget";
import JoinSprechzimmerButton from "@/components/JoinSprechzimmerButton";
import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";
import Sidebar from "@/components/Sidebar";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import ProfileManagement from "@/components/ProfileManagement";
import SlideTutorial from "@/components/Tutorial/SlideTutorial";
import Preloader from "@/components/Utils/Preloader.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import SupportChat from "../Support/SupportChat";

import deFlagIcon from '@/assets/Icons/deutsch-59.svg';
import frFlagIcon from '@/assets/Icons/französisch-60.svg';
import enFlagIcon from '@/assets/Icons/englisch-61.svg';
import trFlagIcon from '@/assets/Icons/türkisch-97.svg';
import arFlagIcon from '@/assets/Icons/syrisch-98.svg';
import ruFlagIcon from '@/assets/Icons/russisch.svg';
import itFlagIcon from '@/assets/Icons/flag-italy.svg';
import roFlagIcon from '@/assets/Icons/flag-romania.svg';
import kuFlagIcon from '@/assets/Icons/flag-kurdistan.svg';
import left from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import right from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import logoutIcon from '@/assets/Icons/redesignV3/exit-red.svg';
import stundenplanIcon from '@/assets/Icons/timetable.svg';
import brettIcon from '@/assets/Icons/brett.svg';
import chatIcon from '@/assets/Icons/chat-alternative-filled-102.svg';
import menuIcon from '@/assets/Icons/FaceliftIcons/menu_lang_weiss.svg';
import abbrechenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import aktualisierenIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';
import kalenderIcon from "../../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import briefIcon from '@/assets/Icons/letter.svg';
import ukFlagIcon from "@/assets/Icons/flag-ukraine.svg";
import privacyPolicySlide1 from "@/assets/Tutorial/privacyPolicySlide1.png";
import privacyPolicySlide2 from "@/assets/Tutorial/privacyPolicySlide2.png";
import privacyPolicySlide3 from "@/assets/Tutorial/privacyPolicySlide3.png";
import privacyPolicySlide4 from "@/assets/Tutorial/privacyPolicySlide4.png";
import privacyPolicySlide5 from "@/assets/Tutorial/privacyPolicySlide5.png";
import keyboardIcon from '../../assets/Icons/keyboard-24px.svg';
import tastaturTippenMetacom from '../../assets/METACOM/Allgemein/tastaturtippen.png';
import lupenIcon from '../../assets/Icons/redesignV3/magnifier.svg';
import lupeMetacom from '../../assets/METACOM/Allgemein/suchenlupe.png';
import CalendarApp from "../Calendar/CalendarApp.vue";
import * as backend from "../../api/backend";
import EventBus from "../../util/eventBus";
import Nutzungsbedingungen from '../Policys/Nutzungsbedingungen.vue';
import BadgeExplanations from '../Utils/BadgeExplanations.vue';
import ForceLogoutDialog from '../Utils/ForceLogoutDialog.vue';

export default {
    name: "Plan",
    components: {
        SlideTutorial,
        ProfileManagement,
        ChatWidget,
        MessagesWidget,
        AppWidget,
        TTSSmall,
        PlanWidget,
        BrettWidget,
        VueSlickCarousel,
        JoinSprechzimmerButton,
        VideoTutorial,
        Sidebar,
        Avatar,
        CalendarApp,
        SupportChat,
        Preloader,
        Nutzungsbedingungen,
        BadgeExplanations,
        ForceLogoutDialog,
    },
    data() {
        return {
            hidePreloader: false,

            usageAgreementNotSigned: false,
            revokeAgreement: false,
            sidebar: false,
            sidebarMobile: false,

            isSecondPageUpdated: [],
            isFirstPageUpdated: [],

            crtAccount: null,

            allPinboards: [],

            showEmailBadge: false,
            date: new Date(),
            appointments: [],
            person: null,
            children: [],
            currentChild: null,
            currentTTSLangImg: deFlagIcon,
            page: 0,
            initialSlide: 0,
            currSlide: 0,               //to color icons of mobile bottom navBar
            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            textSelected: false,
            selectedText: '',

            videoTutorial: false,
            videoTutorialClosed: false,

            privacyPolicySlidesContent: [
                privacyPolicySlide1,
                privacyPolicySlide2,
                privacyPolicySlide3,
                privacyPolicySlide4,
                privacyPolicySlide5,
            ],
            privacyPolicySlidesSubtitle: [
                "Jeder Schule bei EKLARA wird ein sicherer Schulserver von Hetzner mit Serverstandort in Deutschland bereitgestellt. ",
                "EKLARA nutzt die sichere online HTTPS + SSL-Verschlüsselung, um dich und deine Daten während der Nutzung besser zu schützen.",
                "Texte und Inhalte, die du übersetzen lassen möchtest werden mit Google geteilt.",
                "Achtung, personenbezogene Daten wie Namen, Mailadressen, Telefonnummern oder Adressen dürfen ohne Genehmigung nicht mit Google geteilt werden!",
                "Diese Funktion kann individuell ein- und ausgeschaltet werden.",
                "Direkte Anfragen zur Übersetzung von Texten an Google hinterlassen auch Informationen zu deinem Standort.",
                "Um dich und deinen Standort online besser zu schützen, übernimmt stattdessen EKLARA für dich über einen anonymisierten und dezentralen Dienst die Anfragen zur Übersetzung an Google.",
                "EKLARA anonymisiert hier nur deinen Standort, nicht deine zu übersetzenden Texte und Inhalte.",
                "Es wird daher empfohlen keine personenbezogenen Daten mit Google zu teilen.",
                "Solltest Du ausversehen personenbezogene Daten mit Google zur Übersetzung teilen wollen, warnt und zensiert EKLARA vorher zur Sicherheit erkannte Namen, Mailadressen, Telefonnummern oder Adressen.",
            ],

            left,
            right,
            logoutIcon,
            menuIcon,
            abbrechenIcon,
            aktualisierenIcon,
            kalenderIcon,
            keyboardIcon,
            tastaturTippenMetacom,
            lupenIcon,
            lupeMetacom,

            deFlagIcon,
            frFlagIcon,
            trFlagIcon,
            arFlagIcon,
            enFlagIcon,
            ruFlagIcon,

            btmNavLangItems: [
                /*1st lang row*/
                { countryCode: 'de', icon: deFlagIcon, alt: 'Deutsche Flagge' },
                { countryCode: 'en', icon: enFlagIcon, alt: 'Englische Flagge' },
                { countryCode: 'fr', icon: frFlagIcon, alt: 'Französische Flagge' },
                /*2nd lang row*/
                { countryCode: 'it', icon: itFlagIcon, alt: 'Italienische Flagge' },
                { countryCode: 'ru', icon: ruFlagIcon, alt: 'Russische Flagge' },
                { countryCode: 'ro', icon: roFlagIcon, alt: 'Rumänische Flagge' },
                /*3rd lang row*/
                { countryCode: 'tr', icon: trFlagIcon, alt: 'Türkische Flagge' },
                { countryCode: 'uk', icon: ukFlagIcon, alt: 'Ukrainische Flagge' },
                { countryCode: 'ar', icon: arFlagIcon, alt: 'Arabische Flagge' },
                // { countryCode: 'ku', icon: kuFlagIcon, alt: 'Kurdische Flagge' },
            ],

            showCalendarWidget: false,
            showCalendarBadge: false,
            eventsNotSeen: '',

            appwidgetMounted: true,
            brettMounted: true,
            chatMounted: false,
            planMounted: true,
            messagesMounted: true,
            otherConnectedDevices: null,
            showForceLogoutDialog: false,
            reloadChat: false,
            reloadBrett: false,
            reloadPlan: false,
            reloadMessages: false,
            reloadAppwidget: false,
            buttonStyle: {
                position: 'absolute',
                top: '0px',
                left: '0px',
                display: 'none'
            }
        };
    },
    computed: {
        ...mapState('weeklyView', ["weeklyView"]),
        ...mapState('profileManagement', ['profileManagement']),
        ...mapState('auth', ['stayLoggedIn', 'deviceToken', 'preventLogout']),
        ...mapState('util', ['browserSupported', 'windowWidth', 'keyboard']),
        ...mapState('blackboard', ['blackboard']),
        ...mapState('translation', [ 'targetLang' ]),
        ...mapState('badges', ['parentalLetterBadge', 'blackboardBadge', 'chatBadge', 'appBadge']),
        ...mapState('magnifier', ['magnifier']),
        ...mapState('tooltips', ['showTooltips']),
        ...mapState('utilUnpersistent', ['blockMobileWidgetChangeByDrag','hideNavbar']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename', 'getPrivacyPolicy', 'getForcePrivacyPolicy', 'getPrivacyPolicyTutorialInfo' ]),
        ...mapGetters('matrix', ['matrixBadge']),
        ...mapGetters('support',['supportPageOpen']),
        ...mapGetters('auth', ['accountId']),
         ...mapGetters('chat',['chatBadgeEventList']),
         ...mapGetters('blackboard',['blackboardBadgeEventList']),
        ...mapGetters('parents',{ 'vuexCurrentChild': 'currentChild' }),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),
        allWidgetsMounted(){
            return this.chatMounted && this.brettMounted && this.planMounted && this.appwidgetMounted  && this.messagesMounted;
        },
        page0WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 0 ){
                return   this.chatMounted && this.brettMounted && this.planMounted;
            }
            return true;
        },
        page1WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 1 ){
                return  this.appwidgetMounted && this.messagesMounted;
            }
            return true;
        },
        isUpdatesOnOtherPage() {
            if (this.isSecondPageUpdated.length && this.page === 0) {
                return true;
            }
            if (this.isFirstPageUpdated.length && this.page === 1) {
                return true;
            }

            return false;
        },
          showNextSlide(){
            return !(this.messagesDeactivated && this.appsDeactivated)
        },
         btmNavItems(){

            const items = [];

            if(!this.planDeactivated){
                items.push(
                { name: 'Stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: items.length, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                )
           }
            if(!this.brettDeactivated){
                items.push(
                { name: 'Schwarzes Brett', icon: brettIcon, goToIndex: items.length, bgColor: 'var(--v-dunkelgrau-base)', color: 'filter: brightness(0) saturate(100%) invert(40%) sepia(22%) saturate(0%) hue-rotate(171deg) brightness(102%) contrast(80%);' },

                )
            }
            if(!this.chatDeactivated){
                items.push(
                    { name: 'Chat', alt: 'Chat', icon: chatIcon, goToIndex: items.length, bgColor: 'var(--v-chatColor-base)', color: 'filter: brightness(0) saturate(100%) invert(55%) sepia(8%) saturate(4130%) hue-rotate(70deg) brightness(97%) contrast(81%);' },

                )
            }
            if(!this.messagesDeactivated){
                items.push(
                { name: 'Elternbriefe', alt: 'Elternbrief', icon: briefIcon, goToIndex: items.length, bgColor: '#94519B', color: 'filter: brightness(0) saturate(100%) invert(34%) sepia(43%) saturate(774%) hue-rotate(247deg) brightness(98%) contrast(81%)' },
                )
            }
            if(!this.appsDeactivated){
                items.push(
                { name: 'Apps', alt: 'Apps', mdi: 'mdi-apps', goToIndex: items.length, bgColor: 'var(--v-headerblue-base)',}

                )
            }
            return items;
        },
        chatWidgetSlide(){
            return this.btmNavItems.findIndex((item)=> item.name === 'Chat');
        },
    },
    watch: {
        // selectedText() {
        //     if (this.selectedText.length) {
        //         if (!this.textSelected) {
        //             this.textSelected = true;
        //         }
        //     } else {
        //         if (this.textSelected) {
        //             this.textSelected = false;
        //         }
        //     }
        // },
        allWidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page0WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page1WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    async created(){
        this.checkQueryForPage();
        // Check query param for page
        const page = Number.parseInt(this.$route.query.page);
        if (page && Number.isSafeInteger(page)) {
            if (page && Number.isSafeInteger(page)) {
                if (this.windowWidth > 900) {
                    this.page = page;
                    if (page > 1) {
                        this.page = Math.floor(page / 3);
                        await this.$router
                            .replace({ query: { ...this.$route.query, page: this.page }});
                    }
                } else {
                    this.currSlide = page;
                }
            }
        }
    },
    async mounted() {
        setTimeout(() => {
            this.hidePreloader = true;
        }, 5000);
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.onOrientationChange);

        this.crtAccount = await this.getCurrentAccount(true);
        this.usageAgreementNotSigned = this.crtAccount.signedUsageAgreement.isSigned ? false : true;
        this.checkForCalendarUpdates();
        // await this.checkForPinboardUpdates();

        // setInterval(() => {
        //     let selection = JSON.parse(JSON.stringify(this.selectedText));
        //     if (window.getSelection) {
        //         selection = window.getSelection();
        //     }
        //     // document.getSelection
        //     else if (document.getSelection) {
        //         selection = document.getSelection();
        //     }
        //     // document.selection
        //     else if (document.selection) {
        //         selection =
        //             document.selection.createRange().text;
        //     }
        //     if (selection.toString() !== this.selectedText) {
        //         this.update = !this.update;
        //     }
        //     this.selectedText = selection.toString();

        // }, 100);
        document.addEventListener('selectionchange', this.handleSelectionChange);

        await this.hasUserNewEvents();

        await this.requestPerson();
        this.date = new Date(await this.loadServerTime());
        // this.requestAppointments();
        this.videoTutorial = (this.$route.query.page == 1)
            ? this.videoTutorialsByFilename['Tutorial_Eltern2.mp4']
            : this.videoTutorialsByFilename['Tutorial_Eltern.mp4'];

        if (this.targetLang !== 'de') {
            this.currentTTSLangImg = this[`${this.targetLang}FlagIcon`];
        }
        EventBus.$on('blackboard-badge', () => {
            this.isFirstPageUpdated.push('brett');
        });
        EventBus.$on('parental-badge', () => {
            this.isSecondPageUpdated.push('parental');
        });
        EventBus.$off('account-forcelogout');
        EventBus.$on('account-forcelogout', () => {
            if (!this.preventLogout) {
                this.logoutUser();
                this.$router.push({ name: 'login'});
            } else {
                this.setPreventLogout(false);
            }
        });
            //this is needed because if feature is disabled it wont mount so we would get stuck in infinite loading screen
        if(this.deactivatedFeatures.apps){
            this.appwidgetMounted = true;
        }
        if(this.deactivatedFeatures.plan){
            this.planMounted = true;
        }
        if(this.deactivatedFeatures.chat){
            this.chatMounted = true;
        }
        if(this.deactivatedFeatures.messages){
            this.messagesMounted = true;
        }if(this.deactivatedFeatures.blackboard){
            this.brettMounted = true;
        }
        const connectionsRes =  await backend.checkWebsocketConnections(this.crtAccount._id);
        const clientsObject = await connectionsRes.json();
        this.otherConnectedDevices = clientsObject.connected - 1;
        if(this.otherConnectedDevices > 0){
        this.showForceLogoutDialog = true;
        }
        
    },
    methods: {
        ...mapActions("appointments", ["getAppointmentsParent"]),
        ...mapActions("parents", ["getMeParent"]),
        ...mapMutations("parents", ["setCurrentChild"]),
        ...mapActions("pupils", ["getPupil"]),
        ...mapActions("translation", ["setTargetLang"]),
        ...mapActions("auth", ["logoutUser", "getCurrentAccount"]),
        ...mapActions("util", ["loadServerTime"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),
        ...mapActions('parentalLetter', ['getAllParentalLetters']),
        ...mapActions("videoTutorial", [ "getTutorialProperties", "updateTutorialProperties", "setPrivacyPolicyState" ]),
        ...mapActions("magnifier", ["turnOffMagnifier"]),
        ...mapActions("util", ["toggleKeyboard"]),
        ...mapActions("calendar", ['getUnseenEventsForUser']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions('weeklyView', ['toggleWeeklyView', 'closeWeeklyView']),
        ...mapActions('pinboard', ['getPinboards', 'hasUserSeenPinboard', 'toggleOpenPinboardLibrary']),
        ...mapMutations('appointments', ['clearAppointments']),
        ...mapMutations('support', ['setSupportPageOpen']),
        ...mapMutations('auth', ['setPreventLogout']),

        
        handleSelectionChange() {
            let selection = '';
            let rect = null;

            if (window.getSelection) {
                const sel = window.getSelection();
                selection = sel.toString();
                if (sel.rangeCount > 0) {
                rect = sel.getRangeAt(0).getBoundingClientRect();
                }
            } else if (document.getSelection) {
                const sel = document.getSelection();
                selection = sel.toString();
                if (sel.rangeCount > 0) {
                rect = sel.getRangeAt(0).getBoundingClientRect();
                }
            } else if (document.selection) {
                const sel = document.selection.createRange();
                selection = sel.text;
                rect = sel.getBoundingClientRect();
            }
            if (selection !== this.selectedText && selection !== '') {
                this.selectedText = selection;
                this.updateButtonPosition(rect);
                this.textSelected = true;
            } else {
                this.textSelected = false; // Hide button if no selection
            }
        },
        updateButtonPosition(rect) {
            if (rect) {
                this.buttonStyle = {
                position: 'absolute',
                top: `${rect.bottom + window.scrollY}px`, // Adjust the button below the selection
                left: `${rect.right + window.scrollX}px`, // Align the button to the left of the selection
                display: 'block',
                };
            }
        },
        isMounted(item){
            switch(item.name){
                case 'Stundenplan':
                    return this.planMounted;
                case 'Schwarzes Brett':
                    return this.brettMounted;
                case 'Chat':
                    return this.chatMounted;
                case 'Apps':
                    return this.appwidgetMounted;
                case 'Elternbriefe':
                    return this.messagesMounted;
                default: 
                return true;
            }
        },
        confirmForceLogout(){
            this.showForceLogoutDialog = false;
            this.showSnackbar({message:`Andere Geräte erfolgreich ausgeloggt.`, color:'success'})
        },
        revokeUsageAgreement(){
            this.usageAgreementNotSigned = true;
            this.revokeAgreement = true;
        },
        async checkForCalendarUpdates() {
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            this.showCalendarBadge = this.eventsNotSeen > 0;
            if (this.eventsNotSeen) {
                this.isSecondPageUpdated.push('calendar');
            }
        },
        async checkForPinboardUpdates() {
            const pinboardsJson = await this.getPinboards();
            this.allPinboards = pinboardsJson;
            for(var i = 0; i < pinboardsJson.length; i++) {
                const crtPinboard = pinboardsJson[i];
                const resJson = await this.hasUserSeenPinboard( { pinboardId: crtPinboard._id, accountId: this.crtAccount._id } );
                if(!resJson.seen) {
                    this.isSecondPageUpdated.push('pinboard');
                    return;
                }
            }
        },
        async changePage(direction) {
            this.page = Math.abs((this.page + direction) %2);
            if (this.page === 0) {
                this.isFirstPageUpdated = [];
            }
            // await this.setFocusOnNextPage()

            // update query params
            await this.$router.replace({ query: { ...this.$route.query, page: this.page } });

            //reset video tutorial
            this.videoTutorial = (this.$route.query.page == 1)
                ? this.videoTutorialsByFilename['Tutorial_Eltern2.mp4']
                : this.videoTutorialsByFilename['Tutorial_Eltern.mp4'];
            this.videoTutorialClosed = false;
        },

        async overrideCurrentChild(child) {
            // Clear store appointments and fetch them for new selected child
            this.clearAppointments();
            // await this.getAppointmentsParent(child._id);
            this.currentChild = child;
            // also inform store parents module about the selected child
            this.setCurrentChild(child);
        },

        async requestPrivacyPolicyTutorialInfo() {
            const today = new Date();
            this.privacyPolicyTutorialInfo = await this.getTutorialProperties({
                videoTutorialFilename: 'privacyPolicyTutorial',
                privacyPolicy: true,
                nextShowDate: new Date(today.getFullYear(), today.getMonth() + 6, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds()),
            })
            if(this.privacyPolicyTutorialInfo.timesShownRemaining < 3) {
                this.forcePrivacyPolicyTutorial = false;
            }
            return (this.privacyPolicyTutorialInfo.timesShownRemaining > 0 && this.privacyPolicyTutorialInfo.seeAgain)
                || Date.parse(this.privacyPolicyTutorialInfo.nextShowDate) < Date.now();
        },

        async closePrivacyPolicyTutorial() {
            let data;
            let tutInfo = this.getPrivacyPolicyTutorialInfo;
            if (Date.parse(tutInfo.nextShowDate) < Date.now()) {
                const today = new Date();
                data = {
                    videoTutorialFilename: 'privacyPolicyTutorial',
                    timesShownRemaining: 3,
                    nextShowDate: new Date(today.getFullYear(), today.getMonth() + 6, today.getDate(), today.getHours(), today.getMinutes()),
                }
            } else {
                data = {
                    videoTutorialFilename: 'privacyPolicyTutorial',
                    timesShownRemaining: tutInfo.timesShownRemaining - 1,
                }
            }
            await this.updateTutorialProperties(data);
            this.setPrivacyPolicyState(false);
        },

        vueSlickCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
        },

        async requestAppointments() {
            this.appointments = await this.getAppointmentsParent(this.currentChild._id);
        },

        async requestPerson() {
            this.person = await this.getMeParent();
            this.showEmailBadge = this.person.hideEmailBadge ? !this.person.hideEmailBadge : true;
            let pupils = this.person.pupils;
            for (let i = 0; i < pupils.length; i++) {
                let item = await this.getPupil(pupils[i]);
                this.children.push(item);
            }
            const savedData = JSON.parse(localStorage.getItem('currentChild'));
            if (savedData){
                //this needs to be done to prevent getting wrong children when other parent logs in and accesses same local storage
                const filtered = this.children.filter(child => child._id === savedData._id);
                this.currentChild = filtered.length ? filtered[0] : this.children[0];
            }
            else{
                this.currentChild = this.vuexCurrentChild ? this.vuexCurrentChild : this.children[0];
            }
            this.setCurrentChild(this.currentChild);

            return this.person;
        },

        reloadPage() {
            this.$router.go();
        },

        //#region btmNav methods
        switchSlide(index) {
            if (this.slideChangeAllowed && index !== this.currSlide) {
                this.$refs.carousel.goTo(index);

                this.currSlide = index;
                //this.slideChangeAllowed = false;
                // set weeklyView to closed on change
                 this.sidebar = false;
                 this.sidebarMobile = false;
                this.closeWeeklyView();

                // add page to query
                this.$router.replace({ query: { ...this.$route.query, page: this.currSlide } });
             }else if(index === this.currSlide){
                this.reloadWidget(index)
            }
        },
        reloadWidget(index){
            switch(index){
                  case 0:
                    this.reloadPlan =true;
                    break;
                case 1:
                     this.reloadBrett = true;
                     break;
                case 2:
                    this.reloadChat = true;
                    break;
                case 3:
                   this.reloadMessages = true;
                    break;
                case 4:
                    this.reloadAppwidget = true;
                    break;
            }
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },
        clickTTS() {
            this.$refs.tts.$el.click();
        },
        //#endregion

        /**
         * Returns the value/text to display in the badge for given widget index
         * 0 = timetable, 1 = blackboard, 2 = chat, 3 = parental letter, 4 = appBadge
         * @param index of the widget you want the badge for
         */
        getBadge(index) {
            switch (index) {
                case 1:
                    return this.blackboardBadgeEventList.length;
                 case 2:
                    return this.chatBadgeEventList.length;
                case 3:
                    return this.parentalLetterBadge;
                case 4:
                    return this.appBadge;
                default:
                    return false;
            }
        },

        openCalendar() {
            this.showCalendarWidget = true;
        },

        async hasUserNewEvents() {
            this.showCalendarBadge = false;
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            if (this.eventsNotSeen > 0) {
                this.showCalendarBadge = true;
            }
        },

        checkQueryForPage() {
            if (this.$route.query.page) {
                this.initialSlide = parseInt(this.$route.query.page);
            }
        },

        /*async subscribe() {
            navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                // Register Push
                const subscription = await registrations[0].pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
                });
                await backend.subscribe({
                    subscription,
                    deviceToken: this.deviceToken,
                });
            });
            this.showSnackbar({
                message: 'Benachrichtigungen aktiv für das Schwarze Brett',
                color: 'info'
            });
        },*/

        /*urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                // eslint-disable-next-line no-useless-escape
                .replace(/\-/g, "+")
                .replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },*/
    },
};
</script>

<style lang="scss" scoped>
.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table,
tr,
td {
    border: none;
}

.small {
    display: none;
}

.big {
    display: flex;
}

.slick-slider {
    user-select: auto !important;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg)
        brightness(98%) contrast(91%) !important;
}

.btmNav {
    display: none;
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 70px !important;
    background-color: var(--v-headerblue-base);
}

.btmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
    border-radius: 8px !important;
}

.hiddenBtmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: transparent !important;
    border-radius: 8px !important;
}

/*removes darker background after btn is clicked
    also works without the specific class in front*/
.btmNavBtn.v-btn:before {
    opacity: 0 !important;
}
.arrowBtn:before {
    opacity: 0 !important;
}

.btmNavIcon {
    height: 35px;
}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .small {
        display: block !important;
    }

    .big {
        display: none !important;
    }

    .topPadding {
        padding-top: 12px;
    }

    .displayHide {
        display: none;
    }

    .btmNav {
        display: block;
    }

    .reload {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        margin-bottom: 78px !important;
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

.iconToHeaderblue {
    filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
