<template>
    <div>
        <div>Git hash: {{ gitHash.substring(0, gitHash.length - 1) }}</div>
        <div>Git branch: {{ gitBranch }} </div>
        <div>Build time: {{ new Date(buildTime).toLocaleString() }}</div>
    </div>
</template>

<script>
export default {
  name: "Version",
  components: {},
  props: {},
  data: () => ({
    gitHash: process.env.VUE_APP_GIT_HASH,
    buildTime: process.env.VUE_APP_BUILD_TIME,
    gitBranch: process.env.VUE_APP_GIT_BRANCH,
  }),
  computed: {},
  async mounted() {
  },
  methods: {},
};
</script>