<template>
    <div style="height: auto">
        <v-btn
            v-if="big"
            class="pa-0"
            style="text-transform: inherit; height: 40px"
            block
            elevation="0"
            @click="showChatRoomSettingsDialog = true"
        >
            <div
                class="d-flex align-center"
                style="width: 100%"
            >
                <img
                    :src="penIcon"
                    class="icon20 ml-4 mr-2"
                    alt="Einstellungen"
                >
                <p class="mr-auto">
                    Einstellungen
                </p>
            </div>
        </v-btn>
        <v-btn
            v-else
            id="muelleimerBtn"
            class="mr-1"
            color="weiss"
            icon
            :small="windowWidth <= 900 && windowWidth > 770"
            :x-small="windowWidth <= 770"
            @click="showChatRoomSettingsDialog = true"
        >
            <v-icon>mdi-cog</v-icon>
        </v-btn>
        <!-- Are you sure dialog for delete chat -->
        <ChatRoomSettingsDialog
            v-if="showChatRoomSettingsDialog"
            :chat-room="chatRoom"
            @deleteChatRoom="deleteChatRoom"
            @close="showChatRoomSettingsDialog = false"
        />
    </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import ChatRoomSettingsDialog from "./ChatRoomSettingsDialog";
import penIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";

export default {
    name: "OpenChatRoomSettingsButton",
    components: { ChatRoomSettingsDialog },
    props: {
        chatRoom: { required: true, type: Object },
        big: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        showChatRoomSettingsDialog: false,
        penIcon,
    }),
    computed: {
        windowWidth() {
            return window.innerWidth;
        },
    },
    methods: {
        ...mapActions("matrix", ["deleteMatrixRoom"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        deleteChatRoom() {
            this.showChatRoomSettingsDialog = false;
            this.$emit("deleteChatRoom");
        },
    },
};
</script>

<style scoped lang="scss">
#muelleimerBtn {
    height: 45px;
    min-width: 45px;
    width: 45px;
}

@media only screen and (max-width: 900px) {
    #muelleimerBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;
    }
}
</style>
