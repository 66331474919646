import * as backend from '../../api/backend'
import {checkResponseStatus} from "@/util/check";
import Vue from "vue";

const getToDoListWithTasks = async (toDoList) => {
    try {
        if (!toDoList.timestamp && !toDoList.message) {
            const toDoTasks = await Promise.all(toDoList.tasks.map(async (taskId) => {
                let taskResponse = await backend.getToDoTask(taskId);
                return taskResponse.json();
            }));
            const returnObject = JSON.parse(JSON.stringify(toDoList));
            returnObject.tasks = toDoTasks;
            return returnObject;
        }
    } catch (e) {
        console.error(e);
        return e.response.status;
    }
};
const state = {
    toDoLists: [],
};
const getters = {
    toDoLists: state => state.toDoLists,
    toDoListsById: state => state.toDoLists.reduce((obj, toDoList) => {
        obj[toDoList._id] = toDoList;
        return obj;
    }, {}),
};
const mutations = {
    setToDoLists: (state, lists) => {
        state.toDoLists = lists;
    },
    updateToDoList: (state, newEntry) => {

        state.toDoLists.push(newEntry);
    }
};
const actions = {
    // populates all toDoLists with tasks and then sets the state => this needs to be called on mounted
    async getAllToDoListsWithTasks({ commit, dispatch, rootGetters }) {
        try {
            const response = await backend.getToDoLists();
            const toDoListsJson = await response.json();
        
            const toDoLists = await Promise.all(
              toDoListsJson.map(async (toDoList) => {
                if (!toDoList.timestamp && !toDoList.message) {
                  const toDoTasks = await Promise.all(
                    toDoList.tasks.map(async (taskId) => {
                      const taskResponse = await backend.getToDoTask(taskId);
                      return taskResponse.json();
                    })
                  );
        
                  toDoList.tasks = toDoTasks;
                  return toDoList;
                }
              })
            );
        
            commit('setToDoLists', toDoLists);
            return toDoLists;
        } catch (e) {
            console.error(e);
            return e.response.status;
        }
    },

    async postToDoList ({ commit, dispatch }, params) {
        try {
            const response = await backend.postToDoList(params);
            await checkResponseStatus(201, response);
            const newBlackboardEntry = await response.json();
            //update tasks - Id's of todolist with actual tasks objects 
            const populatedEntry = await getToDoListWithTasks(newBlackboardEntry);
            commit('updateToDoList', populatedEntry);
            return newBlackboardEntry;
        } catch (e) {
            return e.response.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}