<template>
    <div :class="windowWidth > 900 ? 'd-flex flex-column' : ''">
        <v-overlay
            v-if="supportPageOpen"
            absolute
            style="z-index: 99"
            overlay-opacity="0.8"
            @click.native="setSupportPageOpen(false)"
        />
        <Header
            v-if="windowWidth > 900"
            :show-email-badge="showEmailBadge"
            class="mb-3"
            style="width: 100%; z-index: 5"
            @openSidebar="sidebar = true"
        />
        <v-overlay
            v-if="menuOpen"
            style="z-index:40"
        />
        <v-overlay
            v-if="sidebar"
            style="z-index: 10"
        />
        <keep-alive>
            <router-view
                class="d-flex"
                style="flex: auto;"
            />
        </keep-alive>
        <ContractVerwaltungV2 v-if="showDialogGDPR" />
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="goToTutorial()"
        />
        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />

        <Sidebar
            v-if="sidebar"
            v-model="sidebar"
            :sidebar-open="sidebar"
            @closeSidebar="sidebar = false;"
            @openUsageAgreement="revokeUsageAgreement"
            @hideBadge="showEmailBadge = false"
        />
        <Nutzungsbedingungen
            :usage-agreement-not-signed="usageAgreementNotSigned"
            :current-account="crtAccount"
            :revoke="revokeAgreement"
            @closeDialog="()=>{usageAgreementNotSigned = false}"
        />
    </div>
</template>

<script>
import Header from "../components/Verwaltung/Header";
import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import ContractVerwaltungV2 from '../components/Verwaltung/ContractVerwaltungV2.vue';
import SupportChat from "@/components/Support/SupportChat";
import Sidebar from "@/components/Sidebar";
import Nutzungsbedingungen from '@/components/Policys/Nutzungsbedingungen.vue';

export default {
    name: 'Main',
    components: {
        Header,
        VideoTutorial,
        ContractVerwaltungV2,
        SupportChat,
        Sidebar,
        Nutzungsbedingungen,
    },
    data: () => ({
        videoTutorial: false,
        videoTutorialClosed: false,
        menuOpen: false,
        sidebar: false,
        showEmailBadge: false,
        userrole: null,
        usageAgreementNotSigned: false,
        revokeAgreement: false,
        crtAccount: null,
    }),
    computed: {
        ...mapGetters('videoTutorial', ['videoTutorialsByFilename']),
        ...mapGetters("maintainer", ["showDialogGDPR"]),
        ...mapGetters("support",["supportPageOpen"]),
        ...mapState('faq', ['faq']),
        ...mapState('util', ['windowWidth']),
    },
    watch: {
        faq() {
            this.goToFaq();
        }
    },
    async mounted() {
        this.videoTutorial = (this.$route.query.page == 1)
        ? this.videoTutorialsByFilename['Tutorial_Verwaltung2.mp4']
        : this.videoTutorialsByFilename['Tutorial_Verwaltung.mp4'];
        this.userrole = await this.getMeMaintainer();
        this.showEmailBadge = this.userrole.hideEmailBadge ? !this.userrole.hideEmailBadge : true;
    },
    methods: {
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),
        ...mapMutations("support", ["setSupportPageOpen"]),
        ...mapActions("maintainers", ["getMeMaintainer"]),

        goToTutorial() {
            this.$router.push('/verwaltung/tutorial');
        },

        goToFaq() {
            this.$router.push('/verwaltung/faq');
        },
        revokeUsageAgreement(data){
            if(data){
                this.crtAccount = data.crtAccount;
            }
            this.usageAgreementNotSigned = true;
            this.revokeAgreement = true;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
