<template>
    <!-- teacher -->
    <div style="border-radius: 0px;">
        <v-btn
            id="schlossBtn"
            class="pa-0"
            style="text-transform: inherit; height: 40px;"
            block
            elevation="0"
            @click.stop.prevent="toggleChatRoomOpen"
        >
            <div
                class="d-flex align-center"
                style="width: 100%"
            >
                <img
                    v-if="isOpen"
                    :src="schlossZuIcon"
                    class="iconToRed mx-2"
                    alt="Chat öffnen"
                    style="width: 20px; height: 20px; opacity: 0.4"
                >
                <img
                    v-else
                    :src="schlossOffenIcon"
                    class="iconToGreen mx-2"
                    alt="Chat sperren"
                    style="width: 20px; height: 20px; opacity: 0.4"
                >
               
                <p> {{ isOpen ? 'Chat sperren' : 'Chat öffnen' }}</p>
            </div>
        </v-btn>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import * as backend from "../../api/backend.js";

import schlossOffenIcon from "../../assets/Icons/FaceliftIcons/schloss_offen.svg";
import schlossZuIcon from "../../assets/Icons/FaceliftIcons/schloss_zu.svg";

export default {
    name: "ToggleChatRoomOpenButton",
    props: {
        chatRoom: { required: true, type: Object },
    },
    data: () => ({
        schlossOffenIcon,
        schlossZuIcon,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("translation", ["targetLang"]),
        ...mapState("util", ["windowWidth"]),
        isOpen() {
            return this.chatRoom.isOpen;
        },
    },

    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("chat", ["patchChatRoom"]),
        async toggleChatRoomOpen() {
            const res = await this.patchChatRoom({ chatRoomId: this.chatRoom._id, body: {
                    isOpen: !this.chatRoom.isOpen,
                }});
            if (res.status === 200 && !this.chatRoom.isOpen) {
                this.showSnackbar({
                    message: "Der Chat ist wieder geöffnet.",
                });
            } else if (res.status === 200 && this.chatRoom.isOpen) {
                this.showSnackbar({
                    message:
                        "Der Chat ist ist jetzt geschlossen. Es können keine weiteren Nachrichten gesendet werden.",
                });
            } else {
                this.showSnackbar({
                    message: "Unerwarteter Fehler beim Schließen/Öffnen des Chats. Bitte wenden Sie sich an den Support.",
                    color: "error",
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}



#schlossBtn {
    img {
        width: 24px;
        height: 24px;
    }
}

</style>
