import pdfjs from "@/util/pdfjs";

const generateThumbnailData = async (originFile) => {
    // get arraybuffer of file and load that into pdf-lib
    const pdfDocumentLoadingTask = pdfjs.getDocument({ data: await originFile.arrayBuffer() });
    const pdfDocument = await pdfDocumentLoadingTask.promise;

    const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
        .map((element) => element + 1) // Add 1 to each element to get all page numbers
        .map((page) => pdfDocument.getPage(page));
    const allPages = await Promise.all(pagePromises);
    // get first page
    const firstPage = allPages[0];
    const viewport = firstPage.getViewport({scale: 1.5});

    let createdCanvas = document.createElement('canvas');
    createdCanvas.width = viewport.width;
    createdCanvas.height = viewport.height;

    const canvasContext = createdCanvas.getContext('2d');
    const renderContext = {canvasContext, viewport, renderInteractiveForms: true};
    const renderTask = firstPage.render(renderContext);
    await renderTask.promise;

    const zwischenUrl = createdCanvas.toDataURL('image/png');
    const thumbnailBlob = await fetch(zwischenUrl).then(r => r.blob());
    return thumbnailBlob;
};

const generateThumbnailForPdf = async ( originFileId, originFile ) => {
    const thumbnailBlob = await generateThumbnailData(originFile);
    // save as png
    const thumbnailFile = new File([thumbnailBlob], 'thumbnail.png', {type: 'image/png'});

    // return in json with fileId
    return { originFileId, thumbnailFile };
};

/**
 * This method exists for use cases where you only need the thumbnail without the file creation, for instance in
 * PinboardCardElement.vue where it only creates the file when saving a pinboard card afterwards.
 *
 * @param originFile the file you want to create a thumbnail for
 * @returns {Promise<Blob>} returns the thumbnail as blob
 */
const generateThumbnailWithoutFile = async (originFile) => {
    return await generateThumbnailData(originFile);
};

export { generateThumbnailData, generateThumbnailForPdf, generateThumbnailWithoutFile };
