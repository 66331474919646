import * as backend from "../../api/backend";
import {checkResponseStatus} from "@/util/check";

const defaultState = {
    thumbnails: [],
    converterThumbnails: [],
    openPinboard: null,
    openPinboardLibrary: false,
    dragMode: false,
    dragColumnMode: false,
    selected: null,
    selectedTitleCard:{},
};

const mutations = {
    pushThumbnailToState: (state, { fileId, thumbnailObject } ) => {
        state.thumbnails.push({ _id: fileId, thumbnailObject });
    },
    pushConverterThumbnail: (state, { fileId, thumbnailObject } ) => {
        state.converterThumbnails.push({ _id: fileId, thumbnailObject });
    },
    removeConverterThumbnail: (state, fileId) => {
        state.converterThumbnails = state.converterThumbnails.filter((thumbnail) => thumbnail._id !== fileId);
    },
    clearStateThumbnails(state) {
        state.thumbnails.forEach((thumbnail) => {
            window.URL.revokeObjectURL(thumbnail.thumbnailObject.url);
        })
        state.thumbnails = [];
        state.converterThumbnails.forEach((thumbnail) => {
            window.URL.revokeObjectURL(thumbnail.thumbnailObject.url);
        })
        state.converterThumbnails = [];
    },
    setOpenPinboard(state, id) {
        state.openPinboard = id;
    },
    setOpenPinboardLibrary(state, value) {
        state.openPinboardLibrary = value;
    },
    clearLibraryState(state) {
        state.openPinboardLibrary = false;
    },
    toggleDragModeState(state){
        state.dragMode = !state.dragMode;
    },
    setSelected(state, value){
        state.selected = value;
    },
    removeSelected(state){
        state.selected = null;
    },
    toggleDragColumnModeState(state){
        state.dragColumnMode = !state.dragColumnMode;
    },
    setSelectedTitleCard(state, value){
        state.selectedTitleCard = value; 
    }

};

const getters = {
    thumbnailsById: state => state.thumbnails.reduce((acc, thumbnail) => {
        acc[thumbnail._id] = thumbnail.thumbnailObject;
        return acc;
    }, {}),
    converterThumbnailsById: state => state.converterThumbnails.reduce((acc, thumbnail) => {
        acc[thumbnail._id] = thumbnail.thumbnailObject;
        return acc;
    }, {}),
    currentlyOpenPinboard: (state) => state.openPinboard,
    openPinboardLibrary: (state) => state.openPinboardLibrary,
    dragMode: (state) => state.dragMode,
    selected: (state) => state.selected,
    dragColumnMode:(state) => state.dragColumnMode,
    selectedTitleCard:(state) => state.selectedTitleCard,

};


const actions = {

    async getPinboards ({ commit, dispatch }, params) {
        try {
            const res = await backend.getPinboards();
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getPinboard ({ commit, dispatch }, pinboardId) {
        try {
            const res = await backend.getPinboard(pinboardId);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getInvitationPinboard ({ commit, dispatch }, invitationCode) {
        try {
            const res = await backend.getInvitationPinboard(invitationCode);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async postPinboard ({ commit, dispatch }, data) {
        try {
            const res = await backend.postPinboard(data);
            await checkResponseStatus(201, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async postCard ({ commit, dispatch }, data) {
        try {
            const res = await backend.postCard(data);
            await checkResponseStatus(201, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async patchPinboard ({ commit, dispatch }, { cardId, body }) {
        try {
            const res = await backend.patchPinboard(cardId, body);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async patchPinboardInfo ({ commit, dispatch }, { pinboardId, body }) {
        try {
            const res = await backend.patchPinboardInfo(pinboardId, body);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async patchCard ({ commit, dispatch }, { cardId, body }) {
        try {
            const res = await backend.patchCard(cardId, body);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async deletePinboard ({ commit, dispatch }, pinboardId) {
        try {
            const res = await backend.deletePinboard(pinboardId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async deleteCard ({ commit, dispatch }, cardId) {
        try {
            const res = await backend.deleteCard(cardId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async markPinboardAsSeen ({ commit, dispatch }, { pinboardId, accountId }) {
        try {
            const res = await backend.markPinboardAsSeen(pinboardId, accountId);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async hasUserSeenPinboard ({ commit, dispatch }, { pinboardId, accountId }) {
        try {
            const res = await backend.hasUserSeenPinboard(pinboardId, accountId);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async postPinboardCardUpload ({ commit, dispatch }, { cardId, file }) {
        try {
            const res = await backend.postPinboardCardUpload(cardId, file);
            await checkResponseStatus(201, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getPinboardCardUpload ({ commit, dispatch, getters }, { cardId, fileId }) {
        try {
            if (getters.thumbnailsById[fileId]) {
                return getters.thumbnailsById[fileId];
            }
            const res = await backend.getPinboardCardUpload(cardId, fileId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                const hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                const thumbnailObject = {
                    url, hasThumbnail
                }
                commit('pushThumbnailToState', { fileId, thumbnailObject});
                return url;
            }
            return null;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async patchPinboardCardUpload ({ commit, dispatch }, { cardId, fileId }) {
        try {
            const res = await backend.patchPinboardCardUpload(cardId, fileId);
            await checkResponseStatus(0, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async deletePinboardCardUpload ({ commit, dispatch }, { cardId, fileId } ) {
        try {
            const res = await backend.deletePinboardCardUpload(cardId, fileId);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getPinboardUploadThumbnail ({ commit, dispatch, getters }, { fileId, pinboardCardId, pinboardId }) {
        try {
            if (getters.converterThumbnailsById[fileId]) {
                return getters.converterThumbnailsById[fileId];
            }
            const res = await backend.getPinboardUploadThumbnail(fileId, pinboardCardId, pinboardId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                const hasThumbnail = blob.type !== 'text/plain; charset=utf-8';
                const thumbnailObject = {
                    url, hasThumbnail
                }
                if (hasThumbnail) {
                    commit('pushConverterThumbnail', {fileId, thumbnailObject});
                }
                return { url, hasThumbnail: blob.type !== 'text/plain; charset=utf-8', };
            }
            return null;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async postInvitation ({ commit, dispatch }, {pinboardId, invitation}) {
        try {
            const res = await backend.postInvitation(pinboardId, invitation);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async deleteInvitation ({ commit, dispatch }, invitationCode) {
        try {
            const res = await backend.deleteInvitation(invitationCode);
            await checkResponseStatus(204, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async sendPinboardNotification({ commit, dispatch }, pinboardId){
        try {
            const res = await backend.postPinboardNotification(pinboardId);
            await checkResponseStatus(200, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    clearThumbnails({ commit }) {
        commit("clearStateThumbnails");
    },
    toggleOpenPinboard({ commit }, id) {
        commit("setOpenPinboard", id);
    },
    toggleOpenPinboardLibrary({ commit }, value) {
        commit("setOpenPinboardLibrary", value);
    },
    selectCard({commit}, pinboardCardId){
        commit('setSelected',pinboardCardId);
    },
    removeSelectedCard({commit}){
        commit('removeSelected');
    },
    toggleDragMode({commit, getters}){
        if(getters.dragColumnMode === true){
            //reset drag column mode
            commit('toggleDragColumnModeState');
            commit('setSelectedTitleCard',{});
        }
        commit('toggleDragModeState');
        commit('removeSelected')
    },
    toggleDragColumnMode({commit, getters}){
        if(getters.dragMode === true){
            //reset drag Mode
            commit('toggleDragModeState');
            commit('removeSelected');
        }
        commit('toggleDragColumnModeState');
    },
    selectTitleCard({commit}, pinboardCard){
        commit('setSelectedTitleCard',pinboardCard);
    }
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions
};
