import * as backend from "../../api/backend";

const defaultState = {
    serverTime: null,
    // By how many milliseconds the server time deviates from local time
    serverTimeOffset: null,
    loading: false,
    versionNumber: null,
    openAppointment: null,
    openTeacherUploadId: null,
    currentGroup: "",
    currentFolder: null,
    keyboard: false,
    browserSupported: true,
    bonusFunctions: {},
    lastOpenedInEditor: null,
    showCookieLawWatcher: false,
    windowWidth: 0,
    windowHeight: 0,
    showDropdownBadge: true,
    fachInfoUploadCallback: null,
    nameFileExisting: false,
    // Boolean to help prevent opening a specific appointment in FachInfo after pupil closed editor in HausiWidget
    dontOpenAppointment: false,
};

let stopLoadingTimer = null;

const mutations = {
    setNameFileExisting(state, nameFileExisting) {
        state.nameFileExisting = nameFileExisting;
    },
    setServerTimeOffset(state, serverTimeOffset) {
        state.serverTimeOffset = serverTimeOffset;
    },
    setServerTime(state, serverTime) {
        state.serverTime = serverTime;
    },
    setLoadingShown(state, loading) {
        state.loading = loading;
    },
    versionNumber(state, version) {
        state.versionNumber = version;
    },
    setOpenAppointment(state, id) {
        state.openAppointment = id;
    },
    setCurrentUploadGroup(state, group) {
        state.currentGroup = group;
    },
    setCurrentUploadFolder(state, folder) {
        state.currentFolder = folder;
    },
    setOpenTeacherUploadId(state, id) {
        state.openTeacherUploadId = id;
    },
    setKeyboard(state, value) {
        state.keyboard = value;
    },
    setBrowserCompatibility(state, value) {
        state.browserSupported = value;
    },
    setBonusFunctions(state, value) {
        state.bonusFunctions = value;
    },
    setLastOpenedInEditor(state, value) {
        state.lastOpenedInEditor = value;
    },
    setShowCookieLawWatcher(state, value) {
        state.showCookieLawWatcher = value;
    },
    setWindowWidth(state, value) {
        state.windowWidth = value;
    },
    setWindowHeight(state, value) {
        state.windowHeight = value;
    },
    setShowDropdownBadge(state, value) {
        state.showDropdownBadge = value;
    },
    setFachInfoUploadCallback(state, value) {
        state.fachInfoUploadCallback = value;
    },
    setDontOpenAppointment(state, value) {
        state.dontOpenAppointment = value;
    },
};

const getters = {
    isSetNameExisting: (state) => state.nameFileExisting,
    isLoadingShown: (state) => state.loading,
    currentlyOpenAppointment: (state) => state.openAppointment,
    currentlyOpenTeacherUploadId: (state) => state.openTeacherUploadId,
    currentUploadGroup: (state) => state.currentGroup,
    currentUploadFolder: (state) => state.currentFolder,
    getServerTime: (state) => state.serverTime,
    getFachInfoUploadCallback: (state) => state.fachInfoUploadCallback
};

const actions = {
    async loadServerTime({ commit, dispatch, state }) {
        // Check if offset is already registered
        if (!state.serverTimeOffset) {
            const res = await backend.getServerTime();
            const serverTimeIsoString = await res.json();
            const serverTime = new Date(serverTimeIsoString).valueOf();
            const localTime = new Date().valueOf();

            commit("setServerTimeOffset", localTime - serverTime);
            commit("setServerTime", Date.now() + state.serverTimeOffset);
        }

    return new Date(Date.now() + state.serverTimeOffset);
  },
  // Method is used on reload
  // Normally time is persisted, but you need to handle timezone changes
  // So the time is reset in order to request the current time again
  resetServerTimeOffset({ commit, dispatch }) {
    commit("setServerTimeOffset", null);
  },
  toggleLoading({ commit }, loading) {
    commit("setLoadingShown", loading);
  },
  triggerLoading({ commit, state }) {
    // Remove old stop timeout
    if(stopLoadingTimer) {
      clearTimeout(stopLoadingTimer);
      stopLoadingTimer = undefined;
    }

    // Set timeout to deactivate after 500ms
    stopLoadingTimer = setTimeout(() => {
      commit("setLoadingShown", false);
      stopLoadingTimer = undefined;

    }, 500);

    if(!state.loading) {
      commit("setLoadingShown", true);
    }
  },
  async requestVersionNumber({ commit }) {
    try {
      const res = await backend.getVersionNumber();
      const version = await res.json();
      commit("versionNumber", version);
      return version;
    } catch (e) {
      return e.res.status;
    }
  },
  async requestBonusFunctions({ commit }) {
    try {
      const res = await backend.getBonusFunctions();
      const bonusFunctions = await res.json();
      commit("setBonusFunctions", bonusFunctions);
      return bonusFunctions;
    } catch (e) {
      return e.res.status;
    }
  },
  toggleOpenAppointment({ commit }, id) {
    commit("setOpenAppointment", id);
  },
    toggleFachInfoUploadCallback({ commit }, callbackFunction) {
        commit("setFachInfoUploadCallback", callbackFunction)
    },
  toggleOpenTeacherUploadId({ commit }, id) {
    commit('setOpenTeacherUploadId', id)
  },
  toggleCurrentUploadGroup({ commit }, group) {
    commit("setCurrentUploadGroup", group);
  },
  toggleCurrentUploadFolder({ commit}, folder) {
    commit("setCurrentUploadFolder", folder);
  },
  toggleKeyboard({ commit, state }) {
    commit("setKeyboard", !state.keyboard);
    // console to help debug issue #796, if issue resolved feel free to delete the line :)
      console.info('toggled keyboard with Alt + T');
  },
  setWindowWidth({ commit }, value) {
    commit('setWindowWidth', value);
  },
  setWindowHeight({ commit }, value) {
    commit('setWindowHeight', value);
  },
  checkBrowserCompatibilityForNotifications({ commit }) {
    if (navigator.vendor.includes('Apple') || navigator.platform.includes('Mac')) {
      commit('setBrowserCompatibility', false);
    } else if (!navigator.vendor.length) {
      // Extra for firefox, since browserSupported is false by default there for some reason
      commit('setBrowserCompatibility', true);
    }
  },
  insertLastOpenedInEditor({ commit, state }, id) {
    commit("setLastOpenedInEditor", id);
  },
  toggleShowCookieLawWatcher({ commit, state }) {
    commit("setShowCookieLawWatcher", !state.showCookieLawWatcher);
  },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
    actions,
};
