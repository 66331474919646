<template>
    <BaseMenu
        :items="neededItems"
        :show-list="showList"
        header-color="green"
        header-title="Speichern unter"
        @close="$emit('close')"
        @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <div
            v-if="mode === 'teacher'"
            class="px-2"
        >
            <v-checkbox
                v-model="showBadges"
                class="mt-0"
                :label="'Benachrichtigungen'"
                dense
                @change="toggleBadgeSending"
            />
        </div>
        <div
            v-if="mode === 'creator' && smartphoneUi"
        >
            <v-checkbox
                v-model="computedCheckExtendedFunctionsSmartphone"
                class="mt-0"
                :label="'Erweiterte Funktionen'"
                dense
                @change="toggleExtendedFunctionsOnSmartphone"
            />
        </div>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import hochladenIcon from "@/assets/Icons/hochladen-15.svg";
import exportIcon from '@/assets/Icons/drucken-filled.svg';
import privateIcon from "@/assets/Icons/FaceliftIcons/ordner_privat.svg";
import subjectIcon from "@/assets/Icons/FaceliftIcons/ordner_stundenplan.svg";
import groupIcon from "@/assets/Icons/FaceliftIcons/ordner_geteilt.svg";
import verlassenIcon from "@/assets/Icons/FaceliftIcons/login_eintreten_einloggen_ausloggen_verlassen.svg";
import infoIcon from "@/assets/Icons/info-2-121.svg";
import fertigIcon from "@/assets/Icons/fertig-weiss-74.svg";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: 'CreatorSaveMenu',
    components: {BaseMenu},
    props: {
        mode: {required: true, type: String},
        isNextPupilUploadAvailable: { required: false, type: Boolean, default: false },
        hasTargetFolder: { required: false, type: Boolean, default: false },
        limitSaveMenu: { required: false, type: Boolean, default: false },
        smartphoneUi: { required: false, type: Boolean, default: false },
        wantSmartphoneExtendedElementsOn: { required: false, type: Boolean, default: false },
    },
    data: ()  => ({
        showList: true,
        items: [
            // {icon: privateIcon, text: 'Privat speichern', val: 0},
            {icon: subjectIcon, text: 'Zwischenspeichern', val: 5},
        ],

        optItems: [
            // {icon: groupIcon, text: 'Geteilt speichern', val: 1},
            {icon: exportIcon, text: 'PDF-Export', val: 2},
            {icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'}
        ],

        showBadges: true,
    }),
    computed: {
        ...mapGetters('badges', ['showCorrectionBadge']),
        neededItems() {
            let result = [];
            if (this.smartphoneUi) {
                result.push({icon: fertigIcon, text: 'Fertig', val: 99, background: 'var(--v-gruen-base)'});
            }
            if (this.mode === 'teacher') {
                result = result.concat([{
                    icon: infoIcon,
                    text: 'Nächste Abgabe',
                    val: 4,
                    disabled: !this.isNextPupilUploadAvailable,
                    tooltip: 'Falls es eine weitere Abgabe in dieser Fachstunde zu korrigieren gibt, können Sie diese über diesen Knopf öffnen.'
                }, {
                    icon: subjectIcon, text: 'Zwischenspeichern', val: 5
                }, {
                    icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'
                }]);
            } else if (this.mode === 'pupil' && this.hasTargetFolder) {
                result = result.concat([{icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'}]);
            } else if (this.mode === 'pupil' && !this.hasTargetFolder) {
                result = result.concat(this.items.concat([{
                    icon: verlassenIcon,
                    text: 'Verlassen',
                    val: 3, color: 'red'
                }]));
            } else if (this.mode !== 'pupil' && this.mode !== 'teacher' && this.hasTargetFolder) {
                result = result.concat([{
                    icon: hochladenIcon,
                    text: 'PDF-Export',
                    val: 2
                },{
                    icon: verlassenIcon,
                    text: 'Verlassen',
                    val: 3,
                    color: 'red'
                }]);
            } else {
                result = result.concat(this.items.concat(this.optItems));
            }
            return result;
        },
        computedCheckExtendedFunctionsSmartphone() {
            return this.wantSmartphoneExtendedElementsOn
        }
    },
    mounted() {
        if (this.limitSaveMenu) {
            this.items.splice(0, 1); // Zwischenspeichern rausnehmen
        }
        this.showBadges = this.showCorrectionBadge;
    },
    methods: {
      ...mapMutations('badges', ['setShowCorrectionBadge']),
        onItemSelected(item) {
            switch (item.val) {
                case 0:
                    this.$emit('onSaveInPrivateFolder');
                    break;
                case 1:
                    this.$emit('onSaveInSharedFolder');
                    break;
                case 2:
                    this.$emit('onExport');
                    break;
                case 3:
                    this.$emit('onLeave');
                    break;
                case 4:
                    this.$emit('onNextPupilUpload');
                    break;
                case 5:
                    this.$emit('onSaveDraft');
                    break;
                case 99:
                    this.$emit('onSave');
            }
        },
        toggleBadgeSending() {
            this.setShowCorrectionBadge(this.showBadges);
            this.$emit('toggleBadgeSending', this.showBadges);
        },
        toggleExtendedFunctionsOnSmartphone() {
          console.log('toggleExtendedFunctionsOnSmartphone');
          this.$emit('toggleExtendedFunctionsOnSmartphone', !this.wantSmartphoneExtendedElementsOn);
        },
    }
}
</script>

<style scoped>

</style>
