import { UnexpectedResponseStatusError } from '@/util/error';

const throwUnexpectedResposeError = async (expectedStatus, response) => {
  let message = '';
  try {
    const body = await response.json();
    message = body.message || body.error.message;
  } catch (error) {
    message = `Status "${expectedStatus}" erwartet, aber "${response.status} ${response.statusText}" erhalten`;
  }
  throw new UnexpectedResponseStatusError(response, message);
};

const checkResponseStatus = async (expectedStatus, response) => {
  if (response.status !== expectedStatus) {
    await throwUnexpectedResposeError(expectedStatus, response);
    return false;
  }
  return true;
};

export { checkResponseStatus };
