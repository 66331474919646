import * as backend from '../../api/backend';
import { checkResponseStatus } from '@/util/check';
import Vue from "vue";

const state = {
    accounts: [],
    me: null,
    devices: null,
};

const getPrettyAccount = (account, rootGetters) => {
    let displayName;

    if (account.role === "maintainer") {
        displayName = "Verwaltung";
    } else if (account.role === "teacher") {
        const teacher = rootGetters['teachers/teachersByAccountId'][account._id];

        displayName = teacher && teacher.lastName ? `${teacher.name[0]}. ${teacher.lastName}` : `Unbekannter Lehrer (${account.accountName})`
    } else if (account.role === "pupil") {
        const pupil = rootGetters['pupils/pupilsByAccountId'][account._id];

        displayName = pupil && pupil.name? `${pupil.name} ${pupil.lastName[0]}.` : `Unbekannter Schüler (${account.accountName})`

    } else if (account.role === "parent") {
        const parent = rootGetters['parents/parentsByAccountId'][account._id];

        const pupilIds = parent?.pupils || [];
        const pupils = pupilIds
            .map((pupilId) => rootGetters['pupils/pupilsById'][pupilId]);
        const pupilNames = pupils
            .filter(pupil => !!pupil)
            .map(pupil => pupil.name? `${pupil.name} ${pupil.lastName[0]}.` : `Unbekannter Schüler`);

        // Example "Eltern Monika S., Dan D."
        displayName = parent ? `Eltern ${pupilNames.join(", ")}` : `Unbekannte Eltern (${account.accountName})`;
    }

    return {
        ...account,
        displayName,
    };
};

const getters = {
    accounts: (state, getters, rootState, rootGetters) => state.accounts
        .map(account => getPrettyAccount(account, rootGetters))
        .filter(account => account !== null), // filter out invalid accounts
    accountsById: (state, getters, rootState, rootGetters) => {
        return state.accounts.reduce((acc, account) =>
        {
            const prettyAccount = getPrettyAccount(account, rootGetters);
            if(!prettyAccount) {
                // Don't add invalid account;
                return acc
            }
            acc[account._id] = getPrettyAccount(account, rootGetters);
            return acc;
        }, {});
    },
    me: state => state.me,
    devices: state => state.devices,
};

const mutations = {
    setAccounts: (state, accounts) => {
        state.accounts = accounts;
    },
    setAccount: (state, account) => {
        console.log('Store.setAccount', account);
        const accountIndex = state.accounts.findIndex(item => item._id === account._id);
        if (accountIndex >= 0){
            Vue.set(state.accounts, accountIndex, account);
        }
        // TODO check if this is reactive
    },
    pushAccount:(state,account) =>{
        const accountIndex = state.accounts.findIndex(item => item._id === account._id);
        if (accountIndex >= 0){
            Vue.set(state.accounts, accountIndex, account);
        }else{
            state.accounts.push(account);
        }
    },
    setMe: (state, me) => {
        state.me = me;
    },
    setDevices: (state, devices) => {
        state.devices = devices;
    },
    clearDevices: (state) => {
        state.devices = [];
    }
};

const actions = {
    fetchAccounts: async ({ commit, dispatch }, params) => {
        try {
            const res = await backend.getAccounts(params);
            await checkResponseStatus(200, res);
            const accounts = await res.json();
            commit('setAccounts', accounts);
            return res.status;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getAccounts({ commit, getters }, update = false) {
        try {
            if (getters.accounts && getters.accounts.length > 0 && !update) {
                return getters.accounts;
            }
            const res = await backend.getAccounts();
            await checkResponseStatus(200, res);
            const allAccounts = await res.json();
            commit('setAccounts', allAccounts);
            return allAccounts;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async pullMe({commit, getters}, account) {
        try {
            let personTypeResponse = null;
            switch (account.role) {
                case 'teacher':
                    personTypeResponse = await backend.getMeTeacher();
                    break;
                case 'pupil':
                    personTypeResponse = await backend.getMePupil();
                    break;
                case 'parent':
                    personTypeResponse = await backend.getMeParent();
                    break;
                case 'maintainer':
                    personTypeResponse = await backend.getMaintainers();
                    break;
            }
            if (personTypeResponse) {
                await checkResponseStatus(200, personTypeResponse);
                let person = await personTypeResponse.json();
                if (account.role === 'maintainer') {
                    person = person[0];
                }
                commit('setMe', person);
                return person;
            }

        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getDevices({ commit, getters }, { accountId, update }) {
        try {
            if (getters.devices && getters.devices.length > 0 && !update) {
                return getters.devices;
            }
            const res = await backend.getDevices(accountId);
            await checkResponseStatus(200, res);
            const allDevices = await res.json();
            commit('setDevices', allDevices);
            return allDevices;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async patchAccount({ commit, getters }, { accountId, payload }) {
        try {
            const res = await backend.patchAccount(accountId, payload);
            await checkResponseStatus(200, res);
            // update specific account entry
            const accountInfo = await res.json();
            commit ('setAccount', accountInfo);
            return accountInfo;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async toggleUsageAgreement({commit},{accountId}){
        try{
            const res = await backend.toggleUsageAgreement(accountId);
            await checkResponseStatus(200, res);
            // update specific account entry
            const accountInfo = await res.json();
            commit ('setAccount', accountInfo);
            return accountInfo;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
