<template>
    <div class="d-flex justify-start">
        <!-- teacher -->
        <v-btn
            v-if="accountRole === 'teacher'"
            id="schlossBtn"
            class="ml-2"
            icon
            :small="windowWidth <= 900 && windowWidth > 770"
            :x-small="windowWidth <= 770"
            @click.stop.prevent="toggleMatrixRoom"
        >
            <img
                :src="lockIcon"
                :class="isOpen ? 'iconToGrey' : 'iconToRed'"
                :alt="isOpen ? 'Chat schließen' : 'Chat öffnen'"
                style="width: 20px; height: 20px; opacity: 0.4; "
            >
        </v-btn>
    </div>
</template>

<script>
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex';

import lockIcon from '@/assets/Icons/lock.svg';

export default {
    name: "ToggleMatrixRoomButton",
    props: {
        room: { required: true, type: Object },
    },
    data: () => ({
        lockIcon,

        isOpen: null,
    }),
    computed : {
        ...mapGetters("auth",["accountRole"]),
        ...mapState('translation', ['targetLang']),
        ...mapState('util', ['windowWidth']),
    },
    watch: {
        room() {
            this.isOpen = this.room.isOpen;
        }
    },
    async mounted() {
        this.isOpen = this.room.isOpen;
    },
    methods: {
        ...mapActions("matrix", ["getMatrixRoomLockStatus", "setMatrixRoomLockStatus"]),
        ...mapMutations("snackbar", ["showSnackbar"]),

        async toggleMatrixRoom() {
            let newStatus = !this.isOpen;
            let data = {
                roomId: this.room.roomId,
                isOpen: newStatus,
            }
            const res = await this.setMatrixRoomLockStatus(data);
            if (res.status === 201) {
                this.isOpen = newStatus;
                this.$emit("lockStatusChanged", this.isOpen, this.room.roomId);

                // and show snackbar
                // note that the request to change the status of the room has now already been executed,
                // meaning if you clicked on the button to open it, the room's isOpen status is now already true,
                // thus room.isOpen needs to show the message that it has now been opened.
                if (this.room.isOpen) {
                    this.showSnackbar({message: 'Der Chat ist wieder geöffnet.'});
                } else {
                    this.showSnackbar({message: 'Der Chat ist ist jetzt geschlossen. Es können keine weiteren Nachrichten gesendet werden.'});
                }
            }
        },
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    border-radius: 8px;
}

.iconToGreen {
    filter: brightness(0) saturate(100%) invert(64%) sepia(40%) saturate(579%) hue-rotate(43deg) brightness(98%) contrast(91%) !important;
}

#schlossBtn {
    height: 45px;
    min-width: 45px;
    width: 45px;

    img {
        height: 30px;
        filter: brightness(1000%);
    }
}

@media only screen and (max-width: 900px) {
    #schlossBtn {
        height: 40px;
        min-width: 40px;
        width: 40px;

        img {
            height: 21px !important;
            margin: 0 !important;
        }
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #schlossBtn {
        margin-left: 0 !important;
        padding: 0 !important;
        width: 40px !important;

        img {
            margin: 0 !important;
        }
    }
}
</style>
