<template>
    <v-card
        elevation="0"
        style="border-radius: 0 0 5px 5px"
    >
        <v-card-title
            class="py-2 px-0 d-flex flex-row justify-space-around align-center "
            style="background-color: var(--v-timetableColor-base); color: white"
        >
            <p style="font-size: x-large">
                {{ editMode ? 'Zeitslot bearbeiten' : 'Zeitslot erstellen' }}
            </p>  
 
            <v-btn
                text
                elevation="0"
                style="margin-right: -8px; min-width: 30px!important;"
                @click="close"
            >
                <img
                    :src="cancelIcon"
                    class="icon iconToWhite icon20"
                    alt="Abbrechen"
                >
            </v-btn>
        </v-card-title>

        <div class="pickerContainer">
            <p
                :hidden="!isInvalidTime"
                class="ml-2"
                style="color: red; font-size: smaller"
            >
                Startzeit muss vor Endzeit liegen!
            </p>
            <div class="timeRow">
                <p>
                    Start:
                </p>

                <v-text-field
                    v-model="timePickerStart"
                    :disabled="disabled"
                    flat
                    type="time"
                    :persistent-hint="true"
                    hint="Klicken um zu bearbeiten"
                    class="centered-input"
                    :background-color="disabled ? 'grey lighten-3' : 'white'"
                    style="max-width: 160px;"
                    append-outer-icon=""
                /> 
            </div>
            <div class="timeRow ">
                <p>
                    Ende:
                </p>
                <v-text-field
                    v-model="timePickerEnd"
                    :disabled="disabled"
                    flat
                    :persistent-hint="true"
                    hint="Klicken um zu bearbeiten"
                    type="time"
                    class="centered-input"
                    :background-color="disabled ? 'grey lighten-3' : 'white'"
                    style="max-width: 160px;"
                    append-outer-icon=""
                />  
            </div>
        </div>

        <v-card-actions
            class="d-flex justify-space-between"
            :class="editMode? 'flex-row':'flex-column'"
        >
            <div
                v-if="!editMode"
                class="d-flex flex-row justify-flex-start align-center pb-6"
            >
                <v-checkbox
                    v-model="isNewTimeslotBreak"
                    :label="'Pause'"
                    class="mr-2"
                />
                <v-checkbox
                    v-if="accountRole === 'maintainer'"
                    v-model="timeslotForAllGroups"
                    :label="'Alle Gruppen'"
                />
            </div>
            <div
                class="d-flex"
            > 
                <v-btn
                    v-if="editMode"
                    style="background-color: var(--v-error-base); color: white; font-size: smaller"
                    width="120"

                    @click="showAreYouSure()"
                >
                    <img
                        :src="trashIcon"
                        class="icon20 iconToWhite mr-2"
                        alt="Löschen"
                    >
                    Löschen
                </v-btn>
                <v-btn
                    v-if="editMode"
                    class="ml-4"
                    :disabled="isInvalidTime"
                    style="background-color: var(--v-success-base); color: white; font-size: smaller"
                    width="120"
                    @click="saveTimeslot"
                >
                    <img
                        :src="saveIcon"
                        class="icon20 iconToWhite ml-1"
                        alt="Änderung Bestätigen"
                    >
                    Bestätigen
                </v-btn>
                <v-btn
                    v-else-if="!editMode"
                    style="position: absolute; bottom: 0.5rem; right: 1rem;background-color: var(--v-success-base); color: white; font-size: smaller"
                    width="120"
                    :disabled="isInvalidTime"
                    @click="createTimeslot"
                >
                    <img
                        :src="plusIcon"
                        class="icon20 iconToWhite mr-2"
                        alt="Änderung Bestätigen"
                    >
                    Erstellen
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import trashIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import plusIcon from "@/assets/Icons/plus.svg";
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import { mapGetters } from 'vuex';

export default {
    components: {
    },
    props: {
        timeslot: {required: false, },
        newTimeStart:{required: false, },
        newTimeEnd:{required: false, },
        editMode: {required: false, type: Boolean, default: false},
        disabled: { required: false, default: false, type: Boolean},
    },
    data: () => ({
        hoursAndMinutes: '',
        trashIcon,
        plusIcon,
        saveIcon,
        timePickerStart: '',
        timePickerEnd:'',
        cancelIcon,
        timeslotForAllGroups: true,
        isNewTimeslotBreak: false,
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),

        isInvalidTime() {
            if (!this.timePickerStart || !this.timePickerEnd) {
                // If either field is empty, consider it valid
                return false;
            }

            // Convert time strings to Date objects
            const startTime = new Date(`2000-01-01T${this.timePickerStart}`);
            const endTime = new Date(`2000-01-01T${this.timePickerEnd}`);

            // Check if startTime is greater than or equal to endTime
            return startTime >= endTime;
            },
    },

    async mounted() {
        if(this.editMode){
            this.timePickerStart = this.timeslot?.timePickerStart;
            this.timePickerEnd = this.timeslot?.timePickerEnd;
        }else{
            this.timePickerStart = this.newTimeStart;
            this.timePickerEnd = this.newTimeEnd;
        }
    },
    methods: {
        createTimeslot() {
            const start =this.timePickerStart;
            const end = this.timePickerEnd;
            const newTimeslot ={
                start,
                end,
                timeslotForAllGroups: this.timeslotForAllGroups,
                isNewTimeslotBreak: this.isNewTimeslotBreak,
                }
            this.$emit('createTimeslot', newTimeslot);
        },
        showAreYouSure(){
            this.$emit('showAreYouSure',this.timeslot);
        },
        saveTimeslot(){
            this.$emit('saveTimeslot',this.timePickerStart, this.timePickerEnd)
        },
        close(){
            if(this.editMode){
            this.$emit('closeEditTimeslot');

            }else{
                this.$emit('closeCreateTimeslot')
            }
        }

    },
}
</script>

<style scoped>
    .pickerContainer {
        display: flex;
        flex-direction: column;
    }
    .centered-input >>> input {
      text-align: center;
      font-size: x-large;
      line-height: 32px;
      max-height: 32px;
    }
    .timeRow{
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items:baseline;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .timeRow p{
        font-size: x-large;
    }
</style>
<style lang="scss">
    .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
        min-height: auto !important;
        cursor: text;
    }
    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: auto !important;
        cursor: text;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
        padding: 0 0 0 9px;
    }
</style>
