<template>
    <BaseMenu
        :items="items"
        :show-list="showList"
        @close="$emit('close')"
        @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <!-- <div>
            Custom data
        </div> -->
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import hausBlatt from '@/assets/Icons/FileCreator/EKLARA_Icons_editor_leeres_Blatt_hauslinien.svg';
import hochladenIcon from '@/assets/Icons/FaceliftIcons/hochladen.svg'
import kopierenIcon from '@/assets/Icons/FaceliftIcons/kopieren_datei_.svg'
import papierkorbIcon from "../../../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import {mapMutations} from "vuex";

export default {
    name: 'AddPageMenu',
    components: { BaseMenu },
    props: {
        pageCount: { type: Number, required: true },
    },
    data() {
        return {
            showList: true,
            items: [
                { icon: leeresBlatt, text: 'Leer', ariaLabel: 'Leere Seite' },
                { icon: liniertesBlatt, text: 'Liniert', ariaLabel: 'Linierte Seite' },
                { icon: kariertesBlatt, text: 'Kariert', ariaLabel: 'Karierte Seite' },
                { icon: hausBlatt, text: 'Haus', ariaLabel: 'Haus Seite' },
                { icon: hochladenIcon, text: 'Individuell', ariaLabel: 'Undividuelle Seite' },
                { icon: kopierenIcon, text: 'Duplizieren', ariaLabel: 'Seite Duplizieren' },
                { icon: papierkorbIcon, text: 'Seite löschen', color: 'Red', ariaLabel: 'Seite löschen' },
            ],
        }
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        onItemSelected(item){
            switch (item.text) {
                case 'Individuell':
                    this.$emit('addCustomPage');
                    this.$emit('close');
                    break;
                case 'Seite löschen':
                    if (this.pageCount > 1) {
                        this.$emit('deleteActivePage');
                    } else {
                        this.showSnackbar({
                            message: 'Es müssen mindestens zwei Seiten vorhanden sein um eine Seite löschen zu können.',
                            color: 'warning'
                        })
                    }
                    break;
                case 'Duplizieren':
                    this.$emit('duplicateActivePage');
                    break;
                default:
                    this.$emit('addAdditionalPage',
                        item.text.toLowerCase());
                    this.$emit('close');
                    break;
            }
        }
    }
}
</script>

<style scoped>

</style>
