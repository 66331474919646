import * as backend from '../../api/backend'
import { checkResponseStatus } from "@/util/check";

const actions = {
    // returns letters for corresponding role (teacher / parent)
    async getAllParentalLetters ({ commit, dispatch }, params) {
        const getIconFromMessage = async (icon) =>{
            const res = await backend.getIconUpload(icon);
            const blob = await res.blob();
            if(blob){
                const url = URL.createObjectURL(blob);
                return url;
            }
            else {
                return null;
            }
        };
        try {
            const res = await backend.getAllParentalLetters(params);
            await checkResponseStatus(200, res);
            const letters = await res.json();
            const lettersWithBlobs = await Promise.all(letters.map(async(letter) => ({
                    ...letter,
                    iconBlob : letter.uploadedIcon ?
                        await getIconFromMessage(letter.icon) : null,
                })
            ));
            return lettersWithBlobs;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // create letter, to attach files use backend.postParentalLetterFile with XmlHttpRequest
    async createLetter ({ commit, dispatch }, params) {
        try {
            const res = await backend.createParentalLetter(params);
            await checkResponseStatus(201, res);
            const letter = await res.json();
            letter.status = 201;
            return letter;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async replyToParentalLetter ({ commit, dispatch }, params) {
        try {
            console.log('replying to Letter');
            const parentalLetterId = params.originalLetter;
            delete params.originalLetter;
            const res = await backend.createReply(parentalLetterId, params)
            const letter = await res.json();
            return letter;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    // add given account ID to seenBy field of given letter
    async setSeenBy ({ commit, dispatch }, params) {
        try {
            const parentalLetterId = params.letterId;
            const res = await backend.setSeenBy(parentalLetterId, params);

            await checkResponseStatus(200, res);
            return res.status;
        }
        catch (err) {
            console.warn(err);
            return err;
        }
    },

    // only updates title, content, receivers and signature, files need additional method
    async updateParentalLetter ({ commit, dispatch }, params) {
        try {
            const letterId = params.id;
            const res = await backend.patchParentalLetter(letterId, params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // delete letter and all it's responses (including the files)
    async deleteParentalLetter ({ commit, dispatch }, params) {
        try {
            const res = await backend.deleteParentalLetter(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    // returns groups and participants which the teacher is the leader of
    async getParentalLetterList ({ commit, dispatch }, params) {
        try {
            const res = await backend.getParentalLetterList();
            await checkResponseStatus(200, res);
            const list = await res.json();
            return list;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getSignature({ commit, dispatch }, letterResponseId) {
        const response = await backend.getSignature(letterResponseId);

        await checkResponseStatus(200, response);
        return response;
    },

    /**
     * I wrote this method so that all relevant changes you take in Users.vue can directly be fetched in
     * MessagesWidget.vue if you choose to go there afterwards. This eliminates the need to refresh your page every
     * time. The use of this method can be extended and outsourced to other widgets if necessary.
     *
     * @param value boolean which decides if possible receivers should be fetched again because a change was made in Users.vue
     */
    async toggleUpdatePossible({ commit }, value) {
        commit("setUpdatePossible", value);
    },

    async getParentalLetterFile({ commit }, { letterId, fileId }) {
        try {
            // pull normal upload and return directly as URL
            const res = await backend.getParentalLetterFile(letterId, fileId);
            // throw error if upload could not be gotten
            await checkResponseStatus(200, res)
            let blob = await res.blob();
            return window.URL.createObjectURL(blob);
        } catch (e) {
            console.error(e);
            // return empty blob url
            const blob = new Blob([], { type: 'image/png' });
            return window.URL.createObjectURL(blob);
        }
    },
    async getParentalLetterReplyFile({ commit }, { parentalLetterReplyId, fileId }) {
        try {
            // pull normal upload and return directly as URL
            const res = await backend.getReplyFile(parentalLetterReplyId, fileId);
            // throw error if upload could not be gotten
            await checkResponseStatus(200, res)
            let blob = await res.blob();
            return window.URL.createObjectURL(blob);
        } catch (e) {
            console.error(e);
            // return empty blob url
            const blob = new Blob([], { type: 'image/png' });
            return window.URL.createObjectURL(blob);
        }
    },
    async deleteParentalLetterFile({ commit }, { letterId, fileId }) {
        try {
            const res = await backend.deleteParentalLetterFile(letterId, fileId);
            return res;
        } catch (e) {
            console.error(e);
        }
    },

    async postParentalLetterFile({ commit }, {letterId, file}) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const XmlHttpRequest = await backend.postParentalLetterFile(letterId, file);

            XmlHttpRequest.addEventListener('load', (e) => {
                return XmlHttpRequest;
            });
            XmlHttpRequest.send(formData)
        } catch (e) {
            console.error(e);
        }
    },
    async postReplyLetterFile({ commit }, {parentalLetterReplyId, file}) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const XmlHttpRequest = await backend.postReplyFile(parentalLetterReplyId, file);

            XmlHttpRequest.addEventListener('load', (e) => {
                return XmlHttpRequest;
            });
            XmlHttpRequest.send(formData)
        } catch (e) {
            console.error(e);
        }
    },
    async createResponse({ commit }, {letterId, file, fileName, childId}) {
        try {
            const formData = new FormData();
            formData.append("file", file, fileName);
            formData.append("account", childId);

            const XmlHttpRequest = await backend.createResponse(letterId, file);

            XmlHttpRequest.send(formData);
            return XmlHttpRequest;
        } catch (e) {
            console.error(e);
        }
    }
};

const mutations = {
    setUpdatePossible(state, value) {
        state.updatePossible = value;
    }
};

const getters = {
    isUpdatePossible: (state) => state.updatePossible
};

const state = {
    updatePossible: false
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
