import { render, staticRenderFns } from "./PinboardCardElement.vue?vue&type=template&id=98b05c94&scoped=true&"
import script from "./PinboardCardElement.vue?vue&type=script&lang=js&"
export * from "./PinboardCardElement.vue?vue&type=script&lang=js&"
import style0 from "./PinboardCardElement.vue?vue&type=style&index=0&id=98b05c94&prod&lang=css&"
import style1 from "./PinboardCardElement.vue?vue&type=style&index=1&id=98b05c94&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98b05c94",
  null
  
)

export default component.exports