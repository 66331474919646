<template>
    <v-tooltip top>
        <template v-slot:activator="{ on }">
            <v-btn
                class="fileListButton"
                :style="`background-color: ${primaryColor ? primaryColor : 'var(--v-chatColor-base)'}; width: ${buttonSize}px; height: ${buttonSize}px`"
                x-small
                elevation="0"
                v-on="on"
                @click="eyeClicked"
            >
                <img
                    :src="fasFaEye"
                    :alt="isLibre ? 'In LibreOffice anzeigen' : 'Im Editor anzeigen'"
                    style="filter: brightness(100%)"
                    class="fileListButtonIcon"
                >
            </v-btn>
        </template>
        <span>{{ isLibre ? 'In LibreOffice anzeigen' : 'Im Editor anzeigen' }}</span>
    </v-tooltip>
</template>

<script>
import fasFaEye from "@/assets/Icons/fas-fa-eye.svg";
import {
    allowedExtensionsForEditor, allowedExtensionsForLibre,
} from "@/constants/allowedMimeTypes";
import {mapMutations} from "vuex";

export default {
    name: "FileListEditButton",
    props: {
        file: { type: Object, required: true },
        primaryColor: { type: String, required: false },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        fasFaEye,
        isLibre: false,
    }),
    mounted() {
        this.isLibre = allowedExtensionsForLibre.includes(this.file.title.split('.').pop().toLowerCase());
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),

        eyeClicked(event) {
            if (this.file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return;
            }
            event.stopPropagation();
            if (!this.isLibre) {
                this.$emit('openInEditor');
            } else {
                this.$emit('openInLibre');
            }
        }
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
}
</style>
