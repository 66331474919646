<template>
    <div style="width: 100%;">
        <v-card
            :flat="embeddedStyle"
            :style="embeddedStyle ? 'border-radius: 8px' : ''"
        >
            <vue-custom-scrollbar
                v-if="embeddedStyle"
                class="scroll-area-maintainer"
                :settings="settings"
            >
                <div
                    class="d-flex flex-column"
                    style="margin-top: 16px"
                >
                    <div class="d-flex flex-row align-center justify-space-between mx-3">
                        {{ `Uhrzeit: ${timeslot.timePickerStart} - ${timeslot.timePickerEnd}` }}
                    </div>
                    <div class="d-flex flex-row align-center justify-space-between">
                        <!-- <div class="d-flex flex-column textGrau pa-3">
                            Fach:
                        </div> -->
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedSubject"
                                :items="subjects"
                                item-value="_id"
                                :item-text="item => item.name"
                                :menu-props="{ offsetY: true }"
                                :style="windowWidth < 901 ? 'width: 65vw; max-width: 65vw;' : 'width: 25vw; max-width: 25vw;'"
                                outlined
                                dense
                                label="Fach"
                                hide-details
                                return-object
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item
                                        hide-details
                                        @click="faecherDialog = !faecherDialog"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neues Fach
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>
                            <v-dialog
                                v-if="faecherDialog"
                                v-model="faecherDialog"
                                max-height="1000px"
                                transition="dialog-bottom-transition"
                            >
                                <FachErstellen
                                    @addSubject="addSubject"
                                    @stopEvent="closeDialog()"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <!-- <div class="d-flex flex-column textGrau pa-3">
                            Lehrer:
                        </div> -->
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedTeachers"
                                :disabled="!selectedSubject"
                                :items="currentSubjectTeachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                :menu-props="{ offsetY: true }"
                                :style="windowWidth < 901 ? 'width: 65vw; max-width: 65vw;' : 'width: 25vw; max-width: 25vw;'"
                                no-data-text="Kein Lehrer für dieses Fach zuständig"
                                outlined
                                dense
                                label="Lehrer"
                                multiple
                                hide-details
                                return-object
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item @click="createTeacherDialog = true">
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neuer Lehrer
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>

                            <!-- Create teacher dialog -->
                            <!-- TODO: The teacher being saved also needs to be associated with the current subject
                            otherwise the create Teacher option doesn't make much sense in this context -->
                            <v-dialog
                                v-if="createTeacherDialog"
                                v-model="createTeacherDialog"
                                max-height="1000px"
                                max-width="400"
                                style="border-radius: 16px"
                            >
                                <CreateTeacher
                                    :header-color="'var(--v-timetableColor-base)'"
                                    :add-new-teacher="addNewTeacher"
                                    :subjects="subjects"
                                    @stopEvent="createTeacherCallback"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        v-if="edit && !selectedRoom && !this.weekly"
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <!-- <div class="d-flex flex-column textGrau pa-3">
                            Vertretung:
                        </div> -->
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedSubstitution"
                                :disabled="!selectedSubject"
                                :items="filteredAvailableTeachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                :menu-props="{ offsetY: true }"
                                :style="windowWidth < 901 ? 'width: 65vw; max-width: 65vw;' : 'width: 25vw; max-width: 25vw;'"
                                no-data-text="Kein Lehrer für dieses Fach zuständig"
                                outlined
                                label="Vertretung"
                                dense
                                hide-details
                                return-object
                                @focus="showWarning"
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item @click="createTeacherDialog = true">
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neuer Lehrer
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>

                            <!-- Create teacher dialog -->
                            <!-- TODO: The teacher being saved also needs to be associated with the current subject
                            otherwise the create Teacher option doesn't make much sense in this context -->
                            <v-dialog
                                v-if="createTeacherDialog"
                                v-model="createTeacherDialog"
                                max-height="1000px"
                                max-width="400"
                                style="border-radius: 16px"
                            >
                                <CreateTeacher
                                    :header-color="'var(--v-timetableColor-base)'"
                                    :add-new-teacher="addNewTeacher"
                                    :subjects="subjects"
                                    @stopEvent="createTeacherCallback"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        v-if="selectedRoom"
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <!-- <div class="d-flex flex-column textGrau pa-3">
                            Gruppe:
                        </div> -->
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedGroup"
                                :items="filteredGroups"
                                :item-value="item => item._id"
                                :item-text="item => item.name"
                                :menu-props="{ offsetY: true }"
                                :style="windowWidth < 901 ? 'width: 65vw; max-width: 65vw;' : 'width: 25vw; max-width: 25vw;'"
                                outlined
                                dense
                                label="Gruppe"
                                hide-details
                                return-object
                                @change="() => { searchGroupNames = ''}"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        class="px-2"
                                        @mousedown.prevent
                                    >
                                        <v-text-field
                                            v-model="searchGroupNames"
                                            style="background-color: white;"
                                            solo
                                            dense
                                            flat
                                            placeholder="suchen"
                                            hide-details
                                        >
                                            <template v-slot:prepend-inner>
                                                <img
                                                    :src="searchIcon"
                                                    class="icon20"
                                                    alt="suchen"
                                                >
                                            </template>
                                        </v-text-field>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div
                        v-else
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <!-- <div class="d-flex flex-column textGrau pa-3">
                            Raum:
                        </div> -->
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedRooms"
                                :disabled="!selectedSubject"
                                :items="currentSubjectRooms"
                                item-value="_id"
                                :item-text="item => item.roomName + ' (' + item.available + ')'"
                                :menu-props="{ offsetY: true }"
                                :style="windowWidth < 901 ? 'width: 65vw; max-width: 65vw;' : 'width: 25vw; max-width: 25vw;'"
                                outlined
                                dense
                                label="Raum"
                                multiple
                                hide-details
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <div style="display: flex">
                                        <v-text-field
                                            v-model="addRoomName"
                                            placeholder="Neu (max. 10 Zeichen)"
                                            single-line
                                            hide-details
                                            style="align-self: center"
                                            class="pa-2"
                                            @keydown.enter="addRoom"
                                            @blur="addRoom"
                                        >
                                            <template #prepend-inner>
                                                <div>
                                                    <v-icon
                                                        style="height: 24px"
                                                    >
                                                        mdi-plus
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <template #append>
                                                <div>
                                                    <v-icon
                                                        style="height: 24px"
                                                        @click="addRoom"
                                                    >
                                                        mdi-check
                                                    </v-icon>
                                                </div>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <v-divider />
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row pb-0">
                    <div class="d-flex flex-column textGrau pb-0 pa-3">
                        Wiederholung:
                    </div>
                </div>
                <div
                    class="d-flex flex-row py-0 pa-3"
                    style="justify-content: space-between; align-items: center; max-width: 70vw;"
                >
                    <div class="d-flex flex-column py-0">
                        <v-checkbox
                            v-model="weekly"
                            style="margin-top: 0 !important; padding: 0"
                            label="Wöchentlich bis"
                            hide-details
                        />
                    </div>
                    <div class="d-flex flex-column py-0 pb-2">
                        <v-menu
                            v-model="dtPickerMenu"
                            :disabled="!weekly"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            :nudge-width="70"
                            :nudge-right="10"
                            :width="400"
                            :z-index="10"
                            :max-width="400"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="weeklyTill"
                                    label="Wöchentlich bis"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    :disabled="!weekly"
                                    v-bind="attrs"
                                    :class="{ errorInput: fields.weeklyField }"
                                    class="ma-0 pa-0 ml-2"
                                    v-on="on"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon>
                                            mdi-calendar
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="weeklyTill"
                                :allowed-dates="weekdaysOnly"
                                locale="de-DE"
                                first-day-of-week="1"
                                no-title
                                show-week
                                @input="()=>{ dtPickerMenu = false; fullYear = false; halfYear = false}"
                            />
                        </v-menu>
                    </div>
                </div>
                <div
                    v-if="!edit"
                    class="d-flex flex-row py-1 px-3"
                    style="justify-content: space-between; align-items: center"
                >
                    <div
                        class="py-0"
                    >
                        <v-checkbox
                            v-model="halfYear"
                            style="margin-top: 0 !important"
                            label="restliches Halbjahr"
                        />
                    </div>
                    <div
                        class="py-0"
                    >
                        <v-checkbox
                            v-model="fullYear"
                            style="margin-top: 0 !important"
                            label="restliches Schuljahr"
                        />
                    </div>
                </div>

                <v-card-actions class="pr-3">
                    <v-spacer />

                    <v-btn
                        class="text-none"
                        :color="edit ? 'rot' : ''"
                        elevation="0"
                        :dark="edit"
                        @click="edit ? deleteDialog = true : cancel()"
                    >
                        {{ edit ? 'Löschen' : 'Abbrechen' }}
                    </v-btn>

                    <v-btn
                        class="text-none"
                        color="gruen"
                        elevation="0"
                        dark
                        @click="edit ? updateAppointment() : saveAppointment()"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>

            <!-- <br> -->
            </vue-custom-scrollbar>
            <div v-else>
                <div
                    class="d-flex flex-row justify-space-between  white--text pb-2 pt-2 px-4"
                    style="justify-content: space-between; align-items: center"
                    :style="'background-color: ' + (selectedSubject ? selectedSubject.color : '#CACACA')"
                >
                    <!-- iconToWhite could become problem, when subject color is also white or close to white -->
                    <div style="display: flex; align-items: center">
                        <img
                            :src="subjectIcon"
                            class="icon20 iconToWhite"
                            alt="Fach"
                        >

                        <span
                            v-if="selectedRoom && !appointment"
                            class="ml-4"
                            style="font-size: larger"
                        >
                            {{ selectedRoom.roomName }} buchen
                        </span>
                        <span
                            v-else-if="!selectedSubject"
                            class="ml-4"
                            style="font-size: larger"
                        >
                            Fach hinzufügen
                        </span>
                        <span
                            v-else
                            class="ml-4"
                            style="font-size: larger"
                        >
                            Fach bearbeiten
                        </span>
                    </div>

                    <v-btn
                        text
                        x-small
                        elevation="0"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                            @click="cancel()"
                        >
                    </v-btn>
                </div>

                <div
                    class="d-flex flex-column"
                    style="margin-top: 16px"
                >
                    <div class="d-flex flex-row align-center justify-space-between mx-3">
                        {{ `Uhrzeit: ${timeslot.timePickerStart} - ${timeslot.timePickerEnd}` }}
                    </div>
                    <div class="d-flex flex-row align-center justify-space-between">
                        <div class="d-flex flex-column textGrau pa-3">
                            Fach:
                        </div>
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedSubject"
                                :items="subjects"
                                item-value="_id"
                                :item-text="item => item.name"
                                :menu-props="{ offsetY: true }"
                                style="width: 250px; max-width: 250px"
                                outlined
                                dense
                                hide-details
                                return-object
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item
                                        hide-details
                                        @click="faecherDialog = !faecherDialog"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neues Fach
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>
                            <v-dialog
                                v-if="faecherDialog"
                                v-model="faecherDialog"
                                max-height="1000px"
                                transition="dialog-bottom-transition"
                            >
                                <FachErstellen
                                    @addSubject="addSubject"
                                    @stopEvent="closeDialog()"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <div class="d-flex flex-column textGrau pa-3">
                            Lehrer:
                        </div>
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedTeachers"
                                :disabled="!selectedSubject"
                                :items="currentSubjectTeachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                :menu-props="{ offsetY: true }"
                                style="width: 250px; max-width: 250px"
                                no-data-text="Kein Lehrer für dieses Fach zuständig"
                                outlined
                                dense
                                multiple
                                hide-details
                                return-object
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item @click="createTeacherDialog = true">
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neuer Lehrer
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>

                            <!-- Create teacher dialog -->
                            <!-- TODO: The teacher being saved also needs to be associated with the current subject
                            otherwise the create Teacher option doesn't make much sense in this context -->
                            <v-dialog
                                v-if="createTeacherDialog"
                                v-model="createTeacherDialog"
                                max-height="1000px"
                                max-width="400"
                                style="border-radius: 16px"
                            >
                                <CreateTeacher
                                    :header-color="'var(--v-timetableColor-base)'"
                                    :add-new-teacher="addNewTeacher"
                                    :subjects="subjects"
                                    @stopEvent="createTeacherCallback"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        v-if="edit && !selectedRoom && !this.weekly"
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <div class="d-flex flex-column textGrau pa-3">
                            Vertretung:
                        </div>
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedSubstitution"
                                :disabled="!selectedSubject"
                                :items="filteredAvailableTeachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                :menu-props="{ offsetY: true }"
                                style="width: 250px; max-width: 250px"
                                no-data-text="Kein Lehrer für dieses Fach zuständig"
                                outlined
                                dense
                                hide-details
                                return-object
                                @focus="showWarning"
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <v-list-item @click="createTeacherDialog = true">
                                        <v-list-item-action>
                                            <v-icon>
                                                mdi-plus-circle
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Neuer Lehrer
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-select>

                            <!-- Create teacher dialog -->
                            <!-- TODO: The teacher being saved also needs to be associated with the current subject
                            otherwise the create Teacher option doesn't make much sense in this context -->
                            <v-dialog
                                v-if="createTeacherDialog"
                                v-model="createTeacherDialog"
                                max-height="1000px"
                                max-width="400"
                                style="border-radius: 16px"
                            >
                                <CreateTeacher
                                    :header-color="'var(--v-timetableColor-base)'"
                                    :add-new-teacher="addNewTeacher"
                                    :subjects="subjects"
                                    @stopEvent="createTeacherCallback"
                                />
                            </v-dialog>
                        </div>
                    </div>
                    <div
                        v-if="selectedRoom"
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <div class="d-flex flex-column textGrau pa-3">
                            Gruppe:
                        </div>
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedGroup"
                                :items="filteredGroups"
                                :item-value="item => item._id"
                                :item-text="item => item.name"
                                :menu-props="{ offsetY: true }"
                                style="width: 250px; max-width: 250px"
                                outlined
                                dense
                                hide-details
                                return-object
                                @change="() => { searchGroupNames = ''}"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        class="px-2"
                                        @mousedown.prevent
                                    >
                                        <v-text-field
                                            v-model="searchGroupNames"
                                            style="background-color: white;"
                                            solo
                                            dense
                                            flat
                                            placeholder="suchen"
                                            hide-details
                                        >
                                            <template v-slot:prepend-inner>
                                                <img
                                                    :src="searchIcon"
                                                    class="icon20"
                                                    alt="suchen"
                                                >
                                            </template>
                                        </v-text-field>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div
                        v-else
                        class="d-flex flex-row align-center"
                        style="justify-content: space-between"
                    >
                        <div class="d-flex flex-column textGrau pa-3">
                            Raum:
                        </div>
                        <div class="d-flex flex-column pa-3">
                            <v-select
                                v-model="selectedRooms"
                                :disabled="!selectedSubject"
                                :items="currentSubjectRooms"
                                item-value="_id"
                                :item-text="item => item.roomName + ' (' + item.available + ')'"
                                :menu-props="{ offsetY: true }"
                                style="width: 250px; max-width: 250px"
                                outlined
                                dense
                                multiple
                                hide-details
                            >
                                <template
                                    v-if="!hideAddSchoolSubjectOptionInSelector"
                                    v-slot:prepend-item
                                >
                                    <div style="display: flex">
                                        <v-text-field
                                            v-model="addRoomName"
                                            placeholder="Neu (max. 10 Zeichen)"
                                            single-line
                                            hide-details
                                            style="align-self: center"
                                            class="pa-2"
                                            @keydown.enter="addRoom"
                                            @blur="addRoom"
                                        >
                                            <template #prepend-inner>
                                                <div>
                                                    <v-icon
                                                        style="height: 24px"
                                                    >
                                                        mdi-plus
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <template #append>
                                                <div>
                                                    <v-icon
                                                        style="height: 24px"
                                                        @click="addRoom"
                                                    >
                                                        mdi-check
                                                    </v-icon>
                                                </div>
                                            </template>
                                        </v-text-field>
                                    </div>
                                    <v-divider />
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row pb-0">
                    <div class="d-flex flex-column textGrau pb-0 pa-3">
                        Wiederholung:
                    </div>
                </div>
                <div
                    class="d-flex flex-row py-0 pa-3"
                    style="justify-content: space-between; align-items: center"
                >
                    <div class="d-flex flex-column py-0">
                        <v-checkbox
                            v-model="weekly"
                            style="margin-top: 0 !important; padding: 0"
                            label="Wöchentlich bis"
                            hide-details
                        />
                    </div>
                    <div class="d-flex flex-column py-0 pb-2">
                        <v-menu
                            v-model="dtPickerMenu"
                            :disabled="!weekly"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            :nudge-width="70"
                            :nudge-right="10"
                            :width="400"
                            :z-index="10"
                            :max-width="400"
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="weeklyTill"
                                    label="Wöchentlich bis"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    :disabled="!weekly"
                                    v-bind="attrs"
                                    :class="{ errorInput: fields.weeklyField }"
                                    class="ma-0 pa-0 ml-2"
                                    v-on="on"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon>
                                            mdi-calendar
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="weeklyTill"
                                :allowed-dates="weekdaysOnly"
                                locale="de-DE"
                                first-day-of-week="1"
                                no-title
                                show-week
                                @input="()=>{ dtPickerMenu = false; fullYear = false; halfYear = false}"
                            />
                        </v-menu>
                    </div>
                </div>
                <div
                    v-if="!edit"
                    class="d-flex flex-row py-1 px-3"
                    style="justify-content: space-between; align-items: center"
                >
                    <div
                        class="py-0"
                    >
                        <v-checkbox
                            v-model="halfYear"
                            style="margin-top: 0 !important"
                            label="restliches Halbjahr"
                        />
                    </div>
                    <div
                        class="py-0"
                    >
                        <v-checkbox
                            v-model="fullYear"
                            style="margin-top: 0 !important"
                            label="restliches Schuljahr"
                        />
                    </div>
                </div>

                <v-card-actions class="pr-3">
                    <v-spacer />

                    <v-btn
                        class="text-none"
                        :color="edit ? 'rot' : ''"
                        elevation="0"
                        :dark="edit"
                        @click="edit ? deleteDialog = true : cancel()"
                    >
                        {{ edit ? 'Löschen' : 'Abbrechen' }}
                    </v-btn>

                    <v-btn
                        class="text-none"
                        color="gruen"
                        elevation="0"
                        dark
                        @click="edit ? updateAppointment() : saveAppointment()"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>

        <!-- Delete dialog -->
        <v-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            persistent
            max-width="400"
            style="border-radius: 16px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-timetableColor-base)"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Termin löschen?
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon30 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-4">
                    Sind Sie sicher, dass Sie diesen Termin löschen möchten? <br>
                    <v-radio-group
                        v-if="appointment && appointment.rule"
                        v-model="deleteRecurringAppointment"
                    >
                        <v-radio
                            :value="'onlyThis'"
                            label="Nur diesen Termin"
                        />
                        <v-radio
                            :value="'thisAndFollowing'"
                            label="Diesen Termin und alle folgenden"
                        />
                        <v-radio
                            :value="'allOfThem'"
                            label="Alle Termine"
                        />
                    </v-radio-group>
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="rot"
                        dark
                        @click="deleteAction"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="changeInRuleDialog"
            persistent
            max-width="400"
            style="border-radius: 16px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-timetableColor-base)"
                    class="pa-2 px-2 pl-4 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Alle Termine bearbeiten?
                    </p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="changeInRuleDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text class="pa-4">
                    Wollen sie alle Termine dieser Serie bearbeiten? <br>
                    <v-radio-group
                        v-model="updateRecurringAppointment"
                    >
                        <v-radio
                            :value="'onlyThis'"
                            label="Nur diesen Termin"
                        />
                        <v-radio
                            :value="'allOfThem'"
                            label="Alle Termine"
                        />
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="text-none"
                        elevation="0"
                        @click="changeInRuleDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="text-none"
                        elevation="0"
                        color="gruen"
                        dark
                        @click="updateAppointment"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import FachErstellen from '@/components/Verwaltung/FachErstellen';
import CreateTeacher from '@/components/Verwaltung/CreateTeacher';
import vueCustomScrollbar from "vue-custom-scrollbar";

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import subjectIcon from '@/assets/Icons/FaceliftIcons/fach_kontur.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import searchIcon from '@/assets/Icons/suche-26.svg';
import * as backend from "@/api/backend";


export default {
    name: "FachInfoMaintainer",
    components: { FachErstellen, CreateTeacher, 
    vueCustomScrollbar,
     },
    props: {
        appointment: {required: false, default: null},
        subjects: {required: true, default: null},
        teachers: {required: true},
        currentGroup: {type: Object, required: true},
        day: {type: Date, required: false},
        requestAppointments: {type: Function, required: true},

        embeddedStyle: {type: Boolean, default: false},
        timeslot: {required: true, default: null},
        hideAddSchoolSubjectOptionInSelector: {required: false, type: Boolean, default: false},
        addNewTeacher: {type: Function},
        selectedRoom: {type: Object, required: false, default: () => {}},
        roomMode: {default: false, type: Boolean, required: false},
    },

    data: () => ({
        cancelIcon,
        subjectIcon,
        deleteIcon,
        searchIcon,

        edit: false,

        deleteDialog: false,
        deleteRecurringAppointment: 'onlyThis',
        weekly: false,
        fullYear: false,
        halfYear: false,
        weeklyTill: "",
        weeklyFrom: "",
        fields: {
            weeklyField: false,
        },
        dtPickerMenu: false,
        selectedSubject: null,
        selectedTeachers: [],
        selectedSubstitution: null,
        selectedRooms: [],
        faecherDialog: false,
        createTeacherDialog: false,
        changeInRuleDialog: false,
        updateRecurringAppointment: 'allOfThem',
        rule: null,
        availableTeachers: [], // Teachers who are eligible for being a substitute
        substitutionExists: false, // This determines if the substitution is going to be posted or patched

        currentSubjectTeachers: [],
        currentSubjectRooms: [],

        addRoomName: '',
        selectedGroup: null,
        searchGroupNames: '',
        settings: {
            suppressScrollY: false,
            suppressScrollX: false,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
    }),
    computed: {
        ...mapGetters('appointments', ['appointments']),
        ...mapGetters("subjects",['subjectsById']),
        ...mapGetters("teachers", ['teachersById']),
        ...mapGetters("groups", ['groupsById']),
        ...mapGetters('groups', {'vuexGroups': 'groups' }),
        ...mapGetters("auth",["accountRole"]),
        ...mapGetters("accounts", ['me']),
        ...mapGetters("groups", ['groupsByLeader']),
        ...mapState('util', ['windowWidth']),

        // This will filter out teachers who are already selected for this subject
        filteredAvailableTeachers() {
            return this.availableTeachers.filter((teacher) => {
                return !this.selectedTeachers.some((selectedTeacher) => {
                    return teacher._id === selectedTeacher._id;
                });
            });
        },
        filteredGroups() {
            if (this.accountRole === 'teacher') {
                return this.groupsByLeader(this.me._id);
            }
            return this.groups.filter((group) => group.name.toLowerCase().includes(this.searchGroupNames.toLowerCase()));
        },
        groups() {
            return this.vuexGroups
                .filter(el => el.name !== 'Alle')
                .sort((a, b) => this.alphabetically(a.name, b.name));
        },
    },
    watch: {
        selectedSubject() {
            if (this.selectedSubject && this.selectedSubject.teachers) {
                this.currentSubjectTeachers = this.selectedSubject.teachers;
            }
        },
        appointment() {
            if(this.appointment) {
                this.edit = true;
            }
        },
        halfYear(){
            if(this.halfYear){
                this.weekly = false;
                this.fullYear = false;
            }
        },
        fullYear(){
            if(this.fullYear){
                this.weekly = false;
                this.halfYear = false;
            }
        },
        async selectedTeachers(newVal,oldVal){
            if(newVal.length > oldVal.length){
                const teacher =  newVal[newVal.length-1];
                const midDay = new Date(new Date(this.day).getUTCFullYear(), new Date(this.day).getUTCMonth(),
                    new Date(this.day).getUTCDate() + 1, 12)
                const res = await backend.getTeacherAppointmentAtTimeslotAndDate(teacher._id, this.timeslot._id,midDay);
                if(res.status === 200){
                    const occupiedAppointments = await res.json();    
                    const room = occupiedAppointments[0].rooms.length ? await this.getRoomById({roomId:occupiedAppointments[0].rooms[0]}) : null;
                    let message = `Achtung. ${teacher.name} ${teacher.lastName} unterrichtet zu diesem Zeitpunkt
                        schon ${this.subjectsById[occupiedAppointments[0].schoolSubject].name} bei Gruppe ${this.groupsById[occupiedAppointments[0].group].name}`
                    const concatedMessage = room ? `${message} in Raum ${room.roomName}.` : message;
                    if(occupiedAppointments.length > 0) {
                        this.showSnackbar({ message: concatedMessage, color: "warning" })
                    }
                }
            }
        }
    },
    async mounted() {
        if (this.appointment) {
            this.edit = true;
            await this.setInfoInDialogToAppointmentProp();
        }
        if (this.selectedRoom && !this.selectedSubject) {
            this.selectedGroup = this.currentGroup;
            this.selectedRooms.push(this.selectedRoom._id);
        }
        await this.loadRooms();
        await this.prepareSubstitutions();
    },
    methods: {
        ...mapActions('appointments', ['createAppointment', 'deleteAppointment', 'getAppointmentRuleById', 'patchSchoolSubjectAppointment','updateDisplayTeacherInfo']),
        ...mapActions("physicalRooms", ["getRooms", "getRoomsForTimeslot", "createRoom", "editRoom", "deleteRoom", "setRoomListStatus", "getRoomById"]),
        ...mapActions("subjects", ["getSchoolSubject"]),
        ...mapActions('substitution', ['postSubstitution', 'patchSubstitution']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("appointments", ["addOrUpdateAppointment", 'addOrUpdateAppointments', 'patchAppointment', "removeAppointment", 'setAppointmentBuffer', 'emptyBufferIntoState']),

        showWarning(){
            console.log(this.selectedTeachers)
            if(!this.substitutionExists && !this.selectedSubstitution){
                this.showSnackbar({message:`Achtung, ${this.selectedTeachers[0].name} ${this.selectedTeachers[0].lastName} wird dadurch automatisch für diese Stunde abwesend gemeldet.`, color:'warning'})
            }
        },
        alphabetically(a, b) {
            let x = (a || '').toLowerCase();
            let y = (b || '').toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },
        
        addSubject(subject) {
            this.selectedSubject = subject;
            //this.$emit('addNewSubject', subject);
        },

        async setInfoInDialogToAppointmentProp() {
            // This needs to be done to get the teachers in the appointment schoolsubject from ids to objects
            this.selectedSubject = JSON.parse(JSON.stringify(this.appointment.schoolSubject));
            this.selectedSubject.teachers = this.subjects.find((el) => el._id === this.selectedSubject._id).teachers;

            // This maps the appointment teachers from ids to objects. You could have done this using schoolSubjects
            // teachers but that just didn't feel right here
            this.appointment.teachers.forEach((teacherId) => {
                this.selectedTeachers.push(this.teachers.find((teacherObject) => teacherObject._id === teacherId));
            });

            this.selectedTeachers = this.appointment.teachers;
            this.appointment.rooms.map((room) => {
                this.selectedRooms.push(room);
            });

            if (this.appointment.rule) {
                this.weekly = true;
                this.rule = await this.getAppointmentRuleById(this.appointment.rule);
                this.weeklyFrom = this.rule.startDate.split('T')[0];
                this.weeklyTill = this.rule.endDate.split('T')[0];
            }

            if (this.selectedRoom) {
                let group = this.appointment.group;
                if (typeof group === 'string') {
                    group = this.groups.find((el) => el._id === group);
                }
                this.selectedGroup = group;
            }
        },

        //only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        validateInput() {
            let result = true;

            this.fields.weeklyField = false;

            if (this.weeklyTill.replace(/\s/g, "") === "" || this.isWeeklyInPast()) {
                this.fields.weeklyField = true;
                result = false;
            }
            return result;
        },

        cancel() {
            this.selectedSubject = null;
            this.selectedGroup = null;
            this.selectedTeachers = [];
            this.selectedRooms = [];
            this.selectedSubstitution = null;
            this.weekly = false;
            this.halfYear = false;
            this.fullYear = false;
            this.weeklyTill = "";
            // Only when editing
            if(this.appointment) {
                this.setInfoInDialogToAppointmentProp();
            }
            this.faecherDialog = false;
            this.$emit('close');
        },

        isWeeklyInPast() {
            const tillDate = new Date(this.weeklyTill);
            const now = new Date();
            return tillDate.toString() !== "Invalid Date" && tillDate < now;
        },

        async saveAppointment() {
            let group = this.currentGroup._id;
            if (this.selectedRoom && (!this.selectedGroup || this.selectedGroup.length === 0)) {
                this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                return;
            } else if(this.roomMode) {
                group = this.accountRole === 'maintainer' ? this.selectedGroup._id : this.currentGroup._id ;
            }
            if (!this.selectedSubject || this.selectedTeachers.length === 0) {
                this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                return;
            } else if (this.weekly) {
                if (!this.validateInput()) {
                    this.showSnackbar({message: 'Das ausgewählte Datum muss in der Zukunft liegen!', color: 'error'});
                    return;
                }
            }
            // 'Trick' um halbjährig bzw ganzjährig Termine einzutragen,
            //  werden sie einfach wöchentlich bis zur woche des halbjahres eingetragen.
            //  Bei Fragen wieso @Julian
            if(this.halfYear){
                this.weekly = true;
                let tempWeeklyTill = ""
                if(new Date(this.day).getMonth() < 1){
                    tempWeeklyTill =  new Date(this.day).toISOString().substr(0, 4) + '-01-31';
                }else if(new Date(this.day).getMonth() > 6 ){
                    const nextYearString = parseInt(new Date(this.day).toISOString().substr(0, 4)) + 1;
                    tempWeeklyTill = nextYearString + '-01-31';
                }else{
                     tempWeeklyTill =  new Date(this.day).toISOString().substr(0, 4) + '-07-31';
                }
                if( new Date(tempWeeklyTill).getDay() === 0){
                        //if sunday set date 1 day later
                        this.weeklyTill = tempWeeklyTill.substr(0,4) + tempWeeklyTill.substr(5,7) === '01' ? '-02-01' : '-08-01';
                    }else if(new Date(tempWeeklyTill).getDay() === 6 ) {
                        //if saturday set date 2 days later
                        this.weeklyTill= tempWeeklyTill.substr(0,4) + tempWeeklyTill.substr(5,7) === '01' ? '-02-02' : '-08-02';
                    }else {
                        this.weeklyTill = tempWeeklyTill;
                    }
            }
            if(this.fullYear){
                this.weekly = true;
                let tempWeeklyTill = ""
                if(new Date(this.day).getMonth() > 6 ){
                    const nextYearString = parseInt(new Date(this.day).toISOString().substr(0, 4)) + 1;
                    tempWeeklyTill = nextYearString + '-07-31';
                }else{
                     tempWeeklyTill =  new Date(this.day).toISOString().substr(0, 4) + '-07-31';
                }
                if( new Date(tempWeeklyTill).getDay() === 0){
                        //if sunday set date 1 day later
                        this.weeklyTill = tempWeeklyTill.substr(0,4) + tempWeeklyTill.substr(5,7) === '01' ? '-02-01' : '-08-01';
                    }else if(new Date(tempWeeklyTill).getDay() === 6 ) {
                        //if saturday set date 2 days later
                        this.weeklyTill= tempWeeklyTill.substr(0,4) + tempWeeklyTill.substr(5,7) === '01' ? '-02-02' : '-08-02';
                    }else {
                        this.weeklyTill = tempWeeklyTill;
                    }
            }
            let data = {
                schoolSubject: this.selectedSubject._id,
                teachers: this.selectedTeachers,
                rooms: this.selectedRooms,
                groupId: group,
                day: this.day,
                timeslot: this.timeslot._id,
                ...(this.weekly
                    ? {
                        repeat: {
                            interval: "weekly",
                            from: new Date(this.day),
                            until: new Date(this.weeklyTill),
                        },
                    }
                    : {}),
            };
            let response = await this.createAppointment(data);
            if (Number.isInteger(response)) {
                if (response === 409) {
                    this.showSnackbar({message: 'Der Termin konnte nicht erstellt werden.', color: 'error'});
                } else {
                    this.showSnackbar({message: 'Ein unerwarteter Fehler ist aufgetretten!', color: 'error'});
                }
                this.snackbar = true;
            } else {
                this.showSnackbar({message: 'Gespeichert!'});
                this.requestAppointments(null, true);
                this.$emit('close');
            }
        },

        async updateAppointment() {
            let group = this.currentGroup._id;
            if (this.selectedRoom && (!this.selectedGroup || this.selectedGroup.length === 0)) {
                this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                return;
            } else if(this.roomMode) {
                 group = this.accountRole === 'maintainer' ? this.selectedGroup._id : this.currentGroup._id ;
            }
            if (!this.selectedSubject || this.selectedTeachers.length === 0) {
                this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                return;
            } else if (this.weekly) {
                const weeklyTillDate = new Date(this.weeklyTill);
                const appointmentDate = new Date(this.appointment.day);
                if (new Date(weeklyTillDate.getFullYear(), weeklyTillDate.getMonth(), weeklyTillDate.getDate()) < new Date(appointmentDate.getFullYear(), appointmentDate.getMonth(), appointmentDate.getDate()) ) {
                    this.showSnackbar({message: 'Bitte wählen Sie ein Datum das nach dem Beginn der Schulstunde liegt!', color: 'error'});
                    return;
                }
            }
            // Post or patch substitutions
            if (this.selectedSubstitution) {
                if (this.substitutionExists) {
                    this.selectedTeachers.map(async (teacher) => {
                        const foundSubstitution = this.appointment.substitutions.find((substitution) => {
                            return substitution.sickTeacher._id === teacher._id;
                        })
                        console.log('exists,',foundSubstitution);
                        await this.patchSubstitution({substitutionId: foundSubstitution._id, params:{
                                substitutionTeacher: this.selectedSubstitution._id,
                                seenByMaintainer: true,
                            }});
                    })
                } else {
                    this.selectedTeachers.map(async (teacher) => {
                        await this.postSubstitution({
                            appointment: this.appointment._id,
                            sickTeacher: teacher._id,
                            substitutionTeacher: this.selectedSubstitution._id,
                        });
                    })
                    this.substitutionExists = true;
                }
            }

            let data = null;
            if (this.weekly) {
                if(!this.changeInRuleDialog) {
                    this.changeInRuleDialog = true;
                    return;
                } else {
                    const ruleFormatted = this.rule || {};
                    ruleFormatted.repeat = ruleFormatted.repeat || 'weekly';
                    ruleFormatted.startDate = new Date(this.day);
                    ruleFormatted.endDate = new Date(this.weeklyTill);
                    data = {
                        _id: this.appointment._id,
                        schoolSubject: this.selectedSubject._id,
                        teachers: this.selectedTeachers,
                        rooms: this.selectedRooms,
                        groupId: this.group,
                        day: this.day,
                        timeslot: this.timeslot._id,
                        note: this.appointment.note,
                        rule: ruleFormatted,
                        changeInRule: this.updateRecurringAppointment === 'allOfThem',
                    };
                }
            } else {
                data = {
                    _id: this.appointment._id,
                    schoolSubject: this.selectedSubject._id,
                    teachers: this.selectedSubstitution  ? this.selectedTeachers.concat([this.selectedSubstitution]) : this.selectedTeachers,
                    rooms: this.selectedRooms,
                    groupId: group,
                    day: this.day,
                    timeslot: this.timeslot._id,
                    note: this.appointment.note,
                };
            }

            const res = await this.patchSchoolSubjectAppointment({ data });
            const body = await res.json();

            if (res.status === 409) {
                const roomId = body[0].rooms[0];
                const room = await this.getRoomById({ roomId });
                this.showSnackbar({
                    message: 'Der Raum: ' + room.roomName + ' ist zu diesem Zeitslot schon belegt',
                    color: 'error'
                });
            } else if (res.status === 200){
                this.showSnackbar({message: 'Gespeichert!'});
                //populates array necessary to display info of teachers in top right corner of fach
                const updatedAppointment = await this.updateDisplayTeacherInfo(body);
                //mutation to set correct appointment in store
                this.patchAppointment(updatedAppointment);
                await this.loadRooms();
                this.$emit('close');
            } else {
                this.showSnackbar({
                    message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                    color: 'error'
                });
            }
            await this.requestAppointments([this.appointment._id]);
            this.changeInRuleDialog = false;
        },

        closeDialog(){
            this.faecherDialog = false;
        },

        /**
         * Method to delete one appointment or a repeating appointment
         * pretty nifty I know :)
         * @returns {Promise<void>} nothing of importance
         */
        async deleteAction() {
            let snackbar409Text = 'Der Termin konnte nicht gelöscht werden.'
            let response;

            const requestParams = {type: 'onlyThis', appointment: this.appointment};

            if (this.appointment.rule
                && (this.deleteRecurringAppointment === 'thisAndFollowing'
                    || this.deleteRecurringAppointment === 'allOfThem')) {
                requestParams.type = this.deleteRecurringAppointment;
                snackbar409Text = 'Die Termine konnten nicht gelöscht werden.'
            }

            response = await this.deleteAppointment(requestParams);

            if (Number.isInteger(response)) {
                if (response === 409) {
                    this.showSnackbar({message: snackbar409Text, color: 'error'});
                } else {
                    this.showSnackbar({message: 'Ein unerwarteter Fehler ist aufgetreten!', color: 'error'});
                }
            } else {
                this.showSnackbar({message: 'Gespeichert!'});
                this.selectedSubject = null;
                this.selectedGroup = null;
                this.requestAppointments(null, true);
                this.$emit('close');
            }
            this.deleteDialog = false;
        },

        async createTeacherCallback(createdTeacher) {
            const updatedSubj = await this.getSchoolSubject(this.selectedSubject._id);
            this.selectedSubject = updatedSubj;
            this.$emit('createTeacher');
            this.createTeacherDialog = false;
        },

        async addRoom() {
            if (this.addRoomName === '') {
                return;
            } else {
                let data = {
                    roomName: this.addRoomName,
                };

                let response = await this.createRoom(data);

                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen RaumNamen ein!', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                } else {
                    const newRoomData = {
                        _id: response._id,
                        roomName: response.roomName,
                        available: 'frei',
                    }
                    this.currentSubjectRooms.push(newRoomData);
                    this.setRoomListStatus(true);
                    this.addRoomName = '';
                    this.selectedRooms.push(newRoomData);
                    this.showSnackbar({ message: 'Gespeichert!'});
                }
            }
        },

        async loadRooms() {
            this.currentSubjectRooms = await this.getRoomsForTimeslot({ timeslotId: this.timeslot._id, day: this.day });
        },

        getSubs(teacherId){
            return this.subjects.filter((subject) => {
                return subject.teachers.some((teacher) => teacher._id === teacherId)
            })
        },

        async prepareSubstitutions() {
            let allTeachers = this.teachers;
            allTeachers = await Promise.all(allTeachers.map(async(teacher) => ({
                ...teacher,
                schoolSubjects: this.getSubs(teacher._id),
            })));
            allTeachers.forEach((teacher) => {
                if (!this.appointment || !this.appointment.substitutions
                    || !this.appointment.substitutions.some(el => el.sickTeacher._id === teacher._id)) {
                    this.availableTeachers.push(teacher);
                }
            });
            // Sort by least working hours
            this.availableTeachers = this.availableTeachers
                .sort((a,b)=> (a.workHours/a.maxHours) - (b.workHours/b.maxHours));

            // Check for substitution teachers
            if (this.edit && this.appointment.displayTeacherInfo.length > 0) {
                const foundSub = this.appointment.displayTeacherInfo.find((teacher) => teacher.role === 'substitution');
                if (foundSub) { // If substitution teacher exists
                    this.selectedSubstitution = foundSub.teacher;
                }
                if (this.appointment.substitutions.length > 0) {
                    this.substitutionExists = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}

.textGrau {
    color: var(--v-dunkelgrau-base);
}

.input[disabled] {
    color: grey;
    background-color: grey;
}

.cancelIcon {
    margin-right: 12px;
}

.greyButton {
    background-color: var(--v-hellgrau-base) !important;
    border-radius: 8px;
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

.scroll-area-maintainer {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    max-height: calc(80vh - 170px);
}

</style>
