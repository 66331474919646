<template>
    <div style="justify-content: flex-end; align-items: flex-end; position: absolute; pointer-events: none;">
        <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
                <v-btn
                    ref="fileSystemElMenuButton"
                    x-small
                    icon
                    elevation="0"
                    class="ml-3 pa-0 fileWidgetButton"
                    :class="listView ? 'marginLess' : 'marginMore'"
                    :style="item.learningPath ? item.learningPath.pathVFSOrigin == item._id ? 'background-color: transparent; border-radius: 6px' :
                        item.learningPath.pathVFSOrigin == item.parentFolder._id ? 'background-color: transparent; border-radius: 6px' : '' : ''"
                    v-on="{ ...tooltip }"
                    @click="toggleMenu"
                >
                    <img
                        :src="menuIcon"
                        style="height: 25px; opacity: 1;"
                        :alt="openMenu ? 'Menü schließen' : 'Menü öffnen'"
                    >
                </v-btn>
            </template>
            <span>Weitere Aktionen</span>
        </v-tooltip>
        <div
            v-if="openMenu"
            v-click-outside="clickOutsideMenu"
            class="fileTileMenuContainer"
            :class="listView ? 'listView' : ''"
        >
            <simplebar
                class="scroll-area"
                data-simplebar="init"
            >
                <!-- Menu Content -->
                <!-- Menu FILES -->
                <div
                    style="display: flex; flex-direction: column; overflow-x: hidden; overflow-y: auto"
                >
                    <v-btn
                        v-if="allowedMimeTypesForAudioPlayer.includes(item.mimetype)"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="() => { emitButtonPress('playAudio'); toggleMenu(); }"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-play-circle-outline
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Anhören
                        </p>
                    </v-btn>
                    <v-btn
                        v-else-if="allowedExtensionsForVideoPlayer.includes(item.mimetype ? item.mimetype.split('/').pop().toLowerCase() : false)"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="() => { emitButtonPress('playVideo'); toggleMenu(); }"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-play-circle-outline
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Abspielen
                        </p>
                    </v-btn>
                    <v-btn
                        v-else-if="type === 'file' || type === 'draft'"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="() => { emitButtonPress('loadFullscreenPreview'); toggleMenu(); }"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-eye
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Vorschau
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="type === 'file' && folderType === 'privateFolder'"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="viewFileFunction"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-brush
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Bearbeiten
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="type === 'file' && !vfs.rulesetTask && type !== 'appointment' && (!isSharedFile || accountRole === 'teacher')"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('multiselectElement')"
                    >
                        <img
                            class="pl-3"
                            :src="zeigerIcon"
                            style="height: 25px; opacity: 1"
                            alt="Zeiger"
                        >
                        <p
                            class="px-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Auswählen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="(type === 'appointment' && homeworkCategory === 'toDo' && !vfs.isVfsTask || (appointmentCategory === 'teacher' && accountRole === 'pupil')) && isDesktopSize"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('uploadFile')"
                    >
                        <img
                            :src="dateiIcon"
                            alt=""
                            class="fileTileMenuElementIcon"
                        >
                        <p
                            class="pl-2"
                            style="text-transform: none !important;"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Datei vom Gerät hochladen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="type === 'file' || vfs.isVfsTask"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('downloadItem')"
                    >
                        <img
                            class="pl-3"
                            :src="herunterladenIcon"
                            style="height: 25px; opacity: 1"
                            alt="Herunterladen"
                        >
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Herunterladen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="(accountId === item.owner || !isSharedFile || accountRole === 'teacher') && !vfs.rulesetTask && type !== 'appointment' && !(accountRole === 'pupil' && item.learningPath)"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('editSharing')"
                    >
                        <img
                            v-if="(item.learningPath && item.learningPath.pathVFSOrigin === item._id) || (item.learningPath && item.learningPath.pathVFSOrigin !== item._id && item.learningPath.pathVFSOrigin === item.parentFolder._id)"
                            :src="stiftIcon"
                            alt="Lernpfad bearbeiten"
                            class="icon20 ml-4"
                        >
                        <img
                            v-else
                            :src="groupIcon"
                            alt="Teilen"
                            class="iconToBlack icon20 ml-4"
                        >
                        <p
                            class="px-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            {{ item.learningPath ? item.learningPath.pathVFSOrigin == item._id ? 'Bearbeiten' : item.learningPath.pathVFSOrigin == item.parentFolder._id ? 'Bearbeiten' : 'Teilen' : 'Teilen' }}
                        </p>
                    </v-btn>
                    <!-- Duplicate of Teilen Button, specific to renaming -->
                    <v-btn
                        v-if="(accountId === item.owner || !isSharedFile || accountRole === 'teacher') && !vfs.rulesetTask && type !== 'appointment' && !(accountRole === 'pupil' && item.learningPath)"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('editSharing')"
                    >
                        <img
                            :src="penIcon"
                            alt=""
                            class="fileTileMenuElementIcon"
                        >
                        <p
                            class="px-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Umbenennen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="isDeleteDisplayed && type !== 'appointment'"
                        elevation="0"
                        color="error"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('deleteItem')"
                    >
                        <img
                            :src="papierkorbIcon"
                            alt=""
                            class="fileTileMenuElementIcon"
                            style="filter: brightness(1000%)"
                        >
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                            style="color: white"
                        >
                            Löschen
                        </p>
                    </v-btn>
                    <!-- EDIT Item is the legacy V1 way of changing name and sharing properties. Should not be used in 941-beta and upwards -->
                    <v-btn
                        v-if="folderType !== 'documentRegistry' && folderType !== 'groupFolder' && !vfs.rulesetTask && type !== 'appointment'"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('editItem')"
                    >
                        <img
                            :src="penIcon"
                            alt=""
                            class="fileTileMenuElementIcon"
                        >
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Umbenennen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="isMoveButtonDisplayed && !isSharedFile"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('moveItemTo')"
                    >
                        <img
                            :src="verschiebenIcon"
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Verschieben
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="isSaveToDisplayed"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('saveItemTo')"
                    >
                        <img
                            class="pl-3"
                            :src="copyIcon"
                            style="height: 25px; opacity: 1"
                            alt="Kopieren"
                        >
                        <p
                            class="px-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Kopieren
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="debugMode && type === 'file' && accountRole === 'teacher' && folderType === 'teacherFolder'"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('publishItem')"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-file-move-outline
                        </v-icon>
                        <p
                            class="px-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Veröffentlichen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'groupFolder' && type === 'file' && isTask && (accountRole === 'teacher' || (accountRole === 'pupil' && alreadySubmitted))"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('openSubmissionsToTask')"
                    >
                        <img
                            :src="ordnerOffenIcon"
                            alt="Abgaben öffnen"
                            class="icon20 ml-4"
                        >
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Abgaben öffnen
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="folderType === 'groupFolder' && accountRole === 'teacher' && type === 'file' && !vfs.rulesetTask && !isSharedFile"
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="() => { emitButtonPress('setItemAsTask'); toggleMenu(); }"
                    >
                        <img
                            v-if="isTask && !activeTask"
                            class="pl-3"
                            :src="dateiOffenIcon"
                            style="height: 25px; opacity: 1"
                            alt="Aufgabe öffnen"
                        >
                        <img
                            v-else-if="isTask && activeTask"
                            class="pl-3"
                            :src="keinDocument"
                            style="height: 25px; opacity: 1"
                            alt="Aufgabe schließen"
                        >
                        <v-icon
                            v-else-if="isTask"
                            class="fileTileMenuElementIcon"
                            :alt="isTask ? activeTask ? 'Geschlossenes Schloss' : 'Offenes Schloss' : 'Kalender'"
                        >
                            {{ isTask ? activeTask ? '' : '' : '' }}
                        </v-icon>
                        <img
                            v-else
                            class="pl-3"
                            :src="dateiBearbeitenIcon"
                            style="height: 25px; opacity: 1"
                            alt="Als Aufgabe markieren"
                        >
                        <p
                            class="pl-2 text-none"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            {{ isTask ? activeTask ? 'Aufgabe schließen' : 'Aufgabe öffnen' : 'Als Aufgabe markieren' }}
                        </p>
                    </v-btn>
                    <!-- Menu APPOINTMENTS -->
                    <v-btn
                        v-if="type === 'appointment' && homeworkCategory !== 'toDo'"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="() => { emitButtonPress('loadEditorPreview'); toggleMenu(); }"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-eye
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Vorschau
                        </p>
                    </v-btn>
                    <v-tooltip
                        v-if="!item.pipReference"
                        right
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-if="type === 'appointment' && !vfs.isVfsTask"
                                elevation="0"
                                color="white"
                                class="pa-0 text-capitalize"
                                :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                                v-on="on"
                                @click="clickDownloadTeacherUpload(item)"
                            >
                                <img
                                    class="pl-3"
                                    :src="herunterladenIcon"
                                    style="height: 25px; opacity: 1"
                                    alt="Herunterladen"
                                >
                                <p
                                    class="pl-2"
                                    :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                                >
                                    Herunterladen
                                </p>
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <img
                                class="pl-3"
                                :src="herunterladenIcon"
                                style="height: 25px; opacity: 1"
                                alt="Herunterladen"
                            >
                        </div>
                    </v-tooltip>
                    <v-btn
                        v-if="type === 'appointment' && homeworkCategory === 'toDo' && !vfs.isVfsTask || (appointmentCategory === 'teacher' && accountRole === 'pupil')"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        :disabled="accountRole === 'teacher'"
                        @click="() => { moveDialog = true }"
                    >
                        <img
                            :src="tick1Icon"
                            class="fileTileMenuElementIcon editImg"
                            alt=""
                        >
                        <p
                            class="pl-2"
                            style="text-transform: none !important;"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            In erledigt verschieben
                        </p>
                    </v-btn>
                    <v-btn
                        v-if="(homeworkCategory === 'done' || homeworkCategory === 'corrected') && accountRole === 'pupil'"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="emitButtonPress('toggleArchiveElement'); toggleMenu()"
                    >
                        <v-icon
                            class="fileTileMenuElementIcon"
                            alt=""
                        >
                            mdi-archive-outline
                        </v-icon>
                        <p
                            class="pl-2"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            {{ item.archived ? 'Wiederherstellen' : 'Archivieren' }}
                        </p>
                    </v-btn>
                    <!-- LAST ENTRY: CANCEL MENU -->
                    <v-btn
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize"
                        :class="vfsGridWidthPct === 33 ? 'fileTileMenuElement33': 'fileTileMenuElement'"
                        @click="toggleMenu()"
                    >
                        <img
                            class="pl-3"
                            :src="abbrechenIcon"
                            style="height: 25px; opacity: 1"
                            alt="Abbrechen"
                        >
                        <p
                            class="pl-1"
                            :style="vfsGridWidthPct < 50 ? 'font-size: small' : ''"
                        >
                            Abbrechen
                        </p>
                    </v-btn>
                </div>
            </simplebar>
        </div>

        <!-- Dialogs -->
        <v-dialog
            v-if="moveDialog"
            v-model="moveDialog"
            max-width="337px"
        >
            <v-card 
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-fileArchiveOrange-base);"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Verschieben bestätigen
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon30 iconToWhite"
                            alt="Abbrechen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text class="mt-2">
                    Willst du die Datei wirklich <strong>ohne</strong> eine Lösung/Abgabe in 'Erledigt' verschieben?
                </v-card-text>

                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="gruen"
                        dark
                        @click="moveUploadToDone(item)"
                    >
                        <img
                            :src="doneIcon"
                            class="icon20 iconToWhite mr-2"
                            alt=""
                        >
                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import {mapGetters, mapState} from "vuex";
import * as backend from "@/api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import penIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import menuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import abbrechenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import zeigerIcon from "@/assets/Icons/FaceliftIcons/auswaehlen_zeiger_hand.svg";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import copyIcon from '@/assets/Icons/FaceliftIcons/kopieren_datei_.svg';
import dateiBearbeitenIcon from '@/assets/Icons/FaceliftIcons/datei_bearbeiten.svg';
import dateiOffenIcon from '@/assets/Icons/FaceliftIcons/datei_offen.svg';
import keinDocument from '@/assets/Icons/FaceliftIcons/datei_nicht_abgegeben.svg';
import hausaufgabenMetacom from "@/assets/METACOM/Hausis/hausaufgaben.png";
import toDoMetacom from "@/assets/METACOM/Hausis/machen.png";
import herunterladenMetacom from "@/assets/METACOM/Hausis/speichern.png";
import hochladenMetacom from "@/assets/METACOM/Hausis/fotoapparat.png";
import tick1Icon from "@/assets/Icons/tick-1.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import doneIcon from '@/assets/Icons/fertig-74.svg';
import stiftIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import exportTeilenIcon from '@/assets/Icons/FaceliftIcons/export_teilen.svg';
import ordnerOffenIcon from '@/assets/Icons/FaceliftIcons/ordner_offen.svg';
import dateiIcon from "@/assets/Icons/FaceliftIcons/ordner_lokal.svg";
import verschiebenIcon from "@/assets/Icons/FaceliftIcons/Datei_verschieben_icon.svg";
import groupIcon from '@/assets/Icons/group.svg';
import { allowedMimeTypesForAudioPlayer,
    allowedExtensionsForVideoPlayer } from "../../constants/allowedMimeTypes";

export default {
    name: 'FileTileMenuV2',
    components: {
        // vueCustomScrollbar,
        simplebar
    },
    props: {
        // The json item the menu is adjusting to
        item: { required: true, type: Object },
        // folderType is deprecated and should be removed
        folderType: { required: false, type: String, default: 'groupFolder' },
        // valid: file, folder, appointment
        type: { required: true, type: String },
        viewFileFunction: { required: false, type: Function, default: () => {} },
        vfs: { required: false, type: Object },
        debugMode: { required: false, type: Boolean, default: false },
        isTask: { required: false, type: Boolean, default: false },
        activeTask: { required: false, type: Boolean, default: false },
        // homeworkCategory: toDo, done, corrected
        homeworkCategory: { required: false, type: String, default: '' },
        // appointment-in-vfs integration : teacher, pupil, corrected
        appointmentCategory: { required: false, type: String, default: '' },
        // if this the gridWidthPercentage is 100%, meaning we are rendering the FSTE in a list
        listView: { required: false, type: Boolean, default: false },
        // limit the menu options for UNOWNED shared files
        isSharedFile: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        openMenu: false,
        moveDialog: false,

        allowedMimeTypesForAudioPlayer,
        allowedExtensionsForVideoPlayer,

        papierkorbIcon,
        penIcon,
        menuIcon,
        tick1Icon,
        cancelIcon,
        doneIcon,
        abbrechenIcon,
        zeigerIcon,
        herunterladenIcon,
        copyIcon,
        dateiBearbeitenIcon,
        dateiOffenIcon,
        keinDocument,
        stiftIcon,
        exportTeilenIcon,
        ordnerOffenIcon,
        dateiIcon,
        verschiebenIcon,
        groupIcon,

        // hausaufgabenMetacom,
        // toDoMetacom,
        // herunterladenMetacom,
        // hochladenMetacom,
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole', 'accountId' ]),
        ...mapState('auth', ['token']),
        ...mapGetters('vfs', ['vfsGridWidthPct']),
        ...mapState('util', ['windowWidth', 'windowHeight']),
        isDeleteDisplayed() {
            return !this.vfs.rulesetTask && !this.isSharedFile && !(this.item.learningPath && this.accountRole === 'pupil')
                && (this.accountRole === 'teacher'
                    || this.accountRole === 'pupil' && !(this.vfs.sharing_enabled && this.vfs.sharing_discoverable)
                    ) && this.accountId === this.item.owner;
        },
        isSaveToDisplayed() {
            return (this.type === 'file' && this.accountRole === 'teacher');
        },
        isMoveButtonDisplayed() {
            return this.folderType !== 'documentRegistry'
                && this.type !== 'folder' // disabled folder moving until fully stable in backed. does not update the sharing values as expected. also the frontend menu is bugged.
                && !(this.folderType === 'groupFolder'
                    && (this.accountRole === 'pupil' || (this.type === 'folder' && this.accountId !== this.item.owner)))
                && !this.vfs.rulesetTask && this.type !== 'appointment'
                && this.type !== 'draft';
        },
        isDesktopSize() {
            // height on my 1080p screen is about 762px - Jan
            return this.windowWidth > 900 && this.windowHeight > 700
        }
    },
    methods: {
        toggleMenu() {
            this.openMenu = !this.openMenu;
            if (this.openMenu) {
                this.$emit('disableParentScroll', false);
            }
            this.$emit('openMenu', this.openMenu);
        },
        clickOutsideMenu(event) {
            // Check if event.path contains fileSystemElMenuButton
            if (event.path && !event.path.includes(this.$refs.fileSystemElMenuButton.$el)) {
                this.openMenu = false;
                this.$emit('openMenu', false);
            }

            if (!event.path) {
                const path = [];
                let currentElem = this.target;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                if (!path.includes(this.$refs.fileSystemElMenuButton.$el)) {
                    this.openMenu = false;
                    this.$emit('openMenu', false);
                }
            }
        },

        // emits correct events to parent element
        emitButtonPress(identifier, payload = null) {
            this.$emit(identifier, payload);
        },

        // gonna refactor this later, but I'm offloading the appointment functions
        // here for now. sorry @jan
        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            let res;
            if (this.appointmentCategory !== 'pupil') {
                res = await backend.getTeacherUpload(file.appointmentId, file.file);
            } else {
                res = await backend.getPupilUpload(file.appointmentId, file.file);
            }
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },
        async moveUploadToDone() {
            await backend.postTeacherUploadMove(this.item.appointmentId, this.item._id);
            this.$emit('getAllTeacherFiles',true);
            this.moveDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.scroll-area {
    position: relative;
    min-height: 125px;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch !important;
    align-content: flex-start;
    pointer-events: all;
    background-color: var(--v-dunkelgrau-base);
    border-radius: 6px;
}
.fileWidgetButton {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    background-color: transparent;
    position: absolute;
    top: 5%;
    right: 5%;
    pointer-events: all;
}
.fileTileMenuContainer {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 5px;
    pointer-events: all;
}

.listView {
    position: absolute;
    z-index: 999;
    height: 200px;
    left: -2%;
    top: 30%;
}

.editImg {
    filter: brightness(0%);
}

.iconToWhite {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

@media only screen and (max-width: 1200px) {
    .fileTileMenuContainer {
        padding: 2px;
    }
    .fileTileMenuElement {
        p {
            font-size: x-small;
        }
    }

    .fileTileMenuContainer {
        padding: 2px;
    }
    .fileTileMenuElement33 {
        p {
            font-size: x-small;
        }
    }
}

.fileTileMenuElement33 {
    width: 100%;
    padding: 5px;
    display: inline-flex;
    justify-content: flex-start;
    border-radius: 5px;
    margin: 1px;
    color: black;
}

.fileTileMenuElement {
    padding: 5px;
    display: inline-flex;
    justify-content: flex-start;
    border-radius: 5px;
    margin: 1px;
    color: black;
}

.fileTileMenuElementIcon {
    width: 20px;
    color: var(--v-dunkelgrau-base) !important;
    margin-left: 15px;
}

.iconToGrey {
    filter: invert(43%) sepia(0%) saturate(1788%) hue-rotate(156deg) brightness(98%) contrast(76%);
}

.iconToBlack {
    filter: invert(55%);
}

.marginLess {
    margin-right: -20px;
}

.marginMore {
    filter: brightness(1000%);
    margin-right: -10px;
}
</style>
