import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const defaultState = {
    VFSFolderUploads: null,
    uploadThumbnails: []
};

const mutations = {
    setVFSFolderUploads: (state, uploads) => {
        state.VFSFolderUploads = uploads;
    },
    pushThumbnailToState: (state, { fileId, url }) => {
        state.uploadThumbnails.push({ _id: fileId, url });
    },
    removeThumbnailFromState: (state, fileId) => {
        state.uploadThumbnails = state.uploadThumbnails.filter((thumbnail) => thumbnail._id !== fileId);
    },
    clearVFSStates: (state) => {
        state.VFSFolderUploads = null;
        state.uploadThumbnails.forEach((thumbnail) => {
            window.URL.revokeObjectURL(thumbnail.url);
        });
        state.uploadThumbnails = [];
    }
};

const getters = {
    VFSFolderUploads: state => state.VFSFolderUploads,
    thumbnailsById: state => state.uploadThumbnails.reduce((acc, thumbnail) => {
        acc[thumbnail._id] = thumbnail.url;
        return acc;
    }, {})
};

const actions = {

    async getVFSFolderUploadsV2({commit, dispatch, getters}, params) {
        let folderId, mounting, forceRequest;
        if (params) {
            folderId = params.folderId;
            mounting = params.mounting;
            forceRequest = params.forceRequest;
        }
        if ((!folderId && mounting) || forceRequest) { // This will only be executed when called in mounted() or created()
            if (getters.VFSFolderUploads && !forceRequest) {
                return JSON.parse(JSON.stringify(getters.VFSFolderUploads));
            } else {
                const res = await backend.getVFSFolderUploadsV2();
                const uploads = await res.json();
                commit('setVFSFolderUploads', uploads);
                return JSON.parse(JSON.stringify(uploads));
            }
        }
        const res = await backend.getVFSFolderUploadsV2(folderId);
        const teacherFiles = await res.json();
        if (!folderId) {
            commit('setVFSFolderUploads', teacherFiles);
        }
        return JSON.parse(JSON.stringify(teacherFiles));
    },

    async getVFSAppFolderByNameV2({commit, dispatch}, params) {
        let folderName;
        if (params) {
            folderName = params.appFolderName;
        }
        const res = await backend.getVFSAppFolderV2(folderName);
        return await res.json();
    },

    async getVFSFolderUploadV2({commit, dispatch}, fileId) {
        try {
            const res = await backend.getVFSFolderUploadV2(fileId);
            return res;
        } catch (err) {
            return err;
        }
    },

    async postVFSFolderUploadV2({commit, dispatch}, {file, destinationId}) {
        try {
            const request = await backend.postVFSFolderUploadV2(file, destinationId);
            const formData = new FormData();
            formData.append('file', file);

            return new Promise((resolve) => {
                request.addEventListener('load', async (e) => {
                    resolve(JSON.parse(request.response));
                });
                request.send(formData);
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async getVFSSharingV2({commit, dispatch}, folderId) {
        try {
            const res = await backend.getVFSSharingV2(folderId);
            const response = await res.json();
            return response;
        } catch (err) {
            return err.response.status;
        }
    },

    async deleteVFSFolderUploadV2({commit, dispatch}, data) {

        try {
            const res = await backend.deleteVFSFolderUploadV2(data);
            await checkResponseStatus(204, res);
        } catch (err) {
            return err.response.status;
        }
    },

    async createVFSFolderV2({commit, dispatch}, params) {

        try {
            const folderId = params.currentFolderId;
            delete params.currentFolderId;
            const res = await backend.createVFSFolderV2(folderId, params);
            await checkResponseStatus(200, res);
            const newFolder = await res.json();
            return newFolder;
        } catch (err) {
            return err.response.status;
        }
    },

    async updateVFSFolderV2({commit, dispatch}, data) {

        try {
            const res = await backend.updateVFSFolderV2(data._id, data);
            return res;
        } catch (err) {
            return err.response.status;
        }
    },

    async updateVFSFolderUploadV2({commit, dispatch}, data) {

        try {
            const res = await backend.updateVFSFolderUploadV2(data.fileId, data);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            return err.response.status;
        }
    },

    updateVFSFolderUpload_LegacyV2: async ({commit, dispatch, rootGetters}, {data, fileId, fileName}) => {
        const formData = new FormData();
        let file = new File([data], fileName, {type: 'application/pdf'});
        formData.append('file', file, fileName);

        let xmlRequest = await backend.updateVFSFolderUpload_LegacyV2(fileId, file);
        xmlRequest.send(formData);
        return xmlRequest;
    },


    async deleteVFSFolderV2({commit, dispatch}, folderId) {

        try {
            const res = await backend.deleteVFSFolderV2(folderId);
            await checkResponseStatus(204, res);
        } catch (err) {
            return err.response.status;
        }
    },

    async markFileSeen({commit, dispatch}, fileId) {
        try {
            const res = await backend.markFileSeen(fileId);
            await checkResponseStatus(200, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async markLearningPathSeen({commit, dispatch}, learningPathId) {
        try {
            const res = await backend.markLearningPathSeen(learningPathId);
            await checkResponseStatus(200, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getFileBlob({commit, dispatch}, file) {
        try {
            const res = await backend.getFileBlob(file._id, file.accessToken);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            return window.URL.createObjectURL(blob);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getVFSUploadThumbnail ({ commit, getters }, { fileId, update, fileAccessToken }) {
        try {
            if (update) { // If new converter thumbnail came in and needs to be exchanged
                commit('removeThumbnailFromState', fileId);
            }
            if (getters.thumbnailsById[fileId]) {
                return getters.thumbnailsById[fileId]
            }
            const res = await backend.getVFSUploadThumbnail(fileId, fileAccessToken);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                commit('pushThumbnailToState', { fileId, url });
                return url;
            }
            return null;
        } catch (e) {
            console.error(e);
            return e?.response?.status;
        }
    }
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters
};
