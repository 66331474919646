<template>
    <div
        style="display: flex; flex-direction: column; width: 100%"
        class="greyDivBorderColor"
    >
        <div
            style="display: flex; flex-grow: 1"
            :style="'flex-direction: ' + direction"
            :class="!showTeacherUI ? (direction === 'row' ? 'marginr-48' : 'marginb-48') : ''"
        >
            <div
                style="margin: 12px; flex-grow: 1"
                :style="direction === 'row' ? 'width: 100%' : 'height: 100%'"
            >
                <!-- Teacher Input Fields -->
                <div
                    v-if="showTeacherUI"
                    style="display: flex"
                    :style="direction === 'row' ? 'width: 100%; flex-direction: row' : 'height: 100%; width: fit-content; flex-direction: column'"
                >
                    <div
                        v-for="(field, index) in fields"
                        :key="index"
                        style="width: fit-content; padding: 12px"
                        class="greyDiv"
                        :class="direction === 'row' ? 'mx-auto' : 'my-auto'"
                    >
                        <textarea
                            v-if="showTeacherUI"
                            v-model="fields[index]"
                            type="text"
                            placeholder=" Text eingeben"
                            style="border-radius: 6px; resize: none; height: 40px"
                            :style="sizeState === 0 ? 'width: 173px' : sizeState === 1 ? 'width: 225px' : 'width: 275px'"
                            :class="currentFontSize"
                            @change="fillAllFields(true)"
                            @keyup.enter="fillAllFields(true)"
                            @keyup.tab="fillAllFields(true)"
                            @keyup="fillAllFields(false)"
                        />
                    </div>
                </div>

                <!-- Pupil fields that are fillable with drag n drop -->
                <div
                    v-if="showPupilUI"
                    :style="direction === 'row' ? '' : 'height: 100%'"
                >
                    <div
                        style="display: flex; flex-grow: 1"
                        :style="direction === 'row' ? 'width: 100%; flex-direction: row' : 'height: 100%; flex-direction: column'"
                    >
                        <div
                            v-for="(field, index) in fields"
                            :key="index"
                            style="position: relative; width: fit-content"
                            class="greyDiv"
                            :class="direction === 'row' ? 'mx-auto' : 'my-auto'"
                        >
                            <div
                                style="display: flex; align-items: center; min-height: 50px; text-align: center; white-space: nowrap; overflow: auto"
                                :style="sizeState === 0 ? 'width: 197px; height: 64px' : sizeState === 1 ? 'width: 249px; height: 76px' : 'width: 299px; height: 100px'"
                                class="greyDiv colorWhite ml-1 mr-1"
                                :class="`${currentSolutionHint[index] || solutions[index] ? 'editorColorNeutralBackground' : ''} ${currentFontSize}`"
                                @drop="drop($event, index)"
                                @dragenter="setCurrentSolutionHint(index)"
                                @dragleave="currentSolutionHint = []"
                                @mouseenter="setCurrentSolutionHintMouseEnter(index)"
                                @mouseleave="currentSolutionHint = []"
                                @click="setClickSolution(index)"
                            >
                                <div
                                    class="mx-auto"
                                    :style="!solutions[index] ? 'pointer-events: none' : ''"
                                >
                                    <span
                                        style="pointer-events: none"
                                    >{{ currentSolutionHint[index] ? currentSolutionHint[index] : solutions[index] }}</span>
                                    <v-icon
                                        v-if="solutions[index]"
                                        style="color: white; cursor: pointer; margin-left: 4px"
                                        :size="sizeState === 0 ? '25' : sizeState === 1 ? '35' : '45'"
                                        @click="$set(solutions, index, undefined)"
                                    >
                                        mdi-close-circle-outline
                                    </v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Korrektur Fields -->
                <div
                    v-if="(accountRole === 'teacher' && state === 'teacher') || state === 'viewer'"
                    :style="direction === 'row' ? '' : 'height: 100%'"
                >
                    <div
                        style="display: flex; flex-direction: row; flex-grow: 1"
                        :style="direction === 'row' ? 'width: 100%; flex-direction: row' : 'height: 100%; flex-direction: column'"
                    >
                        <div
                            v-for="(field, index) in fields"
                            :key="index"
                            style="position: relative; width: fit-content"
                            class="greyDiv"
                            :class="direction === 'row' ? 'mx-auto' : 'my-auto'"
                        >
                            <v-tooltip
                                top
                                :disabled="fields[index] === solutions[index] || !elementOnCurrentPage"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                        style="text-align: center; display: flex; align-items: center"
                                        :style="sizeState === 0 ? 'width: 197px; height: 64px' : sizeState === 1 ? 'width: 249px; height: 76px' : 'width: 299px; height: 100px'"
                                        class="greyDiv"
                                        :class="`${fields[index] === solutions[index] ? 'editorColorPrimary' : (showSolution[index] ? 'editorColorPrimaryBackground colorWhite' : 'editorColorWrongBackground colorWhite')} ${currentFontSize}`"
                                        @click="fields[index] === solutions[index] ? {} : showSolutionField(index)"
                                        v-on="on"
                                    >
                                        <span
                                            class="mx-auto"
                                        >{{ fields[index] === solutions[index] ? fields[index] : showSolution[index] ? fields[index] : solutions[index] === undefined ? '-' : solutions[index] }}</span>
                                    </div>
                                </template>
                                <span
                                    style="color: white"
                                >{{ showSolution[index] ? 'Lösung verstecken' : 'Lösung anzeigen' }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lehrer add/remove Fields -->
            <div
                v-if="showTeacherUI"
                class="editorOptionsFade"
                style="display: flex; margin-right: 12px; margin-left: 12px"
                :style="direction === 'row' ? 'flex-direction: column' : 'flex-direction: row'"
            >
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover greyDiv"
                    depressed
                    color="#ededed"
                    style="flex-grow: 1; width: 48px; min-width: 48px; height: 48px; cursor: pointer;"
                    @click="addCol"
                >
                    <span
                        class="editorColorPrimaryOnHoverChildColorToWhite editorColorPrimary"
                        style="font-size: xx-large"
                    >+</span>
                </v-btn>
                <v-btn
                    v-ripple="fields.length > 1"
                    :style="fields.length > 1 ? '' : 'cursor: default; background-color: var(--v-editorGrey-base) !important'"
                    class="editorColorWrongBackgroundOnHover greyDiv"
                    depressed
                    color="#ededed"
                    style="width: 48px; min-width: 48px; cursor: pointer; height: 48px"
                    :class="direction === 'row' ? 'mt-1' : 'ml-1'"
                    @click="removeCol"
                >
                    <span
                        :class="fields.length > 1 ? 'editorColorWrongOnHoverChildColorToWhite editorColorWrong' : 'controlsDisabled'"
                        style="font-size: xx-large"
                    >-</span>
                </v-btn>
            </div>

            <!-- Lehrer Controls rechts rausgeschoben -->
            <div
                v-if="showTeacherUI"
                class="editorOptionsFade"
                style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px"
            >
                <div>
                    <v-btn
                        class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                        @click="changeSize()"
                    >
                        <img
                            :src="currentSizeIcon"
                            style="height: 32px"
                            class="iconToWhiteHover"
                            alt="Größe des Elements verändern"
                        >
                    </v-btn>
                    <v-btn
                        class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                        @click="changeDirection"
                    >
                        <v-icon
                            class="editorColorPrimary editorColorPrimaryOnHoverChildColorToWhite"
                        >
                            {{ direction === 'row' ? 'mdi-arrow-right-bold-outline' : 'mdi-arrow-down-bold-outline' }}
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- Verfügbare Drag n Drop Elemente die in die Felder gezogen werden können -->
        <div
            style="margin: 12px"
            class
        >
            <div
                style="display: flex; flex-direction: row; align-items: center"
                class="greyDiv"
                :class="showTeacherUI ? 'editorOptionsFade1' : ''"
            >
                <!--  Lehrer kann Drag n Drop Elemente shufflen -->
                <img
                    v-if="showTeacherUI"
                    :src="aktualisierenIcon"
                    style="cursor: pointer; margin-left: 8px; margin-right: 2px"
                    :style="sizeState === 0 ? 'height: 20px' : sizeState === 1 ? 'height: 25px' : 'height: 35px'"
                    @click="fillAllFields(true)"
                >
                <div
                    style="display: flex; "
                    :style="direction === 'row' ? 'flex-direction: row; flex-wrap: wrap' : 'flex-wrap: wrap'"
                >
                    <div
                        v-for="(field, index) in allFields"
                        :key="index"
                        style="margin: 8px 6px; border-radius: 12px"
                        :style="sizeState === 0 ? 'padding: 8px' : sizeState === 1 ? 'padding: 12px' : 'padding: 24px'"
                        :class="`${showTeacherUI ? 'editorColorPrimaryBackground' : 'editorColorNeutralBackground colorWhite moveCursor'} ${lastClickedIndicator[index] ? 'lastClickedBorder' : ''} ${currentFontSize}`"
                        :draggable="showPupilUI"
                        @dragstart="showPupilUI ? setDragData($event, field) : {}"
                        @click="setClickData(field, index)"
                    >
                        <span style="padding: 4px">{{ field === '' ? 'Text eingeben' : field }}</span>
                    </div>
                    <!-- Der Lehrer kann falsche Drag n Drop Elemente hinzufügen -->
                    <input
                        v-if="addNewWrongField && showTeacherUI"
                        v-model="wrongField"
                        style="background-color: white !important; margin: 8px 6px; padding: 4px; border: solid 2px; border-radius: 12px;"
                        class="editorColorPrimaryBorderColor"
                        :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: x-large'"
                        @change="addWrongField(); fillAllFields(true)"
                    >
                    <div
                        v-if="showTeacherUI"
                        style="display: flex; align-items: center; margin: 8px 28px 8px 6px; border: solid 2px; border-radius: 12px; background-color: white !important; cursor: pointer"
                        class="editorColorPrimaryBorderColor"
                        :style="sizeState === 2 ? 'height: 49px' : ''"
                        :class="currentFontSize"
                        @click="addNewWrongField = true"
                    >
                        <span style="padding: 4px; cursor: pointer">+ Falsche Lösung</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import fertigIcon from "../../assets/Icons/fertig-gruen-74.svg"
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import pfeilIcon from "@/assets/Icons/arrow-to-right.svg";
import pfeilCrossedIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import aktualisierenIcon from "../../assets/Icons/aktualisieren-green-107.svg";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "EditorDragnDrop",
    props: {
        disableMovePupil: { type: Boolean, required: false, default: false },
        canvasWidth: { type: Number, required: true },
        canvasHeight: { type: Number, required: true },
        tabIndex: { type: Number, required: false, default: 0 },
        config: { type: Object, required: true },
        state: { type: String, required: true },
        exportMode: { type: Boolean, required: false, default: false },
        elementOnCurrentPage: { type: Boolean, required: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        allFields: [],
        fields: ['', '', ''],
        wrongFields: [],
        wrongField: '',
        addNewWrongField: false,
        sizeState: 0,
        direction: 'row',
        dragData: '',
        solutions: [],
        currentSolutionHint: [],
        showSolution: [],
        lastClicked: undefined,
        lastClickedIndicator: [],

        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,
        aktualisierenIcon,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),

        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },

        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
    },
    watch: {
        sizeState: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        allFields: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        wrongFields: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        solutions: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        direction: {
            handler(val, oldVal) {
                this.saveState();
            }
        },
        previewPupilUI: {
            handler(val, oldVal) {
                this.solutions = [];
            }
        }
    },
    mounted() {
        if (!this.config.dragndropConfig) {
            this.config.dragndropConfig = {};
            this.config.identifier = uuidv4();
            this.config.dragndropConfig.fields = this.fields;
            this.config.dragndropConfig.solutions = this.solutions;
            this.config.dragndropConfig.allFields = this.allFields;
            this.config.dragndropConfig.sizeState = this.sizeState;
            this.config.dragndropConfig.direction = this.direction;
            this.fillAllFields(false);
        } else {
            if (this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.fields = this.config.dragndropConfig.fields;
            this.allFields = this.config.dragndropConfig.allFields;
            this.sizeState = this.config.dragndropConfig.sizeState;
            this.solutions = this.config.dragndropConfig.solutions;
            this.direction = this.config.dragndropConfig.direction;
        }
    },
    methods: {
        ...mapActions('pupils', ['getMePupil']),

        setClickData(field, index) {
            if (this.showPupilUI) {
                if (this.lastClicked === field) {
                    this.lastClicked = undefined;
                    this.$set(this.lastClickedIndicator, index, false);
                } else {
                    this.lastClicked = field;
                    this.$set(this.lastClickedIndicator, index, true);
                }
            }
        },

        setCurrentSolutionHintMouseEnter(index) {
            if(!this.lastClicked) {
                this.$set(this.currentSolutionHint, index, this.lastClicked);
            }
        },

        setClickSolution(index) {
            this.$set(this.solutions, index, this.lastClicked);
            this.lastClickedIndicator = [];
            this.lastClicked = undefined;

        },

        changeDirection() {
            if (this.direction === 'row') {
                this.direction = 'column';
            } else {
                this.direction = 'row';
            }
        },

        showSolutionField(index) {
            let showSolution = false;
            if (this.showSolution[index] === undefined) {
                showSolution = true;
            } else {
                showSolution = !this.showSolution[index];
            }

            this.$set(this.showSolution, index, showSolution);
        },

        fillAllFields(shuffle) {
            let array = this.fields.concat(this.wrongFields);
            if (shuffle) {
                this.allFields = this.shuffleArray(array)
            } else {
                this.allFields = array;
            }
        },

        shuffleArray(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        },

        setCurrentSolutionHint(index) {
            this.$set(this.currentSolutionHint, index, this.dragData);
        },

        setDragData(event, text) {
            this.dragData = text;
            event.dataTransfer.setData("fieldText", text);
        },

        drop(event, index) {
            event.preventDefault();
            this.currentSolutionHint = [];
            this.$set(this.solutions, index, event.dataTransfer.getData("fieldText"));
        },

        addCol() {
            this.fields.push('');
        },

        removeCol() {
            if (this.fields.length > 1) {
                this.fields.pop();
            }
        },

        addWrongField() {
            this.wrongFields.push(this.wrongField);
            this.wrongField = '';
            this.addNewWrongField = false;
        },

        changeSize() {
            switch (this.sizeState) {
                case 0:
                    this.sizeState = 1;
                    break;
                case 1:
                    this.sizeState = 2;
                    break;
                case 2:
                    this.sizeState = 0;
                    break;
                default:
                    break;
            }
        },

        saveState() {
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            if(!this.previewPupilUI) {
                this.config.dragndropConfig.fields = this.fields;
                this.config.dragndropConfig.allFields = this.allFields;
                this.config.dragndropConfig.sizeState = this.sizeState;
                this.config.dragndropConfig.solutions = this.solutions;
                this.config.dragndropConfig.direction = this.direction;
            }
        },
    }
}
</script>

<style scoped lang="scss">
input:focus {
    outline: none;
}
textarea:focus {
    outline: none;
}

.inputWithoutBorder {
    border: 0;
}

.colorWhite {
    color: white;
}

.marginr-48 {
    margin-right: 70px;
}

.marginb-48 {
    margin-bottom: 48px;
}
.lastClickedBorder {
    border: dashed 4px red;
    padding: 4px !important;
}
.moveCursor {
    cursor: move
}
</style>
