<template>
    <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: #f8f8f8; overflow: hidden">
        <div style="position: absolute;  left: 0.25rem;">
            <!-- Verwaltung: <a
                class="infobarLink"
                style="text-decoration: underline"
            ><b @click="maintainerResetDialog = true">
                Passwort zurücksetzen
            </b></a> -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        style="background-color: #eeeeee !important"
                        x-small
                        elevation="0"
                        class="accessibilityButtons"
                        v-on="on"
                        @click="maintainerResetDialog = true"
                    >
                        <img
                            :src="lockIcon"
                            style="max-width: 25px; max-height: 25px;"
                            class="iconToDarkBlue"
                            alt="Verwaltung Passwort zurücksetzen"
                        >
                    </v-btn>
                </template>
                <span>Verwaltung: Passwort zurücksetzen</span>
            </v-tooltip>
        </div>
        <div
            style="position: absolute; display: flex; justify-content: flex-end; width: 100%"
        >
            <!-- Magnifier -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        :style="magnifier ? 'background-color: var(--v-chatColor-base) !important' : 'background-color: #eeeeee !important'"
                        x-small
                        elevation="0"
                        class="accessibilityButtons"
                        v-on="on"
                        @click="toggleMagnifier()"
                    >
                        <img
                            :src="lupenIcon"
                            style="max-width: 25px; max-height: 25px;"
                            class="iconToDarkBlue"
                            :alt="magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'"
                        >
                    </v-btn>
                </template>
                <span v-if="magnifier">Lupe ausschalten (Alt + L)</span>
                <span v-else>Lupe einschalten (Alt + L)</span>
            </v-tooltip>

            <!-- Keyboard -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        elevation="0"
                        class="accessibilityButtons"
                        :style="keyboard ? 'background-color: var(--v-chatColor-base) !important' : 'background-color: #eeeeee !important'"
                        v-on="on"
                        @click="toggleKeyboard()"
                    >
                        <img
                            :src="keyboardIcon"
                            class="iconToDarkBlue"
                            :alt="keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)'"
                            height="25"
                        >
                    </v-btn>
                </template>
                <span v-if="keyboard">Tastatursteuerung ausschalten (Alt + T)</span>
                <span v-else>Tastatursteuerung einschalten (Alt + T)</span>
            </v-tooltip>
        </div>

        <v-btn
            v-if="false"
            class="mt-5 mr-5 helpBtn questionmarkIcon"
            text
            fixed
            right
            top
            @click="showLoginTutorial()"
        >
            <v-img
                class="questionmarkIcon"
                :src="questionmark"
                contain
                alt="Fragezeichensymbol"
            />
        </v-btn>

        <v-form
            ref="loginForm"
            class="loginFormWrapper"
        >
            <div class="loginForm">
                <!-- logo at top-->
                <div>
                    <img
                        id="eKlaraLogo"
                        :src="logo"
                        class="center mb-4"
                        alt="eKlara-Logo"
                    >
                </div>

                <div
                    style="background-color: var(--v-headerblue-base); border-radius: 8px"
                    class="px-4"
                >
                    <!-- login text fields -->
                    <div class="loginFormInputs">
                        <div class="loginFormRow">
                            <v-text-field
                                v-model="userName"
                                required
                                autocomplete="username"
                                label="Nutzername"
                                :disabled="showTwoFactor"
                                solo
                                flat
                                light
                                hide-details
                                class="font-weight-bold pt-4"
                                color="#707070 !important"
                                @keydown="
                                    (keyEvent) => {
                                        if (keyEvent.key === 'Enter') {
                                            clickLoginUser();
                                        }
                                    }
                                "
                            >
                                <template v-slot:append>
                                    <img
                                        :src="profileIcon"
                                        class="iconToDunkelgrau"
                                        style="display: block; height: 30px; margin-top: -4px;"
                                        alt
                                    >
                                </template>
                            </v-text-field>
                        </div>

                        <div class="loginFormRow">
                            <v-text-field
                                v-model="password"
                                required
                                autocomplete="current-password"
                                :type="show1 ? 'text' : 'password'"
                                label="Passwort"
                                :disabled="showTwoFactor"
                                solo
                                flat
                                light
                                hide-details
                                class="font-weight-bold"
                                color="#707070 !important"
                                @keydown="
                                    (keyEvent) => {
                                        if (keyEvent.key === 'Enter') {
                                            clickLoginUser();
                                        }
                                    }
                                "
                            >
                                <template v-slot:append>
                                    <img
                                        tabindex="0"
                                        role="button"
                                        :alt="show1 ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                        :src="show1 ? eyeIcon : eyeOffIcon"
                                        style="display: block; height: 30px; margin-top: -4px; color: #747474"
                                        @click="clickShow1"
                                    >
                                </template>
                            </v-text-field>
                        </div>
                        <p
                            v-if="showResetPasswordButton"
                            style="cursor: pointer; color: white; margin-bottom: -10px !important;font-size: 12px;text-decoration:underline;"
                            @click="initiateResetPassword"
                        >
                            <b>Account gesperrt? Hier klicken um selbst freizuschalten</b>
                        </p>
                        <div class="loginFormRow">
                            <v-text-field
                                v-if="showTwoFactor"
                                v-model="twoFactorCode"
                                label="2FA Code"
                                solo
                                flat
                                light
                                hide-details
                                class="font-weight-bold"
                                color="#707070 !important"
                            />
                        </div>
                    </div>
                  
                    <!-- login buttons -->
                    <div
                        class="d-flex flex-row align-center justify-space-between"
                    >
                        <div
                            class="d-flex flex-row align-center"
                            style="width: 45%"
                        >
                            <v-btn
                                id="qrLoginButton"
                                aria-role="button"
                                large
                                outlined
                                class="text-capitalize loginButtonWidth"
                                @click="qrScanLogin =true"
                            >
                                <img
                                    :src="qrLoginIcon"
                                    class="mr-2 icon"
                                    alt
                                >
                                <p style="color: white">
                                    {{ windowWidth > 600 ? ' Scan QR-Ausweis':'Scan QR' }}
                                </p>
                            </v-btn>
                        </div>
                        <div
                            class="d-flex flex-row align-center"
                            style="width: 45%"
                        >
                            <v-btn
                                id="regularLoginBtn"
                                outlined
                                large
                                style="background-color: var(--v-chatColor-base);"
                                class="text-capitalize loginButtonWidth"
                                @click="clickLoginUser"
                            >
                                <v-progress-circular
                                    v-if="isLoading"
                                    indeterminate
                                    color="white"
                                    size="20"
                                    class="mr-2"
                                />
                                <img
                                    v-else
                                    :src="beitretenIcon"
                                    class="mr-2 icon"
                                    alt
                                >
                                <p style="color:white">
                                    Anmelden
                                </p>
                            </v-btn>
                        </div>
                    </div>

                    <!-- Stay logged in checkbox -->
                    <v-row class="loginBtnWrapper">
                        <v-col>
                            <div class="loginFormRow stayLoggedIn mb-0 d-flex justify-center align-center">
                                <v-checkbox
                                    v-model="localStayLoggedIn"
                                    class="mt-0 pt-0"
                                    hide-details
                                    color="var(--v-chatColor-base)"
                                    dark
                                    style="color: white"
                                >
                                    <template v-slot:label>
                                        <span
                                            style="color: white"
                                            aria-hidden="true"
                                            class="ml-1"
                                        >
                                            Angemeldet bleiben
                                        </span>
                                    </template>
                                </v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <!-- Add eKlara to homescreen text -->
                <v-row id="homescreenTextWrapper">
                    <!-- <v-col
                        id="homescreenText"
                        aria-hidden="true"
                        :cols="$vuetify.breakpoint.width > 500 ? 11 : 10"
                        class="pt-0"
                    >
                        So installierst Du eklara als App auf deinem Apple 
                        <a
                            href="https://www.youtube.com/watch?v=LcvvbcVRI14"
                            class="infobarLink"
                            aria-label="Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"
                        >Iphone/IPad</a> oder Android
                        <a
                            href="https://www.youtube.com/watch?v=LcvvbcVRI14"
                            class="infobarLink"
                            aria-label="Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"
                        >Smartphone/Tablet</a>.
                    </v-col> -->
                    <div class="d-flex flex-row justify-space-around mt-1 align-center">
                        <img
                            :src="youtubeIcon"
                            class="icon20 mr-2"
                            style="cursor: pointer;"
                            @click="openYoutube()"
                        > <p id="homescreenText">
                            <b>eklara als App  
                                installieren  <a
                                    href="https://youtu.be/66wm74ubYKM"
                                    target="_blank"
                                    class="infobarLink"
                                    aria-label="Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"
                                >Apple</a>/<a
                                    href="https://youtu.be/JquzwF-Xly0"
                                    target="_blank"
                                    class="infobarLink"
                                    aria-label="Füge die eKlara-App deinem Homescreen hinzu Beispielvideo"
                                >Android</a>.</b>
                        </p>
                    </div>
                </v-row>
            </div>
        </v-form>

        <!-- Supported browsers text -->
        <div
            ref="infobarContent"
            class="infobar"
        >
            <v-tooltip top>
                <template v-slot:activator="{ on,attrs }">
                    <div
                        class="infobarIcons"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img
                            class="infobarIcon"
                            :src="devices"
                            alt
                        >
                        <img
                            class="infobarIcon"
                            :src="internet"
                            alt
                        >
                    </div>
                </template>
                <span>
                    Eine
                    <b>stabile Internetverbindung</b> ist Voraussetzung.
                </span>
            </v-tooltip>

            <div class="infobarTexts">
                <span class="infobarText">
                    Kompatibel: 
                    <b>Safari, Chrome, Firefox, Edge.</b>
                </span>
            </div>
        </div>

        <!--<TTS></TTS>-->
        <DatenschutzAbfrage
            v-if="displayAGB"
            :has-accepted-a-g-b="hasAcceptedAGB"
            @declinedAGB="() => { displayAGB = false }"
        />
        <v-dialog
            v-model="qrScanLogin"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <!-- With v-if="qrScanLogin" will be q-r-scan-login closed properly-->
                <!-- This is helpful when a first qr-code scan fails because of something like: backend unreachable-->
                <!-- Because without closing it the same qr-code cannot be read 2 times in a row-->
                <q-r-scan-login
                    v-if="qrScanLogin"
                    :close-function="() => { qrScanLogin = false; }"
                    @codeScanned="qrScanLoginUser"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="maintainerResetDialog"
            width="400"
            class="d-flex align-center"
            style="max-width: 400px;"
        >
            <v-card 
                style="max-width: 400px;"
            >
                <v-card-title
                    class="pa-2 d-flex justify-space-between"
                    style="background-color: var(--v-headerblue-base);"
                >
                    <p style="color: white">
                        Passwort zurücksetzen
                    </p>
                    <v-btn
                        icon
                        @click="closeMaintainerResetDialog"
                    >
                        <img
                            :src="schliessenIcon"
                            class="icon30 iconToWhite"
                            alt="schliessen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text class="pt-2">
                    Um das Verwaltungspasswort zurückzusetzen, geben Sie Bitte das initiale Verwaltungspasswort, welches Sie bei der Einrichtung von eklara per Email erhalten haben ein. 
                    Falls Sie dieses nicht mehr wissen, wenden Sie sich bitte an den Support.
                </v-card-text>
                <div class="mx-4">
                    <v-text-field
                        v-model="initialMaintainerPassword"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        dense
                        hide-details
                        placeholder="Initiales Passwort eingeben"
                        class="inputField mb-2"
                    >
                        <template v-slot:append>
                            <img
                                tabindex="0"
                                role="button"
                                :alt="showPassword ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                                :src="showPassword ? eyeIcon : eyeOffIcon"
                                style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                                @click="showPassword = !showPassword"
                            >
                        </template>
                    </v-text-field>
                </div>
                <v-card-actions class="d-flex flex-row justify-end">
                    <v-btn
                        color="success"
                        @click="confirmMaintainerResetDialog"
                    >
                        Passwort zurücksetzen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PinDialog
            :show-pin-dialog="showPinDialog"
            :account="pinAccount"
            :usage="'login'"
            :has-pin="hasPin"
            @close="showPinDialog = false"
            @accountPinValid="()=>{pinValid();}"
        />
        <v-dialog 
            v-model="resetPasswordDialog"
            width="400"
            class="d-flex align-center"
            style="max-width: 400px;"
        >
            <v-card>
                <v-card 
                    style="max-width: 400px;"
                >
                    <v-card-title
                        class="pa-2 d-flex justify-space-between"
                        style="background-color: var(--v-headerblue-base);"
                    >
                        <p style="color: white">
                            Zugang freischalten
                        </p>
                        <v-btn
                            icon
                            @click="closeResetPwDialog"
                        >
                            <img
                                :src="schliessenIcon"
                                class="icon30 iconToWhite"
                                alt="schliessen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pt-2">
                        Um den Zugang selbst wieder freizuschalten, müssen sie Ihren Nutzernamen sowie die bestehende Email eingeben. 
                        Im Anschluss erhalten sie ein Code zur Freischaltung an diese Email zugesendet. Falls Ihr Konto keine Email eingetragen hatte,
                        wenden Sie sich an die Schulverwaltung.
                    </v-card-text>
                    <div
                        v-if="!showCodeField"
                        class="mx-4"
                    >
                        <v-text-field
                            v-model="userName"
                            outlined
                            dense
                            hide-details
                            placeholder="Nutzername"
                            class="inputField mb-2"
                        />
                        <v-text-field
                            v-model="accountMail"
                            outlined
                            dense
                            hide-details
                            placeholder="Account-Email eingeben"
                            class="inputField mb-2"
                        />
                    </div>
                    <div
                        v-else
                        class="mx-4"
                    >
                        <v-text-field
                            v-model="resetCode"
                            outlined
                            dense
                            hide-details
                            placeholder="Code aus Email eingeben"
                            class="inputField mb-2"
                        />
                    </div>
                    <v-card-actions class="d-flex flex-row justify-end">
                        <v-btn
                            v-if="!showCodeField"
                            color="success"
                            @click="sendResetLockedAccountRequest"
                        >
                            Code senden
                        </v-btn>
                        <v-btn
                            v-else
                            color="success"
                            @click="sendCode"
                        >
                            Code bestätigen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import * as backend from "@/api/backend";
import logo from "@/assets/Icons/FaceliftIcons/eklaraLogoV3.svg";
import qrLoginIcon from "@/assets/Icons/FaceliftIcons/qr_schulausweis.svg";
import lockIcon from '@/assets/Icons/lock.svg';
import beitretenIcon from "@/assets/Icons/FaceliftIcons/passwort_login_fingerabdruck.svg";
import keyboardIcon from '@/assets/Icons/FaceliftIcons/tastatur_behinderung_.svg'
import lupenIcon from '@/assets/Icons/FaceliftIcons/suchen_lupe.svg'
import questionmark from "@/assets/Tooltip/fragezeichen.svg";
import eyeIcon from "@/assets/Icons/eye.svg";
import eyeOffIcon from "@/assets/Icons/eye-off.svg";
import profileIcon from "@/assets/Icons/pupil.svg";
import devices from "@/assets/Icons/Login Tablet Mobil PC.svg";
import internet from "@/assets/Icons/wlan.svg";
import youtubeIcon from "@/assets/Icons/FaceliftIcons/youtube_icon.svg"
import schliessenIcon from "../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";

import QRScanLogin from "./QRScanLogin";
import DatenschutzAbfrage from "./Policys/DatenschutzAbfrage";
import {checkCookieAccepted, getCookie, setCookie} from '@/util/cookies';
import {AuthorizationError, ConnectionRefusedError, HTTPConflictError} from "@/util/error";
import PinDialog from '@/components/PinDialog.vue';

export default {
    name: "Login",
    components: {
        QRScanLogin,
        DatenschutzAbfrage,
        PinDialog,
        /*TTS,*/
    },
    data: () => ({
        //#region Icons
        logo,
        qrLoginIcon,
        beitretenIcon,
        keyboardIcon,
        lupenIcon,
        questionmark,
        eyeIcon,
        eyeOffIcon,
        profileIcon,
        devices,
        internet,
        youtubeIcon,
        schliessenIcon,
        lockIcon,
        //#endregion

        loginTries: 0,
        showResetPasswordButton: false,
        showCodeField: false,
        resetCode: '',
        resetPasswordDialog: false,
        accountMail:"",
        userName: "",
        password: "",
        showTwoFactor: false,
        showPinDialog: false,
        twoFactorCode: "",
        show1: false,
        qrScanLogin: false,
        isLoading: false,

        showPassword: false, 
        initialMaintainerPassword: '',

        showLoginTut: false,
        displayAGB: false,
        hasAcceptedAGB: false,

        maintainerResetDialog: false,
        qr2FA: false,
        pinAccount: null,
        hasPin: false,
        recommendedBrowser: ['Edge', 'Chrome', 'Firefox', 'Safari', 'Mobile Safari'],
        currentBrowser: null,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("auth", ["stayLoggedIn"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ["keyboard","windowWidth"]),
        ...mapState("translate", ["translationInitialized"]),
        localStayLoggedIn: {
            get: function () {
                return this.stayLoggedIn;
            },
            set: function (newValue) {
                this.authChangeStayLoggedIn(newValue);
            }
        }
    },
   async mounted() {
        // console.log('loggedIn', this.stayLoggedIn);
        const storageShowLoginTutorial = localStorage.getItem("showLoginTutorial");
        this.showLoginTut =
            storageShowLoginTutorial === null
                ? true
                : storageShowLoginTutorial === "true";
        // window.removeEventListener("keyup", this.shortcutMagnifier);
        window.addEventListener("keydown", this.handleKeyUp);
        this.closeProfileManagement();
        const browserres = await backend.checkBrowser();
        const browserObject = await browserres.json();
        this.currentBrowser = browserObject.name;
            if (!this.recommendedBrowser.includes(browserObject.name)) {
                this.showSnackbar({
                                message: "Dieser Browser wird nicht unterstützt. Bitte verwenden Sie für eine optimale Nutzung einen empfohlenen Browser(Google Chrome, Firefox, Edge, Safari)",
                                color: "error",
                                timeout: 15000,
                            })
            }
  
        window.speechSynthesis.getVoices();

        this.loginTries = 0;
        // if (!checkCookieAccepted()) {
        //     this.toggleShowCookieLawWatcher();
        //     return;
        // }

        // check height of screen for bottom text
        // const startHeight = window.clientHeight || window.innerHeight;
        // this.$refs.infobarContent.style.top = `${startHeight - 50}px`;
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions("auth", {
            authLoginUser: "loginUser",
            authChangeStayLoggedIn: "changeStayLoggedIn",
        }),
        ...mapActions("auth", ["get2FA", "has2FA", "tryResetLockedAccount","verifyReset"]),
        ...mapActions("util", ["toggleKeyboard", ""]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions("util", ['loadServerTime', 'requestVersionNumber', 'toggleShowCookieLawWatcher']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('auth', ['setPinActivated']),
        ...mapActions('profileManagement', ['closeProfileManagement']),
        ...mapActions('translation', ['setTranslationInitialized']),

        async sendResetLockedAccountRequest(){
            const data = { accountName: this.userName.trim(), email: this.accountMail.trim() };
            const response = await this.tryResetLockedAccount(data);
            if(response === 'accepted'){
                this.showCodeField = true;
                this.showSnackbar({message:'Der Code zum Zurücksenden wurde erfolgreich an die angegebene Email versandt. Falls Sie diesen nicht erhalten haben überprüfen Sie auch den Spam-Ordner oder senden den Code erneut. ', color:"success"});
            }else{
                this.showSnackbar({message:'Die Email ist nicht mit diesem Nutzer verknüpft. Um den Zugang freizuschalten wenden Sie sich an die Schulverwaltung.',color:"error"});
                this.resetPasswordDialog = false;
                this.accountMail = false;
            }
        },
        async sendCode(){
            const data = {
                otp: this.resetCode.trim(),
                accountName: this.userName.trim(),
                }

            const res = await backend.verifyResetOTP(data);
            if(res.status === 200){
                this.showSnackbar({message:'Der Nutzer wurde erfolgreich freigeschalten.', color:"success"});
                this.closeResetPwDialog();    
            }else if(res.status === 401){
                this.showSnackbar({message:'Der eingegebene Code ist nicht korrekt oder abgelaufen.', color: "error"});
            }else {
                this.showSnackbar({message:'Etwas ist schiefgelaufen, laden sie die Seite neu und versuchen es erneut.', color: "error"});
            }
        },
        closeResetPwDialog(){
            this.resetPasswordDialog = false;
            this.accountMail = false;
            this.showCodeField = false;
            this.resetCode = false;
        },
        initiateResetPassword(){
            this.resetPasswordDialog = true;
        },
        async pinValid(){
            this.showSnackbar({
                message: 'Login erfolgreich!',
                color: 'success'
            })
            
            this.loadServerTime();
            this.requestVersionNumber();
            this.rerouteAccount(this.accountRole);
        },
        async closeMaintainerResetDialog(){
            this.maintainerResetDialog = false;
            this.initialMaintainerPassword = '';
        },
        async confirmMaintainerResetDialog(){
            const body = {password: this.initialMaintainerPassword.trim()}
            const res = await backend.resetMaintainerPassword(body);
            if(res.status === 200){
                this.maintainerResetDialog = false;
                    this.showSnackbar({message: 'Das Passwort wurde erfolgreich zurückgesetzt und an die Email des Verwaltungsaccounts gesendet. Falls Sie keinen Zugriff auf diese haben, wenden Sie sich  bitte an den Eklara Support.', color: 'success', timeout: 15000});

            }else{
                this.showSnackbar({message: 'Das initiale Verwaltungspasswort ist nicht korrekt. Falls Sie dieses nicht mehr wissen wenden Sie sich bitte an den Eklara Support.', color: 'error', timeout: 15000});
            }
        },
        openYoutube(){
            window.open("https://youtu.be/66wm74ubYKM", "_blank");
        },
        handleKeyUp(event) {
            if (event.altKey && event.key === 't') {
                this.toggleKeyboard()
                this.triggerShortcutTooltips();
            }
            if (event.altKey && event.key === 'l') {
                this.toggleMagnifier();
                this.triggerShortcutTooltips();
            }
        },
        showLoginTutorial() {
            this.showLoginTut = true;
            localStorage.setItem("showLoginTutorial", "true");
        },

        async clickLoginUser() {
            if (!this.userName || !this.password) {
                this.showSnackbar({message: "Nutzername oder Passwort nicht ausgefüllt ", color: "error"});
                return;
            }
             //somehow on apple devices there is the problem that tts for translatedLanguages doesnt work
            //unless it is used once with german language. therefore we do this (it works pls dont break unless u find better solution)
                let msg = new SpeechSynthesisUtterance();
                msg.text = '';
                msg.lang = 'de-DE';
                window.speechSynthesis.speak(msg);
                msg.onend = () => {
                this.setTranslationInitialized(true);
            }
            await this.loginUser({accountName: this.userName.trim(), password: this.password, qrLogin: false});
        },
        qrScanLoginUser({accountName, password}) {
            this.loginUser({accountName, password, qrLogin:true});
            this.qrScanLogin = false;
        },
        async loginUser({accountName, password, qrLogin}) {
            this.isLoading = true
        try{
                if(this.twoFactorCode) {
                   this.twoFactorCode = this.twoFactorCode.trim();
                }
                const copyAccName = accountName;

                const res = await this.authLoginUser({accountName, password, twoFactor: this.twoFactorCode, qrLogin});
                if (res.twoFA) {
                    if(qrLogin){
                        this.qr2FA = true;
                    }
                    this.password = password;
                    this.userName = copyAccName.trim();
                    const body = await this.get2FA({accountName: copyAccName.trim(), accountPassword: password});
                    this.showSnackbar({
                        message: 'Ein gültiger Code wurde an ihre Email verschickt.',
                        color: 'success'
                    });
                    this.showTwoFactor = true;
                    return;
                }
                else if(res.loginPinActivated && !this.twoFactorCode){
                    const hasPinRes = await backend.accountHasPin(res._id);
                    if(hasPinRes.status === 200){
                        this.hasPin = true;
                    }else{
                        this.hasPin = false;
                    }
                    this.pinAccount = res;
                    this.showPinDialog = true;
                    return;
                }
                else if(res.error) {
                    this.showSnackbar({
                        message: 'Der eingegebene Code scheint nicht gültig zu sein.',
                        color: 'error'
                    });
                    return;
                }
            this.showSnackbar({
                message: 'Login erfolgreich!',
                color: 'success'
            })
            
            this.loadServerTime();
            this.requestVersionNumber();
            this.rerouteAccount(this.accountRole);
        } catch (err) {
            if (err instanceof ConnectionRefusedError) {
                this.showSnackbar({message: "Server nicht erreichbar. Haben Sie Internet? ", color: "error"});
            } else if (err instanceof AuthorizationError) {
                this.showSnackbar({
                    message: "QR-Login deaktiviert oder kein Nutzer mit diesen Daten gefunden. Achten Sie auf Groß- und Kleinschreibung.",
                    color: "error",
                    timeout:8000,
                });
                this.loginTries = this.loginTries +1;
                if(this.loginTries === 3){
                    this.showSnackbar({
                    message: "Achtung! Nach 2 weiteren falschen Eingaben wird ihr Account möglicherweise gesperrt.  Achten Sie auf Groß- und Kleinschreibung.",
                    color: "warning"
                });
                }
            }else if (err instanceof HTTPConflictError) {
                this.showSnackbar({
                    message: "Nutzer aufgrund von zu vielen fehlerhaften Anmeldeversuchen gesperrt. Bitten Sie die Verwaltung den Nutzer wieder freizuschalten.",
                    color: "error"
                });
                this.showResetPasswordButton = true;
            } else {
                console.error(err);
                this.showSnackbar({message: "Unerwarteter Fehler.", color: "error"});
            }
        } finally {
            this.isLoading = false;
            this.qr2FA = false;
        }
        },
        async rerouteAccount(role) {
            switch (role) {
                case 'maintainer':
                    this.$router.push({name: 'verwaltung.plan', query: this.$route.query});
                    break;
                case 'pupil':
                    this.$router.push({name: 'schueler.plan', query: this.$route.query});
                    break;
                case 'teacher':
                    this.$router.push({name: 'lehrer.plan', query: this.$route.query});
                    break;
                case 'parent':
                    this.$router.push({name: 'eltern.plan', query: this.$route.query});
                    break;
                default:
                    console.error("No default role for role found", role);
                    break;
            }
        },
        clickShow1(){
            if(!this.qr2FA){
                this.show1 = !this.show1;
                }
                else{
                    this.show1 = false
                    ;}
        },
    },

};
</script>

<style lang="scss" scoped>
.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.helpBtn {
    z-index: 1;
    padding: 16px;
}

.loginBtnWrapper {
    display: flex;
    justify-content: center;
    width: 400px;
    max-width: 400px;
    margin: auto;
}

.loginBtn {
    color: white;
    background-color: var(--v-chatColor-base);
    width: 100%;
    border: 0;
}

#qrLoginButton {
    background-color: var(--v-hellblau-base);
    border: 0;
}

.loginFormWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.loginFormWrapper::before {
    display: block;
    width: 0;

    content: "";
    flex: 1 1 0;
    background-color: red;
}

.loginFormWrapper::after {
    width: 0;
    content: "";
    flex: 1 1 350px;
}

.loginForm {
    max-width: 500px;
    min-width: 100px;
    // margin: 0 auto;
    padding: 28px;
    background-color: transparent;
    z-index: 1;
    flex: 0 0 auto;
}

.loginFormInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginFormRow {
    width: 400px;
    max-width: 400px;
    margin-bottom: 15px;
}

#homescreenTextWrapper {
    display: flex;
    justify-content: center;
    max-width: 450px;
    margin: auto;
}

#homescreenText {
    font-size: small;
    vertical-align: bottom;
    text-align: left;
    line-height: 12px;
}

.stayLoggedIn {
    height: 35px;
}

#questionmark-container {
    position: fixed;
    top: 0;
    right: 0;
}

.colorSchueler {
    color: $schueler-blau;
    margin: auto;
}

.marginAuto {
    margin: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.icon {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(61deg) brightness(109%) contrast(111%);
}

.questionmarkIcon {
    height: 5rem;
    width: 5rem;
}

.infobar {
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
@media only screen and (max-height: 666px) {
    .infobar {
        display: none;
    }
}
.infobarTexts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.infobarText {
    font-size: small;
    vertical-align: bottom;
    margin-left: 3px;
    margin-right: 3px;
    text-align: center;
    line-height: 20px;
}

.infobarIcons {
    display: flex;
    align-items: center;
}

.infobarIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.infobarLink {
    font-size: small;
    line-height: 30px;
}

.accessibilityButtons {
    width: 50px;
    height: 50px !important;
    border-radius: 8px;
    z-index: 2;
    margin: 16px 16px 0 0;
}

#eKlaraLogo {
    width: 80%;
    max-height: 40vh;
}

@media only screen and (max-height: 750px) {
    #eKlaraLogo {
        width: 50%;
        max-height: 40vh;
    }
}

@media only screen and (max-width: 500px) {
    .helpBtn {
        top: 1%;
        right: 1%;
    }

    .loginForm {
        width: 400px;
    }

    .loginFormRow {
        width: 312px;
    }

    .loginBtnWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
    }

    .loginBtn {
        width: 45%;
    }

    #regularLoginBtn {
        // margin-top: 12px;
    }

    .infobarIcon {
        width: 20px;
        height: 20px;
    }

    .stayLoggedIn {
        width: 100% !important;
    }

    .accessibilityButtons {
        width: 36px;
        height: 36px !important;
        margin: 4px 4px 0 0;
    }
}

@media only screen and (max-width: 850px), screen and (max-height: 600px) {
    .questionmarkIcon {
        height: 2.5rem;
        width: 2.5rem;
    }
}

.iconToDarkBlue {
    filter:
        brightness(0)
        saturate(100%)
        invert(15%)
        sepia(34%)
        saturate(669%)
        hue-rotate(187deg)
        brightness(91%)
        contrast(89%)
    ;
}
.loginButtonWidth {
    width: 100%;
    min-width: 100%;
}
</style>
