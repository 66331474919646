import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";
import store from "@/store";

const state = {
    icons: [],
    uploadedIcons: null // List of all uploaded icons (but not the URLs yet) for IconPicker.vue
};

const mutations = {
    pushIconToState: (state, { iconId, url } ) => {
        state.icons.push({ _id: iconId, url });
    },
    setUploadedIcons: (state, icons) => {
        state.uploadedIcons = icons;
    },
    pushUploadedIcon: (state, icon) => {
        state.uploadedIcons.push(icon);
    },
    deleteUploadedIcon: (state, iconId) => {
        state.uploadedIcons = state.uploadedIcons.filter((icon) => {
            return icon._id !== iconId;
        })
    },
    clearUploadedIcons: (state) => {
        state.uploadedIcons = null;
    }
};

const getters = {
    icons: state => state.icons,
    iconsById: state => state.icons.reduce((acc, icon) => {
        acc[icon._id] = icon.url;
        return acc;
    }, {}),
    uploadedIcons: state => state.uploadedIcons,
};

const actions = {
    async getIconUploads ({ commit, getters }, forceRequest) {
        try {
            if (!forceRequest && getters.uploadedIcons) {
                return getters.uploadedIcons;
            }
            const res = await backend.getIconUploads();
            await checkResponseStatus(200, res);
            const resJson = await res.json();
            commit('setUploadedIcons', resJson);
            return resJson;
        } catch (e) {
            console.error(e);
            return null;
        }
    },
    async getUploadedIcon ({ commit, getters }, iconId) {
        try {
            if (getters.iconsById[iconId]) {
                return getters.iconsById[iconId]
            }
            const res = await backend.getIconUpload(iconId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                commit('pushIconToState', { iconId, url });
                return url;
            }
            return null;
        } catch (e) {
            console.error(e);
            return e?.response?.status;
        }
    },
    async postIconUpload ({ commit }, icon) {
        try {
            const res = await backend.postIconUpload(icon);
            await checkResponseStatus(201, res);
            const resJson = await res.json();
            commit('pushUploadedIcon', resJson);
            store.commit('snackbar/showSnackbar', {
                color: "success",
                message: 'Icon erfolgreich hochgeladen',
            }, { root: true });
            return resJson;
        } catch (e) {
            console.error(e);
            store.commit('snackbar/showSnackbar', {
                color: "error",
                message: 'Beim hochladen des Icons ist etwas schiefgelaufen! Bitte neu laden',
            }, { root: true });
            return null;
        }
    },
    async deleteIconUpload ({ commit }, iconId) {
        try {
            const res = await backend.deleteIconUpload(iconId);
            await checkResponseStatus(204, res);
            commit('deleteUploadedIcon', iconId);
            return res;
        } catch (e) {
            console.error(e);
            return null;
        }
    },
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
