<template>
    <v-dialog
        v-model="show"
        width="500"
        @close="() => { $emit('close') }"
        @click:outside="() => { $emit('close') }"
        @keydown.esc="(e) => { $emit('close'); e.stopPropagation(); }"
    >
        <v-card
            style="min-height: 50vh; border-radius: 16px"
        >
            <v-card-title
                class="d-flex justify-space-between"
                style="background-color: var(--v-fileArchiveOrange-base); color: white"
            >
                <p class="headerDescription">{{ !isFile ? interactable ? 'Ordner umbennen, teilen' : 'Ordner' : fileName.length > 0 ? fileAvailability : 'Geteilter Ordner' }}</p>
                <div style="display: flex; align-items: center">
                    <v-btn
                        v-if="interactable"
                        color="success"
                        class="saveButton"
                        @click="startTransaction"
                    >
                        <img
                            :src="saveIcon"
                            class="iconToWhite"
                            style="height: 15px; margin-right: 8px"
                        >
                        <p> Speichern </p>
                    </v-btn>
                    <v-btn
                        icon
                        small
                        @click="() => { $emit('close') }"
                    >
                        <img
                            :src="closeIcon"
                            class="icon20 iconToWhite"
                            alt="Schließen des Teilen Dialogs"
                        >
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text style="height: 90%">
                <!-- Content of Dialog -->
                <div
                    style="position: sticky; top: 0px; z-index: 5; background-color: white"
                >
                    <p>{{ !isFile ? 'Name des Ordners:' : 'Name der Datei:' }}</p>
                    <v-text-field
                        v-model="itemName"
                        outlined
                        :readonly="!interactable"
                    />
                </div>
                <div>
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <p>
                            {{ interactable ? 'Teilen mit:' : item.sharing_Tasks.length > 0 ? 'Übersicht der Gruppen und Abgaben' : 'Geteilt mit:' }}
                        </p>
                        <v-tooltip
                            v-if="isFile && item.sharing_Tasks.find((task) => task.file == fileId) && accountRole === 'teacher'"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="min-width: 22px; width: 22px; margin-right: 16px"
                                    v-on="on"
                                    @click="openSubmission"
                                >
                                    <v-icon>
                                        mdi-folder
                                    </v-icon>
                                </v-btn>
                            </template>
                            Alle Abgaben ansehen
                        </v-tooltip>
                    </div>
                    <div id="folderContainer">
                        <div
                            v-if="interactable"
                            class="d-flex align-center"
                        >
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="mr-2"
                                style="height: 20px"
                            >

                            <v-text-field
                                v-model="searchTreeView"
                                class="ma-0 pt-0 pb-2"
                                hide-details
                                clearable
                            />
                        </div>
                        <v-treeview
                            v-model="receiverAccounts"
                            :items="possibleReceivers"
                            item-children="participants"
                            item-key="_id"
                            :search="searchTreeView"
                            selected-color="var(--v-chatColor-base)"
                            selectable
                            dense
                            open-on-click
                            :disabled="!interactable || isSharedFile"
                            return-object
                        >
                            <template v-slot:prepend="{ item }">
                                <div class="d-flex align-center">
                                    <img
                                        v-if="!item.participants"
                                        :src="pupilIcon"
                                        class="iconToDunkelgrau"
                                        height="20"
                                        alt=""
                                    >
                                    <div v-else>
                                        <v-badge
                                            v-if="badgeCounter(item) > 0"
                                            left
                                            color="#ff0000"
                                            :content="badgeCounter(item)"
                                            style="top: -8px; left: 6px"
                                        />
                                        <v-icon>
                                            mdi-account-group
                                        </v-icon>
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="label"
                                slot-scope="data"
                            >
                                <div
                                    :style="accountRole === 'teacher' && !data.item.participants && data.item.status && data.item.status !== 'Offen' ? 'cursor: pointer' : ''"
                                    @click="accountRole === 'teacher' && !data.item.participants && data.item.status && data.item.status !== 'Offen' ? openSubmission(data.item) : ''"
                                >
                                    {{ `${data.item.displayName}` }}
                                </div>
                            </template>
                            <template v-slot:append="{ item }">
                                <div
                                    v-if="accountRole === 'teacher' && !item.participants && item.status"
                                    class="pa-2"
                                    style="display: flex; align-items: center"
                                    :style="item.status !== 'Offen' ? 'cursor: pointer' : ''"
                                    @click="item.status !== 'Offen' ? openSubmission(item) : ''"
                                >
                                    <p>
                                        {{ item.status }}
                                    </p>
                                    <v-tooltip
                                        bottom
                                        :disabled="item.status === 'Offen'"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                elevation="3"
                                                style="margin-left: 20px; min-width: 20px; width: 20px; background-color: rgb(140, 189, 70);"
                                                :style="item.status === 'Offen' ? 'background-color: #d3d3d36b' : ''"
                                                v-on="on"
                                            >
                                                <v-badge
                                                    v-if="item.status === 'Abgegeben'"
                                                    left
                                                    content="1"
                                                    color="#ff0000"
                                                    style="top: -8px; left: 6px"
                                                />
                                                <v-icon
                                                    style="color: white; font-size: 32px"
                                                    :style="item.status === 'Offen' ? 'cursor: default' : ''"
                                                    :disabled="item.status === 'Offen'"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        Abgabe ansehen
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-treeview>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import searchIcon from '@/assets/Icons/lupe-18.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import { mapActions, mapGetters } from "vuex";
import closeIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';


export default {
    name: 'FolderSharingDialog',
    props: {
        item: { required: true, type: Object },
        interactable: { required: false, type: Boolean, default: true },
        isFile: { required: false, type: Boolean, default: false },
        fileName: { required: false, type: String },
        fileId: { required: false, type: String },
    },
    data: () => ({
        // Icons
        closeIcon,
        searchIcon,
        pupilIcon,
        saveIcon,
        show: true,
        searchTreeView: '',
        receiverAccounts: [],

        itemName: '',
        possibleReceivers: [],

        // watcher
        blockedWatcher: false,
    }),
    computed: {
        ...mapGetters('auth', ['accountRole', 'accountId']),
        fileAvailability() {
            if (this.item.sharing_Accounts.length > 0 || (this.isFile && this.item.sharing_Files ? this.item.sharing_Files.find((file) => file.file == this.fileId) : false)) {
                return `Geteilte Datei ${this.isSharedFile ? '(extern)' : ''}`;
            } else {
                return 'Private Datei';
            }
        },
        // shared file which is NOT owned
        isSharedFile() {
            return this.item.sharing_Files ? !!this.item.sharing_Files.find((file) => (this.fileId == file.file && this.accountId != file.owner)) : false;
        },
    },
    watch: {
        // force sharing with teacher if pupil
        receiverAccounts: {
            async handler() {
                if (!this.blockedWatcher) {
                    this.blockedWatcher = true;
                    for (let i = 0; i < this.possibleReceivers.length; i++) {
                        // if pupil is sharing to anyone, also force share to teachers
                        if (this.accountRole === 'pupil' && this.receiverAccounts.length > 0 && this.possibleReceivers[i].displayName === 'Lehrer') {
                            for (let j = 0; j < this.possibleReceivers[i].participants.length; j++) {
                                this.receiverAccounts.push(this.possibleReceivers[i].participants[j]);
                            }
                        }
                    }
                    // the v-treeview updates with heavy delay, without sleep this causes infinite loop. needs testing on slower devices
                    await new Promise(r => setTimeout(r, 100));
                    this.blockedWatcher = false;
                }
            }
        },
    },
    async mounted() {
        await this.getPossibleReceivers();
        this.itemName =`${this.fileName ? this.fileName : this.item.name ? this.item.name : this.item.fileName ? this.item.fileName : this.item.uploadedName ? this.item.uploadedName : '' }`;
    },
    methods: {
        ...mapActions("virtualFileSystem", ["getVFSFolderUploadsV2", "getVFSSharingV2", "updateVFSFolderV2", "updateVFSFolderUploadV2" ]),

        async startTransaction() {
            let sharing_Accounts = await Promise.all(this.receiverAccounts.map(el => {
                return el.account;
            }));
            let data;
            if (!this.isFile) {
                data = {
                    _id: this.item._id,
                    name: this.itemName,
                    sharing_Accounts,
                    sharing_enabled: sharing_Accounts.length > 0,
                    sharing_discoverable: sharing_Accounts.length > 0,
                };
            } else {
                // was the name changed?
                if (this.itemName != this.fileName && this.fileName) {
                    let res = await this.updateVFSFolderUploadV2({ fileId: this.fileId, name: this.itemName });
                }
                // create initial sharing_Files for legacy folder
                if (!this.item.sharing_Files || this.item.sharing_Files.length === 0) {
                    this.item.sharing_Files = [];
                    if (this.fileId && this.fileId != '') {
                        this.item.sharing_Files.push({
                            owner: this.accountId,
                            file: this.fileId,
                            sharing_Accounts: this.item.sharing_Accounts,
                        });
                    }
                }
                let createNewEntry = true;
                const sharing_Files = await Promise.all(this.item.sharing_Files.map((file) => {
                    if (file.file == this.fileId) {
                        createNewEntry = false;
                        // for the file of this dialog, replace the sharing Accounts with new selection
                        // performance: call should be ignored if prev==new
                        file.sharing_Accounts = sharing_Accounts;
                        return file;
                    }
                    return file;
                }));
                // no sharing_Files entry for current file exists, push new json data
                if (createNewEntry) {
                    sharing_Files.push({
                        owner: this.accountId,
                        file: this.fileId,
                        sharing_Accounts,
                    });
                }
                data = {
                    _id: this.item._id,
                    sharing_Files,
                };
            }
            let res = await this.updateVFSFolderV2(data);
            if (res.status === 200) {
                res = await res.json();
                this.$emit('close', res);
            }
        },

        async getPossibleReceivers() {
            if (this.item) {
                let addItemText;
                if(!this.isFile) {
                    addItemText = await this.getVFSSharingV2(this.item.parentFolder._id);
                    for (let i = 0; i < addItemText.length; i++) {
                        // grab the name of the Group
                        addItemText[i].displayName = addItemText[i].name;
                        let displayStatus = true;
                        if (addItemText[i].displayName === 'Lehrer' || this.item.type === 'folder') {
                            displayStatus = false;
                        }
                        // visually preselect possible recievers with currently set receivers
                        // for every possible receiver account
                        for (let j = 0; j < addItemText[i].participants.length; j++) {
                            // for every currently Shared AccountId
                            if (this.item.sharing_Accounts) {
                                for (let k = 0; k < this.item.sharing_Accounts.length; k++) {
                                    // add to selected Model if matching (user sees the menu with current setting)
                                    if (this.item.sharing_Accounts[k] == addItemText[i].participants[j].account) {
                                        // also check the status of the submission and create a flag for visual cues
                                        if (this.item.sharing_Tasks.length > 0) {
                                            for (let l = 0; l < this.item.sharing_Tasks.length; l++) {
                                                for (let m = 0; m < this.item.sharing_Tasks[l].submissions.length; m++) {
                                                    // is this account the uploader of this submission?
                                                    if (this.item.sharing_Tasks[l].submissions[m].account === addItemText[i].participants[j].account) {
                                                        // is this is also the same file that was opened in the sharingDialog?
                                                        if (this.fileId === this.item.sharing_Tasks[l].file) {
                                                            // is this seenByTeacher ? -> corrected, otherwise submitted.
                                                            if (this.item.sharing_Tasks[l].submissions[m].seenByTeacher) {
                                                                addItemText[i].participants[j].status = 'Korrigiert';
                                                            } else {
                                                                addItemText[i].participants[j].status = 'Abgegeben';
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            // if no status has been established, mark it as todo
                                            if (!addItemText[i].participants[j].status && displayStatus) {
                                                addItemText[i].participants[j].status = 'Offen';
                                            } else if (!displayStatus) {
                                                addItemText[i].participants[j].status = '';
                                            }
                                        }
                                        this.receiverAccounts.push(addItemText[i].participants[j]);
                                    }
                                }
                            }
                        }
                    }
                    this.possibleReceivers = addItemText;
                } else {
                    // grab the limited scope from backend
                    addItemText = await this.getVFSSharingV2(this.item._id);
                    // since this is a file, check the sharing_Files[] and fetch sharing metadata from its .sharing_Accounts
                    const file_sharingInfo = this.item.sharing_Files ? this.item.sharing_Files.find((file) => (file.file ? file.file : file) == this.fileId) : false
                    const file_sharing_Accounts = file_sharingInfo ? file_sharingInfo.sharing_Accounts : this.item.sharing_Accounts;
                    for (let i = 0; i < addItemText.length; i++) {
                        // grab the name of the Group
                        addItemText[i].displayName = addItemText[i].name;
                        let displayStatus = true;
                        if (addItemText[i].displayName === 'Lehrer' || this.item.type === 'folder') {
                            displayStatus = false;
                        }
                        // visually preselect possible recievers with currently set receivers
                        // for every possible receiver account
                        for (let j = 0; j < addItemText[i].participants.length; j++) {
                            // for every currently Shared AccountId
                            if (file_sharing_Accounts) {
                                for (let k = 0; k < file_sharing_Accounts.length; k++) {
                                    // add to selected Model if matching (user sees the menu with current setting)
                                    if (file_sharing_Accounts[k] == addItemText[i].participants[j].account) {
                                        // also check the status of the submission and create a flag for visual cues
                                        if (this.item.sharing_Tasks.length > 0) {
                                            for (let l = 0; l < this.item.sharing_Tasks.length; l++) {
                                                for (let m = 0; m < this.item.sharing_Tasks[l].submissions.length; m++) {
                                                    // is this account the uploader of this submission?
                                                    if (this.item.sharing_Tasks[l].submissions[m].account === addItemText[i].participants[j].account) {
                                                        // is this is also the same file that was opened in the sharingDialog?
                                                        if (this.fileId === this.item.sharing_Tasks[l].file) {
                                                            // is this seenByTeacher ? -> corrected, otherwise submitted.
                                                            displayStatus = true;
                                                            if (this.item.sharing_Tasks[l].submissions[m].seenByTeacher) {
                                                                addItemText[i].participants[j].status = 'Korrigiert';
                                                            } else {
                                                                addItemText[i].participants[j].status = 'Abgegeben';
                                                            }
                                                        } else {
                                                            displayStatus = false;
                                                        }
                                                    }
                                                }
                                            }
                                            // if no status has been established, mark it as todo
                                            if (!addItemText[i].participants[j].status && displayStatus) {
                                                addItemText[i].participants[j].status = 'Offen';
                                            } else if (!displayStatus) {
                                                addItemText[i].participants[j].status = '';
                                            }
                                        }
                                        this.receiverAccounts.push(addItemText[i].participants[j]);
                                    }
                                }
                            }
                        }
                    }
                    this.possibleReceivers = addItemText;
                }
            }
        },
        // opens the submission of the selected pupil
        openSubmission(item) {
            this.$emit('openSubmissions', this.fileId, item.account);
            this.$emit('close');
        },
        badgeCounter(item) {
            return item.participants.filter((el) => el.status === 'Abgegeben').length;
        },
    },
}
</script>

<style scoped>
    .saveButton {
        text-transform: inherit;
        margin-right: 15px
    }

    @media only screen and (max-width: 500px) {
        .saveButton {
            margin-right: 0px
        }

        .headerDescription {
            font-size: medium;
        }
    }

    @media only screen and (max-width: 370px) {
        .saveButton {
            width: 65%;
        }

        .headerDescription {
            font-size: small;
        }
    }
</style>
