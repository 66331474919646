<template>
    <div
        ref="lineItem"
        style="width: 100%"
    >
        <v-row
            no-gutters
            align="center"
            justify="center"
            style="height: 100%"
        >
            <v-col
                :style="`
                        transform: rotate(${line.rotation}rad);
                        -moz-transform: rotate(${line.rotation}rad);
                        -webkit-transform: rotate(${line.rotation}rad);
                        -o-transform: rotate(${line.rotation}rad);
                    `"
            >
                <v-row
                    justify="center"
                    dense
                >
                    <v-col cols="auto">
                        <v-col cols="auto">
                            <div
                                v-show="showTeacherUI"
                                ref="rotateIcon"
                            >
                                <v-icon
                                    style="cursor: grab"
                                    height="25"
                                    width="25"
                                >
                                    fas fa-sync-alt
                                </v-icon>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row
                    dense
                    :style="`margin-top: ${showTeacherUI ? '0px' : '26px'}; pointer-events: none;`"
                >
                    <v-col>
                        <div
                            ref="box"
                            class="mr-2"
                            :style="`
                                width: 100%;
                                height: 15px;
                            `"
                        >
                            <div
                                :class="{ 'soft-box-shadow': showTeacherUI }"
                                :style="`
                                    background-color: ${line.text};
                                    width: calc(100% - ${showTeacherUI ? '0px' : '65px'});
                                    height: 1px;
                                `"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "EditorLine",
    props: {
        line: {type: Object, required: true},
        mode: { type: String, required: true },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        exportMode: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        originalRotation: 0,
    }),
    computed: {
        // ...mapGetters("auth",["accountRole"]),

        showTeacherUI() {
            return !this.exportMode && this.mode === 'creator';
        }
    },
    mounted() {
        if (this.mode === 'creator') {
            this.rotateElement(this.$refs.rotateIcon);
        }
    },
    methods: {
        /**
         * Method to handle rotation of line element
         * @param element
         */
        rotateElement(element) {
            let _this = this;
            element.onmousedown = rotateMouseDown;
            element.ontouchstart = rotateMouseDown;
            // element.onpointerdown = dragMouseDown;
            // element.onpointermove = elementDrag;
            var rotStartX, rotStartY;
            var startAngle;
            var imgStartAngle;

            function rotateMouseDown(e) {
                // if (_this.accountRole === 'teacher')
                //     _this.$emit('focusMe');
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                var x = e.clientX ? e.clientX : e.touches[0].clientX;
                var y = e.clientY ? e.clientY : e.touches[0].clientY;
                rotStartX = x;
                rotStartY = y + (_this.$refs.lineItem.offsetHeight / 2);
                startAngle = Math.atan2( rotStartY, rotStartX );
                imgStartAngle = isNaN(_this.line.rotation) ? 0 : _this.line.rotation;
                _this.originalRotation = isNaN(_this.line.rotation) ? 0 : _this.line.rotation;
                // lastMove = e;
                document.onmouseup = closeRotateElement;
                document.ontouchend = closeRotateElement;
                // document.onpointerup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementRotate;
                document.ontouchmove = elementRotate;
                // document.onpointermove = elementDrag;
            }

            const elementRotate = (e) => {
                e = e || window.event;
                if (e.type !== 'touchmove' && e.type !== 'pointermove') {
                    e.preventDefault();
                }

                var x = e.clientX ? e.clientX : e.touches[0].clientX;
                var y = e.clientY ? e.clientY : e.touches[0].clientY;
                var mouseXFromCentre = x - rotStartX;
                var mouseYFromCentre = y - rotStartY;
                var mouseAngle = Math.atan2( mouseYFromCentre, mouseXFromCentre ) + (Math.PI / 2);
                // Calculate the new rotation angle for the image
                _this.line.rotation = mouseAngle - startAngle + imgStartAngle;
            }

            const closeRotateElement = (e) => {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.onpointerup = null;
                document.ontouchmove = null;
                document.onpointermove = null;
                this.$emit('history', { rotation: _this.originalRotation });
            }
        },
    }
}
</script>

<style lang="scss">

</style>
