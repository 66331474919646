<template>
    <div class="d-flex align-center">
        <div
            :ref="`menuBtns${chatRoomEvent._id}`"
            class="hideMenuBtns"
            style=" position: absolute; top: 30px; left: -30px;"
        >
            <ChatRoomEventListItemDeleteButton
                v-if="eventFromMe || chatRoomMembershipRole === 'moderator'"
                :chat-room-event="chatRoomEvent"
            />
        </div>
        <div style="line-height: 20px; width: 100%; display: flex; align-items: center">
            <div
                class="d-flex flex-row justify-center pointer"
                style="min-height: 24px; width: 100%"
            >
                <!-- Play/Pause Button with Tooltip -->
                <v-tooltip
                    :disabled="!showTooltips"
                    top
                    style="z-index: 9;"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            aria-hidden="true"
                            elevation="2"
                            class="pa-0 chatRoomEventColorInvert chatMessageBtn"
                            v-on="on"
                            @keypress.enter="toggleAudio"
                            @click.stop="toggleAudio"
                        >
                            <img
                                tabindex="0"
                                :src="audioPlaying ? pauseIcon : playIcon"
                                alt="Play audio message"
                                class="icon"
                                v-on="on"
                            >
                        </v-btn>
                    </template>
                    <span>{{ audioPlaying ? "Pause" : "Play" }}</span>
                </v-tooltip>

                <!-- Progress Bar and Time Display -->
                <div
                    class="d-flex flex-row align-center mx-1 mb-2"
                    style="width: 100%;"
                >
                    <!-- <v-progress-linear
                        :value="audioProgressPercent"
                        rounded
                        color="white"
                        style="width: 100%; height: 25px; margin-right: 8px"
                        @mousedown.stop="startDragging"
                        @mousemove.stop="handleDragging"
                        @mouseup.stop="stopDragging"
                        @touchdown="startDragging"
                        @touchmove="handleDragging"
                        @touchup="stopDragging"
                    /> --><v-progress-linear
                        :value="audioProgressPercent"
                        rounded
                        color="white"
                        style="width: 100%; height: 32px; margin-right: 8px"
                        @click.stop="updateAudioTime"
                    />
                    <div
                        class="d-flex flex-column align-center"
                        style="cursor: pointer" 
                        @click.stop="togglePlaybackSpeed"
                    >
                        <v-btn
                            v-if="!audioPlaying"
                            small
                            icon
                            color="white"
                            class="pa-0"
                            @click.stop="togglePlaybackSpeed"
                        >
                            <v-icon>mdi-speedometer</v-icon>
                        </v-btn>
                        <span
                            v-if="audioPlaying"
                            style="font-size: 14px; color: white"
                        >{{ playbackSpeed }}x</span>
                    </div>
                </div>
            </div>
        </div>
        <span
            style="position: absolute;top: 38px;left: 40px; color: white"
        >{{ audioPlaying ? formattedCurrentTime : formattedTotalTime }}</span>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as backend from "../../../../api/backend";

import ChatRoomEventListItemDeleteButton from "./ChatRoomEventListItemDeleteButton";

import playIcon from "@/assets/Icons/Play-114.svg";
import pauseIcon from "../../../../assets/Icons/Pause-115.svg";


export default {
    name: "ChatRoomEventListAudioItem",
    components: {
        ChatRoomEventListItemDeleteButton,
    },
    props: {
        chatRoomEvent: { type: Object, required: true },
        chatRoomMembershipRole: { required: true, type: String },
        fontColor: { required: true, type: String },
    },
    data: () => ({
        loadedAudio: null,
        pauseIcon,
        playIcon,
              audio: null,
      audioPlaying: false,
      audioDuration: 0,
      audioCurrentTime: 0,
      playbackSpeed: 1, // Default playback speed is 1x
      isDragging: false,
      timerUpdateInterval: null,
    }),
    computed: {
        ...mapState("auth", ["account"]),
        ...mapState("tooltips", ["showTooltips"]),
        eventFromMe() {
            return this.chatRoomEvent.author === this.account._id;
        },
        formattedTotalTime() {

            const totalMinutes = Math.floor(this.audioDuration / 60);
            const totalSeconds = Math.floor(this.audioDuration % 60);
            return `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;
        },
        formattedCurrentTime(){
            const minutes = Math.floor(this.audioCurrentTime / 60);
            const seconds = Math.floor(this.audioCurrentTime % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        audioProgressPercent() {
            return this.audioDuration ? (this.audioCurrentTime / this.audioDuration) * 100 : 0;
        },
    },
    async mounted() {
        this.loadedAudio = await this.loadAudioFile(this.chatRoomEvent._id);
    },
    unmount() {
        if (this.loadedAudio) URL.revokeObjectURL(this.loadedAudio);
    },
      beforeDestroy() {
        // Stop audio playback if component is removed from DOM
        if (this.audio) {
        this.audio.pause();
        this.audio = null;
        }
      },
    methods: {
        ...mapActions("chat", ["getChatRoomEventFile"]),
         updateAudioTime(event) {
            if (!this.audio || !this.audioDuration) return;

            // Get the progress bar width
            const progressBarWidth = event.currentTarget.offsetWidth;
            
            // Get the click position relative to the progress bar (X-axis)
            const clickPositionX = event.offsetX || event.clientX - event.currentTarget.getBoundingClientRect().left;

            // Calculate the new current time based on the click position
            const newTime = (clickPositionX / progressBarWidth) * this.audioDuration;

            // Set the new current time for the audio
            this.audio.currentTime = newTime;
        },
        async loadAudio() {
            if (!this.loadedAudio) {
                this.loadedAudio = await this.loadAudioFile(this.chatRoomEvent._id);
            }
            if (!this.audio) {
                this.audio = new Audio(this.loadedAudio);

                this.audio.onloadedmetadata = () => {
                this.audioDuration = this.audio.duration || 0;
                };

                this.audio.ontimeupdate = () => {
                if (!this.isDragging) {
                    this.audioCurrentTime = this.audio.currentTime;
                }
                };

                this.audio.onended = () => {
                this.audioPlaying = false;
                this.audioCurrentTime = 0;
                };
            }
        },
        async playAudio() {
            await this.loadAudio();
            if (!this.audioPlaying) {
                this.audio.playbackRate = this.playbackSpeed;
                await this.audio.play();
                this.audioPlaying = true;
            }
        },
        pauseAudio() {
        if (this.audio && this.audioPlaying) {
            this.audio.pause();
            this.audioPlaying = false;
        }
        },
        togglePlaybackSpeed() {
            this.playbackSpeed = this.playbackSpeed === 1 ? 1.5 : this.playbackSpeed === 1.5 ? 2 : 1;
            if (this.audio) {
                this.audio.playbackRate = this.playbackSpeed;
            }
        },
        startDragging(event) {
          this.isDragging = true;
        },
        stopDragging(event) {
            if (this.isDragging && this.audio) {
                // Update audio current time when dragging stops
                this.audio.currentTime = this.audioCurrentTime;
            }
            this.isDragging = false;
        },
        handleDragging(event) {
            if (this.isDragging && this.audio) {
                const progressWidth = event.currentTarget.offsetWidth;
                const clickPositionX = event.offsetX;
                const draggedTime = (clickPositionX / progressWidth) * this.audioDuration;
                this.audioCurrentTime = draggedTime;
            }
        },
        async loadAudioFile(chatRoomEventId) {
            const blob = await this.getChatRoomEventFile(chatRoomEventId);
            return URL.createObjectURL(blob);
        },
         toggleAudio() {
            if (this.audioPlaying) {
                this.pauseAudio();
            } else {
                this.playAudio();
            }
        },
        handleMsgClick(event){
            const element = this.$refs[`menuBtns${event._id}`];
            if (element) {
                element.classList.remove('hideMenuBtns');
                setTimeout(() => {
                    element.classList.add('hideMenuBtns');
                }, 3000);
            }
        }

    },
};
</script>
<style scoped>
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    height: 20px;
}
.chatMessageBtn{
    min-width: 30px !important;
    max-width: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    background-color:white !important;
}
.hideMenuBtns {
    display: none !important;
}
</style>
