import * as backend from '../../api/backend';
import {checkResponseStatus} from '../../util/check';

const actions = {
    async getMatrixUsersFullNameByRoomId({commit, dispatch}, params) {
        try {
            const res = await backend.getMatrixUsersFullNameByRoomId(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch (e) {
            return e.response.status;
        }
    },

    // use to create 1-to-1 chat or group chat, check required params in backend
    async createCustomRoom({commit, dispatch}, params) {
        try {
            const res = await backend.postCustomRoom(params);
            await checkResponseStatus(201, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async removeMessage({commit, dispatch}, params) {
        try {
            const res = await backend.deleteMessage(params);
            await checkResponseStatus(204, res);
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMatrixName({commit, dispatch}, params) {
        try {
            const res = await backend.getMatrixName(params);
            await checkResponseStatus(201, res);
            const name = await res.json();
            return name;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMatrixUsersInRoom({commit, dispatch}, params) {
        try {
            const res = await backend.getMatrixUsersInRoom(params);
            await checkResponseStatus(201, res);
            const users = await res.json();
            return users;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMatrixRoomLockStatus({commit, dispatch}) {
        try {
            const res = await backend.getMatrixRoomLockStatus();
            await checkResponseStatus(200, res);
            const matrixRoomLockStatus = await res.json();
            return matrixRoomLockStatus;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMatrixRoomLockStatusById({commit, dispatch}, params) {
        try {
            const roomId = params;
            const res = await backend.getMatrixRoomLockStatusById(roomId);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async setMatrixRoomLockStatus({commit, dispatch}, params) {
        try {
            const roomId = params.roomId;
            delete params.roomId;
            const res = await backend.setMatrixRoomLockStatus(roomId, params);

            await checkResponseStatus(201, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteMatrixRoom({commit, dispatch}, roomId) {
        try {
            const res = await backend.deleteMatrixRoom(roomId);

            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    }
};

export default {
    namespaced: true,
    actions,
};
