import {
    allowedMimeTypes,
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre,
    allowedMimeTypesForAudioPlayer,
    allowedMimeTypesForLibre,
    allowedMimeTypesForEditor,
} from '../constants/allowedMimeTypes'

const isFileThumbnailCompatibleExtension = (fileExtension) => {
  return !allowedExtensionsForAudioPlayer.includes(fileExtension) || fileExtension === 'mp4';
};

export { isFileThumbnailCompatibleExtension };
